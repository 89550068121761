/**
 * Copyright 2021 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import {useRef, useCallback, useState} from 'react';
import {Document as FSDocument, Index as FSIndex} from 'flexsearch';
import {generalUtils, domUtils} from 'utils';
import {KEY_UP, KEY_DOWN, KEY_RIGHT, KEY_LEFT, KEY_RETURN, KEY_TAB, KEY_BACK_SPACE} from 'keycode-js';
import styles from './Selector.css';
import styleUtils from 'utils.css';
import modalStyles from 'components/Modal/Modal.css';

export const CATEGORYPANEL_ID = 'categoryPanel';
export const OPTIONPANEL_ID = 'optionPanel';
export const VALUEPANEL_ID = 'valuePanel';
export const DROPDOWN_ID = 'dropdown';
export const INPUT_ID = 'input';
export const SEARCHBAR_ID = 'searchBar';
export const SEARCHBAR_CONTAINER_ID = 'searchBarContainer';

export const ADD_NEW_ID = 'add_new_id';

export const categorySuggestionRegex = /\b(in)\b/i;
export const multipleWhiteSpaceRegex = /  +/g;
// Escape Regex http://stackoverflow.com/questions/3115150/#answer-9310752
export const ESCAPE = str => str.replace(/[\s#$()*+,.?[\\\]^{|}-]/g, '\\$&');

/**
 * Whether keyDown event is to navigte - UP, DOWN, or Ctrl/Cmd RIGHT/LEFT keys
 */
export const isMovingHighlighted = evt => {
  if (
    evt.keyCode === KEY_UP ||
    evt.keyCode === KEY_DOWN ||
    (generalUtils.cmdOrCtrlPressed(evt) && (evt.keyCode === KEY_LEFT || evt.keyCode === KEY_RIGHT))
  ) {
    return true;
  }
};

/**
 * When dropdown is open the focus stays on input element which handles keydown event,
 * An option/category in dropdown is highligted based on navigate events (UP, DOWN, Ctrl/Cmd RIGHT/LEFT)
 * This method returns true if there is a highlighted child and event should be handled by child and not input
 */
export const isHighlightedBlockEvent = (evt, valuePanelIsHighlighted) => {
  if (
    evt.keyCode === KEY_RETURN ||
    evt.keyCode === KEY_TAB ||
    // If a selected value is highlighted in value panel then
    // backspace should remove that selection and is handled by highligted value element (handle remove)
    // otherwise backspace event should delete query string in input
    (evt.keyCode === KEY_BACK_SPACE && valuePanelIsHighlighted)
  ) {
    return true;
  }
};

/**
 * Returns true if element rect is in the direction of navigation from base rect
 */
const isElementInDirection = ({direction, rect, elementRect}) => {
  let isInDirection = false;

  switch (direction) {
    case KEY_UP:
      isInDirection = elementRect.bottom <= rect.top;
      break;
    case KEY_DOWN:
      isInDirection = elementRect.top >= rect.bottom;
      break;
    case KEY_RIGHT:
      isInDirection = elementRect.left >= rect.right;
      break;
    case KEY_LEFT:
      isInDirection = elementRect.right <= rect.left;
      break;
  }

  return isInDirection;
};

/**
 * Returns the closest element in a direction from a reference point
 * @param {*} [childrenPropsMap] - id and ref Map of input elements
 * @param {*} [rect] - reference point rect
 * @returns {Object}
 */
const getClosestElement = ({direction, elementsMap, rect = {}} = {}) =>
  [...Array.from(elementsMap)].reduce((result, [id, elementProps = {}]) => {
    const elementRect = elementProps.element?.getBoundingClientRect() ?? {};

    if (elementRect.top === elementRect.bottom) {
      // Hidden element
      return result;
    }

    if (isElementInDirection({direction, elementRect, rect})) {
      const distance = (elementRect.left - rect.left) ** 2 + (elementRect.top - rect.top) ** 2;

      if (!result.minDistance || distance < result.minDistance) {
        return {id, ...elementProps, minDistance: distance};
      }
    }

    return result;
  }, {});

/**
 * A custom hook to:
 * 1. store a component's child elements information in a Map, E.g. their refs, keyDown and highlighted handlers
 * 2. capture which child is in highlighted and set highlighted among its children
 */
export const useFilialPiety = () => {
  const childrenPropsMap = useRef(null); //[id, {element, setHighlightedChild, keyDown}]
  const [highlightedChild, highlightedSetter] = useState(null); // {id, element}
  const highlightedChildRef = useRef(); // Ref to store current highligtedChild, added to remove any dependency in setHighlightedChild

  if (!childrenPropsMap.current) {
    childrenPropsMap.current = new Map();
  }

  const saveChildRef = useCallback((id, element) => {
    childrenPropsMap.current.set(id, {element});
  }, []);

  const registerChildHandlers = useCallback((id, handlers = {}) => {
    const {element} = childrenPropsMap.current.get(id) ?? {};

    childrenPropsMap.current.set(id, {element, ...handlers});

    return () => childrenPropsMap.current.delete(id); //Return a function to remove handlers (called during unmount)
  }, []);

  /*
  If we draw Selector component in a tree then each branch of this tree can be considered a pathArr,
  each node of this tree stores information on which of its child has highlight (highlightedChild state),
  leaf nodes of this tree renders a set of options or selected values (<li> elements)
                                  input
                                /       \
      categoryPanel <--- dropdown            valuePanel
                        /        \            /   |...  \
              categoryPanel    optionPanel   R1    R2 ... Rn
             /   |...  \        /   |...  \                 \
           R1    R2 ... Rn    R1    R2 ... Rn             selected values
          /      |            /
     options  options....   option

  Examples of pathArr: If an option in resource R1 (lets assume R1 is in optionPanel) is highlighted then
  1. pathArr from input: ['dropdown', 'optionPanel', 'R1']
  2. pathArr from dropdown: ['optionPanel', 'R1']
  3. pathArr from optionPanel: ['R1']
  4. pathArr from resource R1: []
 */

  const resetHighlightedChild = useCallback(pathArr => {
    // we need to recursively call resetHighlightedChild of nodes along the pathArr until we reach the option
    if (pathArr.length === 0) {
      // If relative pathArr is empty that means we have reached the direct parent of option
      // reset highlightedChild to null, this will remove highlighted style from option
      highlightedChildRef.current = null;

      highlightedSetter(null);

      return;
    }

    // Otherwise recursively traverse the pathArr
    return childrenPropsMap.current.get(pathArr.shift())?.resetHighlightedChild?.(pathArr);
  }, []); // NOTE: Adding a dependency to resetHighlightedChild will invoke component unmount which will delete its ref

  const setHighlightedChild = useCallback((options = {}) => {
    /*
      options: {pathArr, newHighlightedId, rect}
      There are three scenarios when setting highlighted on an option:
      1) No highlight exists - in this case the arguments are input element rect (root node) and direction of navigation
      2) highlighted option exists -
         in this case the arguments are direction of navigation, highlighted option rect and pathArr
      3) hover on an option - arguments are pathArr and optionId

      To set highlight on an option we need to know:
        a) its relative path from input element (tree branch)
        b) set of parameters to identify the option i.e optionId Or rect & direction
      In scenario three both information are already provided to the function
    */
    if (_.isEmpty(options)) {
      return;
    }

    const {newHighlightedId, direction} = options;
    const pathArr = [...(options.pathArr ?? [])];
    const childrenMap = new Map(childrenPropsMap.current);
    const rect = highlightedChildRef.current?.element.getBoundingClientRect() ?? options.rect;

    let closestElement = {};

    while (true) {
      if (pathArr.length > 0) {
        closestElement = {id: pathArr[0], ...childrenMap.get(pathArr[0])};
      } else {
        closestElement = newHighlightedId
          ? {id: newHighlightedId, element: childrenMap.get(newHighlightedId).element}
          : getClosestElement({direction, elementsMap: childrenMap, rect});
      }

      if (_.isEmpty(closestElement)) {
        // (code tag #leave) If there is no child element in the direction then return
        // returning undefined will move highlighted search to its sibling node
        highlightedChildRef.current = null;
        highlightedSetter(null);

        return;
      }

      if (!closestElement.setHighlightedChild) {
        // (code tag #found) If setHighlightedChild is undefined then we have reached the leaf node
        // This element is the new highlighted element
        // Scroll to the element if it is hidden and return its pathArr and rect
        const infoPanelElement = closestElement.element.parentElement?.querySelector(`.${styles.infoPanel}`);

        // Scroll to the element if needed
        domUtils.scrollToElement({
          element: closestElement.element,
          ...(infoPanelElement && {offsetElements: [infoPanelElement]}),
        });

        highlightedChildRef.current = closestElement;
        highlightedSetter(closestElement);

        return [pathArr, closestElement.element.getBoundingClientRect()];
      }

      // reset any existing highlighted child
      highlightedChildRef.current = null;
      highlightedSetter(null);

      // (code tag #enter)
      const [highlightedElementPathArr, highlightedRectRef] =
        closestElement.setHighlightedChild?.({...options, pathArr: pathArr.slice(1)}) ?? [];

      if (!highlightedRectRef) {
        // A recursive call has returned undefined (code tag #leave) i.e no highlighted child found in the direction
        // If pathArr exists then remove this node from path Arr and continue closest node search
        const index = pathArr.indexOf(closestElement.id);

        if (index !== -1) {
          pathArr.splice(index);
        }

        childrenMap.delete(closestElement.id);

        if (closestElement.id === VALUEPANEL_ID || closestElement.id === DROPDOWN_ID) {
          return;
        }

        continue;
      }

      // highlighted option is found (code tag #found) then concatenate the node to pathArr and return
      return [[closestElement.id, ...(highlightedElementPathArr ?? [])], highlightedRectRef];
    }
  }, []); // NOTE: Adding a dependency to highlightedSetter will invoke component unmount which will delete its ref

  const keyDown = useCallback((evt, {pathArr = []}) => {
    if (childrenPropsMap.current.get(pathArr[0])?.keyDown) {
      // Recursively traverse the pathArr to delegate keyDown event to the child component which is managing highlighted
      return childrenPropsMap.current.get(pathArr[0]).keyDown(evt, {pathArr: pathArr.slice(1)});
    }
  }, []);

  return {
    childrenPropsMap: childrenPropsMap.current,
    saveChildRef,
    registerChildHandlers,
    highlightedChild,
    highlightedChildRef,
    setHighlightedChild,
    resetHighlightedChild,
    keyDown,
  };
};

export const getOptionIdPath = (option = {}, idPath) =>
  idPath ?? (option.href ? 'href' : option.id ? 'id' : option.value ? 'value' : '');
export const getOptionTextPath = (option = {}, textPath) =>
  textPath ?? (option.name ? 'name' : option.value ? 'value' : option.hostname ? 'hostname' : '');

export const getOptionId = (option, idPath) =>
  typeof option === 'object' ? _.get(option, getOptionIdPath(option, idPath)) : option;
export const getOptionText = (option, textPath) =>
  typeof option === 'object' ? _.get(option, getOptionTextPath(option, textPath)) : String(option);

export const getOptionById = (options = [], id, idPath) => options.find(option => getOptionId(option, idPath) === id);
export const getOptionByText = (options = [], text, textPath) =>
  text.trim() &&
  options.find(option => getOptionText(option, textPath).trim().toLowerCase() === text.trim().toLowerCase());

export const getSearchResult = (searchIndex, query, options = {enrich: true}) => {
  // Search Index can be an instance of Index Or Document
  // get options from result in case of document otherwise return the searchResult
  const searchResult = searchIndex.search(query, options);
  const document = _.get(searchResult, '0.result');

  return document ? document.map(({doc}) => doc) : searchResult;
};

export const prepareSearchIndex = ({options = [], idPath, textPath, store} = {}) => {
  // flexSearch path is separated by ':' unlike lodash '.' separator
  const id = getOptionIdPath(options[0], idPath)?.replace('.', ':');
  const field = getOptionTextPath(options[0], textPath).replace('.', ':');
  let index;
  const indexOptions = {encoder: 'icase', tokenize: 'full', resolution: 9};

  if (id && field) {
    index = new FSDocument({
      ...indexOptions,
      document: {id, index: [field], store: store ?? [id, field]},
    });

    options.forEach(option => index.add(option));

    return index;
  }

  index = new FSIndex(indexOptions);
  options.forEach(option => index.add(option, option));

  return index;
};

/**
 * Find category Id of the last selected option
 */
export const getLastSelectedActiveCategory = ({values = new Map(), categories, allResources}) => {
  let lastSelectedCategoryId;

  _.forEachRight([...Array.from(values.keys())], resourceId => {
    if (lastSelectedCategoryId) {
      return;
    }

    const category = categories.find(({id}) => id === allResources[resourceId].categoryId);

    if (!category.hidden && !category.displayResourceAsCategory) {
      lastSelectedCategoryId = category.id;
    }
  });

  return lastSelectedCategoryId;
};

/**
 * Find active category object from given Id
 */
export const getNextVisibleCategoryId = categories =>
  categories.find(({hidden, displayResourceAsCategory}) => !hidden && !displayResourceAsCategory)?.id;

/**
 * Find the longest matching text among options that starts with query string
 */
export const getSuggestionText = (inputQuery, options = [], textPath) => {
  if (!inputQuery.trim() || options.length === 0) {
    return;
  }

  const query = inputQuery.replace(multipleWhiteSpaceRegex, ' ');

  const ref = getOptionText(options[0], textPath);
  let suggestion = '';
  const startIndex = ref.toLowerCase().indexOf(query.toLowerCase());

  if (startIndex === -1) {
    return suggestion;
  }

  // Take first option as reference and find all the substring combinations from query string index
  for (let endIndex = startIndex + 1; endIndex <= ref.length; endIndex++) {
    const nextSuggestion = ref.substring(startIndex, endIndex);

    if (options.some(value => !getOptionText(value, textPath).toLowerCase().includes(nextSuggestion.toLowerCase()))) {
      // If the substring is not in any one of the value then return current result
      break;
    }

    // Otherwise return new suggestion
    suggestion = nextSuggestion;
  }

  return suggestion.slice(query.length);
};

/**
 * create promise object
 */
export const createPromise = () => {
  const promiseObj = {};

  promiseObj.promise = new Promise((resolve, reject) => {
    promiseObj.onSearchDone = resolve;
    promiseObj.onSearchReject = reject;
  });

  return promiseObj;
};

export const populateSearchPromises = (categories, activeCategoryId) =>
  categories.reduce(
    (result, category) => {
      if (category.displayResourceAsCategory || category.id === activeCategoryId) {
        Object.entries(category.resources).forEach(([resourceId, {hidden, type}]) => {
          if (hidden || type === 'container') {
            return;
          }

          result[resourceId] = createPromise(); // promiseObj: {promise, onSearchDone, onSearchReject}
        });
      }

      return result;
    },
    categories.length === 1 ? {} : {[CATEGORYPANEL_ID]: createPromise()},
  );

/**
 * Bold or underline the text match in each string
 */
export const getHighlightedText = ({query, text, bold = false} = {}) => {
  if (!query.trim() || query.length === 0 || !text) {
    return text;
  }

  const regex = new RegExp(`(${ESCAPE(query)})`, 'i');
  const matches = text.split(regex).map((text, index) => {
    const highlight = text.toLowerCase() === query.toLowerCase();
    const highlightStyle = bold ? styleUtils.bold : styles.highlightTextUnderline;

    return (
      <span key={index} className={highlight ? highlightStyle : ''}>
        {text}
      </span>
    );
  });

  return <span>{matches}</span>;
};

/**
 * Takes the result of options load promise and determine the next suggestion/highlighted among primary matches
 */
export const pickSuggestion = (allSuggestions = [], query) => {
  // allSuggestions is an array of objects - {suggestion, primaryMatch: {id, text}, id, pathArr}
  // Remove entries with undefined suggestions
  const suggestions = allSuggestions.filter(({suggestion}) => typeof suggestion === 'string');

  if (suggestions.length === 0) {
    return;
  }

  // Category list suggestion
  const categorySuggestion = suggestions.find(({id}) => id === CATEGORYPANEL_ID);

  // Either total length is one Or all suggestion strings are same, in this case we can just pick the first entry
  const hasSingleSuggestion =
    suggestions.length === 1 ||
    !suggestions.some(({suggestion}) => suggestion !== suggestions[0].suggestion) ||
    suggestions.filter(({suggestion}) => suggestion !== '').length === 1;

  // 1. If there is a suggestion from category list then return category list suggestion as result
  // 2. If there is only one entry then return that entry as result
  if (categorySuggestion || hasSingleSuggestion) {
    const {suggestion, primaryMatch, pathArr} = categorySuggestion ?? suggestions[0];

    return {
      suggestion: primaryMatch.id === ADD_NEW_ID ? '' : suggestion,
      highlighted: {pathArr, newHighlightedId: primaryMatch.id},
    };
  }

  // Otherwise, we need to take primary match from each list and determine suggestion/highlighted among these
  const suggestion = getSuggestionText(query, suggestions, 'primaryMatch.text');

  const searchIndex = prepareSearchIndex({
    options: suggestions,
    idPath: 'primaryMatch.id',
    textPath: 'primaryMatch.text',
    store: true,
  });

  const {primaryMatch, pathArr} = getSearchResult(searchIndex, query, {enrich: true, limit: 1})[0] ?? {};

  return {suggestion, highlighted: {pathArr, newHighlightedId: primaryMatch.id}};
};

export const shouldCloseDropdown = (targetElement, searchBarElement, modalContext) => {
  // Case 1: Clicked in selector
  if (searchBarElement.contains(targetElement)) {
    // Should not close on click inside selector
    return false;
  }

  // modalContext is empty if selector is not mounted on a Modal
  // Case 2: Selector is mounted on a Modal and clicked anywhere in Modal
  if (modalContext.getModalRef?.()?.contains(targetElement)) {
    // Should close on click, return true to skip checking other scenarios
    return true;
  }

  const isModalClicked = targetElement.closest(`.${modalStyles.modal}`);

  // Case 3: Container resource in Selector has a modal, clicking on this should not close selector
  if (isModalClicked) {
    // Should not close on container resource modal click
    return false;
  }

  const isBackdropClicked = targetElement.classList.contains(styleUtils.fixedCurtain);
  const isNotParentModalBackdrop = !modalContext.zIndex || getComputedStyle(targetElement).zIndex > modalContext.zIndex;

  if (isBackdropClicked && isNotParentModalBackdrop) {
    // If backdrop z-order is higher than selector parent Modal then clicking on it should not close the selector, return false
    return false;
  }

  // Close in all other cases
  return true;
};

const areResourceValuesSame = (newValues, oldValues) => {
  if (newValues.length !== oldValues.length) {
    return false;
  }

  return generalUtils.sortAndStringifyArray(newValues) === generalUtils.sortAndStringifyArray(oldValues);
};

export const isValuesMapEqual = (map1 = new Map(), map2 = new Map()) => {
  if (map1.size !== map2.size) {
    return false;
  }

  for (const [resourceId, resourceValues] of map1) {
    if (!map2.has(resourceId)) {
      return false;
    }

    // compare values array
    if (!areResourceValuesSame(resourceValues, map2.get(resourceId))) {
      return false;
    }
  }

  return true;
};
