/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import {Component} from 'react';
import * as PropTypes from 'prop-types';
import cx from 'classnames';
import intl from 'intl';
import Slider from 'rc-slider';
import styles from './DateTimePicker.css';

export default class TimePicker extends Component {
  static propTypes = {
    hour: PropTypes.number, // value from 0-23
    minute: PropTypes.number, // value from 0-59
    onChange: PropTypes.func.isRequired,
    single: PropTypes.bool, // single = TimePicker used alone and not as part of DateTimePicker
    active: PropTypes.bool, // active = TimePicker should be shown
    direction: PropTypes.oneOf(['up', 'down']), // direction = which direction the TimePicker should open i.e. up, down
    small: PropTypes.bool, // true means width = 260px, false means width = 300px. Default is true
    shadow: PropTypes.bool, // true means the TimePicker will have a box shadow around it
    type: PropTypes.oneOf(['from', 'to']),
  };

  static defaultProps = {
    single: true,
    active: false,
    direction: 'down',
    small: true,
    shadow: false,
  };

  constructor(props) {
    super(props);

    const {hour, minute} = this.props;
    const hourStr = hour !== null ? hour.toString().padStart(2, '0') : '-';
    const minuteStr = minute !== null ? minute.toString().padStart(2, '0') : '-';

    this.state = {hourTextValue: hourStr, minuteTextValue: minuteStr};

    this.handleHourRangeChange = this.handleHourRangeChange.bind(this);
    this.handleHourTextChange = this.handleHourTextChange.bind(this);
    this.handleHourKeyDown = this.handleHourKeyDown.bind(this);
    this.handleHourBlur = this.handleHourBlur.bind(this);
    this.handleMinuteRangeChange = this.handleMinuteRangeChange.bind(this);
    this.handleMinuteBlur = this.handleMinuteBlur.bind(this);
    this.handleMinuteTextChange = this.handleMinuteTextChange.bind(this);
    this.handleMinuteKeyDown = this.handleMinuteKeyDown.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const {hour, minute, type} = nextProps;
    const state = {hour, minute, type};

    if (hour === null && prevState.hour !== null) {
      state.hourTextValue = '-';
    } else if (hour !== null) {
      state.hourTextValue = hour.toString().padStart(2, '0');
    }

    if (minute === null && prevState.minute !== null) {
      state.minuteTextValue = '-';
    } else if (minute !== null) {
      state.minuteTextValue = minute.toString().padStart(2, '0');
    }

    return state;
  }

  handleHourRangeChange(val) {
    const hour = Number(val);

    this.setState({hourTextValue: hour.toString().padStart(2, '0')});
    this.props.onChange({hour});
  }

  handleMinuteRangeChange(val) {
    const stepSize = 5;
    const modValue = val % stepSize;
    const roundedValue = modValue < 3 ? val - modValue : val + (5 - modValue);
    // don't allow user to set minute to 60
    const minute = roundedValue === 60 ? 59 : roundedValue;

    this.setState({minuteTextValue: minute.toString().padStart(2, '0')});
    this.props.onChange({minute});
  }

  handleHourTextChange(evt) {
    const evtValue = evt.target.value;
    const intValue = Number(evtValue);

    if (isNaN(intValue) || evtValue.length > 2 || intValue > 23) {
      return;
    }

    if (intValue < 0) {
      this.setState({hourTextValue: '0'});
      this.props.onChange({hour: 0});
    } else {
      this.setState({hourTextValue: evtValue});
      this.props.onChange({hour: intValue});
    }
  }

  handleMinuteTextChange(evt) {
    const evtValue = evt.target.value;
    const intValue = Number(evtValue);

    if (isNaN(intValue) || evtValue.length > 2 || intValue > 59) {
      return;
    }

    if (intValue < 0) {
      this.setState({minuteTextValue: '0'});
      this.props.onChange({minute: 0});
    } else {
      this.setState({minuteTextValue: evtValue});
      this.props.onChange({minute: intValue});
    }
  }

  handleHourKeyDown(evt) {
    const hour = this.props.hour;
    const up = evt.key === 'ArrowUp';
    const down = evt.key === 'ArrowDown';

    if (evt.key === 'Enter') {
      this.setState(state => ({hourTextValue: state.hourTextValue.padStart(2, '0')}));
    } else if ((up && hour < 23) || (down && hour > 0)) {
      const hourChange = up ? 1 : -1;

      this.props.onChange({hour: hour + hourChange});
    }
  }

  handleMinuteKeyDown(evt) {
    const minute = this.props.minute;
    const up = evt.key === 'ArrowUp';
    const down = evt.key === 'ArrowDown';

    if (evt.key === 'Enter') {
      this.setState(state => ({minuteTextValue: state.minuteTextValue.padStart(2, '0')}));
    } else if ((up && minute < 59) || (down && minute > 0)) {
      const minuteChange = up ? 1 : -1;

      this.props.onChange({minute: minute + minuteChange});
    }
  }

  handleHourBlur() {
    if (this.props.hour !== null) {
      this.setState(state => ({hourTextValue: state.hourTextValue.padStart(2, '0')}));
    }
  }

  handleMinuteBlur() {
    if (this.props.minute !== null) {
      this.setState(state => ({minuteTextValue: state.minuteTextValue.padStart(2, '0')}));
    }
  }

  render() {
    const {shadow, hour, minute, single, active, direction, small} = this.props;

    const pickerClass = cx(styles.timePicker, {
      [styles.shadow]: shadow,
      [styles.smallSection]: small,
    });

    const timeTitleClass = cx(styles.timeTitle, {
      [styles.visible]: single,
      [styles.smallSection]: small,
    });

    const wrapperClass = cx({
      [styles.disabled]: !active,
      [styles.smallSection]: small,
      [styles.openUp]: single && direction === 'up',
      [styles.absolute]: single,
    });

    return (
      <div className={wrapperClass}>
        <div className={timeTitleClass}>
          <span>{intl('Explorer.Time')}</span>
        </div>
        <div className={pickerClass}>
          <div className={styles.timeTextField}>
            <input
              type="text"
              value={this.state.hourTextValue}
              onChange={this.handleHourTextChange}
              onKeyDown={this.handleHourKeyDown}
              onBlur={this.handleHourBlur}
            />
            <span>:</span>
            <input
              type="text"
              value={this.state.minuteTextValue}
              onChange={this.handleMinuteTextChange}
              onKeyDown={this.handleMinuteKeyDown}
              onBlur={this.handleMinuteBlur}
            />
          </div>
          <div className={styles.timeRangeField}>
            {intl('Common.Hours')}
            <Slider min={0} max={23} tooltip={false} value={hour} onChange={this.handleHourRangeChange} />
          </div>
          <div className={styles.timeRangeField}>
            {intl('Common.Minutes')}
            <Slider
              min={0}
              max={60}
              step={5}
              tooltip={false}
              value={minute % 5 < 3 ? Math.floor(minute / 5) * 5 : Math.ceil(minute / 5) * 5}
              onChange={this.handleMinuteRangeChange}
            />
          </div>
        </div>
      </div>
    );
  }
}
