/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import {getGridSelector} from 'components/Grid/GridSelectors';
import {isUserOwner, getUserId, getAllUsersByIdMap} from 'containers/User/UserState';
import {createSelector} from 'reselect';
import {gridSettings} from './LocalUserDetailConfig';
import * as GridUtils from 'components/Grid/GridUtils';
import {getAccessRestrictionOptions} from '../../UsersAndGroupsUtils';
import {
  getFormattedRoleFromHref,
  isGlobalRoleHref,
  getScopeId,
  getScopeAll,
  getLabelObjects,
  getScopeIdStringFromScope,
  areScopesEqual,
} from 'containers/RBAC/RBACUtils';
import {getRouteParams, isEdge} from 'containers/App/AppState';
import {getAuthSecPrincipals} from 'containers/RBAC/AuthSecPrincipalState';
import {getAccessRestrictions} from 'containers/RBAC/AccessRestriction/List/AccessRestrictionListState';
import {getPermissions} from 'containers/RBAC/PermissionState';
import {isAPIAvailable} from 'api/apiUtils';
import {hrefUtils} from 'utils';

export const getLocalUserScopeRows = createSelector(
  [getPermissions, getAuthSecPrincipals, getAllUsersByIdMap, getRouteParams, getUserId],
  (permissions, authSecPrincipals, users, params, currentUserId) => {
    const id = Number(params.id);
    const user = users.get(id);
    const authSecPrincipal = authSecPrincipals.find(
      ({nameLow, type}) => nameLow === user.username.toLowerCase() && type === 'user',
    );
    const isSelfViewing = currentUserId === id;
    const hasDeletePermission = isAPIAvailable('org_permission.delete');

    const scopeRows = permissions.reduce((result, permission) => {
      if (permission.auth_security_principal.href !== authSecPrincipal?.href) {
        return result;
      }

      const scope = getLabelObjects(permission.scope);
      const scopeExists = result.find(
        item =>
          areScopesEqual(item.scope, scope) && isGlobalRoleHref(permission.role.href) === (item.type === 'global'),
      );

      if (scopeExists) {
        scopeExists.roles.push(getFormattedRoleFromHref(permission.role.href));
        scopeExists.roleHrefs.push(permission.role);
        scopeExists.permissionHrefs.push(permission.href);
      } else {
        result.push({
          key: isGlobalRoleHref(permission.role.href) ? 'global' : `scoped${getScopeIdStringFromScope(scope)}`,
          type: isGlobalRoleHref(permission.role.href) ? 'global' : 'scoped',
          scope,
          scopeHrefs: permission.scope.length ? getScopeId(permission.scope) : getScopeAll(),
          roles: [getFormattedRoleFromHref(permission.role.href)],
          roleHrefs: [permission.role],
          permissionHrefs: [permission.href],
          labels: GridUtils.getLabelsMap(scope),
        });
      }

      return result;
    }, []);

    scopeRows.forEach(row => {
      // For current user, lock the row that has 'owner' role to prevent him/her from removing his/her owner role.
      const isLock =
        isSelfViewing &&
        row.type === 'global' &&
        row.roleHrefs.some(roleHref => hrefUtils.getId(roleHref.href) === 'owner');
      const isAllowed = hasDeletePermission && !isLock;

      row.selectable = isAllowed;
      row.removable = isAllowed;
    });

    return scopeRows;
  },
);

export const getGridSettings = createSelector([isEdge, gridSettings], (isEdge, gridSettings) => {
  const columns = {...gridSettings.columns};

  columns.scope.disabled = isEdge;

  return {...gridSettings, columns};
});

const getGrid = state =>
  getGridSelector(state, {
    settings: getGridSettings,
    rows: getLocalUserScopeRows,
  });

export const getLocalUserDetails = createSelector(
  [
    getGrid,
    isUserOwner,
    getRouteParams,
    getAuthSecPrincipals,
    getAllUsersByIdMap,
    getUserId,
    getAccessRestrictions,
    isEdge,
  ],
  (grid, userIsOwner, params, authSecPrincipals, users, currentUserId, accessRestrictions, edge) => {
    const id = Number(params.id);
    const user = users.get(id);
    const authSecPrincipal = authSecPrincipals.find(
      ({nameLow, type}) => nameLow === user.username.toLowerCase() && type === 'user',
    );
    const accessRestriction = authSecPrincipal.access_restriction
      ? accessRestrictions.find(({href}) => href === authSecPrincipal.access_restriction.href).name
      : intl('Common.None');
    const isSelfViewing = currentUserId === id;
    let userlock = false;

    const accessRestrictionOptions = getAccessRestrictionOptions(accessRestrictions);

    if (user && user.locked) {
      userlock = true;
    }

    return {
      grid,
      userIsOwner,
      authSecPrincipal,
      userlock,
      user,
      currentUserId,
      isSelfViewing,
      accessRestrictionOptions,
      accessRestriction,
      edge,
    };
  },
);
