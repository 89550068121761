/**
 * Copyright 2016 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import {actionTypes} from 'redux-router5';
import {createSelector} from 'reselect';
import {generalUtils} from 'utils';
import {isUserScoped, getUser, isUserWithReducedScope} from 'containers/User/UserState';
import {parseVersion, getVersion as getVersionUtil} from 'utils/general';

const defaultAppState = {
  loading: false,
  error: false,
};

export default {
  app(state = defaultAppState, action) {
    switch (action.type) {
      case 'LOADING_START':
        return {...state, loading: true};
      case 'LOADING_END':
        return {...state, loading: false};
      case 'ERROR':
        return {...state, error: action.error};
      default:
        return state;
    }
  },

  // Boolean if data is fetching
  transitionFetching(state = false, action) {
    switch (action.type) {
      case 'FETCHING_DATA_START':
        return true;
      case 'FETCHING_DATA_END':
        return false;
      default:
        return state;
    }
  },

  // Boolean if data is fetching until router finish state
  // getRoute returns transitionRoute when this is true
  transitionFetchingToEnd(state = false, action) {
    switch (action.type) {
      case 'FETCHING_DATA_START':
        return true;
      case actionTypes.CANCEL_TRANSITION:
      case actionTypes.TRANSITION_ERROR:
      case actionTypes.TRANSITION_SUCCESS:
        return false;
      default:
        return state;
    }
  },

  transitionFetchingRoutes(state = [], action) {
    switch (action.type) {
      case 'FETCHING_DATA_START':
        return action.routes;
      case 'FETCHING_DATA_END':
        return [];
      default:
        return state;
    }
  },

  system(state = {}, {type, data}) {
    switch (type) {
      case 'USER_LOGIN_SUCCESS':
        return _.pick(data, [
          'pce_cluster_type',
          'flow_analytics_enabled',
          'health_dashboard_enabled',
          'inactivity_expiration_minutes',
        ]);
      default:
        return state;
    }
  },

  product(state = {}, {type, data}) {
    switch (type) {
      case 'USER_LOGIN_SUCCESS':
        return {
          ...data.product_version,
          help_url: data.help_url,
          support_url: data.support_url,
          version_date: data.version_date,
          version_tag: data.version_tag,
          cs_integration_enabled: data.cs_integration_enabled,
          cs_workload_limit: data.cs_workload_limit,
        };
      default:
        return state;
    }
  },

  optionalFeatures(state = {}, {type, data}) {
    switch (type) {
      case 'OPTIONAL_FEATURES':
        return data.reduce((result, feature) => {
          result[feature.name] = feature;

          return result;
        }, {});
      default:
        return state;
    }
  },

  org(state = {}, {type, data}) {
    switch (type) {
      case 'GET_ORG_INSTANCE':
        return data;
      default:
        return state;
    }
  },

  orgSettings(state = {}, {type, data}) {
    switch (type) {
      case 'GET_ORG_SETTINGS':
        return data;
      default:
        return state;
    }
  },
};

const getSystem = state => state.system;
export const getProduct = state => state.product;
export const getOrg = state => state.org;

export const getProductName = createSelector(getProduct, product => product.product_name);

export const reverseProviderConsumer = createSelector(getOrg, org => org.reverse_provider_consumer_column);

export const isSupercluster = createSelector(getSystem, system => Boolean(system.pce_cluster_type));

export const isHealthEnabled = createSelector(
  [getSystem, isUserScoped],
  (system, userIsScoped) => system.health_dashboard_enabled && !userIsScoped,
);

export const getSessionExpirationMinutes = createSelector(
  getSystem,
  // Fallback to 20 minutes
  ({inactivity_expiration_minutes: minutes}) => (typeof minutes === 'number' && minutes) || 20,
);

export const getSupportUrl = createSelector(getProduct, product => product.support_url);

export const requireProvisionNote = createSelector(getOrg, org => org.require_sec_policy_commit_message);

export const getAppGroupLabelTypes = createSelector(getOrg, org => org.app_groups_default);

export const areVulnerabilitiesEnabled = state => state.optionalFeatures.vulnerability_analytics?.enabled ?? false;

export const isAsyncExplorerEnabled = state =>
  (state.optionalFeatures?.async_traffic_queries && state.optionalFeatures.async_traffic_queries?.enabled) ?? false;

export const isIlluminationApiEnabled = state => state.optionalFeatures.illumination?.enabled ?? false;

export const isNetworkEnforcementNodeEnabled = state =>
  state.optionalFeatures.network_enforcement_node?.enabled ?? false;

export const isLDAPEnabled = state => state.optionalFeatures.ldap_authentication_enabled?.enabled ?? false;

export const isEnhancedDataCollectionEnabled = state =>
  state.optionalFeatures.enhanced_data_collection?.enabled ?? false;

// Feature flag for displaying Reporting DB replication information.
export const isReportingEnabled = state => state.optionalFeatures.reporting?.enabled ?? false;

// For displaying core services settings menu item
export const isCoreServicesEnabled = state => state.optionalFeatures.core_services?.enabled ?? false;

// Security settings - IP Forwarding feature
export const isIPForwardingEnabled = state => state.optionalFeatures.ip_forwarding_firewall_setting?.enabled ?? false;

// API Key Settings
export const getOrgSettings = state => state.orgSettings;

/**
 * Router selectors
 */
export const getRouter = state => state.router;

// Select previous route
// Note: router.previousRoute can be null. (e.g. page refresh)
export const getRoutePrevious = createSelector(getRouter, router => router.previousRoute);

export const getRoutePreviousName = createSelector(getRoutePrevious, previousRoute => previousRoute?.name);

export const getRoutePreviousParams = createSelector(getRoutePrevious, previousRoute => previousRoute?.params);

// Select current route. If a transition is happening, it will return a route before transition
export const getRouteCurrent = createSelector(getRouter, router => router.route);
export const getRouteCurrentName = state => getRouteCurrent(state).name;
export const getRouteCurrentParams = state => getRouteCurrent(state).params;

// Select route, if a transition is happening, it will return a future route we transitioning to
export const getRoute = state => {
  const router = getRouter(state);
  const route = state.transitionFetchingToEnd ? router.transitionRoute : router.route;

  return route;
};
export const getRouteName = state => getRoute(state).name;
export const getRouteParams = state => getRoute(state).params;

export const isEdge = createSelector([getProduct], product => product.product_name === 'edge');

export const isCrowdstrike = createSelector([getProduct], product => product.cs_integration_enabled === true);

export const isParameterizedBuild = createSelector([getProduct], product => product.parameterized === true);

export const getWorkloadMaximum = createSelector(
  [getProduct],
  // TODO: Need to check cs_integration_enabled first before trying to read cs_workload_limit per backend developer
  // This will probably change when we mix when cs_integration_enabled flag is irrelevant
  product => (product.cs_integration_enabled === true ? product.cs_workload_limit : Number.POSITIVE_INFINITY),
);

/**
 *  Version mismatch
 */
export const getVersion = createSelector([getProduct, isEdge], (product, isEdge) => {
  const regex = /^(\d+\.\d+\.\d+).*/;

  // Will use release_info from "product_version" object for checking both parameterized and non-parameterized build for
  // backend with Edge until backend is ready to use 'product.version'.
  // Note: Prior parameterized builds before 6/10/2020 have release_info: 'parameterized'.
  // e.g. {release_info: '20.1.0-Edge'} - non-parameterized build
  //      {release_info: '0.0.0-parameterized' - parameterized build
  //      {release_info: 'parameterized' - previous parameterized build before 6/10/2020
  return isEdge && regex.test(product.release_info) ? product.release_info.match(regex)[1] : product.version;
});

export const getParsedVersion = createSelector(
  getVersion,
  version => version.match(/^(?<major>\d+)\.(?<minor>\d+)\.(?<patch>\d+).*/).groups,
);

export const getParsedVersionNumber = createSelector(getParsedVersion, version => _.mapValues(version, Number));

export const getPCEBuild = createSelector([getProduct], product => product.build);

export const getVersionMismatch = createSelector([getVersion, isParameterizedBuild], (pceVersion, parameterized) =>
  generalUtils.getVersionMismatch({pceVersion, parameterized}),
);

export const getUIVersion = createSelector([], () => getVersionUtil(process.env.UI_VERSION));

export const getParsedUIVersionNumber = createSelector(getUIVersion, version => parseVersion(version));

export const getHelpUrl = () => 'https://docs.illumio.com';

export const getHelpVersion = createSelector(
  [getParsedVersion, isEdge],
  ({major, minor}, edgeEnabled) => `${edgeEnabled ? 'edge' : 'core'}/${major}.${minor}/Content`,
);

export const getDocsUrl = createSelector(
  getHelpUrl,
  getHelpVersion,
  (domain, path) => `${domain}/${path}/Search%20Results.htm?q=`,
);

export const getKbDocsUrl = createSelector(getSupportUrl, url => `${url}/knowledge-base/articles`);
export const getHelpEdgeUrl = createSelector(
  getHelpUrl,
  getHelpVersion,
  (domain, path) => `${domain}/${path}/LandingPages/Categories/illumio-edge.htm`,
);

export const getAppState = state => ({
  routeName: getRouteName(state),
  previousRoute: getRoutePrevious(state),
  system: getSystem(state),
  userIsWithReducedScope: isUserWithReducedScope(state),
  user: getUser(state),
});

export const areStatePropsEqual = (next, prev) =>
  next.routeName.split('.')[1] === prev.routeName.split('.')[1] &&
  next.system.inactivity_expiration_minutes === prev.system.inactivity_expiration_minutes &&
  next.previousRoute === prev.previousRoute;
