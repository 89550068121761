/**
 * Copyright 2021 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from 'intl';
import {array, object, string} from 'yup';
import {StatusIcon, Pill, Button, Notifications, AttributeList, Form, Diff, TypedMessages} from 'components';
import styles from './EssentialServiceRules.css';
import {getId} from 'utils/href';

export const labels = (
  <>
    <Pill.Label type="role">{intl('Common.AllRoles')}</Pill.Label>
    <Pill.Label type="app">{intl('Common.AllApplications')}</Pill.Label>
    <Pill.Label type="env">{intl('Common.AllEnvironments')}</Pill.Label>
    <Pill.Label type="loc">{intl('Common.AllLocations')}</Pill.Label>
  </>
);

/**
 * Grid Formatting Utils
 */
const getStatusString = enabled => (enabled ? intl('Common.Enabled') : intl('Common.Disabled'));

export const formatStatus = ({value, oldValue}) => (
  <Diff.Option
    value={getStatusString(value.enabled)}
    oldValue={oldValue ? getStatusString(oldValue.enabled) : undefined}
    noDiff={typeof oldValue?.enabled !== 'boolean'}
  />
);

export const formatServices = services => (
  <div className={styles.pillGap}>
    {services.map((service, index) => (
      <Pill.Service value={service} noIcon key={index} />
    ))}
  </div>
);

export const formatIPs = ({value, oldValue, modified}, clickableRef) => {
  // ips are readonly, so ignore when deciding whether to diff
  const noDiff = !modified || !oldValue.ip_lists;
  const pillValues = value.ip_lists ?? value.ips.map(ip => ({name: ip}));
  const oldPillValues = noDiff ? null : oldValue.ip_lists ?? oldValue.ips.map(ip => ({name: ip}));

  return <Pill.IPListDiff value={pillValues} oldValue={oldPillValues} noDiff={noDiff} ref={clickableRef} />;
};

export const requiredForTextMap = new Map([
  ['dhcp_v4_ingress', intl('EssentialServiceRules.Ingress.DHCPv4')],
  ['dhcp_v6_ingress', intl('EssentialServiceRules.Ingress.DHCPv6')],
  ['ipsec_ingress', intl('EssentialServiceRules.IPSec')],
  ['traceroute_ingress', intl('EssentialServiceRules.Ingress.Traceroute')],
  ['icmp_v6_ingress', intl('EssentialServiceRules.Ingress.ICMPv6')],
  ['dns_egress', intl('EssentialServiceRules.Egress.DNS')],
  ['dhcp_v4_egress', intl('EssentialServiceRules.Egress.DHCPv4')],
  ['dhcp_v6_egress', intl('EssentialServiceRules.Egress.DHCPv6')],
  ['ipsec_egress', intl('EssentialServiceRules.IPSec')],
  ['icmp_v6_egress', intl('EssentialServiceRules.Egress.ICMPv6')],
  ['traceroute_egress', intl('EssentialServiceRules.Egress.Traceroute')],
  ['pce_egress', intl('EssentialServiceRules.Egress.PCE')],
  ['pce_event_service_egress', intl('EssentialServiceRules.Egress.EventService')],
]);

export const formatInfoIcon = key => <StatusIcon status="info" tooltip={requiredForTextMap.get(key)} />;

export const formatEditButton = (key, data, handleClick) =>
  !data.readonly && (
    <Button
      color="standard"
      size="small"
      icon="edit"
      tid={`${key}-edit`}
      noFill
      onClick={_.partial(handleClick, key, data)}
    />
  );

/**
 * ModalMachine Utils
 */
export const formatInitialValues = (key, {enabled, ip_lists: ips, direction, ingress_services: services}) => ({
  key,
  status: enabled ? 'enabled' : 'disabled',
  ips: ips.map(ip => ({...ip, categoryKey: 'ip_lists'})),
  direction,
  services,
});

const schemas = object({
  status: Form.Radio.schema,
  ips: array(
    object({
      href: string(),
    }),
  ),
});

const ipCategory = [{value: intl('Common.IPList'), categoryKey: 'ip_lists'}];

// not all keys are here, only ones that are editable: {readonly: false}
const warningNotificationMap = new Map([
  ['dhcp_v4_ingress', intl('EssentialServiceRules.Modal.Notification.DHCP')],
  ['dhcp_v6_ingress', intl('EssentialServiceRules.Modal.Notification.DHCP')],
  ['traceroute_ingress', intl('EssentialServiceRules.Modal.Notification.Traceroute')],
  ['icmp_v6_ingress', intl('EssentialServiceRules.Modal.Notification.ICMPv6')],
  ['dns_egress', intl('EssentialServiceRules.Modal.Notification.DNS')],
  ['dhcp_v4_egress', intl('EssentialServiceRules.Modal.Notification.DHCP')],
  ['dhcp_v6_egress', intl('EssentialServiceRules.Modal.Notification.DHCP')],
  ['icmp_v6_egress', intl('EssentialServiceRules.Modal.Notification.ICMPv6')],
  ['traceroute_egress', intl('EssentialServiceRules.Modal.Notification.Traceroute')],
]);

const allowLeaveOnDirty = true;
const dhcpRegex = /^dhcp/;

export const getEditModalContent = ({formData: initialValues, context, notifications}) => ({
  formProps: {schemas, initialValues, allowLeaveOnDirty},
  header: {props: {title: intl('EssentialServiceRules.Modal.Title')}},
  content: {
    props: {notScrollable: true},
    children: () => (
      <>
        {notifications.length > 0 && <Notifications>{notifications}</Notifications>}
        <Notifications>
          {[
            {
              type: 'warning',
              message: warningNotificationMap.get(context.key),
            },
          ]}
        </Notifications>
        <AttributeList>
          {[
            {
              key: intl('EssentialServiceRules.Modal.Purpose'),
              value: requiredForTextMap.get(context.key),
            },
            {
              key: <Form.Label name="status" title={intl('Common.Status')} />,
              value: (
                <Form.RadioGroup name="status" horizontal>
                  <Form.Radio name="status" value="enabled" label={intl('Common.Enabled')} tid="enabled" />
                  <Form.Radio name="status" value="disabled" label={intl('Common.Disabled')} tid="disabled" />
                </Form.RadioGroup>
              ),
            },
            {
              key: context.services.length > 1 ? intl('Common.Services') : intl('Common.Service'),
              value: (
                <div className={styles.pseudoSelector}>
                  {context.services.map((service, index) => (
                    <Pill.Service value={service} noIcon key={index} themePrefix="pill-" theme={styles} />
                  ))}
                </div>
              ),
            },
            ...(dhcpRegex.test(context.key)
              ? [
                  {
                    key: intl('EssentialServiceRules.Modal.SourcePort'),
                    value: context.services[0].source_port ?? context.services[0].port,
                  },
                ]
              : []),
            {
              key: (
                <Form.Label
                  name="ips"
                  title={context.direction === 'ingress' ? intl('Common.Consumers') : intl('Common.Providers')}
                />
              ),
              value: (
                <Form.ObjectSelector
                  type="SingleGroupMultiItemSelect"
                  name="ips"
                  categories={ipCategory}
                  theme={styles}
                  objects={[{type: 'ip_lists', pversion: 'draft'}]}
                />
              ),
            },
          ]}
        </AttributeList>
      </>
    ),
  },
  footer: {
    children: (send, {values: {ips}, isValid}) => (
      <>
        <Button
          text={intl('Common.Cancel')}
          noAnimateInAndOut
          tid="cancel"
          noFill
          onClick={_.partial(send, {type: 'CANCEL'})}
        />
        <Button
          text={intl('Common.OK')}
          noAnimateInAndOut
          tid="ok"
          disabled={!isValid || !ips.length}
          onClick={_.partial(send, {type: 'CONTINUE'})}
        />
      </>
    ),
  },
});

export const getConfirmationModalContent = ({onProgressDone, matches}) => {
  return {
    formProps: {schemas, allowLeaveOnDirty},
    header: {
      props: {title: intl('EssentialServiceRules.Modal.ConfirmationTitle')},
    },
    content: (
      <TypedMessages>
        {[
          {
            icon: 'warning',
            content: intl('EssentialServiceRules.Modal.ConfirmationMessage'),
          },
        ]}
      </TypedMessages>
    ),

    footer: {
      children: (send, {values, isValid}) => (
        <>
          <Button
            text={intl('Common.Back')}
            noAnimateInAndOut
            noFill
            tid="back"
            onClick={_.partial(send, {type: 'BACK'})}
          />
          <Button
            text={intl('Common.Cancel')}
            noAnimateInAndOut
            noFill
            tid="cancel"
            onClick={_.partial(send, {type: 'CANCEL'})}
          />
          <Button
            progress={matches('modal.submitting.progress')}
            progressCompleteWithCheckmark
            text={intl('Common.Save')}
            tid="save"
            disabled={!isValid}
            onClick={_.partial(send, {type: 'SUBMIT', values})}
            onProgressDone={onProgressDone}
          />
        </>
      ),
    },
  };
};

export const getErrorModalContent = error => ({
  header: {props: {title: intl('Common.Error')}},
  formProps: {allowLeaveOnDirty},
  content: (
    <TypedMessages>
      {[
        {
          icon: 'error',
          content: error?.message ?? error,
        },
      ]}
    </TypedMessages>
  ),
  footer: [{type: 'CLOSE', buttonProps: {text: intl('Common.Close')}}],
});

export const getModalConfig = defaultConfig => {
  const customConfig = {
    states: {
      modal: {
        states: {
          open: {
            always: [{target: 'edit'}],
          },
          edit: {
            on: {
              CANCEL: {
                actions: 'close',
              },
              CONTINUE: 'confirmation',
            },
          },
          confirmation: {
            on: {
              BACK: 'edit',
              CANCEL: {actions: 'close'},
              SUBMIT: 'submitting',
            },
          },
          submitting: {
            states: {
              completeProgress: {
                invoke: {
                  onDone: [{target: 'close'}],
                },
              },
            },
          },
        },
      },
    },
  };

  return _.merge(defaultConfig, customConfig);
};

export const computePayload = ({ips: initialIPs, status: initialStatus, key}, {values: {ips, status}}) => {
  const payload = {};

  if (initialStatus !== status) {
    payload.enabled = status === 'enabled';
  }

  if (!payload.hasOwnProperty('enabled') || initialIPs.length !== ips.length) {
    payload.ip_lists = ips.map(({href}) => ({href}));
  } else {
    const initialIPHrefs = initialIPs.reduce((set, {href}) => set.add(href), new Set());
    const ipHrefs = ips.reduce((set, {href}) => set.add(href), new Set());
    const union = new Set([...initialIPHrefs, ...ipHrefs]);

    if (union.size !== initialIPHrefs.size) {
      payload.ip_lists = Array.from(ipHrefs).map(href => ({href}));
    }
  }

  return {[key]: payload};
};

const areIpsDifferent = (ips, oldIPs) => {
  if (ips.length !== oldIPs.length) {
    return true;
  }

  const ipHrefs = ips.reduce((set, {href}) => set.add(getId(href)), new Set());
  const oldIpHrefs = oldIPs.reduce((set, {href}) => set.add(getId(href)), new Set());
  const union = new Set([...ipHrefs, ...oldIpHrefs]);

  return union.size !== ipHrefs.size;
};

export const getDiffData = (value, oldValue, pversion) => {
  if (!oldValue || pversion === 'active' || value.readonly) {
    return null;
  }

  let isRuleModified = false;
  const oldData = {};

  if (value.enabled !== oldValue.enabled) {
    oldData.enabled = oldValue.enabled;
    isRuleModified = true;
  }

  if (areIpsDifferent(value.ip_lists, oldValue.ip_lists)) {
    oldData.ip_lists = oldValue.ip_lists;

    if (!isRuleModified) {
      isRuleModified = true;
    }
  }

  return isRuleModified ? oldData : null;
};
