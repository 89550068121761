/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import {createSelector} from 'reselect';
import {getRouteName, getRouteParams} from '../../App/AppState';
import {getAllUsersMap, canUserProvisionGlobalObject} from 'containers/User/UserState';
import {fillPolicyObjUserInfo, getPolicyVersions} from 'containers/Provisioning/ProvisioningUtils';

export default {
  instance(state = {}, action) {
    switch (action.type) {
      case 'SERVICE_GET_INSTANCE':
        return action.data;
      default:
        return state;
    }
  },

  detail(state = {}, action) {
    switch (action.type) {
      case 'SERVICE_GET_ITEM':
        return action.data.detail;
      default:
        return state;
    }
  },
};
export const getServiceInstance = state => state.service.instance;
export const getServiceDetail = createSelector(
  [state => state.service.detail, getRouteName],
  (serviceDetail, routeName) => (routeName.endsWith('create') ? {} : serviceDetail),
);

export const getServiceVersions = createSelector([getServiceDetail, getRouteParams], (serviceDetail, {pversion}) =>
  getPolicyVersions(serviceDetail, pversion),
);

export const getServiceItem = createSelector(
  [getServiceVersions, getRouteParams, getAllUsersMap, canUserProvisionGlobalObject],
  ({versions, pversionObjIsDeleted, isOldVersion}, {pversion}, usersMap, userCanProvisionGlobalObject) => {
    const isProvisionDisabled = !userCanProvisionGlobalObject;

    versions = fillPolicyObjUserInfo(versions, usersMap);

    return {
      versions,
      pversionObjIsDeleted,
      pversion,
      usersMap,
      isProvisionDisabled,
      isOldVersion,
    };
  },
);
