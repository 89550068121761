/**
 * Copyright 2021 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import _ from 'lodash';
import {useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react';
import {motion} from 'framer-motion';
import {useDeepCompareMemo} from 'utils/react';
import {AppContext} from 'containers/App/AppUtils';
import {KEY_RETURN, KEY_TAB} from 'keycode-js';
import {Banner, Icon, Pill, Notifications} from 'components';
import {
  ADD_NEW_ID,
  CATEGORYPANEL_ID,
  useFilialPiety,
  getOptionId,
  getOptionById,
  getOptionByText,
  getOptionText,
  getSuggestionText,
  getHighlightedText,
  prepareSearchIndex,
} from 'containers/Selector/SelectorUtils';
import {fetchResource} from 'containers/Selector/SelectorSaga';
import Option from 'containers/Selector/Option';
import InfoPanel from 'containers/Selector/InfoPanel';

export default function ListResource(props) {
  const {
    id,
    query,
    saveRef,
    theme,
    isGridArea,
    insensitive,
    resource: {
      dataProvider,
      name,
      hidden,
      format,
      noTitle,
      noSubtitle,
      infoPanel,
      historyKey,
      enableHistory,
      noEmptyBanner,
      optionProps: {
        idPath,
        textPath,
        filterOption,
        noCheckbox,
        allowMultipleSelection,
        visibilityWhenSelected,
        isPill,
        pillProps,
        tooltipProps,
      } = {},
      template,
    },
    onMouseLeave,
    history,
    initialLoadParams,
    onSetHighlighted,
    registerHandlers,
  } = props;

  const {fetcher} = useContext(AppContext);
  const fetchTaskRef = useRef();

  /* We do not need to execute fetch task in case of
      1) initial load of resources with only static values (Note: we need to fetch when query string exists)
      2) resources that support recently used history, in this case we already have the resource history options available
   */
  const fetchIsNotNeeded = !query && (enableHistory || !dataProvider);
  const optionsLoaded = fetchTaskRef.current === undefined ? fetchIsNotNeeded : fetchTaskRef.current === null;

  const {saveChildRef, highlightedChild, setHighlightedChild, resetHighlightedChild, highlightedChildRef} =
    useFilialPiety();

  const [optionsObj, setOptionsObj] = useState({}); // {dataProviderOptions, staticsOptions, createOption, partialOption}
  const [error, setError] = useState(null);

  const resource = useDeepCompareMemo(props.resource);
  const values = useDeepCompareMemo(props.values);
  const selectedValuesInResource = useDeepCompareMemo(values.get(id) ?? []);
  const pathArr = useDeepCompareMemo(props.pathArr);
  const searchParams = useDeepCompareMemo(props.searchParams ?? {});
  const {onInitialLoadDone, onInitialLoadReject} = useDeepCompareMemo(initialLoadParams ?? {});

  const resourceHistory = useMemo(
    () => history[historyKey ?? typeof dataProvider === 'string' ? dataProvider : id] ?? [],
    [history, id, dataProvider, historyKey],
  );

  const statics = useMemo(
    () => (typeof resource.statics === 'function' ? resource.statics(query, resource) : resource.statics),
    [resource, query],
  );

  const searchIndex = useMemo(
    () => (Array.isArray(statics) ? prepareSearchIndex({options: statics, idPath, textPath, store: true}) : null),
    [statics, idPath, textPath],
  );

  const apiArgs = useMemo(() => {
    const {apiArgs} = resource;

    if (!apiArgs) {
      return {query: {query}};
    }

    if (typeof apiArgs === 'function') {
      return apiArgs(query, values, resource);
    }

    const {query: {getQuery, ...otherQuery} = {}, ...args} = apiArgs;

    Object.assign(args, {query: {...otherQuery, ...(getQuery?.(query, values, resource) ?? {query})}});

    return args;
  }, [query, resource, values]);

  const apiOptions = useDeepCompareMemo(apiArgs);

  const filterableOptionsRef = useRef([]);
  const allOptionsRef = useRef();

  if (fetchIsNotNeeded) {
    filterableOptionsRef.current = (enableHistory ? resourceHistory : statics) ?? [];
  }

  // Create refs to prevent creating dependency for component mount and unmount (code tag #register/unregister)
  // Otherwise component registry to its parent will be deleted if any dependency changes
  const dataProviderRef = useRef();
  const historyRef = useRef();
  const optionsObjRef = useRef();
  const selectedValuesInResourceRef = useRef();
  const onOptionSelectRef = useRef();
  const onOptionUnselectRef = useRef();
  const setHistoryRef = useRef();

  onOptionSelectRef.current = props.onOptionSelect;
  onOptionUnselectRef.current = props.onOptionUnselect;
  setHistoryRef.current = props.setHistory;
  optionsObjRef.current = optionsObj;
  dataProviderRef.current = resource.dataProvider;
  historyRef.current = history;
  selectedValuesInResourceRef.current = selectedValuesInResource;

  const saveRefCallback = useCallback(element => saveRef(id, element), [id, saveRef]);

  const handleMouseOver = useCallback(
    (evt, optionId) => {
      if (optionId !== highlightedChild?.id) {
        // remove previous highlighted and set new highlighted
        onSetHighlighted(evt, {pathArr: [...pathArr, id], newHighlightedId: optionId});
      }
    },
    [id, onSetHighlighted, highlightedChild, pathArr],
  );

  const handleOptionSelect = useCallback(
    (evt, {resourceId, value, pathArr} = {}) => {
      const isStaticOption = optionsObjRef.current.staticsOptions?.matches?.some(option => _.isEqual(value, option));
      const isCreateOption = value.id === ADD_NEW_ID;
      const isPartialOption = value.isPartial;

      // Update kvPairs history
      const key = historyKey ?? (typeof dataProviderRef.current === 'string' ? dataProviderRef.current : resourceId);
      const shouldUpdateKvPairs =
        enableHistory &&
        !isStaticOption &&
        !isCreateOption &&
        !isPartialOption &&
        // Skip updating history object when value is already present as it will reshuffle list to move selected value to the top
        !historyRef.current[key]?.some(val => _.isEqual(val, value));

      if (shouldUpdateKvPairs) {
        setHistoryRef.current(draft => {
          return {
            ...draft,
            [key]: [value, ...(draft[key] ?? [])].splice(0, 25),
          };
        });
      }

      onOptionSelectRef.current(evt, {resourceId, value, pathArr});
    },
    [enableHistory, historyKey],
  );

  const handleClick = useCallback(
    (evt, optionId) => {
      const option = getOptionById(allOptionsRef.current, optionId, idPath);

      const optionIsSelected = Boolean(selectedValuesInResourceRef.current.some(value => _.isEqual(value, option)));

      if (optionIsSelected) {
        // If value is already selected then unselect it on click
        return onOptionUnselectRef.current(evt, new Map([[id, [option]]]));
      }

      return handleOptionSelect(evt, {resourceId: id, value: option, idPath});
    },
    [id, idPath, handleOptionSelect],
  );

  const handleKeyDown = useCallback(
    evt => {
      if ((evt.keyCode === KEY_RETURN || evt.keyCode === KEY_TAB) && highlightedChildRef.current) {
        handleClick(evt, highlightedChildRef.current.id);
      }
    },
    [highlightedChildRef, handleClick],
  );

  useEffect(() => {
    //code tag #register/unregister
    const unregister = registerHandlers(id, {setHighlightedChild, resetHighlightedChild, keyDown: handleKeyDown}); //Register

    return () => unregister();
  }, [registerHandlers, id, setHighlightedChild, resetHighlightedChild, handleKeyDown]);

  useEffect(() => {
    // Resolve loading done to optionPanel to set initial width
    if (!optionsLoaded) {
      //Loading is in progress
      return;
    }

    onInitialLoadDone?.();

    return onInitialLoadReject?.();
  }, [optionsLoaded, onInitialLoadDone, onInitialLoadReject]);

  useEffect(() => {
    if (fetchIsNotNeeded) {
      // initial render of static options Or recently used options
      return;
    }

    (async () => {
      try {
        fetchTaskRef.current = fetcher.fork(fetchResource, {resource, apiOptions, searchIndex});

        const response = await fetchTaskRef.current;

        if (typeof response === 'string' && response.includes('CANCEL')) {
          return;
        }

        const {staticsOptions, dataProviderOptions} = response;

        filterableOptionsRef.current = [
          ...((Array.isArray(staticsOptions) ? staticsOptions : staticsOptions?.matches) ?? []),
          ...((Array.isArray(dataProviderOptions) ? dataProviderOptions : dataProviderOptions.matches) ?? []),
        ];

        const {createHint, allowCreate, allowPartial, name, optionProps: {textPath} = {}} = resource;

        const exactMatch = getOptionByText(filterableOptionsRef.current, query, textPath);

        let createOption;
        let partialOption;

        if (query.trim()) {
          if (allowPartial) {
            partialOption = {value: query, isPartial: true};
          } else {
            const showCreateOption =
              Boolean(query.trim()) &&
              !exactMatch &&
              (typeof allowCreate === 'function' ? allowCreate(query, filterableOptionsRef.current) : allowCreate);

            if (showCreateOption) {
              const hintText = `(${createHint ?? `${intl('Common.New')} ${name}`})`;

              createOption = {id: ADD_NEW_ID, value: `${query} ${hintText}`};
            }
          }
        }

        if (fetchTaskRef.current) {
          // checking fetchTaskRef.current prevents react state update on an unmounted component
          // fetchTaskRef.current is cleared in the cleanup function on unmount
          fetchTaskRef.current = null;

          /* Include createOption and partialOption as a separate key, to prevent including it in:
              1. Suggestion - Adding it to filterableOptionsRef would always return suggestion text as an empty string
                              because of its exact match as query string
              2. FilterOption - create new option and partialOption should not be passed to filterOption callback
           */
          setOptionsObj({
            staticsOptions,
            dataProviderOptions,
            ...(createOption && {createOption}),
            ...(partialOption && {partialOption}),
          });
          setError(null);
        }
      } catch (error) {
        if (fetchTaskRef.current) {
          fetchTaskRef.current = null;
          filterableOptionsRef.current = [];
          setOptionsObj({});
          setError(error.message ?? error);
        }
      }
    })();

    return () => {
      if (fetchTaskRef.current) {
        fetcher.cancel(fetchTaskRef.current);
        fetchTaskRef.current = null;
      }
    };
  }, [apiOptions, enableHistory, query, resource, searchIndex, fetcher, fetchIsNotNeeded]);

  useEffect(() => {
    // Resolve parent promise with new highlighted element
    if (fetchTaskRef.current) {
      // options loading is in progress
      return;
    }

    const {onSearchDone, onSearchReject} = searchParams;

    if (error || !query) {
      return onSearchReject?.();
    }

    const {id, optionProps: {idPath, textPath, allowMultipleSelection, noCheckbox, filterOption} = {}} = resource;

    let filteredOptions;

    if (noCheckbox || !allowMultipleSelection) {
      // Filter selected values from dropdown options in the case of singleSelect or noCheckbox
      filteredOptions = filterableOptionsRef.current.filter(option => !selectedValuesInResource.includes(option));
    }

    if (typeof onSearchDone === 'function') {
      const suggestionResult = {id};

      filteredOptions =
        typeof filterOption === 'function'
          ? filterableOptionsRef.current.filter(filterOption)
          : filterableOptionsRef.current;

      const createOrPartialOption = optionsObj.createOption || optionsObj.partialOption;

      if (filteredOptions.length > 0 || createOrPartialOption) {
        // If only option displayed is to Add new object then simply pass suggestion as empty string
        // because we do not want to add create new hint text as suggestion
        suggestionResult.suggestion = getSuggestionText(query, filteredOptions) ?? '';
        suggestionResult.primaryMatch = {
          id: getOptionId(filteredOptions[0] ?? createOrPartialOption, idPath),
          text: getOptionText(filteredOptions[0] ?? createOrPartialOption, textPath),
        };
        suggestionResult.pathArr = [...pathArr, id];
      }

      onSearchDone(suggestionResult);
    }

    return () => onSearchReject?.();
  }, [error, optionsObj, pathArr, query, resource, searchParams, selectedValuesInResource]);

  if (hidden) {
    return null;
  }

  filterableOptionsRef.current =
    noCheckbox || !allowMultipleSelection
      ? filterableOptionsRef.current.filter(option => !selectedValuesInResource.includes(option))
      : filterableOptionsRef.current;

  const filteredOptions =
    typeof filterOption === 'function'
      ? filterableOptionsRef.current.filter(filterOption)
      : filterableOptionsRef.current;

  // number of filtered options will be subtracted from the total and matched count
  let matchedTotal = filteredOptions.length - filterableOptionsRef.current.length; // negative offset as a result of filter

  const {staticsOptions, dataProviderOptions} = optionsObj;

  if (Array.isArray(dataProviderOptions)) {
    matchedTotal += dataProviderOptions.length;
  } else if (dataProviderOptions?.num_matches) {
    matchedTotal += dataProviderOptions.num_matches;
  }

  if (Array.isArray(staticsOptions)) {
    matchedTotal += staticsOptions.length;
  } else if (staticsOptions?.num_matches) {
    matchedTotal += staticsOptions.num_matches;
  }

  const matchedDisplayed = filteredOptions.length;

  matchedTotal = Math.max(matchedDisplayed, matchedTotal);

  const title =
    format?.(name, {matchedDisplayed, matchedTotal}) ??
    (!enableHistory || query
      ? matchedTotal
        ? intl('ObjectSelector.MatchedCount', {name, matchedDisplayed, matchedTotal})
        : name
      : intl('Common.RecentlyUsed', {name}));

  const subTitle =
    noSubtitle || query
      ? null
      : // Options are contructed based on user input, for e.g. port and/or protocol
        intl(
          typeof resource.statics === 'function'
            ? 'ObjectSelector.TypeToShowObject'
            : 'ObjectSelector.TypeToSearchObject',
          {
            object: name,
          },
        );

  const infoPanelContent = typeof infoPanel === 'function' ? infoPanel(query) : infoPanel;

  const style = {};

  if (template) {
    style.display = 'grid';
    style.gridTemplate = template;
  }

  if (isGridArea) {
    style.gridArea = id;
  }

  allOptionsRef.current = [optionsObj.partialOption, optionsObj.createOption, ...filteredOptions].filter(Boolean);

  const showLoadingSkeleton = // Show loading skeleton only for initial load, skip for statics and recently used render
    (fetchTaskRef.current === undefined && !fetchIsNotNeeded) ||
    Boolean(fetchTaskRef.current && dataProvider && onInitialLoadDone);

  return (
    <div
      onKeyDown={handleKeyDown}
      ref={saveRefCallback}
      {...(_.isEmpty(style) ? {} : {style})}
      data-tid="comp-selector-listresource"
    >
      {showLoadingSkeleton ? (
        <motion.div animate={{opacity: [null, 0.3, 1]}} transition={{repeat: Infinity, duration: 1.5, ease: 'linear'}}>
          {_.times(pathArr.includes(CATEGORYPANEL_ID) ? 1 : 5, index => (
            <div key={index}>
              <div className={theme.loadingLineLong} />
              <div className={theme.loadingLine} />
            </div>
          ))}
        </motion.div>
      ) : (
        <>
          {infoPanelContent || error ? (
            <InfoPanel title={title} theme={theme} themePrefix="drawer-">
              {infoPanelContent}
              {error && <Notifications>{[{type: 'error', message: error}]}</Notifications>}
            </InfoPanel>
          ) : noTitle ? null : (
            <div className={theme.infoPanel}>
              {title}
              <div className={theme.resourceSubtitle}>{subTitle}</div>
            </div>
          )}
          {!noEmptyBanner && allOptionsRef.current.length === 0 ? (
            <Banner type="plain" theme={theme} themePrefix="emptyMessage-">
              {intl('Common.NoData')}
            </Banner>
          ) : (
            allOptionsRef.current.map(option => {
              // Set selected to boolean value in for checkbox options i.e. multiple highlighted and noCheckbox is false
              // Otherwise set it to undefined to skip rendering checkbox in option
              const isSelected = Boolean(selectedValuesInResource.some(value => _.isEqual(value, option)));

              const optionProps = {
                theme,
                saveRef: saveChildRef,
                onClick: handleClick,
                onMouseOver: handleMouseOver,
              };

              optionProps.id = getOptionId(option, idPath);

              optionProps.onMouseLeave = optionProps.id === highlightedChild?.id ? onMouseLeave : undefined;
              optionProps.highlighted = optionProps.id === highlightedChild?.id;
              // Set checked prop to undefined to skip checkbox rendering when:
              // 1) option is "create new object" 2) noCheckbox prop is true 3) resource is singleSelect
              optionProps.checked =
                optionProps.id === ADD_NEW_ID || noCheckbox || !allowMultipleSelection ? undefined : isSelected;
              optionProps.insensitive = insensitive;

              if (optionProps.checked === undefined && isSelected) {
                if (visibilityWhenSelected === 'insensitive') {
                  optionProps.insensitive = true;
                } else if (visibilityWhenSelected === 'disabled') {
                  optionProps.disabled = true;
                } else if (visibilityWhenSelected === 'hidden' || optionsObj.history) {
                  optionProps.hidden = true;
                }
              }

              // Option hint text i.e. Substring matching query are highlighted with bold style
              const optionText = option.isPartial ? (
                <span className={theme.partialOption}>
                  {`${option.value} (${intl('ObjectSelector.ShowAllMatches')})`}
                </span>
              ) : (
                getHighlightedText({query, text: getOptionText(option, textPath), bold: true})
              );

              optionProps.text = (() => {
                if (format) {
                  return typeof format === 'function' ? format(option) : format;
                }

                if (optionProps.id === ADD_NEW_ID) {
                  return (
                    <div className={theme.createOption} data-tid="comp-selector-create-option">
                      <Icon name="add" theme={theme} themePrefix="createOption-" />
                      {optionText}
                    </div>
                  );
                }

                if (isPill) {
                  const props = {
                    theme,
                    ...(typeof pillProps === 'function'
                      ? pillProps(option, optionProps, values)
                      : {...pillProps, insensitive: optionProps.insensitive, disabled: optionProps.disabled}),
                  };

                  if (props.disabled) {
                    Object.assign(props, {themePrefix: 'pillDisabled-'});
                  }

                  return <Pill {...props}>{optionText}</Pill>;
                }

                return optionText;
              })();

              if (tooltipProps) {
                const {appearWhen, content, ...restProps} = tooltipProps;

                if (!appearWhen || values.has(appearWhen)) {
                  optionProps.tooltip = typeof content === 'function' ? content(option, optionProps, values) : content;
                  optionProps.tooltipProps = restProps;
                }
              }

              return <Option key={optionProps.id} {...optionProps} />;
            })
          )}
        </>
      )}
    </div>
  );
}
