/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import apiSaga from 'api/apiSaga';
import {call, put, select, all} from 'redux-saga/effects';
import {RedirectError} from 'errors';
import {getWorkloadOSfamilies} from 'containers/Workload/WorkloadSaga';
import {fetchPairingProfiles} from 'containers/PairingProfile/List/PairingProfileListSaga';
import {getPairingProfilePairAccessList} from './PairingProfilePairState';
import {isWorkloadManagerReadOnlyClusterInsensitive} from 'containers/User/UserState';
import {hrefUtils} from 'utils';
import {fetchPairingProfileItem} from '../PairingProfileItemSaga';
import {cachedResponses} from 'api/apiCache';

export function* fetchPairingKey({id}) {
  // Pairing Key request will never be cached since all POST request will return a unique activation code
  yield call(apiSaga, 'pairing_profile.pairing_key', {
    params: {pairing_profile_id: id},
    *onDone({data}) {
      yield put({type: 'PAIRING_PROFILE_PAIR_KEY', data});

      // Invalid pairing profile instance
      cachedResponses.removeByMethodName('pairing_profiles.get_instance');
      // Invalidate pairing profile list
      cachedResponses.removeByMethodName('pairing_profiles.get_collection');
    },
  });
}

export function* fetchPairingKeyList({params}, refetch = false) {
  // Check User's permission
  const userReadOnly = yield select(isWorkloadManagerReadOnlyClusterInsensitive);

  if (!userReadOnly) {
    let pairingProfile;
    let {id} = params;

    // Get the Pairing Profile List to display the proper dropdown options.
    // Use 'yield call' to make sure when calling 'yield select(getPairingProfilePairAccessList)'
    // data exist especially when there is no 'id' passed in.
    // Note: The pairing profile get collection API is extremely slow
    yield call(fetchPairingProfiles);

    try {
      if (id) {
        // id exist e.g. 'pairingprofiles/pair/?id=123'
        pairingProfile = yield call(fetchPairingProfileItem, {params}, refetch);
      } else {
        // id doesn't exist e.g. 'pairingprofiles/pair/'
        const pairingProfileList = yield select(getPairingProfilePairAccessList);

        // Get the is_default: true instance from the pairing profile write-able list first
        pairingProfile = pairingProfileList.find(profile => profile.is_default);

        // When default pairing profile doesn't exist then retrieve the first from the list
        if (!pairingProfile && pairingProfileList.length) {
          pairingProfile = pairingProfileList[0];
        }

        if (pairingProfile) {
          id = hrefUtils.getId(pairingProfile.href);
          // Update the current pairing profile instance
          yield put({type: 'PAIRING_PROFILES_GET_INSTANCE', data: pairingProfile});
        }
      }

      yield all([
        // Get specific key
        call(fetchPairingKey, {id}),
        // Get OS Families
        call(getWorkloadOSfamilies, {query: {pairing_profile_id: id}}),
      ]);
    } catch (error) {
      if (error instanceof RedirectError) {
        throw error;
      } else {
        throw new RedirectError({to: 'pairingProfiles.list', proceedFetching: true, thisFetchIsDone: true});
      }
    }
  } else {
    throw new RedirectError({to: 'pairingProfiles.list', proceedFetching: true, thisFetchIsDone: true});
  }
}
