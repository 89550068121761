/**
 * Copyright 2021 Illumio, Inc. All Rights Reserved.
 */
import cx from 'classnames';
import {useRef, useCallback, useState, useEffect, type ReactElement} from 'react';
import {type Theme} from '@css-modules-theme/react';
import {Tooltip, Checkbox} from 'components';
import {tidUtils} from 'utils';
import type {MouseEventLike, MouseEventLikeHandler} from 'utils/dom';
import type {TooltipProps} from 'components/Tooltip/Tooltip';
import type {UnionOmit} from 'utils/types';

type MouseEventCallback = (event: MouseEventLike, id: string | number) => void;

type OptionProps = {
  theme: Theme;
  saveRef(id: string | number, element: HTMLLIElement | null): void;
  tid?: string;
  id: string | number;
  text: ReactElement;
  active?: boolean;
  checked?: boolean;
  disabled?: boolean;
  hidden?: boolean;
  insensitive?: boolean;
  highlighted?: boolean;
  onClick: MouseEventCallback;
  onMouseOver: MouseEventCallback;
  onMouseLeave: MouseEventLikeHandler;
  tooltip?: TooltipProps['content'];
  tooltipProps?: UnionOmit<TooltipProps, 'content'>;
};

export default function Option(props: OptionProps): JSX.Element | null {
  const {
    theme,
    tid,
    id,
    text,
    active,
    disabled,
    hidden,
    insensitive,
    highlighted,
    onClick,
    onMouseOver,
    onMouseLeave,
    checked,
    tooltip,
    tooltipProps = {},
    saveRef,
  } = props;

  const elementRef = useRef();
  const [tooltipIsVisible, setTooltipIsVisible] = useState(false);

  const saveRefCallback = useCallback(
    element => {
      elementRef.current = element;
      saveRef(id, element);
    },
    [id, saveRef],
  );

  const {fast = false, delay: [delayShow, delayHide] = [], ...restTooltipProps} = tooltipProps;
  const handleMouseOver = useCallback(evt => onMouseOver(evt, id), [id, onMouseOver]);
  const handleClick = useCallback(evt => onClick(evt, id), [id, onClick]);

  useEffect(() => {
    // Set tooltip visible with a delay
    if (!tooltip || !highlighted) {
      //Skip if: option is not highlighted or no tooltip
      return;
    }

    const delay = fast ? 100 : delayShow ?? 500;

    // Add delay to show tooltip
    const showTooltipTimeout = setTimeout(() => {
      setTooltipIsVisible(true); // show tooltip after a delay
    }, delay);

    return () => clearTimeout(showTooltipTimeout);
  }, [tooltip, highlighted, fast, delayShow]);

  useEffect(() => {
    // Hide tooltip with a delay
    if (!tooltipIsVisible || highlighted) {
      //Skip if: Tooltip was not visible Or option is still in highlighted
      return;
    }

    const delay = fast ? 0 : delayHide ?? delayShow ?? 0;

    // Add delay to show/hide tooltip
    const hideTooltipTimeout = setTimeout(() => {
      setTooltipIsVisible(false); //hide tooltip after a delay
    }, delay);

    return () => clearTimeout(hideTooltipTimeout);
  }, [tooltipIsVisible, highlighted, fast, delayShow, delayHide]);

  if (hidden) {
    return null;
  }

  const isInsensitive = insensitive || disabled;

  const optionTheme = cx(theme.option, {
    [theme.active]: active && !highlighted,
    [theme.highlighted]: highlighted,
    [theme.disabled]: disabled,
    [theme.clickable]: !isInsensitive,
  });

  const tids = [tid];

  if (disabled) {
    tids.push('disabled');
  }

  if (insensitive) {
    tids.push('insensitive');
  }

  return (
    <>
      <li
        data-tid={tidUtils.getTid('comp-selector-option', tids)}
        className={optionTheme}
        tabIndex={-1}
        onClick={isInsensitive ? undefined : handleClick}
        ref={saveRefCallback}
        onMouseOver={isInsensitive ? undefined : handleMouseOver}
        onMouseLeave={onMouseLeave}
      >
        {typeof checked === 'boolean' ? (
          <Checkbox notChangeable theme={theme} themePrefix="option-" checked={checked} label={text} />
        ) : (
          text
        )}
      </li>
      {tooltip ? (
        <Tooltip
          reference={elementRef.current}
          content={tooltip}
          visible={tooltipIsVisible}
          duration={[250, 0]}
          {...restTooltipProps}
        />
      ) : null}
    </>
  );
}
