/**
 * Copyright 2021 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import {useSelector} from 'react-redux';
import {fetchIKECertificate} from './IKECertificatesSaga';
import IKECertificatesReducers, {getIKECertificatePage, isIKECertificateEnabled} from './IKECertificatesState';
import {HeaderProps} from 'containers';
import {AttributeList, StatusIcon, ToolBar, ToolGroup, Button} from 'components';

IKECertificates.viewName = () => intl('Settings.IKECertificates');
IKECertificates.reducers = IKECertificatesReducers;
IKECertificates.isAvailable = isIKECertificateEnabled;
IKECertificates.prefetch = fetchIKECertificate;

export default function IKECertificates() {
  const {ikeCertificate} = useSelector(getIKECertificatePage);

  return (
    <>
      <HeaderProps title={intl('Settings.IKECertificates')} />
      <>
        <ToolBar justify="left">
          <ToolGroup>
            <Button.Link icon="edit" tid="edit" text={intl('Common.Edit')} link="ikecertificates.edit" />
          </ToolGroup>
        </ToolBar>
        <StatusIcon status="info" label={intl('Settings.VensIKEAuthenticationInfo')} noTextColor />
        <AttributeList>
          {[
            {
              key: intl('Settings.CertificateIssuer'),
              value: ikeCertificate,
            },
          ]}
        </AttributeList>
      </>
    </>
  );
}
