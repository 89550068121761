/**
 * Copyright 2016 Illumio, Inc. All Rights Reserved.
 */
import cx from 'classnames';
import {PureComponent, createRef, type ComponentPropsWithRef, type MutableRefObject} from 'react';
import {tidUtils} from 'utils';
import styles from './Icon.css';
import {mixThemeWithProps, type ThemeProps} from '@css-modules-theme/react';
import {Tooltip} from 'components';
import {fileNames, tidByName, getTitle} from './IconUtils';
import type {ReactStrictNode} from 'utils/types';
import type {IconName} from './IconName';
import type {TooltipProps} from 'components/Tooltip/Tooltip';

export type {IconName};

const iconNameRegex = /^.*\/([\w-]+)\.svg$/i;

export const icons = fileNames.reduce((acc: IconName[], name: string) => {
  const match = name.match(iconNameRegex);

  if (match) {
    acc.push(match[1] as IconName);
  }

  return acc;
}, []);

export type IconProps = {
  //
  /** Id of svg symbol */
  name: IconName;

  hidden?: boolean;

  autoTooltip?: boolean;
  title?: string; // Custom title, name is used if not specified

  /**
   * Additional tid that will be added to default one (that already includes name)
   * For instance, if name is 'user' and tid is 'hoho': 'comp-icon comp-icon-user comp-icon-hoho'
   */
  tid?: string;

  tooltip?: ReactStrictNode;
  tooltipProps?: TooltipProps;

  position?: 'before' | 'after' | 'inbetween';
} & ThemeProps &
  ComponentPropsWithRef<'span'>;

export default class Icon extends PureComponent<IconProps> {
  static defaultProps = {
    autoTooltip: false,
  };

  element: HTMLSpanElement | null = null;
  iconRef: MutableRefObject<Element | null>;

  constructor(props: IconProps) {
    super(props);

    this.iconRef = createRef();
    this.saveRef = this.saveRef.bind(this);
  }

  static getTitle = getTitle;

  private saveRef(element: HTMLSpanElement | null) {
    this.element = element;
    this.iconRef.current = element;
  }

  render() {
    const {name, position, hidden, autoTooltip, title, tid, theme, tooltip, tooltipProps, ...elementProps} =
      mixThemeWithProps(styles, this.props);
    const tooltipText = autoTooltip ? getTitle(name) : tooltip;

    elementProps.className = cx(theme.icon, {
      [theme.hidden]: hidden,
      [theme.before]: position === 'before',
      [theme.after]: position === 'after',
      [theme.inbetween]: position === 'inbetween',
    });

    // set aria-label on icon
    if (typeof tooltip === 'string') {
      elementProps['aria-label'] = tooltip;
    } else if (title) {
      elementProps['aria-label'] = title;
    } else {
      elementProps['aria-label'] = getTitle(name);
    }

    elementProps.ref = this.saveRef;
    elementProps['data-tid'] = tid ? tidUtils.getTid('comp-icon', [name, tid]) : tidByName(icons).get(name);

    return (
      <>
        <span {...elementProps}>
          <svg className={theme.svg}>
            <use xlinkHref={`#${name}`} />
          </svg>
        </span>
        {tooltipText ? <Tooltip content={tooltipText} {...tooltipProps} reference={this.iconRef} /> : null}
      </>
    );
  }
}

// Check that all icons have default title in dev
if (__DEV__) {
  for (const name of icons) {
    if (!getTitle(name)) {
      console.log(`%cNo default title for icon %c${name}`, 'color:orange', 'color:red');
    }
  }
}
