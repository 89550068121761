/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import * as PropTypes from 'prop-types';
import {PureComponent} from 'react';
import {mixThemeWithProps} from '@css-modules-theme/react';
import {Icon, Link, Tooltip} from 'components';
import {hrefUtils, tidUtils} from 'utils';
import {roundNumber, getVulnerabilityByScore} from './VulnerabilityUtils';
import styles from './Vulnerability.css';

export default class Vulnerability extends PureComponent {
  static propTypes = {
    severityScore: PropTypes.number.isRequired,
    vulnerabilityExposureScore: PropTypes.number,
    internetExposure: PropTypes.bool,
    exposureApplicable: PropTypes.bool,

    title: PropTypes.string,

    // Link parameters, if pill is clickable to navigate
    link: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    // Alternatively, instead of 'link' you can just specify id or href of workload with vulnerability,
    // like id "85914bd3-2075-4d39-8b17-150d09e0cfe7" or href "/orgs/2/workloads/85914bd3-2075-4d39-8b17-150d09e0cfe7",
    // In that case pill will automatically create link to {{to: 'workloads.item.vulnerabilities', params: {id}}},
    id: PropTypes.string,
    href: PropTypes.string,

    // If it's not a link, you can specify custom onClick
    onClick: PropTypes.func,

    // tooltip props
    tooltip: PropTypes.node,
    tooltipProps: PropTypes.object,
  };

  static defaultProps = {
    internetExposure: false,
    title: intl('Vulnerability.TotalVEScore'),
  };

  constructor(props) {
    super(props);

    this.saveRef = this.saveRef.bind(this);
    this.handleKeyUp = this.handleKeyUp.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  saveRef(element) {
    if (element && (this.props.link || this.props.id || this.props.href)) {
      this.element = element.element;
    } else {
      this.element = element;
    }
  }

  handleKeyDown(evt) {
    if (evt.key === ' ' || evt.key === 'Enter') {
      evt.preventDefault();
    }

    if (this.props.onKeyDown) {
      this.props.onKeyDown(evt);
    }
  }

  handleKeyUp(evt) {
    if (this.props.onKeyUp) {
      this.props.onKeyUp(evt);
    }

    if (evt.key === ' ' || evt.key === 'Enter') {
      // Emulate click on Space and Enter
      this.props.onClick(evt);
    }
  }

  render() {
    const {
      link,
      id,
      href,
      theme,
      tooltip,
      tooltipProps,
      title,
      severityScore,
      vulnerabilityExposureScore,
      internetExposure,
      exposureApplicable,
      ...elementProps
    } = mixThemeWithProps(styles, this.props);

    const severity = getVulnerabilityByScore(severityScore, 'severity');
    const exposureState = exposureApplicable ? (
      <Icon name="syncing" theme={theme} themePrefix="syncing-" />
    ) : (
      intl('Common.NA')
    );
    let classes = theme[severity];

    let ElementType;

    elementProps.ref = this.saveRef;
    elementProps['data-tid'] = tidUtils.getTid('comp-vulnerability', severity);
    elementProps['aria-label'] = typeof tooltip === 'string' ? tooltip : title;

    if (link || id || href) {
      // If Label is a Link, assign classes string to .link theme and link object properties to route properties.
      // It will become focusable and activatable by space/enter automatically
      ElementType = Link;
      elementProps.theme = Link.getLinkTheme(`${classes} ${theme.clickable}`);

      if (link) {
        if (typeof link === 'string') {
          elementProps.to = link;
        } else {
          Object.assign(elementProps, link);
        }
      } else {
        elementProps.to = 'workloads.item.vulnerabilities';
        elementProps.params = {id: id || hrefUtils.getId(href)};
      }
    } else {
      ElementType = 'div';

      if (elementProps.onClick) {
        // If pill is not a link but has onClick handler, make it focusable (tabIndex) and control keys manually
        classes += ` ${theme.clickable}`;
        elementProps.tabIndex = elementProps.tabIndex || '0';

        elementProps.onKeyUp = this.handleKeyUp;
        elementProps.onKeyDown = this.handleKeyDown;
      }

      elementProps.className = classes;
    }

    const vulnerability = (
      <ElementType {...elementProps}>
        {vulnerabilityExposureScore === null ? exposureState : roundNumber(vulnerabilityExposureScore)}
        {internetExposure && <Icon name="internet" theme={theme} themePrefix="internet-" />}
      </ElementType>
    );

    return tooltip ? <Tooltip {...{content: tooltip, ...tooltipProps}}>{() => vulnerability}</Tooltip> : vulnerability;
  }
}
