/**
 * Copyright 2021 Illumio, Inc. All Rights Reserved.
 */
import cx from 'classnames';
import {useCallback} from 'react';
import {Pill, Icon, Tooltip} from 'components';
import styleUtils from 'utils.css';

export default function SelectedValue(props) {
  const {
    theme,
    saveRef,
    disabled,
    insensitive,
    id,
    text,
    value,
    format,
    resourceName,
    hideResourceName,
    error,
    highlighted,
    isPill,
    pillProps,
    onClick,
    onRemove,
    onMouseOver,
    onMouseLeave,
  } = props;

  const saveRefCallback = useCallback(element => saveRef(id, element), [id, saveRef]);
  const handleRemove = useCallback(evt => onRemove(evt, id), [id, onRemove]);
  const handleMouseOver = useCallback(evt => onMouseOver(evt, id), [id, onMouseOver]);

  let val = (
    <div className={cx(theme.selectedValueText, {[theme.clickable]: onClick})}>
      {format?.(value) ??
        (hideResourceName ? (
          text
        ) : (
          <>
            {`${resourceName}: `}
            <span className={styleUtils.bold}>{text}</span>
          </>
        ))}
    </div>
  );

  if (error) {
    val = (
      <Tooltip content={error} visible>
        {val}
      </Tooltip>
    );
  }

  const isInsensitive = insensitive || disabled;

  return (
    <div
      ref={saveRefCallback}
      onClick={onClick}
      onMouseOver={isInsensitive ? undefined : handleMouseOver}
      onMouseLeave={onMouseLeave}
      data-tid="comp-selected-value"
    >
      {isPill ? (
        <Pill
          onClose={isInsensitive ? undefined : handleRemove}
          theme={theme}
          error={typeof error === 'string'}
          themePrefix={highlighted ? 'pillHighlighted-' : undefined}
          insensitive={insensitive}
          disabled={disabled}
          {...(typeof pillProps === 'function' ? pillProps(value) : pillProps)}
        >
          {val}
        </Pill>
      ) : (
        <div className={cx(styleUtils.gapInline, styleUtils.gapHorizontal, {[theme.highlighted]: highlighted})}>
          {val}
          <Icon name="close" theme={theme} onClick={isInsensitive ? undefined : handleRemove} themePrefix="close-" />
        </div>
      )}
    </div>
  );
}
