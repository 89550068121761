/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import {useContext} from 'react';
import * as PropTypes from 'prop-types';
import {mixThemeWithProps} from '@css-modules-theme/react';
import {ModalContext} from './ModalUtils';
import {Icon} from 'components';
import styles from './ModalHeader.css';

ModalHeader.propTypes = {
  title: PropTypes.node, // Any valid react element can be a title
  noCloseIcon: PropTypes.bool, // Whether hide (true) or show close icon on header (true - default)
};

export default function ModalHeader(props) {
  const {title, theme, noCloseIcon, ...elementProps} = mixThemeWithProps(styles, props);
  const {onClose} = useContext(ModalContext);

  elementProps.className = theme.header;
  elementProps['data-tid'] = 'comp-dialog-header';

  return (
    <div {...elementProps}>
      {title ? (
        <div className={theme.title} data-tid="comp-dialog-title">
          {title}
        </div>
      ) : null}
      {!noCloseIcon && typeof onClose === 'function' && (
        <div className={theme.close} onClick={onClose} data-tid="comp-dialog-close" title={intl('Common.Close')}>
          <Icon name="close" />
        </div>
      )}
    </div>
  );
}
