/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import {ipUtils} from 'utils';
import type {Address6, Address4} from 'ip-address';
import type {BigInteger} from 'jsbn';

interface IpRange {
  address: string;
  fromIp: {
    bigInt: BigInteger;
    helper: Address4 | Address6;
    ip: string;
    ip4: boolean;
    ipString: string;
    isValid: boolean;
    version?: number;
  };
  from_ip: string;
  removed?: boolean;
  text: string;
  type: string;
  error?: string;
  fqdn?: string;
  description?: string;
  to_ip?: string;
  exclusion?: boolean;
}

/** Nominal Types */
type IpRanges = IpRange & {original?: IpRange};

type UpdateRange = Pick<IpRange, 'from_ip' | 'to_ip' | 'description' | 'exclusion'>;

type FqdnsRange = Pick<IpRange, 'fqdn' | 'description'>;

interface IpListInstance {
  description: string;
  name: string;
  fromIp: IpRange['fromIp'];
  ip_ranges: IpRanges[];
  text: IpRange['text'];
  type: IpRange['type'];
}

export interface IpList {
  name: IpListInstance['name'];
  description: IpListInstance['description'];
  ip_ranges: UpdateRange[];
  fqdns: FqdnsRange[];
}

export const ipListsGetUpdate = (ipList?: IpListInstance): IpList | undefined => {
  if (!ipList) {
    return;
  }

  const result: IpList = {
    name: _.trim(ipList.name),
    description: _.trim(ipList.description),
    ip_ranges: [],
    fqdns: [],
  };

  const ipRanges: UpdateRange[] = [];
  const fqdns: FqdnsRange[] = [];

  if (ipList.ip_ranges) {
    const validData = ipList.ip_ranges.filter(range => !range.error && !range.removed && (range.from_ip || range.fqdn));

    validData.forEach(range => {
      if (range.from_ip) {
        const updateRange: UpdateRange = {
          from_ip: range.from_ip,
        };

        if (range.to_ip) {
          updateRange.to_ip = range.to_ip;
        }

        if (range.description) {
          updateRange.description = range.description;
        }

        if (range.exclusion) {
          updateRange.exclusion = range.exclusion;
        }

        ipRanges.push(updateRange);
      } else if (range.fqdn) {
        const fqdn: FqdnsRange = {fqdn: range.fqdn};

        if (range.description) {
          fqdn.description = range.description;
        }

        fqdns.push(fqdn);
      }
    });
  }

  result.ip_ranges = ipRanges;

  result.fqdns = fqdns;

  return result;
};

export const ipListsValidateValues = (ipList?: IpListInstance): IpList | string => {
  const update = ipListsGetUpdate(ipList);

  if ((update?.ip_ranges && update.ip_ranges.length) || (update && update.fqdns && update.fqdns.length)) {
    return update;
  }

  return 'invalid';
};

export const ipListsIsEmpty = (ipList?: IpList): boolean | null =>
  !ipList ||
  ((!ipList.name || ipList.name.match(/New IP List/)) &&
    !ipList.description &&
    (!ipList.ip_ranges || ipList.ip_ranges.length === 0));

export const ipListsHasChanges = (old: IpList, updates: IpList, ignoreName: boolean): boolean => {
  let rangeChange = false;

  if (ipListsIsEmpty(old) && ipListsIsEmpty(updates)) {
    return false;
  }

  if (ipListsIsEmpty(old) && !ipListsIsEmpty(updates)) {
    return true;
  }

  if ((!ignoreName && old.name !== updates.name) || (old.description || '') !== (updates.description || '')) {
    return true;
  }

  if (old.ip_ranges.length !== updates.ip_ranges.length) {
    return true;
  }

  old.ip_ranges.forEach((oldRange, index) => {
    if (!ipUtils.areIpRangesEqual(oldRange, updates.ip_ranges[index])) {
      rangeChange = true;
    }
  });

  /** there are different numbers of fqdns */
  if (old.fqdns.length !== updates.fqdns.length) {
    return true;
  }

  old.fqdns.forEach((oldValue, index) => {
    if (oldValue.fqdn !== updates.fqdns[index].fqdn) {
      rangeChange = true;
    }
  });

  return rangeChange;
};
