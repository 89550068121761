/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import * as PropTypes from 'prop-types';
import SizeWatcher from 'react-size-watcher';
import {createRef, PureComponent} from 'react';
import {CSSTransition} from 'react-transition-group';
import {mixThemeWithProps} from '@css-modules-theme/react';
import styles from './ProgressBar.css';

export default class ProgressBar extends PureComponent {
  // All extra props will be passed down to rendered SizeWatcher as is.
  static propTypes = {
    breakpoints: PropTypes.array, // Custom breakpoints
    fillPercent: PropTypes.number,
    threshold: PropTypes.number,
    tid: PropTypes.string,
  };

  static defaultProps = {
    breakpoints: [
      {props: {className: styles.xLarge}},
      {maxWidth: 500, props: {className: styles.large}},
      {maxWidth: 360, props: {className: styles.medium}},
      {maxWidth: 220, props: {className: styles.small}},
      {maxWidth: 80, props: {className: styles.xSmall}},
    ],
    fillPercent: 100,
    threshold: 20,
    tid: 'statusbar',
  };

  progressRef = createRef();

  render() {
    const {tid, theme, justify, fillPercent, threshold, ...elementProps} = mixThemeWithProps(styles, this.props);
    const fillBar = fillPercent + threshold < 100 ? fillPercent + threshold : fillPercent;

    return (
      <SizeWatcher {...elementProps} renderContentOnInit>
        <CSSTransition timeout={{exit: 10_000}} nodeRef={this.progressRef}>
          <div className={theme.barFill} style={{width: `${fillBar}%`}} data-tid={tid} ref={this.progressRef} />
        </CSSTransition>
      </SizeWatcher>
    );
  }
}
