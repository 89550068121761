/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from 'intl';
import {Component} from 'react';
import {connect} from 'react-redux';
import {BroadcastChannel} from 'broadcast-channel';
import {getRouteName, getSessionExpirationMinutes} from 'containers/App/AppState';
import {AppContext} from 'containers/App/AppUtils';
import {Modal, Button} from 'components';
import styles from './SessionExpiration.css';
import stylesUtils from 'utils.css';

const modalTheme = {modal: styles.modal};
const secondIntl = new Intl.NumberFormat(intl.locale, {
  notation: 'standard',
  style: 'unit',
  unit: 'second',
  unitDisplay: 'long',
});

@connect(state => ({
  routeName: getRouteName(state),
  expirationMinutes: getSessionExpirationMinutes(state),
}))
export default class SessionExpiration extends Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);

    this.state = this.getInitialState();

    this.handleLogout = this.handleLogout.bind(this);
    this.handleContinueClick = this.handleContinueClick.bind(this);
    this.countdown = this.countdown.bind(this);
  }

  getInitialState() {
    return {
      show: false,
      seconds: null,
      timeout: null,
    };
  }

  componentDidMount() {
    try {
      // Create channel to broadcast message that the current user is active to the other tabs to prevent them from calling logout
      const activityChannel = new BroadcastChannel('userActivityChannel', {webWorkerSupport: false});

      this.broadcastActivity = () => {
        try {
          activityChannel.postMessage('Active');
        } catch (error) {
          console.error('Could not broadcast a message', error);
        }
      };

      // When other tab writes to activity channel restart logout countdown
      activityChannel.onmessage = event => {
        if (event === 'Active') {
          if (this.state.show) {
            this.continueSession();
          }

          this.scheduleCountdown();
        }
      };

      // Depending on expirationMinutes, countdown in modal will be between 60s and 15s
      this.logoutTimeout = 1000 * Math.max(15, Math.min(60, Math.floor(0.1 * 60 * this.props.expirationMinutes)));
      this.showCountdownTimeout = 60_000 * this.props.expirationMinutes - this.logoutTimeout;

      // Method to dispatch logout action when user is inactive
      // Logout after 10min of user inactivity (after last mouse movement) in production, except routes that have 'noAutoLogout' flag
      this.scheduleCountdown = _.debounce(this.showCountdown.bind(this), this.showCountdownTimeout);

      // When user performs some action, restart logout countdown and send a message to other tabs
      this.updateUserActivity = _.throttle(
        () => {
          try {
            this.scheduleCountdown();
            this.broadcastActivity();
          } catch (error) {
            console.error('Could not broadcast a message', error);
          }
        },
        1000,
        {trailing: false},
      );

      // Start listening to events that will call updateUserActivity
      this.addEvents();

      // Start countdown to automatically logout after debounce time, even if user is not making any action
      // And notify other tabs to reset their session
      this.updateUserActivity();
    } catch (error) {
      console.error('During BroadcastChannel creation', error);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.routeName !== prevProps.routeName) {
      this.updateUserActivity();
    }

    if (prevState.show && !this.state.show) {
      this.addEvents();
    }
  }

  handleContinueClick() {
    this.broadcastActivity();
    this.continueSession();
    this.scheduleCountdown();
  }

  handleLogout() {
    this.props.dispatch({type: 'LOGOUT'});
  }

  continueSession() {
    clearTimeout(this.state.timeout);
    this.setState(this.getInitialState());
  }

  addEvents() {
    // Use capture to avoid being prevented by stopPropagation call from children handlers
    // User is active when mouse is moved or mouse/touch is down.
    document.body.addEventListener('mousemove', this.updateUserActivity, {capture: true});
    document.body.addEventListener('mousedown', this.updateUserActivity, {capture: true});
    document.body.addEventListener('keydown', this.updateUserActivity, {capture: true});
    window.addEventListener('scroll', this.updateUserActivity, {passive: true});
  }

  removeEvents() {
    document.body.removeEventListener('mousemove', this.updateUserActivity, {capture: true});
    document.body.removeEventListener('mousedown', this.updateUserActivity, {capture: true});
    document.body.removeEventListener('keydown', this.updateUserActivity, {capture: true});
    window.removeEventListener('scroll', this.updateUserActivity, {passive: true});
  }

  showCountdown() {
    if (__DEV__) {
      // Never show countdown in development
      return;
    }

    try {
      const route = this.context.router.routesMap.get(this.props.routeName);

      if (route && !route.noAutoLogout) {
        this.removeEvents();
        this.countdown();
      }
    } catch (error) {
      console.error('Could not show session expiration countdown', error);
    }
  }

  countdown() {
    this.setState(state => {
      let seconds;

      if (state.show) {
        seconds = state.seconds - 1;

        if (!seconds) {
          this.handleLogout();

          return null;
        }
      } else {
        seconds = this.logoutTimeout / 1000;
      }

      return {show: true, seconds, timeout: setTimeout(this.countdown, 1000)};
    });
  }

  render() {
    const {show, seconds} = this.state;

    if (!show) {
      return null;
    }

    return (
      <Modal
        idleOnBackdropClick
        notResizable
        dontRestrainChildren
        stretch
        fixStretchedWidth
        minHeight={140}
        maxWidth="large"
        theme={modalTheme}
        tid="session-expiration"
      >
        <div className={styles.timer}>
          <div className={styles.number}>{intl.num(seconds)}</div>
          <div className={styles.unit}>
            {secondIntl.formatToParts(seconds).find(item => item.type === 'unit')?.value}
          </div>
        </div>
        <div className={styles.body}>
          <div className={styles.text}>
            <div className={styles.title}>{intl('PasswordPolicy.SessionTimeoutWarning')}</div>
            <div>
              {intl(
                'PasswordPolicy.LogoutWarningDescription',
                {
                  seconds,
                  number: <span className={stylesUtils.monoSpace}>{intl.num(seconds)}</span>,
                },
                {jsx: true},
              )}
            </div>
          </div>
          <div className={styles.buttons}>
            <Button noFill tid="logout" text={intl('PasswordPolicy.Logout')} onClick={this.handleLogout} />
            <Button
              color="primary"
              tid="continue"
              text={intl('PasswordPolicy.ContinueSession')}
              onClick={this.handleContinueClick}
            />
          </div>
        </div>
      </Modal>
    );
  }
}
