/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import apiSaga from 'api/apiSaga';
import {all, call, select, put} from 'redux-saga/effects';
import {fetchLoadBalancers, fetchDeviceTypes} from 'containers/LoadBalancer/List/LoadBalancerListSaga';
import {hrefUtils} from 'utils';
import {reverseLookupProtocol} from 'containers/Service/ServiceUtils';
import {
  getGridSettings,
  getDiscoveredVirtualServers,
  getVirtualServersCount,
  getFilterMap,
} from './VirtualServerListState';
import {getSelectorSettings} from './VirtualServerListConfig';
import {getUrlScopeValue} from 'components/Grid/GridSelectors';
import {fetchValidScopeLabels} from 'containers/Selectors/SelectorSaga';
import gridSaga from 'components/Grid/GridSaga';
import {isEdge} from 'containers/App/AppState';
import {RedirectError} from 'errors';
import * as apiUtils from 'api/apiUtils';

export function* fetchAllVirtualServers({filter, scope, force = false} = {}) {
  const query = {max_results: 1000};
  const selectorSettings = yield select(getSelectorSettings);

  if (filter) {
    const {staticValues} = selectorSettings;

    for (const [name, [value]] of Object.entries(filter)) {
      if (name === 'virtual_server_mode') {
        if (value === intl('Common.Unmanaged')) {
          query.has_virtual_server = false; // uses 1 param instead of 2 endpoints
          continue;
        }

        query.has_virtual_server = true;
      }

      if (name === 'slbs' || name === 'slb') {
        query.slb = value.href;
      } else if (staticValues[name]?.[value]) {
        query[name] = staticValues[name][value];
      } else if (value) {
        query[name] = value;
      }
    }
  }

  if (scope && query.has_virtual_server !== false) {
    query.has_virtual_server = true;
    Object.assign(
      query,
      apiUtils.getXXXLabelsQueryParam(
        // labels apply to associated virtual_server, not DVS
        [scope.scope.map(obj => obj.href).filter(href => !href?.includes('exists'))],
        'virtual_server_labels',
      ),
    );
  }

  return yield call(apiSaga, 'discovered_virtual_servers.get_collection', {
    query,
    cache: !force,
    *onDone({data}) {
      if (force || data !== (yield select(getDiscoveredVirtualServers))) {
        yield put({type: 'DISCOVERED_VIRTUAL_SERVERS', data});
      }

      const count = {
        matches: data.reduce((result, {virtual_server}) => {
          result += virtual_server !== null ? 1 : 0;

          return result;
        }, 0),
      };

      if (force || count !== (yield select(getVirtualServersCount))) {
        yield put({type: 'VIRTUAL_SERVER_GET_LIST', data: {count}});
      }

      return {count};
    },
  });
}

export function* updateEnforceState({hrefs, policyState, pversion = 'draft'}) {
  yield all(
    hrefs.map(href =>
      call(apiSaga, 'virtual_server.update', {
        params: {virtual_server_id: hrefUtils.getId(href), pversion},
        data: {mode: policyState},
      }),
    ),
  );
}

export function* removeVirtualServer({href, pversion = 'draft'}) {
  yield call(apiSaga, 'virtual_server.delete', {
    params: {pversion, virtual_server_id: hrefUtils.getId(href)},
    hrefs: [href],
  });
}

export function* fetchVirtualServerList(route, refetch = false, options = {}) {
  const edgeEnabled = yield select(isEdge);
  const {params} = route;

  if (edgeEnabled) {
    throw new RedirectError({to: 'landing', proceedFetching: true, thisFetchIsDone: true});
  }

  yield call(gridSaga, {
    route,
    settings: getGridSettings,
    filterMap: getFilterMap, // re-getFilterMap fixed "filterMap is undefined"
    *onSaga({filterParams}) {
      const {fetchSLBs = true} = options;
      let filter = filterParams.isEmpty ? {} : filterParams.valid;
      const scopeParams = yield select(getUrlScopeValue, params);
      const scope = scopeParams.isEmpty ? undefined : scopeParams.valid;

      if (filter?.vip_proto) {
        filter.vip_proto[0] = reverseLookupProtocol(filter.vip_proto[0]);
      }

      if (options.filter) {
        // restore filter stripped away by GridSelectors (filter is not in urlParams or gridParams in slb page url)
        filter = {...filter, ...options.filter};
      }

      // need to fetch before fetchLoadBalancers, because it checks isLoadBalancerEnabled.
      yield call(fetchDeviceTypes, {force: refetch});

      const [{count}] = yield all([
        call(fetchAllVirtualServers, {filter, scope, force: refetch}),
        fetchSLBs && call(fetchLoadBalancers, {force: refetch}),
        call(fetchValidScopeLabels, scope), // get *text* of labels in case not already in redux store
      ]);

      return count.matches;
    },
  });
}
