/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import _ from 'lodash';
import {Button, ButtonGroup, MenuItem, MenuDelimiter} from 'components';
import * as GridUtils from 'components/Grid/GridUtils';
import {sortRoles, scopeColumn} from 'containers/RBAC/RBACUtils';
import {isAPIAvailable} from 'api/apiUtils';
import {createSelector} from 'reselect';

function handleButtonAction(type, props) {
  const {row, component} = props;

  switch (type) {
    case 'remove':
      component.handleRemoveSingleRow(row);
      break;
    case 'edit':
      component.context.navigate({
        to: 'rbac.accesswizard',
        params: {
          type: row.type,
          prevRoute: JSON.stringify({params: {id: component.props.routeParams.id}, to: 'rbac.users.local.detail'}),
          scopeId: JSON.stringify(row.scopeHrefs),
          roles: JSON.stringify(row.roleHrefs.map(role => _.last(role.href.split('/')))),
          authSecPrincipals: JSON.stringify([_.last(component.props.authSecPrincipal.href.split('/'))]),
        },
      });
      break;
    default:
      component.handleRemoveSingleRow(row);
  }
}

export const gridSettings = createSelector([], () => ({
  id: 'localuserdetail',
  sort: 'type',
  capacities: [25, 50, 100, 250, 500],
  capacity: 50,
  maxPage: Number.MAX_SAFE_INTEGER,
  showPagination: true,
  columns: {
    checkboxes: {},
    type: {
      header: intl('Common.Type'),
      value: ({row}) => row.type,
      format: ({value}) => (value === 'global' ? intl('Common.Global') : intl('Common.Scoped')),
    },
    scope: {
      ...scopeColumn,
    },
    roles: {
      linky: true,
      header: intl('Common.Roles'),
      value: ({row: {roles}}) => sortRoles(roles),
      format: ({value}) => value.reduce((result, role) => (result ? `${result}, ${role}` : role), ''),
    },
    action: {
      value: true,
      format(props) {
        // in config format, if you want to call functions / use this.props in the actual component, you need to:
        //    1. in actual component, pass down a prop "component" with value of "this" in Grid. e.g.: <Grid component={this}>...</Grid>
        //    2. in config columns.format, (format becomes a function) you can either use props.component, or "this" keyword directly
        //       e.g.: this.context.navigate({...}) is equivalent to props.component.context.navigate({...})
        const isSelfViewing = props?.component?.props?.isSelfViewing;
        const selectable = props?.row?.selectable;

        return (
          <ButtonGroup color="standard" noFill>
            <Button
              disabled={
                !isAPIAvailable('org_permission.delete', 'org_permissions.create') || (isSelfViewing && !selectable)
              }
              icon="edit"
              tid="edit"
              onClick={_.partial(handleButtonAction, 'edit', props)}
            />
            <Button.Menu
              menu={[
                <MenuItem
                  disabled={
                    !isAPIAvailable('org_permission.delete', 'org_permissions.create') || (isSelfViewing && !selectable)
                  }
                  text="Edit"
                  onSelect={_.partial(handleButtonAction, 'edit', props)}
                />,
                <MenuDelimiter />,
                <MenuItem
                  disabled={!isAPIAvailable('org_permission.delete') || (isSelfViewing && !selectable)}
                  text="Remove"
                  onSelect={_.partial(handleButtonAction, 'remove', props)}
                />,
              ]}
            />
          </ButtonGroup>
        );
      },
      sortable: false,
    },
  },
  templates: [
    [
      {columns: ['checkboxes'], size: 'max-content'},
      {columns: ['type'], size: 'minmax(50px, auto)'},
      {columns: ['scope'], size: 'minmax(200px, auto)'},
      {columns: ['roles'], size: 'minmax(200px, auto)'},
      {columns: ['action'], size: 'max-content'},
    ],
    {
      maxWidth: 640,
      template(columns) {
        if (GridUtils.hasOptionalColumns(columns)) {
          //all column breakpoint
          return [
            {columns: ['checkboxes'], size: 'max-content'},
            {columns: ['type'], size: 'minmax(50px, auto)'},
            {columns: ['scope', 'roles'], size: 'minmax(200px, auto)'},
            {columns: ['action'], size: 'minmax(200px, auto)'},
          ];
        }

        return [
          {columns: ['checkboxes'], size: 'max-content'},
          {columns: ['type'], size: 'minmax(50px, auto)'},
          {columns: ['scope', 'roles'], size: 'minmax(200px, auto)'},
          {columns: ['action'], size: 'minmax(200px, auto)'},
        ];
      },
    },
  ],
}));
