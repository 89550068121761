/**
 * Copyright 2016 Illumio, Inc. All Rights Reserved.
 */
import {combineReducers} from 'redux';
import {router5Reducer} from 'redux-router5';

import appReducers from 'containers/App/AppState';
import UserReducers from 'containers/User/UserState';
import helpReducers from 'containers/HelpPopup/HelpPopupState';
import VenReducers from 'containers/Ven/VenState';
import LoadBalancerReducers from 'containers/LoadBalancer/LoadBalancerState';
import selectorReducers from 'containers/Selectors/SelectorState';
import headerReducers from 'containers/Header/HeaderState';
import provisioningReducers from 'containers/Provisioning/ProvisioningState';
import healthReducers from 'containers/Health/HealthState';
import InstantSearchReducers from 'containers/InstantSearch/InstantSearchState';
import CoreServiceReducers from 'containers/CoreServices/CoreServicesState';
import GlobalIpsReducers from 'containers/CorporatePublicIPs/CorporatePublicIPsState';

// we need to use explicit any to avoid exporting the private name Router5ReducerState,
// which blocks the emitting of declaration files
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const reducers: any = {
  router: router5Reducer,
  ...appReducers,
  ...headerReducers,
  ...healthReducers,
  ...helpReducers,
  ...InstantSearchReducers,
  ...provisioningReducers,
  ...selectorReducers,
  ...VenReducers,
  ...LoadBalancerReducers,
  ...UserReducers,
  ...CoreServiceReducers,
  ...GlobalIpsReducers,
};

const thorApp = combineReducers(reducers);

export default thorApp;
