/**
 * Copyright 2021 Illumio, Inc. All Rights Reserved.
 */
import cx from 'classnames';
import _ from 'lodash';
import intl from 'intl';
import {select} from 'redux-saga/effects';
import {getParameterizedPathLoosely} from 'api/apiUtils';
import {getOrgId} from 'containers/User/UserState';
import LabelEdit from 'containers/Label/Edit/LabelEdit';
import LabelGroupEdit from 'containers/LabelGroup/Edit/LabelGroupEdit';
import {getDiscardChangesModalProps} from 'components/UnsavedPendingWarning/UnsavedPendingWarningUtils';
import {prepareSearchIndex, getSearchResult} from './SelectorUtils';
import styleUtils from 'utils.css';

const prefix = ':';
const prefixMap = {
  A: 'app',
  E: 'env',
  L: 'loc',
  R: 'role',
};
const labelTypeString = {
  app: intl('Common.Application'),
  env: intl('Common.Environment'),
  loc: intl('Common.Location'),
  role: intl('Common.Role'),
};

const getQuery = (queryString, values, resource) => {
  const query = {};
  const allowMultipleSelection = resource.optionProps?.allowMultipleSelection;
  const labels = values.get('labels') ?? [];
  const labelGroups = values.get('label_groups') ?? [];

  if (!allowMultipleSelection) {
    const excludeKeys = [...new Set([...labels.map(({key}) => key), ...labelGroups.map(({key}) => key)])];

    if (excludeKeys.length) {
      query.exclude_keys = JSON.stringify(excludeKeys);
    }
  }

  const selectedScope = [];

  // IncludeSelectedResources: ['labels', 'label_groups']
  resource.includeSelectedResources?.forEach(resourceId => {
    selectedScope.push(
      ...(values.get(resourceId)?.map(({href}) => ({[resourceId === 'labels' ? 'label' : 'label_group']: {href}})) ??
        []),
    );
  });

  if (selectedScope.length > 0) {
    query.selected_scope = JSON.stringify(selectedScope);
  }

  if (!queryString.includes(prefix)) {
    query.query = queryString;

    return query;
  }

  let [key, value] = queryString.split(prefix);

  key = prefixMap[key.trim()];

  if (key) {
    query.key = key;
  }

  query.query = value;

  return query;
};

const getNoLabelHref = (orgId, key) =>
  getParameterizedPathLoosely({
    orgId,
    params: {key, exists: false},
    path: '/orgs/:xorg_id/labels?key=:key&exists=:exists',
  });

export function* populateNoScopeLabels({query}) {
  const orgId = yield select(getOrgId);

  const noScopeLabels = [
    {href: getNoLabelHref(orgId, 'role'), value: intl('Common.NoRoleLabel'), key: 'role'},
    {href: getNoLabelHref(orgId, 'app'), value: intl('Common.NoApplicationLabel'), key: 'app'},
    {href: getNoLabelHref(orgId, 'env'), value: intl('Common.NoEnvironmentLabel'), key: 'env'},
    {href: getNoLabelHref(orgId, 'loc'), value: intl('Common.NoLocationLabel'), key: 'loc'},
  ];

  const searchIndex = prepareSearchIndex({options: noScopeLabels, store: true});
  const matches = query.query ? getSearchResult(searchIndex, query.query) : noScopeLabels;

  return {matches, num_matches: matches.length};
}

const getLabelProps = ({group = false} = {}) => ({
  name: group ? intl('Labels.Groups') : intl('Common.Labels'),
  dataProvider: group ? 'label_groups.autocomplete' : 'labels.autocomplete',
  apiArgs: {...(group && {params: {pversion: 'draft'}}), query: {getQuery}},
  includeSelectedResources: ['labels', 'label_groups'],
  selectedProps: {
    valueJoiner: 'and',
    hideResourceName: true,
    pillProps: label => ({icon: label.key, group}),
    joinerPillProps: labels => ({
      key: labels[0].key,
      icon: labels[0].key,
      group,
    }),
    formatResource: ({values, formatContent, selectedProps, theme}) => {
      const labelsByTypeArray = Object.values(_.groupBy(values, 'key'));

      return labelsByTypeArray.map((labelsByType, index) => {
        return (
          <div key={index} className={cx(styleUtils.gapInline, styleUtils.gapHorizontal)}>
            {index > 0 && selectedProps.resourceJoiner && (
              <div className={theme.joiner}>{selectedProps.resourceJoiner}</div>
            )}
            {formatContent(labelsByType)}
          </div>
        );
      });
    },
  },
  optionProps: {
    isPill: true,
    pillProps: (label, {insensitive, disabled}) => ({
      icon: label.key,
      group,
      insensitive,
      disabled,
    }),
    tooltipProps: {
      content: (label, {insensitive, disabled}) => (insensitive || disabled ? undefined : labelTypeString[label.key]),
    },
    allowMultipleSelection: true,
  },
  allowCreate: true,
  createHint: intl('ObjectSelector.NewObject', {object: group ? 'labelGroup' : 'label'}),
  onCreateEnter: group ? 'labelGroupForm' : 'labelForm',
  onSelect: ({value, values, resource}) => {
    const objType = group ? 'label_groups' : 'labels';

    if (values.has(objType)) {
      // Selected labels needs to be re-shuffled:
      // 1) truncate the already selected labels of the same type and
      // 2) append the selections at the end of the list along with new selection
      const [labelsInSelectedType, labelsInRemainingTypes] = _.partition(
        values.get(objType),
        ({key}) => key === value.key,
      );

      values.delete(objType); // Remove the key from values Map so that new entry can be appended at the end of the list

      const newValuesInSelectedType = resource.optionProps.allowMultipleSelection
        ? [...labelsInSelectedType, value]
        : [value];

      values.set(objType, [...labelsInRemainingTypes, ...newValuesInSelectedType]);
    } else {
      values.set(objType, [value]);
    }

    return values;
  },
});

export const CategoryPresets = {
  scope: {
    id: 'scope',
    name: 'Scope',
    template: `
      'allScopeLabels noScopeLabels' fit-content(35%)
      'labels label_groups' auto / 1fr 1fr
      `,
    resources: {
      allScopeLabels: {
        name: 'All Scope Labels',
        template: `
        auto / repeat(auto-fit, minmax(180px, auto))
        `,
        statics: [
          {href: 'all_role', value: intl('Common.AllRoleLabels'), key: 'role'},
          {href: 'all_app', value: intl('Common.AllApplicationLabels'), key: 'app'},
          {href: 'all_env', value: intl('Common.AllEnvironmentLabels'), key: 'env'},
          {href: 'all_loc', value: intl('Common.AllLocationLabels'), key: 'loc'},
        ],
        noEmptyBanner: true,
        noTitle: true,
        optionProps: {
          isPill: true,
          pillProps: label => ({icon: label.key}),
        },
        selectedProps: {hideResourceName: true, pillProps: label => ({icon: label.key})},
      },
      noScopeLabels: {
        name: 'No Scope Labels',
        template: `
        auto / repeat(auto-fit, minmax(180px, 1fr))
        `,
        dataProvider: populateNoScopeLabels,
        noEmptyBanner: true,
        noTitle: true,
        optionProps: {
          isPill: true,
          pillProps: label => ({icon: label.key}),
        },
        selectedProps: {hideResourceName: true, pillProps: label => ({icon: label.key})},
      },
      labelForm: {
        type: 'container',
        selectIntoResource: 'labels',
        historyKey: 'labels.autocomplete',
        enableFocusLock: true,
        container: LabelEdit,
        containerProps: query => ({
          controlled: true,
          buttonAlign: 'bottom',
          label: {value: query},
          formProps: {id: 'labelFrom'},
        }),
        unsavedWarningData: {...getDiscardChangesModalProps('label')},
        hidden: true,
      },
      labelGroupForm: {
        type: 'container',
        selectIntoResource: 'label_groups',
        historyKey: 'label_groups.autocomplete',
        enableFocusLock: true,
        container: LabelGroupEdit,
        containerProps: query => ({
          controlled: true,
          buttonAlign: 'bottom',
          labelGroup: {detail: {draft: {name: query}}},
          formProps: {id: 'labelGroupForm'},
        }),
        unsavedWarningData: {...getDiscardChangesModalProps('label_group')},
        hidden: true,
      },
      labels: {...getLabelProps({group: false})},
      label_groups: {...getLabelProps({group: true})},
    },
  },
};
