/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */
import {createSelector} from 'reselect';
import {gridSettings} from './MyManagedTenantsListConfig';
import {getGridSelector} from 'components/Grid/GridSelectors';

export default {
  list(state = [], action) {
    switch (action.type) {
      case 'MSPTENANTS_GET_LIST':
        return action.data;
      default:
        return state;
    }
  },

  count(state = {}, action) {
    switch (action.type) {
      case 'MSPTENANTS_GET_LIST':
        return {matched: action.data.length, total: action.data.length};
      default:
        return state;
    }
  },
};

export const getMSPTenants = state => state.mspTenants.list;
export const getMSPTenantsCount = state => state.mspTenants.count;

const getMSPTenantsRows = createSelector([getMSPTenants], mspTenants =>
  mspTenants.map(item => ({
    key: item.href,
    selectable: true,
    removable: true,
    data: {
      ...item,
    },
  })),
);

const getGrid = state =>
  getGridSelector(state, {
    settings: gridSettings,
    rows: getMSPTenantsRows,
  });

export const getMyManagedTenantsList = createSelector([getGrid, getMSPTenantsCount], (grid, count) => ({grid, count}));
