/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from 'intl';
import {createSelector} from 'reselect';
import {getRouteName, getRouteParams, isCrowdstrike, isEdge} from 'containers/App/AppState';
import {doesIKECertificateExist} from 'containers/IKECertificates/IKECertificatesState';
import {isPairingProfilesEnabled} from 'containers/PairingProfile/PairingProfileState';
import {getPolicyVersions} from 'containers/Provisioning/ProvisioningUtils';
import {networkTypes} from 'containers/Network/NetworkUtils';
import {isUserReadOnly} from 'containers/User/UserState';
import {getChangeSubsets} from 'edge/containers/OutboundPolicy/OutboundPolicyState';
import {getGridSelector} from 'components/Grid/GridSelectors';
import {
  groupViewGridSettings,
  getSelectorSettings,
  groupAdminAccessGridSettings,
  getAdminSelectorSettings,
} from './GroupViewConfig';
import {getGroupFilter, getAdminGroupFilter, parseGroupExternalDataSet} from './GroupUtils';
import {hrefUtils} from 'utils';

export default {
  groupVersionInstance(state = {}, action) {
    switch (action.type) {
      case 'GROUP_GET_ITEM':
        return action.item;
      default:
        return state;
    }
  },
  profile(state = {}, action) {
    switch (action.type) {
      case 'GROUP_GET_ITEM_PROFILE':
        return action.profile;
      default:
        return state;
    }
  },
  workloadCount(state = 0, action) {
    switch (action.type) {
      case 'GROUP_GET_ITEM_WL_COUNT':
        return action.count;
      default:
        return state;
    }
  },
  venCount(state = 0, action) {
    switch (action.type) {
      case 'GROUP_GET_ITEM_VEN_COUNT':
        return action.count;
      default:
        return state;
    }
  },
  adminGroupList(state = [], action) {
    switch (action.type) {
      case 'GROUPS_GET_ADMIN_LIST':
        return action.data.list;
      default:
        return state;
    }
  },

  adminGroupCount(state = {}, action) {
    switch (action.type) {
      case 'GROUPS_GET_ADMIN_LIST':
        return action.data.count;
      default:
        return state;
    }
  },
};

export const getGroupsAdminList = state => state.group.adminGroupList;
export const getGroupsAdminCount = state => state.group.adminGroupCount;
export const getGroupVersionInstance = state => state.group?.groupVersionInstance || {};
export const getGroupProfile = state => state.group?.profile;
export const getGroupVenCount = state => state.group.venCount;
export const doesAdminGroupExist = createSelector(getGroupsAdminList, adminGroupList => Boolean(adminGroupList.length));

export const getGroupItem = createSelector([getRouteParams, getGroupVersionInstance], (params, {instance}) => {
  const {versions, isOldVersion, pversionObjIsDeleted} = getPolicyVersions(instance, params.pversion);

  return {versions, isOldVersion, pversionObjIsDeleted};
});

export const getGroup = createSelector([getGroupItem, getGroupVersionInstance], ({versions}, {group}) => {
  if (!group) {
    return versions;
  }

  return {
    name: group.name,
    id: hrefUtils.getId(group.href),
    href: group.href,
    isAdmin: group.admin,
    //Administered Groups Example:
    /* [
        {
          'href': '/orgs/1/groups/1',
          'name': 'RND',
        },
        {
          'href': '/orgs/1/groups/2',
          'name': 'Marketing',
        },
      ]; */
    administeredGroups: group.administered_groups,
    adminsteredGroupsCount: group.administered_groups.length,
    roleLabel: {...group.label, id: hrefUtils.getId(group.label.href), key: 'role', value: group.name},
    pairingProfile: {...group.pairing_profile, id: hrefUtils.getId(group.pairing_profile.href)},
    externalDataSet: parseGroupExternalDataSet(versions.pversionObj),
    workloadCount: group.num_workloads,
    created_by: group.created_by,
    updated_by: group.updated_by,
    ruleset: {...group.rule_set, id: hrefUtils.getId(group.rule_set.href)},
    updateType: group.rule_set.update_type,
  };
});

// common to getRows & getAdminGroupRows
const icon = {
  create: 'add',
  delete: 'remove',
  update: 'modified',
};

// row structure
// { key: 'href with 'draft' replaced', active: rule, draft: rule, noDiff: (optional boolean) }
const getRows = createSelector([getGroupItem, getRouteParams], ({versions}, routeParams) => {
  const {pversionObj, prevPversionObj} = versions;
  const {groupRuleList} = routeParams;

  if (!pversionObj) {
    return;
  }

  if (_.isEmpty(prevPversionObj)) {
    return getGroupFilter(
      pversionObj.rules
        .filter(rule => !rule.machine_auth)
        .map(rule => ({
          key: rule.href,
          status: rule.update_type ? icon[rule.update_type] : null,
          data: {
            service: rule.ingress_services[0],
            ipRanges: rule.consumers.map(({ip_list}) => ip_list),
            oldIpRanges: rule.consumers.map(({ip_list}) => ip_list),
            network_type: rule.network_type,
            oldNetworkType: rule.network_type,
          },
        })),
      groupRuleList ? JSON.parse(groupRuleList).filter : null,
    );
  }

  return getGroupFilter(
    pversionObj.rules
      .filter(rule => !rule.machine_auth)
      .map(rule => ({
        key: rule.href,
        status: rule.update_type ? icon[rule.update_type] : null,
        data: {
          service: rule.ingress_services[0],
          ipRanges: rule.consumers.map(({ip_list}) => ip_list),
          oldIpRanges: prevPversionObj?.rules
            .find(prevRule => hrefUtils.getId(prevRule.href) === hrefUtils.getId(rule.href))
            ?.consumers?.map(consumer => consumer.ip_list),
          network_type: rule.network_type,
          oldNetworkType: prevPversionObj?.rules.find(
            prevRule => hrefUtils.getId(prevRule.href) === hrefUtils.getId(rule.href),
          )?.network_type,
        },
      })),
    groupRuleList ? JSON.parse(groupRuleList).filter : null,
  );
});

const getAdminGroupRows = createSelector([getGroupItem, getRouteParams], ({versions}, routeParams) => {
  const {pversionObj, prevPversionObj} = versions;
  const {adminGroupList} = routeParams;

  if (!pversionObj) {
    return;
  }

  if (_.isEmpty(prevPversionObj)) {
    return getAdminGroupFilter(
      pversionObj.rules
        .filter(rule => rule.machine_auth)
        .map(rule => ({
          key: rule.href,
          status: rule.update_type ? icon[rule.update_type] : null,
          data: {
            service: rule.ingress_services[0],
            adminGroups: rule.consumers.map(({label}) => label),
            oldAdminGroups: rule.consumers.map(({label}) => label),
            encrypt: rule.sec_connect ? intl('Common.Yes') : intl('Common.No'),
            oldEncrypt: rule.sec_connect ? intl('Common.Yes') : intl('Common.No'),
          },
        })),
      adminGroupList ? JSON.parse(adminGroupList).filter : null,
    );
  }

  return getAdminGroupFilter(
    pversionObj.rules
      .filter(rule => rule.machine_auth)
      .map(rule => ({
        key: rule.href,
        status: rule.update_type ? icon[rule.update_type] : null,
        data: {
          service: rule.ingress_services[0],
          adminGroups: rule.consumers.map(({label}) => label),
          oldAdminGroups: prevPversionObj?.rules
            .find(prevRule => hrefUtils.getId(prevRule.href) === hrefUtils.getId(rule.href))
            ?.consumers?.map(({label}) => label),
          encrypt: rule.sec_connect ? intl('Common.Yes') : intl('Common.No'),
          oldEncrypt: prevPversionObj?.rules.find(
            prevRule => hrefUtils.getId(prevRule.href) === hrefUtils.getId(rule.href),
          )?.sec_connect
            ? intl('Common.Yes')
            : intl('Common.No'),
        },
      })),
    adminGroupList ? JSON.parse(adminGroupList).filter : null,
  );
});

const getIPRangeStaticsSet = createSelector(getGroupItem, ({versions}) => {
  const {pversionObj, prevPversionObj} = versions;

  const ipRangeStaticsSet = pversionObj?.rules.reduce((result, rule) => {
    const ipRanges = new Set();
    const oldIpRanges = new Set();

    rule.consumers.forEach(({ip_list}) => {
      if (ip_list) {
        ipRanges.add(ip_list);
      }
    });

    prevPversionObj?.rules
      .find(prevRule => hrefUtils.getId(prevRule.href) === hrefUtils.getId(rule.href))
      ?.consumers.forEach(({ip_list}) => {
        if (ip_list) {
          oldIpRanges.add(ip_list);
        }
      });

    const ipRangesDropdown = [...ipRanges, ...oldIpRanges];

    ipRangesDropdown.forEach(({name}) => {
      result.add(name);
    });

    return result;
  }, new Set());

  return ipRangeStaticsSet;
});

const getAdminGroupStaticsSet = createSelector(getGroupItem, ({versions}) => {
  const {pversionObj, prevPversionObj} = versions;

  const adminGroupStaticsSet = pversionObj?.rules.reduce((result, rule) => {
    const adminGroups = new Set();
    const oldAdminGroups = new Set();

    rule.consumers.forEach(({label}) => {
      if (label) {
        adminGroups.add(label);
      }
    });

    prevPversionObj?.rules
      .find(prevRule => hrefUtils.getId(prevRule.href) === hrefUtils.getId(rule.href))
      ?.consumers.forEach(({label}) => {
        if (label) {
          oldAdminGroups.add(label);
        }
      });

    const adminGroupsDropdown = [...adminGroups, ...oldAdminGroups];

    adminGroupsDropdown.forEach(({value}) => {
      result.add(value);
    });

    return result;
  }, new Set());

  return adminGroupStaticsSet;
});

const getPolicyGrid = state =>
  getGridSelector(state, {
    settings: groupViewGridSettings,
    rows: getRows,
    filterMap: getSelectorSettings().filterMap,
  });

const getAdminGrid = state =>
  getGridSelector(state, {
    settings: groupAdminAccessGridSettings,
    rows: getAdminGroupRows,
    filterMap: getAdminSelectorSettings().filterMap,
  });

const getGrid = createSelector([getPolicyGrid, getAdminGrid, getRouteParams], (policyGrid, adminGrid, params) => {
  if (params.tab === 'inboundpolicy') {
    return policyGrid;
  }

  return adminGrid;
});

export const getGroupItemPage = createSelector(
  [
    getGrid,
    getGroupItem,
    getRouteName,
    getRouteParams,
    isUserReadOnly,
    isPairingProfilesEnabled,
    isCrowdstrike,
    isEdge,
    getIPRangeStaticsSet,
    getAdminGroupStaticsSet,
    getSelectorSettings,
    getAdminSelectorSettings,
    getGroup,
    doesIKECertificateExist,
    doesAdminGroupExist,
    getChangeSubsets,
  ],
  (
    grid,
    {versions, isOldVersion, pversionObjIsDeleted},
    routeName,
    params,
    userIsReadOnly,
    pairingProfilesIsEnabled,
    crowdstrikeEnabled,
    edgeEnabled,
    ipRangeStaticsSet,
    adminGroupStaticsSet,
    policySelectorSettings,
    adminSelectorSettings,
    selectedGroup,
    ikeCertificateExists,
    adminGroupExists,
    ChangeSubsets,
  ) => {
    const {pversionObj, draft} = versions;
    let selector;

    if (params.tab === 'inboundpolicy') {
      selector = {
        initialItems: Object.entries(grid.params?.filter || {}).reduce((result, [categoryKey]) => {
          grid.params.filter[categoryKey].forEach(item => {
            result.push({
              categoryKey,
              value: item,
              categoryName:
                policySelectorSettings.filterMap[categoryKey]?.value || policySelectorSettings.filterMap[categoryKey],
            });
          });

          return result;
        }, []),
        categories: [
          {categoryKey: 'service', value: intl('Common.Service')},
          {categoryKey: 'ip_list', value: intl('Edge.SourceIPRanges'), isStatic: true},
          {categoryKey: 'networkType', value: intl('Edge.NetworkProfile'), isStatic: true},
        ],
        statics: {
          service: pversionObj?.rules.filter(rule => !rule.machine_auth).map(rule => rule?.ingress_services?.[0]?.name),
          ip_list: ipRangeStaticsSet ? [...ipRangeStaticsSet] : [],
          networkType: networkTypes,
        },
      };
    } else {
      selector = {
        initialItems: Object.entries(grid.params?.filter || {}).reduce((result, [categoryKey]) => {
          grid.params.filter[categoryKey].forEach(item => {
            result.push({
              categoryKey,
              value: item,
              categoryName:
                adminSelectorSettings.filterMap[categoryKey]?.value || adminSelectorSettings.filterMap[categoryKey],
            });
          });

          return result;
        }, []),
        categories: [
          {categoryKey: 'service', value: intl('Common.Service')},
          {categoryKey: 'adminGroups', value: intl('Edge.AdminGroup.AllowedAdminGroups'), isStatic: true},
          {categoryKey: 'encryption', value: intl('Edge.AdminGroup.AllowedAdminAccess.EncryptTraffic'), isStatic: true},
        ],
        statics: {
          service: pversionObj?.rules.filter(rule => rule.machine_auth).map(rule => rule?.ingress_services?.[0]?.name),
          adminGroups: adminGroupStaticsSet ? [...adminGroupStaticsSet] : [],
          encryption: [intl('Common.Yes'), intl('Common.No')],
        },
      };
    }

    const provisionHrefs = new Map();

    const ruleset = draft?.update_type ? [{href: draft?.href}] : [];

    provisionHrefs.set('ruleset', ruleset);

    const servicesAndIPLists = draft?.rules.reduce((result, rule) => {
      if (!result.services) {
        result.services = [];
      }

      if (!result.iplists) {
        result.iplists = [];
      }

      if (rule.ingress_services[0].update_type === 'update') {
        result.services.push({href: rule.ingress_services[0].href});
      }

      rule.consumers.forEach(consumer => {
        if (consumer?.ip_list && consumer?.ip_list.update_type === 'update') {
          result.iplists.push({href: consumer.ip_list.href});
        }
      });

      return result;
    }, {});

    if (servicesAndIPLists) {
      provisionHrefs.set('services', _.uniqBy(servicesAndIPLists.services, 'href'));
      provisionHrefs.set('iplists', _.uniqBy(servicesAndIPLists.iplists, 'href'));
    }

    const showProvision =
      isOldVersion ||
      Boolean(draft?.update_type) ||
      Boolean(
        draft?.rules.some(
          rule =>
            rule.ingress_services[0].update_type || rule.consumers.some(consumer => consumer?.ip_list?.update_type),
        ),
      );

    const readOnly = _.isEmpty(ChangeSubsets.objectsToRevert);

    return {
      grid,
      versions,
      isOldVersion,
      pversionObjIsDeleted,
      routeName,
      userIsReadOnly,
      pairingProfilesIsEnabled,
      params,
      crowdstrikeEnabled,
      edgeEnabled,
      selector,
      selectedGroup,
      ikeCertificateExists,
      adminGroupExists,
      showProvision,
      provisionHrefs,
      readOnly,
    };
  },
);
