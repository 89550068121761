/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import {combineReducers} from 'redux';

import {createSelector} from 'reselect';
import {getLocalUsersUsernameSet, getUsernameToUserMap} from 'containers/User/UserState';

/*
 * authSecPrincipals are one of the three key objects that make up a RBAC permission (scope, authSecPrincipal, role)
 * an authSecPrincipal is the link between the permission and the user.
 * an authSecPrincipal has three properties
 *
 * type: (string: 'group' or 'user') - determines whether the authSecPrincipal is for a user or a group.
 *                         unfortunately type does not include local vs external user granularity so if you need to filter for
 *                         local vs. external user authSecPrincipals, you must cross reference the user apis
 * name: (string) - this is the key which links the authSecPrincipal to the user or group.
 *                  localUsers: will match this string w/ email
 *                  extUsers: will match this string w/ username
 *                  extGroups: will match this string w/ group name
 * display_name: (string) - this is user friendly name i.e. if name = 'john.doe@x.com', display_name might be = 'John Doe'
 */
export default {
  authSecPrincipals: combineReducers({
    all(state = [], action) {
      switch (action.type) {
        case 'AUTH_SEC_PRINCIPAL_GET_ALL':
          return action.data.authSecPrincipals;
        default:
          return state;
      }
    },
    allCount(state = {}, action) {
      switch (action.type) {
        case 'AUTH_SEC_PRINCIPAL_GET_ALL':
          return action.data.count;
        default:
          return state;
      }
    },

    /*
     *  filtered authSecPrincipal list is used for the users and groups list page rows.
     *  Even though the page is rendered from a filtered list, the full list may be used
     *  for other reasons on the same pages. Because of this the filtered list is stored in
     *  a separate reducer
     */
    filtered(state = [], action) {
      switch (action.type) {
        case 'AUTH_SEC_PRINCIPAL_GET_FILTERED':
          return action.data.authSecPrincipals;
        default:
          return state;
      }
    },
    filteredCount(state = {}, action) {
      switch (action.type) {
        case 'AUTH_SEC_PRINCIPAL_GET_FILTERED':
          return action.data.count;
        default:
          return state;
      }
    },
  }),
};

/*
 *  Return list of authSecPrincipals and add nameLow prop, because name of principal is case sensitive,
 *  but should be connected with lowered username of a user object
 */
export const getAuthSecPrincipals = createSelector(
  state => state.authSecPrincipals.all,
  principals =>
    principals.map(({...principalCopy}) => {
      if (typeof principalCopy.name === 'string') {
        principalCopy.nameLow = principalCopy.name.toLowerCase();
      }

      return principalCopy;
    }),
);
export const getAuthSecPrincipalsCount = state => state.authSecPrincipals.allCount;
export const getFilteredAuthSecPrincipals = createSelector(
  state => state.authSecPrincipals.filtered,
  principals =>
    principals.map(({...principalCopy}) => {
      if (typeof principalCopy.name === 'string') {
        principalCopy.nameLow = principalCopy.name.toLowerCase();
      }

      return principalCopy;
    }),
);
export const getFilteredAuthSecPrincipalsCount = state => state.authSecPrincipals.filteredCount;

/*
 *  This selector adds usertype (local vs exeternal), user object, and userHref to the authSecPrincipal get colleciton response.
 *  Some authSecPrincipals may not have associated users. In these cases, usertype, user, and userHref will be null.
 */
export const getAuthSecPrincipalsWithUser = createSelector(
  [getAuthSecPrincipals, getLocalUsersUsernameSet, getUsernameToUserMap],
  (authSecPrincipals, localUserUsernames, usernameUsersMap) =>
    authSecPrincipals.map(authSecPrincipal => {
      let usertype = null;
      let user = null;

      if (authSecPrincipal.type === 'user') {
        usertype = localUserUsernames.has(authSecPrincipal.nameLow) ? 'local' : 'external';

        if (usernameUsersMap.hasOwnProperty(authSecPrincipal.nameLow)) {
          user = usernameUsersMap[authSecPrincipal.nameLow];
        }
      }

      return {...authSecPrincipal, usertype, user};
    }),
);

/*
 *  This selector adds usertype (local vs exeternal), user object, and userHref to the authSecPrincipal get colleciton response.
 *  Some authSecPrincipals may not have associated users. In these cases, usertype, user, and userHref will be null.
 */
export const getFilteredAuthSecPrincipalsWithUser = createSelector(
  [getFilteredAuthSecPrincipals, getLocalUsersUsernameSet, getUsernameToUserMap],
  (authSecPrincipals, localUserUsernames, usernameUsersMap) =>
    authSecPrincipals.map(authSecPrincipal => {
      let usertype = null;
      let user = null;

      if (authSecPrincipal.type === 'user') {
        usertype = localUserUsernames.has(authSecPrincipal.nameLow) ? 'local' : 'external';

        if (usernameUsersMap.hasOwnProperty(authSecPrincipal.nameLow)) {
          user = usernameUsersMap[authSecPrincipal.nameLow];
        }
      }

      return {...authSecPrincipal, usertype, user};
    }),
);

/*
 *  Returns authSecPrincipals for local users
 */
export const getLocalUserAuthSecPrincipals = createSelector(getAuthSecPrincipalsWithUser, authSecPrincipals =>
  authSecPrincipals.filter(
    authSecPrincipal => authSecPrincipal.type === 'user' && authSecPrincipal.usertype === 'local',
  ),
);
export const getLocalUserFilteredAuthSecPrincipals = createSelector(
  getFilteredAuthSecPrincipalsWithUser,
  authSecPrincipals =>
    authSecPrincipals.filter(
      authSecPrincipal => authSecPrincipal.type === 'user' && authSecPrincipal.usertype === 'local',
    ),
);

/*
 *  Returns authSecPrincipals for external users
 */
export const getExternalUserAuthSecPrincipals = createSelector(getAuthSecPrincipalsWithUser, authSecPrincipals =>
  authSecPrincipals.filter(
    authSecPrincipal => authSecPrincipal.type === 'user' && authSecPrincipal.usertype === 'external',
  ),
);
export const getFilteredExternalUserAuthSecPrincipals = createSelector(
  getFilteredAuthSecPrincipalsWithUser,
  authSecPrincipals =>
    authSecPrincipals.filter(
      authSecPrincipal => authSecPrincipal.type === 'user' && authSecPrincipal.usertype === 'external',
    ),
);

/*
 *  Returns authSecPrincipals for external groups
 */
export const getExternalGroupAuthSecPrincipals = createSelector(getAuthSecPrincipalsWithUser, authSecPrincipals =>
  authSecPrincipals.filter(authSecPrincipal => authSecPrincipal.type === 'group'),
);
export const getExternalGroupFilteredAuthSecPrincipals = createSelector(
  getFilteredAuthSecPrincipalsWithUser,
  authSecPrincipals => authSecPrincipals.filter(authSecPrincipal => authSecPrincipal.type === 'group'),
);

/*
 * Default read only is a special read only permission that can be toggled on or off for all users.
 * In order to implement this default read only toggle feature, a special group authSecPrincipal is created by the backend.
 *
 * The special default read only authSecPrincipal has name = null, display_name = null, and type = 'group'
 * Then there is a special permission w/ all-all-all scope, read_only role, and this special authSecPrincipal
 *
 * The backend makes everyone a part of this special 'null' group and this group membership can not be modified.
 * If default read only is enabled, a read_only permission is created w/ this null group
 * If default read only is disabled, the read_only permission for this group is deleted
 */
export const getDefaultReadOnlyPrincipal = createSelector(getAuthSecPrincipalsWithUser, authSecPrincipals =>
  authSecPrincipals.find(authSecPrincipal => authSecPrincipal.name === null),
);

/*
 * This selector returns an object which maps authSecPrincipal hrefs to full authSecPrincipal objects
 */
export const getAuthSecPrincipalsHrefMap = createSelector(getAuthSecPrincipalsWithUser, authSecPrincipals =>
  authSecPrincipals.reduce((res, authSecPrincipal) => {
    res[authSecPrincipal.href] = authSecPrincipal;

    return res;
  }, {}),
);

/*
 * This selector returns an object which maps authSecPrincipal for users by names to full authSecPrincipal objects
 */
export const getAuthSecPrincipalsUsersNameMap = createSelector(getAuthSecPrincipalsWithUser, authSecPrincipals =>
  authSecPrincipals.reduce((result, authSecPrincipal) => {
    if (authSecPrincipal.type === 'user') {
      result[authSecPrincipal.nameLow] = authSecPrincipal;
    }

    return result;
  }, {}),
);

/*
 * This selector returns an object which maps authSecPrincipal for groups by names to full authSecPrincipal objects
 */
export const getAuthSecPrincipalsGroupsNameMap = createSelector(getAuthSecPrincipalsWithUser, authSecPrincipals =>
  authSecPrincipals.reduce((result, authSecPrincipal) => {
    if (authSecPrincipal.type === 'group' && authSecPrincipal.name) {
      result[authSecPrincipal.nameLow] = authSecPrincipal;
    }

    return result;
  }, {}),
);
