/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
export const getScopeLabels = scope => {
  const formattedScope = ['All', 'All', 'All'];

  scope.forEach(label => {
    const labelObject = label.label ? label.label : label.label_group;

    if (labelObject) {
      if (labelObject.key === 'app') {
        formattedScope[0] = labelObject.value || labelObject.name;
      } else if (labelObject.key === 'env') {
        formattedScope[1] = labelObject.value || labelObject.name;
      } else if (labelObject.key === 'loc') {
        formattedScope[2] = labelObject.value || labelObject.name;
      }
    }
  });

  return formattedScope.join(' | ');
};

export const getScopes = scopes => scopes.map(scope => getScopeLabels(scope));
