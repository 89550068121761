/**
 * Copyright 2021 Illumio, Inc. All Rights Reserved.
 */
import {call, put, select} from 'redux-saga/effects';
import {RedirectError} from 'errors';
import apiSaga, {apiCachedResponses} from 'api/apiSaga';
import {isIKECertificateEnabled} from './IKECertificatesState';

export function* fetchIKECertificate({force = false} = {}) {
  const ikeCertificateIsEnabled = yield select(isIKECertificateEnabled);

  if (!ikeCertificateIsEnabled) {
    throw new RedirectError({to: 'landing', proceedFetching: true, thisFetchIsDone: true});
  }

  const params = {
    cache: !force,
  };

  const {
    data: {ike_certificate_issuer_name_match},
  } = yield call(apiSaga, 'ven_settings.get', params);
  const ikeCertificate = ike_certificate_issuer_name_match[0].value ?? '';

  yield put({type: 'IKE_CERTIFICATE_GET', data: {ikeCertificate}});
}

export function* updateIKECertificate(ikeCertificate = null) {
  yield call(apiSaga, 'ven_settings.update', {
    data: {
      ike_certificate_issuer_name_match: [
        {
          // as per interface update for EYE-72490:
          // we will only support array of length 1, with scope = [] (i.e. all workloads), but
          // the schema is designed to be flexible to allow scoped settings in the future
          // i.e. choose a different certificate depending on datacenter, dev vs. prod, etc.
          scope: [],
          value: ikeCertificate,
        },
      ],
    },
  });

  apiCachedResponses.removeByMethodName('ven_settings.get');
}
