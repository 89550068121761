/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import cx from 'classnames';
import intl from 'intl';
import _ from 'lodash';
import * as PropTypes from 'prop-types';
import {Component, createRef, cloneElement} from 'react';
import {composeThemeFromProps} from '@css-modules-theme/react';
import {Icon, OptionItem, TypedMessages} from 'components';
import {tidUtils, domUtils} from 'utils';
import styles from './OptionSelector.css';

const defaultSelectorTid = 'comp-field-selector';
const ErrorMessageTid = 'comp-field-error-message';

export default class OptionSelector extends Component {
  static propTypes = {
    // An error message to show but by default it is isn't shown unless showError is set to true
    // Passing in boolean[true | false] will not show errorMessage
    errorMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    disableErrorMessage: PropTypes.bool,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
        subLabel: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]).isRequired,
      }),
    ).isRequired,
    // arbitrary unique name
    name: PropTypes.string.isRequired,
    // When value is passed in then it is controlled
    value: PropTypes.object,
    // When initialValue is passed in then it is uncontrolled
    initialValue: PropTypes.object,
    disabled: PropTypes.bool,
    // Icon properties
    iconSettings: PropTypes.shape({
      position: PropTypes.string,
      name: PropTypes.string.isRequired,
    }),
    // theme object to override css
    theme: PropTypes.object,
    // Callback that is called on upon change, required in case of controlled behavior or uncontrolled
    onChange: PropTypes.func,
    // Callback that is called after changed checked state has been rendered. Useful in case of uncontrolled behavior to notify parent
    onAfterChange: PropTypes.func,
    placeholder: PropTypes.string,
    // tid - specific instead of using default 'name' as tid
    tid: PropTypes.string,
    // valueToNumber: true - convert the value to numeric
    // e.g. <li value='12'>, 12 will convert to Number(12) to sync with formik's values and this Component
    valueToNumber: PropTypes.bool,
    // Exclude dropdown options
    excludeOptions: PropTypes.array,
    // onHandleTypedMessage
    onHandleTypedMessage: PropTypes.func,
  };

  static defaultProps = {
    disabled: false,
    placeholder: intl('Forms.SelectOption'),
    valueToNumber: false,
    tid: '',
    onChange: _.noop,
    disableErrorMessage: false,
    excludeOptions: [],
  };

  constructor(props) {
    super(props);

    this.selector = createRef();

    this.items = createRef();

    this.handleIconOnClick = this.handleIconOnClick.bind(this);
    this.handleDocumentMouseDown = this.handleDocumentMouseDown.bind(this);
    this.handleNameOnClick = this.handleNameOnClick.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleKeyDownOpen = this.handleKeyDownOpen.bind(this);

    this.handleItemOnChange = this.handleItemOnChange.bind(this);
    this.handleOnFocusSelector = this.handleOnFocusSelector.bind(this);

    this.getCustomPicker = this.getCustomPicker.bind(this);
    this.handleCustomPickerCancel = this.handleCustomPickerCancel.bind(this);
    this.handleCustomPickerApply = this.handleCustomPickerApply.bind(this);

    this.state = {
      active: false,
      customPickerActive: false,
      hiddenScroll: false,
      value: (typeof props.value === 'undefined' && props.initialValue) || undefined,
      focus: false,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    // null indicates controlled
    const controlled = nextProps.value === null || typeof nextProps.value === 'object';

    if (controlled && nextProps.value !== prevState.value) {
      // Update value in controlled state.
      return {
        controlled,
        value: nextProps.value,
      };
    }

    return null;
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleDocumentMouseDown);
  }

  componentDidUpdate(prevProps, prevState) {
    // When prevActive === true which means the dropdown selection was previous shown is then
    // closed by prevActive === false, call the parent's controlled onChange handler()
    if (this.state.controlled && prevState.active && !this.state.active) {
      this.props.onChange();
    }

    if (typeof this.props.onAfterChange === 'function' && prevState.value !== this.state.value) {
      this.props.onAfterChange(this.state.value, this.props.name);
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleDocumentMouseDown);
  }

  getDropdownSelection(value) {
    return this.props.options.find(val => val.value === value);
  }

  setSelectedValue(item, evt) {
    const {onChange} = this.props;
    const {controlled} = this.state;

    if (controlled) {
      onChange(evt, item);
    } else {
      this.setState({value: item});
    }

    this.setState(prevState => ({
      active: !prevState.active,
    }));
  }

  getCustomPicker(selectedOption) {
    const {options, customPickers} = this.props;
    const customPickerName = options.find(option => selectedOption === option && option.key === 'custom')?.value;
    let customPickerElement;
    let customPickerLabel;

    if (customPickerName && customPickers) {
      const customPicker = customPickers[customPickerName];

      if (customPicker) {
        customPickerLabel = customPicker.label;

        if (this.state.customPickerActive) {
          customPickerElement = cloneElement(customPicker.component, {
            onApply: this.handleCustomPickerApply,
            onCancel: this.handleCustomPickerCancel,
            onSave: this.handleCustomPickerApply,
            onClose: this.handleCustomPickerCancel,
            ...customPicker.props,
          });
        }
      }
    }

    return [customPickerElement, customPickerLabel];
  }

  handleOnFocusSelector() {
    this.setState(() => ({focus: true}));
  }

  handleNameOnClick() {
    this.setState({active: true});
  }

  handleIconOnClick() {
    this.setState(prevState => ({
      active: !prevState.active,
    }));
  }

  handleDocumentMouseDown(evt) {
    if (this.selector && this.selector.current.contains(evt.target) === false) {
      this.setState({active: false, focus: false});

      // Handle custom picker's cancel
      if (this.state.customPickerActive) {
        this.setState({customPickerActive: false});

        if (this.props.onCancel) {
          this.props.onCancel();
        }
      }
    }
  }

  handleEnterSelect(item, evt) {
    this.setSelectedValue(item, evt);
  }

  handleMiscKeys(evt) {
    let isKeyMatched = false;

    if ((evt.key === 'Tab' && !this.state.customPickerActive) || evt.key === 'Escape') {
      this.setState({active: false, focus: false});
      isKeyMatched = true;
    }

    return isKeyMatched;
  }

  /*
   * Handles the arrow down, up, enter key on the items list
   */
  handleKeyDown(evt, item) {
    domUtils.preventEvent(evt);

    const isKeyExist = this.handleMiscKeys(evt);

    if (!isKeyExist) {
      // e.target is where the event occurred
      const target = evt.target;

      switch (evt.key) {
        case 'ArrowDown':
          this.focusNextItem(target);
          break;
        case 'ArrowUp':
          this.focusPreviousItem(target);
          break;
        case 'Enter':
          this.handleItemOnChange(evt, item);
          break;
      }
    }
  }

  /*
   * Handles the arrow down, up, enter key on the parent container div not the items list
   */
  handleKeyDownOpen(evt) {
    evt.stopPropagation();

    const isKeyExist = this.handleMiscKeys(evt);

    if (!isKeyExist) {
      switch (evt.key) {
        case 'ArrowDown':
        case 'Enter':
          this.focusItem(true);
          break;
        case 'ArrowUp':
          this.focusItem(false);
          break;
      }
    }
  }

  handleItemOnChange(evt, item) {
    const {onChange} = this.props;
    const {controlled} = this.state;

    if (controlled) {
      onChange(evt, item);
    } else {
      this.setState({value: item});
    }

    if (item.key === 'custom') {
      this.setState(() => ({
        customPickerActive: true,
      }));
    } else {
      this.setState(prevState => ({
        active: !prevState.active,
      }));
    }
  }

  handleScrollView(node) {
    node.scrollIntoView({behavior: 'auto', block: 'nearest', inline: 'nearest'});
  }

  handleCustomPickerApply(value) {
    if (this.props.onChange) {
      this.props.onChange(null, value);
    }

    this.setState(prevState => ({
      active: !prevState.active,
      customPickerActive: false,
    }));
  }

  handleCustomPickerCancel() {
    if (this.props.onCancel) {
      this.props.onCancel();
    }

    this.setState(prevState => ({
      active: !prevState.active,
      customPickerActive: false,
    }));
  }

  focusItem(active) {
    if (active) {
      this.setState(
        () => ({active, hiddenScroll: true}),
        () => {
          // component is re-rendered and setState complete thus able to access the items
          // for this callback
          const items = Array.from(this.items.current.childNodes);
          // set the first item when the user the arrow down
          const target = items[0];

          target.focus();
        },
      );
    } else {
      this.setState({active});
    }
  }

  focusPreviousItem(focusedItem) {
    const items = Array.from(this.items.current.childNodes);
    const focusedItemIndex = items.indexOf(focusedItem);

    if (focusedItemIndex > -1) {
      // don't loop through - stop at the last item when using the arrow up
      const previousItem = !items[focusedItemIndex - 1] ? items[0] : items[focusedItemIndex - 1];

      previousItem.focus();
      this.handleScrollView(items[focusedItemIndex]);

      if (this.state.hiddenScroll) {
        this.setState({hiddenScroll: false});
      }
    }
  }

  focusNextItem(focusedItem) {
    const items = Array.from(this.items.current.childNodes);
    const focusedItemIndex = items.indexOf(focusedItem);

    if (focusedItemIndex > -1) {
      const len = items.length - 1;
      // don't loop through - stop at the last item when using the arrow down
      const nextItem = !items[focusedItemIndex + 1] ? items[len] : items[focusedItemIndex + 1];

      nextItem.focus();
      this.handleScrollView(items[focusedItemIndex]);

      if (this.state.hiddenScroll) {
        this.setState({hiddenScroll: false});
      }
    }
  }

  render() {
    const {
      options,
      name,
      errorMessage,
      disabled,
      iconSettings,
      tid,
      placeholder,
      disableErrorMessage,
      onHandleTypedMessage,
    } = this.props;
    const theme = composeThemeFromProps(styles, this.props);
    const {value, focus} = this.state;
    const active = this.state.active;
    const iconName = this.state.active ? 'up' : 'down';
    const tidName = tid || name;

    const selectorMain = cx(theme.selectorMain, {
      [theme.selectorActive]: active && this.selector.current !== document.activeElement,
      [theme.disabled]: disabled,
      [theme.selectorError]:
        !disableErrorMessage && !focus && (errorMessage === undefined || typeof errorMessage === 'string'),
    });

    let label = placeholder;
    let iconElement = '';

    const [customPickerElement, customPickerLabel] = this.getCustomPicker(value);

    const optionItems = customPickerElement
      ? []
      : options.reduce((option, val, index) => {
          // Exclude specific dropdown options.
          if (this.props.excludeOptions.includes(val.value)) {
            return option;
          }

          let labelIsActive = false;

          if (value === val) {
            label = customPickerLabel || val.label;
            labelIsActive = true;
          }

          option.push(
            <OptionItem
              key={index}
              item={val}
              tid={tidName}
              theme={theme}
              labelIsActiv={labelIsActive}
              onKeyDown={this.handleKeyDown}
              onChange={this.handleItemOnChange}
            />,
          );

          return option;
        }, []);

    // with Icon
    if (typeof iconSettings === 'object') {
      iconElement = <Icon {...iconSettings} />;
    }

    // By not having css hiddenScroll, the first element is not viewable in the scroll view when overflow-y: scroll is set thus
    // set the overflow-y: hidden to allow the first element to be viewable for first arrow down in the parent selector div
    const dropdownClassName = cx(theme.dropdown, {
      [theme.hiddenScroll]: this.state.hiddenScroll,
      [theme.customPicker]: customPickerElement,
    });

    const labelClassName = cx(theme.selectMainLabel, {[theme.selectorPlaceHolder]: label === placeholder});
    const showCustomMessage = typeof onHandleTypedMessage === 'function';
    const showError = !showCustomMessage && !disableErrorMessage;

    return (
      <>
        <div
          tabIndex="0"
          onFocus={this.handleOnFocusSelector}
          onKeyDown={this.handleKeyDownOpen}
          data-tid={tidUtils.getTid(defaultSelectorTid, tidName) + (disabled ? ' dropdown-disabled' : '')}
          className={selectorMain}
          ref={this.selector}
        >
          {iconElement}
          <div className={labelClassName} onClick={disabled === false ? this.handleNameOnClick : undefined}>
            {customPickerLabel || label}
          </div>
          <div className={theme.iconCheck}>
            <Icon
              onClick={disabled === false ? this.handleIconOnClick : undefined}
              name={iconName}
              theme={{svg: theme.iconSvgArrowDown}}
            />
          </div>
          {this.state.active && (
            <div onBlur={this.handleOnBlurSelector} ref={this.items} className={dropdownClassName}>
              {customPickerElement || optionItems}
            </div>
          )}
        </div>
        {(showCustomMessage || showError) && (
          <div>
            <TypedMessages key="status" gap="gapXSmall">
              {[
                showCustomMessage ? onHandleTypedMessage() : null,

                showError && errorMessage
                  ? {content: errorMessage, color: 'error', fontSize: 'var(--12px)', tid: ErrorMessageTid}
                  : null,
              ]}
            </TypedMessages>
          </div>
        )}
      </>
    );
  }
}
