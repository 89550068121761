/**
 * Copyright 2021 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from 'intl';
import PubSub from 'pubsub';
import {useCallback, useState, useRef, useContext, useEffect, useLayoutEffect, useMemo, createContext} from 'react';
import {sticky} from 'tippy.js';
import {composeThemeFromProps} from '@css-modules-theme/react';
import PropTypes from 'prop-types';
import maxSize from 'popper-max-size-modifier';
import {KEY_BACK_SPACE, KEY_TAB, KEY_ESCAPE, KEY_RIGHT, KEY_UP, KEY_DOWN, KEY_LEFT, KEY_I} from 'keycode-js';
import {useDeepCompareMemo, mutuallyExclusiveTruePropsSpread} from 'utils/react';
import {Button, StatusIcon, Tooltip, TypedMessages} from 'components';
import {AppContext} from 'containers/App/AppUtils';
import {ModalContext} from 'components/Modal/ModalUtils';
import {domUtils, generalUtils, tidUtils} from 'utils';
import {fetchResourceHistory, updateResourceHistory} from './SelectorSaga';
import {
  getLastSelectedActiveCategory,
  getNextVisibleCategoryId,
  INPUT_ID,
  ADD_NEW_ID,
  VALUEPANEL_ID,
  SEARCHBAR_ID,
  SEARCHBAR_CONTAINER_ID,
  categorySuggestionRegex,
  isMovingHighlighted,
  useFilialPiety,
  isHighlightedBlockEvent,
  populateSearchPromises,
  pickSuggestion,
  getOptionId,
  shouldCloseDropdown,
  isValuesMapEqual,
} from './SelectorUtils';
import SearchBar from './SearchBar';
import Dropdown from './Dropdown';
import styles from './Selector.css';
import produce, {enableMapSet} from 'immer';

import {CategoryPresets} from './Presets';

enableMapSet(); //To enable Immer to operate on the native Map and Set collections

Selector.Context = createContext(null);

Selector.propTypes = {
  tid: PropTypes.string, // Additional tid that will be added to default one
  values: PropTypes.instanceOf(Map), // items pre-selected {[resourceId1]: values1: array, [resourceId2]: values2: array}

  // Whether selector should have border and error message
  errors: PropTypes.object,
  errorMessage: PropTypes.string,
  hideErrorMessage: PropTypes.bool,

  helpInfo: PropTypes.any,
  enableReset: PropTypes.bool,

  insensitive: PropTypes.bool, // Makes selector not interactable (not clickable, not tabbable), useful when API call is in progress
  disabled: PropTypes.bool, // Makes selector Input insensitive and applies disabled style
  hideClearAll: PropTypes.bool, // hide 'x' button on the right to delete all items at once
  placeholder: PropTypes.string, // placeholder text to show in selector input bar
  noActiveIndicator: PropTypes.bool,
  inputProps: PropTypes.object, // Props to be passed to selector Input box, e.g. autoFocus: true, data-tid etc

  closeDropdownOnSelection: PropTypes.bool, // Close dropdown on selection change
  dropdownTippyProps: PropTypes.object, // Tooltip props for dropdown tippy
  activeCategoryId: PropTypes.string, // Id of currently open (active) category
  maxHeight: PropTypes.number, // maxHeight of dropdown in pixels
  maxColumns: PropTypes.number, // determines maxWidth, 1 col = 180 px

  footer: PropTypes.any, // custom footer to put at the bottom of the selector
  onSelectionChange: PropTypes.func,

  categories: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape({divider: PropTypes.bool}),
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string,
        placeholder: PropTypes.string, // placeholder text to show in selector input bar when this category is active

        template: PropTypes.string, // Grid area template for resources
        format: PropTypes.any, // Format category name, e.g. Adding icons/tooltip or custom style
        displayResourceAsCategory: PropTypes.bool,
        noActiveIndicator: PropTypes.bool,
        maxColumns: PropTypes.number, // 1 col = 180px

        // type: 'list' | 'container'
        resources: PropTypes.objectOf(
          PropTypes.oneOfType([
            PropTypes.shape({
              type: PropTypes.string, //type: 'container'
              container: PropTypes.node,
              containerProps: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
              unsavedWarningData: PropTypes.object,
              selectIntoResource: PropTypes.string,
              enableFocusLock: PropTypes.bool,

              // Hooks to set values, categories/resources, and errors on selecting an option
              onSelect: PropTypes.func,
              onUnselect: PropTypes.func,
            }),
            PropTypes.shape({
              type: PropTypes.string, //type: 'list'

              name: PropTypes.string, // Resource name
              format: PropTypes.any, // Format resource name, e.g. Adding icons/tooltip or custom style
              infoPanel: PropTypes.any, // Information Panel, collapsible
              // dropdown values
              statics: PropTypes.oneOfType([
                PropTypes.array, // Static values passed from the page
                PropTypes.func, // A function that returns an array of options
              ]),
              // API endpoint Or saga whose response will be merged with values
              // The result of dataProvider should be `{matches, num_matches}`
              dataProvider: PropTypes.oneOfType([
                PropTypes.func, // A saga that returns an object of {matches: array, num_matches: number}
                PropTypes.string, // Name of the api method, in 'class.method' notation, like 'labels.facets'
              ]),
              apiArgs: PropTypes.oneOfType([PropTypes.object, PropTypes.func]), //Api Options such as params, query etc. that are passed down to api.js/fetcher.js
              includeSelectedResources: PropTypes.oneOfType([PropTypes.array, PropTypes.func]), //Ids of resources that will be included in API Query
              validate: PropTypes.func, // Useful to validate user query string, throw a validation error if query string is not valid

              optionProps: PropTypes.shape({
                format: PropTypes.any, // Can be a function, a text, a node to format the options e.g. add icon in option
                filterOptions: PropTypes.func, // A callback function to filter options

                noCheckbox: PropTypes.bool, // Whether to hide checkbox for multiple select options
                hidden: PropTypes.bool,
                disabled: PropTypes.bool,
                visibilityWhenSelected: PropTypes.oneOf(['hidden', 'disabled', 'insensitive']),
                allowMultipleSelection: PropTypes.bool, // allows more than one value to be selected

                isPill: PropTypes.bool,
                pillProps: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
                ...mutuallyExclusiveTruePropsSpread('format', 'isPill'),

                // Tooltip props
                tooltipProps: PropTypes.shape({
                  appearWhen: PropTypes.string, // Show tooltip when appearWhen resource is selected
                  content: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
                  // Other tooltip props can be passed in addition to appearWhen and content
                }),
              }),

              noEmptyBanner: PropTypes.bool,
              enableHistory: PropTypes.bool,
              historyKey: PropTypes.string, // historyKey || dataProvider || resource id

              allowPartial: PropTypes.bool, // adds query string at the top of the list in case of no exact match
              allowCreate: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]), // func returns bool
              createHint: PropTypes.string,
              // Pass a container resourceId that will be unhidden on create option click,
              // Or, pass a callback that will return container resource id
              onCreateEnter: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),

              selectedProps: PropTypes.shape({
                resourceJoiner: PropTypes.oneOf(['or', 'and']),
                valueJoiner: PropTypes.oneOf(['or', 'and']),
                formatResource: PropTypes.func,
                formatValue: PropTypes.func, // A callback to format selected value, e.g. add icon when error Or tooltip when selected
                hideResourceName: PropTypes.bool,

                isPill: PropTypes.bool,
                pillPropsValue: PropTypes.oneOfType([PropTypes.func, PropTypes.object]), // Pass any pillProps here. e.g. icon, category, pinned etc.
                pillPropsResource: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
                ...mutuallyExclusiveTruePropsSpread('formatValue', 'isPill'),
              }),

              noTitle: PropTypes.bool, // whether to hide "Name - 5 of 8 results" at the top of an resource

              hidden: PropTypes.bool, // Hides the resource
              insensitive: PropTypes.bool, // Makes resource insensitive
              disabled: PropTypes.bool, // Makes resource insensitive and apply disabled styles

              // Hooks to set values, categories/resources, and errors on selecting an option
              onSelect: PropTypes.func,
              onUnselect: PropTypes.func,
            }),
          ]),
        ),
      }),
    ]),
  ).isRequired,
};

export default function Selector(props) {
  const {
    categories: categoriesProp,
    closeDropdownOnSelection,
    disabled,
    errorMessage,
    footer,
    hideClearAll,
    hideErrorMessage,
    infoPanel,
    insensitive,
    placeholder = intl('Common.FilterView'),
    noActiveIndicator,
    helpInfo,
    enableReset,
    dropdownTippyProps = {},
    inputProps,
    tid,
    onSelectionChange,
    values: valuesProp,
    useCmdKey,
  } = props;

  const theme = composeThemeFromProps(styles, props);
  const [categoriesState, categoriesSetter] = useState(categoriesProp);
  const [errorsState, errorsSetter] = useState(props.errors ?? {});
  const [valuesState, setValues] = useState(valuesProp ?? new Map());
  const [history, setHistory] = useState({});
  const [active, setActive] = useState();
  const [query, setQuery] = useState('');
  const [suggestion, setSuggestion] = useState('');
  const [activeCategoryIdState, setActiveCategoryId] = useState(props.activeCategoryId);
  const [closeDropdown, setCloseDropdown] = useState(false);
  const [searchPromises, setSearchPromises] = useState({});
  const [showFilteringTips, setShowFilteringTips] = useState(false);

  const {childrenPropsMap, saveChildRef, registerChildHandlers, setHighlightedChild, resetHighlightedChild} =
    useFilialPiety();

  const {
    fetcher,
    store: {prefetcher},
  } = useContext(AppContext);

  const modalContext = useContext(ModalContext);
  // keep prefetcher form dirty props on Selector mount and reset it to previous value on unmount
  const parentFormDirtyRef = useRef(null);
  const highlightedPathArrRef = useRef([]);
  const highlightedRectRef = useRef();
  const shouldDispatchUpdateKVPairsRef = useRef(false);
  const shouldInvokeOnSelectionChange = useRef(false);
  const hasFocusLockRef = useRef(null);
  const dropdownCloseIsHandledByParentRef = useRef();
  const onSelectionChangeRef = useRef(onSelectionChange);

  const focusLockGroupNameRef = useRef();

  if (!focusLockGroupNameRef.current) {
    // passed to FocusLock group prop in search bar and containerResource
    focusLockGroupNameRef.current = generalUtils.randomString(5, true);
  }

  const prevActiveCategoryIdRef = useRef(null); // Needed to trigger kvpairs update when category changes

  const prevStateCategoriesRef = useRef(props.categories); // Needed to go to previous categories state on Go Back action in container forms

  const prevPropValuesRef = useRef(null); // Needed to reinitalize Selector state when initial values changes

  if (!prevPropValuesRef.current) {
    prevPropValuesRef.current = valuesProp;
  }

  const prevPropCategoriesRef = useRef(null); // Needed to reinitalize Selector state when initial categories changes

  if (!prevPropCategoriesRef.current) {
    prevPropCategoriesRef.current = categoriesProp;
  }

  const shouldReinitialize = useMemo(() => {
    const valuesPropChanged = !isValuesMapEqual(prevPropValuesRef.current, valuesProp);

    // reassign prevProp if values or categories props have changed
    if (valuesPropChanged) {
      // Even though values prop has changed, we need to make sure that changed value is different than prevState
      // No need to re-initialize if values state is same as next prop
      // (for e.g. in case of GridFilter onSelectioChange passes the same prev state values as next prop)
      prevPropValuesRef.current = valuesProp;
    }

    const categoriesPropChanged = !_.isEqual(prevPropCategoriesRef.current, categoriesProp);

    if (categoriesPropChanged) {
      prevPropCategoriesRef.current = categoriesProp;

      // return true if categories have changed
      return true;
    }

    // Re-initialize if values prop changed and new prop is not same as prev state
    // For e.g. In list pages, onSelectionChange receives the updated state and pass it back to Selector
    // Selector should not reinitialize in this case even though values next prop is different than prev prop
    const shouldTakeValuesFromProp = valuesPropChanged && !isValuesMapEqual(valuesProp, valuesState);

    if (shouldTakeValuesFromProp) {
      return true;
    }

    return false;
  }, [valuesProp, valuesState, categoriesProp]);

  if (__DEV__ && shouldReinitialize) {
    console.info('Modifying values or categories by parent has reinitialized Selector state');
  }

  // Read values from props on selector reinitialize, otherwise read from state
  const values = useDeepCompareMemo(shouldReinitialize ? props.values ?? new Map() : valuesState);
  const categories = useDeepCompareMemo(shouldReinitialize ? props.categories : categoriesState);
  const errors = useDeepCompareMemo(shouldReinitialize ? props.errors ?? {} : errorsState);

  const allResources = useMemo(
    // A category is an object with resources nested object, we need to flatten and combine resources in a single object
    // and, also assign the resource id and category id to its value
    () =>
      categories.reduce(
        (result, category) => ({
          ...result,
          ..._.mapValues(category.resources, (value, key) => ({
            ...value,
            id: key,
            categoryId: category.id,
            name: value.name ?? category.name, // Assign category name to resource name if resource name is undefined
            displayResourceAsCategory: category.displayResourceAsCategory,
          })),
        }),
        {},
      ),
    [categories],
  );

  const shouldFetchHistory = useDeepCompareMemo(Object.values(allResources).some(({enableHistory}) => enableHistory));

  // active category is set to the category of last value in values
  const lastSelectedValueCategoryId = useMemo(
    () => getLastSelectedActiveCategory({values, categories, allResources}),
    [allResources, values, categories],
  );

  // Get a visible category that renders its options in option panel
  const nextVisibleCategoryId = useMemo(() => getNextVisibleCategoryId(categories), [categories]);

  const activeCategoryId =
    (shouldReinitialize ? props.activeCategoryId : activeCategoryIdState) ??
    lastSelectedValueCategoryId ??
    nextVisibleCategoryId;

  const activeCategory = useDeepCompareMemo(categories.find(({id}) => id === activeCategoryId));
  const prevActiveCategory = useDeepCompareMemo(categories.find(({id}) => id === prevActiveCategoryIdRef.current));
  const activeContainerResource = useMemo(
    () => Object.values(activeCategory.resources).find(({hidden, type}) => type === 'container' && !hidden),
    [activeCategory],
  );
  const activeFormId = useMemo(() => {
    const {containerProps} = activeContainerResource ?? {};
    const formProps = typeof containerProps === 'function' ? containerProps()?.formProps : containerProps?.formProps;

    return formProps?.id ?? '';
  }, [activeContainerResource]);

  hasFocusLockRef.current = activeContainerResource?.enableFocusLock;

  const fetcherTask = useRef(null);

  const setCategories = useCallback(
    modifier => {
      // {[categoryId]: {hidden: boolean, resources: {[resourceId]: {hidden: true}}}}
      categoriesSetter(
        produce(categories, draft => {
          if (categories !== prevStateCategoriesRef.current) {
            prevStateCategoriesRef.current = categories;
          }

          Object.entries(modifier).forEach(([categoryId, categoryProps]) => {
            const categoryIndex = draft.findIndex(({id}) => id === categoryId);

            draft[categoryIndex] = _.merge(draft[categoryIndex], categoryProps);
          });
        }),
      );
    },
    [categories],
  );
  // 'app' : [, , , ,  , v6]    env[4] =
  // 'app' : [,,,,'Error msg']; app[6] = 'error msg'
  const setErrors = useCallback(
    modifier => {
      // path: app[6], errorMsg: 'App error'
      // path: app, errorMsg: undefined
      errorsSetter(
        produce(errors, draft => {
          Object.assign(draft, modifier);

          Object.entries(draft).forEach(([resourceId, errorsInResource]) => {
            if (
              errorsInResource === undefined ||
              (Array.isArray(errorsInResource) && !errorsInResource.some(Boolean))
            ) {
              draft = _.omit(draft, resourceId);
            }
          });
        }),
      );
    },
    [errors],
  );

  const resetHighlighted = useCallback(() => {
    // reset previous highlighted if exists
    if (highlightedPathArrRef.current?.length) {
      resetHighlightedChild(highlightedPathArrRef.current);

      //clear previous highlighted path and rect
      highlightedPathArrRef.current = null;
      highlightedRectRef.current = null;
    }
  }, [resetHighlightedChild]);

  const setHighlighted = useCallback(
    (options = {}) => {
      // set new highlighted
      // setHighlightedChild returns undefined when highlighted is removed, reset highlighted path with empty array in that case
      [highlightedPathArrRef.current, highlightedRectRef.current] = setHighlightedChild(options) ?? [];

      if (highlightedRectRef.current || [KEY_UP, KEY_DOWN].includes(options.direction)) {
        return;
      }

      // If No element found in the left/right direction then search again in up/down direction
      [highlightedPathArrRef.current, highlightedRectRef.current] =
        setHighlightedChild({...options, direction: options.direction === KEY_LEFT ? KEY_UP : KEY_DOWN}) ?? [];
    },
    [setHighlightedChild],
  );

  const handleSetHighlighted = useCallback(
    // Called on hover, reset existing highlighted state and set new highlighted by passing pathArr and optionId
    (evt, options = {}) => {
      resetHighlighted();
      setHighlighted(options);
    },
    [setHighlighted, resetHighlighted],
  );

  const handleToggle = useCallback(evt => {
    evt.stopPropagation();

    // Reset any query string
    setQuery('');
    setActive(active => !active);
  }, []);

  const handleReturnFocus = useCallback(() => {
    const inputElement = childrenPropsMap.get(INPUT_ID).element;

    if (!hasFocusLockRef.current && document.activeElement !== inputElement) {
      inputElement.focus();
    }
  }, [childrenPropsMap]);

  const handleGoBack = useCallback(async () => {
    const {unsavedWarningData} = activeContainerResource ?? {};

    if (prefetcher.formId === activeFormId && prefetcher.formIsDirty) {
      // Cancel confirmation is mounted if active container resource(s) is a form and has unsaved changes
      const answer = await new Promise(resolve =>
        PubSub.publish('UNSAVED.WARNING', {
          selfPublished: true,
          resolve,
          content: intl('Common.DiscardUnsavedChangesMessage'),
          ...unsavedWarningData,
          modalProps: {
            title: intl('Common.DiscardUnsavedChanges'),
            confirmProps: {text: intl('Common.Discard')},
            focusLockProps: {onDeactivation: handleReturnFocus},
            ...unsavedWarningData?.modalProps,
          },
        }),
      );

      if (answer === 'cancel') {
        return answer;
      }

      // Reset formIsDirty in prefetcher to prevent displaying unsaved warning again
      PubSub.publish('FORM.DIRTY', {dirty: false}, {immediate: true});
    }

    categoriesSetter(prevStateCategoriesRef.current);
  }, [activeFormId, prefetcher, activeContainerResource, handleReturnFocus]);

  const handleInputChange = useCallback(
    async (evt, query) => {
      if (prefetcher.formId === activeFormId && prefetcher.formIsDirty) {
        const answer = await handleGoBack(evt);

        if (answer === 'cancel') {
          return;
        }
      }

      setActive(true);
      setSuggestion('');
      setQuery(query);
    },
    [activeFormId, prefetcher, handleGoBack],
  );

  const handleReset = useCallback(() => {
    setValues(prevPropValuesRef.current ?? new Map());
    categoriesSetter(prevPropCategoriesRef.current);
    errorsSetter(props.errors);
    setQuery('');
  }, [props.errors]);

  const handleUnselectValues = useCallback(
    (evt, valuesToUnselectMap) => {
      // call parent onSelectionChange only when values state is changed by user action
      shouldInvokeOnSelectionChange.current = true;

      setValues(
        produce(values, draft => {
          [...Array.from(valuesToUnselectMap)].forEach(([resourceId, valuesToUnselect]) => {
            const valuesFromHandler = allResources[resourceId].onUnselect?.({
              valuesToUnselect,
              values: draft,
              errors,
              categories,
              setCategories,
              setErrors,
            });

            if (valuesFromHandler) {
              return valuesFromHandler;
            }

            const idPath = allResources[resourceId].optionProps?.idPath;
            const valuesInResource = draft
              .get(resourceId)
              .filter(
                selectedValue =>
                  !valuesToUnselect.some(value => getOptionId(value, idPath) === getOptionId(selectedValue, idPath)),
              );

            if (!valuesInResource.length) {
              draft.delete(resourceId);
            } else {
              draft.set(resourceId, valuesInResource);
            }
          });
        }),
      );
    },
    [allResources, errors, categories, values, setCategories, setErrors],
  );

  const handleSelectValue = useCallback(
    (evt, {resourceId, value}) => {
      const resource = allResources[resourceId];
      const selectedValueId = getOptionId(value, resource.optionProps?.idPath);

      // call parent onSelectionChange only when values state is changed by user action
      shouldInvokeOnSelectionChange.current = true;

      if (selectedValueId === ADD_NEW_ID) {
        // unhide the create form container resource and hide other resources in active category option panel
        const categoryModifierObject = {
          [activeCategory.id]: {
            template: '',
            resources: _.mapValues(activeCategory.resources, (value, id) => ({
              hidden:
                id !==
                (typeof resource.onCreateEnter === 'function' ? resource.onCreateEnter(query) : resource.onCreateEnter),
            })),
          },
        };

        setCategories(categoryModifierObject);
      } else {
        const allowMultipleSelection = resource.optionProps?.allowMultipleSelection;

        // If selected value is an option to select/unselect then add this option to selected values
        setValues(
          produce(values, draft => {
            let valuesInResource = draft.get(resourceId) ?? [];

            // If onSelect hook is passed by page then first call it to receive new values that page wants to set
            const valuesFromHandler = resource.onSelect?.({
              path: `${resourceId}[${valuesInResource.length}]`,
              resource,
              value,
              values: draft,
              errors,
              categories,
              setCategories,
              setErrors,
            });

            if (valuesFromHandler) {
              // Set new values if it is provided by page
              return valuesFromHandler;
            }

            if (allowMultipleSelection) {
              // Otherwise push the new selected value to values Map
              valuesInResource.push(value);
            } else {
              valuesInResource = [value];
            }

            if (draft.has(resourceId)) {
              draft.delete(resourceId);
            }

            draft.set(resourceId, valuesInResource);
          }),
        );
      }
    },
    [allResources, query, activeCategory, errors, values, categories, setCategories, setErrors],
  );

  const handleClearValues = useCallback(evt => handleUnselectValues(evt, values), [values, handleUnselectValues]);

  const handleSearchBarClick = useCallback(() => {
    handleReturnFocus(); // Return focus to input if it is not focused
    setActive(true);
  }, [handleReturnFocus]);

  const handleKeyDown = useCallback(
    async evt => {
      if (generalUtils.cmdOrCtrlPressed(evt) && evt.keyCode === KEY_I) {
        setShowFilteringTips(true);

        return;
      }

      if (evt.keyCode === KEY_UP || evt.keyCode === KEY_DOWN) {
        setActive(true); // no-op if dropdown is already active
      }

      const input = childrenPropsMap.get(INPUT_ID).element;
      const movingHighlighted = useCmdKey
        ? isMovingHighlighted(evt)
        : evt.keyCode === KEY_UP ||
          evt.keyCode === KEY_DOWN ||
          (evt.keyCode === KEY_RIGHT && !suggestion && highlightedPathArrRef.current) ||
          (evt.keyCode === KEY_LEFT && (input.selectionStart === 0 || highlightedPathArrRef.current)); // cursor is at the begining of input

      if (
        prefetcher.formId === activeFormId &&
        prefetcher.formIsDirty &&
        ((useCmdKey && evt.keyCode === KEY_RIGHT && suggestion) ||
          (highlightedPathArrRef.current?.length && !movingHighlighted))
      ) {
        const answer = await handleGoBack(evt);

        if (answer === 'cancel') {
          resetHighlighted();

          return;
        }
      }

      if (
        !hasFocusLockRef.current &&
        (evt.keyCode === KEY_ESCAPE || (evt.keyCode === KEY_TAB && !highlightedPathArrRef.current?.length))
      ) {
        // Close dropdown and clear input on escape
        // Close dropdown and clear input on tab if there is no highlighted child, otherwise tab event will be handled by highlighted child
        setQuery('');
        // Set active to false to close the dropdown
        // it is possible to receive Tab/Shift+Tab events when dropdown is not active, skip setting active to prevent re-render
        setActive(active => (active === true ? false : active));

        return;
      }

      if (movingHighlighted) {
        // move highlighted to either valuePanel, categoryPanel or optionPanel
        domUtils.preventEvent(evt);

        const baseRect = highlightedRectRef.current || childrenPropsMap.get(INPUT_ID).element.getBoundingClientRect();

        setHighlighted({rect: baseRect, pathArr: highlightedPathArrRef.current, direction: evt.keyCode});

        return;
      }

      if ((evt.keyCode === KEY_TAB || evt.keyCode === KEY_RIGHT) && suggestion && !query.includes(suggestion)) {
        // If suggestion exists and is not same as query then Right/Tab will autocomplete the suggestion
        domUtils.preventEvent(evt);

        setQuery(`${query}${suggestion}`);

        return;
      }

      if (
        highlightedPathArrRef.current?.length &&
        isHighlightedBlockEvent(evt, highlightedPathArrRef.current?.includes(VALUEPANEL_ID))
      ) {
        // highlightedPathArrRef includes an array of ids from parent to highlighted block
        // For E.g. ['dropdown', 'category_panel'], ['dropdown', 'category_panel', 'option_panel', 'R1'], ['dropdown', 'option_panel', 'R5']
        // Pass keydown event to highlighted block

        domUtils.preventEvent(evt);

        childrenPropsMap
          .get(highlightedPathArrRef.current[0])
          ?.keyDown?.(evt, {pathArr: highlightedPathArrRef.current.slice(1)});

        return;
      }

      if (values.size && evt.keyCode === KEY_BACK_SPACE && !query) {
        const [resourceId, selectedValues] = _.last(Array.from(values));

        handleUnselectValues(evt, new Map([[resourceId, selectedValues.slice(-1)]]));
      }
    },
    [
      activeFormId,
      prefetcher,
      childrenPropsMap,
      query,
      values,
      suggestion,
      handleUnselectValues,
      handleGoBack,
      setHighlighted,
      resetHighlighted,
      useCmdKey,
    ],
  );

  const handleCloseDropdown = useCallback(async () => {
    if (dropdownCloseIsHandledByParentRef.current) {
      // Calling handleCloseDropdown in a timeout allows Selector to listen to UNSAVED.WARNING if published by parent
      // and dropdownCloseIsHandledByParentRef.current is set to true if UNSAVED.WARNING is published by parent
      // reset it to false and skip handler to let parent handle discard changes confirmation
      dropdownCloseIsHandledByParentRef.current = false;

      return;
    }

    if (prefetcher.formId === activeFormId && prefetcher.formIsDirty) {
      // Mount a discard changes confirmation if dropdown form is dirty
      // Go back to previous state of dropdown - for e.g. unmount the container Form before closing the dropdown
      const answer = await handleGoBack();

      if (answer === 'cancel') {
        return;
      }
    }

    // Reset any query string and close the dropdown
    setQuery('');
    setActive(false);
  }, [activeFormId, prefetcher, handleGoBack]);

  const handleClickOutside = useCallback(
    (_, evt) => {
      const searchBarElement = childrenPropsMap.get(SEARCHBAR_ID).element;

      /* Effects and callbacks execution on click outside:
         handleClickOutside -> setCloseDropdown -> two effects: 1. handleCloseDropdown in a timeout 2. listen to UNSAVED.WARNING subscription
       */
      if (shouldCloseDropdown(evt.target, searchBarElement, modalContext)) {
        /* when dropdown is not active then prefetcher fromIsDirty contains parent state,
           so when drodown mounts we capture this information in a parentFormDirtyRef and reset prefetcher formIsDirty
           so that prefetcher formIsDirty reflects selector form state
        */
        if (!prefetcher.formIsDirty && parentFormDirtyRef.current?.dirty) {
          /* when selector form is not dirty but parent form is dirty then
             there are two scenarios w.r.t the target element of onClickOutside event
              1. taget element is not a link, then we should close the dropdown without any unsaved warning confirmation
              2. target element is a link then we should flip prefetcher flags to parent form dirty data
                 so that prefetcher can handle unsaved changes warning
            Since we cannot determine whether or not target element is a link, we will flip prefetcher settings to parent data
           */

          PubSub.publish('FORM.DIRTY', parentFormDirtyRef.current, {immediate: true});
        }

        setCloseDropdown(true);
      }
    },
    [childrenPropsMap, modalContext, prefetcher],
  );

  const handleUpdateKVPairs = useCallback(
    category => {
      // Move recent selections at the top of the list
      setHistory(draft => {
        Object.entries(category.resources).forEach(([resourceId, resource]) => {
          const key =
            resource.historyKey ?? (typeof resource.dataProvider === 'string' ? resource.dataProvider : resourceId);

          // history values that are currently selected (checkbox checked) in reverse order of selection (last selected should appear first in history)
          const selectedValues =
            draft[key] &&
            values
              .get(resourceId)
              ?.filter(value => draft[key].includes(value))
              .reverse();

          if (selectedValues) {
            draft = {
              ...draft,
              [key]: [...selectedValues, ...draft[key].filter(value => !selectedValues.includes(value))],
            };
          }
        });

        // Dispatch KVPairs update only on category change Or dropdown close
        shouldDispatchUpdateKVPairsRef.current = true;

        return draft;
      });
    },
    [values],
  );

  useLayoutEffect(() => {
    if (shouldReinitialize) {
      // reset state if selector has reinitialized
      setValues(values);
      categoriesSetter(categories);
      errorsSetter(errors);
    }
  }, [shouldReinitialize, values, categories, errors]);

  useEffect(() => {
    if (!showFilteringTips) {
      return;
    }

    const filteringTipsTimeout = setTimeout(() => {
      setShowFilteringTips(false); // hide tooltip after a few seconds
    }, 5000);

    return () => clearTimeout(filteringTipsTimeout);
  }, [showFilteringTips]);

  useEffect(() => {
    if (!shouldInvokeOnSelectionChange.current) {
      return;
    }

    shouldInvokeOnSelectionChange.current = false;

    // Execute onSelectionChange only when values state is changed by user action
    // For e.g valuesState change as a result of reinitialization should not call onSelectionChange
    onSelectionChangeRef.current?.(valuesState);

    if (active) {
      // Also populateSearchPromises since we also need to recompute suggestion and highlight when options changes
      setSearchPromises(populateSearchPromises(categories, activeCategoryId, query));
    } else {
      resetHighlighted();
    }

    if (active && closeDropdownOnSelection) {
      // Reset any query string and close the dropdown
      setQuery('');
      setActive(false);
    }
  }, [active, valuesState, categories, activeCategoryId, closeDropdownOnSelection, query, resetHighlighted]);

  useEffect(() => {
    if (!active) {
      return;
    }

    const token = PubSub.subscribe(
      'UNSAVED.WARNING',
      ({selfPublished}) => {
        dropdownCloseIsHandledByParentRef.current = !selfPublished;
      },
      {
        getLast: true,
      },
    );

    return () => {
      PubSub.unsubscribe(token);
      dropdownCloseIsHandledByParentRef.current = false;
    };
  }, [active]);

  useEffect(() => {
    if (!closeDropdown) {
      return;
    }

    const closeDropdownTimeout = setTimeout(() => {
      setCloseDropdown(false); // reset closeDropdown to false
      handleCloseDropdown(); // handleCloseDropdown is called in a timeout in useEffect, this effect is a result of setting closeDropdown state to true
    });

    return () => clearTimeout(closeDropdownTimeout);
  }, [closeDropdown, handleCloseDropdown]);

  useEffect(() => {
    if (active === undefined) {
      return;
    }

    if (active === true && prefetcher.formIsDirty) {
      // Capture parent form dirty data
      parentFormDirtyRef.current = {dirty: prefetcher.formIsDirty, resetForm: prefetcher.resetForm};
    }

    if (active || parentFormDirtyRef.current) {
      // Reset prefetcher form dirty flag when dropdown mounts and flip to parent data on unmount if parent form was dirty
      PubSub.publish('FORM.DIRTY', active ? {dirty: false} : parentFormDirtyRef.current, {immediate: true});
    }
  }, [prefetcher, active]);

  useEffect(() => {
    if (shouldDispatchUpdateKVPairsRef.current) {
      shouldDispatchUpdateKVPairsRef.current = false;
      // Update resource history, when either 1) dropdown closes 2) category is changed
      fetcher.spawn(updateResourceHistory, {data: history});
    }
  }, [fetcher, history]);

  useEffect(() => {
    // KVPairs is updated when either user selects a different category Or Dropdown is closed
    const selectedCategoryIsChanged = prevActiveCategory && prevActiveCategory.id !== activeCategory.id;
    const category = active === false ? activeCategory : selectedCategoryIsChanged ? prevActiveCategory : undefined;

    if (category) {
      handleUpdateKVPairs(category);
    }
  }, [active, handleUpdateKVPairs, activeCategory, prevActiveCategory]);

  useEffect(() => {
    if (!shouldFetchHistory) {
      // Skip if none one of the resource has history enabled
      return;
    }

    fetcherTask.current = fetcher.fork(fetchResourceHistory);

    fetcherTask.current.then(history => {
      fetcherTask.current = null;

      if (history) {
        setHistory(history);
      }
    });

    return () => {
      if (fetcherTask.current) {
        fetcher.cancel(fetcherTask.current);
        fetcherTask.current = null;
      }
    };
  }, [fetcher, fetcherTask, shouldFetchHistory]);

  useEffect(() => {
    // We need to repopulate search promise when loaded options changes
    setSearchPromises(populateSearchPromises(categories, activeCategoryId, query));
  }, [categories, activeCategoryId, query]);

  useEffect(() => {
    if (_.isEmpty(searchPromises)) {
      setSuggestion('');
      resetHighlighted();

      return;
    }

    (async () => {
      const promises = searchPromises;

      try {
        const suggestions = await Promise.all(Object.values(promises).map(({promise}) => promise));

        if (promises !== searchPromises) {
          return;
        }

        const {suggestion, highlighted} = pickSuggestion(suggestions, query) ?? {};

        setSuggestion(suggestion ?? '');

        // reset previous highlighted if any and set new highlighted
        resetHighlighted();
        setHighlighted(highlighted);
      } catch {
        setSuggestion('');
        resetHighlighted();
      }
    })();
  }, [query, searchPromises, setHighlighted, resetHighlighted]);

  const handleCategoryClick = useCallback(
    (evt, categoryId) => {
      const categoryName = categories.find(({id}) => id === categoryId).name;
      const parts = categorySuggestionRegex.test(query) ? query.split(categorySuggestionRegex) : [];
      const categoryHintText = parts[2]?.trimStart();

      // Remove category hint text and keyword from query
      if (categoryHintText && categoryName.toLowerCase().includes(categoryHintText.toLowerCase())) {
        setQuery(query.split(categorySuggestionRegex)[0].trimEnd());
      }

      // capture previous active category to update its kvpairs entries when active category changes
      // kvpairs recents entries are updated when we move away from an active category either by:
      // 1) selecting other category or 2) by closing the dropdown
      prevActiveCategoryIdRef.current = activeCategoryId;
      setActiveCategoryId(categoryId);
    },
    [categories, query, activeCategoryId],
  );

  const handleSelectedValueClick = useCallback(
    (evt, categoryId) => {
      setActive(true);
      handleCategoryClick(evt, categoryId);
    },
    [handleCategoryClick],
  );

  _.merge(dropdownTippyProps, {
    sticky: true, // Pass sticky true as dropdown rect changes its options loading is complete
    plugins: [sticky], // Checks for reference and popper rect changes and ensures that popper sticks to the reference
    placement: 'auto',
    dark: false,
    bottomStart: true,
    visible: active,
    arrow: false,
    flipBehavior: ['bottom', 'topStart', 'top'],
    interactive: true,
    onClickOutside: handleClickOutside,
    zIndex: typeof modalContext.zIndex === 'number' ? modalContext.zIndex + 1 : 'var(--shadow-above-all-z)',
    noSingleton: true,
    popperOptions: [
      maxSize,
      {
        name: 'applyMaxSize',
        enabled: true,
        phase: 'main',
        requires: ['maxSize'],
        fn({state}) {
          // The `maxSize` modifier provides this data
          const {height: availableHeight} = state.modifiersData.maxSize;
          const defaultMinHeight = 450;
          let minHeight = defaultMinHeight;

          const verticalMargin = 20;

          // When dropdown placement is top, we need to set a margin to prevent dropdown from overlapping over active indicator
          const activeIndicatorOffset = 10;

          let maxHeight = availableHeight - verticalMargin;

          const searchBarTop =
            childrenPropsMap.get(SEARCHBAR_CONTAINER_ID).element.getBoundingClientRect().top - verticalMargin;
          const style = {};

          if (state.placement.includes('top')) {
            // We need to calculate offset between top of the search bar and top of reference (input) and subtract this from available viewport height
            style.marginTop = `${searchBarTop - maxHeight - activeIndicatorOffset}px`;
            maxHeight = searchBarTop - activeIndicatorOffset;
          }

          if (searchBarTop < minHeight && maxHeight < minHeight) {
            minHeight = Math.max(searchBarTop, maxHeight);
          }

          if (props.maxHeight) {
            maxHeight = Math.min(maxHeight, props.maxHeight);
          }

          if (props.maxColumns) {
            style.maxWidth = `${props.maxColumns * 180}px`;
          }

          style.minHeight = `${Math.max(minHeight)}px`;
          style.maxHeight = `${Math.max(minHeight, maxHeight)}px`;

          state.styles.popper = {
            ...state.styles.popper,
            ...style,
          };
        },
      },
    ],
  });

  const tids = [tid];

  if (disabled) {
    tids.push('disabled');
  }

  if (insensitive) {
    tids.push('insensitive');
  }

  const elementProps = {
    activeCategory,
    allResources,
    errors,
    focusLockGroupName: focusLockGroupNameRef.current,
    insensitive,
    onMouseLeave: resetHighlighted,
    onSetHighlighted: handleSetHighlighted,
    query,
    registerHandlers: registerChildHandlers,
    saveRef: saveChildRef,
    theme,
    values,
  };

  // active category is needed because this can be a result of sideeffect and not user action
  // Added activeCategory to avoid having multiple categories in active status
  return (
    <div className={theme.selectorContainer} data-tid={tidUtils.getTid('comp-selector', tids)}>
      <div className={theme.selector}>
        <SearchBar
          {...elementProps}
          inputProps={inputProps}
          hasFocusLockWithContainerResource={hasFocusLockRef.current}
          placeholder={placeholder}
          hideClearAll={hideClearAll}
          disabled={disabled}
          noActiveIndicator={noActiveIndicator}
          active={active}
          suggestion={suggestion}
          error={typeof errorMessage === 'string'}
          onSelectedValueClick={handleSelectedValueClick}
          onValueRemove={handleUnselectValues}
          onKeyDown={handleKeyDown}
          onToggle={handleToggle}
          onInputChange={handleInputChange}
          onClearValues={handleClearValues}
          onSearchBarClick={handleSearchBarClick}
        />
        {(helpInfo || enableReset) && (
          <>
            {helpInfo && (
              <StatusIcon status="help" theme={theme} themePrefix="selectorAlignedIcon-" tooltip={helpInfo} />
            )}
            {enableReset && (
              <Button
                noFill
                disabled={disabled}
                insensitive={insensitive}
                tid="revert"
                icon="revert"
                tooltip={intl('Common.Reset')}
                onClick={handleReset}
              />
            )}
          </>
        )}
      </div>
      <TypedMessages key="status" gap="gapXSmall">
        {[
          !hideErrorMessage && typeof errorMessage === 'string'
            ? {content: errorMessage, color: 'error', fontSize: 'var(--12px)', tid: 'comp-selector-errormessage'}
            : null,
        ]}
      </TypedMessages>
      {active && (
        <Tooltip
          theme={theme}
          content={
            <Dropdown
              {...elementProps}
              onReturnFocusToInput={handleReturnFocus}
              searchPromises={searchPromises}
              categories={categories}
              infoPanel={infoPanel}
              history={history}
              footer={footer}
              showFilteringTips={showFilteringTips}
              onBack={handleGoBack}
              onCategoryClick={handleCategoryClick}
              onOptionSelect={handleSelectValue}
              onOptionUnselect={handleUnselectValues}
              setQuery={setQuery}
              setHistory={setHistory}
              setCategories={setCategories}
            />
          }
          reference={childrenPropsMap.get(INPUT_ID).element}
          maxWidth="96vw"
          {...dropdownTippyProps}
        />
      )}
    </div>
  );
}

Selector.categoryPresets = CategoryPresets;
Selector.emptyMessage = '';
Selector.undefinedValue = undefined;
