/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import {createSelector} from 'reselect';
import {getPairingProfileInstance} from '../PairingProfileItemState';
import {getAllUsersMap} from 'containers/User/UserState';
import {getRouteCurrentParams, isEdge} from 'containers/App/AppState';
import {fillUserInfo} from 'containers/RBAC/RBACUtils';

export const getPairingProfileDetail = createSelector(
  [getPairingProfileInstance, getAllUsersMap, getRouteCurrentParams, isEdge],
  (pairingProfile, usersMap, currentRouteParams, edgeEnabled) => {
    const createdBy = pairingProfile.created_by ? fillUserInfo(usersMap, pairingProfile.created_by) : null;
    const updatedBy = pairingProfile.updated_by ? fillUserInfo(usersMap, pairingProfile.updated_by) : null;
    const generatedBy = pairingProfile.last_pairing_key_generated_by
      ? fillUserInfo(usersMap, pairingProfile.last_pairing_key_generated_by)
      : null;
    const userReadOnly = !pairingProfile.caps.includes('write');
    const isGenerateKeyEnabled =
      Array.isArray(pairingProfile.caps) && pairingProfile.caps.includes('generate_pairing_key');

    return {
      pairingProfile,
      createdBy,
      updatedBy,
      userReadOnly,
      currentRouteParams,
      isGenerateKeyEnabled,
      edgeEnabled,
      generatedBy,
    };
  },
);
