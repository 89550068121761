/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import {Link, Pill} from 'components';
import * as GridUtils from 'components/Grid/GridUtils';
import {hrefUtils} from 'utils';
import {createSelector} from 'reselect';

export const getSelectorSettings = createSelector([], () => ({
  facetMap: {
    service: intl('Common.Service'),
    //description: intl('Common.Description'),
    ip: intl('Common.IPAddress'),
  },
  filterMap: {
    service: intl('Common.Service'),
    //description: intl('Common.Description'),
    ip: intl('Common.IPAddress'),
  },
}));

/**
 [{
  header: string | Function,
  key: string | Function,
  value: string | Function,
  format: Function,
  sort: Function, // Getter for sorting value
  sortFunction: Function, // Custom sort function
  sortable: [true]boolean,
  isDate: boolean | string,
}];
 */

export const gridSettings = createSelector([], () => ({
  id: 'virtualServerRules',
  sort: 'service',
  capacities: [25, 50, 100, 250, 500],
  capacity: 50,
  maxPage: Number.MAX_SAFE_INTEGER,
  showColumns: true,
  showCapacity: true,
  showPagination: true,
  columns: {
    service: {
      header: intl('Common.Service'),
      value: ({row}) => row.service,
      format: ({value}) => <Pill.Service value={{name: value}} />,
    },
    ruleset: {
      linky: true,
      header: intl('Common.Ruleset'),
      value: ({row}) => row.ruleset,
      format: ({value, row}) => {
        const routeParams = {
          id: hrefUtils.getId(row.href),
          pversion: 'draft',
        };

        return (
          <Link to="rulesets.item.view" className="Grid-link" params={routeParams}>
            {value}
          </Link>
        );
      },
    },
    consumers: {
      header: intl('Common.Consumers'),
      value: ({row}) => row.entity,
    },
    secure_connect: {
      header: intl('Common.SecureConnect'),
      value: ({row}) => row.secure_connect,
    },
    ip: {
      header: intl('Common.IPAddress'),
      value: ({row}) => row.ip,
    },
  },
  templates: [
    [
      {columns: ['service'], size: 'minmax(100px, auto)'},
      {columns: ['ruleset'], size: 'minmax(100px, auto)'},
      {columns: ['consumers'], size: 'minmax(100px, auto)'},
      {columns: ['secure_connect'], size: 'minmax(100px, auto)'},
      {columns: ['ip'], size: 'minmax(100px, auto)'},
    ],
    {
      maxWidth: 480,
      template(columns) {
        if (GridUtils.hasOptionalColumns(columns)) {
          //all column breakpoint
          return [{columns: ['service', 'ruleset', 'consumers', 'secure_connect', 'ip'], size: 'auto'}];
        }

        return [{columns: ['service', 'ruleset', 'consumers', 'secure_connect', 'ip'], size: 'auto'}];
      },
    },
  ],
}));
