/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from 'intl';
import PropTypes from 'prop-types';
import PubSub from 'pubsub';
import {Component, createRef} from 'react';
import {object, string} from 'yup';
import {connect} from 'react-redux';
import {AppContext} from 'containers/App/AppUtils';
import {Badge, Button, Modal, ToolGroup, ToolBar, TypedMessages, AttributeList, Form, InfoCard} from 'components';
import {getLabelGroupEdit} from './LabelGroupEditState';
import LabelGroupState from '../LabelGroupState';
import {updateLabelGroup, fetchLabelGroupItem, createLabelGroup, fetchLabelGroupEdit} from '../Item/LabelGroupItemSaga';
import {HeaderProps} from 'containers';
import {reactUtils, hrefUtils} from 'utils';

const LabelGroupTypes = [
  {value: 'role', label: intl('Common.Role')},
  {value: 'app', label: intl('Common.Application')},
  {value: 'env', label: intl('Common.Environment')},
  {value: 'loc', label: intl('Common.Location')},
];

// when container is controlled we will pass the data via containerProps, as opposed to connecting to the store
const makeMapState = (state, props) => (props.controlled ? {} : getLabelGroupEdit(state));

@connect(makeMapState, null, null, {forwardRef: true})
export default class LabelGroupEdit extends Component {
  static prefetch = fetchLabelGroupEdit;
  static contextType = AppContext;
  static reducers = LabelGroupState;

  static propTypes = {
    buttonAlign: PropTypes.oneOf(['top', 'bottom']),
    onDone: PropTypes.func,
    onCancel: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.initialValues = {
      name: props.labelGroup.detail?.draft.name ?? '',
      description: props.labelGroup.detail?.draft.description ?? '',
      type: Form.Utils.findSelectedOption(LabelGroupTypes, props.labelGroup.detail?.draft.key),
    };

    this.schemas = object({
      name: string().max(225).required(Form.emptyMessage),
      type: object().nullable().required(Form.emptyMessage),
      description: string(),
    });

    this.state = {
      saving: false,
      error: null,
      isEdit: props.routeName === 'app.labelGroups.item.summary.edit',
    };

    this.infoCardIconRef = createRef();

    this.renderForm = this.renderForm.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleAlertClose = this.handleAlertClose.bind(this);
  }

  componentDidMount() {
    if (!_.isEmpty(this.props.labelGroup) && !this.state.isEdit) {
      PubSub.publish('FORM.DIRTY', {id: this.props.formProps?.id, dirty: true}, {immediate: true});
    }
  }

  handleAlertClose() {
    this.setState({error: null});
  }

  async handleSave(evt) {
    const {
      props: {labelGroup, onDone},
      state: {isEdit},
      formik: {values, setSubmitting},
      context: {fetcher, navigate},
    } = this;

    try {
      let id;
      const data = {
        name: values.name.trim(),
        description: values.description.trim(),
      };

      if (!isEdit) {
        data.key = values.type.value;
      }

      await reactUtils.setStateAsync({saving: true}, this);

      // Call formik method to set isSubmitting to true
      setSubmitting(true);

      if (isEdit) {
        id = this.props.routeParams.id;

        if (labelGroup.detail.draft.external_data_set) {
          data.external_data_set = labelGroup.detail.draft.external_data_set;
        }

        if (labelGroup.detail.draft.external_data_reference) {
          data.external_data_reference = labelGroup.detail.draft.external_data_reference.includes('\r')
            ? labelGroup.detail.draft.external_data_reference
            : `${labelGroup.detail.draft.external_data_reference}\r`;
        }

        await fetcher.spawn(updateLabelGroup, {params: {label_group_id: id, pversion: 'draft'}, data});
      } else {
        const {
          data: {href},
        } = await fetcher.spawn(createLabelGroup, {params: {pversion: 'draft'}, data});

        id = hrefUtils.getId(href);
      }

      const {
        detail: {draft},
      } = await fetcher.fork(
        fetchLabelGroupItem,
        {name: 'labelGroups.item.summary', params: {id, pversion: 'draft'}},
        true,
      );

      // Wait progress on save button to finish
      await new Promise(onSaveDone => this.setState({onSaveDone, saving: false}));

      if (typeof onDone === 'function') {
        onDone(evt, draft);
      } else {
        // Navigate to a view page
        navigate({
          to: isEdit ? 'labelGroups.item.summary' : 'labelGroups.item.members',
          params: {id, pversion: 'draft'},
        });
      }
    } catch (error) {
      this.setState({error, saving: false});
      // Call formik method to set isSubmitting to false
      setSubmitting(false);
    }
  }

  handleCancel(evt) {
    const {
      props: {onCancel, routeParams},
      state: {isEdit},
      context: {navigate},
    } = this;

    if (typeof onCancel === 'function') {
      return onCancel();
    }

    navigate({
      evt,
      ...(isEdit ? {to: 'labelGroups.item.summary', params: routeParams} : {to: 'labelGroups.list'}),
    });
  }

  renderAlert() {
    const {error, isEdit} = this.state;
    const title = isEdit ? intl('LabelGroups.UpdateError') : intl('LabelGroups.CreateError');

    return (
      <Modal.Alert stretch title={title} onClose={this.handleAlertClose} buttonProps={{text: intl('Common.OK')}}>
        <TypedMessages>{[{icon: 'error', content: error.errors}]}</TypedMessages>
      </Modal.Alert>
    );
  }

  renderForm(options) {
    const {saving, onSaveDone, error, isEdit} = this.state;
    const {isValid} = options;
    const {labelGroup, buttonAlign = 'top', typeIsDisabled, saveButtonProps, cancelButtonProps} = this.props;

    this.formik = options;

    const buttons = (
      <ToolBar>
        <ToolGroup>
          <Button
            icon="save"
            type="submit"
            text={intl('Common.Save')}
            tid="save"
            disabled={isValid === false}
            onClick={this.handleSave}
            onProgressDone={onSaveDone}
            progressCompleteWithCheckmark
            progress={saving}
            progressError={error !== null}
            {...saveButtonProps}
          />
          <Button
            icon="cancel"
            text={intl('Common.Cancel')}
            color="standard"
            tid="cancel"
            onClick={this.handleCancel}
            {...cancelButtonProps}
          />
        </ToolGroup>
      </ToolBar>
    );

    return (
      <>
        {buttonAlign === 'top' && buttons}
        <AttributeList>
          {[
            {
              key: <Form.Label name="name" title={intl('Common.Name')} />,
              tid: 'name',
              value: (
                <Form.Input
                  name="name"
                  tid="name"
                  placeholder={intl('LabelGroups.Create.Placeholder.LabelGroupName')}
                />
              ),
            },
            {
              key: <Form.Label name="type" title={intl('Common.Type')} />,
              tid: 'type',
              value: (
                <>
                  <Form.Selector
                    name="type"
                    tid="type"
                    disabled={isEdit || typeIsDisabled}
                    options={LabelGroupTypes}
                    placeholder={intl('LabelGroups.Create.Placeholder.LabelGroupType')}
                  />
                  <InfoCard trigger={this.infoCardIconRef}>
                    {() => [
                      {title: intl('LabelGroups.Types')},
                      {header: intl('Common.Role'), message: intl('Common.RoleDescription')},
                      {header: intl('Common.Application'), message: intl('Common.ApplicationDescription')},
                      {header: intl('Common.Environment'), message: intl('Common.EnvironmentDescription')},
                      {header: intl('Common.Location'), message: intl('Common.LocationDescription')},
                    ]}
                  </InfoCard>
                </>
              ),
              icon: <InfoCard.Icon ref={this.infoCardIconRef} />,
              valueGap: 'gapLarge',
            },
            {
              key: <Form.Label name="description" title={intl('Common.Description')} />,
              tid: 'description',
              value: (
                <Form.Textarea
                  name="description"
                  tid="description"
                  placeholder={intl('LabelGroups.Create.Placeholder.LabelGroupDescription')}
                />
              ),
            },
            isEdit &&
              labelGroup.detail.draft &&
              labelGroup.detail.draft.external_data_set && {
                tid: 'external_data_set',
                key: intl('Common.ExternalSet'),
                value: labelGroup.detail.draft.external_data_set,
              },
            isEdit &&
              labelGroup.detail.draft &&
              labelGroup.detail.draft.external_data_reference && {
                tid: 'external_data_reference',
                key: intl('Common.ExternalReference'),
                valueGap: 'gapMedium gapHorizontal gapAlignStart',
                value: labelGroup.detail.draft.external_data_reference.includes('\r') ? (
                  <>
                    <Badge type="updated" style={{lineHeight: 'var(--21px)'}}>
                      {intl('Common.Edited')}
                    </Badge>
                    <span>{labelGroup.detail.draft.external_data_reference}</span>
                  </>
                ) : (
                  labelGroup.detail.draft.external_data_reference
                ),
              },
            buttonAlign === 'bottom' ? {value: buttons} : null,
          ]}
        </AttributeList>
      </>
    );
  }

  render() {
    const {
      state: {error, isEdit},
      props: {routeParams, controlled, formProps},
    } = this;

    return (
      <>
        {!controlled && (
          <HeaderProps
            title={intl('Labels.Groups')}
            label={`(${intl(isEdit ? 'Common.Edit' : 'Common.Create')})`}
            up={isEdit ? {to: 'labelGroups.item.summary', params: routeParams} : 'labelGroups.list'}
          />
        )}
        <Form enableReinitialize schemas={this.schemas} initialValues={this.initialValues} {...formProps}>
          {this.renderForm}
        </Form>
        {error && this.renderAlert()}
      </>
    );
  }
}
