/**
 * Copyright 2021 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import cx from 'classnames';
import FocusLock from 'react-focus-lock';
import {useCallback, useRef, useState, useLayoutEffect} from 'react';
import {useDeepCompareMemo} from 'utils/react';
import {Button} from 'components';
import ValuePanel from './ValuePanel/ValuePanel';
import {INPUT_ID, SEARCHBAR_ID, SEARCHBAR_CONTAINER_ID} from './SelectorUtils';

export default function SearchBar(props) {
  const {
    theme,
    saveRef,
    placeholder,
    error,
    focusLockGroupName,
    noActiveIndicator,
    errors = {},
    suggestion,
    allResources,
    active,
    disabled,
    insensitive,
    hasFocusLockWithContainerResource,
    hideClearAll,
    query,
    activeCategory,
    registerHandlers,
    onSelectedValueClick,
    onSetHighlighted,
    onMouseLeave,
    onValueRemove,
    onKeyDown,
    onInputChange,
    onClearValues,
    onToggle,
    onSearchBarClick,
  } = props;

  const values = useDeepCompareMemo(props.values);
  const searchBarContainerRef = useRef(null);
  const inputRef = useRef();
  const prevValues = useRef(null);
  const [activeIndicatorLeft, setActiveIndicatorLeft] = useState(null);
  const saveRefCallbackInput = useCallback(
    element => {
      inputRef.current = element;
      saveRef(INPUT_ID, element);
    },
    [saveRef],
  );
  const saveRefSearchBar = useCallback(
    element => {
      saveRef(SEARCHBAR_ID, element);
    },
    [saveRef],
  );

  const saveRefSearchBarContainer = useCallback(
    element => {
      searchBarContainerRef.current = element;
      saveRef(SEARCHBAR_CONTAINER_ID, element);
    },
    [saveRef],
  );

  const handleSearchBarClick = useCallback(
    evt => {
      if (!active && document.activeElement !== inputRef.current) {
        inputRef.current.focus();
      }

      onSearchBarClick(evt);
    },
    [active, onSearchBarClick],
  );

  const handleChange = useCallback(evt => onInputChange(evt, evt.target.value), [onInputChange]);

  useLayoutEffect(() => {
    if (prevValues.current !== values) {
      const searchBarLeft = searchBarContainerRef.current?.getBoundingClientRect().left;
      const inputLeft = inputRef.current?.getBoundingClientRect().left;
      const offset = inputLeft - searchBarLeft;

      setActiveIndicatorLeft(offset);
    }

    prevValues.current = values;
  }, [values]);

  const isInsensitive = insensitive || disabled;

  const searchBarClasses = cx(theme.searchBar, {
    [theme.active]: active,
    [theme.disabled]: disabled,
    [theme.error]: error || Object.values(errors).some(Boolean),
  });

  // SearhBar either shows an activeIndicator Or a placeholder
  const showActiveIndicator = active && !noActiveIndicator && !activeCategory.noActiveIndicator;
  const inputPlaceholder = showActiveIndicator ? activeCategory.placeholder : values.size === 0 ? placeholder : null;

  const inputProps = {
    ...props.inputProps,
    ...(isInsensitive && {tabIndex: -1}),
    ...(query || inputPlaceholder ? {} : {size: 1}), // needed to prevent empty input row when Selector is inactive
  };

  return (
    <div className={cx(theme.searchBarContainer, {[theme.clickable]: !isInsensitive})} ref={saveRefSearchBarContainer}>
      <fieldset
        className={searchBarClasses}
        {...(activeIndicatorLeft ? {style: {paddingLeft: activeIndicatorLeft}} : {})}
      >
        <legend className={cx(theme.activeIndicator, {[theme.showActiveIndicator]: showActiveIndicator})}>
          <span>{activeCategory.name}</span>
        </legend>
      </fieldset>
      <div ref={saveRefSearchBar} className={theme.items} onClick={isInsensitive ? undefined : handleSearchBarClick}>
        <ValuePanel
          theme={theme}
          saveRef={saveRef}
          insensitive={insensitive}
          disabled={disabled}
          values={values}
          errors={errors}
          allResources={allResources}
          onRemove={onValueRemove}
          registerHandlers={registerHandlers}
          onSelectedValueClick={onSelectedValueClick}
          onSetHighlighted={onSetHighlighted}
          onMouseLeave={onMouseLeave}
        />
        <FocusLock
          group={focusLockGroupName}
          disabled={!hasFocusLockWithContainerResource}
          className={cx(theme.inputPanel, {[theme.show]: active || values.size === 0})}
        >
          {query && suggestion && !query.includes(suggestion) ? (
            <input tabIndex="-1" readOnly className={theme.suggestion} value={`${query}${suggestion}`} />
          ) : null}
          <input
            disabled={disabled}
            readOnly={insensitive}
            data-tid="comp-selector-input"
            type="text"
            ref={saveRefCallbackInput}
            className={theme.input}
            value={query}
            placeholder={inputPlaceholder}
            onChange={handleChange}
            onKeyDown={onKeyDown}
            {...inputProps}
          />
        </FocusLock>
      </div>
      {!hideClearAll && values.size > 0 && (
        <Button
          theme={theme}
          themePrefix="clearAll-"
          noFill
          insensitive={insensitive}
          disabled={disabled}
          icon="clear"
          tooltip={intl('InstantSearch.ClearAll')}
          onClick={onClearValues}
        />
      )}
      <Button
        noFill
        color="standard"
        tabIndex="-1"
        theme={theme}
        themePrefix="toggle-"
        insensitive={insensitive}
        disabled={disabled}
        icon={active ? 'up' : 'down'}
        onClick={onToggle}
      />
    </div>
  );
}
