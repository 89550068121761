/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import {PureComponent} from 'react';
import {connect} from 'react-redux';
import {TabPanel} from 'components';
import {fetchWorkloadItem} from '../WorkloadSaga';
import {HeaderProps} from 'containers';
import {getWorkloadItemPage} from './WorkloadItemState';
import {showWorkloadReport} from '../WorkloadUtils';

@connect(getWorkloadItemPage)
export default class WorkloadItem extends PureComponent {
  static prefetch = fetchWorkloadItem;

  render() {
    const {
      currentRouteParams,
      workload,
      virtualservices,
      isUserWithReducedScope,
      isExplorerEnabled,
      areVulnerabilitiesEnabled,
      routeName,
      edgeEnabled,
      crowdstrikeEnabled,
    } = this.props;
    const params = {id: currentRouteParams.id};
    const isUnmanaged = !workload.agent.status;
    const isEdit = routeName.endsWith('edit');

    return (
      <>
        <HeaderProps title={intl('Common.Workload')} subtitle={workload.name || workload.hostname} up="workloads" />
        <TabPanel primary>
          {[
            {link: 'workloads.item.view', params, text: intl('Common.Summary'), tid: 'comp-navmenuitem-summary'},
            !edgeEnabled &&
              virtualservices &&
              Boolean(virtualservices.length) && {
                link: 'workloads.item.virtualServices',
                params,
                text: intl('Common.VirtualServices'),
                tid: 'comp-navmenuitem-virtual-services',
                disabled: isEdit,
              },
            !crowdstrikeEnabled && {
              link: 'workloads.item.services',
              params,
              text: intl('Common.Processes'),
              tid: 'comp-navmenuitem-services',
              disabled: isEdit,
            },
            !edgeEnabled &&
              !isUserWithReducedScope &&
              !isUnmanaged &&
              workload.rulesetCaps && {
                link: 'workloads.item.rules',
                params,
                text: intl('Common.Rules'),
                tid: 'comp-navmenuitem-rules',
                disabled: isEdit,
              },
            !edgeEnabled &&
              !isUnmanaged && {
                link: 'workloads.item.boundaries',
                params,
                text: intl('Workloads.EnforcementBoundaries'),
                tid: 'comp-navmenuitem-boundaries',
                disabled: isEdit,
              },
            showWorkloadReport(workload) && {
              link: 'workloads.item.report',
              params,
              text: intl('Common.CompatibilityReport'),
              tid: 'comp-navmenuitem-report',
              disabled: isEdit,
            },
            !crowdstrikeEnabled &&
              isExplorerEnabled &&
              workload.rulesetCaps && {
                link: 'workloads.item.blockedTraffic',
                params,
                text: intl('BlockedTraffic.Name'),
                tid: 'comp-navmenuitem-blockedtraffic',
                disabled: isEdit,
              },
            !edgeEnabled &&
              areVulnerabilitiesEnabled && {
                link: 'workloads.item.vulnerabilities',
                params,
                text: intl('Common.Vulnerabilities'),
                tid: 'comp-navmenuitem-vulnerabilities',
                disabled: isEdit,
              },
          ]}
        </TabPanel>
        {this.props.children}
      </>
    );
  }
}
