/**
 * Copyright 2021 Illumio, Inc. All Rights Reserved.
 */
import {PureComponent} from 'react';
import {fetchRulesetItem} from './RulesetItemSaga';

export default class RulesetItem extends PureComponent {
  static prefetch = fetchRulesetItem;

  render() {
    return this.props.children;
  }
}
