/**
 * Copyright 2021 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import cx from 'classnames';
import {useCallback, useEffect, useState} from 'react';
import {Tooltip, StatusIcon} from 'components';
import CategoryPanel from './CategoryPanel/CategoryPanel';
import OptionPanel from './OptionPanel';
import {generalUtils} from 'utils';
import {DROPDOWN_ID, useFilialPiety} from './SelectorUtils';
import styleUtils from 'utils.css';

export default function Dropdown(props) {
  const {theme, saveRef, onCategoryClick, footer, registerHandlers, activeCategory, ...restProps} = props;

  const [showFilteringTips, setShowFilteringTips] = useState(false);
  const {saveChildRef, registerChildHandlers, setHighlightedChild, resetHighlightedChild, keyDown} = useFilialPiety();

  const saveRefCallback = useCallback(element => saveRef(DROPDOWN_ID, element), [saveRef]);

  useEffect(() => {
    const unregister = registerHandlers(DROPDOWN_ID, {setHighlightedChild, resetHighlightedChild, keyDown});

    return () => unregister();
  }, [registerHandlers, setHighlightedChild, resetHighlightedChild, keyDown]);

  const handleShowFilteringTips = useCallback(() => setShowFilteringTips(true), []);
  const handleHideFilteringTips = useCallback(() => setShowFilteringTips(false), []);

  const os = generalUtils.isMac() ? 'mac' : 'windows';

  const dropdownStyles = cx(theme.dropdown, {
    [theme.hidden]: false,
    [theme.noCategoryPanel]: props.categories.length === 1,
    [theme.clickable]: !props.insensitive,
  });

  // active category is needed because this can be a result of sideeffect and not user action
  // Added activeCategory to avoid having multiple categories in active status
  return (
    <div
      ref={saveRefCallback}
      className={dropdownStyles}
      onClick={props.onReturnFocusToInput}
      data-tid="comp-selector-dropdown"
    >
      {props.categories.length > 1 && (
        <CategoryPanel
          {...restProps}
          activeCategory={activeCategory}
          saveRef={saveChildRef}
          pathArr={[DROPDOWN_ID]}
          theme={theme}
          onClick={onCategoryClick}
          registerHandlers={registerChildHandlers}
        />
      )}
      <OptionPanel
        {...restProps}
        category={activeCategory}
        saveRef={saveChildRef}
        pathArr={[DROPDOWN_ID]}
        theme={theme}
        registerHandlers={registerChildHandlers}
      />
      <div className={theme.footer} data-tid="comp-selector-footer">
        {footer ?? (
          <>
            <Tooltip
              visible={props.showFilteringTips || showFilteringTips}
              content={intl('ObjectSelector.FilteringTipsContent', {os})}
              maxWidth="500px"
            >
              <span
                className={theme.filteringTips}
                onMouseOver={handleShowFilteringTips}
                onMouseLeave={handleHideFilteringTips}
                data-tid="comp-selector-filteringtips"
              >
                <StatusIcon status="help" />
                {intl('ObjectSelector.FilteringTipsLabel', {os})}
              </span>
            </Tooltip>
            <span className={`${styleUtils.gapXSmall} ${styleUtils.gapHorizontalWrap}`}>
              <span className={styleUtils.bold}>↑↓</span>
              <span>{intl('Common.ToNavigate')}</span>
            </span>
            <span className={`${styleUtils.gapXSmall} ${styleUtils.gapHorizontalWrap}`}>
              <span className={`${styleUtils.bold} ${styleUtils.paddingSmallTop}`}>↵</span>
              <span className={styleUtils.paddingXSmallTop}>{intl('Common.ToSelect')}</span>
            </span>
            <span className={`${styleUtils.gapXSmall} ${styleUtils.gapHorizontalWrap}`}>
              <span className={styleUtils.bold}>esc</span>
              <span>{intl('Common.ToClose')}</span>
            </span>
          </>
        )}
      </div>
    </div>
  );
}
