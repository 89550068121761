/**
 * Copyright 2021 Illumio, Inc. All Rights Reserved.
 */
import {Component} from 'react';
import {select} from 'redux-saga/effects';
import {isCrowdstrike} from 'containers/App/AppState';
import {RedirectError} from 'errors';

export default class IKECertificateEnabled extends Component {
  static prefetch = function* () {
    const crowdstrike = yield select(isCrowdstrike);

    if (crowdstrike) {
      throw new RedirectError({to: 'landing', proceedFetching: true, thisFetchIsDone: true});
    }
  };

  render() {
    return this.props.children;
  }
}
