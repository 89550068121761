/**
 * Copyright 2016 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import {Component} from 'react';
import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';
import {Icon, MenuItem, Tooltip} from 'components';
import * as UserSelectors from 'containers/User/UserState';
import {getVersionMismatch, isEdge} from 'containers/App/AppState';
import {AppContext} from 'containers/App/AppUtils';
import {HeaderMenu} from 'containers';
import styles from './UserMenu.css';

const getUserMenu = createStructuredSelector({
  userName: UserSelectors.getUserName,
  userIsExternal: UserSelectors.isUserExternal,
  getVersionMismatch,
  orgName: UserSelectors.getOrgName,
  isEdge,
  userIsMSPOwner: UserSelectors.isUserMSPOwner,
  mspOrgUrl: UserSelectors.getMspOrgUrl,
});

@connect(getUserMenu)
export default class UserMenu extends Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);

    this.state = {displayTooltip: true};
    this.renderContent = this.renderContent.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
    this.handleDisplayTooltip = this.handleDisplayTooltip.bind(this);
  }

  handleLogout() {
    this.props.dispatch({type: 'LOGOUT', unsavedPendingWarning: this.context.store.prefetcher.formIsDirty});
  }

  handleDisplayTooltip() {
    this.setState(prevState => ({displayTooltip: !prevState.displayTooltip}));
  }

  // Pass content as function to prevent creating react elements at the beginning, create only when dropdown is open
  renderContent() {
    const {getVersionMismatch, isEdge, userIsExternal, userIsMSPOwner, mspOrgUrl} = this.props;

    const logoutElement = (
      <MenuItem onSelect={this.handleLogout} text={intl('Users.Logout')} data-tid="comp-navbar-logout" />
    );

    // return immediately to not show the admin and other options
    if (getVersionMismatch !== 0) {
      return logoutElement;
    }

    return (
      <>
        <MenuItem link="myprofile" text={intl('Users.MyProfile')} data-tid="comp-navbar-myprofile" />
        {!isEdge && <MenuItem link="myroles.list" text={intl('Users.MyRoles')} data-tid="comp-navbar-myroles" />}
        {isEdge && userIsMSPOwner && (
          <MenuItem
            link={mspOrgUrl ? {href: mspOrgUrl} : 'mymanagedtenants.list'}
            text={intl('MSP.MyManagedTenants')}
            data-tid="comp-navbar-mymanagedtenants"
          />
        )}
        {!isEdge && !userIsExternal && (
          <MenuItem link="apikeys.list" text={intl('Users.APIKeys.MyAPIKeys')} data-tid="comp-navbar-apikeys" />
        )}
        {logoutElement}
      </>
    );
  }

  render() {
    const {userName, orgName} = this.props;
    const {displayTooltip} = this.state;
    const label = (
      <>
        <div className={styles.avatar}>
          <Icon className={styles.avatarIcon} name="user" />
        </div>
        <div className={styles.name}>{userName}</div>
      </>
    );

    return (
      <Tooltip
        bottom
        visible={displayTooltip ? undefined : false}
        content={
          <div className={styles.organization}>
            <div className={styles.title}>{intl('Common.Organization')}</div>
            <div>{orgName}</div>
          </div>
        }
      >
        <HeaderMenu
          label={label}
          theme={styles}
          tid="Navbar-user"
          icon={<Icon name="down" theme={styles} themePrefix="expandIcon-" />}
          onOpen={this.handleDisplayTooltip}
          onClose={this.handleDisplayTooltip}
        >
          {this.renderContent}
        </HeaderMenu>
      </Tooltip>
    );
  }
}
