/**
 * Copyright 2016 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import cx from 'classnames';
import * as PropTypes from 'prop-types';
import {createContext, useRef, useEffect} from 'react';
import {Formik, Form as FormikForm} from 'formik';
import {mixThemeWithProps} from '@css-modules-theme/react';
import PubSub from 'pubsub';
import FormCheckbox from './Checkbox/FormCheckbox';
import FormCheckboxGroup from './Checkbox/FormCheckboxGroup';
import FormInput from './Input/FormInput';
import FormTextarea from './Textarea/FormTextarea';
import FormRadioGroup from './Radio/FormRadioGroup';
import Radio from './Radio/Radio';
import FormLabel from './Label/FormLabel';
import FormFileUpload from './FileUpload/FormFileUpload';
import FormOptionSelector from './OptionSelector/FormOptionSelector';
import FormSingleGroupMultiItemSelect from './SingleGroupMultiItemSelect/FormSingleGroupMultiItemSelect';
import FormSelector from './FormSelector';
import FormGrid from './Grid/FormGrid';
import FormDurationPicker from './FormDurationPicker/FormDurationPicker';
import FormDateTimeRangePicker from './FormDateTimeRangePicker/FormDateTimeRangePicker';
import {classSplitter, randomString} from 'utils/general';
import {tidUtils} from 'utils';
import stylesUtils from 'utils.css';
import * as FormUtils from './FormUtils';
import {DraftJSPlugins, DraftJSUtils, FormIPListEditor} from './DraftJS';
import styles from './Form.css';
import OptionSelectorStyles from './OptionSelector/OptionSelector.css';

Form.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
  // Callback to use when submitting
  onSubmit: PropTypes.func,
  // Yup validation schema
  schemas: PropTypes.object.isRequired,
  // Gap class to wrap children, by default is just `.gap` to inherit any other parent gap size
  gap: PropTypes.string,
  // do not show cancel confirmation on unsaved Pending changes
  allowLeaveOnDirty: PropTypes.bool,
  autoFocus: PropTypes.bool,
  // will refresh the form when new initialValues are provided
  enableReinitialize: PropTypes.bool,
};

export default function Form(props) {
  let {
    'schemas': validationSchema,
    className,
    gap = 'gap',
    theme,
    children,
    tid,
    'data-tid': dataTid,
    allowLeaveOnDirty = false,
    autoFocus = true,
    ...formikProps
  } = mixThemeWithProps(styles, props);
  const isDirtyRef = useRef(false);
  const isSubmittingRef = useRef(false);
  const id = useRef(null);

  // Assign form a random id until we move to formik 2 and it can accept a ref
  if (!id.current) {
    id.current = `form_${randomString(6, true)}`;
  }

  // Check if focus is already inside the form and set focus on the first element if not
  useEffect(() => {
    if (!autoFocus) {
      return;
    }

    const timeout = setTimeout(() => {
      const form = document.querySelector(`#${id.current}`);

      if (form && !form.contains(document.activeElement)) {
        form.querySelector(`input, textarea, .${OptionSelectorStyles.selectorMain}`)?.focus();
      }
    });

    return () => {
      clearTimeout(timeout);
    };
  }, [autoFocus]); // To run it only on mount

  if (!dataTid) {
    dataTid = tidUtils.getTid('comp-form', tid);
  }

  className = cx(theme.form, classSplitter(stylesUtils, gap), className);
  formikProps.validationSchema = validationSchema;
  formikProps.onSubmit ??= _.noop;
  formikProps.enableReinitialize ??= false;

  return (
    <Form.SchemaContext.Provider value={validationSchema}>
      <Formik {...formikProps}>
        {form => {
          if (!allowLeaveOnDirty) {
            if (isDirtyRef.current !== form.dirty) {
              isDirtyRef.current = form.dirty;

              PubSub.publish(
                'FORM.DIRTY',
                {id: props.id ?? id.current, dirty: form.dirty, resetForm: form.resetForm},
                {immediate: true},
              );
            } else if (form.dirty && isSubmittingRef.current !== form.isSubmitting) {
              isSubmittingRef.current = form.isSubmitting;

              PubSub.publish(
                'FORM.DIRTY',
                {id: props.id ?? id.current, dirty: !form.isSubmitting, resetForm: form.resetForm},
                {immediate: true},
              );
            }
          }

          //remove span and move className back to FormikForm when new version of Formik support ref
          return (
            <FormikForm
              id={props.id ?? id.current}
              className={className}
              data-tid={dataTid}
              onSubmit={form.handleSubmit}
            >
              {typeof children === 'function' ? children(form) : children}
            </FormikForm>
          );
        }}
      </Formik>
    </Form.SchemaContext.Provider>
  );
}

// Create context for passing schema down to form components
Form.SchemaContext = createContext();

// Add form components as static properties, so consumers can render <Form><Form.Checkbox/></Form> without a need to import them separately
Form.Checkbox = FormCheckbox;
Form.CheckboxGroup = FormCheckboxGroup;
Form.Input = FormInput;
Form.Textarea = FormTextarea;
Form.RadioGroup = FormRadioGroup;
Form.Radio = Radio;
Form.Selector = FormOptionSelector;
Form.Utils = FormUtils;
Form.Label = FormLabel;
Form.FileUpload = FormFileUpload;
Form.SingleGroupMultiItemSelect = FormSingleGroupMultiItemSelect;
Form.ObjectSelector = FormSelector;
Form.Grid = FormGrid;
Form.IPListEditor = FormIPListEditor;
Form.DurationPicker = FormDurationPicker;
Form.DateTimeRangePicker = FormDateTimeRangePicker;
Form.DraftJS = {
  Utils: DraftJSUtils,
  Plugins: DraftJSPlugins,
};
Form.emptyMessage = ' ';
