/**
 * Copyright 2016 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl, {setIntlValueMapper} from 'intl';
import {getIntlValueMapper} from 'intl/utils';
import apiSaga from 'api/apiSaga';
import {eventChannel} from 'redux-saga';
import Visibility from 'visibilityjs';
import {UNAUTHORIZED, FORBIDDEN} from 'http-status-codes';
import * as progressBar from 'progressBar';
import {BroadcastChannel} from 'broadcast-channel';
import PubSub from 'pubsub';
import {APIError, RedirectError} from 'errors';
import {webStorageUtils} from 'utils';
import * as UserSelectors from 'containers/User/UserState';
import {call, delay, select, spawn, put, fork, take, all} from 'redux-saga/effects';
import {startFetchPendingPolling, stopFetchPendingPolling} from 'containers/Provisioning/ProvisioningSaga';
import {startFetchHealthPolling, stopFetchHealthPolling} from 'containers/Health/HealthSaga';
import {getVersionMismatch, isCoreServicesEnabled, isEdge, getRouteName} from './AppState';
import {fetchVenLibraries} from 'containers/Ven/Library/VenLibrarySaga';
import {fetchDeviceTypes} from 'containers/LoadBalancer/List/LoadBalancerListSaga';
import {loginUser, startFetchUserOrgPolling, stopFetchUserOrgPolling} from 'containers/User/UserSagas';
import {fetchUserSettings} from 'containers/User/Settings/SettingsSaga';
import {fetchInstantSearchHistory} from 'containers/InstantSearch/InstantSearchSaga';
import {fetchCoreServicesSettings} from 'containers/CoreServices/Settings/CoreServicesSettingsSaga';
import {fetchIKECertificate} from 'containers/IKECertificates/IKECertificatesSaga';
import {fetchOutboundAllowRuleset} from 'edge/containers/OutboundPolicy/OutboundPolicySaga';
import {isMyManagedTenantsEnabled} from 'containers/MSP/MyManagedTenants/MyManagedTenantsState';

const intlValuesMappingForEdge = {
  [intl('Common.Workloads')]: intl('Edge.Endpoints'),
  [intl('Common.Workload')]: intl('Edge.Endpoint'),
  [intl('Workloads.EnforcementBoundary')]: intl('Edge.DenyRule'),
  [intl('Workloads.EnforcementBoundaries')]: intl('Rulesets.DenyRules'),
  [intl('Common.IPList')]: intl('Common.IPRange'),
  [intl('Common.IPLists')]: intl('IPLists.Mixin.Ranges'),
  [intl('Illumio.About.Core')]: intl('Illumio.About.Edge'),
  [intl('Common.ConsumerAndProvider')]: intl('Common.SourcesAndDestinations'),
  [intl('Common.ConsumerOrProvider')]: intl('Common.SourcesOrDestinations'),
  [intl('Common.ProviderAndConsumer')]: intl('Common.DestinationsAndSources'),
  [intl('Common.ProviderOrConsumer')]: intl('Common.DestinationsOrSources'),
  [intl('Common.Consumers')]: intl('Common.Sources'),
  [intl('Explorer.ClearFilters')]: intl('Explorer.ResetToDefault'),
  [intl('Explorer.ProviderNames')]: intl('Explorer.DestinationNames'),
  [intl('Explorer.ProviderIPAddresses')]: intl('Explorer.DestinationIPAddresses'),
  [intl('Common.ProviderFqdn')]: intl('Common.DestinationFqdn'),
  [intl('Explorer.ConsumerNames')]: intl('Explorer.SourceNames'),
  [intl('Explorer.ConsumerIPAddresses')]: intl('Explorer.SourceIPAddresses'),
  [intl('Explorer.ProviderLabels')]: intl('Explorer.DestinationGroups'),
  [intl('Explorer.ConsumerLabels')]: intl('Explorer.SourceGroups'),
  [intl('Common.Consumer')]: intl('Common.Source'),
  [intl('Explorer.ConsumerSelections')]: intl('Explorer.SourceSelections'),
  [intl('Explorer.ProviderSelections')]: intl('Explorer.DestinationSelections'),
  [intl('Help.Desc.IPList')]: intl('Help.Desc.IPRange'),
  [intl('Help.Desc.Providers')]: intl('Help.Desc.Destination'),
  [intl('Help.Desc.Consumers')]: intl('Help.Desc.Source'),
  [intl('Help.Desc.ExplorerSearchFormats')]: intl('Edge.Help.Desc.ExplorerSearchFormats'),
  [intl('PairingProfiles.UnlimitedWorkloadCanPaired')]: intl('Edge.InstallScript.UnlimitedEnpointsCanBePaired'),
  [intl('PairingProfiles.OneWorkloadCanPaired')]: intl('Edge.InstallScript.OneEndpointCanBePaired'),
  [intl('PairingProfiles.UnlimitedTime')]: intl('Edge.InstallScript.UnlimitedTime'),
  [intl('Port.PortProcess')]: intl('Edge.Port.PortProcess'),
  [intl('Common.Provided')]: intl('Edge.Provided'),
  [intl('Common.Consumed')]: intl('Edge.Consumed'),
  [intl('Common.NotConsumed')]: intl('Edge.NotConsumed'),
  [intl('Explorer.SourceInclude')]: intl('Edge.Explorer.SourceInclude'),
  [intl('Explorer.TargetInclude')]: intl('Edge.Explorer.TargetInclude'),
  [intl('Explorer.OrIncludeCP')]: intl('Edge.Explorer.OrIncludeCP'),
  [intl('Explorer.OrIncludePC')]: intl('Edge.Explorer.OrIncludePC'),
  [intl('Explorer.OrExcludeCP')]: intl('Edge.Explorer.OrExcludeCP'),
  [intl('Explorer.OrExcludePC')]: intl('Edge.Explorer.OrExcludePC'),
  [intl('Explorer.SourceExclude')]: intl('Edge.Explorer.SourceExclude'),
  [intl('Explorer.TargetExclude')]: intl('Edge.Explorer.TargetExclude'),
  [intl('GlobalNetwork.CorporateNatPublicIpsDesc')]: intl('Edge.GlobalNetwork.CorporateNatPublicIpsDesc'),
  [intl('Common.AppGroupsMore')]: intl('Common.GroupsMore'),
  [intl('Common.EntitiesIsNotMore')]: intl('Common.ExcludeWorkload'),
  [intl('Common.RFC1918')]: intl('Common.RFC1918Private'),
  [intl('Common.ServiceIsNot')]: intl('Common.ExcludeService'),
  [intl('Common.ServiceIsNotMore')]: intl('Common.ExcludeServiceMore'),
  [intl('IPLists.EnableValidation')]: intl('IPLists.EnableValidationIPs'),
  [intl('IPLists.EnableValidationTooltip')]: intl('IPLists.EnableValidationTooltipIps'),
  [intl('Policy.IdleDesc')]: intl('Policy.IdleDescIllumio'),
  [intl('Firewall.Coexistence.ModeDesc')]: intl('Firewall.Coexistence.ModeDescEdge'),
  [intl('VEN.PairWithPairingProfile')]: intl('VEN.PairVENWithPairingProfile'),
};

export function* fetchCommonData(options) {
  if (Visibility.state() === 'prerender') {
    // If document is prerendering (like in Safari) before user actually pressed enter on the url,
    // wait for any other visibility state to actually start fetching data.
    // It's ok to fetch and render on hidden page, for instance if user opened new tab in background (like cmd+enter)
    yield take(['DOCUMENT_HAS_BECOME_VISIBLE', 'DOCUMENT_HAS_BECOME_HIDDEN']);
  }

  let user = yield select(UserSelectors.getUser);

  if (!user.href) {
    try {
      // Try to login user on PCE with or without authToken
      // If without, browser will send pce_session cookie, that we can't read from javascript (it is HttpOnly)
      user = yield call(loginUser, {authToken: window.authToken});
    } catch (err) {
      if (err instanceof APIError) {
        // If user session does not exist, save url and redirect to login server
        // Other errors will be handled by the App.js or NavigationAlert.js
        // 401
        if ([UNAUTHORIZED].includes(err.response.status)) {
          saveUriForLoginRedirect();

          window.location = err.response?.headers?.get('x-redirect') ?? _loginHref_;

          return yield delay(1e4); // Because redirection is not instant
        }

        // If user doesn't have a permission redirect to a special endpoint login server endpoint EYE-71824
        // 403
        if ([FORBIDDEN].includes(err.response.status) && err.data?.[0]?.token === 'rbac_no_permission_to_this_org') {
          saveUriForLoginRedirect();

          window.location = _noAccessLoginHref_;

          return yield delay(1e4); // Because redirection is not instant
        }
      }

      throw err;
    }

    const edge = yield select(isEdge);

    if (edge) {
      yield fork(fetchIKECertificate);
      yield fork(fetchOutboundAllowRuleset);
      setIntlValueMapper(getIntlValueMapper(intlValuesMappingForEdge));
    }

    if (!user.href || _.isEmpty(user.orgs)) {
      progressBar.end();

      return;
    }

    // check version mismatch, e.g. 0 !=== PCE and UI are different
    if ((yield select(getVersionMismatch)) !== 0 && options.name !== 'app.versionmismatch') {
      throw new RedirectError({to: 'versionmismatch', proceedFetching: true, thisFetchIsDone: true});
    }

    // Get the set of enabled features via optional features api and org flags
    yield call(fetchOptionalFeatures);

    const isOwner = yield select(UserSelectors.isUserOwner);
    const isAdmin = yield select(UserSelectors.isUserAdmin);
    const isCoreServicesFeatureEnabled = yield select(isCoreServicesEnabled);

    yield fork(fetchOrgInstance);

    // Get organization's settings
    if (isOwner) {
      yield fork(fetchOrgSettings);
    }

    // Get the user settings
    yield fork(fetchUserSettings);

    // Get instant search history
    yield spawn(fetchInstantSearchHistory);

    // Get Core Service settings
    if ((isOwner || isAdmin) && isCoreServicesFeatureEnabled) {
      yield spawn(fetchCoreServicesSettings);
    }

    // Make My Managed Tenants list page the landing page for MSP owners
    const routeName = yield select(getRouteName);

    if (routeName === 'app.landing.dashboard') {
      const myManagedTenantsIsEnabled = yield select(isMyManagedTenantsEnabled);
      const mspOrgUrl = yield select(UserSelectors.getMspOrgUrl);

      if (myManagedTenantsIsEnabled && !mspOrgUrl) {
        throw new RedirectError({to: 'mymanagedtenants', proceedFetching: true, thisFetchIsDone: true});
      }
    }
  }
}

// Polling saga called after all content is rendered for the first time
export function* fetchDeferredPollingData() {
  // Start provision polling cycle to show provision count on menu (doesn't block this saga) except for workload manager
  yield spawn(startFetchPendingPolling, {instantStart: true});

  yield spawn(startFetchHealthPolling, {instantStart: true});

  yield spawn(startFetchUserOrgPolling, {instantStart: false});
}
// One-off saga, that is called from App.js after all content is rendered for the first time, and
// starts making some calls after that to not block the AppSaga itself or current route children's saga
export function* fetchDeferredData() {
  // Check if any VENs are installed to determine whether to display in main menu
  yield spawn(fetchVenLibraries);

  yield spawn(fetchDeviceTypes); // show SLB page when >= 1 NFCs

  yield spawn(watchUserOrgChanges);

  // Check provision calculation progress status (doesn't block this saga)
  yield put({type: 'PROVISION_CHECK_CALC_PROGRESS'});
}

// Restart polling fetches on user org permission changes
export function* watchUserOrgChanges() {
  while (true) {
    yield call(fetchDeferredPollingData);

    yield take('USER_GET_ORG_SUCCESS');

    yield all([call(stopFetchPendingPolling), call(stopFetchUserOrgPolling), call(stopFetchHealthPolling)]);
  }
}

const logoutBroadcastChannel = new BroadcastChannel('userLogoutChannel', {webWorkerSupport: false});
const logoutSagaChannel = () =>
  eventChannel(emitter => {
    logoutBroadcastChannel.onmessage = event => {
      emitter({url: event.data});
    };

    return logoutBroadcastChannel.close;
  });

export function* watchLogout() {
  // Spawn a saga to transform logout channel to LOGOUT action
  yield spawn(function* () {
    const {url} = yield take(yield call(logoutSagaChannel));

    yield put({type: 'LOGOUT', url, broadcasted: true});
  });

  while (true) {
    const {url, broadcasted = false, unsavedPendingWarning} = yield take('LOGOUT');

    if (unsavedPendingWarning) {
      const answer = yield new Promise(resolve => {
        PubSub.publish('UNSAVED.WARNING', {resolve});
      });

      if (answer === 'cancel') {
        continue;
      }
    }

    // In case of logout/session expiration
    // Reset formIsDirty in prefetcher to prevent 'beforeunload' displaying browser native leave page warning
    PubSub.publish('FORM.DIRTY', {dirty: false}, {immediate: true});

    const redirectUrl = url || `${(yield select(UserSelectors.getUser)).login_url ?? _loginPureHref_ ?? ''}/logout`;

    webStorageUtils.removeItem('user_id');
    webStorageUtils.removeItem(`${browser.code}WarningClosed`);
    webStorageUtils.clearSession();

    saveUriForLoginRedirect();

    if (!broadcasted) {
      logoutBroadcastChannel.postMessage(redirectUrl);
    }

    window.location = redirectUrl;
  }
}

export function saveUriForLoginRedirect() {
  const uri = window.getUri();

  if (uri !== '/landing') {
    window.saveUriForLoginRedirect(uri);
  }
}

export function* fetchOptionalFeatures() {
  try {
    const {data} = yield call(apiSaga, 'optional_features.get');

    yield put({type: 'OPTIONAL_FEATURES', data});
  } catch (error) {
    console.warn('Could not fetch Optional Features:', error.message);
  }
}

export function* fetchOrgInstance() {
  const {data} = yield call(apiSaga, 'orgs.get_instance');

  yield put({type: 'GET_ORG_INSTANCE', data});
}

export function* updateOrgInstance({requireSecPolicyCommitMessage, reverseProviderConsumer}) {
  yield call(apiSaga, 'org.update', {
    data: {
      require_sec_policy_commit_message: requireSecPolicyCommitMessage === 'yes',
      reverse_provider_consumer_column: reverseProviderConsumer === 'consumerFirst',
    },
  });
  yield call(fetchOrgInstance);
}

export function* fetchOrgSettings() {
  const {data} = yield call(apiSaga, 'settings.get');

  yield put({type: 'GET_ORG_SETTINGS', data});
}

export function* updateOrgSettings(data) {
  yield call(apiSaga, 'settings.update', {data});
  yield call(fetchOrgSettings);
}
