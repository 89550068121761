/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import {clickableLabelColumn, hasOptionalColumns} from 'components/Grid/GridUtils';
import {getVenName, venStatuses, getVenConditions} from '../VenUtils';
import {noScopeLabels} from 'containers/Selectors/SelectorUtils';
import {DateTimeCustomPicker, DateTimeSingleCustomPicker, VersionRangePicker} from 'components';
import {edge} from 'api/apiUtils';
import {createSelector} from 'reselect';

export const resourceType = 'agent_infos';

const validateDisconnectedBefore = val => val <= new Date();
const fromPresets = [
  {name: 'anytime', label: 'Anytime', value: null},
  {name: 'custom', label: 'Custom Time', value: 'custom'},
];

const toPresets = [
  {name: 'now', label: 'Now', value: new Date()},
  {name: 'custom', label: 'Custom Time', value: 'custom'},
];

const objectMap = {
  vens: {type: 'vens'},
  roleLabels: {type: 'labels', key: 'role'},
  appLabels: {type: 'labels', key: 'app'},
  envLabels: {type: 'labels', key: 'env'},
  locLabels: {type: 'labels', key: 'loc'},
  container_clusters: {type: 'container_clusters'},
  health: {type: 'health'},
};

export const getSelectorSettings = createSelector([], () => ({
  objectMap,
  facetMap: {
    name: {value: intl('Common.Name'), object: objectMap.vens},
    description: {value: intl('Common.Description'), object: objectMap.vens},
    hostname: {value: intl('Common.Hostname'), object: objectMap.vens},
    os: {value: intl('Common.OS'), object: objectMap.vens},
    version: {value: intl('Common.Version'), object: objectMap.vens},
    ip_address: {value: intl('IPLists.IPAddresses'), object: objectMap.vens},
  },
  scopeMap: {
    role: {
      value: edge ? intl('Common.Group') : intl('Labels.RoleLabels'),
      object: objectMap.roleLabels,
      scope: true,
      statics: edge ? null : noScopeLabels().role,
    },
    ...(!edge && {
      app: {
        value: intl('Labels.ApplicationLabels'),
        object: objectMap.appLabels,
        scope: true,
        statics: noScopeLabels().app,
      },
    }),
    ...(!edge && {
      env: {
        value: intl('Labels.EnvironmentLabels'),
        object: objectMap.envLabels,
        scope: true,
        statics: noScopeLabels().env,
      },
    }),
    ...(!edge && {
      loc: {
        value: intl('Labels.LocationLabels'),
        object: objectMap.locLabels,
        scope: true,
        statics: noScopeLabels().loc,
      },
    }),
  },
  autocompleteMap: {
    container_clusters: {value: intl('VEN.ContainerCluster'), object: objectMap.container_clusters},
  },
  staticMap: {
    status: {value: intl('Common.Status')},
    upgrade_pending: {value: intl('VEN.Upgrade.UpgradePending')},
    last_heartbeat_at: intl('Workloads.HeartbeatLastReceived'),
    disconnected_before: intl('VEN.DisconnectedBefore'),
    health: intl('VEN.Health'),
    condition: intl('VEN.Condition'),
    version: intl('Common.Version'),
  },
  staticValues: {
    status: {
      [intl('Common.Active')]: 'active',
      ...(!edge ? {[intl('Workloads.Status.Suspended')]: 'suspended'} : {}),
      [intl('Common.Stopped')]: 'stopped',
      [intl('Common.Uninstalled')]: 'uninstalled',
    },
    upgrade_pending: {
      [intl('Common.True')]: 'true',
      [intl('Common.False')]: 'false',
    },
    security_policy_update_mode: {
      [intl('Workloads.StaticWorkloads')]: 'static',
      [intl('Workloads.AdaptiveWorkloads')]: 'adaptive',
    },
    health: {
      [intl('Common.Error')]: 'error',
      [intl('Common.Warning')]: 'warning',
      [intl('Common.Healthy')]: 'healthy',
    },
    condition: {
      [intl('EventUtils.VENCloneDetected')]: 'agent.clone_detected',
      [intl('EventUtils.VENMissingHeartbeatAfterUpgrade')]: 'agent.missing_heartbeats_after_upgrade',
      [intl('EventUtils.VENMissedHeartbeatsCheck')]: 'agent.missed_heartbeats',
      [intl('EventUtils.VENUpgradeTimeOut')]: 'agent.upgrade_time_out',
      [intl('EventUtils.VENHasTooManyInterfacesReported')]: 'agent.too_many_interfaces_reported',
    },
  },
  customPickers: {
    security_policy_applied_at: (
      <DateTimeCustomPicker fromPresets={fromPresets} toPresets={toPresets} categoryKey="security_policy_applied_at" />
    ),
    security_policy_received_at: (
      <DateTimeCustomPicker fromPresets={fromPresets} toPresets={toPresets} categoryKey="security_policy_received_at" />
    ),
    last_heartbeat_at: (
      <DateTimeCustomPicker fromPresets={fromPresets} toPresets={toPresets} categoryKey="last_heartbeat_at" />
    ),
    disconnected_before: (
      <DateTimeSingleCustomPicker
        inputLabel={intl('Vulnerability.Before')}
        onValidate={validateDisconnectedBefore}
        fromPresets={toPresets}
        categoryKey="disconnected_before"
      />
    ),
    version: <VersionRangePicker categoryKey="version" />,
  },
  customRouteFilters: {
    'security_policy_applied_at[gte]': null,
    'security_policy_applied_at[lte]': null,
    'security_policy_received_at[gte]': null,
    'security_policy_received_at[lte]': null,
    'last_heartbeat_at[gte]': null,
    'last_heartbeat_at[lte]': null,
    'disconnected_before': null,
    'version[gte]': null,
    'version[lte]': null,
    'scope': null,
  },
}));

/**
 [{
  header: string | Function,

  key: string | Function,
  value: string | Function,
  format: Function,
  sort: Function, // Getter for sorting value
  sortFunction: Function, // Custom sort function
  sortable: [true]boolean,
  isDate: boolean | string,
}];
 */

export const gridSettings = createSelector([], () => ({
  id: 'venslist',
  sort: 'name',
  capacities: [25, 50, 100, 250, 500],
  capacity: 50,
  maxPage: Number.MAX_SAFE_INTEGER,
  showColumns: true,
  showCapacity: true,
  showPagination: true,
  columns: {
    checkboxes: {},
    state: {
      header: intl('Common.Status'),
      value: ({row}) => venStatuses()[row.data.status],
      sort: ({row}) => row.data.status,
    },
    conditions: {
      header: intl('VEN.Health'),
      value: ({row}) => getVenConditions(row.data.conditions, 'list'),
    },
    name: {
      linky: true,
      header: intl('Common.Name'),
      value: ({row}) => getVenName(row.data),
    },
    hostname: {
      linky: true,
      header: intl('Common.Hostname'),
      value: ({row}) => row.data?.hostname,
      optional: true,
    },
    version: {
      header: intl('Common.Version'),
      value: ({row}) => row.data.version,
    },
    role: {
      header: edge ? intl('Common.Group') : intl('Common.Role'),
      ...clickableLabelColumn,
    },
    app: {
      header: intl('Common.Application'),
      ...clickableLabelColumn,
    },
    env: {
      header: intl('Common.Environment'),
      ...clickableLabelColumn,
    },
    loc: {
      header: intl('Common.Location'),
      ...clickableLabelColumn,
    },
    family: {
      header: intl('Common.OS'),
      value: ({row}) => row.data.os_id,
    },
    heartbeat: {
      header: intl('VEN.LastHeartbeat'),
      value: 'last_heartbeat_at',
      optional: true,
      isDate: true,
    },
  },

  templates: [
    [
      {columns: ['checkboxes'], size: 'max-content'},
      {columns: ['state'], size: 'minmax(100px, auto)'},
      {columns: ['conditions'], size: 'min-content'},
      {columns: ['name'], size: 'minmax(50px, auto)'},
      {columns: ['hostname'], size: 'minmax(50px, auto)'},
      {columns: ['version'], size: 'minmax(100px, auto)'},
      {columns: ['role'], size: 'minmax(75px, auto)'},
      {columns: ['app'], size: 'minmax(75px, auto)'},
      {columns: ['env'], size: 'minmax(100px, auto)'},
      {columns: ['loc'], size: 'minmax(100px, auto)'},
      {columns: ['family'], size: 'minmax(75px, auto)'},
      {columns: ['heartbeat'], size: 'minmax(75px, auto)'},
    ],
    {
      maxWidth: 1366,
      template(columns) {
        if (hasOptionalColumns(columns)) {
          //all column breakpoint
          return [
            {columns: ['checkboxes'], size: 'max-content'},
            {columns: ['state'], size: 'minmax(100px, auto)'},
            {columns: ['conditions'], size: 'min-content'},
            {columns: ['name'], size: 'minmax(50px, auto)'},
            {columns: ['hostname'], size: 'minmax(50px, auto)'},
            {columns: ['version'], size: 'minmax(100px, auto)'},
            {columns: ['role'], size: 'minmax(75px, auto)'},
            {columns: ['app'], size: 'minmax(75px, auto)'},
            {columns: ['env'], size: 'minmax(100px, auto)'},
            {columns: ['loc'], size: 'minmax(100px, auto)'},
            {columns: ['family'], size: 'minmax(75px, auto)'},
            {columns: ['heartbeat'], size: 'minmax(75px, auto)'},
          ];
        }

        return [
          {columns: ['checkboxes'], size: 'max-content'},
          {columns: ['state'], size: 'minmax(100px, auto)'},
          {columns: ['conditions'], size: 'min-content'},
          {columns: ['name'], size: 'minmax(50px, auto)'},
          {columns: ['hostname'], size: 'minmax(50px, auto)'},
          {columns: ['version'], size: 'minmax(100px, auto)'},
          {columns: ['role'], size: 'minmax(75px, auto)'},
          {columns: ['app'], size: 'minmax(75px, auto)'},
          {columns: ['env'], size: 'minmax(100px, auto)'},
          {columns: ['loc'], size: 'minmax(100px, auto)'},
          {columns: ['family'], size: 'minmax(75px, auto)'},
          {columns: ['heartbeat'], size: 'minmax(75px, auto)'},
        ];
      },
    },
    {
      maxWidth: 1152,
      template(columns) {
        if (hasOptionalColumns(columns)) {
          //all column breakpoint
          return [
            {columns: ['checkboxes'], size: 'max-content'},
            {columns: ['state'], size: 'minmax(100px, auto)'},
            {columns: ['conditions'], size: 'min-content'},
            {columns: ['name'], size: 'minmax(50px, auto)'},
            {columns: ['hostname'], size: 'minmax(50px, auto)'},
            {columns: ['version'], size: 'minmax(100px, auto)'},
            {columns: ['role'], size: 'minmax(75px, auto)'},
            {columns: ['app'], size: 'minmax(75px, auto)'},
            {columns: ['env'], size: 'minmax(100px, auto)'},
            {columns: ['loc'], size: 'minmax(100px, auto)'},
            {columns: ['family'], size: 'minmax(75px, auto)'},
            {columns: ['heartbeat'], size: 'minmax(75px, auto)'},
          ];
        }

        return [
          {columns: ['checkboxes'], size: 'max-content'},
          {columns: ['state'], size: 'minmax(100px, auto)'},
          {columns: ['conditions'], size: 'min-content'},
          {columns: ['name'], size: 'minmax(50px, auto)'},
          {columns: ['hostname'], size: 'minmax(50px, auto)'},
          {columns: ['version'], size: 'minmax(100px, auto)'},
          {columns: ['role'], size: 'minmax(75px, auto)'},
          {columns: ['app'], size: 'minmax(75px, auto)'},
          {columns: ['env'], size: 'minmax(100px, auto)'},
          {columns: ['loc'], size: 'minmax(100px, auto)'},
          {columns: ['family'], size: 'minmax(75px, auto)'},
          {columns: ['heartbeat'], size: 'minmax(75px, auto)'},
        ];
      },
    },
    {
      maxWidth: 960,
      template(columns) {
        if (hasOptionalColumns(columns)) {
          //all column breakpoint
          return [
            {columns: ['checkboxes'], size: 'max-content'},
            {columns: ['state', 'conditions'], size: 'minmax(120px, auto)'},
            {columns: ['name', 'hostname', 'version'], size: 'minmax(100px, auto)'},
            {columns: ['role', 'app', 'env', 'loc'], size: 'minmax(75px, auto)'},
            {columns: ['family', 'heartbeat'], size: 'minmax(55x, auto)'},
          ];
        }

        return [
          {columns: ['checkboxes'], size: 'max-content'},
          {columns: ['state', 'conditions'], size: 'minmax(120px, auto)'},
          {columns: ['name', 'hostname', 'version'], size: 'minmax(100px, auto)'},
          {columns: ['role', 'app', 'env', 'loc'], size: 'minmax(75px, auto)'},
          {columns: ['family', 'heartbeat'], size: 'minmax(55px, auto)'},
        ];
      },
    },
    {
      maxWidth: 800,
      template(columns) {
        if (hasOptionalColumns(columns)) {
          //all column breakpoint
          return [
            {columns: ['checkboxes'], size: 'max-content'},
            {columns: ['state', 'conditions'], size: 'minmax(120px, auto)'},
            {columns: ['name', 'hostname', 'version'], size: 'minmax(100px, auto)'},
            {columns: ['role', 'app', 'env', 'loc'], size: 'minmax(75px, auto)'},
            {columns: ['family', 'heartbeat'], size: 'minmax(55px, auto)'},
          ];
        }

        return [
          {columns: ['checkboxes'], size: 'max-content'},
          {columns: ['state', 'conditions'], size: 'minmax(120px, auto)'},
          {columns: ['name', 'hostname', 'version'], size: 'minmax(100px, auto)'},
          {columns: ['role', 'app', 'env', 'loc'], size: 'minmax(75px, auto)'},
          {columns: ['family', 'heartbeat'], size: 'minmax(55px, auto)'},
        ];
      },
    },
    {
      maxWidth: 640,
      template(columns) {
        if (hasOptionalColumns(columns)) {
          //all column breakpoint
          return [
            {columns: ['checkboxes', 'state', 'conditions', 'heartbeat'], size: 'max-content'},
            {columns: ['name', 'hostname', 'version', 'family'], size: 'minmax(100px, auto)'},
            {columns: ['role', 'app', 'env', 'loc'], size: 'minmax(75px, auto)'},
          ];
        }

        return [
          {columns: ['checkboxes', 'state', 'conditions', 'heartbeat'], size: 'max-content'},
          {columns: ['name', 'hostname', 'version', 'family'], size: 'minmax(100px, auto)'},
          {columns: ['role', 'app', 'env', 'loc'], size: 'minmax(75px, auto)'},
        ];
      },
    },
  ],
}));
