/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */
import {Component} from 'react';
import {isEdge} from 'containers/App/AppState';
import {RedirectError} from 'errors';
import {select} from 'redux-saga/effects';

export default class ContainerCluster extends Component {
  static prefetch = function* () {
    const edgeEnabled = yield select(isEdge);

    if (edgeEnabled) {
      throw new RedirectError({to: 'landing', proceedFetching: true, thisFetchIsDone: true});
    }
  };

  render() {
    return this.props.children;
  }
}
