/**
 * Copyright 2021 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import {call, delay} from 'redux-saga/effects';
import apiSaga from 'api/apiSaga';
import {fetchKvpairs, updateKvpairs} from 'containers/User/Settings/SettingsSaga';
import {getSearchResult} from './SelectorUtils';

export function* fetchMatches(dataProvider, ...args) {
  if (typeof dataProvider === 'string') {
    const {data} = yield call(apiSaga, dataProvider, ...args);

    return data;
  }

  return yield call(dataProvider, ...args);
}

export function* fetchResource({resource, apiOptions, statics = [], searchIndex} = {}) {
  try {
    yield delay(700);

    const {validate, dataProvider} = resource;
    const {query = ''} = apiOptions.query;

    validate?.(query, resource);

    const dataProviderOptions = dataProvider ? yield call(fetchMatches, dataProvider, apiOptions) : [];

    const staticsOptions = searchIndex && query ? getSearchResult(searchIndex, query) : statics;

    return {dataProviderOptions, staticsOptions};
  } catch (error) {
    error.message = _.get(error, 'data[0].message', error.message);

    throw error;
  }
}

export function* fetchResourceHistory() {
  return yield call(fetchKvpairs, {key: 'selector_recent_history', force: true});
}

export function* updateResourceHistory({data}) {
  yield call(updateKvpairs, {key: 'selector_recent_history', data});
}
