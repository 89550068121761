/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import {hrefUtils} from 'utils';
import {createSelector} from 'reselect';
import {getLabelsHrefMap} from 'containers/Label/List/LabelListState';
import {getAllUsersMap, isWorkloadManagerReadOnly, isSuperclusterMember} from 'containers/User/UserState';
import {getScopeLabelByHref} from '../../Selectors/SelectorUtils';
import {getGridSelector, getUrlScopeValue} from 'components/Grid/GridSelectors';
import {gridSettings, getSelectorSettings} from './PairingProfileListConfig';
import {fillUserInfo} from 'containers/RBAC/RBACUtils';
import {isAPIAvailable} from 'api/apiUtils';

export default {
  list(state = [], action) {
    switch (action.type) {
      case 'PAIRING_PROFILE_GET_LIST':
        return action.data.list;
      default:
        return state;
    }
  },

  count(state = {}, action) {
    switch (action.type) {
      case 'PAIRING_PROFILE_GET_LIST':
        return action.data.count;
      default:
        return state;
    }
  },
};

export const getPairingProfiles = state => state.pairingprofiles.list;
export const getPairingProfilesCount = state => state.pairingprofiles.count;

const getPairingProfilesRows = createSelector([getPairingProfiles, getAllUsersMap], (pairingProfile, usersMap) =>
  pairingProfile.map(item => {
    const isAllowed = Array.isArray(item?.caps) && item.caps.includes('write');

    return {
      key: item.href,
      selectable: isAllowed,
      removable: isAllowed,
      data: {
        ...item,
        labels: item.labels.reduce((result, label) => {
          if (label) {
            result[label.key] = {...label, id: hrefUtils.getId(label.href)};
          }

          return result;
        }, {}),
        updated_by: fillUserInfo(usersMap, item.updated_by),
      },
    };
  }),
);

export const getGridSettings = createSelector(gridSettings, gridSettings => {
  const columns = {...gridSettings.columns};

  columns.checkboxes.disabled = !isAPIAvailable('pairing_profiles.delete');

  return {...gridSettings, columns};
});

export const getScopeItems = createSelector(
  [getUrlScopeValue, getLabelsHrefMap, getSelectorSettings],
  (scope, labelsMap, selectorSettingsObject) => {
    if (scope.isEmpty) {
      return [];
    }

    return scope.valid.scope.map(({href}) => {
      const label = labelsMap[href] || getScopeLabelByHref(href) || {};

      return {
        href,
        value: label.value,
        key: label.key,
        categoryKey: label.key,
        scope: true,
        categoryName: _.get(selectorSettingsObject.filterMap[label.key], 'value'),
      };
    });
  },
);

const getGrid = state =>
  getGridSelector(state, {
    settings: getGridSettings,
    rows: getPairingProfilesRows,
    filterMap: getSelectorSettings().filterMap,
  });

export const getPairingProfilesPage = createSelector(
  [
    getPairingProfilesCount,
    getGrid,
    isWorkloadManagerReadOnly,
    getScopeItems,
    getUrlScopeValue,
    isSuperclusterMember,
    getSelectorSettings,
  ],
  (count, grid, readOnlyUser, scopeItems, scope, userIsSuperClusterMember, selectorSettingsObject) => {
    // Selector parameters based on filter and config
    const selector = {
      initialItems: scopeItems.concat(
        Object.entries(grid.filter).map(([categoryKey]) => ({
          categoryKey,
          value: grid.filter[categoryKey][0],
          categoryName:
            selectorSettingsObject.filterMap[categoryKey].value || selectorSettingsObject.filterMap[categoryKey],
        })),
      ),
      objects: Object.values(selectorSettingsObject.objectMap),
      categories: Object.entries(selectorSettingsObject.filterMap).map(([categoryKey, value]) => ({
        categoryKey,
        value: value.value || value,
        object: value.object,
        scope: value.scope,
        statics: value.statics,
      })),
      facets: Object.keys(selectorSettingsObject.facetMap),
      partials: Object.keys(selectorSettingsObject.facetMap),
    };

    const userIsReadOnly = readOnlyUser || userIsSuperClusterMember;

    return {grid, count, selector, userIsReadOnly, scope, userIsSuperClusterMember};
  },
);
