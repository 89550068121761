/**
 * Copyright 2021 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import styles from './CoreServicesItem.css';
import {Badge, Button, Pill, Tooltip, Icon, StatusIcon} from 'components';
import {createSelector} from 'reselect';
import {scopeColumn} from 'containers/RBAC/RBACUtils';
import {friendlyName} from 'containers/Workload/WorkloadUtils';
import cx from 'classnames';
import stylesUtils from 'utils.css';
import {getRouteParams} from 'containers/App/AppState';
import {getId} from 'utils/href';

export const basedOnMap = {
  port_based: intl('CoreServices.PortMatch'),
  flow_based: intl('CoreServices.TrafficFlows'),
  process_based: intl('CoreServices.ProcessNames'),
};

export const statusMap = {
  recommended: intl('Common.New'),
  skip: intl('CoreServices.FollowUp'),
};

export const methodInformantionMap = {
  port_based: intl('CoreServices.PortMatchInformation'),
  flow_based: intl('CoreServices.TrafficFlowsInformation'),
  process_based: intl('CoreServices.ProcessNamesInformation'),
};

const handleAction = (row, component, type) => event => {
  event.stopPropagation();
  component.handleAction(row, type);
};

function formatButton(extraProps, row, component, tab) {
  return extraProps?.span ?? row?.span ? (
    <Button
      color="standard"
      size="small"
      progress={row.undoing}
      text={intl('Common.Undo')}
      onClick={handleAction(row, component, 'undo')}
    />
  ) : (
    <div className={cx(stylesUtils.gapSmall, stylesUtils.gapHorizontalWrap)}>
      <Button
        color="primary"
        size="small"
        text={tab === 'accepted' ? intl('Labels.Edit') : intl('Common.Accept')}
        onClick={handleAction(row, component, tab === 'accepted' ? 'edit_labels' : 'accept')}
      />
      {tab !== 'rejected' ? (
        <Button
          color="standard"
          size="small"
          text={intl('CoreServices.Reject')}
          onClick={handleAction(row, component, 'reject')}
        />
      ) : null}
      {tab === 'recommended' ? (
        <Button
          color="standard"
          size="small"
          text={intl('CoreServices.FollowUp')}
          onClick={handleAction(row, component, 'followup')}
        />
      ) : null}
      {tab !== 'rejected' ? (
        <Button
          color="standard"
          size="small"
          icon="info"
          tooltip={intl('CoreServices.PeerCountInfo')}
          tooltipProps={{topEnd: true, fast: true}}
        />
      ) : null}
    </div>
  );
}

function formatNote(row, component, clickableRef, tab) {
  if (row?.feedback) {
    return (
      <Tooltip content={row.feedback}>
        <Icon
          ref={clickableRef}
          onClick={handleAction(row, component, tab === 'rejected' ? 'reject' : 'followup')}
          name="comment"
        />
      </Tooltip>
    );
  }
}

function formatRecommendationTime(row) {
  const add = 30 * 60 * 1000;
  const lastRunAt = new Date(row.lastRunAt).getTime() + add;
  const lastDetected = new Date(row.last_detected_at).getTime();
  const current = lastRunAt > lastDetected ? intl('CoreServices.Current') : null;

  return <StatusIcon status="inuse" label={current} noTextColor />;
}

function sortDetectedBy(row) {
  if (row.method_name === 'flow_based') {
    return row.confidence;
  }

  return basedOnMap[row.method_name];
}

export const gridSettings = createSelector([getRouteParams], params => ({
  id: `coreServicesItem-${params.tab}`,
  sort: 'status',
  capacities: [25, 50, 100, 250, 500],
  capacity: 50,
  maxPage: Number.MAX_SAFE_INTEGER,
  showCapacity: true,
  showPagination: true,
  showColumns: true,
  columns: {
    checkboxes: {
      disabled: params.tab === 'rejected',
    },
    recommendation: {
      header: intl('CoreServices.Recommendation'),
      manager: params.tab === 'accepted',
      format: ({row}) => formatRecommendationTime(row),
      disabled: params.tab !== 'accepted',
    },
    status: {
      header: intl('Common.Status'),
      tid: 'comp-grid-column-state',
      manager: params.tab === 'recommended',
      defaultOrder: 'desc',
      value: ({row}) => statusMap[row.action],
      format: ({value, row}) => (
        <Badge type={row.action === 'recommended' ? 'new' : 'updated'} theme={styles} themePrefix="status-">
          {value}
        </Badge>
      ),
      disabled: params.tab !== 'recommended',
    },
    basedOn: {
      header: intl('CoreServices.DetectionModel'),
      manager: params.tab === 'recommended',
      value: ({row}) => basedOnMap[row.method_name],
      format: ({row, value}) => (
        <div className={stylesUtils.gapXSmall}>
          <div className={cx(stylesUtils.gapXSmall, stylesUtils.gapHorizontal)}>
            {value}
            <Tooltip content={methodInformantionMap[row.method_name]}>
              <Icon name="info" theme={styles} />
            </Tooltip>
          </div>
          {row.method_name !== 'port_based' ? (
            <div className={styles.confidence}>{intl('CoreServices.Confidence', {confidence: row.confidence})}</div>
          ) : null}
        </div>
      ),
      sort: ({row}) => sortDetectedBy(row),
      disabled: params.tab !== 'recommended',
    },
    server: {
      header: params.tab === 'accepted' ? intl('Common.Workload') : intl('Common.Server'),
      tid: params.tab === 'accepted' ? 'comp-grid-column-workload' : 'comp-grid-column-server',
      format: ({row, component, clickableRef}) => (
        <div className={stylesUtils.gapXSmall}>
          {row.workload?.href ? (
            <div>
              <Pill.Workload
                id={getId(row.workload.href)}
                ref={clickableRef}
                value={row.workload}
                onClick={handleAction(row, component)}
              />
            </div>
          ) : (
            intl('CoreServices.UnknownIP')
          )}
          <div>{row.ip_address}</div>
        </div>
      ),
      sort: ({row}) => (row.workload ? friendlyName(row.workload) : row.ip_address),
    },
    labels: {
      ...scopeColumn,
      header: intl('Common.Labels'),
    },
    note: {
      header: intl('Common.Note'),
      value: ({row}) => row.feedback,
      format: ({row, component, clickableRef}) => formatNote(row, component, clickableRef, params.tab),
    },
    messages: {
      manager: false,
      format: ({row, component}) => {
        const extraProps = component?.state.extraPropsKeyMap.get(row.key);

        if (extraProps?.span ?? row?.span) {
          const name = extraProps?.data?.name;

          return (
            <div className={styles.message}>
              {extraProps?.data?.type === 'accept'
                ? intl('CoreServices.AcceptedCoreService', {name, service: row.coreservice})
                : intl('CoreServices.RejectedCoreService', {name, service: row.coreservice})}
            </div>
          );
        }
      },
    },
    actions: {
      headerManager: intl('Common.Actions'),
      format: ({row, component}) => {
        const extraProps = component?.state.extraPropsKeyMap.get(row.key);

        return formatButton(extraProps, row, component, params.tab);
      },
      sortable: false,
    },
  },

  /* Grid's breakpoints configuration */
  /**
   Each breakpoint can have:
   [{
    id: ?string, // Optional breakpoint id, goes to cellFormat function
    data: ?object, // Object with any data, goes to cellFormat function
    maxWidth: number, // Maximum width for breakpoint, minimum will be calculated automatically, goes to cellFormat function
    template: string | Function, // Columns configuration
  }];
   */
  templates: [
    [
      {columns: ['checkboxes'], size: 'max-content'},
      {columns: ['recommendation'], size: 'minmax(50px, auto)'},
      {columns: ['status'], size: 'minmax(100px, auto)'},
      {columns: ['basedOn'], size: 'minmax(100px, auto)'},
      {columns: ['server'], size: 'minmax(300px, auto)'},
      {columns: ['labels'], size: 'minmax(300px, auto)'},
      {columns: ['note'], size: 'minmax(60px, auto)'},
      {columns: ['messages'], span: [-7, 0]},
      {columns: ['actions'], size: 'minmax(300px, 300px)'},
    ],
    {
      //Large Width - Do not default or the tabs will be wrong
      maxWidth: 5000,
      template:
        params.tab === 'accepted'
          ? [
              {columns: ['checkboxes'], size: 'max-content'},
              {columns: ['recommendation'], size: 'minmax(200px, 200px)'},
              {columns: ['server'], size: 'minmax(150px, auto)'},
              {columns: ['labels'], size: 'minmax(200px, auto)'},
              {columns: ['note'], size: 'minmax(60px, auto)'},
              {columns: ['messages'], span: [-5, 0]},
              {columns: ['actions'], size: 'max-content'},
            ]
          : params.tab === 'rejected'
          ? [
              {columns: ['server'], size: 'minmax(300px, auto)'},
              {columns: ['labels'], size: 'minmax(200px, auto)'},
              {columns: ['note'], size: 'minmax(60px, auto)'},
              {columns: ['messages'], span: [-3, 0]},
              {columns: ['actions'], size: 'max-content'},
            ]
          : [
              {columns: ['checkboxes'], size: 'max-content'},
              {columns: ['status'], size: 'minmax(100px, 100px)'},
              {columns: ['basedOn'], size: 'minmax(100px, auto)'},
              {columns: ['server'], size: 'minmax(100px, auto)'},
              {columns: ['labels'], size: 'minmax(100px, auto)'},
              {columns: ['note'], size: 'minmax(60px, auto)'},
              {columns: ['messages'], span: [-5, 0]},
              {columns: ['actions'], size: 'max-content'},
            ],
    },
    {
      maxWidth: 760,
      template:
        params.tab === 'rejected'
          ? [
              {columns: ['server', 'labels'], size: 'minmax(150px, auto)'},
              {columns: ['note'], size: 'minmax(60px, auto)'},
              {columns: ['messages'], span: [-2, 0]},
              {columns: ['actions'], size: 'max-content'},
            ]
          : [
              {columns: ['checkboxes'], size: 'max-content'},
              {columns: ['recommendation', 'status', 'basedOn'], size: 'max-content'},
              {columns: ['server', 'labels'], size: 'minmax(100px, auto)'},
              {columns: ['note'], size: 'minmax(60px, auto)'},
              {columns: ['messages'], span: [-4, 0]},
              {columns: ['actions'], size: 'minmax(100px, auto)'},
            ],
    },
    {
      maxWidth: 525,
      template: [
        {columns: ['checkboxes'], size: 'max-content'},
        {
          columns: ['recommendation', 'status', 'basedOn', 'server', 'labels'],
          size: 'minmax(200px, 200px)',
        },
        {columns: ['messages'], span: [-2, 0]},
        {columns: ['actions', 'note'], size: 'minmax(100px, auto)'},
      ],
    },
  ],
}));
