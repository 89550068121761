/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import {createSelector} from 'reselect';
import {getRouteName, getRouteCurrentParams} from 'containers/App/AppState';
import {ProtocolMap} from 'containers/Service/ServiceUtils';
import {isUserReadOnly} from 'containers/User/UserState';
import {portIP} from '../FlowCollectionFiltersUtils';
import {staticValues} from '../List/FlowCollectionFiltersListConfig';
import intl from 'intl';

export default {
  instance(state = {}, action) {
    switch (action.type) {
      case 'FLOWCOLLECTIONFILTERS_GET_ITEM':
        return action.data;
      default:
        return state;
    }
  },
};

export const getFlowCollectionFilter = state => state.flowCollectionFilters.instance;

export const getFlowCollectionFiltersEdit = createSelector(
  [getFlowCollectionFilter, getRouteName, getRouteCurrentParams, isUserReadOnly, staticValues],
  (flowCollection, routeName, currentRouteParams, userIsReadOnly, staticValues) => {
    const flowCollectionSelector = {
      staticValues,
      categories: [{value: intl('Common.Protocol'), categoryKey: 'protocol', freeSearch: false}],
    };

    const flowCollectionFilter =
      routeName === 'app.flowCollectionFilters.create'
        ? {
            action: 'drop',
            transmission: 'broadcast',
          }
        : {
            action: flowCollection.action === 'drop' ? 'drop' : 'aggregate',
            transmission: flowCollection.transmission === 'B' ? 'broadcast' : 'multicast',
            protocol: ProtocolMap()[Number(flowCollection.target.proto)],
            destip: flowCollection?.target[portIP.destIP],
            destport: flowCollection?.target[portIP.destPort],
          };

    const selectorProps = {
      displayAllCategories: true,
      placeholder: intl('Common.SelectProtocol'),
      activeCategoryKey: 'protocol',
      categories: flowCollectionSelector.categories,
      statics: Object.entries(flowCollectionSelector.staticValues).reduce((result, [key, values]) => {
        result[key] = Object.keys(values);

        return result;
      }, {}),
    };

    return {flowCollectionFilter, routeName, currentRouteParams, userIsReadOnly, selectorProps};
  },
);
