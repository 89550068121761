/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import {combineReducers} from 'redux';
import {createSelector} from 'reselect';
import {getSelectorMatches, isLoadingMatches} from 'containers/Selectors/SelectorState';
import {getUserRoles, getOrgId} from 'containers/User/UserState';
import {getTesseReactRoutesNamesMap, getLegacyRoutesDisplayNamesMap} from './InstantSearchContainerProperties';

export default {
  instantSearch: combineReducers({
    active(state = false, action) {
      switch (action.type) {
        case 'TOGGLE_INSTANT_SEARCH':
          return !state;
        default:
          return state;
      }
    },
    filter(state = null, action) {
      switch (action.type) {
        case 'SET_INSTANT_SEARCH_FILTER':
          return action.data;
        default:
          return state;
      }
    },
    history(state = [], action) {
      switch (action.type) {
        case 'SET_INSTANT_SEARCH_HISTORY':
          return action.data;
        default:
          return state;
      }
    },
  }),
};

const containersRouteNamesMap = state =>
  new Map([...getTesseReactRoutesNamesMap().entries(), ...Object.entries(getLegacyRoutesDisplayNamesMap(state))]);

const historyValidator = state => (routesMap, item) => {
  if (!routesMap.has(item)) {
    return false;
  }

  return routesMap.get(item).isAvailable?.(state) ?? true;
};

export const isInstantSearchActive = state => state.instantSearch.active;
export const getInstantSearchFilter = state => state.instantSearch.filter;
export const getInstantSearchHistory = state => state.instantSearch.history;
const getValidatedInstantSearchHistory = createSelector(
  historyValidator,
  containersRouteNamesMap,
  getInstantSearchHistory,
  (validator, routeNames, history) => {
    return history.filter(item => validator(routeNames, item.name));
  },
  {
    // Selector publishes recalculated data when result is changed
    memoizeOptions: {
      resultEqualityCheck: _.isEqual,
    },
  },
);

export const getInstantSearchMatches = createSelector(
  state => getSelectorMatches(state, state.instantSearch.filter),
  getValidatedInstantSearchHistory,
  getInstantSearchFilter,
  (selectorMatches, history, filter) => {
    const matches = [];

    if (selectorMatches?.length) {
      for (const match of selectorMatches) {
        // Sometimes autocomplete returns objects with name or value (for example Workloads and Labels)
        matches.push({key: match.name || match.value, value: match});
      }
    } else if (!filter && history.length) {
      for (const item of history) {
        matches.push({key: item.value, value: item});
      }
    }

    return matches;
  },
);

export const getInstantSearchData = createSelector(
  getOrgId,
  getUserRoles,
  isLoadingMatches,
  isInstantSearchActive,
  getInstantSearchFilter,
  getValidatedInstantSearchHistory,
  getInstantSearchMatches,
  (orgId, userRoles, loading, active, filter, history, matches) => ({
    orgId,
    userRoles,
    loading,
    active,
    filter,
    history,
    matches,
  }),
);
