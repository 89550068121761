/**
 * Copyright 2021 Illumio, Inc. All Rights Reserved.
 */
import {init} from '../app';
import routes from './routes';
import {reducers} from '../reducer';
import * as commonContainers from '../containers';
import * as containers from './containers';
import OutboundPolicyState from './containers/OutboundPolicy/OutboundPolicyState';
import ServiceState from '../containers/Service/ServiceState';
import GroupState from './containers/Group/GroupState';
import WorkloadReducers from '../containers/Workload/WorkloadState';
import LabelState from '../containers/Label/LabelState';
import SecuritySettingsState from '../containers/SecuritySettings/SecuritySettingsState';
import ReportsState from '../containers/Reports/ReportsState';
import ExplorerState from 'containers/Explorer/ExplorerState';
import IKECertificatesState from 'containers/IKECertificates/IKECertificatesState';

// Update global reducers with main Edge reducers
const edgeReducers = {
  ...OutboundPolicyState,
  ...GroupState,
  ...ServiceState,
  ...WorkloadReducers,
  ...LabelState,
  ...SecuritySettingsState,
  ...ReportsState,
  ...ExplorerState,
  ...IKECertificatesState,
};

init({routes, containers: {...commonContainers, ...containers}, reducers: {...reducers, ...edgeReducers}});
