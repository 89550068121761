/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';

// Get params from current params object only valid from specified route upwards in route hierarchy
export function pickUpwardsParams(route, currentParams, meta) {
  if (!route || _.isEmpty(currentParams)) {
    return currentParams;
  }

  const result = {};

  while (route) {
    const name = route.name;
    const metaParams = meta[name];

    for (const param of Object.keys(metaParams)) {
      if (currentParams.hasOwnProperty(param)) {
        result[param] = currentParams[param];
      }
    }

    route = _.last(route.parents);
  }

  return result;
}

// Get routeParams and omit all props from it that are exclusive for the current routeName,
// i.e only params that are used by other routes will remain
export function omitRouteParamsByMeta(routeName, routeParams, meta) {
  if (!routeParams) {
    return routeParams;
  }

  return Object.entries(meta).reduce((result, [name, params]) => {
    if (name !== routeName && params) {
      for (const param of Object.keys(params)) {
        if (routeParams.hasOwnProperty(param)) {
          result[param] = routeParams[param];
        }
      }
    }

    return result;
  }, {});
}

export const areSomeStripRoutesActive = (router, ...names) => names.some(name => router.isActive(`app.${name}`));

// Checks that a given link (string or object) is active
export const isLinkActive = (link, router) => {
  if (typeof link === 'object' && link.to) {
    return router.isActive(`app.${link.to}`, link.params);
  }

  if (typeof link === 'string' && link) {
    return router.isActive(`app.${link}`);
  }

  return false;
};
