/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import {hrefUtils} from 'utils';
import apiSaga, {apiCachedResponses} from 'api/apiSaga';
import {all, call, put, select} from 'redux-saga/effects';
import {cachedResponses} from 'api/apiCache';
import {fetchPairingProfileByLabel} from '../../edge/containers/Group/GroupSaga';
import {isCrowdstrike, getAppGroupLabelTypes, getRouteParams} from 'containers/App/AppState';
import {getWorkloadsHrefMap} from './List/WorkloadListState';
import {getWorkloadInstance} from './Item/WorkloadItemState';
import {RedirectError} from '../../errors';
import {fetchVirtualServiceWorkloadsBind} from './Item/VirtualServices/WorkloadVirtualServicesSaga';
import {getNodeAppGroupParent} from 'containers/Explorer/ExplorerUtils';

export function* fetchWorkloadInstance({id, force = false}) {
  return yield call(apiSaga, 'workloads.get_instance', {
    params: {workload_id: id},
    query: {representation: 'workload_labels'},
    cache: !force,
    *onDone({data: workload}) {
      let rulesetCaps = true;

      const appGroupType = yield select(getAppGroupLabelTypes);
      const appGroup = getNodeAppGroupParent(workload, appGroupType);
      const appGroupParent = {app_group_keys: JSON.stringify([appGroup])};
      let capsResponse;

      if (appGroup) {
        try {
          capsResponse = yield call(apiSaga, 'network_traffic.caps', {query: appGroupParent});
        } catch {
          console.error('Permission Error on Workload');
        }

        rulesetCaps = capsResponse?.data[appGroup]?.caps.rule_sets?.includes('read');
      }

      if (force || workload !== (yield select(getWorkloadInstance))) {
        workload.rulesetCaps = rulesetCaps;
        yield put({type: 'WORKLOADS_GET_INSTANCE', data: workload});
      }

      return workload;
    },
  });
}

export function* fetchWorkloadItem({params: {id}}, refetch = false) {
  try {
    yield call(fetchWorkloadInstance, {id, force: refetch});
    yield call(fetchVirtualServiceWorkloadsBind);
  } catch {
    throw new RedirectError({to: 'workloads.list', proceedFetching: true, thisFetchIsDone: true});
  }
}

export function* removeWorkload() {
  const {id} = yield select(getRouteParams);

  yield call(apiSaga, 'workload.delete', {
    params: {workload_id: id},
  });
  cachedResponses.removeByMethodName('service_bindings.get_collection');
}

export function* removeWorkloads(unmanagedHrefs) {
  const removed = [];
  const errors = new Map();

  yield all(
    unmanagedHrefs.map(function* (href) {
      try {
        yield call(apiSaga, 'workload.delete', {
          params: {workload_id: hrefUtils.getId(href)},
        });

        removed.push(href);
      } catch (err) {
        const errData = _.get(err, 'data[0]');
        const message = (errData && errData.message) || err.message;
        const hrefs = errors.get(message) || [];

        hrefs.push(href);
        errors.set(message, hrefs);
      }

      cachedResponses.removeByMethodName('service_bindings.get_collection');
    }),
  );

  return {removed, errors};
}

export function* removeUnmanagedWorkload({href}) {
  yield call(apiSaga, 'workload.delete', {
    params: {workload_id: hrefUtils.getId(href)},
    hrefs: [href],
  });
}

export function* editLabelsWorkloads({workloadsForSetLabels, labelsForSetLabels, labelTypesForRemoveLabels}) {
  let error = null;

  try {
    const {data} = yield call(apiSaga, 'workloads.set_labels', {
      data: {
        workloads: workloadsForSetLabels.map(href => ({href})),
        labels: labelsForSetLabels,
        delete_existing_keys: labelTypesForRemoveLabels,
      },
      *onDone() {
        apiCachedResponses.clearAll();
      },
    });

    if (Array.isArray(data) && data.length > 0) {
      error = data;
    }
  } catch (err) {
    const data = _.get(err, 'data[0]');

    error = (data && data.message) || err.message;
  }

  return error;
}

export function* applyPolicyWorkloads(data) {
  yield call(apiSaga, 'workloads.apply_policy', {data});
}

export function* editLabelsWorkloadsInEdge({workloadsForSetLabels, labelsForSetLabels, labelTypesForRemoveLabels}) {
  let error = null;

  try {
    const updateWorkloadPolicyState = {};

    if (workloadsForSetLabels.length > 0) {
      if (yield select(isCrowdstrike)) {
        // Default policy state for workloads with no groups in CS
        updateWorkloadPolicyState.mode = 'idle';
        updateWorkloadPolicyState.visibility_level = 'flow_summary';
        updateWorkloadPolicyState.log_traffic = false;
      }

      // If Group (ruleset labels) to add Workload(s) to exists apply policy state from Pairing Profile for that group
      // Otherwise, if no Group was selected, set Workload(s) to Idle policy state.
      if (labelsForSetLabels.length > 0) {
        // Fetch Pairing Profile for Group
        const pairingProfiles = (yield all(
          labelsForSetLabels.map(({href: labelHref}) =>
            call(fetchPairingProfileByLabel, {labelHref, force: true, dispatch: false}),
          ),
        )).filter(Boolean);

        if (pairingProfiles.length > 0) {
          const pairingProfile = pairingProfiles[0];
          const {mode, visibility_level: visibilityLevel, log_traffic: logTraffic} = pairingProfile;

          updateWorkloadPolicyState.mode = mode;
          updateWorkloadPolicyState.visibility_level = visibilityLevel;
          updateWorkloadPolicyState.log_traffic = logTraffic;
        }
      }
    }

    const {data} = yield call(apiSaga, 'workloads.update', {
      data: {
        workloads: workloadsForSetLabels.map(href => ({href})),
        labels: labelsForSetLabels,
        delete_existing_label_keys: labelTypesForRemoveLabels,
        ...updateWorkloadPolicyState,
      },
      *onDone() {
        apiCachedResponses.clearAll();
      },
    });

    if (Array.isArray(data) && data.length > 0) {
      error = data;
    }
  } catch (err) {
    const data = _.get(err, 'data[0]');

    error = (data && data.message) || err.message;
  }

  return error;
}

/**
 * Allow updating policy.
 * Since workloads.update doesn't have a robust API thus need to make parallel calls for make payload request specific when needed.
 *
 * @param payloads
 * @returns error
 */
export function* updatePolicyState(payloads) {
  let error = null;

  try {
    const info = yield all(
      payloads.map(policy =>
        call(apiSaga, 'workloads.update', {
          data: {...policy},
          *onDone() {
            apiCachedResponses.clearAll();
          },
        }),
      ),
    );

    const allErrors = info.reduce((infoData, {data}) => {
      // When data is non-empty then there are errors
      if (Array.isArray(data) && data.length > 0) {
        infoData = info.concat(data);
      }

      return infoData;
    }, []);

    if (allErrors.length > 0) {
      error = allErrors;
    }
  } catch (err) {
    const data = _.get(err, 'data[0]');

    error = (data && data.message) || err.message;
  }

  return error;
}

export function* getWorkloadOSfamilies({query, force = false}) {
  // e.g. query: {pairing_profile_id: id} OR {pairing_profile_name: name}
  yield call(apiSaga, 'workload_os_families.get_collection', {
    cache: !force,
    query,
    *afterFetch({data}) {
      yield put({type: 'WORKLOAD_GET_LIST_OS_FAMILIES', data});
    },
  });
}

// Action to increase traffic update rate for workloads
export function* increaseTrafficUpdateRate(data) {
  yield call(apiSaga, 'workloads.set_flow_reporting_frequency', {data: {workloads: data.map(href => ({href}))}});
}

// Take workloads from store or make parallel calls to fetch needed workload
export function* fetchSelectiveWorkloads(workloadsOrHrefs = [], force = false) {
  const workloads = yield select(getWorkloadsHrefMap);

  const resultWorkloadsMap = new Map();

  yield all(
    workloadsOrHrefs.reduce((result, workload) => {
      if (!workload) {
        return result;
      }

      if (typeof workload.href === 'string' && workload.href) {
        if (!force && workloads[workload.href]) {
          // If workload already exists in store, just return it
          resultWorkloadsMap.set(workload.href, workloads[workload.href]);
        } else {
          // Otherwise fetch this workload and put it into the store
          result.push(
            call(function* () {
              try {
                yield call(fetchWorkloadInstance, {id: hrefUtils.getId(workload.href), force});

                const workloads = yield select(getWorkloadsHrefMap);

                resultWorkloadsMap.set(workload.href, workloads.get(workload.href));
              } catch {
                // A Workload in the workload's table may not exist even though they exist in object history, that is fine
                resultWorkloadsMap.set(workload.href, null);
              }
            }),
          );
        }
      }

      return result;
    }, []),
  );

  return resultWorkloadsMap;
}
