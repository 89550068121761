/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import * as PropTypes from 'prop-types';
import {PureComponent, createElement, createRef} from 'react';
import {AppContext} from 'containers/App/AppUtils';
import PubSub from 'pubsub';
import {Button} from 'components';
import Modal from '../Modal';

const defaultModalProps = {
  stretch: true,
  minWidth: '70%',
  dontRestrainChildren: true,
};

const defaultDoneButtonProps = {
  tid: 'ok',
  text: intl('Common.Close'),
};

const defaultSaveButtonProps = {
  tid: 'save',
  text: intl('Common.Save'),
  icon: 'save',
};

const defaultCancelButtonProps = {
  tid: 'cancel',
  text: intl('Common.Cancel'),
  noFill: true,
};

export default class PageInvokerModal extends PureComponent {
  static contextType = AppContext;

  // List of props specific to Alert modal, all extra props will be passed down to rendered Modal as is
  static propTypes = {
    // Props for Container page
    edit: PropTypes.bool,
    container: PropTypes.any,
    containerProps: PropTypes.object,
    unsavedWarningData: PropTypes.object,

    // Props for <Modal.Header>
    doneButtonProps: PropTypes.object, // Object with custom props for a button, will be merged with defaultButtonProps and onClose (if passed)
    saveButtonProps: PropTypes.object, // Object with custom props for a button, will be merged with defaultButtonProps and onClose (if passed)
    cancelButtonProps: PropTypes.object, // Object with custom props for a button, will be merged with defaultButtonProps and onClose (if passed)
    isInProgress: PropTypes.bool, // Shortcut to set progress state to the button if buttonProps is not specified

    // Props for <Modal.Header>
    title: PropTypes.node,
    noCloseIcon: PropTypes.bool,

    // Props for <Modal.Content>
    insensitive: PropTypes.bool,
    notScrollable: PropTypes.bool,
    noPaddings: PropTypes.bool,
    gap: PropTypes.string,
  };

  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
      valid: false,
      saveDisabled: true,
    };

    this.containerRef = createRef();

    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleValidation = this.handleValidation.bind(this);
    this.handleFormReset = this.handleFormReset.bind(this);
  }

  handleOpen(event, additionalProps) {
    if (additionalProps) {
      this.setState({additionalProps});
    }

    this.setState({showModal: true, parentFormDirtyFlag: this.context.store.prefetcher.formIsDirty});
    PubSub.publish('FORM.DIRTY', {dirty: false}, {immediate: true});
  }

  handleClose() {
    // if there were not changes in the parent but this page in modal has some changes,
    // then publish dirty event to pubsub to make parent form dirty
    // eslint-disable-next-line no-underscore-dangle
    if (this._container?.formik?.dirty && this.state.parentFormDirtyFlag === undefined) {
      PubSub.publish('FORM.DIRTY', {dirty: true}, {immediate: true});

      // otherwise set the previous value of parent form state
    } else {
      PubSub.publish('FORM.DIRTY', {dirty: this.state.parentFormDirtyFlag}, {immediate: true});
    }

    this.setState({showModal: false});
  }

  handleFormReset() {
    const {resetForm} = this.context.store.prefetcher;

    if (typeof resetForm === 'function') {
      resetForm();
    } else {
      // Reset formIsDirty on submit to prevent displaying confirm leave page warning
      PubSub.publish('FORM.DIRTY', {dirty: false}, {immediate: true});
    }
  }

  handleSave() {
    // eslint-disable-next-line no-underscore-dangle
    const payload = this._container.getPayload();

    this.props.onSave(payload);

    this.handleFormReset(); // Reset Form before closing to prevent cancel leave confirmation from prefetcher on navigation
    this.handleClose();
  }

  handleValidation(saveDisabled) {
    this.setState({saveDisabled});
  }

  async handleCancel() {
    const {formIsDirty} = this.context.store.prefetcher;

    if (formIsDirty) {
      // Cancel confirmation is mounted if form has changes
      const answer = await new Promise(resolve =>
        PubSub.publish('UNSAVED.WARNING', {resolve, ...this.props.unsavedWarningData}),
      );

      if (answer === 'cancel') {
        return;
      }

      this.handleFormReset();
    }

    this.handleClose();
  }

  render() {
    const {
      props: {
        children,
        doneButtonProps,
        saveButtonProps,
        cancelButtonProps,
        isInProgress,
        edit,
        // Destructure <Modal.Header> props
        title,
        noCloseIcon,
        // Destructure <Modal.Content> props
        insensitive,
        notScrollable,
        noPaddings,
        gap,
        container,
        containerProps,
        noFooter,
        noModalFooter,
        pageInvokerRef, // passed down from selector to prevent selector from closing on mousedown within modal
        ...modalProps
      },
      state: {showModal, saveDisabled, additionalProps},
    } = this;
    let headerProps = {title, noCloseIcon};
    const contentProps = {insensitive, notScrollable, noPaddings, gap};
    const modal = {...defaultModalProps, ...modalProps, onClose: this.handleCancel};

    const content = createElement(container, {
      ref: containerInstance => {
        // eslint-disable-next-line no-underscore-dangle
        this._container = containerInstance;
      },
      onValid: this.handleValidation,
      ...containerProps,
      ...additionalProps,
    });

    if (additionalProps) {
      headerProps = {...headerProps, title: additionalProps.title};
    }

    return (
      <>
        {children({handleOpen: this.handleOpen})}
        {showModal && (
          <Modal ref={pageInvokerRef} {...modal}>
            <Modal.Header {...headerProps} />
            <Modal.Content {...contentProps}>{content}</Modal.Content>
            <Modal.Footer>
              {edit ? (
                <>
                  <Button onClick={this.handleCancel} {...defaultCancelButtonProps} {...cancelButtonProps} />
                  <Button
                    onClick={this.handleSave}
                    progress={isInProgress}
                    disabled={saveDisabled}
                    {...defaultSaveButtonProps}
                    {...saveButtonProps}
                  />
                </>
              ) : (
                <Button onClick={this.handleClose} {...defaultDoneButtonProps} {...doneButtonProps} />
              )}
            </Modal.Footer>
          </Modal>
        )}
      </>
    );
  }
}

// Statically assign to the Modal class, so consumer can use it as <Modal.Alert>
Modal.PageInvoker = PageInvokerModal;
