/**
 * Copyright 2017 Illumio, Inc. All Rights Reserved.
 */
import produce from 'immer';
import {createSelector} from 'reselect';
import {actionTypes} from 'redux-router5';
import HelpPopupContentMap from './HelpPopupContentMap';
import {
  getSupportUrl,
  getDocsUrl,
  getRouteName,
  getRouteParams,
  areVulnerabilitiesEnabled,
  isEdge,
  isCrowdstrike,
} from 'containers/App/AppState';
import {edge} from 'api/apiUtils';

export default {
  helppopup: produce(
    (draft, action) => {
      if (action.type === actionTypes.TRANSITION_SUCCESS && action.payload.route.name === 'app.helppopup') {
        // If it is popped out menu window (app.helppopup route), set 'poppedout' mode
        draft.mode = 'poppedout';
      } else if (action.type === 'SET_HELP_MENU_MODE') {
        draft.mode = action.payload;
      } else if (action.type === 'TOGGLE_HELP_MENU_MODE') {
        // Toggle null and popout to inline, inline to null
        draft.mode = draft.mode === 'inline' ? null : 'inline';
      } else if (action.type === 'LOAD_CUSTOM_HELP_MENU_ITEMS') {
        draft.customItems = {...draft.customItems, ...action.payload};
      } else if (action.type === 'TOGGLE_HELP_MENU_MODE_WITH_KEY') {
        if (!draft.mode) {
          draft.mode = draft.mode === null ? 'inline' : null;
        }

        if (action.customHelpPopUpKey !== null) {
          draft.customHelpPopUpKey = action.customHelpPopUpKey;
        }
      } else if (action.type === 'CLEAR_CUSTOM_HELP_MENU__ITEMS') {
        draft.customItems = {};
      }
    },
    {mode: null, customHelpPopUpKey: null, customItems: {}},
  ),
};

export const getHelpPopup = state => state.helppopup;

export const getHelpPopupProps = createSelector(
  [
    getHelpPopup,
    getRouteName,
    getRouteParams,
    getSupportUrl,
    areVulnerabilitiesEnabled,
    getDocsUrl,
    isEdge,
    isCrowdstrike,
  ],
  (
    {mode, customItems, customHelpPopUpKey},
    routeName,
    routeParams,
    supportUrl,
    vulnerabilitiesEnabled,
    docsUrl,
    edgeEnabled,
    crowdstrikeEnabled,
  ) => {
    if (mode === 'poppedout') {
      routeName = routeParams.page;
    }

    const helpPopupKey = getHelpPopupKeyFromRoute(routeName, routeParams, vulnerabilitiesEnabled, crowdstrikeEnabled);

    return {
      routeName,
      routeParams,
      mode,
      customItems: customItems[routeName],
      customHelpPopUpKey,
      cards: getHelpPopupItems(helpPopupKey),
      isAdmin: helpPopupKey === 'admin',
      supportUrl,
      supportPortalSearchBase: docsUrl,
      edgeEnabled,
    };
  },
);

// This function maps a given routeName to a key for HelpPopupContentMap.js
// HelpPopupContentMap then maps these keys to lists of help popup items.
// This is needed because many routes can map to the same list of help topics.
// i.e. workloads, workloadCreate, workloadEdit, etc all map to 'workloads' in HelpPopupContentMap.js
export function getHelpPopupKeyFromRoute(routeName, routeParams, vulnerabilitiesEnabled, crowdstrikeEnabled) {
  if (routeName.startsWith('app.segmentationTemplates')) {
    return 'segmentationTemplates';
  }

  if (!edge && routeName.startsWith('app.workloads')) {
    if (routeName.includes('vens.list')) {
      return 'vensListCore';
    }

    return vulnerabilitiesEnabled ? 'workloadsWithVulnerabilities' : 'workloads';
  }

  if (edge && routeName.startsWith('app.workloads')) {
    if (routeName.includes('vens.list')) {
      return 'venslist';
    }

    if (crowdstrikeEnabled) {
      return 'workloadsCrowdstrike';
    }

    return 'workloadsEdge';
  }

  if (routeName.startsWith('app.workloads.containers')) {
    return 'containerWorkloads';
  }

  if (routeName.startsWith('app.services')) {
    return 'services';
  }

  if (routeName.startsWith('app.mymanagedtenants')) {
    return 'mymanagedtenants';
  }

  if (routeName.startsWith('app.rbac.serviceAccounts')) {
    return 'serviceAccounts';
  }

  if (routeName.startsWith('app.iplists')) {
    return 'iplists';
  }

  if (routeName.startsWith('app.labelGroups')) {
    return 'labelGroups';
  }

  if (routeName.startsWith('app.userGroups')) {
    return 'userGroups';
  }

  if (routeName.startsWith('app.virtualServers')) {
    return 'virtualServers';
  }

  if (!edge && routeName.startsWith('app.securitysettings')) {
    return 'securitysettings';
  }

  if (routeName.startsWith('app.ikecertificates')) {
    return 'ikecertificates';
  }

  if (routeName.startsWith('app.essentialservicerules')) {
    return 'essentialservicerules';
  }

  if (routeName === 'app.pending' || routeName === 'app.provision' || routeName === 'app.revert') {
    return 'provision';
  }

  if (routeName.startsWith('app.workloads.item')) {
    return 'workloadDetails';
  }

  if (routeName.startsWith('app.workloads')) {
    return 'workloads';
  }

  if (routeName.startsWith('app.virtualServices')) {
    return 'virtualServices';
  }

  if (routeName.startsWith('app.labels')) {
    return 'labels';
  }

  if (routeName.startsWith('app.pairingProfiles')) {
    return 'pairingProfiles';
  }

  if (routeName.startsWith('app.loadBalancers')) {
    return 'loadBalancers';
  }

  if (!edge && routeName.startsWith('app.containerClusters')) {
    return 'containerClusters';
  }

  if (!edge && routeName.startsWith('app.secureGateways')) {
    return 'secureGateways';
  }

  if (!edge && routeName.startsWith('app.networks')) {
    return 'networks';
  }

  if (routeName.startsWith('app.reports')) {
    return 'reports';
  }

  if (routeName.startsWith('app.outboundpolicy')) {
    return 'outboundpolicy';
  }

  if (routeName.startsWith('app.vens') || routeName.startsWith('app.venLibrary')) {
    return 'vens';
  }

  if (routeName.startsWith('app.authenticationSettings')) {
    return 'authenticationSettings';
  }

  if (routeName.startsWith('app.provisioning')) {
    return 'provisioning';
  }

  if (routeName.startsWith('app.blockedTraffic')) {
    return 'blockedTraffic';
  }

  if (routeName.startsWith('app.orgevents')) {
    return 'orgevents';
  }

  if (routeName.startsWith('app.connectivity')) {
    return 'connectivity';
  }

  if (routeName.startsWith('app.rulesets.item.rules')) {
    return 'rulesetRules';
  }

  if (!edge && routeName.startsWith('app.rulesets')) {
    return 'rulesets';
  }

  if (routeName.startsWith('app.policygenerator')) {
    return 'policygenerator';
  }

  if (routeName.startsWith('app.components')) {
    return 'rulesetRules';
  }

  if (routeName.startsWith('app.supportBundles')) {
    return 'supportreports';
  }

  if (routeName.startsWith('app.explorer')) {
    return 'explorer';
  }

  if (routeName.startsWith('app.reporting')) {
    return 'reporting';
  }

  if (routeName.startsWith('app.coreservices')) {
    return 'coreServices';
  }

  if (routeName.startsWith('app.boundaries')) {
    return 'enforcementBoundaries';
  }

  if (routeName.startsWith('app.rbac.roles.global')) {
    return 'globalRoles';
  }

  if (!edge && routeName.startsWith('app.rbac.roles.scoped')) {
    return 'scopedRoles';
  }

  if (routeName.startsWith('app.authenticationSettings.passwordPolicy')) {
    return 'passwordPolicy';
  }

  if (routeName.startsWith('app.events')) {
    return 'events';
  }

  if (routeName.startsWith('app.versions')) {
    return 'versions';
  }

  if (routeName.startsWith('app.appGroups')) {
    return 'appGroups';
  }

  if (routeName.startsWith('app.rbac.users.activity')) {
    return 'usersActivity';
  }

  if (routeName.startsWith('app.rbac.users')) {
    return 'usersAndGroups';
  }

  if (routeName.startsWith('app.ssoPicker')) {
    return 'sso';
  }

  if (routeName.startsWith('app.offlineTimers')) {
    return 'offlineTimers';
  }

  if (routeName.startsWith('app.appGroups.rules')) {
    return 'appGroupDetailsRules';
  }

  if (routeName.startsWith('app.appGroups.workloads')) {
    return 'appGroupDetailsWorkloads';
  }

  if (routeName.startsWith('app.appGroups.pairingProfiles')) {
    return 'appGroupDetailsPP';
  }

  if (routeName.startsWith('app.health')) {
    return 'pceHealth';
  }

  if (routeName.startsWith('app.myprofile')) {
    return 'myProfile';
  }

  if (routeName.startsWith('app.apikeys')) {
    return 'apiKeys';
  }

  if (routeName.startsWith('app.apiKeySettings')) {
    return 'apiKeySettings';
  }

  if (!edge && routeName.startsWith('app.ruleSearch')) {
    return 'ruleSearch';
  }

  if (routeName.startsWith('app.policySettings')) {
    return 'policySettings';
  }

  if (
    edge &&
    ((routeName.startsWith('app.landing.dashboard') && routeParams.tab === 'admin') ||
      routeName.startsWith('app.groups.editadmin') ||
      (routeName.startsWith('app.groups') && routeParams.tab === 'admin'))
  ) {
    return 'admin';
  }

  if (edge && (routeName.startsWith('app.landing.dashboard') || routeName.startsWith('app.groups'))) {
    return 'group';
  }

  if (routeName.startsWith('app.accessRestrictions')) {
    return 'accessRestrictions';
  }

  return 'noRoute';
}

export function getHelpPopupItems(helpPopupKey) {
  const HelpPopupContentMapObj = HelpPopupContentMap();

  return HelpPopupContentMapObj[helpPopupKey] || HelpPopupContentMapObj.noRoute;
}
