/**
 * Copyright 2016 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import {Vulnerability, StatusIcon, DateTimeCustomPicker} from 'components';
import * as GridUtils from 'components/Grid/GridUtils';
import {getWorkloadStatus, getWorkloadName, getVulnerabilityValues, getWorkloadSyncElement} from '../WorkloadUtils';
import {sortVulnerabilities} from 'components/Vulnerability/VulnerabilityUtils';
import {getWorkloadStatusIntl} from 'intl/dynamic';
import {noScopeLabels} from 'containers/Selectors/SelectorUtils';
import {edge} from 'api/apiUtils';
import {enforcementModeView, visibilityLevelView} from 'containers/EnforcementBoundaries/EnforcementBoundariesUtils';
import {createSelector} from 'reselect';

export const resourceType = 'workloads';
// TODO add last policy provision preset once History state is merged to rrs
const fromPresets = [
  {name: 'anytime', label: 'Anytime', value: null},
  {name: 'custom', label: 'Custom Time', value: 'custom'},
];

// TODO add last policy provision preset once History state is merged to rrs
const toPresets = [
  {name: 'now', label: 'Now', value: new Date()},
  {name: 'custom', label: 'Custom Time', value: 'custom'},
];

const objectMap = {
  workloads: {type: 'workloads'},
  roleLabels: {type: 'labels', key: 'role'},
  appLabels: {type: 'labels', key: 'app'},
  envLabels: {type: 'labels', key: 'env'},
  locLabels: {type: 'labels', key: 'loc'},
};

export const getSelectorSettings = createSelector([], () => ({
  objectMap,
  facetMap: {
    name: {value: intl('Common.Name'), object: objectMap.workloads, wildcardQueryParam: 'advanced_search_name'},
    ip_address: {value: intl('Common.IPAddress'), object: objectMap.workloads},
    description: {value: intl('Common.Description'), object: objectMap.workloads},
    hostname: {
      value: intl('Common.Hostname'),
      object: objectMap.workloads,
      wildcardQueryParam: 'advanced_search_hostname',
    },
    os_id: {value: intl('Common.OS'), object: objectMap.workloads},
  },
  staticMap: {
    policy_health: intl('Workloads.PolicySync'),
    enforcement_mode: intl('Common.Enforcement'),
    managed: intl('Common.Connectivity'),
    security_policy_applied_at: intl('Workloads.PolicyLastApplied'),
  },
  scopeMap: {
    role: {
      value: edge ? intl('Common.Group') : intl('Labels.RoleLabels'),
      object: objectMap.roleLabels,
      scope: true,
      statics: edge ? null : noScopeLabels().role,
    },
    ...(!edge && {
      app: {
        value: intl('Labels.ApplicationLabels'),
        object: objectMap.appLabels,
        scope: true,
        statics: noScopeLabels().app,
      },
    }),
    ...(!edge && {
      env: {
        value: intl('Labels.EnvironmentLabels'),
        object: objectMap.envLabels,
        scope: true,
        statics: noScopeLabels().env,
      },
    }),
    ...(!edge && {
      loc: {
        value: intl('Labels.LocationLabels'),
        object: objectMap.locLabels,
        scope: true,
        statics: noScopeLabels().loc,
      },
    }),
  },
  filterMap: {
    name: {value: intl('Common.Name'), object: objectMap.workloads, wildcardQueryParam: 'advanced_search_name'},
    ip_address: {value: intl('Common.IPAddress'), object: objectMap.workloads},
    description: {value: intl('Common.Description'), object: objectMap.workloads},
    hostname: {
      value: intl('Common.Hostname'),
      object: objectMap.workloads,
      wildcardQueryParam: 'advanced_search_hostname',
    },
    os_id: {value: intl('Common.OS'), object: objectMap.workloads},
    policy_health: intl('Workloads.PolicySync'),
    enforcement_mode: intl('Common.Enforcement'),
    managed: intl('Common.Connectivity'),
    security_policy_applied_at: intl('Workloads.PolicyLastApplied'),
    role: {value: intl('Labels.RoleLabels'), object: objectMap.roleLabels, scope: true, statics: noScopeLabels().role},
    app: {
      value: intl('Labels.ApplicationLabels'),
      object: objectMap.appLabels,
      scope: true,
      statics: noScopeLabels().app,
    },
    env: {
      value: intl('Labels.EnvironmentLabels'),
      object: objectMap.envLabels,
      scope: true,
      statics: noScopeLabels().env,
    },
    loc: {value: intl('Labels.LocationLabels'), object: objectMap.locLabels, scope: true, statics: noScopeLabels().loc},
  },
  staticValues: {
    policy_health: {
      [intl('Common.Error')]: 'error',
      [intl('Common.Warning')]: 'warning',
      [intl('Common.Active')]: 'active',
      ...(!edge ? {[intl('Workloads.Status.Suspended')]: 'suspended'} : {}),
    },
    enforcement_mode: {
      [edge ? intl('Common.Enforced') : intl('Workloads.Full')]: 'full',
      ...(!edge && {[intl('EnforcementBoundaries.SelectiveEnforcement')]: 'selective'}),
      [intl('Common.VisibilityOnly')]: 'visibility_only',
      [intl('Common.Idle')]: 'idle',
    },
    security_policy_update_mode: {
      [intl('Workloads.StaticWorkloads')]: 'static',
      [intl('Workloads.AdaptiveWorkloads')]: 'adaptive',
    },
    managed: {
      [intl('Common.Online')]: [
        {name: 'online', value: 'true'},
        {name: 'managed', value: 'true'},
      ],
      [intl('Workloads.Status.Offline')]: [
        {name: 'online', value: 'false'},
        {name: 'managed', value: 'true'},
      ],
      ...(!edge && {[intl('Common.Unmanaged')]: 'false'}),
    },
  },
  customPickers: {
    security_policy_applied_at: (
      <DateTimeCustomPicker fromPresets={fromPresets} toPresets={toPresets} categoryKey="security_policy_applied_at" />
    ),
    security_policy_received_at: (
      <DateTimeCustomPicker fromPresets={fromPresets} toPresets={toPresets} categoryKey="security_policy_received_at" />
    ),
  },
  customRouteFilters: {
    'security_policy_applied_at[gte]': null,
    'security_policy_applied_at[lte]': null,
    'security_policy_received_at[gte]': null,
    'security_policy_received_at[lte]': null,
    'scope': null,
  },
}));

const clickableVEColumn = GridUtils.clickableColumn({
  format: ({row, value, clickableRef}) =>
    value ? <Vulnerability {...value} href={row.data.href} ref={clickableRef} /> : null,
  sort: ({value}) => value && value.value,
});

const defaultTemplates = [
  [
    {columns: ['checkboxes'], size: 'max-content', sizeOld: '30px'},
    {columns: ['status'], size: 'max-content', sizeOld: '40px'},
    {columns: ['vulnerability'], size: 'max-content', sizeOld: '115px'},
    {columns: ['enforcement'], size: 'max-content'},
    {columns: ['visibility'], size: 'max-content'},
    {columns: ['sync'], size: 'minmax(max-content, auto)', sizeOld: '110px'},
    {columns: ['name'], size: 'minmax(150px, auto)'},
    {columns: ['hostname'], size: 'minmax(150px, auto)'},
    {columns: ['role'], size: 'minmax(150px, auto)'},
    {columns: ['app'], size: 'minmax(150px, auto)'},
    {columns: ['env'], size: 'minmax(150px, auto)'},
    {columns: ['loc'], size: 'minmax(150px, auto)'},
    {columns: ['paired'], size: 'minmax(170px, auto)'},
    {columns: ['last'], size: 'minmax(170px, auto)'},
  ],
  {
    maxWidth: 1680,
    template(columns) {
      if (GridUtils.hasOptionalColumns(columns)) {
        //all column breakpoint
        return [
          {columns: ['checkboxes'], size: 'max-content', sizeOld: '30px'},
          {columns: ['status'], size: 'max-content', sizeOld: '35px'},
          {columns: ['vulnerability'], size: 'max-content', sizeOld: '115px'},
          {columns: ['enforcement'], size: 'max-content', sizeOld: '115px'},
          {columns: ['visibility'], size: 'max-content', sizeOld: '115px'},
          {columns: ['sync'], size: 'minmax(max-content, auto)', sizeOld: '110px'},
          {columns: ['name'], size: 'minmax(100px, auto)'},
          {columns: ['hostname'], size: 'minmax(100px, auto)'},
          {columns: ['role'], size: 'minmax(100px, auto)'},
          {columns: ['app'], size: 'minmax(100px, auto)'},
          {columns: ['env'], size: 'minmax(100px, auto)'},
          {columns: ['loc'], size: 'minmax(100px, auto)'},
          {columns: ['paired'], size: 'minmax(120px, auto)'},
          {columns: ['last'], size: 'minmax(120px, auto)'},
        ];
      }

      return [
        {columns: ['checkboxes'], size: 'max-content'},
        {columns: ['status'], size: 'max-content'},
        {columns: ['vulnerability'], size: 'max-content'},
        {columns: ['enforcement'], size: 'max-content'},
        {columns: ['visibility'], size: 'max-content'},
        {columns: ['sync'], size: 'minmax(max-content, auto)'},
        {columns: ['name'], size: 'minmax(100px, auto)'},
        {columns: ['hostname'], size: 'minmax(100px, auto)'},
        {columns: ['role'], size: 'minmax(100px, auto)'},
        {columns: ['app'], size: 'minmax(100px, auto)'},
        {columns: ['env'], size: 'minmax(100px, auto)'},
        {columns: ['loc'], size: 'minmax(100px, auto)'},
        {columns: ['last'], size: 'minmax(120px, auto)'},
        {columns: ['paired'], size: 'minmax(120px, auto)'},
      ];
    },
  },
  {
    maxWidth: 1440,
    template(columns) {
      if (GridUtils.hasOptionalColumns(columns)) {
        //all column breakpoint
        return [
          {columns: ['checkboxes'], size: 'max-content'},
          {columns: ['status', 'vulnerability'], size: 'minmax(max-content, auto)'},
          {columns: ['enforcement', 'visibility'], size: 'minmax(120px, auto)'},
          {columns: ['name', 'hostname', 'sync'], size: 'minmax(120px, auto)'},
          {columns: ['role'], size: 'minmax(110px, auto)'},
          {columns: ['app'], size: 'minmax(110px, auto)'},
          {columns: ['env'], size: 'minmax(110px, auto)'},
          {columns: ['loc'], size: 'minmax(110px, auto)'},
          {columns: ['paired', 'last'], size: 'minmax(120px, auto)'},
        ];
      }

      return [
        {columns: ['checkboxes'], size: 'max-content', sizeOld: '35px'},
        {columns: ['status', 'vulnerability'], size: 'minmax(max-content, auto)', sizeOld: '115px'},
        {columns: ['enforcement', 'visibility'], size: 'minmax(120px, auto)'},
        {columns: ['name', 'hostname', 'sync'], size: 'minmax(120px, auto)'},
        {columns: ['role'], size: 'minmax(110px, auto)'},
        {columns: ['app'], size: 'minmax(110px, auto)'},
        {columns: ['env'], size: 'minmax(110px, auto)'},
        {columns: ['loc'], size: 'minmax(110px, auto)'},
        {columns: ['paired', 'last'], size: 'minmax(120px, auto)'},
      ];
    },
  },
  {
    maxWidth: 1024,
    template(columns) {
      if (GridUtils.hasOptionalColumns(columns)) {
        //all column breakpoint
        return [
          {columns: ['checkboxes'], size: 'max-content', sizeOld: '35px'},
          {columns: ['status', 'vulnerability', 'sync'], size: 'minmax(max-content, auto)', sizeOld: '115px'},
          {columns: ['enforcement', 'visibility'], size: 'minmax(120px, auto)'},
          {columns: ['name', 'hostname', 'paired', 'last'], size: 'minmax(140px, auto)'},
          {columns: ['role', 'app'], size: 'minmax(120px, auto)'},
          {columns: ['env', 'loc'], size: 'minmax(120px, auto)'},
        ];
      }

      return [
        {columns: ['checkboxes'], size: 'max-content', sizeOld: '35px'},
        {columns: ['status', 'vulnerability', 'sync'], size: 'minmax(max-content, auto)', sizeOld: '115px'},
        {columns: ['enforcement', 'visibility'], size: 'minmax(120px, auto)'},
        {columns: ['name', 'hostname', 'paired', 'last'], size: 'minmax(140px, auto)'},
        {columns: ['role', 'app'], size: 'minmax(120px, auto)'},
        {columns: ['env', 'loc'], size: 'minmax(120px, auto)'},
      ];
    },
  },
  {
    maxWidth: 640,
    template(columns) {
      if (GridUtils.hasOptionalColumns(columns)) {
        //all column breakpoint
        return [
          {columns: ['checkboxes'], size: 'max-content', sizeOld: '30px'},
          {columns: ['status', 'vulnerability', 'sync'], size: 'minmax(min-content, max-content)', sizeOld: '115px'},
          {columns: ['name', 'hostname', 'paired', 'last', 'enforcement', 'visibility'], size: 'minmax(150px, auto)'},
          {columns: ['role', 'app', 'env', 'loc'], size: 'minmax(150px, auto)' /*, extraClass: styles.labels*/},
        ];
      }

      return [
        {columns: ['checkboxes'], size: 'max-content', sizeOld: '30px'},
        {columns: ['status', 'vulnerability', 'sync'], size: 'minmax(min-content, max-content)', sizeOld: '115px'},
        {columns: ['name', 'hostname', 'paired', 'last', 'enforcement', 'visibility'], size: 'minmax(150px, auto)'},
        {columns: ['role', 'app', 'env', 'loc'], size: 'minmax(150px, auto)' /*, extraClass: styles.labels*/},
      ];
    },
  },
  {
    maxWidth: 480,
    template(columns) {
      if (GridUtils.hasOptionalColumns(columns)) {
        //all column breakpoint
        return [
          {columns: ['checkboxes'], size: 'min-content', sizeOld: '30px'},
          {
            columns: [
              'status',
              'vulnerability',
              'name',
              'hostname',
              'sync',
              'paired',
              'last',
              'enforcement',
              'visibility',
            ],
            size: 'minmax(140px, auto)',
          },
          {columns: ['role', 'app', 'env', 'loc'], size: 'minmax(140px, auto)'},
        ];
      }

      return [
        {columns: ['checkboxes'], size: 'min-content', sizeOld: '30px'},
        {
          columns: [
            'status',
            'vulnerability',
            'name',
            'hostname',
            'sync',
            'paired',
            'last',
            'enforcement',
            'visibility',
          ],
          size: 'minmax(140px, auto)',
        },
        {columns: ['role', 'app', 'env', 'loc'], size: 'minmax(140px, auto)'},
      ];
    },
  },
];

const edgeTemplates = [
  [
    {columns: ['checkboxes'], size: 'min-content', sizeOld: '30px'},
    {columns: ['status'], size: 'minmax(100px, auto)'},
    {columns: ['sync'], size: 'minmax(100px, auto)'},
    {columns: ['role'], size: 'minmax(100px, auto)'},
    {columns: ['last'], size: 'minmax(100px, auto)'},
    {columns: ['paired'], size: 'minmax(100px, auto)'},
    {columns: ['hostname'], size: 'minmax(100px, auto)'},
    {columns: ['enforcement'], size: 'minmax(100px, auto)'},
    {columns: ['visibility'], size: 'minmax(100px, auto)'},
    {columns: ['name'], size: 'minmax(200px, auto)'},
  ],
  {
    maxWidth: 1024,
    template() {
      return [
        {columns: ['checkboxes'], size: 'min-content', sizeOld: '30px'},
        {columns: ['status', 'sync', 'role'], size: 'minmax(100px, auto)'},
        {columns: ['last'], size: 'minmax(100px, auto)'},
        {columns: ['enforcement'], size: 'minmax(100px, auto)'},
        {columns: ['paired'], size: 'minmax(100px, auto)'},
        {columns: ['hostname'], size: 'minmax(100px, auto)'},
        {columns: ['visibility'], size: 'minmax(100px, auto)'},
        {columns: ['name'], size: 'minmax(140px, auto)'},
      ];
    },
  },
  {
    maxWidth: 640,
    template() {
      return [
        {columns: ['checkboxes'], size: 'min-content', sizeOld: '30px'},
        {columns: ['status', 'last', 'role', 'hostname'], size: 'minmax(140px, auto)'},
        {columns: ['enforcement', 'paired', 'visibility'], size: 'minmax(140px, auto)'},
        {columns: ['name', 'sync'], size: 'minmax(140px, auto)'},
      ];
    },
  },
  {
    maxWidth: 480,
    template() {
      return [
        {columns: ['checkboxes'], size: 'min-content', sizeOld: '30px'},
        {columns: ['status', 'sync', 'role', 'hostname', 'last'], size: 'minmax(140px, auto)'},
        {columns: ['enforcement', 'visibility', 'paired', 'name'], size: 'minmax(140px, auto)'},
      ];
    },
  },
];

/**
 [{
  id: string,
  header: string,

  key: string | Function,
  value: string | Function,
  format: Function,
  sort: Function, // Getter for sorting value
  sortFunction: Function, // Custom sort function
  sortable: [true]boolean,
  reactsToSelection: boolean, // If cell should be rerendered on row checkbox change
  isDate: boolean,
}];
 */

export const gridSettings = createSelector([], () => ({
  id: 'workloadlist',
  sort: 'name',
  capacities: [25, 50, 100, 250, 500],
  capacity: 50,
  maxPage: Number.MAX_SAFE_INTEGER,
  showColumns: true,
  showCapacity: true,
  showPagination: true,
  columns: {
    checkboxes: {},
    status: {
      header: intl('Common.Connectivity'),
      value: ({row}) =>
        row.isDiscoveredWorkload && row.data.agent?.config?.mode === 'idle' ? null : getWorkloadStatus(row.data),
      format: ({value}) =>
        value && (
          <>
            <StatusIcon
              status={value}
              title={`${intl('Workloads.VENConnectivity')}: ${getWorkloadStatusIntl(value)}`}
            />
            &nbsp;
            {getWorkloadStatusIntl(value)}
          </>
        ),
    },
    vulnerability: {
      ...clickableVEColumn,
      header: intl('Vulnerability.VEScore'),
      defaultOrder: 'desc',
      value: ({row}) => !row.isDiscoveredWorkload && getVulnerabilityValues(row.data),
      sortFunction: ({a, b, column, sortFactor}) =>
        sortVulnerabilities(a.cells.get(column.id).value, b.cells.get(column.id).value, sortFactor),
    },
    enforcement: {
      header: intl('Common.Enforcement'),
      value: ({row}) =>
        row.unmanaged
          ? null
          : enforcementModeView()[edge && row.data.enforcement_mode === 'full' ? 'enforced' : row.data.enforcement_mode]
              ?.name,
    },
    visibility: {
      header: intl('Common.Visibility'),
      value: ({row}) =>
        row.unmanaged
          ? null
          : row.data.enforcement_mode === 'idle'
          ? visibilityLevelView()[row.data.enforcement_mode].name
          : visibilityLevelView()[row.data.visibility_level]?.name,
    },
    sync: {
      header: intl('Workloads.PolicySync'),
      value: ({row}) => (row.healthStatus && !row.isDiscoveredWorkload ? row.healthStatus.label : null),
      format: ({value, row}) => getWorkloadSyncElement(row.healthStatus, value),
    },
    name: {
      linky: true,
      header: intl('Common.Name'),
      value: ({row}) => getWorkloadName(row.data),
      required: true,
    },
    hostname: {
      linky: true,
      header: intl('Common.Hostname'),
      value: ({row}) => (row.isDiscoveredWorkload ? null : row.data?.hostname),
      optional: true,
    },
    role: {
      header: edge ? intl('Common.Group') : intl('Common.Role'),
      ...GridUtils.clickableLabelColumn,
    },
    app: {
      header: intl('Common.Application'),
      ...GridUtils.clickableLabelColumn,
    },
    env: {
      header: intl('Common.Environment'),
      ...GridUtils.clickableLabelColumn,
    },
    loc: {
      header: intl('Common.Location'),
      ...GridUtils.clickableLabelColumn,
    },
    last: {
      isDate: true,
      header: intl('Workloads.LastAppliedPolicy'),
      value: ({row}) =>
        row.isDiscoveredWorkload ? null : !row.unmanaged && row.data.agent.status.security_policy_refresh_at,
    },
    paired: {
      isDate: true,
      header: intl('Common.Paired'),
      value: ({row}) => (row.isDiscoveredWorkload ? null : !row.unmanaged && row.data.agent.status.managed_since),
      optional: true,
    },
  },
  templates: edge ? edgeTemplates : defaultTemplates,
}));
