/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from 'intl';
import {createRef, PureComponent} from 'react';
import {connect} from 'react-redux';
import {AppContext} from 'containers/App/AppUtils';
import {AttributeList, Button, ToolBar, ToolGroup, Link, Pill, Notifications, ModalMachineAuto} from 'components';
import {hrefUtils, reactUtils} from 'utils';
import {getLabelTypeName} from 'components/Pill/Label/LabelUtils';
import {locationText, venStatuses, getVenConditions, getActivationType} from '../../VenUtils';
import {getVenItemPage} from '../VenItemState';
import supportReportsReducer from 'containers/SupportBundles/VENSupportReports/SupportReportsState';
import {fetchVenItem} from '../../VenSaga';
import {fetchVenSummaryPage, updateVen} from './VenSummarySaga';
import {getPolicyState, getInterfaceDisplay} from 'containers/Workload/WorkloadUtils';
import {getContainerWorkloadStatusIntl} from 'containers/ContainerWorkload/ContainerWorkloadUtils';
import SupportReportsList from 'containers/SupportBundles/VENSupportReports/List/SupportReportsList';
import VenUnpair from 'containers/Ven/Unpair/VenUnpair';
import VenUpgrade from 'containers/Ven/Upgrade/VenUpgrade';
import VenUpgradeRequestedNotice from 'containers/Ven/Upgrade/VenUpgradeRequestedNotice';
import styles from 'containers/PairingProfile/Item/View/PairingProfileView.css';
import {
  enforcementModeView,
  enforcementModeViewEdge,
  visibilityLevelView,
  visibilityLevelViewEdge,
} from 'containers/EnforcementBoundaries/EnforcementBoundariesUtils';

const getEmptyState = () => ({
  pending: false,
  error: null,
  saving: false,
  suspendsaving: false,
  toggleSuspend: false,
});

@connect(getVenItemPage)
export default class VenSummary extends PureComponent {
  static prefetch = fetchVenSummaryPage;
  static contextType = AppContext;
  static reducers = supportReportsReducer;

  constructor(props) {
    super(props);

    this.state = getEmptyState();
    this.supportReport = createRef();

    this.handleToggleSuspend = this.handleToggleSuspend.bind(this);
    this.handleErrorClose = this.handleErrorClose.bind(this);
    this.handleToggleSuspendDone = this.handleToggleSuspendDone.bind(this);
    this.handleUnpairDone = this.handleUnpairDone.bind(this);
    this.handleGenerateReport = this.handleGenerateReport.bind(this);
    this.handleUnpairDone = this.handleUnpairDone.bind(this);
  }

  handleUnpairDone() {
    return this.context.navigate({to: 'workloads.vens.list'});
  }

  handleToggleSuspend() {
    this.setState(({toggleSuspend}) => ({toggleSuspend: !toggleSuspend}));
  }

  // Handle Edit Error
  handleErrorClose() {
    this.setState(getEmptyState());
  }

  async handleGenerateReport(evt) {
    try {
      await reactUtils.setStateAsync({saving: true}, this);
      await this.supportReport.current?.handleStartReport(evt);
      await new Promise(onSaveDone => this.setState({onSaveDone, saving: false, pending: true}));
    } catch (error) {
      this.setState({error, saving: false});
    }
  }

  handleToggleSuspendDone() {
    return this.context.fetcher.fork(fetchVenItem, {params: {id: this.props.currentRouteParams.id}});
  }

  renderLabel(type) {
    const {
      ven: {labels},
    } = this.props;
    const currentLabel = labels.find(item => item.key === type);

    return currentLabel ? (
      <Pill.Label type={type} href={currentLabel.href}>
        {currentLabel.value}
      </Pill.Label>
    ) : null;
  }

  renderToggleSuspend() {
    const {
      ven,
      currentRouteParams: {id},
    } = this.props;

    return (
      <ModalMachineAuto
        onClose={this.handleToggleSuspend}
        onDone={this.handleToggleSuspendDone}
        saga={updateVen}
        modalProps={{large: true}}
        hrefs={{id, payload: {status: ven.status === 'suspended' ? 'active' : 'suspended'}}}
        initialContext={{
          venStatus: ven.status,
        }}
      >
        {{
          title:
            ven.status === 'suspended'
              ? intl('Workloads.ConfirmVENSuspensionClear')
              : intl('Workloads.ConfirmVENSuspension'),
          submitProps: ({venStatus}) => ({
            text: venStatus === 'suspended' ? intl('Workloads.Clear') : intl('VEN.Suspend'),
          }),
          confirmMessage: ({venStatus}) =>
            venStatus === 'suspended' ? (
              <>
                {intl('Workloads.ClearSuspensionForWorkload')}
                <ul>
                  <li>{intl('Workloads.RemoveSuspensionOnPCE')}</li>
                  <li>{intl('Workloads.VENStillSuspended')}</li>
                  <li>{intl('Workloads.RecalculatePolicy')}</li>
                </ul>
              </>
            ) : (
              <>
                {intl('Workloads.VenSuspensionNotice')}
                <ul>
                  <li>{intl('Workloads.NoChanges')}</li>
                  <li>{intl('Workloads.ErrorState')}</li>
                  <li>{intl('Workloads.UnableToCommunicate')}</li>
                </ul>
              </>
            ),
        }}
      </ModalMachineAuto>
    );
  }

  renderVenVisibility = (workload, classNameContent, edgeEnabled) => {
    let visibilityValue = null;
    const visibilityLevelViewText = visibilityLevelView();

    if (!workload) {
      return visibilityValue; // Uninstalled (unpaired) VEN may linger, with no workload
    }

    if (edgeEnabled && workload.enforcement_mode === 'idle') {
      visibilityValue = (
        <>
          {visibilityLevelViewText[workload.enforcement_mode]?.name}
          <div className={classNameContent}>{visibilityLevelViewEdge.idle?.desc}</div>
        </>
      );
    } else if (edgeEnabled) {
      visibilityValue = (
        <>
          {visibilityLevelViewText[workload.visibility_level]?.name}
          <div className={classNameContent}>
            {workload.enforcement_mode === 'full' && workload.visibility_level === 'flow_summary'
              ? visibilityLevelViewEdge.flow_summary_enforce?.desc
              : visibilityLevelViewEdge[workload.visibility_level]?.desc}
          </div>
        </>
      );
    } else if (workload.enforcement_mode === 'idle') {
      visibilityValue = <div>{visibilityLevelViewText[workload.enforcement_mode].name}</div>;
    } else {
      visibilityValue = (
        <>
          {visibilityLevelViewText[workload.visibility_level]?.name}
          <div className={classNameContent}>{visibilityLevelViewText[workload.visibility_level]?.desc}</div>
        </>
      );
    }

    return visibilityValue;
  };

  render() {
    const {
      props: {
        ven,
        enableEdit,
        enableUpgrade,
        enableSuspend,
        enableUnpair,
        currentRouteParams,
        isSuperCluster,
        supportReports,
        supportReportsIsEnabled,
        generateSupportReportIsEnabled,
        edgeEnabled,
        crowdstrikeEnabled,
      },
      state: {toggleSuspend, error, saving, onSaveDone},
    } = this;

    const workload = ven.workloads[0];
    const classNameContent = styles.content;
    const policyState = getPolicyState(workload?.mode, workload?.log_traffic);

    const selectedKeySetWithVen = new Set();
    const selectedKeySetPairedWithKerberosOrPKI = new Set();
    const selectedKeySetPairedWithPairingKey = new Set();
    const selectedKeySetPolicyStateIdlePairingKey = new Set();
    const selectedKeySetPolicyStateIdleKerberosPKI = new Set();
    const pending =
      this.state.pending ||
      (supportReports[0] &&
        !supportReports[0].received_at &&
        new Date(supportReports[0].requested_at).getTime() + intl.utils.DAY > Date.now());

    if (ven.activation_type === 'pairing_key') {
      selectedKeySetWithVen.add(ven.href);
      selectedKeySetPairedWithPairingKey.add(ven.href);

      if (policyState === 'idle') {
        selectedKeySetPolicyStateIdlePairingKey.add(ven.href);
      }
    } else if (ven.activation_type === 'kerberos' || ven.activation_type === 'certificate') {
      selectedKeySetWithVen.add(ven.href);
      selectedKeySetPairedWithPairingKey.add(ven.href);

      if (policyState === 'idle') {
        selectedKeySetPolicyStateIdleKerberosPKI.add(ven.href);
      }
    }

    const enforcementModeViewText = enforcementModeView();

    const workloadDetails = workload
      ? [
          {divider: true},
          {title: intl('Common.Workload')},
          {
            tid: 'name',
            key: intl('Common.Name'),
            value: (
              <Link to="workloads.item" params={{id: hrefUtils.getId(workload.href)}}>
                {workload.name || workload.hostname}
              </Link>
            ),
          },
          {
            tid: 'enforcement-mode',
            key: intl('Common.Enforcement'),
            value: (
              <>
                {
                  enforcementModeViewText[
                    edgeEnabled && workload.enforcement_mode === 'full' ? 'enforced' : workload.enforcement_mode
                  ]?.name
                }
                <div className={classNameContent}>
                  {edgeEnabled
                    ? enforcementModeViewEdge[workload.enforcement_mode]?.desc
                    : enforcementModeViewText[workload.enforcement_mode]?.desc}
                </div>
              </>
            ),
          },
          !crowdstrikeEnabled && {
            tid: 'visibility-level',
            key: intl('Common.Visibility'),
            value: this.renderVenVisibility(workload, classNameContent, edgeEnabled),
          },
          {
            tid: 'policysync',
            key: intl('Workloads.PolicySync'),
            value: getContainerWorkloadStatusIntl(workload.security_policy_sync_state),
          },
          {
            tid: 'policylastrecieve',
            key: intl('Workloads.PolicyLastReceived'),
            value: intl.utils.format.dateAtTimeBy(workload.security_policy_received_at),
          },
          {
            tid: 'interfaces',
            key: intl('Workloads.Interfaces'),
            value:
              Array.isArray(workload.interfaces) && workload.interfaces.length !== 0
                ? _.sortBy(workload.interfaces, ['name', 'address', 'default_gateway_address']).map(iface => {
                    return getInterfaceDisplay({
                      workload,
                      workloadInterface: {
                        ...iface,
                        from_ip: iface.cidr_block ? iface.address + '/' + iface.cidr_block : iface.address,
                      },
                      unmanaged: !ven.status,
                    });
                  })
                : intl('Workloads.NoInterfaces'),
          },
          !edgeEnabled && {
            tid: 'publicip',
            key: intl('Common.IPAddressPublic'),
            value: workload.public_ip,
          },
          {
            tid: 'labelrole',
            key: edgeEnabled ? intl('Common.Group') : getLabelTypeName('role'),
            value: this.renderLabel('role'),
          },
          !edgeEnabled && {
            tid: 'labelapp',
            key: getLabelTypeName('app'),
            value: this.renderLabel('app'),
          },
          !edgeEnabled && {
            tid: 'labelenv',
            key: getLabelTypeName('env'),
            value: this.renderLabel('env'),
          },
          !edgeEnabled && {
            tid: 'labelloc',
            key: getLabelTypeName('loc'),
            value: this.renderLabel('loc'),
          },
        ]
      : [];

    return (
      <>
        <VenUpgradeRequestedNotice />
        {supportReportsIsEnabled && pending ? (
          <Notifications sidebar>
            {[
              {
                type: 'instruction',
                message: intl('Workloads.Summary.TenMinuteWait'),
              },
            ]}
          </Notifications>
        ) : null}
        <ToolBar>
          <ToolGroup>
            <Button.Link
              textIsHideable
              text={intl('Common.Edit')}
              icon="edit"
              tid="edit"
              link={{to: 'workloads.vens.item.edit', params: {id: currentRouteParams.id}}}
              disabled={!enableEdit}
            />
            <VenUnpair
              onDone={this.handleUnpairDone}
              hrefsToUnpair={selectedKeySetWithVen}
              disabled={!enableUnpair}
              hrefsKerberosPKI={selectedKeySetPairedWithKerberosOrPKI}
              hrefsPairingKey={selectedKeySetPairedWithPairingKey}
              hrefsIdlePairingKey={selectedKeySetPolicyStateIdlePairingKey}
              hrefsIdleKerberosPKI={selectedKeySetPolicyStateIdleKerberosPKI}
              edgeEnabled={edgeEnabled}
            />

            <VenUpgrade venSet={selectedKeySetWithVen} vensInView={1} disableViaCaps={!enableUpgrade} />

            <Button
              color="standard"
              textIsHideable
              text={intl('Common.GenerateSupportReport')}
              tid="generatereport"
              onClick={this.handleGenerateReport}
              disabled={!supportReportsIsEnabled || pending || !generateSupportReportIsEnabled}
              onProgressDone={onSaveDone}
              progressCompleteWithCheckmark
              progress={saving}
              progressError={error !== null}
            />
            {!edgeEnabled ? (
              ven.status === 'suspended' ? (
                <Button onClick={this.handleToggleSuspend} disabled={!enableSuspend} tid="ven-clear-suspend">
                  {intl('Workloads.ClearSuspension')}
                </Button>
              ) : (
                <Button onClick={this.handleToggleSuspend} disabled={!enableSuspend} tid="ven-suspend">
                  {intl('Workloads.Suspend')}
                </Button>
              )
            ) : null}
          </ToolGroup>
        </ToolBar>
        <AttributeList>
          {[
            {divider: true},
            {title: intl('Common.Node')},
            {
              tid: 'name',
              key: intl('Common.Name'),
              value: ven.name || ven.hostname,
            },
            {
              tid: 'description',
              key: intl('Common.Description'),
              value: ven.description,
            },
            {
              tid: 'hostname',
              key: intl('Common.Hostname'),
              value: ven.hostname,
            },
            {
              tid: 'nodetype',
              key: intl('VEN.EnforcementNodeType'),
              value: intl('VEN.VEN'),
            },
            {
              tid: 'version',
              key: intl('Common.Version'),
              value: ven.version,
            },
            {
              tid: 'activationtype',
              key: intl('VEN.ActivationType'),
              value: getActivationType(ven.activation_type),
            },
            ven.container_cluster
              ? {
                  tid: 'containercluster',
                  key: intl('VEN.ContainerCluster'),
                  value: (
                    <Link to="containerClusters.item" params={{id: hrefUtils.getId(ven.container_cluster.href)}}>
                      {ven.container_cluster.name}
                    </Link>
                  ),
                }
              : null,

            {divider: true},

            {title: intl('Common.Status')},
            isSuperCluster
              ? {
                  tid: 'active_pce_fqdn',
                  key: intl('Common.PCE'),
                  value: ven.active_pce_fqdn,
                }
              : null,
            {
              tid: 'status',
              key: intl('Common.Status'),
              value: venStatuses()[ven.status],
            },
            {
              tid: 'conditions',
              key: intl('VEN.Health'),
              value: getVenConditions(ven.conditions),
            },
            {
              tid: 'heartbeat',
              key: intl('Workloads.LastHeartbeatReceived'),
              value: intl.utils.format.dateAtTimeBy(ven.last_heartbeat_at),
            },

            {divider: true},

            {title: intl('VEN.Host')},
            !edgeEnabled && {
              tid: 'location',
              key: intl('Common.Location'),
              value: locationText(workload?.data_center, workload?.data_center_zone, true),
            },
            ven.os_id
              ? {
                  tid: 'osfamily',
                  key: intl('Common.OS'),
                  value: ven.os_id,
                }
              : null,
            ven.os_detail
              ? {
                  tid: 'release',
                  key: intl('Workloads.Release'),
                  value: ven.os_detail,
                }
              : null,
            ...workloadDetails,
            supportReportsIsEnabled ? {divider: true} : null,
            supportReportsIsEnabled ? {title: intl('SupportReports.SupportReport')} : null,
            supportReportsIsEnabled
              ? {
                  contentGap: 'gap',
                  content: <SupportReportsList ven={ven.href} ref={this.supportReport} />,
                }
              : null,
          ]}
        </AttributeList>
        {toggleSuspend && this.renderToggleSuspend()}
      </>
    );
  }
}
