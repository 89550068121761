/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */
/* intl-disable */
import intl from 'intl';
import {lookupProtocol} from 'containers/Service/ServiceUtils';
import {getTransmissionName} from 'containers/Events/Detail/EventsDetailsUtils';
import {getId, collator} from 'utils/href';
import {createSelector} from 'reselect';
import {hrefUtils} from 'utils';

export const getConnectionStateName = state => {
  switch (state) {
    case 'active':
      return intl('Common.Active');
    case 'closed':
      return intl('Explorer.Closed');
    case 'timed out':
      return intl('Explorer.TimedOut');
    case 'static':
      return intl('Common.Static');
    case 'snapshot':
      return intl('Common.Static');
    case 'new':
      return intl('Common.New');
    default:
      return intl('Common.Unknown');
  }
};

export const getPolicyDecisionName = policyDecision => {
  switch (policyDecision) {
    case 'allowed':
      return intl('Common.Allowed');
    case 'blocked':
      return intl('Common.Blocked');
    case 'potentially_blocked':
    case 'potentiallyBlocked':
      return intl('Common.PotentiallyBlocked');
    default:
      return intl('Common.Unknown');
  }
};

export const getEndpointTypeName = type => {
  switch (type) {
    case 'workload':
      return intl('Common.Workloads');
    case 'virtualService':
      return intl('Common.VirtualServices');
    case 'virtualServer':
      return intl('Common.VirtualServers');
    case 'ipList':
      return intl('Common.IPRange');
    case 'fqdn':
      return intl('PCE.FQDN');
    case 'ipAddress':
      return intl('Common.IPAddress');
    default:
      return '';
  }
};

export const getEndpointType = endpoint => {
  if (endpoint?.workload) {
    return 'workload';
  }

  if (endpoint?.virtual_service) {
    return 'virtualService';
  }

  if (endpoint?.virtual_server) {
    return 'virtualServer';
  }

  if (endpoint?.ip_lists) {
    return 'ipList';
  }

  if (endpoint?.fqdn) {
    return 'fqdn';
  }

  return 'ipAddress';
};

export const getAppGroupName = (labelObject, appGroupTypes) => {
  if (labelObject && appGroupTypes) {
    const name = appGroupTypes.map(type => labelObject[type]?.value).filter(Boolean);

    if (name.length === appGroupTypes.length) {
      return name.join(' | ');
    }
  }

  return intl('Common.NoAppGroup');
};

export const createAppGroupHref = (labels, appGroupsType) =>
  labels
    .reduce((result, label) => {
      if (!label.key || (appGroupsType && appGroupsType.includes(label.key))) {
        const id = getId(label.label?.href || label.href);

        if (id) {
          result.push(id);
        }
      }

      return result;
    }, [])
    .sort((a, b) => collator.compare(a, b))
    .join('x');

export const getNodeAppGroupParent = (node, appGroupsType) => {
  if (
    !appGroupsType ||
    !appGroupsType.length ||
    !node.labels ||
    !appGroupsType.every(key => node.labels.find(label => label.key === key))
  ) {
    return;
  }

  return createAppGroupHref(node.labels, appGroupsType);
};

export const getLabelObject = labels => {
  if (labels) {
    return labels.reduce((result, label) => {
      label.id = hrefUtils.getId(label.href);
      result[label.key] = label;

      return result;
    }, {});
  }
};

export const getParsedEndpoint = (endpoint, appGroupTypes, transmission) => {
  const ep = endpoint?.workload || endpoint?.virtual_service || endpoint?.virtual_server || endpoint;
  const type = getEndpointType(endpoint);
  const labelObject = getLabelObject(ep?.labels);

  return {
    ...ep,
    ...labelObject,
    labelObject,
    type,
    ip: endpoint?.ip,
    name: type === 'ipList' ? ep.ip_lists[0] : ep?.name || ep?.hostname,
    appGroup: getAppGroupName(labelObject, appGroupTypes),
    transmission: getTransmissionName(transmission),
  };
};

export const getParsedService = link => ({
  port: link.service.port,
  protocol: lookupProtocol(link.service.proto),
  protocolNum: link.service.proto,
  processName: link.service.process_name,
  windowsService: link.service.windows_service_name,
  username: link.service.user_name,
});

export const getProcess = link => link.service.windowsService || link.service.processName;

export const getLinkKey = link =>
  [link.service.port, link.service.protocolNum, link.source.ip, link.target.ip].join(',');

export const getServiceKey = link =>
  [link.service.port, link.service.protocolNum, link.service.processName, link.service.windowsService].join(',');

export const explorerPolicyViewOptions = createSelector([], () => [
  {label: intl('Map.DraftView'), value: 'draft'},
  {label: intl('Map.ReportedView'), value: 'reported'},
]);

// `explorerValue` the value sent to Explorer for correct mapping to the time filter in explorer
// In cases where the value and the label are different, like the pair ['Last 24 Hours', 'Last Day'], we send `explorerValue` otherwise, `label`
// 'Last Day' is the underlaying value Explorer expects, but we display the text 'Last 24 Hours'
export const explorerTimeOptions = createSelector([], () => [
  {label: intl('Explorer.LastHours', {count: 1}), value: 'hour'},
  // EYE-80272 use "Last 24 Hours" in place of "Last Day"
  {label: intl('Explorer.LastHours', {count: 24}), explorerValue: intl('Explorer.LastDays', {count: 1}), value: 'day'},
  {label: intl('Explorer.LastWeeks', {count: 1}), value: 'week'},
  {label: intl('Explorer.LastWeeks', {count: 2}), value: 'twoWeeks'},
  {
    label: intl('Explorer.LastMonths', {count: 1}),
    value: 'month',
  },
]);

export const explorerDirectionOptions = createSelector([], () => [
  {label: intl('Common.Inbound'), value: 'inbound'},
  {label: intl('Common.Outbound'), value: 'outbound'},
]);

export const explorerDataTypeOptions = createSelector([], () => [
  {label: intl('Explorer.TopPorts'), value: 'port'},
  {label: intl('Common.Workloads'), value: 'workloads'},
]);

export const explorerDataTypeDefaultOption = 'port';

export const calculateTime = value => {
  const now = new Date();

  switch (value) {
    case intl('DateTimeInput.Now'):
      return now;
    case intl('Explorer.LastHours', {count: 1}):
    case intl('Explorer.HoursAgo', {count: 1}):
      return intl.utils.subtractTime(now, 'h', 1);
    case intl('Explorer.LastHours', {count: 24}):
    case intl('Explorer.HoursAgo', {count: 24}):
    case intl('Explorer.LastDays', {count: 1}):
    case intl('Explorer.DaysAgo', {count: 1}):
      return intl.utils.subtractTime(now, 'd', 1);
    case intl('Explorer.LastWeeks', {count: 1}):
    case intl('Explorer.WeeksAgo', {count: 1}):
      return intl.utils.subtractTime(now, 'd', 7);
    case intl('Explorer.LastWeeks', {count: 2}):
      return intl.utils.subtractTime(now, 'd', 15);
    case intl('Explorer.LastMonths', {count: 1}):
    case intl('Explorer.MonthsAgo', {count: 1}):
      return intl.utils.subtractTime(now, 'M', 1);
    case intl('DateTimeInput.Anytime'):
      return intl.utils.subtractTime(now, 'y', 5);
  }
};

export const getTime = key => {
  let time = calculateTime(key);

  if (time) {
    return time.toISOString();
  }

  time = explorerTimeOptions().find(time => key === time.value);

  if (time) {
    return calculateTime(time.label).toISOString();
  }

  return calculateTime(intl('Explorer.DaysAgo', {count: 1})).toISOString();
};

export const getBaseExplorerQuery = time => ({
  sources: {
    include: [[]],
    exclude: [],
  },
  destinations: {
    include: [[]],
    exclude: [],
  },
  services: {
    include: [],
    exclude: [],
  },
  sources_destinations_query_op: 'and',
  start_date: getTime(time),
  end_date: new Date().toISOString(),
  policy_decisions: ['potentially_blocked', 'allowed', 'blocked', 'unknown'],
  max_results: 10_000,
});

export const getLabelKey = labels =>
  labels
    .map(label => label.href.split('/').pop())
    .sort((a, b) => a - b)
    .join(',');

export const getRuleCoverageEntity = (endpoint, labelBased) => {
  const type = getEndpointType(endpoint);
  const ep = endpoint.workload || endpoint.virtual_service || endpoint.virtual_server || endpoint;
  const labels = ep.labels;

  switch (type) {
    case 'ipList':
      return {ip_list: {href: endpoint.href}};
    case 'workload':
      return labelBased && labels && labels.length > 0
        ? {labels: labels.map(label => ({href: label.href}))}
        : ep.href
        ? {workload: {href: ep.href}}
        : {actors: 'ams'};
    case 'virtualService':
      return labelBased && labels && labels.length > 0
        ? {labels: labels.map(label => ({href: label.href}))}
        : {virtual_service: {href: ep.href}};
    case 'virtualServer':
      return labelBased && labels && labels.length > 0
        ? {labels: labels.map(label => ({href: label.href}))}
        : {virtual_server: {href: ep.href}};
  }

  return {actors: 'all'};
};

export const getRuleCoverageForLinks = (links, labelBased, force) =>
  Object.values(
    links.reduce((result, row, index) => {
      if (row.rules && (!force || row.network?.name === 'External')) {
        return result;
      }

      let rows = [row];

      if (row.src.ip_lists) {
        rows = row.src.ip_lists.map(list => ({...row, src: {ip_lists: row.src.ip_lists, href: list.href}}));
      } else if (row.dst.ip_lists) {
        rows = row.dst.ip_lists.map(list => ({...row, dst: {ip_lists: row.dst.ip_lists, href: list.href}}));
      }

      rows.forEach(link => {
        const source = getRuleCoverageEntity(link.src, labelBased);
        const destination = getRuleCoverageEntity(link.dst, labelBased);

        const linkHref = [
          source.labels ? getLabelKey(source.labels) : link.src.href,
          destination.labels ? getLabelKey(destination.labels) : link.dst.href,
        ].join(',');

        const resolveSourceAs =
          getEndpointType(link.src) === intl('Common.VirtualServices') ? ['virtual_services'] : ['workloads'];
        const resolveTargetAs =
          getEndpointType(link.dst) === intl('Common.VirtualServices') ? ['virtual_services'] : ['workloads'];

        if (!result[linkHref]) {
          result[linkHref] = {
            serviceMap: {},
            indicies: [],
            query: {
              source,
              destination,
              services: [],
              resolve_labels_as: {
                source: resolveSourceAs,
                destination: resolveTargetAs,
              },
            },
          };
        }

        // Add to the resolve as array if needed
        if (!result[linkHref].query.resolve_labels_as.source.includes(resolveSourceAs[0])) {
          result[linkHref].query.resolve_labels_as.source.push(resolveSourceAs[0]);
        }

        if (!result[linkHref].query.resolve_labels_as.destination.includes(resolveTargetAs[0])) {
          result[linkHref].query.resolve_labels_as.destination.push(resolveTargetAs[0]);
        }

        const {port, protocolNum, processName, windowsService} = getParsedService(link);
        const destinationOs = link.dst.workload?.os_type;

        const serviceMapKey = [linkHref, port, protocolNum, processName, windowsService].join(',');
        let serviceIndex = result[linkHref].serviceMap[serviceMapKey];

        // If not already available add it to the list
        if (serviceIndex === undefined) {
          serviceIndex = result[linkHref].query.services.length;
          result[linkHref].serviceMap[serviceMapKey] = serviceIndex;

          const newService = {
            protocol: protocolNum,
            os_type: destinationOs === 'linux' ? 'linux' : 'windows',
          };

          if (protocolNum === 6 || protocolNum === 17) {
            newService.port = port;
          }

          if (processName) {
            newService.process_name = processName;
          }

          if (destinationOs === 'windows' && windowsService) {
            newService.windows_service_name = windowsService;
          }

          result[linkHref].query.services.push(newService);
        }

        // Add all the traffic indicies for this service
        if (result[linkHref].indicies[serviceIndex]) {
          result[linkHref].indicies[serviceIndex].push(index);
        } else {
          result[linkHref].indicies[serviceIndex] = [index];
        }
      });

      return result;
    }, {}) || {},
  );

const getItems = types => (types ? Object.values(types || {}).filter(type => type.length) : []);

export const getNestedEndpointQuery = types => {
  const finalProduct = Object.keys(types).reduce((result, type) => {
    const items = types[type];

    if (type === 'labels') {
      // For the Label items send the cartesian product of each type of label
      // Example: role: [r1, r2, r3] and env: [e1, e2]
      // Is sent as: [[r1, e1], [r1, e2], [r2, e1], [r2, e2], [r3, e1], [r3, e2]]
      // Algorithm taken from http://stackoverflow.com/questions/12303989
      const product = Object.values(getItems(items)).reduce(
        (result, labelType) => result.flatMap(inner => labelType.map(label => inner.concat([label]))),
        [[]],
      );

      return product[0].length ? [...result, ...product] : result;
    }

    if (items.length && type !== 'tranmission') {
      items.forEach(item => result.push(type === 'appgroups' ? item : [item]));
    }

    return result;
  }, []);

  return finalProduct.length ? finalProduct : [[]];
};

function getAggregatedEndpointKey(link, ep) {
  const endpoint = link[ep];

  if (endpoint.labels) {
    if (!endpoint.href) {
      return null;
    }

    return endpoint.labels.map(label => hrefUtils.getId(label.href)).join(',') || 'empty_labels';
  }

  if (endpoint.type === 'ipList') {
    return endpoint.ip_lists.map(list => list.href).join(',');
  }

  return 'any';
}

export function aggregateLinkCount(links) {
  const aggregated = links.reduce((result, link) => {
    if (link.source.type === 'virtualService' || link.target.type === 'virtualService') {
      return result;
    }

    const serviceKey = [link.service.port, link.service.protocol].join(',');
    const srcEndpointKey = getAggregatedEndpointKey(link, 'source');
    const dstEndpointKey = getAggregatedEndpointKey(link, 'target');
    const linkKey = [serviceKey, srcEndpointKey, dstEndpointKey].join(',');

    if (srcEndpointKey && dstEndpointKey) {
      result.add(linkKey);
    }

    return result;
  }, new Set());

  return aggregated.size;
}

const QUERY_STATUS = {
  WORKING: 'working',
  QUEUED: 'queued',
  KILLED: 'killed',
  COMPLETED: 'completed',
  FAILED: 'failed',
  CANCEL_REQUESTED: 'cancel_requested',
};

export function isQueryPending(status) {
  return status === QUERY_STATUS.QUEUED || status === QUERY_STATUS.WORKING;
}

export function isQueryComplete(status) {
  return status === QUERY_STATUS.COMPLETED;
}

export function isQueryKilled(status) {
  return status === QUERY_STATUS.KILLED;
}

export function isQueryFailed(status) {
  return status === QUERY_STATUS.FAILED;
}

export function isQueryCancelled(status) {
  return status === QUERY_STATUS.CANCEL_REQUESTED;
}
