/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import {createSelector} from 'reselect';
import {hrefUtils} from 'utils';
import {getRouteName} from 'containers/App/AppState';
import {isUserReadOnly, isUserReadOnlyAll, doesUserHaveGlobalObjectPermissions} from 'containers/User/UserState';
import {getLoadBalancersMap, getLoadBalancerInstance} from 'containers/LoadBalancer/Item/LoadBalancerItemState';
import {getLabelsHrefMap} from 'containers/Label/List/LabelListState';
import {getScopeLabelByHref} from 'containers/Selectors/SelectorUtils';
import {getGridSelector, getUrlScopeValue} from 'components/Grid/GridSelectors';
import {gridSettings, getSelectorSettings} from './VirtualServerListConfig';
import {isOnLoadBalancerPage, getFriendlyMode} from '../VirtualServerUtils';

export default {
  dvs(state = [], action) {
    switch (action.type) {
      case 'DISCOVERED_VIRTUAL_SERVERS':
        return action.data;
      default:
        return state;
    }
  },

  count(state = {}, action) {
    switch (action.type) {
      case 'VIRTUAL_SERVER_GET_LIST':
        return action.data.count;
      default:
        return state;
    }
  },
};

export const getDiscoveredVirtualServers = state => state.virtualservers.dvs;
export const getVirtualServersCount = state => state.virtualservers.count;

const getVirtualServersRows = createSelector(
  [
    getDiscoveredVirtualServers,
    isOnLoadBalancerPage,
    getLoadBalancersMap,
    getLoadBalancerInstance,
    isUserReadOnly,
    doesUserHaveGlobalObjectPermissions,
  ],
  (
    virtualServers,
    isOnLoadBalancerPage,
    loadBalancersMap,
    currentSLB,
    userIsReadOnly,
    userHasGlobalObjectPermissions,
  ) =>
    virtualServers
      .filter(object => {
        if (isOnLoadBalancerPage) {
          return object.slb.href === currentSLB.href;
        }

        return object.virtual_server !== null;
      })
      .map(dvs => {
        const item = dvs.virtual_server;
        const {vip_port: vipPort, mode: dvsMode, name, href: vshref} = dvs;

        return {
          key: item?.href || dvs.href,
          // Virtual Server is in draft mode if it has pending status
          draft: Boolean(item?.update_type),
          selectable: userHasGlobalObjectPermissions,
          // Virtual Server is removable only if it's active or with pending changes expect pending deletion
          writable: !userIsReadOnly && (!item?.update_type || item?.update_type !== 'delete'),
          // Fill each IPList with user object
          data: {
            ...item,
            managed: getFriendlyMode(item?.mode),
            vshref,
            name,
            vipPort,
            dvsMode,
            slb: loadBalancersMap.get(dvs.slb.href),
            labels: !item
              ? {}
              : item.labels.reduce((result, label) => {
                  if (label) {
                    result[label.key] = {...label, id: hrefUtils.getId(label.href)};
                  }

                  return result;
                }, {}),
          },
        };
      }),
);

export const getGridSettings = createSelector(
  [isUserReadOnlyAll, isOnLoadBalancerPage, gridSettings],
  (userIsReadOnlyAll, isOnLoadBalancerPage, gridSettings) => {
    const columns = {...gridSettings.columns};

    columns.slb.disabled = isOnLoadBalancerPage;
    columns.checkboxes.disabled = userIsReadOnlyAll;

    return {...gridSettings, columns};
  },
);

export const getFilterMap = createSelector([getSelectorSettings], ({filterMap}) => filterMap);

const getGrid = state =>
  getGridSelector(state, {
    settings: getGridSettings,
    rows: getVirtualServersRows,
    filterMap: getFilterMap,
  });

export const getScopeItems = createSelector(
  [getUrlScopeValue, getLabelsHrefMap, getFilterMap],
  (scope, labelsMap, filterMap) => {
    if (scope.isEmpty) {
      return [];
    }

    return scope.valid.scope.map(({href}) => {
      const label = labelsMap[href] || getScopeLabelByHref(href) || {};

      return {
        href,
        value: label.value,
        categoryKey: label.key,
        key: label.key,
        scope: true,
        categoryName: _.get(filterMap[label.key], 'value'),
      };
    });
  },
);

export const getVirtualServersPage = createSelector(
  [
    getGrid,
    getVirtualServersCount,
    getDiscoveredVirtualServers,
    getRouteName,
    getSelectorSettings,
    getScopeItems,
    getFilterMap,
  ],
  (grid, count, dvsList, routeName, selectorSettings, scopeItems, filterMap) => {
    const {objectMap, facetMap, scopeMap, staticValues, autocompleteMap} = selectorSettings;

    const filterItems = Object.keys(grid.filter).reduce((result, categoryKey) => {
      if (scopeMap[categoryKey]) {
        const filterItems = grid.filter[categoryKey];

        filterItems.forEach(({href, value, key}) => {
          result.push({
            href,
            value,
            categoryKey,
            key,
            categoryName: filterMap[categoryKey].value,
          });
        });
      } else if (autocompleteMap[categoryKey]) {
        grid.filter[categoryKey].forEach(({href, value}) => {
          result.push({href, value, categoryKey, categoryName: _.get(filterMap[categoryKey], 'value')});
        });
      } else {
        result.push({
          categoryKey,
          value: grid.filter[categoryKey][0]?.value || grid.filter[categoryKey][0],
          categoryName: filterMap[categoryKey].value || filterMap[categoryKey],
        });
      }

      return result;
    }, []);

    const selector = {
      initialItems: [...scopeItems, ...filterItems],
      objects: Object.values(objectMap),
      categories: Object.entries(filterMap).map(([categoryKey, value]) => {
        const category = {categoryKey, value: value.value || value, object: value.object, statics: value.statics};

        return category;
      }),
      facets: Object.keys(facetMap),
      partials: Object.keys(facetMap),
      statics: Object.entries(staticValues).reduce((result, [key, values]) => {
        result[key] = Object.keys(values);

        return result;
      }, {}),
    };

    return {grid, selector, count, dvsList, routeName};
  },
);
