/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from 'intl';
import {createSelector} from 'reselect';
import {
  getAllUsersMap,
  isUserReadOnly,
  isUserReadOnlyAll,
  doesUserHaveGlobalObjectPermissions,
} from 'containers/User/UserState';
import {getGridSelector} from 'components/Grid/GridSelectors';
import {getSelectorSettings, gridSettings} from './ServiceListConfig';
import {lookupProtocol} from '../ServiceUtils';
import {fillUserInfo} from 'containers/RBAC/RBACUtils';

export default {
  list(state = [], action) {
    switch (action.type) {
      case 'SERVICE_GET_LIST':
        return action.data.list;
      default:
        return state;
    }
  },

  count(state = {}, action) {
    switch (action.type) {
      case 'SERVICE_GET_LIST':
        return action.data.count;
      default:
        return state;
    }
  },

  serviceDefinitions(state = [], action) {
    switch (action.type) {
      case 'SERVICE_DEFINITIONS_GET_LIST':
        return action.data;
      default:
        return state;
    }
  },
  allServicesHref(state = null, action) {
    switch (action.type) {
      case 'GET_ALL_SERVICES_HREF':
        return action.data;
      default:
        return state;
    }
  },
  map(state = {}, action) {
    switch (action.type) {
      case 'SERVICE_GET_LIST': {
        const result = {...state};

        for (const service of action.data.list) {
          result[service.href] = service;
        }

        return result;
      }
      case 'SERVICE_GET_INSTANCE':
        if (action.data && !_.isEqual(action.data, state[action.data.href])) {
          return {...state, [action.data.href]: action.data};
        }

        return state;
      default:
        return state;
    }
  },
};

export const getServices = state => state.service.list;
export const getServicesCount = state => state.service.count;
export const getServicesHrefMap = state => state.service.map;
export const getAllServicesHref = state => state.service.allServicesHref;

const getServicesRows = createSelector(
  [getServices, getAllUsersMap, isUserReadOnly, doesUserHaveGlobalObjectPermissions],
  (services, usersMap, userIsReadOnly, userHasGlobalObjectPermissions) =>
    services.map(item => ({
      key: item.href,
      // Service is in draft mode if it has pending status
      draft: Boolean(item.update_type),
      // Make system services not selectable, since it cannot be removed or provisioned
      selectable: userHasGlobalObjectPermissions && item.name !== intl('Common.AllServices'),
      // Service is removable only if it's active or with pending changes expect pending deletion
      removable: !userIsReadOnly && (!item.update_type || item.update_type !== 'delete'),
      // Fill each Service with user object
      data: {
        ...item,
        created_by: fillUserInfo(usersMap, item.created_by),
        updated_by: fillUserInfo(usersMap, item.updated_by),
      },
    })),
);

export const getGridSettings = createSelector([isUserReadOnlyAll, gridSettings], (userIsReadOnlyAll, gridSettings) => {
  const columns = {...gridSettings.columns};

  columns.checkboxes.disabled = userIsReadOnlyAll;

  return {...gridSettings, columns};
});

const getGrid = state =>
  getGridSelector(state, {
    settings: getGridSettings,
    rows: getServicesRows,
    filterMap: getSelectorSettings().filterMap,
  });

export const getServicesPage = createSelector(
  [getServicesCount, getGrid, isUserReadOnly, getSelectorSettings],
  (count, grid, userIsReadOnly, selectorSettingsObject) => {
    // Selector parameters based on filter and config
    const selector = {
      initialItems: Object.entries(grid.filter).map(([categoryKey, [value]]) => ({
        categoryKey,
        value: categoryKey === 'protocol' ? lookupProtocol(value) : value,
        categoryName: selectorSettingsObject.filterMap[categoryKey],
      })),
      categories: Object.entries(selectorSettingsObject.filterMap).map(([categoryKey, value]) => ({
        categoryKey,
        value,
      })),
      facets: Object.keys(selectorSettingsObject.facetMap),
      partials: Object.keys(selectorSettingsObject.facetMap),
      statics: Object.entries(selectorSettingsObject.staticValues).reduce((result, [key, values]) => {
        result[key] = Object.keys(values);

        return result;
      }, {}),
    };

    return {grid, count, selector, userIsReadOnly};
  },
);
