/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import cx from 'classnames';
import {useContext} from 'react';
import * as PropTypes from 'prop-types';
import {mixThemeWithProps} from '@css-modules-theme/react';
import ModalStickyShadow from './ModalStickyShadow';
import {ModalContext} from './ModalUtils';
import {tidUtils} from 'utils';
import {classSplitter} from 'utils/general';
import styles from './ModalContent.css';
import stylesUtils from 'utils.css';

ModalContent.propTypes = {
  // Whether the content is insensitive to mouse click, currently it is only true during the command execution in ModalGateway
  insensitive: PropTypes.bool,
  // Don't make content scrollable, i.e don't apply overflow to the container.
  // Might be useful when you have absolute element inside relative inside content (like Selector) and you know there is no other content
  notScrollable: PropTypes.bool,
  // Don't add vertical/horizontal paddings, useful when you want something to occupy full content area, like Grid
  noPaddings: PropTypes.bool,
  // Gap class to set apart children, by default is `gapMedium`. Can be any valid list of classes, like `gapSmall gapHorizontalWrap`
  gap: PropTypes.string,
};

export default function ModalContent(props) {
  const {tid} = useContext(ModalContext);
  const {
    children,
    insensitive,
    notScrollable,
    noPaddings,
    gap = 'gapMedium',
    theme,
    ...scrollerProps
  } = mixThemeWithProps(styles, props);
  const scrollable = !notScrollable;

  scrollerProps.className = cx(theme.scroller, {
    [stylesUtils.insensitive]: insensitive,
    [theme.scrollable]: scrollable,
  });

  const contentClass = cx(theme.content, classSplitter(stylesUtils, gap), {
    [theme.contentPadding]: !noPaddings,
  });

  return (
    <div {...scrollerProps}>
      {scrollable && <ModalStickyShadow top />}
      <div className={contentClass} data-tid={tidUtils.getTid('comp-dialog-content', tid)}>
        {children}
      </div>
      {scrollable && <ModalStickyShadow bottom />}
    </div>
  );
}
