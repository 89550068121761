/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import {createSelector} from 'reselect';
import {getRouteName, getRouteCurrentParams} from 'containers/App/AppState';
import {getLoadBalancerInstance} from '../LoadBalancerItemState';
import {getNetworkFunctionControls} from 'containers/LoadBalancer/List/LoadBalancerListState';

/** for all NENs (aka NFCs), provides a map of their supported device types as form selector options. **/
export const getDeviceTypeSelectorOptionsMap = createSelector(
  [getLoadBalancerInstance, getNetworkFunctionControls],
  (loadBalancer, nfcs) => {
    const deviceTypeMap = {};

    nfcs.forEach(nfc => {
      deviceTypeMap[nfc.href] = nfc.supported_device_types.map(deviceType => ({value: deviceType, label: deviceType}));
    });

    return deviceTypeMap;
  },
);

export const getLoadBalancerEditPage = createSelector(
  [
    getRouteName,
    getLoadBalancerInstance,
    getRouteCurrentParams,
    getNetworkFunctionControls,
    getDeviceTypeSelectorOptionsMap,
  ],
  (routeName, loadBalancer, currentRouteParams, nfcs, deviceTypeMap) => {
    const isEdit = routeName === 'app.loadBalancers.item.edit';

    if (routeName === 'app.loadBalancers.create') {
      loadBalancer = {};
    }

    const nenSelectorOptions = nfcs.map(node => ({
      label: Array.isArray(node.hostname) ? node.hostname[0] : node.hostname, // API provided this string as 1-element array
      value: node.href,
    }));

    // Initialize name
    if (!loadBalancer.name) {
      loadBalancer = {...loadBalancer, name: ''};
    }

    return {isEdit, routeName, loadBalancer, currentRouteParams, deviceTypeMap, nenSelectorOptions};
  },
);
