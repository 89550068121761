/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import {Component} from 'react';
import {connect} from 'react-redux';
import {AppContext} from 'containers/App/AppUtils';
import {hrefUtils} from 'utils';
import {isAPIAvailable} from 'api/apiUtils';
import {ButtonRefresh, Grid, Notifications, ToolBar, ToolGroup, Button} from 'components';
import {HeaderProps, ReportButtons} from 'containers';
import {getVensPage} from './VenListState';
import {fetchVenList} from './VenListSaga';
import {resourceType} from './VenListConfig';
import {getMaxPageNotificationList} from 'components/Grid/GridUtils';
import {ComboSelect} from 'containers/Selectors';
import VenUnpair from 'containers/Ven/Unpair/VenUnpair';
import VenUpgrade from 'containers/Ven/Upgrade/VenUpgrade';
import VenUpgradeConditions from 'containers/Ven/Upgrade/VenUpgradeConditions';
import styles from 'containers/Workload/List/WorkloadList.css';
import stylesUtils from 'utils.css';
import stylesGridUtils from 'components/Grid/GridUtils.css';

const buttonsTheme = {textIsHideable: styles['button-textIsHideable']};
const removeRowHighLight = {className: stylesGridUtils.rowToRemove};
const scopeFilterKeys = ['role', 'app', 'env', 'loc'];
const autocompleteFilterKeys = [...scopeFilterKeys, 'container_clusters'];

const getEmptyState = () => ({
  extraPropsKeyMap: new Map(),
  selectedKeySet: new Set(),
  selectedKeySetPolicyStateIdlePairingKey: new Set(),
  selectedKeySetPolicyStateIdleKerberosPKI: new Set(),
  selectedKeySetToUnpair: new Set(), // selected keys that the user is allowed to unpair
  selectedKeySetPairedWithPairingKey: new Set(),
  selectedKeySetPairedWithKerberosOrPKI: new Set(),
});

@connect((state, props) => getVensPage(state, props))
export default class VenList extends Component {
  static prefetch = fetchVenList;
  static contextType = AppContext;

  constructor(props) {
    super(props);

    this.state = getEmptyState();
    this.filterTimeout = null;
    this.filterCount = 0;

    this.handleClick = this.handleClick.bind(this);
    this.handleUnpairHover = this.handleUnpairHover.bind(this);
    this.handleUnpairDone = this.handleUnpairDone.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleRefresh = this.handleRefresh.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.grid.rows !== prevState.rows) {
      const nextState = {rows: nextProps.grid.rows};

      if (prevState.selectedKeySet.size > 0) {
        nextState.selectedKeySet = new Set();
        nextState.selectedKeySetToUnpair = new Set();
        nextState.selectedKeySetPairedWithPairingKey = new Set();
        nextState.selectedKeySetPairedWithKerberosOrPKI = new Set();
        nextState.selectedKeySetPolicyStateIdlePairingKey = new Set();
        nextState.selectedKeySetPolicyStateIdleKerberosPKI = new Set();

        for (const row of nextProps.grid.rows) {
          if (row.selectable && prevState.selectedKeySet.has(row.key)) {
            nextState.selectedKeySet.add(row.key);

            if (row.data.caps?.includes('unpair')) {
              nextState.selectedKeySetToUnpair.add(row.key);
            }

            if (row.data.activation_type === 'pairing_key') {
              nextState.selectedKeySetPairedWithPairingKey.add(row.key);

              if (row.data.workloads[0]?.mode === 'idle') {
                nextState.selectedKeySetPolicyStateIdlePairingKey.add(row.key);
              }
            }

            if (row.data.activation_type === 'kerberos' || row.data.activation_type === 'certificate') {
              nextState.selectedKeySetPairedWithKerberosOrPKI.add(row.key);

              if (row.data.workloads[0]?.mode === 'idle') {
                nextState.selectedKeySetPolicyStateIdleKerberosPKI.add(row.key);
              }
            }
          }
        }
      }

      return nextState;
    }

    return null;
  }

  handleClick(evt, row) {
    this.context.navigate({evt, to: 'workloads.vens.item', params: {id: hrefUtils.getId(row.key)}});
  }

  handleRefresh = () => {
    const {
      context: {fetcher},
      props: {isInGroupView, roleLabelHref},
    } = this;

    // Need to make a fetch call for Group Views on Dashboard since the
    // view is not hydrated with the prefetch saga for embedded views initially
    if (isInGroupView) {
      const {
        props: {params, pversionObj},
        context: {router},
      } = this;

      return fetcher.fork(fetchVenList, {params, router}, true, {
        customScope: {scope: [{key: 'role', href: roleLabelHref ?? pversionObj?.roleLabel?.href}]},
      });
    }

    return fetcher.fork(fetchVenList.refetch);
  };

  handleSelect({affectedRows, selecting}) {
    this.setState(state => {
      const selectedKeySet = new Set(state.selectedKeySet);
      const selectedKeySetToUnpair = new Set(state.selectedKeySetToUnpair);
      const selectedKeySetPairedWithPairingKey = new Set(state.selectedKeySetPairedWithPairingKey);
      const selectedKeySetPairedWithKerberosOrPKI = new Set(state.selectedKeySetPairedWithKerberosOrPKI);
      const selectedKeySetPolicyStateIdlePairingKey = new Set(state.selectedKeySetPolicyStateIdlePairingKey);
      const selectedKeySetPolicyStateIdleKerberosPKI = new Set(state.selectedKeySetPolicyStateIdleKerberosPKI);

      for (const row of affectedRows) {
        selectedKeySet[selecting ? 'add' : 'delete'](row.key);

        if (selecting) {
          if (row.data.caps?.includes('unpair')) {
            selectedKeySetToUnpair.add(row.key);
          }

          if (row.data.activation_type === 'pairing_key') {
            selectedKeySetPairedWithPairingKey.add(row.key);

            if (row.data.workloads[0]?.mode === 'idle') {
              selectedKeySetPolicyStateIdlePairingKey.add(row.key);
            }
          }

          if (row.data.activation_type === 'kerberos' || row.data.activation_type === 'certificate') {
            selectedKeySetPairedWithKerberosOrPKI.add(row.key);

            if (row.data.workloads[0]?.mode === 'idle') {
              selectedKeySetPolicyStateIdleKerberosPKI.add(row.key);
            }
          }
        } else {
          selectedKeySetToUnpair.delete(row.key);

          selectedKeySetPairedWithPairingKey.delete(row.key);
          selectedKeySetPairedWithKerberosOrPKI.delete(row.key);
          selectedKeySetPolicyStateIdlePairingKey.delete(row.key);
          selectedKeySetPolicyStateIdleKerberosPKI.delete(row.key);
        }
      }

      return {
        selectedKeySet,
        selectedKeySetToUnpair,
        selectedKeySetPolicyStateIdleKerberosPKI,
        selectedKeySetPolicyStateIdlePairingKey,
        selectedKeySetPairedWithPairingKey,
        selectedKeySetPairedWithKerberosOrPKI,
      };
    });
  }

  handleUnpairHover(evt, type) {
    this.setState(state => {
      if (type === 'enter' && state.selectedKeySetToUnpair.size) {
        return {
          extraPropsKeyMap: new Map(Array.from(state.selectedKeySetToUnpair, key => [key, removeRowHighLight])),
        };
      }

      if (type === 'leave' && state.extraPropsKeyMap.size) {
        return {extraPropsKeyMap: new Map()};
      }

      // Return null to prevent updating state
      return null;
    });
  }

  handleUnpairDone() {
    this.setState(getEmptyState());

    return this.handleRefresh();
  }

  handleFilterChange(selection, evt) {
    // The detail page ignores the scope
    const scope = selection
      .filter(item => scopeFilterKeys.includes(item.categoryKey))
      .map(({key, href}) => ({key, href}));

    const filter = selection.reduce((result, {categoryKey, href, value, name, from, to}) => {
      if (!scopeFilterKeys.includes(categoryKey)) {
        if (!this.props.edgeEnabled && autocompleteFilterKeys.includes(categoryKey)) {
          result[categoryKey] = [{value: name || value, href}];
        } else if (!from && !to) {
          result[categoryKey] = [value];
        } else {
          if (from) {
            result[`${categoryKey}[gte]`] = Array.isArray(from) ? from : [from];
          }

          if (to) {
            result[`${categoryKey}[lte]`] = Array.isArray(to) ? to : [to];
          }
        }
      }

      return result;
    }, {});

    clearTimeout(this.filterTimeout);

    const delay = this.filterCount > selection.length ? 250 : 0; // delays on filter delete, where ComboSelect visible

    this.filterCount = selection.length;
    this.filterTimeout = setTimeout(() => {
      this.context.navigate({
        evt,
        params: {
          scope: scope.length ? {scope} : undefined,
          [this.props.grid.settings.id]: {...this.props.grid.params, filter, page: null},
        },
      });
    }, delay);
  }

  render() {
    const {
      props: {
        grid,
        count,
        selector,
        supressVenUpgrade,
        gridOnly,
        gridOffset,
        edgeEnabled,
        noScope,
        hideHeaderProps,
        isInGroupView,
        includeMatches,
        selectedLabels,
        healthError,
        suspended,
        warning,
        pending,
        showActionButtons,
      },
      state: {
        selectedKeySet,
        selectedKeySetToUnpair,
        selectedKeySetPairedWithKerberosOrPKI,
        selectedKeySetPairedWithPairingKey,
        selectedKeySetPolicyStateIdleKerberosPKI,
        selectedKeySetPolicyStateIdlePairingKey,
        extraPropsKeyMap,
      },
    } = this;
    const notifications = getMaxPageNotificationList({page: grid.page, capacity: grid.capacity, count});

    return (
      <div className={stylesUtils.gap}>
        {!gridOnly && (
          <>
            {!hideHeaderProps && <HeaderProps subtitle={intl('Common.VENS')} />}
            {notifications.length > 0 && <Notifications sidebar>{notifications}</Notifications>}
            <ToolBar>
              <ToolGroup noSingleton>
                {!edgeEnabled && showActionButtons && (
                  <Button.Link
                    textIsHideable
                    text={intl('VEN.PairWithPairingProfile')}
                    link={{to: 'pairingProfiles.pair'}}
                    tid="add-paired"
                    icon="add"
                    disabled={!isAPIAvailable('pairing_profile.pairing_key')}
                  />
                )}
                {showActionButtons && (
                  <VenUnpair
                    showCounter
                    theme={buttonsTheme}
                    onButtonHover={this.handleUnpairHover}
                    onDone={this.handleUnpairDone}
                    hrefsToUnpair={selectedKeySetToUnpair}
                    disabled={!selectedKeySetToUnpair.size}
                    hrefsKerberosPKI={selectedKeySetPairedWithKerberosOrPKI}
                    hrefsPairingKey={selectedKeySetPairedWithPairingKey}
                    hrefsIdlePairingKey={selectedKeySetPolicyStateIdlePairingKey}
                    hrefsIdleKerberosPKI={selectedKeySetPolicyStateIdleKerberosPKI}
                    edgeEnabled={edgeEnabled}
                  />
                )}
                {!supressVenUpgrade && showActionButtons && (
                  <VenUpgrade
                    showCounter
                    theme={buttonsTheme}
                    enableMenu
                    venSet={selectedKeySet}
                    gridSettings={grid.settings}
                    filterItems={selector.filterItems}
                    vensInView={count.matched}
                  />
                )}
              </ToolGroup>
              <ToolGroup>
                {!supressVenUpgrade && (
                  <VenUpgradeConditions
                    type="ven"
                    healthError={healthError}
                    suspended={suspended}
                    warning={warning}
                    pending={pending}
                    onFilterClick={this.handleFilterChange}
                  />
                )}
                <ButtonRefresh color="standard" textIsHideable onRefresh={this.handleRefresh} theme={buttonsTheme} />
                <ReportButtons type="vens" disabledGen={!grid.rows.length} theme={buttonsTheme} />
              </ToolGroup>
            </ToolBar>

            {count.total > 0 && (
              <ToolBar>
                <ToolGroup expand tid="page-filter">
                  <ComboSelect
                    scrollable
                    objects={selector.objects}
                    placeholder={intl('Common.FilterView')}
                    initialItems={noScope ? selector.filterItems : selector.scopeItems.concat(selector.filterItems)}
                    categories={selector.categories}
                    activeCategoryKey="name"
                    partials={selector.partials}
                    facets={selector.facets}
                    statics={selector.statics}
                    customPickers={selector.customPickers}
                    onSelectionChange={this.handleFilterChange}
                    resourceType={resourceType}
                    includeMatches={isInGroupView ? includeMatches : []}
                    selectedLabels={isInGroupView ? selectedLabels : null}
                  />
                </ToolGroup>
              </ToolBar>
            )}
          </>
        )}
        <Grid
          grid={grid}
          count={count}
          onClick={this.handleClick}
          offset={gridOffset}
          selectedKeySet={selectedKeySet}
          dontHighlightSelected={extraPropsKeyMap.size > 0}
          extraPropsKeyMap={extraPropsKeyMap}
          emptyMessage={intl('VEN.NoData')}
          onSelect={this.handleSelect}
        />
      </div>
    );
  }
}
