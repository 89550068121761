/**
 * Copyright 2021 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import _ from 'lodash';
import {useSelector} from 'react-redux';
import {useCallback, useContext, useEffect, useState} from 'react';
import {Icon, Link} from 'components';
import {AppContext} from 'containers/App/AppUtils';
import {dismissWhatsNew, fetchWhatsNew, broadcast} from './WhatsNewPopupSaga';
import {getParsedUIVersionNumber, getProductName, getUIVersion} from 'containers/App/AppState';
import styles from './WhatsNewPopup.css';
import {getWhatsNewDocUrl} from './WhatsNewPopupState';

const isVersionOlder = (v1, v2) => {
  return v1.major < v2.major || v1.minor < v2.minor || v1.patch < v2.patch;
};

export default function WhatsNewPopup() {
  const [visible, setVisible] = useState(false);

  const versionString = useSelector(getUIVersion);
  const version = useSelector(getParsedUIVersionNumber);
  const product = useSelector(getProductName);
  const docUrl = useSelector(getWhatsNewDocUrl);
  const {fetcher} = useContext(AppContext);

  useEffect(() => {
    fetcher.fork(fetchWhatsNew).then(checkedVersion => {
      setVisible(
        // user never dimissed what's new ever before, we show the popup
        !checkedVersion ||
          // or the last dimissed version is older than the current one
          isVersionOlder(checkedVersion, version),
      );
    });
  }, [fetcher, version]);

  useEffect(() => {
    const handleMessage = ({type, data: checkedVersion}) => {
      if (type === 'WHATSNEW_DISMISS') {
        setVisible(!checkedVersion || isVersionOlder(checkedVersion, version));
      }
    };

    broadcast.addEventListener('message', handleMessage);

    return () => broadcast.removeEventListener('message', handleMessage);
  }, [version]);

  const handleWhatsNewClose = useCallback(async () => {
    fetcher.spawn(dismissWhatsNew);
    setVisible(false);
  }, [fetcher]);

  return (
    visible && (
      <div className={styles.whatsnew} data-tid="whatsnew">
        <Icon name="close" onClick={handleWhatsNewClose} theme={styles} themePrefix="close-" />
        <div>
          <div className={styles.title}>{intl('WhatsNew.NewAnnouncement')}</div>
          <div className={styles.content}>
            {intl('WhatsNew.IsReleased', {product: _.capitalize(product), version: versionString})}
          </div>
        </div>
        <div>
          <Link href={docUrl} theme={styles} target="_blank">
            {intl('WhatsNew.SeeWhatsNew')}
          </Link>
        </div>
      </div>
    )
  );
}
