/**
 * Copyright 2016 Illumio, Inc. All Rights Reserved.
 */
import cx from 'classnames';
import {TransitionMotion, type TransitionProps, type TransitionStyle} from 'react-motion';
import {Component, createElement} from 'react';
import type {
  ComponentPropsWithRef,
  KeyboardEvent,
  MouseEvent,
  ReactElement,
  AriaAttributes,
  MouseEventHandler,
} from 'react';
import {shallowEqual} from 'utils/general';
import {composeThemeFromProps, type ThemeProps} from '@css-modules-theme/react';
import MenuDropdown, {type DropdownTransitionStyle} from './MenuDropdown';
import {dropdownVertical as dropdownMotions} from './motions';
import {tidUtils, typesUtils} from 'utils';
import styles from './Menu.css';
import type {MenuItemsProps} from './MenuItems';

type FocusItem = 'first' | 'last';

type MenuPropsIn = {
  //
  /**
   * You should only supply MenuItems or Delimiter as children
   */
  children?: MenuItemsProps['children'];

  label?: typesUtils.ReactStrictNode;
  labelProps?: Record<string, unknown>;

  triggerOnHover?: boolean; // Open on trigger button hover
  triggerOnHoverOpenDebounce?: number; // Delay between hover and opening
  triggerOnHoverCloseTimeout?: number; // Delay between menu unhover and closing
  focusItemOnOpen?: FocusItem;

  iconBefore?: ReactElement;
  icon?: ReactElement;
  tid?: string;

  disabled?: boolean;

  insensitive?: boolean; // Makes trigger not interactable (not clickable, not tabbable)

  // autoFocus?: boolean; // Focus on trigger on first render
  // Add to trigger's classes when menu is open. Used when menu is on button to apply 'active' state on trigger when menu is opened
  openedTriggerTheme?: string;

  // Will be called before opening, can return Promise which will be waited
  onOpen?: () => void;
  onClose?: () => void;

  // On click callback - currently used to remove focus (blur) from the div wrapper in Menu from parent Button
  onClick?: MouseEventHandler<HTMLDivElement>;
} & Pick<AriaAttributes, 'aria-disabled'> &
  typeof Menu.defaultProps &
  ThemeProps;

export type MenuProps = typesUtils.ComponentExternalPropsWithoutRef<typeof Menu>;

type MenuState = {
  open: boolean;
  focusItemOnOpen?: FocusItem;
} & ReturnType<typeof Menu.getDerivedStateFromProps>;

const defaultTid = 'comp-menu';
const {close: closePosition, open: openPosition} = dropdownMotions.positions;

// don't use _.pick because _.pick doesn't work with identity-obj-proxy used in testing mock for css modules
const themable = {
  // Styles for menu itself
  menu: styles.menu,
  trigger: styles.trigger,
  openedTrigger: styles.openedTrigger,
  triggerLabel: styles.triggerLabel,
  // Styles for dropdown
  dropdown: styles.dropdown,
  subDropdown: styles.subDropdown,
  dropdownActive: styles.dropdownActive,
  dropdownWithArrow: styles.dropdownWithArrow,
  // Styles for items list
  itemsContainer: styles.itemsContainer,
  itemsExtender: styles.itemsExtender,
  itemsList: styles.itemsList,
  itemsListActive: styles.itemsListActive,
};

/**
 * Menu with mouse/key/tab navigation and optional open/close on hover
 */
export default class Menu extends Component<MenuPropsIn, MenuState> {
  static defaultProps = {
    insensitive: false,
    triggerOnHover: false,
    triggerOnHoverOpenDebounce: 0,
    triggerOnHoverCloseTimeout: 500,
  };

  subDropdownConfig: TransitionStyle[];

  trigger: null | HTMLDivElement = null;
  focusTriggerOnClose?: boolean;

  dropdown: null | MenuDropdown = null;

  opening?: boolean;
  entering?: boolean;
  leaving?: boolean;

  mouseEnterTimeout?: number;
  mouseLeaveTimeout?: number | null | ReturnType<typeof setTimeout> = null;

  constructor(props: MenuPropsIn) {
    super(props);

    this.state = {open: false, focusItemOnOpen: props.focusItemOnOpen, theme: composeThemeFromProps(themable, props)};
    this.subDropdownConfig = [];

    this.saveRef = this.saveRef.bind(this);
    this.saveDropdownRef = this.saveDropdownRef.bind(this);
    this.renderSubDropdown = this.renderSubDropdown.bind(this);

    this.subEnter = this.subEnter.bind(this);
    this.subLeave = this.subLeave.bind(this);

    this.open = this.open.bind(this);
    this.close = this.close.bind(this);

    if (props.triggerOnHover) {
      this.handleMouseEnter = this.handleMouseEnter.bind(this);
      this.handleMouseLeave = this.handleMouseLeave.bind(this);
      this.handleMouseMove = this.handleMouseMove.bind(this);
    }

    this.handleKeyUp = this.handleKeyUp.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleMouseDown = this.handleMouseDown.bind(this);
  }

  static getDerivedStateFromProps(nextProps: Readonly<MenuPropsIn>) {
    return {theme: composeThemeFromProps(themable, nextProps)};
  }

  shouldComponentUpdate(nextProps: Readonly<MenuPropsIn>, nextState: MenuState) {
    return this.state.open !== nextState.open || !shallowEqual(nextProps, this.props);
  }

  componentDidUpdate(_: MenuPropsIn, prevState: MenuState) {
    if (prevState.open && !this.state.open) {
      if (this.props.onClose) {
        this.props.onClose();
      }

      if (this.focusTriggerOnClose) {
        this.trigger?.focus();
        this.focusTriggerOnClose = false;
      }
    }
  }

  componentWillUnmount() {
    clearTimeout(Number(this.mouseLeaveTimeout));
  }

  private saveRef(trigger: HTMLDivElement) {
    this.trigger = trigger;
  }

  private saveDropdownRef(dropdown: MenuDropdown) {
    this.dropdown = dropdown;
  }

  private handleKeyDown(evt: KeyboardEvent) {
    switch (evt.key) {
      case 'Escape':
        evt.stopPropagation();

        if (this.state.open) {
          this.close(true);
        }

        break;
      case ' ':
        evt.preventDefault();
        evt.stopPropagation();
        break;
      case 'Enter':
        evt.preventDefault();
        this.toggle();
        break;
      case 'ArrowUp':
      case 'ArrowLeft':
        evt.preventDefault();

        if (!this.state.open) {
          this.open('last');
        } else if (this.dropdown) {
          this.dropdown.scopeFocus('last');
        }

        break;
      case 'ArrowDown':
      case 'ArrowRight':
        evt.preventDefault();

        if (!this.state.open) {
          this.open('first');
        } else if (this.dropdown) {
          this.dropdown.scopeFocus('first');
        }

        break;
      // no default
    }
  }

  private handleKeyUp(evt: KeyboardEvent) {
    if (evt.key === ' ') {
      this.toggle();
    }
  }

  private handleMouseEnter(evt: MouseEvent) {
    this.clearMouseTimeouts();

    if (!this.state.open) {
      if (evt.target === this.trigger) {
        this.trigger.addEventListener('mousemove', this.handleMouseMove);
      } else {
        this.handleMouseMove();
      }
    }
  }

  private handleMouseMove() {
    // Open on hover when mousemove stops, like debounce
    this.clearMouseTimeouts();

    if (this.props.triggerOnHoverOpenDebounce) {
      this.mouseEnterTimeout = setTimeout(this.open, this.props.triggerOnHoverOpenDebounce, false, true);
    } else {
      this.open();
    }
  }

  private handleMouseLeave() {
    this.clearMouseTimeouts();

    let closeTimeout = this.props.triggerOnHoverCloseTimeout;

    if (this.entering) {
      closeTimeout = 20;
    }

    if (closeTimeout) {
      this.mouseLeaveTimeout = setTimeout(this.close, closeTimeout);
    } else {
      this.close();
    }
  }

  private handleMouseDown(evt: MouseEvent) {
    // Prevent putting focus on trigger button if it's not focuesd yet,
    // to save/restore focus on currently focused element on dropdown closing
    evt.preventDefault();

    if (!this.state.open) {
      // If user wants to open menu, focus trigger to remove focus from other element and blur it to remove glow
      this.trigger!.focus();
      this.trigger!.blur();
    } else if (evt.target === this.trigger! || this.trigger!.contains(evt.target as Node)) {
      // If user clicks on trigger button while menu is opened,
      // it should be closed from this Menu component side (by click event),
      // thus we need prevent mouseDown handling on document in MenuDropdown component
      evt.nativeEvent.stopImmediatePropagation();
    }

    this.toggle();
  }

  private clearMouseTimeouts() {
    clearTimeout(this.mouseEnterTimeout);
    clearTimeout(Number(this.mouseLeaveTimeout));
    this.mouseEnterTimeout = this.mouseLeaveTimeout = undefined;
  }

  async open(focusItemOnOpen?: FocusItem /*, byTriggerHover*/): Promise<void> {
    if (this.state.open || this.opening || this.entering) {
      return;
    }

    this.opening = true;

    if (this.props.onOpen) {
      await this.props.onOpen();
    }

    this.trigger?.removeEventListener('mousemove', this.handleMouseMove);

    // Animate opening
    this.subDropdownConfig = [
      {
        key: 'dropdown',
        data: this.props.children,
        style: dropdownMotions.open,
      },
    ];

    this.setState(state => ({open: true, focusItemOnOpen: focusItemOnOpen || state.focusItemOnOpen}));

    this.opening = false;
  }

  close(focusTriggerOnClose?: boolean): void {
    if (this.opening || this.entering || this.leaving) {
      return;
    }

    // Animate closing
    this.subDropdownConfig = [];

    // Trigger button should be focused
    // if user opened menu without having focus on any other element and is closing menu by pressing Esc
    this.focusTriggerOnClose = focusTriggerOnClose;

    // Reset focused item to props value
    this.setState({open: false, focusItemOnOpen: this.props.focusItemOnOpen});
  }

  toggle(): void {
    this.clearMouseTimeouts();

    if (this.state.open) {
      this.close();
    } else {
      this.open();
    }
  }

  private subEnter() {
    this.entering = true;
    this.leaving = false;

    return dropdownMotions.enter;
  }

  private subLeave() {
    this.entering = false;
    this.leaving = true;

    return dropdownMotions.leave;
  }

  private renderSubDropdown(interpolatedStyles: Parameters<Exclude<TransitionProps['children'], undefined>>[0]) {
    return (
      <>
        {interpolatedStyles.map(config => {
          let lastRender = false;

          if (this.entering && config.style.y === openPosition.y && config.style.opacity === openPosition.opacity) {
            this.entering = false;
          } else if (
            this.leaving &&
            config.style.y === closePosition.y &&
            config.style.opacity === closePosition.opacity
          ) {
            this.leaving = false;
            lastRender = true;
          }

          return (
            <MenuDropdown
              key={config.key}
              active={this.state.open}
              ref={this.saveDropdownRef}
              animating={this.entering || this.leaving}
              lastRender={lastRender}
              style={config.style as DropdownTransitionStyle}
              theme={this.state.theme}
              focusItemOnOpen={this.state.focusItemOnOpen}
              // eslint-disable-next-line react/jsx-handler-names
              onClose={this.close}
            >
              {this.props.children}
            </MenuDropdown>
          );
        })}
      </>
    );
  }

  render() {
    const {
      props,
      props: {disabled, insensitive, label, onClick},
      state: {theme},
    } = this;
    const tids = [props.tid];

    if (disabled) {
      tids.push('disabled');
    }

    const containerProps: ComponentPropsWithRef<'nav'> = {
      'className': theme.menu,
      'data-tid': tidUtils.getTid(defaultTid, tids),
      'aria-disabled': disabled ?? props['aria-disabled'],
    };
    const triggerProps: ComponentPropsWithRef<'div'> = {
      'ref': this.saveRef,
      'className': this.state.open ? cx(theme.trigger, theme.openedTrigger, props.openedTriggerTheme) : theme.trigger,
      'tabIndex': insensitive ? -1 : 0,
      'role': 'button',
      // Show data-tid as 'triggered' when menu is open for QA
      'data-tid': `${defaultTid}-trigger${this.state.open ? 'ed' : ''}`,
      'aria-disabled': disabled ?? props['aria-disabled'],
      'aria-expanded': this.state.open ? 'true' : 'false',
    };

    if (onClick) {
      triggerProps.onClick = onClick;
    }

    if (!insensitive) {
      // triggerProps.autoFocus = props.autoFocus;
      triggerProps.onKeyUp = this.handleKeyUp;
      triggerProps.onKeyDown = this.handleKeyDown;
      triggerProps.onMouseDown = this.handleMouseDown;

      if (props.triggerOnHover) {
        containerProps.onMouseEnter = this.handleMouseEnter;
        containerProps.onMouseLeave = this.handleMouseLeave;
      }
    }

    return (
      <nav {...containerProps}>
        <div {...triggerProps}>
          {props.iconBefore}
          {label
            ? createElement(
                'span',
                {
                  ...props.labelProps,
                  'className': theme.triggerLabel,
                  'data-tid': 'comp-menu-title',
                },
                ...(Array.isArray(label) ? label : [label]),
              )
            : null}
          {props.icon}
        </div>

        <TransitionMotion willEnter={this.subEnter} willLeave={this.subLeave} styles={this.subDropdownConfig}>
          {this.renderSubDropdown}
        </TransitionMotion>
      </nav>
    );
  }
}
