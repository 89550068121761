/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import {Component} from 'react';
import {getPairingProfileDetail} from './PairingProfileViewState';
import {connect} from 'react-redux';
import {AppContext} from 'containers/App/AppUtils';
import {updatePairingProfile, deletePairingProfile, fetchPairingProfileItem} from '../PairingProfileItemSaga';
import {
  AttributeList,
  Pill,
  Notifications,
  Button,
  ToolBar,
  ToolGroup,
  TypedMessages,
  Modal,
  ModalMachineAuto,
} from 'components';
import {UserName, HeaderProps} from 'containers';
import intl from 'intl';
import {formatVenVersion} from './PairingProfileViewUtils';
import {getLabelTypeName} from 'components/Pill/Label/LabelUtils';
import {
  getUsesPerKeyText,
  getKeyLifespanText,
  getLockWorkloadStateText,
  getLockedLabelsText,
  getLabelScopePairing,
} from 'containers/PairingProfile/PairingProfileUtils';
import {
  enforcementModeView,
  enforcementModeViewEdge,
  visibilityLevelView,
  visibilityLevelViewEdge,
} from 'containers/EnforcementBoundaries/EnforcementBoundariesUtils';
import styles from './PairingProfileView.css';
import {reactUtils} from 'utils';
import _ from 'lodash';

const getEmptyState = () => ({
  pairing: null,
  remove: false,
});

@connect(getPairingProfileDetail)
export default class PairingProfileView extends Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);

    this.handlePairing = this.handlePairing.bind(this);
    this.handleModalDialog = this.handleModalDialog.bind(this);

    this.state = getEmptyState();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    // enabled: [true] enable pairing, [false] : disable pairing
    const state = {
      enabled: nextProps.pairingProfile.enabled,
      caps: nextProps.pairingProfile.caps,
    };

    if (nextProps.pairingProfile.enabled !== prevState.enabled) {
      // Reset back to null during successful Stop/Start pairing
      state.pairing = null;

      return state;
    }

    return null;
  }

  // Get the General Attributes
  getGeneralAttributes() {
    const {pairingProfile, createdBy, updatedBy, edgeEnabled, generatedBy} = this.props;
    const classNameContent = styles.content;
    const enforcementModeViewText = enforcementModeView();
    const visibilityLevelViewText = visibilityLevelView();

    const generalAttributes = [
      {
        divider: true,
      },
      {
        title: intl('Common.General'),
      },
      {
        tid: 'name',
        key: intl('Common.Name'),
        value: pairingProfile.name,
      },
      !edgeEnabled && {
        tid: 'uri',
        key: intl('PairingProfiles.Detail.URI'),
        value: pairingProfile.href,
      },
      {
        tid: 'desc',
        key: intl('Common.Description'),
        value: pairingProfile.description,
      },
      {
        tid: 'created',
        key: intl('Common.Created'),
        value: UserName.dateAtTimeBy(pairingProfile.created_at, createdBy, 'full_name'),
      },
    ];

    if (pairingProfile.updated_at && pairingProfile.updated_at !== pairingProfile.created_at) {
      generalAttributes.push({
        tid: 'last-modified',
        key: intl('Common.LastModified'),
        value: UserName.dateAtTimeBy(pairingProfile.updated_at, updatedBy, 'full_name'),
      });
    }

    if (pairingProfile.last_pairing_key_generated_at) {
      generalAttributes.push({
        tid: 'last-key-generated',
        key: intl('Common.LastPairingKeyGenerated'),
        value: UserName.dateAtTimeBy(pairingProfile.last_pairing_key_generated_at, generatedBy, 'full_name'),
      });
    }

    if (pairingProfile.last_pairing_at) {
      generalAttributes.push({
        tid: 'last-used',
        key: intl('Common.LastVENPaired'),
        value: UserName.dateAtTimeBy(pairingProfile.last_pairing_at),
      });
    }

    // Enforcement Mode
    generalAttributes.push({
      tid: 'enforcement-mode',
      key: intl('Common.Enforcement'),
      value: (
        <>
          {
            enforcementModeViewText[
              edgeEnabled && pairingProfile.enforcement_mode === 'full' ? 'enforced' : pairingProfile.enforcement_mode
            ]?.name
          }
          <div className={classNameContent}>
            {(edgeEnabled ? enforcementModeViewEdge : enforcementModeViewText)[pairingProfile.enforcement_mode]?.desc}
          </div>
        </>
      ),
    });

    // Show Visibility View
    let visibilityValue;

    if (pairingProfile.enforcement_mode === 'idle') {
      visibilityValue = <div>{visibilityLevelViewText[pairingProfile.enforcement_mode].name}</div>;
    } else {
      visibilityValue = (
        <>
          {visibilityLevelViewText[pairingProfile.visibility_level]?.name}
          <div className={classNameContent}>
            {(edgeEnabled ? visibilityLevelViewEdge : visibilityLevelViewText)[pairingProfile.visibility_level]?.desc}
          </div>
        </>
      );
    }

    generalAttributes.push({
      tid: 'visibility-level',
      key: intl('Common.Visibility'),
      value: visibilityValue,
    });

    if (pairingProfile.agent_software_release) {
      generalAttributes.push({
        tid: 'ven-version',
        key: intl('PairingProfiles.InitialVenVersion'),
        value: (
          <>
            {formatVenVersion(pairingProfile.agent_software_release)}
            <div className={classNameContent}>{intl('PairingProfiles.Install')}</div>
          </>
        ),
      });
    }

    return generalAttributes;
  }

  // Get the Label Assignment
  getLabelAssignment() {
    const {pairingProfile, edgeEnabled} = this.props;
    const profileLabels = getLabelScopePairing(pairingProfile);

    return [
      {
        divider: true,
      },
      {
        title: edgeEnabled ? intl('Edge.InstallScript.GroupAssignment') : intl('PairingProfiles.LabelAssignment'),
      },
      {
        tid: 'labelrole',
        key: getLabelTypeName('role'),
        value: profileLabels.role ? (
          edgeEnabled ? (
            <Pill.Group value={{name: profileLabels.role.value, href: pairingProfile.external_data_reference}} />
          ) : (
            <Pill.Label type="role" href={profileLabels.role.href}>
              {profileLabels.role.value}
            </Pill.Label>
          )
        ) : null,
      },
      !edgeEnabled && {
        tid: 'labelapp',
        key: getLabelTypeName('app'),
        value: profileLabels.app ? (
          <Pill.Label type="app" href={profileLabels.app.href}>
            {profileLabels.app.value}
          </Pill.Label>
        ) : null,
      },
      !edgeEnabled && {
        tid: 'labelenv',
        key: getLabelTypeName('env'),
        value: profileLabels.env ? (
          <Pill.Label type="env" href={profileLabels.env.href}>
            {profileLabels.env.value}
          </Pill.Label>
        ) : null,
      },
      !edgeEnabled && {
        tid: 'labelloc',
        key: getLabelTypeName('loc'),
        value: profileLabels.loc ? (
          <Pill.Label type="loc" href={profileLabels.loc.href}>
            {profileLabels.loc.value}
          </Pill.Label>
        ) : null,
      },
    ];
  }

  // Get Key Usage & LifeSpan
  getLifeSpanAttributes() {
    const {pairingProfile} = this.props;
    const usesContent = getUsesPerKeyText(String(pairingProfile.allowed_uses_per_key));
    const lifespanContent = getKeyLifespanText(pairingProfile.key_lifespan);
    const classNameContent = styles.content;

    return [
      {
        divider: true,
      },
      {
        title: intl('PairingProfiles.KeyUsage'),
      },
      {
        tid: 'numberuses',
        key: intl('PairingProfiles.Mixin.UsesPerKey'),
        value: (
          <>
            {usesContent.title}
            <div className={classNameContent}>{usesContent.content}</div>
          </>
        ),
      },
      {
        tid: 'lifespan',
        key: intl('PairingProfiles.KeyMaxAge'),
        value: (
          <>
            {lifespanContent.title}
            <div className={classNameContent}>{lifespanContent.content}</div>
          </>
        ),
      },
    ];
  }

  // Get Command Line Overrides
  getLineInterfaceAttributes() {
    const {pairingProfile, edgeEnabled} = this.props;
    const lockedLabels = [];
    const classNameContent = styles.content;

    if (pairingProfile.role_label_lock) {
      lockedLabels.push(getLabelTypeName('role'));
    }

    if (pairingProfile.app_label_lock) {
      lockedLabels.push(getLabelTypeName('app'));
    }

    if (pairingProfile.env_label_lock) {
      lockedLabels.push(getLabelTypeName('env'));
    }

    if (pairingProfile.loc_label_lock) {
      lockedLabels.push(getLabelTypeName('loc'));
    }

    const stateLockContent = getLockWorkloadStateText(pairingProfile.mode_lock);
    const labelsLockContent = getLockedLabelsText(lockedLabels.length, lockedLabels);

    return [
      {
        divider: true,
      },
      {
        title: intl('PairingProfiles.CommandLineOverrides'),
      },
      {
        tid: 'statelock',
        key: intl('Common.Enforcement'),
        value: (
          <>
            {stateLockContent.title}
            <div className={classNameContent}>{stateLockContent.content}</div>
          </>
        ),
      },
      !edgeEnabled && {
        tid: 'labellock',
        key: intl('PairingProfiles.LabelAssignment'),
        value: (
          <>
            {labelsLockContent.title}
            <div className={classNameContent}>{labelsLockContent.content}</div>
          </>
        ),
      },
    ];
  }

  // Get Notifications
  getNotifications() {
    const {pairingProfile, edgeEnabled} = this.props;
    let notification = null;

    if (pairingProfile.enabled) {
      notification = (
        <Notifications sidebar>
          {[
            {
              type: 'success',
              title: `${intl('Common.Running')}:`,
              message: edgeEnabled
                ? intl('Edge.InstallScript.AllowRequests')
                : intl('PairingProfiles.Detail.AllowRequests'),
            },
          ]}
        </Notifications>
      );
    } else {
      notification = (
        <Notifications sidebar>
          {[
            {
              type: 'error',
              title: `${intl('Common.Stopped')}:`,
              message: edgeEnabled
                ? intl('Edge.InstallScript.DenyRequests')
                : intl('PairingProfiles.Detail.DenyRequests'),
            },
          ]}
        </Notifications>
      );
    }

    return notification;
  }

  // Get Remove Confirmation
  getRemoveConfirmation() {
    const {
      props: {edgeEnabled},
      context: {navigate},
    } = this;

    return (
      <ModalMachineAuto
        onClose={this.handleModalDialog}
        saga={deletePairingProfile}
        onDone={_.partial(navigate, {to: 'pairingProfiles.list'})}
      >
        {{
          title: edgeEnabled ? intl('Edge.InstallScript.Remove') : intl('PairingProfiles.Delete', {count: 1}),
          confirmMessage: edgeEnabled
            ? intl('Edge.InstallScript.RemoveConfirm')
            : intl('PairingProfiles.DeleteConfirm', {count: 1}),
          error: {
            title: edgeEnabled ? intl('Edge.InstallScript.Remove') : intl('PairingProfiles.Delete', {count: 1}),
          },
        }}
      </ModalMachineAuto>
    );
  }

  // Get the Stop/Start Button
  getRenderPairing() {
    const {userReadOnly, pairingProfile} = this.props;
    const {pairing} = this.state;

    // Pairing Start/Stop
    const elementProps = {
      onClick: this.handlePairing,
      progressCompleteWithCheckmark: true,
      color: 'standard',
      disabled: userReadOnly,
    };

    if (pairingProfile.enabled) {
      elementProps.text = intl('PairingProfiles.Stop');
      elementProps.tid = 'pause';
      elementProps.icon = 'stop';
    } else {
      elementProps.text = intl('PairingProfiles.Start');
      elementProps.tid = 'resume';
      elementProps.icon = 'play';
    }

    if (pairing && pairing.error) {
      elementProps.progressError = true;
    }

    if (pairing) {
      elementProps.progress = pairing.running;
    }

    return !userReadOnly ? <Button {...elementProps} /> : null;
  }

  // Get Remove Button
  getRenderRemove() {
    const {userReadOnly, edgeEnabled} = this.props;

    if (edgeEnabled) {
      return;
    }

    const elementProps = {
      text: intl('Common.Remove'),
      icon: 'remove',
      tid: 'remove',
      onClick: this.handleModalDialog,
      disabled: userReadOnly,
      color: 'standard',
      progressCompleteWithCheckmark: true,
    };

    return <Button {...elementProps} />;
  }

  // Handle Modal Dialog to display or not display
  handleModalDialog() {
    this.setState(({remove}) => ({remove: !remove}));
  }

  // Handle to stop and start pairing
  async handlePairing() {
    const {
      currentRouteParams: {id},
    } = this.props;
    const {fetcher} = this.context;

    // Set state to show the Button progress by using async to have state and render phase get first before calling spawn
    await reactUtils.setStateAsync(({pairing}) => ({pairing: {...pairing, running: true, runningError: false}}), this);

    try {
      await fetcher.spawn(updatePairingProfile, id, {enabled: !this.props.pairingProfile.enabled});
      await fetcher.fork(fetchPairingProfileItem, {params: {id}}, true);

      this.handleCloseModal();

      return;
    } catch (error) {
      this.setState({pairing: {error, running: false, runningError: true}});
    }
  }

  // Handle Close Modal
  handleCloseModal() {
    this.setState(getEmptyState());
  }

  // Render the pairing error alert modal
  renderPairingAlert() {
    const {pairingProfile} = this.props;
    const {
      pairing: {error},
    } = this.state;
    const token = error.data?.[0]?.token;

    return (
      <Modal.Alert
        medium
        onClose={this.handleCloseModal}
        title={intl(pairingProfile.enabled ? 'PairingProfiles.Errors.Stop' : 'PairingProfiles.Errors.Start')}
      >
        <TypedMessages>
          {[
            {
              icon: 'error',
              content: (token && intl(`ErrorsAPI.err:${token}`)) || error.data?.[0]?.message || error.message,
            },
          ]}
        </TypedMessages>
      </Modal.Alert>
    );
  }

  render() {
    const {
      pairingProfile,
      userReadOnly,
      isGenerateKeyEnabled,
      edgeEnabled,
      currentRouteParams: {id},
    } = this.props;
    const {pairing, remove} = this.state;
    const notifications = this.getNotifications();

    const pairingButton = this.getRenderPairing();
    const removeButton = this.getRenderRemove();

    return (
      <>
        <HeaderProps
          title={edgeEnabled ? intl('Edge.InstallScript.Name') : intl('PairingProfiles.Profiles')}
          subtitle={pairingProfile.name}
          up="pairingProfiles"
        />
        {notifications}
        <ToolBar>
          <ToolGroup>
            <Button.Link
              text={intl('Common.Edit')}
              icon="edit"
              autoFocus
              tid="edit"
              link={{to: 'pairingProfiles.item.edit', params: {id}}}
              disabled={userReadOnly}
            />
            <Button.Link
              text={intl('Common.GenerateKey')}
              icon="key"
              tid="generatekey"
              link={{to: 'pairingProfiles.pair', params: {id}}}
              disabled={!isGenerateKeyEnabled || !pairingProfile.enabled}
              color="standard"
            />
            {pairingButton}
            {removeButton}
          </ToolGroup>
        </ToolBar>
        <AttributeList>
          {[
            ...this.getGeneralAttributes(),
            ...this.getLabelAssignment(),
            ...this.getLifeSpanAttributes(),
            ...this.getLineInterfaceAttributes(),
          ]}
        </AttributeList>
        {remove && this.getRemoveConfirmation()}
        {pairing && pairing.error && this.renderPairingAlert()}
      </>
    );
  }
}
