/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from 'intl';
import cx from 'classnames';
import * as JsDiff from 'diff';
import {createElement, useCallback, useMemo, useState, useRef} from 'react';
import {composeThemeFromProps, type ThemeProps} from '@css-modules-theme/react';
import {Icon, Tooltip, RadioGroup, Radio} from 'components';
import {getTid} from 'utils/tid';
import {useDeepCompareMemo} from 'utils/react';
import {sanitizeDiffValue} from './DiffUtils';
import styleUtils from 'utils.css';
import styles from './Diff.css';
import * as Presets from './Presets';
import type {ZipArrayUnion} from 'utils/types';
import type {TooltipPropsWithoutPlacement} from 'components/Tooltip/Tooltip';

const lineBreakRegex = /\r\n|\r|\n/;

const defaultTid = 'comp-diff';
const defaultTooltipProps = {
  delay: [200, 200] as TooltipPropsWithoutPlacement['delay'],
  topStart: true,
  interactive: true,
  maxWidth: 250,
};

export type AllDiffFunc =
  | 'diffChars'
  | 'diffWords'
  | 'diffWordsWithSpace'
  | 'diffArrays'
  | 'diffLines'
  | 'diffTrimmedLines'
  | 'diffSentences'
  | 'diffCss';

type DiffFunc<T extends string | string[]> = T extends string ? Exclude<AllDiffFunc, 'diffArrays'> : AllDiffFunc;

export type DiffPropsBase<T extends string | string[]> = {
  tid?: string;

  /** Sanitize value/oldValue to remove tags */
  sanitizeInput?: boolean;

  /**
   * Value/oldValue type can be JSX or string,
   * JSDiff library API is called to get diff between value and oldValue when the type is string for e.g. description, name etc.
   * When value/oldValue is JSX for e.g. service with link then component returns value/oldValue formatted in added/removed styles.
   */
  value?: T;
  oldValue?: T;
  noDiff?: boolean;
  noTooltip?: boolean;

  /** type, displayType and diffFunc props determine how diff is rendered. Default: `'sidebyside'` */
  type?: 'unified' | 'sidebyside';

  /** Default: `'block'` */
  displayType?: 'block' | 'line';

  /** Method that is called on 'diff' module */
  diffFunc?: DiffFunc<T>;

  /** Separate method can be specified for the sidebyside type */
  diffFuncSide?: DiffFunc<T>;

  /** Separate method can be specified for the unified type */
  diffFuncUnified?: DiffFunc<T>;

  /**
   *  If value/oldValue are arrays, but diffFunc[Side/Unified] is not diffArrays,
   * then we need to join array into string before calling JsDiff.
   * This prop will be passed to .join() method, '\n' by default.
   */
  arrayJoiner?: string;

  /** Diff options object */
  diffOptions?: {
    // FIXME: remove this line if eslint(lines-around-comment is fixed on TS interface)
    /** ignore casing difference */
    ignoreCase?: boolean;

    /** ignore leading and trailing whitespace */
    ignoreWhitespace?: boolean;

    /** treat newline characters as separate tokens in DiffLines */
    newlineIsToken?: boolean;
  };

  // Unfortunately, mutually exclusive props can't have default value. In this case, `topStart` is the default
  // placement of props (see line 23), so users can't no longer pass any other placement props because they are
  // mutually exclusive unless we have dedicated logic to override the default prop.
  /** Tooltip props, these props are merged with default tooltip props */
  tooltipProps?: TooltipPropsWithoutPlacement;
} & ThemeProps;

export type DiffProps = DiffPropsBase<string> | DiffPropsBase<string[]>;

export default function Diff(props: DiffProps): JSX.Element {
  const {tid, sanitizeInput, displayType = 'block', noTooltip = false, tooltipProps, arrayJoiner = '\n'} = props;
  const theme = composeThemeFromProps(styles, props);
  const valueIsArray = Array.isArray(props.oldValue) || Array.isArray(props.value);

  // Call useDeepCompareMemo to return the same instance of values as in the previous render to avoid calling useMemo following after that
  let value = useDeepCompareMemo(props.value ?? (valueIsArray ? [] : ''));
  let oldValue = useDeepCompareMemo(props.oldValue ?? (valueIsArray ? [] : ''));

  if (__DEV__ && valueIsArray && (!Array.isArray(value) || !Array.isArray(oldValue))) {
    console.warn(
      `Diff component needs 'value' and 'oldValue' props to be of the same type, now value is ${value}, oldValue is ${oldValue}`,
    );
  }

  // Remove tags from values if they have changed since the last render
  value = useMemo(() => (sanitizeInput ? sanitizeDiffValue(value) : value), [value, sanitizeInput]);
  oldValue = useMemo(() => (sanitizeInput ? sanitizeDiffValue(oldValue) : oldValue), [oldValue, sanitizeInput]);

  const diffOptions = useDeepCompareMemo(props.diffOptions);
  const newlineIsToken = diffOptions?.newlineIsToken;
  const previousPropsType = useRef(props.type);
  let [selectedType, setSelectedType] = useState(null);

  // Every time type is changed by a parent, and user manually switched the type before that,
  // reset to the parent one
  if (previousPropsType.current !== props.type) {
    previousPropsType.current = props.type;

    if (selectedType) {
      selectedType = null;
      setSelectedType(selectedType);
    }
  }

  // Final type is taken from either user selected type, or prop, or the default one
  const type = selectedType ?? props.type ?? 'sidebyside';
  // Final diffFunc depends on multiple props
  const diffFunc =
    (type === 'sidebyside' && props.diffFuncSide) ||
    (type === 'unified' && props.diffFuncUnified) ||
    props.diffFunc ||
    (valueIsArray && 'diffArrays') ||
    'diffChars';

  // If the final diffFunc is not diffArrays, join arrays into strings to compare them as text
  if (valueIsArray && diffFunc !== 'diffArrays') {
    value = (value as string[]).join(arrayJoiner);
    oldValue = (oldValue as string[]).join(arrayJoiner);
  }

  const handleChangeDiffType = useCallback(evt => {
    // Set sidebyside/unified type on tooltip radio button toggle
    setSelectedType(evt.target.value);
  }, []);

  // If props.noDiff is not defined, compare values manually, but only if they have changed since the last render
  const noDiff = useMemo(() => props.noDiff || _.isEqual(value, oldValue), [props.noDiff, value, oldValue]);

  // Compute diff based on values and diffFunc
  const diff = useMemo(() => {
    if (noDiff) {
      return;
    }

    // Combination of diffLines without the newlineIsToken acts exactly the same as diffArrays,
    // so there is no need in separate logic for that
    if (!valueIsArray && diffFunc === 'diffLines' && !newlineIsToken) {
      return JsDiff.diffArrays(
        (oldValue as string).split(lineBreakRegex),
        (value as string).split(lineBreakRegex),
        diffOptions,
      );
    }

    if (diffFunc === 'diffArrays') {
      return JsDiff[diffFunc](oldValue as string[], value as string[], diffOptions);
    }

    return JsDiff[diffFunc](oldValue as string, value as string, diffOptions);
  }, [noDiff, value, oldValue, diffFunc, diffOptions, newlineIsToken, valueIsArray]);

  interface Part {
    added?: boolean;
    removed?: boolean;
    value: string | string[];
  }

  type Rows = {changed?: boolean; removed?: boolean; added?: boolean; parts: Part[]}[];

  // Structure rows based on type, displayType and the diff
  const rows = useMemo(() => {
    if (noDiff || !diff) {
      return;
    }

    let highlightParts = !_.isEmpty(value) && !_.isEmpty(oldValue);

    if (type === 'unified') {
      return (diff as ZipArrayUnion<typeof diff>).reduce(
        (rows, part) => {
          let {added = false, removed = false, value} = part;
          const changed = added || removed;

          if (!highlightParts) {
            added = false;
            removed = false;
          }

          if (displayType === 'block') {
            // If we display all changes as one block, simply add the value to the existing only one row
            rows.lastItem.parts.push({
              added,
              removed,
              value: Array.isArray(value) ? `${value.join(arrayJoiner)}${arrayJoiner}` : value,
            });
          } else if (Array.isArray(value)) {
            // If we display as lines, and we compare arrays, then each line in part becomes a separate row
            for (const val of value) {
              rows.push({changed, parts: [{added, removed, value: val}]});
            }
          } else {
            if (!rows.lastItem) {
              rows.push({changed, parts: []});
            }

            if (lineBreakRegex.test(value)) {
              // Part may contain multiple lines,
              // split it into an array and check if the first element is a fragment of the previous line
              const lines = value.split(lineBreakRegex);
              const firstLine = lines.shift();

              if (firstLine) {
                // Push the first line to the existing row
                rows.lastItem.parts.push({value: firstLine, added, removed});

                if (changed) {
                  rows.lastItem.changed = changed;
                }
              }

              // Add the rest of the lines to the new row
              // If the last line is empty, that means part ends with the linebreak, creating a new row waiting for the next part to fill it
              lines.forEach((line, idx, arr) => {
                if (idx === arr.lastIndex && !line) {
                  rows.push({parts: []});
                } else {
                  rows.push({changed, parts: [{added, removed, value: line}]});
                }
              });
            } else {
              rows.lastItem.parts.push({added, removed, value});

              if (changed) {
                rows.lastItem.changed = changed;
              }
            }
          }

          return rows;
        },
        displayType === 'block' ? [{changed: true, parts: []}] : ([] as Rows),
      );
    }

    if (displayType === 'block') {
      // Side-by-Side block
      return (diff as ZipArrayUnion<typeof diff>)
        .reduce(
          (rows, part) => {
            const [addedRow, removedRow] = rows;
            const added = highlightParts && part.added;
            const removed = highlightParts && part.removed;
            const value = Array.isArray(part.value) ? `${part.value.join(arrayJoiner)}${arrayJoiner}` : part.value;

            if (!part.added) {
              // Either removed/unchanged part, unchanged part is added to both removed and added row
              removedRow.parts.push({added, removed, value});
            }

            if (!part.removed) {
              // Either added/unchanged part, unchanged part is added to both removed and added row
              addedRow.parts.push({added, removed, value});
            }

            return rows;
          },
          [
            {added: true, parts: []},
            {removed: true, parts: []},
          ] as Rows,
        )
        .filter(row => row.parts.length > 0);
    }

    if (displayType === 'line') {
      // Side-by-Side lines
      // Buffer arrays for added/removed/unchanged rows, since we need to flush added/removed only when we reach the unchanged row
      const addedRows: Rows = [];
      const removedRows: Rows = [];
      const unchangedRows: Rows = [];

      // Don't highlight changes in case we compare by lines
      if (highlightParts) {
        highlightParts = diffFunc !== 'diffLines' && diffFunc !== 'diffArrays';
      }

      const rows = (diff as ZipArrayUnion<typeof diff>).reduce((rows, {added = false, removed = false, value}) => {
        const targetRows = added ? addedRows : removed ? removedRows : unchangedRows;
        let lines;

        if (Array.isArray(value)) {
          lines = value;
        } else {
          // If part is changed (either added or removed), and buffer of unchanged is filled,
          // we need to copy parts from the unchanged row into added and removed rows and keep filling them instead of the unchanged one
          if ((added || removed) && unchangedRows.length) {
            addedRows.push({added: true, parts: [...unchangedRows[0].parts]});
            removedRows.push({removed: true, parts: [...unchangedRows[0].parts]});
            unchangedRows.splice(0);
          }

          // The part may contain multiple lines,
          // split it into an array and check if the first element is a fragment of the previous line
          lines = value.split(lineBreakRegex);

          const firstLine = lines.shift();

          if (firstLine) {
            if (targetRows === unchangedRows && (addedRows.length || removedRows.length)) {
              // Put unchanged part into added/removed rows if we are filling them, until we reach linebreak
              addedRows.lastItem.parts.push({value: firstLine});
              removedRows.lastItem.parts.push({value: firstLine});
            } else {
              const newPart = {
                value: firstLine,
                added: highlightParts && added,
                removed: highlightParts && removed,
              };

              if (!targetRows.length) {
                targetRows.push({added, removed, parts: [newPart]});
              } else {
                // Push the first line to the existing row
                targetRows.lastItem.parts.push(newPart);
              }
            }
          }
        }

        // Add the rest of the lines to the added/removed/unchanged rows
        lines.forEach((line, idx, arr) => {
          // If we already have unchanged row in the buffer, then flush current added/removed/unchanged buffer rows
          if (unchangedRows.length || (targetRows === unchangedRows && diffFunc === 'diffLines' && newlineIsToken)) {
            if (addedRows.length) {
              rows.push(...addedRows.splice(0));
            }

            if (removedRows.length) {
              rows.push(...removedRows.splice(0));
            }

            if (unchangedRows.length) {
              rows.push(...unchangedRows.splice(0));
            }
          }

          if (idx === arr.lastIndex && !line) {
            // If the last line is empty, that means part ends with linebreak
            if (!removed) {
              addedRows.push({added: true, parts: []});
            }

            if (!added) {
              removedRows.push({removed: true, parts: []});
            }
          } else {
            targetRows.push({
              added,
              removed,
              parts: [
                {
                  value: line,
                  added: highlightParts && added,
                  removed: highlightParts && removed,
                },
              ],
            });
          }
        });

        return rows;
      }, [] as Rows);

      // Flush buffered rows in case we've reached the end
      if (addedRows.length || removedRows.length || unchangedRows.length) {
        rows.push(...addedRows, ...removedRows, ...unchangedRows);
      }

      return rows;
    }
  }, [type, displayType, value, oldValue, arrayJoiner, diff, noDiff, diffFunc, newlineIsToken]);

  const containerProps = {
    'className': cx(theme.container, {[theme.containerUnified]: type === 'unified'}),
    'data-tid': getTid(`${defaultTid}-${type}`, tid),
  };

  let container: JSX.Element;

  if (noDiff || !rows) {
    const unchangedValueArr = valueIsArray && Array.isArray(value) ? value : [value];

    container = createElement(
      'div',
      containerProps,
      ...unchangedValueArr.map(row => createElement('div', {'data-tid': getTid(`${defaultTid}-unchanged`, tid)}, row)),
    );
  } else {
    container = createElement(
      'div',
      containerProps,
      ...rows.map(({added = false, removed = false, changed = false, parts}) => {
        if (!added && !removed && !changed) {
          return (
            <div className={theme.row}>
              <div />
              {createElement(
                'div',
                {'className': theme.value, 'data-tid': getTid(`${defaultTid}-unchanged`, tid)},
                ...parts.map(({value}) => value),
              )}
            </div>
          );
        }

        const type =
          (added && 'added') || (removed && 'removed') || ((changed && 'changed') as 'added' | 'removed' | 'changed');
        const icon =
          (added && 'add') || (removed && 'remove') || ((changed && 'online') as 'add' | 'remove' | 'online');
        const valueProps = {
          'className': theme.value,
          'data-tid': getTid(`${defaultTid}-${type}`, tid),
        };

        return (
          <div className={theme[type]}>
            <Icon name={icon} tid={getTid(defaultTid, tid)} theme={theme} themePrefix={`${icon}-`} />
            {createElement(
              'div',
              valueProps,
              ...parts.map(({added, removed, value}) => {
                if (added) {
                  return (
                    <span className={theme.addedHighlight} data-tid={getTid(`${defaultTid}-addedHighlight`, tid)}>
                      {value}
                    </span>
                  );
                }

                if (removed) {
                  return (
                    <span className={theme.removedHighlight} data-tid={getTid(`${defaultTid}-removedHighlight`, tid)}>
                      {value}
                    </span>
                  );
                }

                return value;
              }),
            )}
          </div>
        );
      }),
    );
  }

  if (!noTooltip && !noDiff && !_.isEmpty(value) && !_.isEmpty(oldValue)) {
    // Add tooltip to toggle diff type when both value and oldValue exist and tooltip is not explicitly disabled
    const tooltip = (
      <div className={`${styleUtils.paddingMediumBottom} ${styleUtils.gapLarge}`}>
        <span>{intl('Common.ChangesHighlighted')}</span>
        <RadioGroup className={styleUtils.gapMedium} name="type" value={type} onChange={handleChangeDiffType}>
          <Radio value="sidebyside" label={intl('Common.SideBySideDiff')} />
          <Radio value="unified" label={intl('Common.UnifiedDiff')} />
        </RadioGroup>
      </div>
    );

    return (
      <Tooltip content={tooltip} {...defaultTooltipProps} {...tooltipProps}>
        {() => container}
      </Tooltip>
    );
  }

  return container;
}

Diff.Text = Presets.Text;
Diff.List = Presets.List;
Diff.Option = Presets.Option;
Diff.Checkboxes = Presets.Checkboxes;
