/**
 * Copyright 2017 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from 'intl';
import {hrefUtils, reactUtils} from 'utils';
import {sortAndStringifyArray} from 'utils/general';
import {Pill, StatusIcon, Icon} from 'components';
import styles from './GridUtils.css';
import {edge} from 'api/apiUtils';

// Enable language sensitive string comparison. Using Intl.Collator compare method to ensure
// the strings are sorted according to the sort order of the specified locale.
const collator = new Intl.Collator(intl.locale, {
  usage: 'sort',
  sensitivity: 'base',
  numeric: true,
  ignorePunctuation: false,
});

export const formatDate = (value, format = 'L_HH_mm_ss') => (value ? intl.date(value, format) : intl('Common.Never'));

const getRowStatusType = row => {
  if (row.error) {
    return 'error';
  }

  if (row.warning) {
    return 'warning';
  }

  if (row.info) {
    return 'info';
  }
};

export const defaultColumns = {
  arrow: {
    headerManager: intl('Common.Arrow'),
    // Takes a directions mapping ({leftOrRight: string, upOrDown: string}) on the arrow column settings to configure the arrow direction.
    header: header => {
      const arrowDirection = header.breakpoint.data?.arrow ?? 'right';

      return <Icon theme={styles} themePrefix="arrow-" name={`arrow-${arrowDirection}`} />;
    },
    format: format => {
      const arrowDirection = format.breakpoint.data?.arrow ?? 'right';

      return <Icon theme={styles} themePrefix="arrow-" name={`arrow-${arrowDirection}`} />;
    },
    sortable: false,
  },
  rowStatus: {
    header: '',
    headerManager: intl('Common.Status'),
    format: ({row, column, error = row.error, warning = row.warning, info = row.info}) => {
      const statuses = {error, warning, info};
      const type = getRowStatusType(statuses);

      return type ? (
        <StatusIcon
          status={type}
          tooltip={statuses[type]}
          theme={styles}
          themePrefix="rowStatus-"
          data-tid={type}
          tooltipProps={{fast: true, ...column.statusIconProps}}
        />
      ) : (
        <>&ensp;</>
      );
    },
    sortable: true,
    sortFunction: ({a, b, sortFactor}) => {
      // Order of rows with the same status or with no status doesn't change
      if (getRowStatusType(a) === getRowStatusType(b)) {
        return 0;
      }

      // Errors go first
      if (a.error) {
        return -sortFactor;
      }

      if (b.error) {
        return sortFactor;
      }

      // Warnings go next
      if (a.warning) {
        return -sortFactor;
      }

      if (b.warning) {
        return sortFactor;
      }

      // Infos go last
      if (a.info) {
        return -sortFactor;
      }

      if (b.info) {
        return sortFactor;
      }

      return 0;
    },
  },
};

export const prepareColumns = columns =>
  Object.entries(columns).reduce((result, [id, column]) => {
    if (column.templates) {
      column.templates.forEach(subColumnId => {
        const subColumn = column[subColumnId] ?? {};
        const subColumnPath = `${id}.${subColumnId}`;
        const tid = `comp-grid-column-${id}-${subColumnId}`.toLowerCase();

        result.set(subColumnPath, {tid, ...subColumn, parentId: id, id: subColumnPath, hidden: subColumn.optional});
      });
    }

    const finalColumn = {
      ...defaultColumns[id],
      tid: `comp-grid-column-${id.toLowerCase()}`,
      ...column,
      id,
      ...(column.optional && column.id !== 'checkboxes' && {hidden: true}),
    };

    if (id === 'checkboxes') {
      // Checkbox cell obviously should be rerender on checkbox change
      finalColumn.reactsToSelection = true;
    }

    result.set(id, finalColumn);

    return result;
  }, new Map());

export const getRowCells = (columns, row) =>
  new Map(
    [...columns.values()].map(column => {
      const result = {column};

      switch (typeof column.value) {
        case 'string':
          result.value = row.data[column.value];
          break;
        case 'function':
          result.value = column.value({row, column, columns});
          break;
      }

      if (column.isDate && result.value !== null) {
        let {value} = result;

        if (value) {
          const dateInstance = new Date(value);
          const dateTime = dateInstance.getTime();

          if (!isNaN(dateTime)) {
            value = dateInstance;
            result.dateTime = dateTime;
            result.dateInstance = dateInstance;
          }
        }

        result.value = formatDate(value, typeof column.isDate === 'string' ? column.isDate : undefined);
      }

      switch (typeof column.key) {
        case 'string':
          result.key = row.data[column.key];
          break;
        case 'function':
          result.key = column.key({value: result.value, row, column, columns});
          break;
        default:
          result.key = String(column.id);
      }

      return [result.key, result];
    }),
  );

export const sortRows = ({rows, columns, sortObject, immutable = false}) => {
  const sortFactor = sortObject.factor;
  const columnId = sortObject.columnId;
  const column = columns.get(columnId);
  const isDate = Boolean(column.isDate);

  if (immutable) {
    rows = rows.slice();
  }

  if (column.sortFunction) {
    return rows.sort((a, b) => column.sortFunction({a, b, rows, column, columns, sortFactor}));
  }

  // Take sort values only ones in advance, cause .sort function invokes each row several time
  let sortCustomValuesMap;

  if (column.sort) {
    sortCustomValuesMap = new Map(
      rows.map(row => [row, column.sort({value: row.cells.get(columnId).value, row, column, columns})]),
    );
  }

  return rows.sort((a, b) => {
    let valueA;
    let valueB;

    if (sortCustomValuesMap) {
      valueA = sortCustomValuesMap.get(a);
      valueB = sortCustomValuesMap.get(b);
    } else {
      const aCell = a.cells.get(columnId);
      const bCell = b.cells.get(columnId);

      if (isDate) {
        // Dates in form of numbers (unix time)
        valueA = aCell.dateTime || 0;
        valueB = bCell.dateTime || 0;
      } else {
        valueA = aCell.value;
        valueB = bCell.value;
      }
    }

    if (typeof valueA === 'string' && valueA.length > 0 && typeof valueB === 'string' && valueB.length > 0) {
      return sortFactor * collator.compare(valueA, valueB);
    }

    if (valueA === valueB) {
      return 0;
    }

    // Zero numbers (and null dates) goes to the beginning, everything else empty ('', null, undefined) goes to the end
    if (!valueA && valueA !== 0) {
      return sortFactor;
    }

    if (!valueB && valueB !== 0) {
      return -sortFactor;
    }

    return valueA < valueB ? -sortFactor : sortFactor;
  });
};

export const lowerNumberCase = ({value}) => (value && value.toLowerCase ? Number(value.toLowerCase()) : Number(value));

/**
 * Use to mark clickable element in the Grid cell, automatically avoid trigger onClick of the row and row link highlight onMouseOver.
 * **Note**: `refs` config is useless now. If you need to use the `refs` config, consider manually handle the clickable settings instead of using this function
 *
 * @param {Object} columnConfig The normal Grid column config object, except the `format` function having an additional `clickableRef` prop for referencing clickable elements.
 * @param {Function} ref The ref callback that returns a **DOM** node instead of React instance
 * @returns The column config object with `refs`, `onMouseOver` and `format`.
 */
export const clickableColumn = ({format, refs, onMouseOver, ...rest}, ref = reactUtils.getNativeElement) => {
  // can't use Symbol here since it's not enumerable
  const refKey = '__clickable__';

  return {
    ...rest,
    refs: {
      [refKey]: (node, {elements}) => (elements ? elements.add(ref(node)) : new Set([ref(node)])),
    },
    onMouseOver: context =>
      (!context.elements.hasOwnProperty(refKey) ||
        Array.from(context.elements[refKey]).every(elem => !elem?.contains(context.evt.target) ?? true)) &&
      (onMouseOver?.(context) ?? true),
    format(options, ...rest) {
      return format.call(this, {...options, clickableRef: options.refs[refKey]}, ...rest);
    },
  };
};

export const clickableLabelColumn = clickableColumn({
  format: ({value, clickableRef}) =>
    value ? (
      <Pill.Label group={value.group} id={value.id} type={edge ? null : value.key} ref={clickableRef}>
        {value.value || value.name}
      </Pill.Label>
    ) : null,
  value: ({row, column}) => row.data.labels[column.id],
  sort: ({value}) => value && (value.value || value.name),
});

/**
 * Returns label data structure that will generate a link in Label component
 * @param item
 * @returns label object
 */
export const getLabelsMap = labels =>
  Array.isArray(labels)
    ? labels.reduce((result, label) => {
        if (label) {
          result[label.key] = {...label, id: hrefUtils.getId(label.href), group: label.href?.includes('label_groups')};
        }

        return result;
      }, {})
    : {};

/**
 * Convert a list of nested label objects (e.g. ContainerWorkloadProfile.labels) to a map.
 * @param apiLabels - array of labels from container API.  Has an extra layer not in most API labels.
 *                    Presence of attribute "assignment" or attribute "restriction" indicates mode.
 *                    {"key": "role", "assignment": {"href": "/orgs/1/labels/2", "value": "Database"}},
 *                    May have multiple labels of one type.
 * @returns object with attributes (R,A,E,L) as assign-label object, or Array of allow label object.
 */
export const getFlattenedLabelModeMap = apiLabels => {
  const result = {};

  if (Array.isArray(apiLabels)) {
    apiLabels.forEach(object => {
      const {key, assignment, restriction, href} = object;

      if (assignment) {
        result[key] = {key, ...assignment, id: hrefUtils.getId(href), mode: 'assign'};
      } else {
        result[`${key}Allow`] = restriction.map(label => ({...label, key, id: hrefUtils.getId(href), mode: 'allow'}));
      }
    });
  }

  return result;
};

/**
 * Returns notification object for End of Data Set if exceeded
 */
export const getMaxPageNotification = ({max = 500, page, capacity, count: {matched, total}}) => {
  // If the user has applied filters from the picker, the actual total is the matched count
  const actualTotal = matched || total;

  if (actualTotal > max && page * capacity >= max) {
    return {
      type: 'instruction',
      title: intl('Common.EndOfData'),
      message: intl('Common.PCEMaxDisplay', {count: max}),
    };
  }
};

/**
 * Returns an array that including notification object for End of Data Set if exceeded max
 * It may append to an existing array if it is provided.
 * @param {Object} options The options to be passed to getMaxPageNotification
 * @param {Array} notifications
 * @returns {Array} New or appended notifications array for End of Data Set if exceeded max
 */
export const getMaxPageNotificationList = (options, notifications = []) => {
  const maxPageNotification = getMaxPageNotification(options);

  if (maxPageNotification) {
    notifications.push(maxPageNotification);
  }

  return notifications;
};

/**
 * For a detail item, fake a rowsMap to share common operations between list and detail pages
 * @param name
 * @param href
 * @returns {Map<string, object>}
 */
export const createRowsMapForOneItem = ({name, href} = {}) => {
  const rowObj = new Map();
  const rowsMap = new Map();

  rowObj.set('name', {value: name});
  rowsMap.set(href, {cells: rowObj});

  return rowsMap;
};

export const cloneColumnMapWithAddedProp = (columnMap, columnSet, prop) =>
  new Map([...columnMap.entries()].map(([id, column]) => [id, columnSet.has(id) ? {...column, ...prop} : {...column}]));

export const sortGridColumnIds = (columnIds, columnMap) => {
  const sortColumnsByHeader = (a, b) => {
    const valueA = columnMap.get(a);
    const valueB = columnMap.get(b);

    return collator.compare(valueA.header || valueA.headerManager, valueB.header || valueB.headerManager);
  };

  return {
    required: columnIds.required.sort(sortColumnsByHeader),
    default: columnIds.default.sort(sortColumnsByHeader),
    optional: columnIds.optional.sort(sortColumnsByHeader),
    visible: columnIds.visible.sort(sortColumnsByHeader),
    defaultNotRequired: columnIds.defaultNotRequired.sort(sortColumnsByHeader),
  };
};

// Checks that sort value, like '-name' is accaptable for a given Set of valid values
export const isSortValueValid = (validSortValues, value) =>
  typeof value === 'string' && value.length > 0 && validSortValues.has(value.startsWith('-') ? value.substr(1) : value);

export const getValidSortValue = (validSortValues, value) => {
  if (isSortValueValid(validSortValues, value)) {
    return value;
  }
};

// Checks that capacity value, like 25 is accaptable for a given array of valid values
export const isCapacityValueValid = (validCapacityValues, value) =>
  typeof value === 'number' && !isNaN(value) && value > 0 && validCapacityValues.includes(value);

export const getValidCapacityValue = (validCapacityValues, value) => {
  if (isCapacityValueValid(validCapacityValues, value)) {
    return value;
  }
};

export const getValidColumns = (defaultColumns, passed) => {
  if (!Array.isArray(passed)) {
    passed = [passed];
  }

  let valid = [];

  for (const {id, required, disabled, manager} of defaultColumns.values()) {
    if (required || (!disabled && passed.includes(id)) || manager === false) {
      valid.push(id);
    }
  }

  if (sortAndStringifyArray(valid) === sortAndStringifyArray(passed)) {
    valid = passed;
  }

  return {passed, valid, isEmpty: valid.length === 0};
};

export const getValidGridParams = (gridValidParams, fields) => {
  for (const [name, value] of Object.entries(fields)) {
    const valueIsObject = typeof value === 'object';
    const passed = valueIsObject ? value.passed : gridValidParams[name];
    const valid = valueIsObject ? value.valid : value;
    const isEmpty = valueIsObject ? value.isEmpty : value === undefined;

    if (isEmpty && Object.hasOwn(getValidGridParams, name)) {
      gridValidParams = _.omit(gridValidParams, name);
    } else if (!isEmpty && passed !== valid) {
      gridValidParams = {...gridValidParams, [name]: valid};
    }
  }

  return gridValidParams;
};

export const hasOptionalColumns = columns =>
  [...columns.values()]
    .filter(column => column.id !== 'checkboxes' && !column.disabled && !column.hidden)
    .some(column => column.optional);

export const allColumnIds = columns =>
  Object.entries(columns).reduce((result, [id, column]) => {
    if (id !== 'checkboxes' && !column.disabled) {
      result.push(id);
    }

    return result;
  }, []);

export const getCustomUserSettings = (settings = {}) => {
  const customParamNames = ['sort', 'capacity', 'columns'];

  return Object.entries(settings).reduce((result, [paramName, paramValue]) => {
    if (customParamNames.includes(paramName)) {
      result[paramName] = paramValue;
    }

    return result;
  }, {});
};

export const getColumnIdsByCategory = columnMap =>
  [...columnMap.entries()].reduce(
    (result, [id, column]) => {
      if (id === 'checkboxes' || column.disabled || column.parentId) {
        return result;
      }

      if (column.required) {
        result.required.push(id);
      } else if (column.optional) {
        result.optional.push(id);
      } else {
        result.defaultNotRequired.push(id);
      }

      if (!column.hidden) {
        result.visible.push(id);
      }

      if (!column.optional) {
        result.default.push(id);
      }

      return result;
    },
    {required: [], default: [], optional: [], visible: [], defaultNotRequired: []},
  );

export const getGridData = (options = {}) => {
  const {settings = {}, rows = [], columns, page = 1, capacity, params = {}, filter} = options;
  let offset = 0;

  // If pagination is set, compute list offset (position of first row)
  if (capacity) {
    offset = (page - 1) * capacity;
  }

  const sort = options.sort || settings.sort;
  let sortObject = {};

  if (sort) {
    sortObject = {
      factor: sort.startsWith('-') ? -1 : 1,
      columnId: sort.startsWith('-') ? sort.substr(1) : sort,
    };
  }

  // If sorting is on, modify columns to indicate which is sorted
  const resultColumns = !sort
    ? columns
    : new Map(
        [...columns.values()].map(column => {
          if (column.sortable === false) {
            return [column.id, column];
          }

          const result = {...column};

          if (column.id === sortObject.columnId) {
            result.sorted = sortObject.factor;
          } else {
            result.sorted = null;
          }

          return [column.id, result];
        }),
      );

  let resultRows = rows;

  if (offset > rows.length) {
    // If offset is bigger than list length, means result will be empty anyway, no need to sort or slice
    resultRows = [];
  } else {
    resultRows = rows.map(row => ({cells: getRowCells(resultColumns, row), ...row}));

    // Sort if sorting parameter exists
    if (sort) {
      sortRows({rows: resultRows, columns: resultColumns, sortObject});
    }

    // If pagination is set and list length is bigger than page capacity, slice list,
    // (no need to slice if there is less rows in list than page capacity)
    if (capacity && resultRows.length > capacity) {
      resultRows = resultRows.slice(offset, offset + capacity);
    }
  }

  const rowsMap = new Map();

  for (const row of resultRows) {
    rowsMap.set(row.key, row);
  }

  return {
    settings,
    rows: resultRows,
    rowsMap,
    columns: resultColumns,
    sort,
    sortObject,
    filter,
    page,
    capacity,
    totalRows: rows.length,
    params,
    columnIds: options.columnIds || getColumnIdsByCategory(columns),
  };
};

/**
 * Helper that decomposes and transforms span and column breakpoint into
 * a. indices of grid areas to remove for each span column to accomodate span.
 * b. grid-column style value for each span column in the array format: gridColumn: [start, end], where style.gridColumn = start / end
 * @param span span object if exists with properties of span object containing columns to span or not span respectively set to true or false boolean values
 * @param breakpoint An object that contains columns array which is a List of column breakpoints
 * @returns {array of objects which contain span columns with a and b mentioned above}
 */
export const computeColSpan = (span = {}, props) => {
  const {breakpoint} = props;
  // Get indices of all span columns.
  //For each span object in state file row: {span: {column1: true, column2: false, column3: true}}

  return Object.keys(span).reduce((result, columnName) => {
    //Find columns if they exists

    const index = breakpoint.columns.findIndex(column =>
      column.cells?.some(cell => cell.id === columnName && span[cell.id] === true),
    );

    //If column not found or any criteria for span: [before, after] is not met return result and log error.
    if (index === -1) {
      return result;
    }

    const foundColumn = {index, ...breakpoint.columns[index]};

    const spanColumn = {
      id: columnName,
      index: foundColumn.index,
      indicesOfGridAreasToRemove: [],
    };

    //Input
    //gridSettings with id: 'secondaryGrid' in ComponentsConfig.js
    //column: messages, span: [-1,4] ; column: messages1, span: [-1, 2]

    //Output
    //indicesOfGridAreasToRemove
    //0,1,3,4,5
    //6,8,9

    //Find grid areas to remove.
    //Computation for span: [before, after]
    for (let i = foundColumn.index + foundColumn.span[0]; i <= foundColumn.index + foundColumn.span[1]; i++) {
      if (i !== foundColumn.index) {
        spanColumn.indicesOfGridAreasToRemove.push(i);
      }
    }

    //Compute grid-column for each span column in the format gridColumn: [x , y] where style.gridColumn = x / y
    //Add 2 to span[0] value for grid-column start value, since grid span starts from column 2 in template(1st is grid-focus column)
    //Add 3 to span[1] value for grid-column since column will span (end - start) number of columns in grid.
    spanColumn.gridColumn = [foundColumn.index + foundColumn.span[0] + 2, foundColumn.index + foundColumn.span[1] + 3];

    result.push(spanColumn);

    return result;
  }, []);
};

export const getColSpanData = props => {
  let spanColumnIndices = [];
  let flattenedIndices = [];
  const {row, extraProps} = props;

  const span = extraProps?.span ?? row.span;

  //Span column functioning:
  //1. Criteria: size: 0px is default for all template columns if not specified in template config.
  //2. Columns that span, should have span: [before, after] specified in template config..
  //3. span property set in extraPropsKeyMap takes precedence over row state file(grid rows) for span.
  //4. Row in state file(grid rows) or extraPropsKeyMap prop span format: {..., span: {column1: true, column2: false}}
  //5. Set showColumnManager: false for now in gridSettingsConfig. Will be addressed in: https://jira.illum.io/browse/EYE-81256

  //Configuration + Outcomes:
  // span: [before, after] (specified in template grid config)
  //1. span set in extraPropsKeyMap or row(state file) in above format.
  //   a. Span from before <= (span column index) <= after; removes grid indices (before and after).
  //   b. Sets style grid-column in GridAreaBody for (span column index)
  //2. span set to false/does not exist(undefined/null) in extraPropsKeyMap/row state file:
  //  a. Hide column if 0px. Show column if size > 0px (like a normal column)
  //  b. Do not remove grid area indices (before, after).
  if (span) {
    spanColumnIndices = computeColSpan(span, props);

    //gridSettings with id: 'secondaryGrid' in ComponentsConfig.js
    //[0,1,3,4,5] => Array 1 for messages column
    //[6,8,9] => Array 2 for messages1 column
    //[0,1,3,4,5,6,8,9] => grid areas to remove. (flattened map)
    flattenedIndices = spanColumnIndices?.map(item => item.indicesOfGridAreasToRemove).flat();
  }

  if (__DEV__) {
    const hasDuplicates = [...new Set(flattenedIndices)].length !== flattenedIndices.length;

    if (hasDuplicates) {
      console.error(
        'Every template span: [before,after] should have unique span columns and the span: [before,after] indices cannot overlap',
      );
    }
  }

  return {
    spanColumnIndices,
    flattenedIndices: new Set(flattenedIndices),
  };
};
