/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */
import {PureComponent} from 'react';
import * as PropTypes from 'prop-types';
import _ from 'lodash';
import intl from 'intl';
import {Tally} from 'components';
import styles from 'components/Tally/Tally.css';
import cx from 'classnames';
import stylesUtils from 'utils.css';

/*
 * Builds a summary of VENs in different states.
 * Uses VEN get collection `/orgs/:xorg_id/vens`, supporting the following events:
 *
 * General
 * - ?health=error health=(error|warning|healthy|unhealthy)
 * - ?status=active
 * - ?status=suspended
 *
 * Upgrade-related
 * - ?upgrade_pending=true
 */
export default class VenUpgradeConditions extends PureComponent {
  static propTypes = {
    type: PropTypes.oneOf(['workload', 'ven']),
    onFilterClick: PropTypes.func.isRequired, // handler to apply filters
    healthError: PropTypes.number, // number of VENs with health: error
    active: PropTypes.number, // number of VENs with status: active
    suspended: PropTypes.number, // number of VENs with status: suspended
    pending: PropTypes.number, // number of VENs with upgrade_pending
  };

  render() {
    const tallyItemsWarning = [];
    const tallyItemsError = [];
    const tallyItemsInfo = [];
    const {healthError, onFilterClick, suspended, warning, pending, type} = this.props;

    if (healthError) {
      tallyItemsError.push({
        type: 'error',
        count: healthError,
        children: intl('Workloads.Status.ErrorCount', {count: healthError}),
        onClick: _.partial(onFilterClick, [
          type === 'workload'
            ? {
                categoryName: intl('Workloads.PolicySync'),
                categoryKey: 'policy_health',
                value: intl('Common.Error'),
              }
            : {categoryName: intl('VEN.Health'), categoryKey: 'health', value: intl('Common.Error')},
        ]),
      });
    }

    if (warning) {
      tallyItemsWarning.push({
        children: intl('Workloads.Status.WarningCount', {count: warning}),
        count: warning,
        onClick: _.partial(onFilterClick, [
          type === 'workload'
            ? {
                categoryName: intl('Workloads.PolicySync'),
                categoryKey: 'policy_health',
                value: intl('Common.Warning'),
              }
            : {categoryName: intl('VEN.Health'), categoryKey: 'health', value: intl('Common.Warning')},
        ]),
      });
    }

    if (suspended) {
      tallyItemsWarning.push({
        children: intl('Workloads.Status.Suspended'),
        count: suspended,
        onClick: _.partial(onFilterClick, [
          type === 'workload'
            ? {
                categoryName: intl('Workloads.PolicySync'),
                categoryKey: 'policy_health',
                value: intl('Workloads.Status.Suspended'),
              }
            : {categoryName: intl('Common.Status'), categoryKey: 'status', value: intl('Workloads.Status.Suspended')},
        ]),
      });
    }

    if (pending) {
      tallyItemsInfo.push({
        children: intl('VEN.Upgrade.UpgradeInProgress'),
        count: pending,
        onClick: _.partial(onFilterClick, [
          {
            categoryName: intl('Common.Status'),
            categoryKey: 'upgrade_pending',
            value: intl('Common.True'),
          },
        ]),
      });
    }

    return (
      <div className={cx(stylesUtils.gapSmall, styles.tallyAlignment, stylesUtils.gapHorizontalWrap)}>
        {tallyItemsError.length ? <Tally justify="right" tallyItems={tallyItemsError} status="error" /> : null}
        {tallyItemsWarning.length ? <Tally justify="right" tallyItems={tallyItemsWarning} status="warning" /> : null}
        {tallyItemsInfo.length ? <Tally justify="right" tallyItems={tallyItemsInfo} status="info" /> : null}
      </div>
    );
  }
}
