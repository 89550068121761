/**
 * Copyright 2017 Illumio, Inc. All Rights Reserved.
 */
import PubSub from 'pubsub';
import * as PropTypes from 'prop-types';
import {RedirectError} from 'errors';
import {createSelector} from 'reselect';
import {createElement, Component} from 'react';
import {connect, ReactReduxContext} from 'react-redux';
import {reactUtils} from 'utils';
import {shallowEqual} from 'utils/general';
import {getRouteListFromRouteName} from './Prefetcher';
import Fetcher from './Fetcher';
import {getRoute, getRouteName, getRouteParams} from 'containers/App/AppState';

const getRouteSpecificParams = createSelector(
  [getRoute, getRouteName, getRouteParams, (state, props) => props.routeNameHandle],
  (route, routeName, routeParamsAll, routeNameHandle) => {
    const routes = getRouteListFromRouteName(routeName, routeNameHandle);

    const routeParams = routes.reduce((result, name) => {
      for (const paramName of Object.keys(route.meta.params[name])) {
        if (routeParamsAll.hasOwnProperty(paramName)) {
          result[paramName] = routeParamsAll[paramName];
        }
      }

      return result;
    }, {});

    return {routeName, routeParams, routeParamsAll};
  },
);

const mapStateToProps = (state, ownProps) => getRouteSpecificParams(state, ownProps);
const areStatePropsEqual = (next, prev) =>
  next === prev || (next.routeName === prev.routeName && shallowEqual(next.routeParams, prev.routeParams));

@connect(mapStateToProps, null, null, {areStatePropsEqual})
export default class PrefetchComponent extends Component {
  static contextType = ReactReduxContext;
  static propTypes = {
    children: PropTypes.func.isRequired,
    routeNameHandle: PropTypes.string.isRequired,

    onEsc: PropTypes.oneOfType([
      PropTypes.func, // Custom Esc handler, gets (evt, instance) params, can call instance.back()/instance.cancel() manually
      PropTypes.string, // 'back', 'cancel'
    ]),

    // By default this PrefetchRouteChildren component is not sensitive to rerender from caller container,
    // because shouldComponentUpdate looks only at internal state that depend on route.
    // So if container rerenders, this component does not.
    // But sometimes you need this one to rerender too - if 'children' function renders something that depends on caller container props
    // In that case you can set updateOnContainerRender prop to true so in shouldComponentUpdate 'children' will be compared,
    // and it is different if caller container create new 'children' function on each render
    updateOnContainerRender: PropTypes.bool,
  };

  static defaultProps = {
    updateOnContainerRender: false,
  };

  constructor(props, context) {
    super(props, context);

    const {
      store,
      store: {
        prefetcher,
        router,
        router: {routesMap},
      },
    } = context;
    const {routeName, routeNameHandle, routeParams} = props;
    const route = routesMap.get(routeNameHandle);

    if (__DEV__ && !route.prefetchChildrenByComponent) {
      console.error(
        `Specify 'prefetchChildrenByComponent' option for route ${routeNameHandle} to use PrefetchRouteChildren component`,
      );
    }

    const {routesNames, routesNamesToRender, routesNamesToPrefetch, continueProgressBarOnChildrenRender} =
      prefetcher.getRoutesToActivate(routeName, routeNameHandle);

    this.state = {
      store,
      prefetcher,
      router,
      routesMap,
      routeName,
      routeParams,
      // Consider children fetched if there is no children to fetch or they have been fetched already by parent fetcher on entry phase,
      // So we can render children rigth away on current cycle
      fetched: !routesNamesToPrefetch.length || route.prefetchChildrenByComponent === 'child-transition',
      fetchError: false,
      fetchingChildSaga: null,
      canceled: false,
      routesNames,
      routesNamesToRender,
      routesNamesToPrefetch,
      continueProgressBarOnChildrenRender,
    };

    this.handleEsc = this.handleEsc.bind(this);
  }

  componentDidMount() {
    const {prefetcher, routesMap} = this.state;
    const {/*routeName, */ routeNameHandle} = this.props;
    const route = routesMap.get(routeNameHandle);

    if (!this.state.fetched) {
      // if children need to be fetched, start progressBar if it is needed and run fetching
      if (route.progressBar === 'entry') {
        prefetcher.progressBar.start({rewind: false});
      }

      this.prefetchChildren();
    } else if (route.progressBar === 'entry' && !this.state.continueProgressBarOnChildrenRender) {
      // If there is nothing to fetch and progresBar is set to entry (parent didn't end it) and
      // last rendering component doesn't need progresBar on entry, then just end progressBar
      prefetcher.progressBar.end();
      prefetcher.pageReadyPublish(prefetcher.toState);
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    // React to route change
    if (nextProps.routeName !== prevState.routeName || !shallowEqual(nextProps.routeParams, prevState.routeParams)) {
      const {routeName, routeNameHandle} = nextProps;
      const {prefetcher, routesMap} = prevState;
      const route = routesMap.get(routeNameHandle);

      const {routesNames, routesNamesToRender, routesNamesToPrefetch, continueProgressBarOnChildrenRender} =
        prefetcher.getRoutesToActivate(routeName, routeNameHandle);
      const childrenHaveFetch = routesNamesToPrefetch.length > 0;

      const newState = {
        routeName,
        routesNames,
        routesNamesToPrefetch,
        routesNamesToRender,
        routeParams: nextProps.routeParams,
        continueProgressBarOnChildrenRender,
      };

      if (
        prevState.fetched &&
        childrenHaveFetch &&
        ['child-transition', 'always'].includes(route.prefetchChildrenByComponent)
      ) {
        // If route always fetches its children and they were already fetched,
        // then reset fetched flag and start fetching in componentDidUpdate again
        newState.fetched = false;

        // Start brogress bar if component handles children prefetch and has progressBar flag
        if (['child-transition', 'always'].includes(route.progressBar)) {
          prefetcher.progressBar.start();
        }
      } else if (!prevState.fetched) {
        if (prevState.fetchingChildSaga) {
          if (prevState.fetchingChildSaga.isRunning()) {
            // If data for route is still fetching, cancel it
            prefetcher.fetchCancel();
          }

          newState.fetchingChildSaga = null;
        }

        if (!childrenHaveFetch) {
          // If new route doesn't have children with prefetch,
          // set fetched state to true to render children immediately,
          // stop data fetcher processing and progress bar
          newState.fetched = true;
          prefetcher.fetchFinish({progress: 'end'});
        }
      } else if (route.progressBar === 'entry' && !prevState.continueProgressBarOnChildrenRender) {
        // If there is nothing to fetch and progresBar set to entry (parent didn't end it) and
        // last rendering component doesn't need progresBar on entry, then just end progressBar
        prefetcher.progressBar.end();
      }

      if (prevState.canceled) {
        // If we're leaving route that was canceled, remove canceled flag
        newState.canceled = false;
      }

      if (prevState.fetchError) {
        // If we're leaving route with error fetch state, just clear that error state
        newState.fetchError = false;
      }

      return newState;
    }

    return null;
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.routeName === this.state.routeName && !shallowEqual(nextState.routeParams, this.state.routeParams)) {
      // If it's the same route but with different params, we should rerender
      return true;
    }

    if (nextProps.updateOnContainerRender && nextProps.children !== this.props.children) {
      return true;
    }

    return (
      nextState.fetched !== this.state.fetched ||
      nextState.canceled !== this.state.canceled ||
      nextState.fetchError !== this.state.fetchError ||
      this.state.routesNamesToRender.length !== nextState.routesNamesToRender.length ||
      this.state.routesNamesToRender.some((name, index) => name !== nextState.routesNamesToRender[index])
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (!this.state.fetched && !this.state.canceled && !this.state.fetchingChildSaga) {
      this.prefetchChildren();
    } else {
      if ((this.state.fetched && !prevState.fetched) || (this.state.canceled && !prevState.canceled)) {
        this.stopEscListening();
      }

      if (this.state.fetched && !prevState.fetched) {
        const {prefetcher} = this.state;

        prefetcher.pageReadyPublish(
          prefetcher.toState,
          !this.state.continueProgressBarOnChildrenRender && !this.state.fetchError,
        );
      }
    }
  }

  componentWillUnmount() {
    if (this.state.fetchingChildSaga && this.state.fetchingChildSaga.isRunning()) {
      // If data for route is still fetching, cancel it
      this.stopEscListening();
      this.state.prefetcher.fetchCancel();
    }
  }

  handleEsc(evt) {
    const {onEsc} = this.props;

    if (evt.keyCode === 27 && onEsc) {
      evt.stopPropagation();

      if (typeof onEsc === 'function') {
        onEsc(evt, this);
      } else if (onEsc === 'cancel') {
        this.cancel();
      } else if (onEsc === 'back') {
        this.back();
      }
    }
  }

  async prefetchChildren() {
    const {routeName, routeNameHandle} = this.props;
    const {prefetcher, router} = this.state;
    const route = router.routesMap.get(routeNameHandle);

    try {
      const {routesNamesToPrefetch, continueProgressBarOnChildrenRender} = this.state;
      const routesToFetch = routesNamesToPrefetch.map(name => router.routesMap.get(name));

      const fetchingChildSaga = await this.startChildFetching(routesToFetch);

      await fetchingChildSaga.toPromise();

      if (fetchingChildSaga.isCancelled()) {
        // Fetching canceled if route is changed, different prefetchChildren might be called already, so just exit
        return;
      }

      this.stopEscListening();
      prefetcher.fetchFinish({
        // If route has progress bar and last route to fetch doesn't have it, end the progressBar
        progress: route.progressBar && !continueProgressBarOnChildrenRender ? 'end' : null,
      });

      // Update state to draw children
      this.setState({fetchingChildSaga: null, fetched: true});
    } catch (error) {
      this.stopEscListening();

      if (error instanceof RedirectError) {
        const name = error.to ? `app.${error.to}` : routeName;

        if (__DEV__) {
          console.info(`Redirecting to ${decodeURIComponent(router.buildUrl(name, error.params))}`);
        }

        if (error.details.proceedFetching) {
          prefetcher.redirecting = true;
          prefetcher.redirectingChildren = true;
        } else {
          prefetcher.fetchFinish({progress: 'rewind', batchEnd: true, batchNotify: true});
        }

        // Set canceled state to reset it back to false on next getDerivedStateFromProps
        // when new route params are ready to bypass shouldComponentUpdate
        this.setState({fetchingChildSaga: null, canceled: true});

        router.navigate(name, error.params, {replace: true});

        return;
      }

      // TODO: Uncomment `window.contentRendered` to render NavigationAlert with error only on subsequent transitions.
      // First error will result into a separate Timeout page, when it's ready
      if (!this.state.continueProgressBarOnChildrenRender /* && window.contentRendered*/) {
        // Notify navigation popup to render error message
        PubSub.publish('NAVIGATION.ALERT', {error});
      }

      prefetcher.fetchFinish({
        // If route has progress bar, end the progressBar
        progress: route.progressBar ? 'end' : null,
      });

      this.setState({fetchingChildSaga: null, fetched: true, fetchError: error});
    }
  }

  cancel() {
    const {prefetcher} = this.state;

    this.stopEscListening();
    prefetcher.fetchCancel(true);
    prefetcher.fetchFinish({progress: 'rewind'});
    this.setState({fetchingChildSaga: null, canceled: true});
  }

  back() {
    const {router, prefetcher} = this.state;

    if (prefetcher.fromState) {
      this.stopEscListening();

      // If we navigated here from other page, navigate back to it
      // It works like user click on other link while this is till fetching, so route middleware will cancel current fetch
      router.navigate(prefetcher.fromState.name, prefetcher.fromState.params, {replace: true});
    } else {
      // If it is the first opening (no previous route state), then just cancel since we don't know where navigate to
      this.cancel();
    }
  }

  async startChildFetching(routesToFetch) {
    const {fetchingChildSaga} = await reactUtils.setStateAsync(
      prevState => ({fetchingChildSaga: prevState.prefetcher.fetch(routesToFetch)}),
      this,
    );

    this.startEscListening();

    return fetchingChildSaga;
  }

  startEscListening() {
    window.addEventListener('keyup', this.handleEsc);
  }

  stopEscListening() {
    window.removeEventListener('keyup', this.handleEsc);
  }

  render() {
    const {
      props: {children, routeParamsAll},
      state: {
        routesMap,
        routeName,
        routeParams,
        fetched,
        canceled,
        fetchError,
        routesNames,
        routesNamesToRender,
        routesNamesToPrefetch,
      },
    } = this;
    const route = routesMap.get(routeName);
    let childrenContent = null;

    if (fetched && !fetchError) {
      childrenContent = [...routesNamesToRender].reverse().reduce((acc, routeNameHandle) => {
        const route = routesMap.get(routeNameHandle);

        return (
          <Fetcher>
            {createElement(
              route.component,
              {
                routeName,
                routeParams,
                routeParamsAll,
                routeNameHandle,
                routesNames,
                routesNamesToRender,
                routesNamesToPrefetch,
              },
              acc,
            )}
          </Fetcher>
        );
      }, null);
    }

    return children({
      fetched,
      fetchError,
      canceled,
      route,
      routeName,
      routeParams,
      routeParamsAll,
      routesNames,
      routesNamesToRender,
      routesNamesToPrefetch,
      childrenContent,
    });
  }
}
