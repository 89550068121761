/**
 * Copyright 2016 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from 'intl';
import * as GridUtils from 'components/Grid/GridUtils';
import {DISCOVERED_GROUP} from 'edge/containers/Group/GroupUtils';
import {createSelector} from 'reselect';
import createCachedSelector from 're-reselect';
import {isSuperclusterMember, getOrgId} from 'containers/User/UserState';
import {getClusters, getLocalClusterBasic, getLocalFQDN} from 'containers/Health/HealthState';
import {getGroupProfile, getGroup, getGroupItem} from 'edge/containers/Group/GroupViewState';
import {
  areVulnerabilitiesEnabled,
  getRouteName,
  getRouteParams,
  getRouteCurrentParams,
  isEdge,
  isCrowdstrike,
  isEnhancedDataCollectionEnabled,
} from 'containers/App/AppState';
import {getLabelsHrefMap} from 'containers/Label/List/LabelListState';
import {getScopeLabelByHref} from 'containers/Selectors/SelectorUtils';
import {getPolicyStateContent, getWorkloadHealthStatus, noLabelsExistList} from '../WorkloadUtils';
import {getUrlScopeValue, getGridSelector} from 'components/Grid/GridSelectors';
import {gridSettings, getSelectorSettings, resourceType} from './WorkloadListConfig';

export default {
  list(state = [], action) {
    switch (action.type) {
      case 'WORKLOADS_GET_LIST':
        return action.data.list;
      default:
        return state;
    }
  },

  count(state = {}, action) {
    switch (action.type) {
      case 'WORKLOADS_GET_LIST':
        return action.data.count;
      default:
        return state;
    }
  },

  // Edge / CS related
  discoveredWorkloads(state = [], action) {
    switch (action.type) {
      case 'DISCOVERED_WORKLOADS_GET_LIST':
        return action.data.list;
      default:
        return state;
    }
  },

  // Edge / CS related
  discoveredWorkloadsCount(state = {}, action) {
    switch (action.type) {
      case 'DISCOVERED_WORKLOADS_GET_LIST':
        return action.data.count;
      default:
        return state;
    }
  },

  // Edge / CS related
  externalDataSetCount(state = {}, action) {
    switch (action.type) {
      case 'WORKLOADS_EXTERNAL_DATA_SET_GET_LIST':
        return action.data.count;
      default:
        return state;
    }
  },

  ignoreScope(state = false, action) {
    switch (action.type) {
      case 'WORKLOADS_GET_LIST':
        return action.data.ignoreScope;
      default:
        return state;
    }
  },

  map(state = {}, action) {
    switch (action.type) {
      case 'WORKLOADS_GET_LIST': {
        const result = {...state};

        for (const workload of action.data.list) {
          result[workload.href] = workload;
        }

        return result;
      }
      case 'WORKLOADS_GET_INSTANCE':
        if (action.data && !_.isEqual(action.data, state[action.data.href])) {
          return {...state, [action.data.href]: action.data};
        }

        return state;
      default:
        return state;
    }
  },
};

export const getWorkloads = state => state.workload.list;
export const getWorkloadsHrefMap = state => state.workload.map;
export const getWorkloadsCount = state => state.workload.count;
export const getWorkloadsIgnoreScope = state => state.workload.ignoreScope;

// Edge / CS related
export const getDiscoveredWorkloads = state => state.workload.discoveredWorkloads;
export const getDiscoveredWorkloadsCount = state => state.workload.discoveredWorkloadsCount;
export const getWorkloadsExternalDataSetCount = state => state.workload.externalDataSetCount;

// Edge / CS related
export const isShowingDiscoveredWorkloads = createSelector(
  [getRouteCurrentParams],
  routeParams => routeParams.group === DISCOVERED_GROUP,
);
// Edge / CS related
export const getDiscoveredWorkloadsMap = createSelector(getDiscoveredWorkloads, discoveredWorkloads =>
  discoveredWorkloads.reduce((map, item) => {
    map.set(item.href, item);

    return map;
  }, new Map()),
);

const getWorkloadsRows = createSelector(
  [
    getWorkloads,
    getDiscoveredWorkloadsMap,
    isSuperclusterMember,
    getLocalClusterBasic,
    isCrowdstrike,
    isEnhancedDataCollectionEnabled,
  ],
  (
    workload,
    discoveredWorkloadsMap,
    userIsSuperclusterMember,
    localCluster = {},
    crowdstrikeEnabled,
    hasEnhancedDataCollection,
  ) =>
    workload.map(item => {
      const ven = item.agent;
      const online = item.online;
      const venConfig = ven && ven.config;
      const venStatus = ven && ven.status;
      const creator = _.get(item, 'created_by.href');
      let pairingType = 'none';

      const {enforcement_mode = '', visibility_level = '', log_traffic = ''} = item;

      if (creator && venStatus) {
        if (creator.includes('agents')) {
          pairingType = 'pairingKey';
        } else if (creator.includes('users')) {
          pairingType = 'kerberosOrPKI';
        }
      }

      const healthStatus = getWorkloadHealthStatus({ven, localCluster, userIsSuperclusterMember, online});
      // Used to hide fields other than Name and Policy State
      const isDiscoveredWorkload = discoveredWorkloadsMap?.has(item.href);

      return {
        isDiscoveredWorkload,
        pairingType,
        healthStatus,
        key: item.href,
        unmanaged: !venStatus,
        // item.caps.length === 0 "read only"
        selectable: Array.isArray(item.caps) && item.caps.length > 0,
        staticPolicy: venConfig && venConfig.security_policy_update_mode === 'static',
        policyState: getPolicyStateContent(enforcement_mode, visibility_level, log_traffic).key,
        enforcement: enforcement_mode,
        visibility: visibility_level,
        hasEnhancedDataCollection,
        data: {
          ...item,
          enforcement_mode,
          visibility_level,
          log_traffic,
          labels: GridUtils.getLabelsMap(item.labels),
        },
      };
    }),
);

export const getCalculatedStaticMap = createSelector(
  [
    getClusters,
    getWorkloadsCount,
    getWorkloadsIgnoreScope,
    isShowingDiscoveredWorkloads,
    isCrowdstrike,
    getSelectorSettings,
  ],
  (clusters, count, ignoreScope, showingDiscoveredWorkloads, crowdstrikeEnabled, selectorSettings) => {
    // CrowdStrike + Discovered Workloads statics hiding
    if (showingDiscoveredWorkloads) {
      return {name: intl('Common.Name')};
    }

    if (crowdstrikeEnabled) {
      const {policy_health: policyHealth, ...statics} = selectorSettings.staticMap;

      return statics;
    }

    const newStaticMap =
      !ignoreScope && clusters.length > 1
        ? {
            'agent.active_pce_fqdn': intl('Common.PCE'),
          }
        : {};

    const policyHealthStaticMap = count.staticMode
      ? {
          security_policy_received_at: intl('Workloads.PolicyLastReceived'),
          security_policy_update_mode: intl('Policy.UpdateMode'),
        }
      : {};

    return {...selectorSettings.staticMap, ...policyHealthStaticMap, ...newStaticMap};
  },
);

export const getCalculatedStaticValues = createSelector(
  [
    getClusters,
    getLocalFQDN,
    getWorkloadsCount,
    getWorkloadsIgnoreScope,
    getRouteName,
    isEdge,
    getDiscoveredWorkloads,
    isShowingDiscoveredWorkloads,
    isCrowdstrike,
    getSelectorSettings,
  ],
  (
    clusters,
    localFQDN,
    count,
    ignoreScope,
    routeName,
    edgeEnabled,
    discoveredWorkloads,
    showingDiscoveredWorkloads,
    crowdstrikeEnabled,
    selectorSettings,
  ) => {
    const newStaticValues =
      !ignoreScope && clusters.length > 1
        ? {
            'agent.active_pce_fqdn': clusters.reduce((result, cluster) => {
              if (cluster) {
                const label =
                  cluster.fqdn === localFQDN ? intl('Workloads.ThisPCE', {fqdn: cluster.fqdn}) : cluster.fqdn;

                result[label] = cluster.fqdn;
              }

              return result;
            }, {}),
          }
        : {};

    // Populate Name with Discovered Workloads Names
    if (showingDiscoveredWorkloads) {
      newStaticValues.name = discoveredWorkloads.reduce((object, discoveredWorkload) => {
        const name = discoveredWorkload.name || discoveredWorkload.hostname;

        object[name] = name;

        return object;
      }, {});
    }

    const policyHealthStaticValues = count.staticMode
      ? {
          policy_health: {
            ...selectorSettings.staticValues.policy_health,
            [intl('Common.Staged')]: [{name: 'security_policy_sync_state', value: 'staged'}],
          },
        }
      : {};

    const UnmanagementStaticValue = crowdstrikeEnabled
      ? {
          managed: {
            [intl('Common.Online')]: [
              {name: 'online', value: 'true'},
              {name: 'managed', value: 'true'},
            ],
            [intl('Workloads.Status.Offline')]: [
              {name: 'online', value: 'false'},
              {name: 'managed', value: 'true'},
            ],
            [intl('Common.Unmanaged')]: 'false',
          },
        }
      : {};

    let managedSaticValues;

    if (!edgeEnabled && routeName.startsWith('app.virtualServers')) {
      managedSaticValues = Object.keys(selectorSettings.staticValues).reduce((object, key) => {
        if (key === 'managed') {
          object[key] = Object.keys(selectorSettings.staticValues[key]).reduce((object, key) => {
            if (key !== intl('Common.Unmanaged')) {
              object[key] = selectorSettings.staticValues.managed[key];
            }

            return object;
          }, {});
        } else {
          object[key] = selectorSettings.staticValues[key];
        }

        return object;
      }, {});
    } else {
      managedSaticValues = selectorSettings.staticValues;
    }

    return {...managedSaticValues, ...newStaticValues, ...policyHealthStaticValues, ...UnmanagementStaticValue};
  },
);

export const getCalculatedFacetMap = createSelector(
  [isShowingDiscoveredWorkloads, isCrowdstrike, getSelectorSettings],
  (showingDiscoveredWorkloads, crowdstrikeEnabled, selectorSettings) => {
    // CrowdStrike + Discovered Workloads facet hiding
    if (showingDiscoveredWorkloads) {
      return {};
    }

    if (crowdstrikeEnabled) {
      const {hostname, ...facets} = selectorSettings.facetMap;

      return facets;
    }

    return selectorSettings.facetMap;
  },
);

export const getCalculatedScopeMap = createSelector(
  [isShowingDiscoveredWorkloads, getSelectorSettings],
  (showingDiscoveredWorkloads, selectorSettings) => {
    // Discovered Workloads scope hiding
    if (showingDiscoveredWorkloads) {
      return {};
    }

    return selectorSettings.scopeMap;
  },
);

export const getCalculatedFilterMap = createSelector(
  [getCalculatedStaticMap, getCalculatedFacetMap, getCalculatedScopeMap, getRouteParams, isEdge],
  (calculatedStaticMap, calculatedFacetMap, calculatedScopeMap, params, isEdge) => {
    let newCalculatedScopeMap = calculatedScopeMap;

    if (isEdge && params.tab === 'workloadlist') {
      newCalculatedScopeMap = _.omit(calculatedScopeMap, 'role');
    }

    return {
      ...newCalculatedScopeMap,
      ...calculatedFacetMap,
      ...calculatedStaticMap,
    };
  },
);

export const getCalculatedRouteFilters = createSelector(
  [getCalculatedFilterMap, getSelectorSettings],
  (calculatedFilterMap, selectorSettings) => ({
    ...calculatedFilterMap,
    ...selectorSettings.customRouteFilters,
  }),
);

export const getGridSettings = createCachedSelector(
  [
    areVulnerabilitiesEnabled,
    isShowingDiscoveredWorkloads,
    isEdge,
    isCrowdstrike,
    gridSettings,
    (_, props) => props?.gridId,
    (_, props) => props?.hideGroupColumn,
  ],
  (
    vulnerabilitiesAreEnabled,
    showingDiscoveredWorkloads,
    edgeEnabled,
    crowdstrikeEnabled,
    gridSettings,
    gridId,
    hideGroupColumn,
  ) => {
    const columns = {...gridSettings.columns};

    // Fields to disable in CrowdStrike + Discovered Workloads
    columns.vulnerability.disabled = !vulnerabilitiesAreEnabled || edgeEnabled || crowdstrikeEnabled;
    columns.status.disabled = showingDiscoveredWorkloads;
    columns.sync.disabled = crowdstrikeEnabled;
    columns.hostname.disabled = crowdstrikeEnabled;
    columns.last.disabled = showingDiscoveredWorkloads;
    columns.paired.disabled = crowdstrikeEnabled;
    columns.app.disabled = edgeEnabled;
    columns.loc.disabled = edgeEnabled;
    columns.env.disabled = edgeEnabled;
    columns.role.disabled = hideGroupColumn || showingDiscoveredWorkloads;
    columns.visibility.disabled = crowdstrikeEnabled;

    const sort = vulnerabilitiesAreEnabled && !crowdstrikeEnabled ? '-vulnerability' : gridSettings.sort;

    const newGridSettings = {...gridSettings, columns, sort};

    // Hide columns customization in Discovered Workloads since we only show one column ('Name')
    newGridSettings.showColumns = !showingDiscoveredWorkloads;

    if (gridId) {
      // grid config key in kvPairs consists of gridId and routeName.
      // In case of groupDashboard there are two workload lists and both uses the same Id: 1. Discovered workloads 2. Group workloads
      // Because of this grid config of one grid overwrites another one.
      // So, we need to pass a different gridId in this case to have unique kvPairs entries
      newGridSettings.id = gridId;
    }

    return newGridSettings;
  },
)((state, props) => props?.gridId ?? gridSettings().id);

export const getScopeItems = createSelector(
  [getUrlScopeValue, getLabelsHrefMap, getCalculatedFilterMap, isEdge],
  (scope, labelsMap, calculatedFilterMap, edgeEnabled) => {
    if (scope.isEmpty) {
      return [];
    }

    return scope.valid.scope.reduce((result, {href}) => {
      const label = labelsMap[href] || getScopeLabelByHref(href) || {};

      // Hide label in filter panel for Discovered Workloads
      if (edgeEnabled && noLabelsExistList.includes(label.value)) {
        return result;
      }

      result.push({
        href,
        value: label.value,
        categoryKey: label.key,
        key: label.key,
        scope: true,
        categoryName: _.get(calculatedFilterMap[label.key], 'value'),
      });

      return result;
    }, []);
  },
);

export const getGrid = (state, props) =>
  getGridSelector(state, {
    settings: state => getGridSettings(state, props),
    rows: getWorkloadsRows,
    filterMap: getCalculatedRouteFilters,
  });

export const getWorkloadsPage = createSelector(
  [
    getGrid,
    getWorkloadsCount,
    getCalculatedFilterMap,
    getCalculatedStaticValues,
    getCalculatedFacetMap,
    getScopeItems,
    isEdge,
    getOrgId,
    getDiscoveredWorkloadsCount,
    isShowingDiscoveredWorkloads,
    getRouteParams,
    isCrowdstrike,
    getSelectorSettings,
    getGroupItem,
    getGroup,
    getGroupProfile,
  ],
  (
    grid,
    count,
    calculatedFilterMap,
    calculatedStaticValues,
    calculatedFacetMap,
    scopeItems,
    edgeEnabled,
    orgId,
    discoveredWorkloadsCount,
    showingDiscoveredWorkloads,
    params,
    crowdstrikeEnabled,
    selectorSettings,
    groupItem,
    group,
    groupProfile,
  ) => {
    const rowsMap = new Map();
    const filter = grid && grid.filter;
    // Edge property
    let includeMatches;

    if (edgeEnabled) {
      includeMatches = grid.rows.map(row => ({
        name: row.data.name || row.data.hostname,
        hostname: row.data.hostname,
      }));

      //This is for case when we want to hide all matches since the grid in empty in group view
      if (!includeMatches.length) {
        includeMatches = null;
      }

      for (const row of grid.rows) {
        rowsMap.set(row.key, row);
      }
    }

    const filterItems = Object.keys(filter).reduce((result, categoryKey) => {
      if (selectorSettings.scopeMap[categoryKey]) {
        // in detail page
        const filterItems = filter[categoryKey];

        filterItems.forEach(({href, key, value}) => {
          result.push({
            href,
            value,
            categoryKey,
            key,
            categoryName: _.get(calculatedFilterMap[categoryKey], 'value'),
          });
        });
      } else if (categoryKey.includes('[gte]') || categoryKey.includes('[lte]')) {
        if (result.some(obj => obj.categoryKey === categoryKey && (obj.from || obj.to))) {
          return result;
        }

        const prefix = categoryKey.split('[')[0];
        const from = filter[`${prefix}[gte]`];
        const to = filter[`${prefix}[lte]`];
        const formattedFrom = from ? intl.date(new Date(from), 'l_HH_mm') : intl('DateTimeInput.Anytime');
        const formattedTo = to ? intl.date(new Date(to), 'l_HH_mm') : intl.date(new Date(), 'l_HH_mm');
        const value = `${intl('DateTimeInput.From')} ${formattedFrom} ${intl('DateTimeInput.To')} ${formattedTo}`;
        const dup = result.find(element => element && element.categoryKey === prefix && element.value === value);

        if (!dup) {
          result.push({
            categoryKey: prefix,
            categoryName: calculatedFilterMap[prefix],
            from,
            to,
            value,
          });
        }
      } else {
        result.push({
          categoryKey,
          value: filter[categoryKey][0],
          categoryName: calculatedFilterMap[categoryKey].value || calculatedFilterMap[categoryKey],
        });
      }

      return result;
    }, []);

    let newObjectMap = selectorSettings.objectMap;

    if (isEdge && params.tab === 'workloadlist') {
      newObjectMap = _.omit(selectorSettings.objectMap, 'roleLabels');
    }

    // Selector parameters based on filter and config
    const selector = {
      scopeItems,
      filterItems,
      objects: Object.values(newObjectMap),
      categories: Object.entries(calculatedFilterMap).map(([categoryKey, value]) => ({
        categoryKey,
        value: value.value || value,
        object: value.object,
        scope: value.scope,
        statics: value.statics,
      })),
      facets: Object.keys(calculatedFacetMap),
      partials: Object.keys(calculatedFacetMap),
      statics: Object.entries(calculatedStaticValues).reduce((result, [key, values]) => {
        result[key] = Object.keys(values);

        return result;
      }, {}),
      customPickers: selectorSettings.customPickers,
    };

    // Edge property
    const hrefSelectedLabel = group?.roleLabel
      ? group.roleLabel.href
      : groupItem?.versions?.pversionObj?.roleLabel?.href;

    return {
      grid,
      rowsMap,
      count,
      selector,
      resourceType,
      edgeEnabled,
      orgId,
      discoveredWorkloadsCount,
      showingDiscoveredWorkloads,
      crowdstrikeEnabled,
      includeMatches,
      selectedLabels: [{key: 'role', href: hrefSelectedLabel}],
      groupProfile,
      showActionButtons: !groupItem?.isOldVersion,
    };
  },
);
