/**
 * Copyright 2017 Illumio, Inc. All Rights Reserved.
 */
import * as PropTypes from 'prop-types';
import {Component, createElement} from 'react';
import {mixThemeWithProps} from '@css-modules-theme/react';
import StickyContainer, {StickyContext} from './StickyContainer';
import styles from './StickyShadow.css';

export {StickyContainer};

export default class Sticky extends Component {
  static propTypes = {
    // Sticky element can mimic any html element ('div', 'h2' etc) or custom component (constructor like Link, Label etc)
    // Custom components are usually functions. but can be objects if they wrapped in forwardRef
    type: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.object]),
    // Depth number from material design
    depth: PropTypes.oneOf([2, 3, 4, 6, 8, 16, 24]).isRequired,
    // Drop shadow only below element, to avoid overlapping shadows of adjacent elements if there are several stickies in a row
    shadowDown: PropTypes.bool,
    // By default shadow appear/disappear animated only after 100ms to allow immediate appearance in case history scroll is being restored
    // If you want it to be animated even on initial load, like in global Header, set alwaysAnimate to true
    alwaysAnimate: PropTypes.bool,
    // Whether stickiness is possible or not, for instance sometimes it should be turned off if container is disabled
    disabled: PropTypes.bool,
    // By default offset will be taken from StickyContainer through context. This property will override it
    offset: PropTypes.string,
    // Any react content
    children: PropTypes.node,
  };

  static defaultProps = {
    type: 'div',
    disabled: false,
    shadowDown: false,
    alwaysAnimate: false,
  };

  static contextType = StickyContext;

  constructor(props, context) {
    super(props, context);

    this.state = {shadow: false, animate: props.alwaysAnimate};
  }

  componentDidMount() {
    this.context.checkIn(this);

    if (!this.props.alwaysAnimate) {
      this.animateTimeout = setTimeout(() => {
        this.setState({animate: true});
      }, 100);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.animateTimeout);
    this.context.checkOut(this);
  }

  setRatio(isIntersecting, ratio) {
    const shadow = !isIntersecting && !ratio;

    if (shadow !== this.state.shadow) {
      this.setState({shadow});
    }
  }

  render() {
    const {
      children,
      depth,
      shadowDown,
      style,
      theme,
      disabled,
      alwaysAnimate,
      type,
      typeTheme,
      typeThemeClass,
      offset = this.context.offset,
      ...props
    } = mixThemeWithProps(styles, this.props);
    const {shadow, animate} = this.state;

    let stickyClassName;

    if (disabled) {
      props.style = style;
      stickyClassName = theme[`shadowDepth${depth}z`];
    } else {
      let shadowClass = `shadowDepth${depth}`;

      if (!shadow) {
        shadowClass += 'z';
      } else if (shadowDown) {
        shadowClass += 'down';
      }

      props.style = {...style, top: offset};
      stickyClassName = `${theme.sticky} ${theme[shadowClass]}`;

      if (animate) {
        stickyClassName += ` ${theme.animated}`;
      }
    }

    if (typeof type === 'string') {
      // If type is simple html element (string like 'div', 'h2' etc.), just create className from theme
      props.className = stickyClassName;
    } else if (typeTheme && typeThemeClass) {
      // If type is a custom component, and theme for it is specified, then add sticky theme to it
      props.theme = {...typeTheme, [typeThemeClass]: `${typeTheme[typeThemeClass]} ${stickyClassName}`};
      // And say to not cache composed theme in that component, since we generate injectTheme here on every render
      props.themeNoCache = true;
    }

    return createElement(type, props, children);
  }
}
