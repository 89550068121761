/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import type {ComponentPropsWithoutRef} from 'react';
import cx from 'classnames';
import {mixThemeWithProps, type ThemeProps} from '@css-modules-theme/react';
import {getTid} from 'utils/tid';
import {Icon} from 'components';
import styles from './Card.css';
import type {IconProps} from 'components/Icon/Icon';
import type {ReactStrictNode} from 'utils/types';

export type CardProps = ThemeProps &
  ComponentPropsWithoutRef<'div'> & {
    children: ReactStrictNode;
    withBorder?: boolean;
    tid?: string;
    showCloseIcon?: boolean;
    onClose?: IconProps['onClick'];
    color?: 'primary' | 'secondary';
  };

export default function Card(props: CardProps): JSX.Element {
  const {
    children,
    theme,
    tid,
    color = 'primary',
    withBorder = false,
    showCloseIcon = false,
    onClose,
    ...elementProps
  } = mixThemeWithProps(styles, props);

  // Default to primary style.
  let baseClass = theme.primary;

  if (color === 'secondary') {
    baseClass = theme.secondary;
  }

  elementProps['data-tid'] = getTid('comp-card', tid);
  elementProps.className = cx(baseClass, {[theme.withBorder]: withBorder, [theme.withCloseIcon]: showCloseIcon});

  return (
    <div {...elementProps}>
      {children}
      {showCloseIcon && <Icon name="close" onClick={onClose} theme={theme} themePrefix="close-" />}
    </div>
  );
}
