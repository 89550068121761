/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import cx from 'classnames';
import * as PropTypes from 'prop-types';
import {Icon} from 'components';
import {createRef, PureComponent} from 'react';
import type {RefObject, MouseEvent, KeyboardEvent} from 'react';
import {composeThemeFromProps, type ThemeProps} from '@css-modules-theme/react';
import styles from './OptionItem.css';
import {tidUtils} from 'utils';

const defaultSelectorTid = 'comp-field-selector';

type OptionItemProps = {
  item: {[item: string]: string};
  onChange: (evt: MouseEvent, item: OptionItemProps['item']) => void;
  onKeyDown: (evt: KeyboardEvent, item: OptionItemProps['item']) => void;
  labelIsActive?: boolean;
  tid: string;
} & ThemeProps &
  typeof OptionItem.defaultProps;

export default class OptionItem extends PureComponent<OptionItemProps> {
  itemElement: RefObject<HTMLLIElement>;
  static propTypes = {
    item: PropTypes.object,
    onChange: PropTypes.func,
    onKeyDown: PropTypes.func,
    // Determine which selection is labeIsActive
    labelIsActive: PropTypes.bool,
    tid: PropTypes.string,
  };

  static defaultProps = {
    labelIsActive: false,
  };

  constructor(props: OptionItemProps) {
    super(props);
    this.itemElement = createRef();

    this.handleItemOnChange = this.handleItemOnChange.bind(this);
    this.handleOnMouseMove = this.handleOnMouseMove.bind(this);
    this.handleOnKeyDown = this.handleOnKeyDown.bind(this);
  }

  handleItemOnChange(evt: MouseEvent): void {
    this.props.onChange(evt, this.props.item);
  }

  handleOnKeyDown(evt: KeyboardEvent): void {
    this.props.onKeyDown(evt, this.props.item);
  }

  handleOnMouseMove(): void {
    const {current} = this.itemElement;

    if (current) {
      current.focus();
    }
  }

  render(): JSX.Element {
    const {labelIsActive, item, tid} = this.props;
    const theme = composeThemeFromProps(styles, this.props);

    return (
      <li
        ref={this.itemElement}
        className={theme.options}
        data-tid={tidUtils.getTid(defaultSelectorTid, tid ? `list-${tid}` : `${item.name}-${item.value}`)}
        onClick={this.handleItemOnChange}
        onKeyDown={this.handleOnKeyDown}
        onMouseMove={this.handleOnMouseMove}
        tabIndex={0 /* use to allow onMouseMove focus */}
      >
        <div className={labelIsActive ? theme.checkShow : theme.checkHide}>
          <Icon name="check" />
        </div>

        <div>
          {/* label component */}
          <div className={theme.dropdownLabel}>{item.label}</div>
          {/* subLabel component */}
          {typeof item.subLabel === 'string' && (
            <div className={cx(theme.dropdownLabel, theme.optionDescription)}>{item.subLabel}</div>
          )}
        </div>
      </li>
    );
  }
}
