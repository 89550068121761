/**
 * Copyright 2014 Illumio, Inc. All Rights Reserved.
 */
import {StrictMode} from 'react';
import ReactDOM from 'react-dom';
import {RouterProvider} from 'react-router5';
import {Provider} from 'react-redux';
import {SizeWatcherProvider} from 'react-size-watcher';
import {App} from 'containers';
import rootSaga from './sagas';
import configureStore from './configureStore';
import Prefetcher from './router/Prefetcher';
import {processRoutes} from './router/routes';
import configureRouter from './router/configureRouter';
import {getRoleNames, isSuperclusterMember} from 'containers/User/UserState';
import {setRoleNames, setHostIsSupercluserMember} from 'api/apiUtils';

const render = Element => ReactDOM.render(Element, document.querySelector('#root')); // eslint-disable-line react/no-render-return-value

export const init = ({routes, containers, reducers}) => {
  const {routesList, routesMap, defaultRoute} = processRoutes([routes], null, containers);

  const initState = {};

  //Scroll positions are preserved using histoty replaceState method and are restored in prefetcher
  //Set scrollRestoration to manual to avoid any conflict with browser scrollRestoration implementation
  if ('scrollRestoration' in history) {
    history.scrollRestoration = 'manual';
  }

  const router = configureRouter(routesList, routesMap, defaultRoute);
  const Store = configureStore(router);
  const store = new Store(reducers, initState);

  store.subscribe(() => {
    const state = store.getState();

    setRoleNames(getRoleNames(state));
    setHostIsSupercluserMember(isSuperclusterMember(state));
  });

  const prefetcher = new Prefetcher({store, router, routesList, routesMap});

  router.useMiddleware(() => prefetcher.routeMiddleware);
  store.prefetcher = prefetcher;
  store.runSaga(rootSaga, store);

  if (!__DEV__) {
    // In production just render once on router start
    router.start(() => {
      render(
        <Provider store={store}>
          <SizeWatcherProvider>
            <RouterProvider router={router}>
              <App />
            </RouterProvider>
          </SizeWatcherProvider>
        </Provider>,
      );
    });
  } else {
    const RedBox = require('redbox-react').default; // eslint-disable-line import/no-extraneous-dependencies
    /*const {AppContainer} = require('react-hot-loader');*/

    // Unlike prod wrap App in AppContainer from hot-loader with RedBox
    const compose = App => (
      <StrictMode>
        <Provider store={store}>
          <SizeWatcherProvider>
            <RouterProvider router={router}>
              {/*<AppContainer errorReporter={RedBox}>*/}
              <App />
              {/*</AppContainer>*/}
            </RouterProvider>
          </SizeWatcherProvider>
        </Provider>
      </StrictMode>
    );

    router.start(() => {
      try {
        render(compose(App));
      } catch (error) {
        requestAnimationFrame(() => render(<RedBox error={error} />));
      }
    });
  }
};
