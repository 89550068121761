/**
 * Copyright 2021 Illumio, Inc. All Rights Reserved.
 */
import cx from 'classnames';
import {useEffect, useCallback} from 'react';
import SelectedResource from './SelectedResource';
import {useFilialPiety, VALUEPANEL_ID} from 'containers/Selector/SelectorUtils';
import styleUtils from 'utils.css';

export default function ValuePanel(props) {
  const {
    theme,
    saveRef,
    values,
    errors,
    disabled,
    insensitive,
    allResources,
    onSelectedValueClick,
    onRemove,
    onSetHighlighted,
    onMouseLeave,
    registerHandlers,
  } = props;
  const {saveChildRef, registerChildHandlers, setHighlightedChild, resetHighlightedChild, keyDown} = useFilialPiety();

  const saveRefCallback = useCallback(element => saveRef(VALUEPANEL_ID, element), [saveRef]);

  useEffect(() => {
    const unregister = registerHandlers(VALUEPANEL_ID, {setHighlightedChild, resetHighlightedChild, keyDown});

    return () => unregister(); //Unregister
  }, [registerHandlers, setHighlightedChild, resetHighlightedChild, keyDown]);

  // Called when selectedResource yield highlight,
  // The handler calls its parent highlightLeave if it cannot find next child to pass highlighted state

  return (
    <div ref={saveRefCallback} className={theme.valuePanel}>
      {Array.from(values, ([id, valuesInResource], index) => (
        <div key={id} className={cx(styleUtils.gapInline, styleUtils.gapHorizontal)}>
          {index > 0 && allResources[id].selectedProps?.resourceJoiner && (
            <div className={theme.joiner}>{allResources[id].selectedProps.resourceJoiner}</div>
          )}
          <SelectedResource
            {...allResources[id]}
            theme={theme}
            saveRef={saveChildRef}
            disabled={disabled}
            insensitive={insensitive}
            values={valuesInResource}
            errors={errors[id]}
            registerHandlers={registerChildHandlers}
            onSelectedValueClick={onSelectedValueClick}
            onMouseLeave={onMouseLeave}
            onRemove={onRemove}
            onSetHighlighted={onSetHighlighted}
          />
        </div>
      ))}
    </div>
  );
}
