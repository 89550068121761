/**
 * Copyright 2021 Illumio, Inc. All Rights Reserved.
 */
import {createElement, useEffect, useCallback, useContext, useRef} from 'react';
import FocusLock from 'react-focus-lock';
import {useDeepCompareMemo} from 'utils/react';
import {AppContext} from 'containers/App/AppUtils';

export default function ContainerResource(props) {
  const {
    resource: {
      id,
      container,
      containerProps,
      enableFocusLock,
      onReturnFocusToInput,
      container: {prefetch},
    },
    query,
    selectIntoResource,
    onOptionSelect,
    onBack,
    focusLockGroupName,
    initialLoadParams,
  } = props;

  const {
    fetcher,
    store: {prefetcher},
  } = useContext(AppContext);
  const routeOptions = useDeepCompareMemo(prefetcher.options, [prefetcher.options]);
  const fetchTask = useRef(null);

  const {onInitialLoadDone, onInitialLoadReject} = useDeepCompareMemo(initialLoadParams ?? {});

  const handleDone = useCallback(
    (evt, value) => {
      onOptionSelect(evt, {resourceId: selectIntoResource ?? id, value});
      onBack(evt);
    },
    [selectIntoResource, id, onOptionSelect, onBack],
  );

  useEffect(() => {
    if (!prefetch) {
      return;
    }

    fetchTask.current = fetcher.fork(prefetch, routeOptions);

    return () => {
      if (fetchTask.current) {
        fetcher.cancel(fetchTask.current);
        fetchTask.current = null;
      }
    };
  }, [prefetch, fetcher, routeOptions]);

  const content = createElement(container, {
    onInitialLoadDone,
    onInitialLoadReject,
    onDone: handleDone,
    onCancel: onBack,
    ...(typeof containerProps === 'function' ? containerProps(query) : containerProps),
  });

  if (enableFocusLock) {
    return (
      <FocusLock group={focusLockGroupName} onDeactivation={onReturnFocusToInput}>
        {content}
      </FocusLock>
    );
  }

  return content;
}
