/**
 * Copyright 2016 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from 'intl';
import {Component} from 'react';
import {connect} from 'react-redux';
import {webStorageUtils} from 'utils';
import {AppContext} from 'containers/App/AppUtils';
import {Icon, MenuItem, MenuDelimiter} from 'components';
import {HeaderMenu} from 'containers';
import {getMainMenu} from './MainMenuState';
import styles from './MainMenu.css';
import {isAPIAvailable} from 'api/apiUtils';

const tid = 'comp-navbar-';

@connect(getMainMenu)
export default class MainMenu extends Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);

    this.renderContent = this.renderContent.bind(this);
    this.handleMapSelect = this.handleMapSelect.bind(this);
    this.handleAppMapSelect = this.handleAppMapSelect.bind(this);
    this.handleVulnerabilityMapSelect = this.handleVulnerabilityMapSelect.bind(this);
  }

  handleMapSelect(evt) {
    const mapRoute = _.get(webStorageUtils.getSessionItem('MapPageStore'), 'mapRoute', {});

    let to;
    let params;

    if (mapRoute.prevtype) {
      to = 'map.prevlevel';
      params = mapRoute;
    } else if (mapRoute.type) {
      to = 'map.level';
      params = mapRoute;
    } else {
      to = 'map';
    }

    this.context.navigate({evt, to, params});
  }

  handleAppMapSelect(evt, version) {
    const mapRoute = _.get(webStorageUtils.getSessionItem('MapPageStore'), 'appMapRoute', {});

    let to;
    let params;

    if (version !== 'vulnerability') {
      webStorageUtils.setSessionItem('MapPageStore', {appMapVersion: 'policy'});
    }

    webStorageUtils.setSessionItem('app_group_list', 'recents');

    if (mapRoute.prevtype) {
      to = 'appMap.prevlevel';
      params = mapRoute;
    } else if (mapRoute.type) {
      to = 'appMap.level';
      params = mapRoute;
    } else {
      to = 'appMap';
    }

    this.context.navigate({evt, to, params});
  }

  handleVulnerabilityMapSelect(evt) {
    webStorageUtils.setSessionItem('MapPageStore', {appMapVersion: 'vulnerability'});
    this.handleAppMapSelect(evt, 'vulnerability');
  }

  isSettingsEnabled() {
    const {
      eventsConfigIsEnabled,
      securitySettingsIsEnabled,
      ikeCertificateIsEnabled,
      authenticationSettingsIsEnabled,
      policySettingsIsEnabled,
      offlineTimersIsEnabled,
      flowCollectionIsEnabled,
      coreServicesEnabled,
      isEdge,
      apiKeySettingsIsEnabled,
    } = this.props;

    return (
      isEdge ||
      [
        eventsConfigIsEnabled,
        securitySettingsIsEnabled,
        ikeCertificateIsEnabled,
        authenticationSettingsIsEnabled,
        policySettingsIsEnabled,
        offlineTimersIsEnabled,
        flowCollectionIsEnabled,
        coreServicesEnabled,
        apiKeySettingsIsEnabled,
      ].some(isEnabled => isEnabled)
    );
  }

  infrastructureIsEnabled() {
    const {loadBalancerIsEnabled, containerClustersIsEnabled, secureGatewayIsEnabled, networkIsEnabled, switchEnabled} =
      this.props;

    return [
      loadBalancerIsEnabled,
      containerClustersIsEnabled,
      secureGatewayIsEnabled,
      networkIsEnabled,
      switchEnabled,
    ].some(isEnabled => isEnabled);
  }

  // Pass content as function to prevent creating react elements at the beginning, create only when dropdown is open
  // New tags should exist for 3 months after their release
  // Preview tags should exist as long as the feature is in preview mode
  renderContent() {
    const {
      isUserOwner,
      userIsWithReducedScope,
      vens,
      explorerIsEnabled,
      supportUrl,
      trafficIsEnabled,
      isNetworkEnforcementNodeEnabled,
      isReportingEnabled,
      userIsHealthEnabled,
      illuminationMapIsEnabled,
      loadBalancerIsEnabled,
      containerClustersIsEnabled,
      secureGatewayIsEnabled,
      networkIsEnabled,
      switchEnabled,
      eventsIsEnabled,
      segmentationIsTemplatesEnabled,
      pairingProfilesIsEnabled,
      supportReportsIsEnabled,
      pceSupportBundlesIsEnabled,
      eventsConfigIsEnabled,
      securitySettingsIsEnabled,
      ikeCertificateIsEnabled,
      authenticationSettingsIsEnabled,
      accessRestrictionIsEnabled,
      policySettingsIsEnabled,
      offlineTimersIsEnabled,
      venLibraryIsEnabled,
      flowCollectionIsEnabled,
      illuminationApiIsEnabled,
      isCoreServicesEnabled,
      coreServicesSettings,
      isEdge,
      isCrowdstrike,
      apiKeySettingsIsEnabled,
      enforcementBoundaryEnabled,
    } = this.props;

    return (
      <>
        {isEdge && <MenuItem link="landing" text={intl('Common.Groups')} data-tid={`${tid}pages-dashboard`} />}
        {illuminationApiIsEnabled && illuminationMapIsEnabled && (
          <MenuItem
            text={intl('Common.IlluminationMap')}
            onSelect={this.handleMapSelect}
            data-tid={`${tid}pages-map`}
          />
        )}
        {illuminationApiIsEnabled &&
          (trafficIsEnabled ? (
            <MenuItem text={intl('Common.AppGroups')} data-tid={`${tid}sub-app-map`}>
              <MenuItem
                text={intl('Common.AppGroupMap')}
                onSelect={this.handleAppMapSelect}
                data-tid={`${tid}pages-app-group-map`}
              />
              <MenuItem link="appGroups" text={intl('Common.AppGroupsList')} data-tid={`${tid}pages-app-groups`} />
            </MenuItem>
          ) : (
            <MenuItem link="appGroups" text={intl('Common.AppGroups')} data-tid={`${tid}pages-app-groups`} />
          ))}
        {explorerIsEnabled && (
          <MenuItem link="explorer" text={intl('Common.Explorer')} data-tid={`${tid}pages-traffic-analyzer`} />
        )}
        {!isEdge && isAPIAvailable('reports.get_collection') && isReportingEnabled && (
          <MenuItem
            link="reporting.downloads.list"
            text={intl('Common.Reports')}
            badge={isEdge ? 'preview' : null}
            data-tid={`${tid}pages-reporting`}
          />
        )}
        {trafficIsEnabled && illuminationApiIsEnabled && (
          <MenuItem
            link="policygenerator"
            text={intl('PolicyGenerator.PolicyGenerator')}
            data-tid={`${tid}pages-policygenerator`}
          />
        )}
        {!isEdge && enforcementBoundaryEnabled && (
          <MenuItem
            link="boundaries.list"
            data-tid={`${tid}pages-boundaries-enforcement-rules`}
            text={intl('Workloads.EnforcementBoundaries')}
          />
        )}
        {!isEdge && !userIsWithReducedScope && (
          <MenuItem link="rulesets" text={intl('Rulesets.AndRules')} data-tid={`${tid}sub-rulesets`}>
            <MenuItem link="rulesets" data-tid={`${tid}pages-rulesets`} text={intl('Common.Rulesets')} />
            <MenuItem
              text={intl('Common.RuleSearch')}
              link={{to: 'ruleSearch', params: {pversion: 'draft'}}}
              data-tid={`${tid}pages-rulesearch`}
            />
          </MenuItem>
        )}

        {isEdge && (
          <>
            {!isCrowdstrike && (
              <MenuItem
                link={{to: 'outboundpolicy'}}
                text={intl('Policy.Organization')}
                data-tid={`${tid}pages-outbound-policy`}
              />
            )}
            <MenuDelimiter />
          </>
        )}

        <MenuItem
          text={isCrowdstrike ? intl('Common.Workloads') : intl('Common.WorkloadManagement')}
          data-tid={`${tid}sub-workloadmanagement`}
        >
          <MenuItem link="workloads.list" text={intl('Common.Workloads')} data-tid={`${tid}pages-workloads`} />
          {!isEdge && (
            <MenuItem
              link="workloads.containers"
              text={intl('Common.ContainerWorkloads')}
              data-tid={`${tid}pages-container-workloads`}
            />
          )}
          {!isCrowdstrike && (
            <MenuItem link="workloads.vens" text={intl('Common.VENS')} data-tid={`${tid}pages-vens`} />
          )}
          {!isEdge && pairingProfilesIsEnabled && (
            <MenuItem
              link="pairingProfiles"
              text={intl('PairingProfiles.Profiles')}
              data-tid={`${tid}pages-pairingprofiles`}
            />
          )}
          {venLibraryIsEnabled && vens?.length > 0 && !isCrowdstrike && (
            <MenuItem link="venLibrary" text={intl('VEN.Library')} data-tid={`${tid}pages-vens-library`} />
          )}
        </MenuItem>

        {!isEdge && <MenuDelimiter />}

        <MenuItem text="Policy Objects" data-tid={`${tid}sub-policy`}>
          <MenuItem link="services" text={intl('Common.Services')} data-tid={`${tid}pages-services`} />
          <MenuItem
            link="iplists"
            text={intl(!isEdge ? 'Common.IPLists' : 'IPLists.Mixin.Ranges')}
            data-tid={`${tid}pages-iplists`}
          />
          {!isEdge && <MenuItem link="labels" text={intl('Common.Labels')} data-tid={`${tid}pages-labels`} />}
          <MenuItem link="userGroups" text={intl('Common.UserGroups')} data-tid={`${tid}pages-usergroups`} />
          {!isEdge && (
            <>
              <MenuItem link="labelGroups" text={intl('Labels.Groups')} data-tid={`${tid}pages-labelgroups`} />
              <MenuItem
                link="virtualServices"
                text={intl('Common.VirtualServices')}
                data-tid={`${tid}pages-virtualservices`}
              />
              <MenuItem
                link="virtualServers"
                text={intl('Common.VirtualServers')}
                data-tid={`${tid}pages-virtualserver`}
              />
              {(isEdge ? !segmentationIsTemplatesEnabled : segmentationIsTemplatesEnabled) && (
                <MenuItem
                  link="segmentationTemplates"
                  text={intl('SegmentationTemplates.SegmentationTemplates')}
                  data-tid="pages-segmentationtemplates"
                />
              )}
            </>
          )}
        </MenuItem>

        {!isEdge && this.infrastructureIsEnabled() && (
          <MenuItem text={intl('Menu.Infrastructure')} data-tid={`${tid}sub-network`}>
            {isAPIAvailable('settings_core_services.update') && coreServicesSettings === 'enabled' && (
              <MenuItem
                link="coreservices.list"
                text={intl('CoreServices.CoreServices')}
                data-tid={`${tid}pages-coreservices`}
              />
            )}
            {loadBalancerIsEnabled && (
              <MenuItem
                link="loadBalancers.list"
                text={intl('Menu.LoadBalancers')}
                data-tid={`${tid}pages-loadbalancers`}
              />
            )}
            {containerClustersIsEnabled && (
              <MenuItem
                link="containerClusters.list"
                text={intl('Menu.ContainerClusters', {multiple: true})}
                data-tid={`${tid}pages-containerClusters`}
              />
            )}
            {secureGatewayIsEnabled && (
              <MenuItem
                link="secureGateways.list"
                text={intl('SecureGateway.SecureConnect')}
                data-tid={`${tid}pages-secureGateways`}
              />
            )}
            {networkIsEnabled && (
              <MenuItem
                link="networks.list"
                text={intl('Menu.Networks', {multiple: true})}
                data-tid={`${tid}pages-networks`}
              />
            )}
            {switchEnabled && isNetworkEnforcementNodeEnabled && (
              <MenuItem link="switches" text={intl('Menu.Switches')} data-tid={`${tid}pages-switches`} />
            )}
            {!isEdge && (
              <MenuItem
                link={{href: 'https://lumos1.illum.io', target: '_blank'}}
                text={intl('Common.CloudSecure')}
                data-tid={`${tid}cloudsecure`}
              />
            )}
          </MenuItem>
        )}

        <MenuDelimiter />

        {!userIsWithReducedScope && (
          <MenuItem text={intl('Common.Provision')} data-tid={`${tid}sub-provision`}>
            <MenuItem link="pending" text={intl('Provision.DraftChanges')} data-tid={`${tid}pages-provisioning`} />
            <MenuItem
              link="versions"
              text={intl('Provision.Versions', {multiple: true})}
              data-tid={`${tid}pages-versions`}
            />
          </MenuItem>
        )}

        <MenuDelimiter />

        {isUserOwner && (
          <MenuItem text={intl('RBAC.AccessManagement')} data-tid={`${tid}sub-rbac`}>
            <MenuItem link="rbac.roles.global" text={intl('RBAC.GlobalRoles')} data-tid={`${tid}pages-rbac-global`} />
            {!isEdge && (
              <MenuItem link="rbac.roles.scope" text={intl('Common.Scopes')} data-tid={`${tid}pages-rbac-scope`} />
            )}
            {!isEdge && <MenuDelimiter />}
            <MenuItem
              link="rbac.users.external.groups"
              text={intl('Users.ExternalGroups')}
              data-tid={`${tid}pages-groups`}
            />
            <MenuItem
              link="rbac.users.external"
              text={intl('Users.ExternalUsers')}
              data-tid={`${tid}pages-users-external`}
              isActive={['rbac.users.external.list', 'rbac.users.external.detail']}
            />
            <MenuItem link="rbac.users.local" text={intl('Users.LocalUsers')} data-tid={`${tid}pages-users`} />
            {!isEdge && (
              <MenuItem
                link="rbac.serviceAccounts.list"
                text={intl('Common.ServiceAccounts')}
                data-tid={`${tid}pages-serviceaccount`}
              />
            )}
            <MenuDelimiter />
            <MenuItem
              link="rbac.users.activity"
              text={intl('RBAC.UsersActivity.UserActivity')}
              data-tid={`${tid}useractivity`}
            />
            <MenuDelimiter />
            {authenticationSettingsIsEnabled && (
              <MenuItem
                link="authenticationSettings"
                text={intl('Common.Authentication')}
                data-tid={`${tid}pages-authentication-settings`}
              />
            )}
            {accessRestrictionIsEnabled && (
              <MenuItem
                link="accessRestrictions.list"
                text={intl('AccessRestriction.AccessRestrictionTitle')}
                data-tid={`${tid}pages-access-restriction`}
              />
            )}
          </MenuItem>
        )}

        {this.isSettingsEnabled() && (
          <MenuItem text={intl('Common.Settings')} data-tid={`${tid}sub-settings`}>
            <MenuItem
              link="corporatepublicips"
              text={intl('GlobalNetwork.GlobalIPs')}
              data-tid={`${tid}pages-global-ips`}
            />
            {isEdge && !isCrowdstrike && (
              <MenuItem
                link={{to: 'ikecertificates'}}
                text={intl('Settings.IKECertificates')}
                data-tid={`${tid}pages-ikecertificates`}
              />
            )}
            {!isEdge && userIsHealthEnabled && eventsConfigIsEnabled ? (
              <MenuItem link="events.config" text={intl('Common.EventSettings')} data-tid={`${tid}pages-eventconfig`} />
            ) : null}
            {flowCollectionIsEnabled && !isEdge && (
              <MenuItem
                link="flowCollectionFilters"
                text={intl('Common.FlowCollectionFilters')}
                data-tid={`${tid}pages-flow-collection`}
              />
            )}
            {!isEdge && securitySettingsIsEnabled && (
              <MenuItem
                link={{to: 'securitysettings'}}
                text={intl('Common.Security')}
                data-tid={`${tid}pages-securitysettings`}
              />
            )}
            {!isEdge && isAPIAvailable('settings_core_services.update') && isCoreServicesEnabled && (
              <MenuItem
                link={{to: 'coreservicesettings'}}
                text={intl('CoreServices.CoreServices')}
                data-tid={`${tid}pages-coreservicessettings`}
              />
            )}
            {!isEdge && isAPIAvailable('essential_service_rules.get') && (
              <MenuItem
                link="essentialservicerules"
                text={intl('Settings.EssentialServiceRules')}
                data-tid={`${tid}pages-essentialservicerules`}
              />
            )}
            {!isEdge && ikeCertificateIsEnabled && (
              <MenuItem
                link={{to: 'ikecertificates'}}
                text={intl('Settings.IKECertificates')}
                data-tid={`${tid}pages-ikecertificates`}
              />
            )}
            {!isEdge && isAPIAvailable('trusted_proxy_ips.get') && (
              <MenuItem
                link={{to: 'trustedproxy'}}
                text={intl('TrustedProxy.TrustedProxyIp')}
                data-tid={`${tid}pages-trustedproxylist`}
              />
            )}
            {(isEdge && isUserOwner) || policySettingsIsEnabled ? (
              <MenuItem link="policySettings" text={intl('Policy.Settings')} data-tid={`${tid}policy-settings`} />
            ) : null}
            {apiKeySettingsIsEnabled && !isEdge && (
              <MenuItem
                link="apiKeySettings"
                text={intl('APIKeySettings.APIKeySettings')}
                data-tid={`${tid}apikey-settings`}
              />
            )}
            {offlineTimersIsEnabled && !isEdge && (
              <MenuItem
                link="offlineTimers"
                text={intl('OfflineTimers.OfflineTimers')}
                data-tid={`${tid}offline-timers`}
              />
            )}
          </MenuItem>
        )}
        <MenuDelimiter />

        {!isEdge && <MenuDelimiter />}

        <MenuItem text={intl('Common.Troubleshooting')} data-tid={`${tid}sub-troubleshooting`}>
          {!isCrowdstrike && explorerIsEnabled && (
            <MenuItem
              link="blockedTraffic"
              text={intl('BlockedTraffic.Name')}
              data-tid={`${tid}pages-blockedtraffic`}
            />
          )}

          {eventsIsEnabled && (
            <MenuItem
              link="events"
              text={intl('Common.Events')}
              data-tid={`${tid}pages-orgevents`}
              isActive={['events.list', 'events.detail']}
            />
          )}
          <MenuItem link="reports" text={intl('Exports.PageName')} data-tid={`${tid}pages-reports`} />
          {!isCrowdstrike && supportReportsIsEnabled && (
            <MenuItem
              link="supportBundles.ven"
              text={intl('SupportReports.PageName')}
              data-tid={`${tid}pages-vensupportbundle`}
            />
          )}
          {pceSupportBundlesIsEnabled && (
            <MenuItem
              link="supportBundles.pce"
              text={intl('PCESupportBundles.PageName')}
              data-tid={`${tid}pages-pcesupportbundle`}
            />
          )}
          {!userIsWithReducedScope && !isEdge && (
            <MenuItem link="connectivity" text={intl('Policy.Check')} data-tid={`${tid}pages-connectivity`} />
          )}
          <MenuItem
            link={{params: {showVersion: true}, replace: true}}
            text={intl('Common.ProductVersion')}
            data-tid={`${tid}about`}
          />
        </MenuItem>

        <MenuItem
          link={{href: supportUrl, target: '_blank'}}
          text={intl('Common.Support')}
          data-tid={`${tid}pages-support`}
        />

        {__DEV__ && (
          <>
            <MenuDelimiter />

            <MenuItem link="components.list" text="Components" />
            <MenuItem link="components.forms" text="Components Forms" />
            <MenuItem text="Page Transitions">
              <MenuItem link="testloader1.testloader2.testloader3" text="Three subroutes, all prefetched by router" />
              <MenuItem text="Three subroutes, first handles sub two prefetch on entry">
                <MenuItem link="testloader1h.testloader2.testloader3" text="First two" />
                <MenuItem link="testloader1h.testloader2alt.testloader3alt" text="Second two" />
              </MenuItem>
              <MenuItem text="Three subroutes, first handles sub two prefetch on child transition">
                <MenuItem link="testloader1ht.testloader2.testloader3" text="First two" />
                <MenuItem link="testloader1ht.testloader2alt.testloader3alt" text="Second two" />
              </MenuItem>
              <MenuItem text="Three subroutes, first handles sub two prefetch always">
                <MenuItem link="testloader1ha.testloader2.testloader3" text="First two" />
                <MenuItem link="testloader1ha.testloader2.testloader3throw" text="First two, second throws" />
                <MenuItem link="testloader1ha.testloader2alt.testloader3alt" text="Second two" />
              </MenuItem>
            </MenuItem>
          </>
        )}
      </>
    );
  }

  render() {
    return (
      <HeaderMenu icon={<Icon name="menu" />} theme={styles} tid="pages">
        {this.renderContent}
      </HeaderMenu>
    );
  }
}
