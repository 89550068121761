/**
 * Copyright 2016 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import apiSaga from 'api/apiSaga';
import {all, call, select, put} from 'redux-saga/effects';
import {getSelectorSettings} from './WorkloadListConfig';
import {fetchHealth} from 'containers/Health/HealthSaga';
import {fetchLabels} from 'containers/Label/List/LabelListSaga';
import {areVulnerabilitiesEnabled, isEdge} from 'containers/App/AppState';
import {
  getWorkloads,
  getWorkloadsCount,
  getWorkloadsIgnoreScope,
  getGridSettings as getWorkloadsGrid,
  getCalculatedStaticValues,
  getCalculatedRouteFilters,
  getWorkloadsExternalDataSetCount,
  getDiscoveredWorkloadsCount,
} from './WorkloadListState';
import gridSaga from 'components/Grid/GridSaga';
import {fetchValidScopeLabels} from 'containers/Selectors/SelectorSaga';
import {getUrlScopeValue} from 'components/Grid/GridSelectors';
import {externalDataSetPartners} from 'containers/Workload/WorkloadUtils';
import {getNoLabelHref} from 'containers/Selectors/SelectorUtils';
import {getOrgId} from 'containers/User/UserState';

export function* fetchWorkloads({ignoreScope = false, containerCluster, filter, scope, force = false} = {}) {
  const staticValues = yield select(getCalculatedStaticValues);
  const vulnerabilitiesEnabled = yield select(areVulnerabilitiesEnabled);
  const query = {representation: vulnerabilitiesEnabled ? 'workload_labels_vulnerabilities' : 'workload_labels'};
  // When vulnerabilities are enabled, the VES score must be calculated for the entire collection, so increase the timeout.
  const timeout = 60_000 * (vulnerabilitiesEnabled ? 5 : 1.5);

  if (containerCluster) {
    query.container_clusters = JSON.stringify([{href: containerCluster}]);
  } else if (scope) {
    // no scope for detail page
    query.xxxlabels = [scope.scope.map(obj => obj.href)];
  }

  if (filter) {
    const containerClusterQuery = {};
    const selectorSettings = yield select(getSelectorSettings);
    let activePolicySyncFlag = false;
    let offlineConnectivityFlag = false;

    for (const [name, [value]] of Object.entries(filter)) {
      if (staticValues.hasOwnProperty(name)) {
        // If there is more than one parameter to make up the query
        if (Array.isArray(staticValues[name][value])) {
          staticValues[name][value].forEach(filter => {
            if (filter.name === 'online' && filter.value === 'false') {
              offlineConnectivityFlag = true;
            }

            query[filter.name] = filter.value;
          });
        } else if (staticValues[name][value]) {
          if (name === 'policy_health' && value === 'Active') {
            activePolicySyncFlag = true;
          }

          query[name] = staticValues[name][value];
        } else {
          query[name] = value;
        }
      } else if (selectorSettings.scopeMap.hasOwnProperty(name)) {
        // labels are the only supported type at this moment
        if (ignoreScope || !scope) {
          // otherwise xxxlabels are already generated
          if (query.xxxlabels && Array.isArray(query.xxxlabels[0])) {
            query.xxxlabels[0].push(value.href);
          } else {
            query.xxxlabels = [[value.href]];
          }
        }
      } else if (name === 'containerCluster') {
        // this query parameter is for container clusters
        containerClusterQuery.name = value;
      } else {
        query[(value.includes('*') && selectorSettings.facetMap[name].wildcardQueryParam) || name] = value;
      }
    }

    if (activePolicySyncFlag && !offlineConnectivityFlag) {
      query.online = true;
    }

    if (!_.isEmpty(containerClusterQuery)) {
      const {data} = yield call(apiSaga, 'container_clusters.get_collection', {
        query: containerClusterQuery,
        cache: !force,
      });

      query.container_clusters = JSON.stringify(data.map(containerCluster => containerCluster.href));
    }
  }

  const [collection, error, suspended, warning, staticMode] = yield all([
    call(apiSaga, 'workloads.get_collection', {
      query,
      cache: !force,
      timeout,
      // Do not compare result automatically, because 'agent.status.last_heartbeat_on/uptime_seconds' can change often
      compareResponseOnCacheInvalidation: false,
    }),
    call(fetchWorkloadHealth, {
      status: 'error',
      container_clusters: query.container_clusters,
      labels: query.xxxlabels,
      force,
    }),
    call(fetchWorkloadHealth, {
      status: 'suspended',
      container_clusters: query.container_clusters,
      labels: query.xxxlabels,
      force,
    }),
    call(fetchWorkloadHealth, {
      status: 'warning',
      container_clusters: query.container_clusters,
      labels: query.xxxlabels,
      force,
    }),
    call(fetchWorkloadPolicyMode, {
      mode: 'static',
      container_clusters: query.container_clusters,
      labels: query.xxxlabels,
      force,
    }),
    call(fetchHealth, {force}),
    call(fetchLabels, {force}),
  ]);

  const {data: list, count} = collection;

  const countWithStatus = {...count, error, suspended, warning, staticMode};

  if (
    force ||
    list !== (yield select(getWorkloads)) ||
    !_.isEqual(countWithStatus, yield select(getWorkloadsCount)) ||
    ignoreScope !== (yield select(getWorkloadsIgnoreScope))
  ) {
    yield put({type: 'WORKLOADS_GET_LIST', data: {list, count: countWithStatus, ignoreScope}});
  }

  return {list, count: countWithStatus, ignoreScope};
}

function* fetchWorkloadHealth({status = 'error', maxResult = 0, force = false, container_clusters, labels} = {}) {
  const edgeEnabled = yield select(isEdge);

  const query = {
    policy_health: status,
    max_results: maxResult,
    container_clusters,
  };

  if (labels && edgeEnabled) {
    query.xxxlabels = labels;
  }

  return yield call(apiSaga, 'workloads.get_collection', {
    query,
    cache: !force,
    *onDone({count}) {
      return count?.matched ?? 0;
    },
  });
}

export function* fetchWorkloadPolicyMode({
  mode = 'static',
  maxResult = 0,
  force = false,
  container_clusters,
  labels,
} = {}) {
  const edgeEnabled = yield select(isEdge);

  const query = {
    security_policy_update_mode: mode,
    max_results: maxResult,
    container_clusters,
  };

  if (labels && edgeEnabled) {
    query.xxxlabels = labels;
  }

  return yield call(apiSaga, 'workloads.get_collection', {
    query,
    cache: !force,
    *onDone({count}) {
      return count?.matched ?? 0;
    },
  });
}

export function* applyPolicyWorkloads(data) {
  yield call(apiSaga, 'workloads.apply_policy', {data});
}

export function* updatePolicyState(data) {
  yield call(apiSaga, 'workloads.update', {data});
}

export function* fetchWorkloadList(route, refetch = false, options = {}) {
  const {params} = route;
  const {
    getGridSettings = getWorkloadsGrid,
    getFilters = getCalculatedRouteFilters,
    containerCluster,
    ignoreScope,
    customScope,
    customFilter,
  } = options;

  yield call(gridSaga, {
    route,
    settings: getGridSettings,
    filterMap: getFilters,
    *onSaga({filterParams}) {
      const scopeParams = yield select(getUrlScopeValue, params);
      let scope;

      if (customScope) {
        scope = customScope;
      } else {
        scope = scopeParams.isEmpty ? undefined : scopeParams.valid;
        yield call(fetchValidScopeLabels, scope);
      }

      const apiCalls = [
        call(fetchWorkloads, {
          containerCluster,
          ignoreScope,
          params,
          filter: filterParams.isEmpty ? customFilter : {...filterParams.valid, ...customFilter},
          scope,
          force: refetch,
        }),
      ];

      if (yield select(isEdge)) {
        apiCalls.push(call(fetchDiscoveredWorkloadList, {force: refetch}));
      }

      const [{list}] = yield all(apiCalls);

      return list.length;
    },
  });
}

/**
 * Request to set the total number of workloads for specific external_data_set and for workloads that are
 * discovered (no labels with specific external_data_set).
 *
 * @params (Object) - force
 * @params (String) - externalDataSet e.g. [illumio_cs_integration]
 */
export function* fetchWorkloadDiscoveredDataSet({force = false, externalDataSet} = {}) {
  yield all([call(fetchDiscoveredWorkloadList, {force}), call(fetchWorkloadExternalDataSet, {force, externalDataSet})]);
}

/**
 * Set all the discovered workloads that doesn't have labels and with specific external_data_set
 *
 * @params (Object) - force
 */
export function* fetchDiscoveredWorkloadList({force = false} = {}) {
  const orgId = yield select(getOrgId);
  // Querying for these two combinations: 1) JSON.stringify([[getNoLabelHref(orgId, ['role', 'app', 'env;, 'loc'])
  // (get no labels for all 4 scopes)
  // and 2) explicitly defining external_data_set is to get all 'discovered' workloads with no labels.
  const query = {
    labels: JSON.stringify([
      [
        getNoLabelHref(orgId, 'role'),
        getNoLabelHref(orgId, 'app'),
        getNoLabelHref(orgId, 'env'),
        getNoLabelHref(orgId, 'loc'),
      ],
    ]),
    external_data_set: externalDataSetPartners.crowdstrike,
  };

  yield call(apiSaga, 'workloads.get_collection', {
    query,
    cache: !force,
    *onDone({data: list, count}) {
      // Get all 'discovered' workloads
      if (force || count !== (yield select(getDiscoveredWorkloadsCount))) {
        yield put({type: 'DISCOVERED_WORKLOADS_GET_LIST', data: {list, count, ignoreScope: false}});
      }
    },
  });
}

/**
 * Set all the workloads counts with specific external_data_set. We can have potential others partners in the future.
 * In addition, when mixing VEN and other partner's agent, calling this method will work.
 * The count.matched will be used to determine the total amount workloads for specific external_data_set.
 *
 * @params (Object) - force
 * @params {String} - externalDataSet e.g. [illumio_cs_integration] or [some_new_partner]
 */
export function* fetchWorkloadExternalDataSet({force = false, externalDataSet} = {}) {
  const query = {
    external_data_set: externalDataSet,
  };

  yield call(apiSaga, 'workloads.get_collection', {
    query,
    cache: !force,
    *onDone({count}) {
      if (force || count !== (yield select(getWorkloadsExternalDataSetCount))) {
        // Use count.matched to get the total count when specific external_data_set='illumio_cs_integration'.
        // count.matched will be used to determine if crowdstrike (other partner) has reach maximum workloads.
        // In the future, workloads will have both crowdstrike (other partner) and VEN
        yield put({type: 'WORKLOADS_EXTERNAL_DATA_SET_GET_LIST', data: {count}});
      }
    },
  });
}
