/**
 * Copyright 2021 Illumio, Inc. All Rights Reserved.
 */
import {call, select} from 'redux-saga/effects';
import apiSaga from 'api/apiSaga';
import {RedirectError} from 'errors';
import {isEdge} from 'containers/App/AppState';

import {hrefUtils} from 'utils';

export function* fetchRulesetItem({params}, refetch = false) {
  const edgeEnabled = yield select(isEdge);

  try {
    const rulesetItem = yield call(fetchRulesetInstance, {force: refetch, id: params.id, pversion: params.pversion});

    // In Edge Redirect to the corresponding group
    if (edgeEnabled) {
      if (rulesetItem.hasOwnProperty('external_data_reference')) {
        throw new RedirectError({
          to: 'groups.view',
          params: {
            group: hrefUtils.getId(rulesetItem.external_data_reference),
            tab: 'inboundpolicy',
            pversion: params.pversion,
          },
          proceedFetching: true,
          thisFetchIsDone: true,
        });
      }

      throw new RedirectError({to: 'landing', proceedFetching: true, thisFetchIsDone: true});
    }

    return rulesetItem;
  } catch (error) {
    if (error instanceof RedirectError) {
      throw error;
    }

    throw new RedirectError({to: 'landing', proceedFetching: true, thisFetchIsDone: true});
  }
}

export function* fetchRulesetInstance(params) {
  const {id, force = false, pversion = 'draft', representation} = params;

  return yield call(apiSaga, 'rule_sets.get_instance', {
    params: {pversion, rule_set_id: id},
    query: {representation},
    ignoreCodes: [404],
    cache: !force,
    *onDone({data: item}) {
      return item;
    },
  });
}
