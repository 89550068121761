/**
 * Copyright 2017 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import {call, fork, put, take, select, spawn} from 'redux-saga/effects';
import {RedirectError} from 'errors';
import {sortAndStringifyArray} from 'utils/general';
import apiSaga from 'api/apiSaga';
import {getRouteCurrentParams, getRouteName} from 'containers/App/AppState';
import {fetchSelectiveLabels} from 'containers/Label/LabelSaga';
import {fetchPending} from 'containers/Provisioning/ProvisioningSaga';
import {lookupProtocol} from 'containers/Service/ServiceUtils';
import {getLabelObjects} from 'containers/RBAC/RBACUtils';

export const matches = {
  container_clusters: {},
  container_workloads: {},
  container_workload_profiles: {},
  discovered_virtual_servers: {},
  virtual_services: {},
  ip_lists: {},
  labels: {
    role: {},
    app: {},
    env: {},
    loc: {},
    all: {},
  },
  label_groups: {
    role: {},
    app: {},
    env: {},
    loc: {},
    all: {},
  },
  networks: {},
  network4: {},
  network6: {},
  services: {},
  service_accounts: {},
  rule_sets: {},
  groups: {},
  user_groups: {},
  virtual_servers: {},
  network_devices: {},
  workloads: {},
  pairing_profiles: {},
  security_principals: {},
  enforcement_boundaries: {},
  org_auth_security_principals: {
    name: {},
    group: {},
  },
  slbs: {},
  users: {},
  vens: {},
};

export const facets = {
  container_clusters: {},
  container_workloads: {},
  container_workload_profiles: {},
  discovered_virtual_servers: {},
  virtual_services: {},
  virtual_servers: {},
  ip_lists: {},
  labels: {},
  label_groups: {},
  networks: {},
  services: {},
  service_accounts: {},
  rule_sets: {},
  groups: {},
  user_groups: {},
  workloads: {},
  org_permissions: {},
  pairing_profiles: {},
  security_principals: {},
  network_devices: {},
  enforcement_boundaries: {},
  vens: {},
};

const matchesRequests = [
  'CONTAINER_CLUSTERS_MATCHES_REQUEST',
  'CONTAINER_WORKLOADS_MATCHES_REQUEST',
  'CONTAINER_WORKLOAD_PROFILES_MATCHES_REQUEST',
  'DISCOVERED_VIRTUAL_SERVERS_MATCHES_REQUEST',
  'IP_LISTS_MATCHES_REQUEST',
  'LABELS_MATCHES_REQUEST',
  'LABEL_GROUPS_MATCHES_REQUEST',
  'NETWORK4_MATCHES_REQUEST',
  'NETWORK6_MATCHES_REQUEST',
  'NETWORKS_MATCHES_REQUEST',
  'NETWORK_DEVICES_MATCHES_REQUEST',
  'ORG_EVENTS_MATCHES_REQUEST',
  'PAIRING_PROFILES_MATCHES_REQUEST',
  'RULE_SETS_MATCHES_REQUEST',
  'SECURITY_PRINCIPALS_MATCHES_REQUEST',
  'ENFORCEMENT_BOUNDARIES_MATCHES_REQUEST',
  'SERVICES_MATCHES_REQUEST',
  'SERVICE_ACCOUNTS_MATCHES_REQUEST',
  'SLBS_MATCHES_REQUEST',
  'GROUPS_MATCHES_REQUEST',
  'USER_GROUPS_MATCHES_REQUEST',
  'VENS_MATCHES_REQUEST',
  'VIRTUAL_SERVERS_MATCHES_REQUEST',
  'VIRTUAL_SERVICES_MATCHES_REQUEST',
  'WORKLOADS_MATCHES_REQUEST',
];

const getCollectionMatchesRequests = ['ORG_AUTH_SECURITY_PRINCIPALS_MATCHES_REQUEST', 'USERS_MATCHES_REQUEST'];

const facetsRequests = [
  'CONTAINER_CLUSTERS_FACETS_REQUEST',
  'CONTAINER_WORKLOADS_FACETS_REQUEST',
  'CONTAINER_WORKLOAD_PROFILES_FACETS_REQUEST',
  'DISCOVERED_VIRTUAL_SERVERS_FACETS_REQUEST',
  'IP_LISTS_FACETS_REQUEST',
  'LABELS_FACETS_REQUEST',
  'LABEL_GROUPS_FACETS_REQUEST',
  'NETWORKS_FACETS_REQUEST',
  'SERVICES_FACETS_REQUEST',
  'SERVICE_ACCOUNTS_FACETS_REQUEST',
  'RULE_SETS_FACETS_REQUEST',
  'GROUPS_FACETS_REQUEST',
  'USER_GROUPS_FACETS_REQUEST',
  'WORKLOADS_FACETS_REQUEST',
  'VENS_FACETS_REQUEST',
  'NETWORK_DEVICES_FACETS_REQUEST',
  'ORG_EVENTS_FACETS_REQUEST',
  'ORG_PERMISSIONS_FACETS_REQUEST',
  'PAIRING_PROFILES_FACETS_REQUEST',
  'RULE_SETS_FACETS_REQUEST',
  'SECURITY_PRINCIPALS_FACETS_REQUEST',
  'ENFORCEMENT_BOUNDARIES_FACETS_REQUEST',
  'USER_GROUPS_FACETS_REQUEST',
  'VENS_FACETS_REQUEST',
  'VIRTUAL_SERVICES_FACETS_REQUEST',
  'VIRTUAL_SERVERS_FACETS_REQUEST',
  'WORKLOADS_FACETS_REQUEST',
];

// Use as a mapping query parameter base on the key set in categoriesKey prop for SingleItemSelector
// e.g. {[{type: 'org_auth_security_principals', key: 'group'}]
const matchesQueryCollectionFilter = {
  ORG_AUTH_SECURITY_PRINCIPALS_GET_MATCHES: {
    group: {
      // External Group filtering
      query: {type: 'group'}, // Specific query for API
      filterKey: 'name', // A key used to filter from the API
    },
  },
};

const createRequests = ['LABELS_CREATE_REQUEST', 'SERVICES_CREATE_REQUEST', 'IP_LISTS_CREATE_REQUEST'];

const clearCacheRequests = ['FETCHING_DATA_END', 'CLEAR_SELECTOR_CACHE'];

const containerWorkloadToWorkloadFilterConversion = {
  workload: 'name',
  hostname: 'hostname',
  hostip: 'ip_address',
};

const convertToContainerClusterFilterConversion = {
  containerCluster: 'name',
};

// When navigating to a new page or there is a clear cache request, clear the selector cache
export function* watchClearSelectorCache() {
  while (true) {
    yield take(clearCacheRequests);

    Object.keys(matches).forEach(key => {
      if (key === 'labels' || key === 'groups' || key === 'label_groups' || key === 'org_auth_security_principals') {
        const group = matches[key];

        Object.keys(group).forEach(key => (group[key] = {}));
      } else {
        matches[key] = {};
      }
    });

    Object.keys(facets).forEach(key => {
      facets[key] = {};
    });
  }
}

export function* watchSelectorRequests() {
  while (true) {
    const {query, params, object, filter} = yield take(matchesRequests);

    yield fork(fetchMatches, query, params, object, filter);
  }
}

export function* watchGetCollectionSelectorRequests() {
  while (true) {
    const {query, params, object, filter} = yield take(getCollectionMatchesRequests);

    yield fork(fetchGetCollectionMatches, query, params, object, filter);
  }
}

export function* watchFacetRequests() {
  while (true) {
    const result = yield take(facetsRequests);
    const {query, params, object} = result;

    if (object === 'container_workload_profiles') {
      const route = yield select(getRouteCurrentParams);

      params.container_cluster_id = route.id;
    } else if (['workloads', 'container_workloads'].includes(object)) {
      const route = yield select(getRouteCurrentParams);

      if (route.id) {
        query.container_cluster_id = route.id;
      }
    } else if (object === 'org_permissions') {
      const routeName = yield select(getRouteName);

      if (routeName.startsWith('app.rbac.roles.scope')) {
        query.scoped_roles_only = true;
      }
    }

    yield fork(fetchFacets, query, params, object);
  }
}

export function* watchCreateRequests() {
  while (true) {
    const {data, query, object} = yield take(createRequests);

    yield fork(createNewObject, object, data, query);
  }
}

function getResourceTypeLabelsKey(resourceType, selectedLabels) {
  return (
    (resourceType || '') +
    (selectedLabels ? getLabelObjects(selectedLabels).reduce((result, {href}) => `${result}${href}`, '') : '')
  );
}

function* fetchMatches(queryObj, params, object, refetch = false) {
  const {key, query: input, resource_type: resourceType} = queryObj;
  let apiString = `${object}.autocomplete`;
  const type = `${object.toUpperCase()}_GET_MATCHES`;
  let removeItem;

  let query = _.cloneDeep(queryObj);

  // Note: Services and SLBs do not accept 'selected_labels', passing selected_labels for App Owner caused
  // unintended consequences by attaching selected_labels to services thus causing failure during a List api call with labels.
  if (['services', 'ip_lists'].includes(object) || (object === 'slbs' && query?.selected_scope)) {
    query = _.omit(query, 'selected_scope');
  }

  if ((object === 'labels' || object === 'groups') && params?.admin) {
    query.admin = true;

    if (params.remove) {
      removeItem = params.remove;
    }

    params = _.omit(params, 'admin', 'remove');
  }

  const {selected_scope: selectedLabels} = query;

  const resourceTypeLabelsKey = getResourceTypeLabelsKey(resourceType, selectedLabels);

  if (selectedLabels) {
    // Only Labels, Label Groups and Rulesets API endpoints support label groups.
    const selectedScope = ['labels', 'label_groups', 'rule_sets'].includes(object)
      ? selectedLabels
      : selectedLabels.filter(item => item.label);

    query.selected_scope = JSON.stringify(selectedScope);
  }

  const cached = key
    ? _.get(matches[object], `${key}.${resourceTypeLabelsKey}${input}`, null)
    : _.get(matches[object], String(input), null);

  if (!_.isEmpty(cached) && !refetch) {
    yield put({type, data: cached, query, key});
  } else {
    // Processes the match result for special cases
    if (object === 'network4' || object === 'network6') {
      apiString = 'networks.autocomplete';
    }

    yield put({type: 'LOADING_MATCHES', object});

    const {data} = yield call(apiSaga, apiString, {params, query});

    if (refetch) {
      // if refetch is true, clear cached matches
      if (key) {
        matches[object][key] = {};
      } else {
        matches[object] = {};
      }
    }

    yield put({type: 'MATCHES_LOADED', object});

    if (refetch) {
      // if refetch is true, clear cached matches
      if (key) {
        matches[object][key] = {};
      } else {
        matches[object] = {};
      }
    }

    if (data) {
      // Processes the match result for special cases
      if (object === 'network4' || object === 'network6') {
        const version = object === 'network4' ? 4 : 6;

        data.matches = data.matches.filter(item => item.data_center !== 'link_local' && item.ip_version === version);
        data.num_matches = data.matches.length;
      }

      if (object === 'labels' && removeItem) {
        data.matches = data.matches.filter(item => item.href !== removeItem);
        data.num_matches = data.matches.length;
      }

      if (object === 'groups' && removeItem) {
        data.matches = data.matches.filter(item => item.name !== removeItem);
        data.num_matches = data.matches.length;
      }

      if (key) {
        matches[object][key][`${resourceTypeLabelsKey}${input}`] = data;
      } else {
        matches[object][input] = data;
      }

      yield put({type, data, query, key});
    }
  }
}

export function* fetchGetCollectionMatches(query, params, object, filter) {
  const {key, query: input} = query;
  const apiString = `${object}.get_collection`;
  const type = `${object.toUpperCase()}_GET_MATCHES`;
  const matchedFilterQuery = matchesQueryCollectionFilter[type] && matchesQueryCollectionFilter[type][key];

  const cached = key ? _.get(matches[object], `${key}.${input}`, null) : _.get(matches[object], String(input), null);

  if (cached) {
    yield put({type, data: cached, query, key});
  } else {
    yield put({type: 'LOADING_MATCHES', object});

    let apiQuery = {[key]: input};

    if (matchedFilterQuery) {
      // Map the proper filterKey to filter when making API request
      const filterKey = matchedFilterQuery.filterKey || key;

      // Attach specific query string to the API request
      apiQuery = {...matchedFilterQuery.query, [filterKey]: input};
    }

    let {data} = yield call(apiSaga, apiString, {params, query: apiQuery});

    yield put({type: 'MATCHES_LOADED', object});

    if (data) {
      if (filter) {
        data = data.filter(filter);
      }

      const matchFormattedData = {num_matches: data.length, matches: data};

      if (key) {
        matches[object][key][input] = matchFormattedData;
      } else {
        matches[object][input] = matchFormattedData;
      }

      yield put({type, data: matchFormattedData, query, key});
    }
  }
}

export function* fetchFacets(query, params, object) {
  const {facet, query: input, resource_type: resourceType} = query;
  const type = `${object.toUpperCase()}_GET_FACETS`;
  let apiString = `${object}.facets`;
  let paramObj = params;
  let queryObj = query;

  // Selective Enforcement Rules do not accept selected_scope as a parameter. It appears 'selected_scope'
  // was an unintended consequence for labels with App Owner
  if (object === 'enforcement_boundaries' && queryObj?.selected_scope) {
    queryObj = _.omit(queryObj, ['selected_scope']);
  }

  const {selected_scope: selectedLabels} = queryObj;

  const resourceTypeLabelsKey = getResourceTypeLabelsKey(resourceType, selectedLabels);
  const cached = _.get(facets[object], `${facet}.${resourceTypeLabelsKey}${input}`, null);

  if (selectedLabels) {
    // Only Labels, Label Groups and Rulesets API endpoints support label groups.
    const selectedScope = ['labels', 'label_groups', 'rule_sets'].includes(object)
      ? selectedLabels
      : selectedLabels.filter(item => item.label);

    query.selected_scope = JSON.stringify(selectedScope);
  }

  if (cached) {
    yield put({type, data: cached, query});
  } else {
    yield put({type: 'LOADING_FACETS', object});

    // This converts special filters from container workloads to workloads
    if (object === 'container_workloads' && containerWorkloadToWorkloadFilterConversion[facet]) {
      apiString = 'workloads.facets';
      queryObj = {...query, facet: containerWorkloadToWorkloadFilterConversion[facet]};
    } else if (
      ['workloads', 'container_workloads', 'virtual_services'].includes(object) &&
      convertToContainerClusterFilterConversion[facet]
    ) {
      // This converts special filters to container clusters
      apiString = 'container_clusters.facets';
      queryObj = {...query, facet: convertToContainerClusterFilterConversion[facet]};
    } else if (object === 'virtual_services') {
      // Insert pversion: 'draft' for virtual services
      paramObj = {...params, pversion: 'draft'};
    }

    const {data} = yield call(apiSaga, apiString, {params: paramObj, query: queryObj});

    yield put({type: 'FACETS_LOADED', object});

    if (data) {
      facets[object][facet] = {};

      if (facet === 'service_ports.proto' || facet === 'discovered_virtual_servers.vip_proto') {
        data.matches = data.matches.map(lookupProtocol);
      } else if (facet === 'service_address.fqdn' && input.includes('*')) {
        data.matches = [];
      }

      facets[object][facet][`${resourceTypeLabelsKey}${input}`] = data;
      yield put({type, data, query});
    }
  }
}

export function* createNewObject(object, data, query, params = {}) {
  const {data: created} = yield call(apiSaga, `${object}.create`, {data, params});

  if (params.pversion) {
    // If a provisionable policy object got created then fetch pending objects to update header count
    yield spawn(fetchPending);
  }

  yield call(fetchMatches, query, params, object, true);

  return created;
}

export function* fetchValidScopeLabels(scopes) {
  if (!scopes) {
    return;
  }

  if (scopes.scope && Array.isArray(scopes.scope)) {
    const params = yield select(getRouteCurrentParams);
    const hrefScopes = scopes.scope.filter(item => !item.href.includes('exists'));
    const labelsMap = yield call(fetchSelectiveLabels, hrefScopes);
    const validScopes = hrefScopes.filter(label => {
      const found = labelsMap.get(label.href);

      return found && !found.deleted;
    });

    if (sortAndStringifyArray(validScopes) !== sortAndStringifyArray(hrefScopes)) {
      throw new RedirectError({
        params: {...params, scope: validScopes.length ? {scope: validScopes} : undefined},
        proceedFetching: true,
        thisFetchIsDone: true,
      });
    } else {
      return labelsMap;
    }
  }
}
