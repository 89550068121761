/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import {createSelector} from 'reselect';
import {getGridSelector} from 'components/Grid/GridSelectors';
import {gridSettings, getSelectorSettings} from './ContainerClusterContainerWorkloadProfileListConfig';
import {getAllUsersMap, isUserReadOnlyClusterInsensitive, isSuperclusterMember} from 'containers/User/UserState';
import {getRouteParams} from 'containers/App/AppState';
import * as GridUtils from 'components/Grid/GridUtils';
import {isLocalPce} from 'containers/App/AppUtils';
import {fillUserInfo} from 'containers/RBAC/RBACUtils';
import {getContainerClusterDetail} from '../../ContainerClusterDetailState';
import {isOpenShift, getNamespaceTerm} from 'containers/ContainerCluster/ContainerClusterUtils';
import {getClusters, getLocalFQDN} from 'containers/Health/HealthState';
import {inferLabelSource} from '../../ContainerClusterDetailUtils';

export default {
  containerWorkloadProfileList(state = [], action) {
    switch (action.type) {
      case 'CONTAINER_CLUSTER_GET_CONTAINER_WORKLOAD_PROFILES':
        return action.data.containerWorkloadProfileList;
      default:
        return state;
    }
  },

  containerWorkloadProfileCount(state = {}, action) {
    switch (action.type) {
      case 'CONTAINER_CLUSTER_GET_CONTAINER_WORKLOAD_PROFILES':
        return action.data.containerWorkloadProfileCount;
      default:
        return state;
    }
  },

  containerWorkloadProfileDefault(state = {}, action) {
    switch (action.type) {
      case 'CONTAINER_CLUSTER_GET_CONTAINER_WORKLOAD_PROFILE_DEFAULT':
        return action.data.containerWorkloadProfileDefault?.[0] ?? state;
      default:
        return state;
    }
  },
};

export const getContainerClusterContainerWorkloadProfiles = state =>
  state.containerClusters.containerWorkloadProfileList;
export const getContainerClusterContainerWorkloadProfilesCount = state =>
  state.containerClusters.containerWorkloadProfileCount;
export const getContainerClusterContainerWorkloadProfileDefault = state =>
  state.containerClusters.containerWorkloadProfileDefault;

const getContainerClusterContainerWorkloadProfilesRows = createSelector(
  [
    getContainerClusterDetail,
    getContainerClusterContainerWorkloadProfiles,
    getAllUsersMap,
    isUserReadOnlyClusterInsensitive,
    getClusters,
    getLocalFQDN,
  ],
  (clusterDetail, profiles, usersMap, userIsReadOnlyClusterInsensitive, clusters, local) => {
    return profiles.map(item => ({
      key: item.href,
      selectable:
        !item.linked &&
        !userIsReadOnlyClusterInsensitive &&
        isLocalPce({clusters, local, pceFqdn: clusterDetail.pce_fqdn}),
      removable: !item.linked,
      data: {
        ...item,
        updated_by: fillUserInfo(usersMap, item.updated_by),
        labels: GridUtils.getLabelsMap(item.labels),
      },
    }));
  },
);

export const getGridSettings = createSelector(
  [getContainerClusterDetail, gridSettings],
  (clusterDetail, gridSettings) => {
    const columns = {...gridSettings.columns};

    columns.namespace.header = getNamespaceTerm(clusterDetail); // based on containercluster type

    return {...gridSettings, columns};
  },
);

const getGrid = state =>
  getGridSelector(state, {
    settings: getGridSettings,
    rows: getContainerClusterContainerWorkloadProfilesRows,
    filterMap: getSelectorSettings().filterMap,
  });

export const getContainerClusterContainerWorkloadProfilesPage = createSelector(
  [
    getContainerClusterDetail,
    getGrid,
    getRouteParams,
    getContainerClusterContainerWorkloadProfilesCount,
    isSuperclusterMember,
    getContainerClusterContainerWorkloadProfileDefault,
    isUserReadOnlyClusterInsensitive,
    getClusters,
    getLocalFQDN,
    getSelectorSettings,
  ],
  (
    clusterDetail,
    grid,
    {id},
    count,
    isMember,
    defaultProfile = {mode: 'unmanaged'},
    userIsReadOnlyClusterInsensitive,
    clusters,
    local,
    selectorSettingsObject,
  ) => {
    selectorSettingsObject.filterMap.namespace.value = getNamespaceTerm(clusterDetail); // based on containercluster type

    const filterItems = Object.keys(grid.filter).reduce((result, categoryKey) => {
      if (selectorSettingsObject.scopeMap[categoryKey]) {
        const filterItems = grid.filter[categoryKey];

        filterItems.forEach(({href, key, value}) => {
          result.push({
            href,
            value,
            categoryKey,
            key,
            categoryName: _.get(selectorSettingsObject.filterMap[categoryKey], 'value'),
          });
        });
      } else {
        result.push({
          categoryKey,
          value: grid.filter[categoryKey][0],
          categoryName:
            selectorSettingsObject.filterMap[categoryKey].value || selectorSettingsObject.filterMap[categoryKey],
        });
      }

      return result;
    }, []);

    const selector = {
      filterItems,
      objects: Object.values(selectorSettingsObject.objectMap),
      categories: Object.entries(selectorSettingsObject.filterMap).map(([categoryKey, value]) => ({
        categoryKey,
        value: value.value || value,
        object: value.object,
        statics: value.statics,
      })),
      facets: Object.keys(selectorSettingsObject.facetMap),
      partials: Object.keys(selectorSettingsObject.facetMap),
      statics: Object.entries(selectorSettingsObject.staticValues).reduce((result, [key, values]) => {
        result[key] = Object.keys(values);

        return result;
      }, {}),
    };

    return {
      isOpenshift: isOpenShift(clusterDetail),
      isLocalPce: isLocalPce({clusters, local, pceFqdn: clusterDetail.pce_fqdn}),
      allowCreateTypes: isMember ? [] : ['labels'],
      grid,
      id,
      count,
      selector,
      defaultProfile,
      userIsReadOnlyClusterInsensitive,
      ...GridUtils.getLabelsMap(defaultProfile.labels),
      defaultLabels: GridUtils.getFlattenedLabelModeMap(defaultProfile.labels),
      roleSource: inferLabelSource('role', defaultProfile.labels),
      appSource: inferLabelSource('app', defaultProfile.labels),
      envSource: inferLabelSource('env', defaultProfile.labels),
      locSource: inferLabelSource('loc', defaultProfile.labels),
    };
  },
);
