/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import {object} from 'yup';
import {Component} from 'react';
import {connect} from 'react-redux';
import {AppContext} from 'containers/App/AppUtils';
import {cachedResponses} from 'api/apiCache';
import PairingProfileState from '../../PairingProfileState';
import {getPairingProfilePairs} from './PairingProfilePairState';
import {Form, AttributeList, Link, Pill, CopyButton, TextLabel, StatusIcon, Icon} from 'components';
import {formatPairingKeys, getLabelScopePairing, formatTimeLeft} from 'containers/PairingProfile/PairingProfileUtils';
import {
  enforcementModeView,
  enforcementModeViewEdge,
  visibilityLevelView,
  visibilityLevelViewEdge,
} from 'containers/EnforcementBoundaries/EnforcementBoundariesUtils';
import {hrefUtils} from 'utils';
import {HeaderProps} from 'containers';
import {getLabelTypeName} from 'components/Pill/Label/LabelUtils';
import styles from './PairingProfilePair.css';
import {formatVenVersion} from '../View/PairingProfileViewUtils';
import {fetchPairingKeyList} from './PairingProfilePairSaga';

const selectorOptions = {};

// Get formik's mandatory initialValues props for form setup
const getInitialValues = props => {
  const {pairingProfile, pairingProfileAccessList} = props;

  selectorOptions.pairingKeyHref = formatPairingKeys(pairingProfileAccessList, 'pairingKeyHref');

  return {
    pairingKeyHref: Form.Utils.findSelectedOption(selectorOptions.pairingKeyHref, pairingProfile.href),
  };
};

// Initial State
const getInitialState = props => ({
  initialValues: getInitialValues(props),
});

@connect(getPairingProfilePairs)
export default class PairingProfilePair extends Component {
  static prefetch = fetchPairingKeyList;
  static contextType = AppContext;
  static reducers = PairingProfileState;

  constructor(props) {
    super(props);

    this.state = getInitialState(props);

    // Yup validation
    this.schemas = object({
      pairingKeyHref: object().nullable(),
    });

    this.getInitialWorkloadState = this.getInitialWorkloadState.bind(this);
    this.handleOnsetPairingWorkloadsPairField = this.handleOnsetPairingWorkloadsPairField.bind(this);
    this.renderForm = this.renderForm.bind(this);
    this.handleCopy = this.handleCopy.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      prevState.initialValues.pairingKeyHref &&
      prevState.initialValues.pairingKeyHref.value !== nextProps.pairingProfile.href
    ) {
      return getInitialState(nextProps);
    }

    return null;
  }

  // Get Pairing Scripts
  getPairingScripts() {
    const {activationCode, workloadOsFamilies, edgeEnabled} = this.props;

    let pairingScriptsAttributes = [];

    if (activationCode.activation_code) {
      pairingScriptsAttributes = [
        {
          divider: true,
        },
        {
          title: intl('PairingProfiles.Workloads.PairingScripts'),
        },
        {
          value: (
            <>
              <StatusIcon status="warning" noTextColor />
              {intl('PairingProfiles.TLSWarning')}
            </>
          ),
          valueGap: 'gapSmall gapHorizontal',
        },
        {
          tid: 'key',
          key: <TextLabel title={intl('PairingProfiles.Key')} paddingTop="var(--5px)" />,
          value: (
            <div className={styles.command}>
              <div className={styles.text}>{activationCode.activation_code}</div>
              <CopyButton copyText={activationCode.activation_code} onCopy={this.handleCopy} />
            </div>
          ),
        },
      ];

      const venOSTitle = {
        linux: intl('PairingProfiles.Workloads.Linux'),
        windows: intl('PairingProfiles.Workloads.Windows'),
        darwin: intl('PairingProfiles.Workloads.Darwin'),
      };

      for (const venOS of workloadOsFamilies) {
        if (edgeEnabled && venOS.os_family_id === 'Linux') {
          continue; //TODO: rm "skip linux" after oldest-supported Edge VEN bundle  excludes linux
        }

        const pairingPolicyCommand = venOS.pairing_policy.replace('{{pairing_key}}', activationCode.activation_code);

        pairingScriptsAttributes.push(
          {
            tid: venOS.os_family_id,
            // os_family_id from the backend can either be ['Linux','linux','Windows','windows'] thus set to lower case
            key: <TextLabel title={venOSTitle[venOS.os_family_id.toLowerCase()]} paddingTop="var(--5px)" />,
            value: (
              <div className={styles.command}>
                <div className={styles.text}>{pairingPolicyCommand}</div>
                <CopyButton copyText={pairingPolicyCommand} onCopy={this.handleCopy} />
              </div>
            ),
          },
          ...(!edgeEnabled
            ? [
                {
                  tid: 'supportedVersions',
                  key: intl('PairingProfiles.Workloads.OperatingSystems'),
                  value: (
                    <Link
                      href="https://support.illumio.com/pce/ven-supported-oses"
                      target="_blank"
                      data-tid="comp-button comp-button-supportedVersions"
                    >
                      <Icon name="external-link" position="before" />
                      {intl('PairingProfiles.Workloads.SupportedVersions')}
                    </Link>
                  ),
                },
                {
                  tid: 'requiredPackages',
                  key: intl('PairingProfiles.Workloads.Dependencies'),
                  value: (
                    <Link
                      href="https://support.illumio.com/pce/ven-dependencies"
                      target="_blank"
                      data-tid="comp-button comp-button-requiredPackages"
                    >
                      <Icon name="external-link" position="before" />
                      {intl('PairingProfiles.Workloads.RequiredPackages')}
                    </Link>
                  ),
                },
              ]
            : []),
        );
      }
    }

    return pairingScriptsAttributes;
  }

  // Get Details Attributes
  getPairingDetailSettings() {
    const {pairingProfile} = this.props;

    return [
      {
        divider: true,
      },
      {
        title: intl('PairingProfiles.Workloads.PairingKeySettings'),
      },
      {
        tid: 'generatedat',
        key: intl('PairingProfiles.Workloads.GeneratedOn'),
        value: intl.utils.format.dateAtTimeBy(pairingProfile.created_at),
      },
      {
        tid: 'lifespan',
        key: intl('PairingProfiles.Workloads.Lifespan'),
        value:
          pairingProfile.key_lifespan === 'unlimited'
            ? intl('Common.Unlimited')
            : formatTimeLeft(pairingProfile.key_lifespan, true, true),
      },
      {
        tid: 'remaininguses',
        key: intl('PairingProfiles.Workloads.RemainingUses'),
        value:
          pairingProfile.allowed_uses_per_key === 'unlimited'
            ? intl('Common.Unlimited')
            : pairingProfile.allowed_uses_per_key,
      },
    ];
  }

  // Get Initial Workload State
  getInitialWorkloadState() {
    const {values} = this.formik;
    const {pairingProfile, edgeEnabled, crowdstrikeEnabled} = this.props;
    const id = hrefUtils.getId(
      (values.pairingKeyHref && values.pairingKeyHref.value) || hrefUtils.getId(pairingProfile.href),
    );
    const profileLabels = getLabelScopePairing(pairingProfile);
    const enforcementModeViewText = enforcementModeView();
    const visibilityLevelViewText = visibilityLevelView();

    // Show Visibility View
    let visibilityValue;

    if (edgeEnabled && pairingProfile.enforcement_mode === 'idle') {
      visibilityValue = (
        <>
          {visibilityLevelViewText[pairingProfile.enforcement_mode]?.name}
          <div className={styles.content}>{visibilityLevelViewEdge.idle?.desc}</div>
        </>
      );
    } else if (edgeEnabled) {
      visibilityValue = (
        <>
          {visibilityLevelViewText[pairingProfile.visibility_level]?.name}
          <div className={styles.content}>
            {pairingProfile.enforcement_mode === 'full' && pairingProfile.visibility_level === 'flow_summary'
              ? visibilityLevelViewEdge.flow_summary_enforce?.desc
              : visibilityLevelViewEdge[pairingProfile.visibility_level]?.desc}
          </div>
        </>
      );
    } else if (pairingProfile.enforcement_mode === 'idle') {
      visibilityValue = visibilityLevelViewText[pairingProfile.enforcement_mode].name;
    } else {
      visibilityValue = (
        <>
          {
            visibilityLevelViewText[
              edgeEnabled && pairingProfile.visibility_level === 'full' ? 'enforced' : pairingProfile.visibility_level
            ].name
          }
          <div className={styles.content}>{visibilityLevelViewText[pairingProfile.visibility_level].desc}</div>
        </>
      );
    }

    let labels;

    if (!edgeEnabled) {
      labels = [
        {
          tid: 'labelapp',
          key: getLabelTypeName('app'),
          value: profileLabels.app ? (
            <Pill.Label type="app" href={profileLabels.app.href}>
              {profileLabels.app.value}
            </Pill.Label>
          ) : null,
        },
        {
          tid: 'labelenv',
          key: getLabelTypeName('env'),
          value: profileLabels.env ? (
            <Pill.Label type="env" href={profileLabels.env.href}>
              {profileLabels.env.value}
            </Pill.Label>
          ) : null,
        },
        {
          tid: 'labelloc',
          key: getLabelTypeName('loc'),
          value: profileLabels.loc ? (
            <Pill.Label type="loc" href={profileLabels.loc.href}>
              {profileLabels.loc.value}
            </Pill.Label>
          ) : null,
        },
        {
          tid: 'labelrole',
          key: getLabelTypeName('role'),
          value: profileLabels.role ? (
            <Pill.Label type="role" href={profileLabels.role.href}>
              {profileLabels.role.value}
            </Pill.Label>
          ) : null,
        },
      ];
    } else {
      labels = [
        {
          tid: 'labelrole',
          key: getLabelTypeName('role'),
          value: profileLabels.role ? (
            <Pill.Group value={{name: profileLabels.role.value, href: pairingProfile.external_data_reference}} />
          ) : null,
        },
      ];
    }

    const initialStateAttributes = [
      {
        divider: true,
      },
      {
        title: intl('PairingProfiles.Workloads.InitialState'),
      },
      {
        tid: 'name',
        key: edgeEnabled ? intl('Edge.InstallScript.Source') : intl('PairingProfiles.Workloads.SourcePairingProfile'),
        value: (
          <Link to="pairingProfiles.item.view" params={{id}}>
            {pairingProfile.name}
          </Link>
        ),
      },
      ...labels,
      {
        tid: 'enforcement-mode',
        key: intl('Common.Enforcement'),
        value: (
          <>
            {
              enforcementModeViewText[
                edgeEnabled && pairingProfile.enforcement_mode === 'full' ? 'enforced' : pairingProfile.enforcement_mode
              ]?.name
            }
            <div className={styles.content}>
              {edgeEnabled
                ? enforcementModeViewEdge[pairingProfile.enforcement_mode]?.desc
                : enforcementModeViewText[pairingProfile.enforcement_mode]?.desc}
            </div>
          </>
        ),
      },
      !crowdstrikeEnabled && {
        tid: 'visibility-level',
        key: intl('Common.Visibility'),
        value: visibilityValue,
      },
    ];

    if (pairingProfile.agent_software_release) {
      initialStateAttributes.push({
        tid: 'state',
        key: intl('PairingProfiles.InitialVenVersion'),
        value: (
          <>
            {formatVenVersion(pairingProfile.agent_software_release)}
            <div className={styles.content}>{intl('PairingProfiles.Install')}</div>
          </>
        ),
      });
    }

    return initialStateAttributes;
  }

  // Set the Form Selector Field
  handleOnsetPairingWorkloadsPairField(evt, valueSelected) {
    const id = hrefUtils.getId(valueSelected.value);

    // Redirect route to selected id
    this.context.navigate({to: 'pairingProfiles.pair', params: {id}});
  }

  // Handle Copy
  handleCopy() {
    // We assume that by copying script user has intention to pair a new workload,
    // so invalidate workloads/labels/firewall_settings cache,
    // and when user returns to workload list page hecan potentially see new workload
    cachedResponses.removeByMethodName('workloads.get_collection');
    cachedResponses.removeByMethodName('firewall_settings.get');
    cachedResponses.removeByMethodName('labels.get_collection');
  }

  renderForm(options) {
    this.formik = options;

    const initialWorkloadState = this.getInitialWorkloadState();
    const pairingKeySettings = this.getPairingDetailSettings();
    const pairingScripts = this.getPairingScripts();

    return (
      <AttributeList>
        {[
          {
            tid: 'pick-a-pairing-profiles',
            key: (
              <Form.Label
                name="pairingKeyHref"
                title={
                  this.props.edgeEnabled
                    ? intl('PairingProfiles.Workloads.PickGroup')
                    : intl('PairingProfiles.Workloads.PickProfile')
                }
              />
            ),
            value: (
              <Form.Selector
                name="pairingKeyHref"
                tid="pairing-profiles"
                theme={{dropdown: styles.dropdown}}
                onChange={this.handleOnsetPairingWorkloadsPairField}
                options={selectorOptions.pairingKeyHref}
              />
            ),
          },
          ...initialWorkloadState,
          ...pairingScripts,
          ...pairingKeySettings,
        ]}
      </AttributeList>
    );
  }

  // Note: Important to set 'enableReinitialize' to update this.state.initialValues dynamically
  render() {
    const {pairingProfile, edgeEnabled} = this.props;

    return (
      <>
        <HeaderProps
          title={edgeEnabled ? intl('Edge.InstallScript.Name') : intl('PairingProfiles.Profiles')}
          subtitle={pairingProfile.name}
          label={`(${intl('PairingProfiles.Pair')})`}
          up={
            edgeEnabled
              ? {to: 'workloads.vens.list'}
              : {to: 'pairingProfiles.item', params: {id: hrefUtils.getId(pairingProfile.href)}}
          }
        />
        <Form enableReinitialize schemas={this.schemas} initialValues={this.state.initialValues} allowLeaveOnDirty>
          {this.renderForm}
        </Form>
      </>
    );
  }
}
