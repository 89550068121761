/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import {PureComponent} from 'react';
import {connect} from 'react-redux';
import {TabPanel} from 'components';
import {HeaderProps} from 'containers';
import {fetchVenItem} from '../VenSaga';
import {getVenItemPage} from './VenItemState';

@connect(getVenItemPage)
export default class VenItem extends PureComponent {
  static prefetch = fetchVenItem;

  render() {
    const {currentRouteParams, ven} = this.props;
    const containerWorkloads = ven.workloads.length > 1;

    return (
      <>
        <HeaderProps title={intl('Common.VEN')} subtitle={ven.name ? ven.name : ven.hostname} up="workloads.vens" />
        {containerWorkloads ? (
          <TabPanel primary>
            {[
              {
                link: 'workloads.vens.item.view',
                text: intl('Common.Summary'),
                params: {id: currentRouteParams.id},
                tid: 'ven-summary',
              },
              {
                link: 'workloads.vens.item.containerWorkloads',
                text: intl('Common.ContainerWorkloads'),
                params: {id: currentRouteParams.id},
                tid: 'ven-containerworkloads',
              },
            ]}
          </TabPanel>
        ) : null}
        {this.props.children}
      </>
    );
  }
}
