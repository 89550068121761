/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from 'intl';
import cx from 'classnames';
import {Document as FSDocument} from 'flexsearch';
import {PureComponent, createRef} from 'react';
import {connect} from 'react-redux';
import {KEY_ESCAPE, KEY_BACK_SPACE, KEY_RETURN, KEY_RIGHT, KEY_UP, KEY_DOWN, KEY_TAB, KEY_K} from 'keycode-js';
import * as progressBar from 'progressBar';
import {domUtils, hrefUtils, reactUtils, generalUtils} from 'utils';
import {AppContext} from 'containers/App/AppUtils';
import {schemaAutocompleteMethodsMap} from 'api/apiUtils';
import {Modal, Input, Icon, Button, Tooltip} from 'components';
import {getInstantSearchData} from './InstantSearchState';
import {updateInstantSearchHistory} from './InstantSearchSaga';
import {matches as matchesDefaults} from 'containers/Selectors/SelectorSaga';
import styles from './InstantSearch.css';
import styleUtils from 'utils.css';
import tesseReactContainersPropertiesMap, {getLegacyRoutesDisplayNamesMap} from './InstantSearchContainerProperties';

const highlightedTextCache = new Map();

// cache context in constructor - we don't get access to 'this' in static methods like getDerivedStateFromProps
const getEmptyState = context => ({
  value: '',
  matches: [],
  selected: 0,
  ...(context ? {context} : {}),
});

@connect(getInstantSearchData)
export default class InstantSearch extends PureComponent {
  static contextType = AppContext;

  debounceAutocompleteSearch = _.debounce(() => {
    const {dispatch, orgId, filter: type} = this.props;
    const {value} = this.state;

    const query = {query: value, facet: 'name', max_results: 25};
    const params = {xorg_id: orgId};

    if (schemaAutocompleteMethodsMap.get(type)?.params.includes('pversion')) {
      params.pversion = 'draft';
    }

    dispatch({type: `${type?.toUpperCase()}_MATCHES_REQUEST`, object: type, query, params});
  }, 450);

  constructor(props, context) {
    super(props, context);

    this.state = getEmptyState(context);

    this.handleClose = this.handleClose.bind(this);
    this.handleKeydown = this.handleKeydown.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
    this.handleOnClick = this.handleOnClick.bind(this);
    this.handleOnFilterClick = this.handleOnFilterClick.bind(this);
    this.handleDeleteSearchItem = this.handleDeleteSearchItem.bind(this);
    this.handleDeleteAllSearchItems = this.handleDeleteAllSearchItems.bind(this);
    this.setupRoutesIndex = this.setupRoutesIndex.bind(this);
    this.selectSuggestion = this.selectSuggestion.bind(this);
    this.handleAnimationEnd = this.handleAnimationEnd.bind(this);
    this.toggleModal = this.toggleModal.bind(this);

    this.filterRef = createRef();
    this.listRef = createRef();
    this.inputRef = createRef();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.userRoles !== prevState.userRoles) {
      return {
        userRoles: nextProps.userRoles,
      };
    }

    if (!nextProps.active) {
      return null;
    }

    if (nextProps.filter && !prevState.value?.length) {
      return {matches: [], noResultsFound: false};
    }

    if (nextProps.history.length === 0 && !prevState.value?.length) {
      return {matches: [], noResultsFound: false};
    }

    if (
      (nextProps.filter || (!prevState.value?.length && nextProps.history.length)) &&
      !generalUtils.areArraysEqualWhenSorted(nextProps.matches, prevState.matches)
    ) {
      // Populate matches key with autocomplete matches.
      return {matches: nextProps.matches, noResultsFound: !nextProps.matches.length};
    }

    return null;
  }

  async componentDidMount() {
    await this.setupRoutesIndex();

    document.addEventListener('keydown', this.handleKeydown);
  }

  componentWillUnmount() {
    this.debounceAutocompleteSearch.cancel();
    document.removeEventListener('keydown', this.handleKeydown);
  }

  // Generates default route suggestion list for legacy and TesseReact routes.
  async setupRoutesIndex() {
    const routesIndex = new FSDocument({
      document: {
        id: 'viewName',
        store: ['viewName', 'collectionName', 'name', 'isAvailable', 'params'],

        index: [
          {
            field: 'viewName',
            tokenize: 'full', // To support forward, reverse, and partial matches e.g. Typing 'ser' or 'ervi' or 'es', will match Services
          },
          {
            field: 'collectionName',
            tokenize: 'strict', // Use default since we don't do direct input search on Collections
          },
        ],
      },
    });

    const {router, store} = this.state.context;
    const {routesMap} = router;

    const storeState = store.getState();
    const legacyRoutesDisplayNamesMap = getLegacyRoutesDisplayNamesMap(storeState);
    let i = 0; // ID tracker

    const routeEntries = Array.from(routesMap.entries()).reduce((result, [, {name, container, component, load}]) => {
      if (container === 'JumpToOld') {
        if (legacyRoutesDisplayNamesMap[name]) {
          const {viewName, collectionName, isAvailable} = legacyRoutesDisplayNamesMap[name];

          if (__DEV__) {
            if (typeof viewName !== 'function') {
              throw new TypeError(`viewName=${viewName} should be a function.`);
            }
          }

          const view = viewName();

          if (!result.get(view)) {
            result.set(view, routesIndex.addAsync({id: i, viewName: view, name, collectionName, isAvailable}));
            i += 1;
          }
        }
      }

      if ((!component && load && !tesseReactContainersPropertiesMap.get(container)) || (!component && !load)) {
        return result;
      }

      // A container component that is loaded dynamically will have meta-properties available via the Container Properties (tesseReactContainersPropertiesMap) map.
      // Containers that are available globally for example can have their static meta-properties used.
      const {viewName, collectionName, isAvailable, aliases, params} =
        tesseReactContainersPropertiesMap.get(container) || component;

      if (viewName) {
        if (__DEV__) {
          if (typeof viewName !== 'function') {
            throw new TypeError(`viewName=${viewName} should be a function.`);
          }
        }

        const view = viewName();

        if (!result.get(view)) {
          result.set(view, routesIndex.addAsync({id: i, viewName: view, name, collectionName, isAvailable, params}));
          i += 1;
        }

        if (aliases?.length) {
          aliases.forEach(alias => {
            if (__DEV__) {
              if (typeof alias.viewName !== 'function') {
                throw new TypeError(`alias.viewName=${alias.viewName} should be a function.`);
              }
            }

            const aliasView = alias.viewName();

            if (!result.get(aliasView)) {
              result.set(
                aliasView,
                routesIndex.addAsync({
                  id: i,
                  viewName: aliasView,
                  name,
                  collectionName,
                  isAvailable: alias.isAvailable,
                  params: alias.params,
                }),
              );
              i += 1;
            }
          });
        }
      }

      return result;
    }, new Map());

    await Promise.all(Array.from(routeEntries.values())); // Call all routesIndex.addAsync() entries for a route
    this.setState({routesIndex});
  }

  getPlaceholder(value, filterViewName) {
    let placeholder;

    if (value?.length) {
      placeholder = undefined;
    } else if (filterViewName?.length && !value?.length) {
      placeholder = intl('InstantSearch.SearchByFilter', {filter: filterViewName});
    } else {
      placeholder = intl('Common.Search');
    }

    return placeholder;
  }

  getHint(match, value) {
    // Only show hint when input is not overflowing
    if (this.inputRef?.current) {
      const input = this.inputRef.current.input.current;
      const isOverflown = input.scrollHeight > input.clientHeight || input.scrollWidth > input.clientWidth;

      if (isOverflown) {
        return '';
      }
    }

    let hint = match?.key || match?.value?.hostname || match;

    if (value?.length && hint?.toLowerCase().startsWith(value.toLowerCase())) {
      hint = Array.from(hint).reduce((acc, char, index) => {
        if (value[index]?.toLowerCase() === char.toLowerCase()) {
          return acc + value[index];
        }

        return acc + char;
      }, '');
    } else {
      hint = '';
    }

    return hint;
  }

  async handleKeydown(evt) {
    // Open modal
    if (generalUtils.cmdOrCtrlPressed(evt) && evt.keyCode === KEY_K) {
      domUtils.preventEvent(evt);
      await this.toggleModal();

      return;
    }

    // Only listen for these key combinations when InstantSearch is visible
    if (this.props.active) {
      // Handle 'tab' key.
      // This results in either a filter being added to the searchbar.
      // Otherwise, an animation is triggered in order to alert the user about invalid filtering.
      if (evt.keyCode === KEY_TAB) {
        if (this.state.matches.length) {
          const value = this.state.matches[this.state.selected].value;

          if (!this.props.filter && value?.collectionName) {
            this.setState(getEmptyState());

            this.props.dispatch({type: 'SET_INSTANT_SEARCH_FILTER', data: value.collectionName});
            this.forceUpdate();
          }
        } else {
          this.setState({shake: true});
        }

        return;
      }

      // Enter - navigate to selection
      if (this.state.matches.length && evt.keyCode === KEY_RETURN) {
        domUtils.preventEvent(evt);
        this.navigate();

        return;
      }

      // Provides arrow key functionality and highlights active suggestion
      if (evt.keyCode === KEY_DOWN || evt.keyCode === KEY_UP) {
        domUtils.preventEvent(evt); // Prevent jumping between input start / end

        const {selected} = this.state;
        let selectedIndex;

        if (evt.keyCode === KEY_DOWN && selected < this.state.matches.length - 1) {
          selectedIndex = selected + 1;

          this.setState({selected: selectedIndex});
        }

        if (evt.keyCode === KEY_UP && selected > 0) {
          selectedIndex = selected - 1;

          this.setState({selected: selectedIndex});
        }

        if (this.listRef.current?.children[selectedIndex]) {
          domUtils.scrollToElement({element: this.listRef.current?.children[selectedIndex]});
        }

        return;
      }

      // Fill input with matching text after pressing right arrow
      if (this.state.matches.length && (evt.keyCode === KEY_RIGHT || evt.keyCode === KEY_TAB)) {
        evt.preventDefault();
        highlightedTextCache.clear();

        const {matches, selected} = this.state;

        if (evt.keyCode === KEY_TAB) {
          const value = matches[selected]?.value;

          if (value?.collectionName) {
            return;
          }
        }

        const autocompleteValue = matches[selected]?.key || matches[selected]?.value?.hostname;

        this.setState({value: autocompleteValue}, () => {
          // Move cursor to end of value
          evt.target.scrollLeft = evt.target.scrollWidth;
          evt.target.setSelectionRange(evt.target.value.length, evt.target.value.length);

          this.forceUpdate();
        });

        return;
      }

      if (generalUtils.cmdOrCtrlPressed(evt)) {
        if (evt.keyCode === KEY_BACK_SPACE) {
          if (evt.shiftKey) {
            // Search History: Clear all
            this.handleDeleteAllSearchItems(evt);
          } else if (generalUtils.isMac() && (this.state.value || this.props.filter)) {
            // Mac OS: CMD + DEL - clear line
            this.clearFilter();
          } else {
            // Search History: Clear selected item
            this.handleDeleteSearchItem(evt);
          }

          return;
        }
      }

      // Escape key should close modal
      if (this.props.active && evt.keyCode === KEY_ESCAPE) {
        await this.toggleModal();

        return;
      }

      // Clear filter
      if (!this.state.value?.length && this.props.filter && evt.keyCode === KEY_BACK_SPACE) {
        this.clearFilter();

        return;
      }
    }
  }

  async handleClose() {
    await this.toggleModal();
  }

  handleOnClick(item, evt) {
    this.setState({selected: item}, _.partial(this.navigate, evt));
  }

  async handleOnFilterClick(evt) {
    domUtils.preventEvent(evt);

    const {matches, selected} = this.state;
    const {filter} = this.props;
    const value = matches[selected].value;

    if (!filter && value?.collectionName) {
      await reactUtils.setStateAsync(getEmptyState(), this);
      this.props.dispatch({type: 'SET_INSTANT_SEARCH_FILTER', data: value.collectionName});
      // Force update to trigger input padding calculations based on ref.
      this.forceUpdate();
    }
  }

  async handleOnChange(evt) {
    if (evt.target.value.length) {
      highlightedTextCache.clear();

      if (this.props.filter?.length) {
        this.setState({value: evt.target.value, selected: 0}, () => {
          this.debounceAutocompleteSearch();
        });
      } else {
        const matches = [];

        if (this.state.routesIndex) {
          for (const {doc: match} of this.state.routesIndex.search(evt.target.value, 25, {
            enrich: true,
            pluck: 'viewName',
          })) {
            const {viewName: key, isAvailable, ...value} = match;

            if (isAvailable && isAvailable(this.context.store.getState()) === false) {
              continue;
            }

            matches.push({key, value});
          }
        }

        this.setState({matches, value: evt.target.value, selected: 0, shake: false, noResultsFound: !matches.length});
      }
    } else {
      const {filter} = this.props;

      if (filter) {
        // Clear match for filtered collection
        this.props.dispatch({type: `${filter.toUpperCase()}_GET_MATCHES`, data: matchesDefaults[filter]});
      }

      await reactUtils.setStateAsync(getEmptyState(), this);
    }
  }

  handleAnimationEnd() {
    this.setState({shake: false});
  }

  handleDeleteAllSearchItems(evt) {
    domUtils.preventEvent(evt);
    this.context.store.runSaga(updateInstantSearchHistory, {history: [], dispatch: true}).toPromise();
  }

  handleDeleteSearchItem(evt) {
    domUtils.preventEvent(evt);

    const {matches, selected} = this.state;

    if (matches.length) {
      const value = matches[selected].value;
      const newHistory = this.props.history.filter(item => !_.isEqual(value, item));

      this.context.store.runSaga(updateInstantSearchHistory, {history: newHistory, dispatch: true}).toPromise();
    }
  }

  async clearFilter() {
    await reactUtils.setStateAsync(getEmptyState(), this);
    this.props.dispatch({type: 'SET_INSTANT_SEARCH_FILTER', data: null});
  }

  selectSuggestion(selected) {
    this.setState({selected});
  }

  async navigate(evt) {
    const {store, router} = this.context;
    const {matches, selected} = this.state;
    const dest = matches[selected].value;

    if (dest) {
      if (dest.href) {
        const filter = this.props.filter || dest.collectionName;

        const routeName = this.state.routesIndex?.search(filter, {
          limit: 1,
          enrich: true,
          pluck: 'collectionName',
        })?.[0]?.doc.name;
        const params = {id: hrefUtils.getId(dest.href)};

        if (schemaAutocompleteMethodsMap.get(filter).params.includes('pversion')) {
          params.pversion = 'draft';
        }

        // Parse route into route translatable form e.g.
        // 'app.workloads.list' --> 'workloads'
        // 'app.workloads.vens.list' --> 'workloads.vens'
        const parsedRouteName = routeName.slice(routeName.indexOf('.') + 1, routeName.lastIndexOf('.'));
        const mostRecentSearch = this.props.history[0];

        if (
          matches[selected]?.key !== mostRecentSearch?.value ||
          dest.hostname !== mostRecentSearch?.value ||
          dest.name !== mostRecentSearch?.value
        ) {
          const historyObject = {
            value: matches[selected]?.key || dest.hostname,
            name: `${parsedRouteName}.item`,
            collectionName: filter,
            href: dest.href,
          };

          if (params) {
            historyObject.params = params;
          }

          const history = [
            historyObject,
            ...this.props.history.filter(historyItem => !_.isEqual(historyItem, historyObject)),
          ];
          let isLegacy = false;

          if (router.routesMap.get(`app.${parsedRouteName}.item`)) {
            let itemRoute = router.routesMap.get(`app.${parsedRouteName}.item`);

            if (itemRoute.redirectTo) {
              itemRoute = router.routesMap.get(itemRoute.redirectTo);
              isLegacy = itemRoute.component?.name === 'JumpToOld';
            } else {
              isLegacy = itemRoute.component?.name === 'JumpToOld';
            }
          }

          // Since we are firing off an API request which could take N time to resolve, let's display a loading
          // indicator early so the user understands a change is occurring and use await to ensure the call is
          // finished before routing to the legacy app.
          if (isLegacy) {
            progressBar.start({delay: false});
            await store.runSaga(updateInstantSearchHistory, {history, dispatch: true}).toPromise();
          } else {
            store.runSaga(updateInstantSearchHistory, {history, dispatch: true}).toPromise();
          }
        }

        await this.toggleModal();
        // Navigate to item view
        this.context.navigate({to: `${parsedRouteName}.item`, params, evt});
      } else {
        const navigateOptions = {to: dest.name.split('app.')[1], evt};

        if (dest.params) {
          navigateOptions.params = dest.params;
        }

        const mostRecentSearch = this.props.history[0];

        if (matches[selected]?.key !== mostRecentSearch?.value) {
          const historyObject = {
            value: matches[selected]?.key,
            name: dest.name,
          };

          if (dest.params) {
            historyObject.params = dest.params;
          }

          if (dest.collectionName) {
            historyObject.collectionName = dest.collectionName;
          }

          const history = [
            historyObject,
            ...this.props.history.filter(historyItem => !_.isEqual(historyItem, historyObject)),
          ];
          let isLegacy = false;

          if (router.routesMap.get(dest.name)) {
            let itemRoute = router.routesMap.get(dest.name);

            if (itemRoute.redirectTo) {
              itemRoute = router.routesMap.get(itemRoute.redirectTo);
              isLegacy = itemRoute.component?.name === 'JumpToOld';
            } else {
              isLegacy = itemRoute.component?.name === 'JumpToOld';
            }
          }

          // Since we are firing off an API request which could take N time to resolve, let's display a loading
          // indicator early so the user understands a change is occurring and use await to ensure the call is
          // finished before routing to the legacy app.
          if (isLegacy) {
            progressBar.start({delay: false});
            await store.runSaga(updateInstantSearchHistory, {history, dispatch: true}).toPromise();
          } else {
            store.runSaga(updateInstantSearchHistory, {history, dispatch: true}).toPromise();
          }
        }

        await this.toggleModal();
        // Navigate to menu level view
        this.context.navigate(navigateOptions);
      }
    }
  }

  async toggleModal() {
    const {filter} = this.props;

    if (filter) {
      // Clear match for filtered collection
      this.props.dispatch({type: `${filter.toUpperCase()}_GET_MATCHES`, data: matchesDefaults[filter]});
    }

    await this.props.dispatch({type: 'TOGGLE_INSTANT_SEARCH'});
    await this.clearFilter();
  }

  highlightText(match, input) {
    let matchLocations = [];

    if (input?.trim()) {
      const escapedInput = input.trim().replace(/[$()*+.?[\\\]^{|}]/g, '\\$&');
      const regex = new RegExp(escapedInput, 'gi');
      let regexMatch;

      // Perform regex match on matching keys based on user input.
      // e.g. User enters "pro" which matches "Pairing Profile", this regex will execute one match at a time
      // and return the indexes of where "pro" occurs. Since RegExp is stateful we can re-execute it in order to capture
      // all matches until there are no more matches (where the regex returns null).
      if (highlightedTextCache.has(match)) {
        matchLocations = highlightedTextCache.get(match);
      } else {
        do {
          regexMatch = regex.exec(match);

          if (regexMatch) {
            matchLocations.push([regexMatch.index, regex.lastIndex]);
            highlightedTextCache.set(match, matchLocations);
          }
        } while (regexMatch !== null);
      }
    }

    const result = match?.split('').reduce((nodes, char, index) => {
      let currentNode = char;

      for (const location of matchLocations) {
        // Bold the element if the character is within the location tuple range.
        if (index >= location[0] && index < location[1]) {
          currentNode = (
            <span key={index} className={styles.highlight}>
              {char}
            </span>
          );
        }
      }

      // Concatenate strings to limit elements from crowding the dom.
      if (nodes[nodes.length - 1]) {
        const lastNode = nodes[nodes.length - 1];

        if (typeof currentNode === 'string' && typeof lastNode === 'string') {
          nodes.pop();
          currentNode = lastNode + char;
        }
      }

      nodes.push(currentNode);

      return nodes;
    }, []);

    return result;
  }

  formatHistoryItem(historyItem) {
    let historyItemText = historyItem.key;

    if (
      historyItem.value.collectionName &&
      this.state.routesIndex?.search(historyItem.value.collectionName, {
        limit: 1,
        enrich: true,
        pluck: 'collectionName',
      })?.[0]?.doc.viewName !== historyItemText
    ) {
      historyItemText = `${
        this.state.routesIndex?.search(historyItem.value.collectionName, {
          limit: 1,
          enrich: true,
          pluck: 'collectionName',
        })?.[0]?.doc.viewName
      } - ${historyItemText}`;
    }

    return historyItemText;
  }

  render() {
    const {selected, value, matches, shake, routesIndex, noResultsFound} = this.state;
    const {active, loading, filter, history} = this.props;
    const showHistory = history.length > 0 && !(filter || value?.length);
    let suggestions;

    if (loading) {
      suggestions = _.range(4).map(value => (
        <li data-tid="is-suggestion-loading-skeleton" key={value} className={styles.suggestionItem}>
          <span className={styles.textSkeleton} />
        </li>
      ));
    } else {
      suggestions = matches.map((match, index) => (
        <li
          data-tid={`is-suggestion-${index}`}
          key={index}
          className={
            index === selected
              ? styles.selectedSuggestionItem
              : showHistory
              ? styles.suggestionHistoryItem
              : styles.suggestionItem
          }
          onClick={_.partial(this.handleOnClick, index)}
          onMouseOver={_.partial(this.selectSuggestion, index)}
        >
          <div className={styles.animateSuggestionItem}>
            {showHistory && (
              <Icon
                theme={{icon: index === selected ? styles.recentSearchesIconSelected : styles.recentSearchesIcon}}
                name="search"
              />
            )}
            <span className={styles.suggestionText}>
              {showHistory
                ? this.formatHistoryItem(match)
                : this.highlightText(match.key || match.value?.hostname || match, value)}
            </span>
            <span className={cx(styles.actions, styleUtils.gapMedium, styleUtils.gapHorizontal)}>
              {match.value?.collectionName && (
                <Button
                  size="small"
                  color="standard"
                  theme={{button: styles.button}}
                  text={intl('InstantSearch.TabToFilter')}
                  icon="filter"
                  onClick={this.handleOnFilterClick}
                />
              )}
              {showHistory && (
                <Tooltip
                  placement="top"
                  content={`${intl('Common.Delete')} (${generalUtils.isMac() ? 'Cmd + Delete' : 'Ctrl + Delete'})`}
                >
                  <Button
                    size="small"
                    noFill
                    noAnimateInAndOut
                    tid="is-delete-search-item"
                    theme={styles}
                    themePrefix={index === selected ? 'selectedDelete-' : 'delete-'}
                    icon="close"
                    aria-label={`${intl('Common.Delete')} (${generalUtils.isMac() ? 'Cmd + Delete' : 'Ctrl + Delete'})`}
                    onClick={this.handleDeleteSearchItem}
                  />
                </Tooltip>
              )}
            </span>
          </div>
        </li>
      ));
    }

    const filterViewName = routesIndex?.search(filter, {enrich: true, limit: 1, pluck: 'collectionName'})?.[0]?.doc
      .viewName;
    const hint = this.getHint(matches && matches[selected], value, selected);
    const placeholder = this.getPlaceholder(value, filterViewName);

    return (
      active && (
        <Modal full notResizable autoFocus tid="instant-search" theme={styles} minHeight="0" onClose={this.handleClose}>
          <div
            className={shake ? styles.shake : styles.container}
            style={{animationPlayState: shake ? 'running' : 'paused'}}
            onAnimationEnd={this.handleAnimationEnd}
          >
            <Icon theme={styles} name="search" />
            {filterViewName && (
              <span data-tid="is-filter" ref={this.filterRef} className={styles.filter}>
                {filterViewName}
              </span>
            )}
            <Input
              tid="instantSearchHint"
              noWrap
              tabIndex="-1"
              readOnly
              value={hint}
              placeholder={placeholder}
              style={{
                paddingLeft: filterViewName && `calc(${this.filterRef?.current?.clientWidth}px + var(--55px))`,
                paddingRight: value.length ? 'calc(var(--100px) + var(--40px))' : '0',
              }}
              theme={{
                input: suggestions.length ? styles.inputBorderRadiusTopHint : styles.inputHint,
              }}
            />
            <Input
              tid="instantSearchInput"
              noWrap
              ref={this.inputRef}
              value={value}
              style={{
                paddingLeft: filterViewName && `calc(${this.filterRef?.current?.clientWidth}px + var(--55px))`,
                paddingRight: value.length ? 'calc(var(--100px) + var(--40px))' : '0',
              }}
              theme={{
                input: suggestions.length ? styles.inputBorderRadiusTop : styles.input,
              }}
              onChange={this.handleOnChange}
            />
            {noResultsFound && (
              <span data-tid="is-no-results" className={styles.noResultsFound}>
                {intl('Common.NoResultsFound')}
              </span>
            )}
            {showHistory && (
              <div className={styles.recentSearches}>
                <span data-tid="is-recent-searches" className={styles.recentSearchesText}>
                  {intl('InstantSearch.RecentSearches')}
                </span>
                <Tooltip
                  placement="top"
                  content={`${intl('InstantSearch.ClearAll')} (${
                    generalUtils.isMac() ? 'Cmd + Shift + Delete' : 'Ctrl + Shift + Delete'
                  })`}
                >
                  <Button
                    size="small"
                    noFill
                    color="standard"
                    tid="is-clear-all"
                    theme={{button: styles.clearAllButton}}
                    aria-label={`${intl('InstantSearch.ClearAll')} (${
                      generalUtils.isMac() ? 'Cmd + Shift + Delete' : 'Ctrl + Shift + Delete'
                    })`}
                    text={intl('InstantSearch.ClearAll')}
                    onClick={this.handleDeleteAllSearchItems}
                  />
                </Tooltip>
              </div>
            )}
            <div
              className={styles.listWrapper}
              style={{
                height: suggestions.length ? `calc(${suggestions.length} * var(--40px))` : 0,
                overflowY: suggestions.length >= 7 ? 'auto' : 'hidden',
              }}
            >
              <Modal.StickyShadow top />
              <ul ref={this.listRef} className={styles.suggestions}>
                {suggestions}
              </ul>
              <Modal.StickyShadow bottom />
            </div>
            {suggestions.length > 0 && (
              <div className={styles.shortcuts}>
                <span className={styles.shortcut}>↑</span>
                <span className={styles.shortcut}>↓</span>
                <span className={styles.shortcutText}>to navigate</span>
                <span className={styles.shortcutOffset}>↵</span>
                <span className={styles.shortcutText}>to select</span>
                <span className={styles.shortcut}>esc</span>
                <span className={styles.shortcutText}>to close</span>
              </div>
            )}
          </div>
        </Modal>
      )
    );
  }
}
