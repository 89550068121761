/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from 'intl';
import * as PropTypes from 'prop-types';
import {Component} from 'react';
import {connect} from 'react-redux';
import {object, string, array} from 'yup';
import {RedirectError} from 'errors';
import {reactUtils, hrefUtils, generalUtils} from 'utils';
import {isAPIAvailable} from 'api/apiUtils';
import ServiceState from '../../ServiceState';
import {getServiceItem} from '../ServiceItemState';
import {fetchServiceFacet} from '../../List/ServiceListSaga';
import * as FormUtils from 'components/Form/FormUtils';
import {AttributeList, Form, ToolBar, ToolGroup, Button, Modal, TypedMessages} from 'components';
import {AppContext} from 'containers/App/AppUtils';
import {HeaderProps} from 'containers';
import {osOptions, getGridSettings} from './ServiceEditConfig';
import {createService, updateService} from './ServiceEditSaga';
import {fetchServiceItem} from '../ServiceItemSaga';
import {
  getPortAndProtocolString,
  validateServiceDefinitions,
  isValidProcessName,
  sanitizePortProto,
  reverseLookupProtocol,
} from 'containers/Service/ServiceUtils';
import styles from './ServiceEdit.css';

// Get formik's mandatory initialValues props for form setup
const getInitialValues = pversionObj => {
  const os = pversionObj?.windows_services ? 'windows' : 'linux';
  let rows;

  const serviceDefinitions = pversionObj ? pversionObj.windows_services || pversionObj.service_ports : [];

  if (serviceDefinitions.length > 0) {
    // If service definition exists then initialize formik rows with ports and process
    rows = serviceDefinitions.map(item => {
      const portProto = getPortAndProtocolString(item);

      return {
        key: generalUtils.randomString(5, true),
        selectable: true,
        data: {
          portProto: portProto ? [{categoryKey: 'portProto', value: portProto, detail: sanitizePortProto(item)}] : [],
          service_name: item.service_name || '',
          process_name: item.process_name || '',
        },
      };
    });
  } else {
    // If service definition does not exists i.e. a case of create service, add an empty row to editor grid
    const key = generalUtils.randomString(5, true);
    const emptyRow = getGridSettings({os, isEdit: false}).getInitialRow(key);

    rows = [emptyRow];
  }

  return {
    name: pversionObj?.name || '',
    description: pversionObj?.description || '',
    os: Form.Utils.findSelectedOption(osOptions(), os),
    rows,
  };
};

// Initial State
const getInitialState = props => ({
  id: props.versions?.pversionObj?.href ? hrefUtils.getId(props.versions.pversionObj.href) : undefined,
  initialValues: getInitialValues(props.versions?.pversionObj),
  isEdit: Boolean(props.versions?.pversionObj?.href),
  saving: false,
});

// when container is controlled we will pass the data via containerProps, as opposed to connecting to the store
const mapStateToProps = (state, props) => (props.controlled ? {} : getServiceItem(state));

@connect(mapStateToProps, null, null, {forwardRef: true})
export default class ServiceEdit extends Component {
  static contextType = AppContext;
  static reducers = ServiceState;
  static prefetch = function* () {
    if (!isAPIAvailable('service.update')) {
      throw new RedirectError({
        to: 'services.item',
        thisFetchIsDone: true,
      });
    }
  };

  static propTypes = {
    versions: PropTypes.shape({
      pversionObj: PropTypes.object,
    }),
    controlled: PropTypes.bool,

    onValid: PropTypes.func,

    isInitialValid: PropTypes.bool, // When we create suggested service, set this to true

    // form defaults to all operating systems, which prevents the user from creating windows services
    hideOperatingSystems: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    this.state = getInitialState(props);

    this.osOptions = osOptions();

    this.schemas = object({
      name: string().max(255, intl('Common.NameIsTooLong')).required(Form.emptyMessage),
      description: string(),
      os: object().nullable().required(Form.emptyMessage),
      rows: array().when('os', {
        is: Form.Utils.findSelectedOption(this.osOptions, 'windows'),
        then: array()
          .required()
          .of(
            object({
              data: object().shape(
                {
                  portProto: array()
                    .nullable()
                    .when(['process_name', 'service_name'], {
                      is: (processName, serviceName) => !processName && !serviceName,
                      then: array().nullable().required(intl('Services.Mixin.PortOrProcessIsRequired')),
                    }),
                  process_name: string()
                    .test(
                      'isValidProcessName',
                      intl('Port.InvalidProcess'),
                      value => !value || isValidProcessName(value),
                    )
                    .when(['portProto', 'service_name'], (portProto, serviceName, schema) => {
                      if (!portProto?.length && !serviceName) {
                        return schema.required(intl('Services.Mixin.PortOrProcessIsRequired'));
                      }
                    }),
                  service_name: string()
                    .max(255, intl('Port.InvalidServiceName'))
                    .when(['portProto', 'process_name'], (portProto, processName, schema) => {
                      if (!portProto?.length && !processName) {
                        return schema.required(intl('Services.Mixin.PortOrProcessIsRequired'));
                      }
                    }),
                },
                [
                  ['portProto', 'process_name'],
                  ['portProto', 'service_name'],
                  ['process_name', 'service_name'],
                ],
              ),
            }).test(function (service) {
              const errorMessage = validateServiceDefinitions(service, this.parent);

              if (errorMessage) {
                return this.createError({path: FormUtils.getGridRowErrorPath(this.path), message: errorMessage});
              }

              return true;
            }),
          )
          .min(1, intl('Services.Mixin.AtLeastOneProcess')),
        otherwise: array()
          .required()
          .of(
            object({
              data: object({
                portProto: array().nullable().required(Form.emptyMessage),
              }),
            }).test(function (service) {
              const errorMessage = validateServiceDefinitions(service, this.parent);

              if (errorMessage) {
                return this.createError({path: FormUtils.getGridRowErrorPath(this.path), message: errorMessage});
              }

              return true;
            }),
          )
          .min(1, intl('Services.Mixin.AtLeastOnePort')),
      }),
    });

    this.handleSave = this.handleSave.bind(this);
    this.renderForm = this.renderForm.bind(this);
    this.renderEditAlert = this.renderEditAlert.bind(this);
    this.handleErrorClose = this.handleErrorClose.bind(this);
    this.handleNameChangeDebounce = _.debounce(this.handleNameChangeDebounce.bind(this), 500);
    this.setExistingName = this.setExistingName.bind(this);
    this.handleNameChange = this.handleNameChange.bind(this);
  }

  componentDidMount() {
    if (!this.state.isEdit) {
      // Note: Important to trim value to pass to facet API
      const value = this.formik.values.name.trim();

      if (value) {
        this.fetchFacet(value);
      }
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.versions !== nextProps.versions) {
      return {...nextProps, ...getInitialState(nextProps)};
    }

    return null;
  }

  componentWillUnmount() {
    cancelAnimationFrame(this.publishValidation);
  }

  getPayload() {
    const {values} = this.formik;
    const {isEdit} = this.state;
    const payload = {name: values.name, description: values.description};

    if (isEdit && values.name === this.props.versions?.pversionObj?.name) {
      payload.isNameUnchanged = true;
    }

    if (values.os.value === 'windows') {
      payload.windows_services = values.rows.map(o => {
        const item = {...o.data.portProto[0]?.detail};

        if (item.proto) {
          // Ensure that service protocols are always numbers (udp -> 17), because service definitions file uses strings
          item.proto = reverseLookupProtocol(item.proto);
        }

        if (o.data.process_name) {
          item.process_name = o.data.process_name;
        }

        if (o.data.service_name) {
          item.service_name = o.data.service_name;
        }

        return item;
      });
    } else {
      payload.service_ports = values.rows.map(o => {
        const result = {...o.data.portProto[0]?.detail};

        if (result.proto) {
          // Ensure that service protocols are always numbers (udp -> 17), because service definitions file uses strings
          result.proto = reverseLookupProtocol(result.proto);
        }

        return result;
      });
    }

    return payload;
  }

  // Set Existing Service Name
  setExistingName() {
    this.setState({warningMessage: intl('Services.DuplicateMessage')});
  }

  // Handle Edit Error
  handleErrorClose() {
    this.setState({error: null});
  }

  // Handle the input field
  async handleNameChange(evt) {
    const {setFieldValue} = this.formik;
    const value = evt.target.value;

    this.setState({warningMessage: null});

    // Update the Form.Input name value since this component is controlling
    setFieldValue('name', value);

    // Don't need to call debounce when value is empty
    if (value.trim()) {
      // Note: Invoke debounce here to delay after calling setFieldValue for formik's values to update properly
      this.handleNameChangeDebounce();
    }
  }

  // Use debounce to wait
  handleNameChangeDebounce() {
    const {
      props: {excludeNames},
      formik: {values},
    } = this;

    if (this.isNameEqual(excludeNames)) {
      this.setExistingName();

      return;
    }

    // Note: Important to trim value to pass to facet API
    const value = values.name.trim();

    // Don't need to make request when:
    // 1. the original service name match current in Edit form
    // 2. value is empty
    if (value && (!this.state.isEdit || this.state.initialValues.name !== value)) {
      this.fetchFacet(value);
    }
  }

  async handleSave() {
    const {setSubmitting} = this.formik;
    const {fetcher, navigate} = this.context;
    const {isEdit} = this.state;
    const {isNameUnchanged, ...data} = this.getPayload();

    let id;

    try {
      await reactUtils.setStateAsync({saving: true}, this);
      setSubmitting(true);

      if (isEdit) {
        id = this.state.id;
        await fetcher.spawn(updateService, {params: {service_id: id, pversion: 'draft'}, data});
      } else {
        const {
          data: {href},
        } = await fetcher.spawn(createService, {params: {pversion: 'draft'}, data});

        id = hrefUtils.getId(href);
      }

      // Wait progress on save button to finish
      await new Promise(onSaveDone => this.setState({onSaveDone, saving: false}));
      // navigate to summary
      await fetcher.fork(fetchServiceItem, {name: 'services.item.view', params: {id, pversion: 'draft'}});

      navigate({to: 'services.item', params: {id, pversion: 'draft'}});
    } catch (error) {
      this.setState({error, saving: false});
      setSubmitting(false);
    }
  }

  async fetchFacet(value) {
    const {
      context: {fetcher},
    } = this;

    if (value) {
      if (this.facetsFetch) {
        // Cancel task if it is still running
        fetcher.cancel(this.facetsFetch);
      }

      this.facetsFetch = fetcher.fork(fetchServiceFacet, {
        query: {facet: 'name', query: value},
        params: {pversion: 'draft'},
      });

      try {
        const {data} = await this.facetsFetch;

        if (this.isNameEqual(data?.matches)) {
          this.setExistingName();

          return;
        }
      } catch (error) {
        await reactUtils.setStateAsync({facet: {error}}, this);
      }
    }
  }

  // Determine if name already exists
  isNameEqual(existingNames = []) {
    const value = this.formik.values.name.trim();

    return (
      existingNames.some(name => !name.localeCompare(value, intl.locale, {sensitivity: 'base'})) &&
      (!this.state.isEdit ||
        value.localeCompare(this.state.initialValues.name, intl.locale, {sensitivity: 'base'}) !== 0)
    );
  }

  // Render alert message when edit or create fails
  renderEditAlert() {
    const {error, isEdit} = this.state;
    const token = _.get(error, 'data[0].token');
    const title = isEdit ? intl('Services.Errors.Edit') : intl('Services.Errors.Create');
    const message = (token && intl(`ErrorsAPI.err:${token}`)) || _.get(error, 'data[0].message', error.message);

    return (
      <Modal.Alert title={title} buttonProps={{tid: 'ok', text: intl('Common.OK'), onClick: this.handleErrorClose}}>
        <TypedMessages>
          {[
            {
              icon: 'error',
              content: message,
            },
          ]}
        </TypedMessages>
      </Modal.Alert>
    );
  }

  renderForm(options) {
    const {values, errors, touched} = options;
    const {onValid, controlled, hideOperatingSystems} = this.props;
    const {saving, onSaveDone, error, isEdit, id, warningMessage} = this.state;
    const gridSettings = getGridSettings({os: values.os.value, isEdit});
    const saveDisabled = options.isValid === false;

    this.formik = options;

    if (controlled && typeof onValid === 'function') {
      this.publishValidation = requestAnimationFrame(() => {
        onValid(saveDisabled);
      });
    }

    const gridIsTouched =
      touched.rows ||
      values.rows.length > 1 ||
      // Otherwise check if any one of the field in row has values entered by user
      Boolean(
        values.rows[0].data.portProto.length || values.rows[0].data.service_name || values.rows[0].data.process_name,
      );

    return (
      <>
        {!controlled && (
          <ToolBar>
            <ToolGroup>
              <Button
                icon="save"
                tid="save"
                disabled={saveDisabled}
                text={intl('Common.Save')}
                progressCompleteWithCheckmark
                progress={saving}
                progressError={Boolean(error)}
                onClick={this.handleSave}
                onProgressDone={onSaveDone}
              />
              <Button.Link
                color="standard"
                disabled={saving || Boolean(onSaveDone)}
                icon="cancel"
                tid="cancel"
                text={intl('Common.Cancel')}
                link={isEdit ? {to: 'services.item', params: {id, pversion: 'draft'}} : 'services.list'}
              />
            </ToolGroup>
          </ToolBar>
        )}
        <AttributeList>
          {[
            controlled ? null : {divider: true},
            {title: intl('Common.General')},
            {
              tid: 'name',
              key: <Form.Label name="name" title={intl('Common.Name')} />,
              value: (
                <Form.Input
                  name="name"
                  tid="name"
                  onChange={this.handleNameChange}
                  placeholder={intl('Services.Mixin.Placeholder.ServiceName')}
                  warningMessage={warningMessage}
                  autoFocus
                />
              ),
            },
            {
              tid: 'description',
              key: <Form.Label name="description" title={intl('Common.Description')} />,
              value: (
                <Form.Textarea
                  tid="description"
                  name="description"
                  placeholder={intl('Services.Mixin.Placeholder.ServiceDescription')}
                />
              ),
            },
            {divider: true},
            {title: intl('Common.Attributes')},
            //NOTE: os Menu is purely in display. Data sent to API contains one-of windows_services or service_ports
            ...(hideOperatingSystems
              ? []
              : [
                  {
                    tid: 'os',
                    key: <Form.Label name="os" title={intl('Services.Mixin.Os.Title')} />,
                    value: <Form.Selector name="os" options={this.osOptions} disabled={isEdit} />,
                  },
                ]),
            {
              tid: 'service',
              key: <Form.Label showAsterisk name="rows" title={intl('Services.ServiceDefinitions')} />,
              value: (
                <Form.Grid
                  name="rows"
                  settings={gridSettings}
                  gridProps={{secondary: true, theme: styles, offset: controlled ? '0px' : undefined}}
                  //Disable Add button on error OR on create form when Grid is not touched(default empty row already exists)
                  addButtonProps={{
                    disabled: (Array.isArray(errors.rows) && errors.rows.length > 0) || (!isEdit && !gridIsTouched),
                  }}
                />
              ),
              valueGap: 'gap',
            },
          ]}
        </AttributeList>
        {controlled /* Temporary add empty div to expand the ServiceEdit in modal until we have new Selector dropdown */ && (
          <div style={{height: '150px'}} />
        )}
      </>
    );
  }

  render() {
    const {
      props: {versions, controlled, onSave},
      state: {remove, error, initialValues, id},
    } = this;

    return (
      <>
        {!controlled && (
          <HeaderProps
            title={intl('Common.Services')}
            subtitle={versions?.pversionObj?.name}
            up={id ? {to: 'services.item', params: {id, pversion: 'draft'}} : 'services'}
            label={`(${intl(id ? 'Common.Edit' : 'Common.Create')})`}
          />
        )}
        <Form
          enableReinitialize
          schemas={this.schemas}
          initialValues={initialValues}
          onSubmit={onSave}
          isInitialValid={Boolean(
            controlled && versions?.pversionObj && !versions?.pversionObj?.href?.includes('/services/'),
          )}
        >
          {this.renderForm}
        </Form>
        {remove && this.renderRemoveConfirmation()}
        {error && this.renderEditAlert()}
      </>
    );
  }
}
