/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import {connect} from 'react-redux';
import {Component} from 'react';
import {object, string, mixed} from 'yup';
import {APIError} from 'errors';
import {AppContext} from 'containers/App/AppUtils';
import {HeaderProps} from 'containers';
import {Form, AttributeList, Button, ToolBar, ToolGroup} from 'components';
import SubmitButton from 'containers/SubmitButton/SubmitButton';
import {submitAction} from 'containers/SubmitButton/SubmitSaga';
import {fetchContainerWorkloadProfileInstance} from '../Detail/ContainerClusterContainerWorkloadProfileDetailSaga';
import {getContainerWorkloadProfileDetailPage} from '../Detail/ContainerClusterContainerWorkloadProfileDetailState';
import {getId, isContainerClusterHref} from 'utils/href';
import styles from '../Detail/ContainerClusterContainerWorkloadProfileDetail.css';
import {
  getEnforcementBoundariesVisibilitySchema,
  getEnforcementBoundariesInitialValues,
} from 'containers/EnforcementBoundaries/EnforcementBoundariesVisibilityFormUtils';
import {
  managementOptions,
  getContainerClusterContainerWorkloadProfilePayload,
  getLabelSourcesForm,
} from '../../ContainerClusterDetailUtils';

const getInitialValues = props => {
  const {name, description, managed, roleSource, appSource, envSource, locSource, flatLabelMap} = props;
  const {role, app, env, loc, roleAllow, appAllow, envAllow, locAllow} = flatLabelMap; // FormLabelSelector expects legacy format

  return {
    name: name || '',
    description: description || '',
    managed: Form.Utils.findSelectedOption(managementOptions, managed?.toString() ?? 'true'),
    roleSource,
    appSource,
    envSource,
    locSource,
    role,
    app,
    env,
    loc,
    roleAllow,
    appAllow,
    envAllow,
    locAllow,
    ...getEnforcementBoundariesInitialValues(props),
  };
};

const getInitialState = props => ({
  actionError: null,
  onUnsavedPending: null,
  initialValues: getInitialValues(props),
  href: props.href,
});

@connect(getContainerWorkloadProfileDetailPage)
export default class ContainerClusterContainerWorkloadProfileConfig extends Component {
  static prefetch = fetchContainerWorkloadProfileInstance;
  static contextType = AppContext;

  constructor(props) {
    super(props);

    this.state = getInitialState(props);

    const {created_by: createdBy, profileId} = props;

    this.schemas = object({
      name:
        profileId && isContainerClusterHref(createdBy.href) ? string() : string().required(intl('Users.NameRequired')),
      managed: mixed(),
      enforcementMode: getEnforcementBoundariesVisibilitySchema.enforcementMode,
      // Use nested when to look for both managed and enforcementMode
      // ref: https://github.com/jquense/yup/issues/335
      // When managed is selected then visibility_level is required only when enforcementMode !== 'idle'
      // When managed is selected and enforcementMode === 'idle' then visibility_level is not required
      visibility_level: mixed().when('managed', {
        is: val => val.value === 'true',
        then: getEnforcementBoundariesVisibilitySchema.visibility_level,
      }),
      roleSource: Form.Radio.schema,
      role: object().when('roleSource', {
        is: 'assign',
        then: object().required(intl('ContainerClusters.AssignLabelIsRequired')),
        otherwise: object(),
      }),
      appSource: Form.Radio.schema,
      app: object().when('appSource', {
        is: 'assign',
        then: object().required(intl('ContainerClusters.AssignLabelIsRequired')),
        otherwise: object(),
      }),
      envSource: Form.Radio.schema,
      env: object().when('envSource', {
        is: 'assign',
        then: object().required(intl('ContainerClusters.AssignLabelIsRequired')),
        otherwise: object(),
      }),
      locSource: Form.Radio.schema,
      loc: object().when('locSource', {
        is: 'assign',
        then: object().required(intl('ContainerClusters.AssignLabelIsRequired')),
        otherwise: object(),
      }),
    });

    this.handleAlertClose = this.handleAlertClose.bind(this);
    this.handleOnSubmit = this.handleOnSubmit.bind(this);
    this.renderForm = this.renderForm.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    // When a different container cluster href doesn't match previous
    if (prevState.href !== nextProps.href) {
      return getInitialState(nextProps);
    }

    return null;
  }

  handleAlertClose() {
    this.setState({actionError: null});
  }

  async handleOnSubmit(values) {
    const {fetcher, navigate} = this.context;
    const {id, profileId} = this.props;
    const {name, description} = values;
    const nameValue = name || null;

    try {
      const payload = {...getContainerClusterContainerWorkloadProfilePayload(values), name: nameValue, description};

      const {data: result, error: actionError} = await fetcher.spawn(submitAction, {
        target: profileId ? 'editContainerClusterProfile' : 'addContainerClusterProfile',
        obj: {
          params: profileId
            ? {container_cluster_id: id, container_workload_profile_id: profileId}
            : {container_cluster_id: id},
          data: payload,
        },
      });

      if (!fetcher.unmounted && !actionError) {
        if (profileId) {
          navigate({to: 'containerClusters.item.profiles.item', params: {id, profileId}});
        } else {
          navigate({
            to: 'containerClusters.item.profiles.item',
            params: {id, profileId: getId(result.href), pairingKey: result.pairing_key},
          });
        }
      } else {
        this.setState({actionError});
        this.setSubmitting(false);
      }
    } catch (error) {
      // Use APIError to align with ModalMachine.parseErrorsFromProcessResult() data structure
      throw new APIError({data: [{message: error.message}]});
    }
  }

  renderForm(options) {
    const {isValid, isSubmitting, setSubmitting} = options;

    const {actionError} = this.state;

    const {namespaceTerm, namespace, created_by: createdBy, profileId, id, allowCreateTypes} = this.props;

    this.setSubmitting = setSubmitting;
    this.options = options;

    const attributeList = [
      {divider: true},
      {title: intl('Common.General')},
      {
        key: (
          <Form.Label
            name="name"
            showAsterisk={!profileId || !isContainerClusterHref(createdBy.href)}
            title={intl('Common.Name')}
          />
        ),
        value: <Form.Input tid="name" placeholder={intl('Common.ProvideAName')} name="name" />,
      },
      {
        key: <Form.Label name="namespace" title={namespaceTerm} />,
        value: namespace,
      },
      {
        key: <Form.Label name="description" title={intl('Common.Description')} />,
        value: (
          <Form.Textarea
            tid="description"
            placeholder={intl('Common.OptionalDescription')}
            rows={5}
            name="description"
          />
        ),
      },
      {
        key: <Form.Label name="managed" title={intl('Common.Management')} />,
        value: <Form.Selector name="managed" tid="managed" options={managementOptions} />,
      },
    ];

    return (
      <>
        <ToolBar>
          <ToolGroup>
            <SubmitButton
              icon="save"
              type="submit"
              text={intl('Common.Save')}
              isSubmitting={isSubmitting}
              error={actionError}
              disabled={isValid === false}
              onCloseAlert={this.handleAlertClose}
            />
            <Button.Link
              icon="cancel"
              text={intl('Common.Cancel')}
              color="standard"
              tid="cancel"
              link={
                profileId
                  ? {to: 'containerClusters.item.profiles.item', params: {id, profileId}}
                  : {to: 'containerClusters.item.profiles.list', params: {id}}
              }
            />
          </ToolGroup>
        </ToolBar>
        <AttributeList valuesGap="gapLarge">{attributeList}</AttributeList>
        <AttributeList valuesGap="gapMedium">{getLabelSourcesForm(options, allowCreateTypes)}</AttributeList>
      </>
    );
  }

  render() {
    const {clusterName, name, namespace, profileId, id} = this.props;

    return (
      <>
        <HeaderProps
          title={intl('Menu.ContainerClusters', {multiple: true})}
          up={
            profileId
              ? {to: 'containerClusters.item.profiles.item', params: {id, profileId}}
              : {to: 'containerClusters.item.profiles.list', params: {id}}
          }
          subtitle={profileId ? `${clusterName} - ${name || namespace}` : clusterName}
          label={profileId ? `(${intl('Common.Edit')})` : intl('ContainerClusters.ContainerWorkloadProfilesAdd')}
        />
        <Form
          enableReinitialize
          schemas={this.schemas}
          initialValues={this.state.initialValues}
          onSubmit={this.handleOnSubmit}
        >
          {this.renderForm}
        </Form>
        <div className={styles.bottomSpacer} />
      </>
    );
  }
}
