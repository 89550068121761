/**
 * Copyright 2021 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import {Pill, Diff} from 'components';
import {edge} from 'api/apiUtils';
import {getPortAndProtocolString} from 'containers/Service/ServiceUtils';
import {
  enforcementModeView,
  enforcementModeViewEdge,
  visibilityLevelView,
  visibilityLevelViewEdge,
} from 'containers/EnforcementBoundaries/EnforcementBoundariesUtils';
import {getKeyLifespanText} from 'containers/PairingProfile/PairingProfileUtils';
import {getTypeAndRoutes} from 'containers/Provisioning/ProvisioningUtils';
import {eventKeyMap, severitySetting} from 'containers/Events/EventsUtils';
import {getDisconnectString, getGoodbyeString} from 'containers/OfflineTimers/OfflineTimersUtils';
import {roleStrings} from 'containers/RBAC/RBACUtils';
import {typesName} from 'components/Pill/Label/LabelUtils';
import {getActivationType} from 'containers/Ven/VenUtils';
import styles from './EventsDetails.css';

/**
 * Simple utils
 */
const getDiffType = type => (type !== 'create' ? 'unified' : null);
const diffWords = (value, oldValue, diffProps = {}) =>
  (value || oldValue) && (
    <Diff diffFunc="diffWords" value={value} oldValue={oldValue} {...{noTooltip: true, ...diffProps}} />
  );

/**
 * String utils
 */
// takes N arguments, filters out falsy values and trims leading and hanging newlines
const stringBuilder = (...strings) =>
  strings
    .filter(Boolean)
    .join('\n')
    .replace(/^\n|\n$/g, '');

const prefixString = (prefix, input, allowEmptyString = false) =>
  (allowEmptyString ? typeof input === 'string' : Boolean(input)) ? `${prefix}: ${input}` : null;

const prefixMultiLineString = (prefix, string) => (string ? `${prefix}:\n${string}` : null);

// this is used for quoted text, where exact string is important - e.g. description, provision note
const prefixQuotedText = (prefix, value) => prefixString(prefix, typeof value === 'string' && `"${value}"`);

const getName = (name, type) =>
  name && (
    <Diff.Text
      noTooltip
      type={getDiffType(type)}
      value={prefixString(intl('Common.Name'), name.after)}
      oldValue={prefixString(intl('Common.Name'), name.before)}
    />
  );

const getDescription = (description, type) =>
  (description?.before || description?.after) && (
    <Diff.Text
      noTooltip
      type={getDiffType(type)}
      value={prefixQuotedText(intl('Common.Description'), description.after ?? '')}
      oldValue={type !== 'create' ? prefixQuotedText(intl('Common.Description'), description.before ?? '') : null}
    />
  );

const getIPVersion = ipVersion =>
  ipVersion === '4' ? intl('Protocol.IPv4') : ipVersion === '6' ? intl('Protocol.IPv6') : null;

const convertBoolToYesOrNo = value =>
  typeof value === 'boolean' ? (value ? intl('Common.Yes') : intl('Common.No')) : null;

const getEnabled = (enabled, type) =>
  diffWords(
    prefixString(intl('Common.Enabled'), convertBoolToYesOrNo(enabled?.after)),
    prefixString(intl('Common.Enabled'), convertBoolToYesOrNo(enabled?.before)),
    {type: getDiffType(type)},
  );

const getEnforcementMode = (mode, type) => {
  const value = prefixString(
    intl('Common.Enforcement'),
    mode?.after && (edge ? enforcementModeViewEdge[mode.after] : enforcementModeView()[mode.after])?.name,
  );

  const oldValue = prefixString(
    intl('Common.Enforcement'),
    mode?.before && (edge ? enforcementModeViewEdge[mode.before] : enforcementModeView()[mode.before])?.name,
  );

  return diffWords(value, oldValue, {type: getDiffType(type)});
};

const getCertIssuer = issuer => issuer && prefixString(intl('Settings.CertificateIssuer'), issuer);

/**
 * Rule utils
 */
// grabs href from object with different keys
const getConsumerOrProviderValue = ({label, label_group: labelGroup, ip_list: ip, virtual_service: virtualService}) =>
  (label || labelGroup || ip || virtualService)?.href ?? intl('Workloads.All');

const computeConsumers = consumers => {
  const value = prefixMultiLineString(
    intl('Common.Consumers'),
    consumers?.created?.map(getConsumerOrProviderValue).join('\n'),
  );

  const oldValue = prefixMultiLineString(
    intl('Common.Consumers'),
    consumers?.deleted?.map(getConsumerOrProviderValue).join('\n'),
  );

  return diffWords(value, oldValue);
};

const computeProviders = providers => {
  const value = prefixMultiLineString(
    intl('Common.Providers'),
    providers?.created?.map(getConsumerOrProviderValue).join('\n'),
  );

  const oldValue = prefixMultiLineString(
    intl('Common.Providers'),
    providers?.deleted?.map(getConsumerOrProviderValue).join('\n'),
  );

  return diffWords(value, oldValue);
};

// TODO: BE bug - https://jira.illum.io/browse/EYE-81615
// TODO: consumers & providers return created & deleted properties - ingress_services ONLY returns created
const computeServices = services => {
  const value = prefixMultiLineString(
    intl('Common.Services'),
    services?.created?.map(({name, href}) => prefixString(name, href)).join('\n'),
  );

  return diffWords(value);
};

const computePortProto = portProto =>
  diffWords(
    prefixMultiLineString(
      intl('Common.PortAndOrProtocol'),
      portProto?.created?.map(getPortAndProtocolString).join('\n'),
    ),
    prefixMultiLineString(
      intl('Common.PortAndOrProtocol'),
      portProto?.deleted?.map(getPortAndProtocolString).join('\n'),
    ),
  );

/**
 * Pill.Diff utils
 */
const generateLabelGroupsDiff = (created, deleted, key) =>
  (created || deleted) && (
    <Pill.LabelDiff
      group
      value={created?.map(({label_group: labelGroup}) => ({
        ...labelGroup,
        key: labelGroup.key ?? key,
        value: labelGroup.name,
      }))}
      oldValue={deleted?.map(({label_group: labelGroup}) => ({
        ...labelGroup,
        key: labelGroup.key ?? key,
        value: labelGroup.name,
      }))}
      pversion="draft"
      insensitive
    />
  );

const generateLabelsDiff = (created, deleted) =>
  (created || deleted) && (
    <Pill.LabelDiff value={created?.map(obj => obj.label ?? obj)} oldValue={deleted?.map(obj => obj.label ?? obj)} />
  );

// output:
// "Labels:"
// <Label 1> <Label 2> <Label 3>... ad nauseam
const generateLabelsDiffWithPrefix = (created, deleted, prefix) => {
  if (!prefix) {
    prefix = edge ? intl('Common.Groups') : intl('Common.Labels');
  }

  const labelsDiff = generateLabelsDiff(created, deleted);

  if (labelsDiff) {
    return (
      <div className={styles.labelsDiffMargin}>
        <span>{`${prefix}:`}</span>
        <div className={styles.labelsDiffGap}>{labelsDiff}</div>
      </div>
    );
  }
};

/**
 * Service Diff Utils
 */
// TODO: BE bug - EYE-81867: missing information for windows based services
export const diffService = (type, {name, description, service_ports: ports}) => (
  <div className={styles.diffWrapper}>
    {getName(name, type)}
    {getDescription(description, type)}
    {computePortProto(ports)}
  </div>
);

/**
 * IP List Diff Utils
 */
const computeIPList = ipList =>
  diffWords(
    prefixMultiLineString(intl('Common.IPList'), ipList?.created?.map(({from_ip: ip}) => ip).join('\n')),
    prefixMultiLineString(intl('Common.IPList'), ipList?.deleted?.map(({from_ip: ip}) => ip).join('\n')),
  );

const computeFQDNs = fqdns =>
  fqdns && (
    <Diff.Text
      value={prefixMultiLineString(intl('Common.Fqdns'), fqdns.created?.map(({fqdn}) => fqdn).join('\n'))}
      oldValue={prefixMultiLineString(intl('Common.Fqdns'), fqdns.deleted?.map(({fqdn}) => fqdn).join('\n'))}
    />
  );

export const diffIPList = (type, {name, description, ip_ranges: ipRanges, fqdns}) => (
  <div className={styles.diffWrapper}>
    {getName(name, type)}
    {getDescription(description, type)}
    {computeIPList(ipRanges, type)}
    {computeFQDNs(fqdns)}
  </div>
);

/**
 * Label Diff Utils
 */
const getLabelType = key => diffWords(prefixString(intl('Common.Type'), typesName[key?.after]));

export const diffLabel = (type, {value: name, key}) => {
  return (
    <div className={styles.diffWrapper}>
      {getName(name, type)}
      {getLabelType(key)}
    </div>
  );
};

/**
 * User Group Diff Utils
 */
export const diffUserGroup = (type, {name, description}) => (
  <div className={styles.diffWrapper}>
    {getName(name, type)}
    {getDescription(description, type)}
  </div>
);

/**
 * Label Group Diff Utils
 */
export const diffLabelGroup = (type, {name, description, labels, sub_groups: labelGroups, key}) => {
  // TODO: BE bug - https://jira.illum.io/browse/EYE-81608
  // label group - 'labels delete' DOESN'T return any resource changes (no idea why - see lack of oldValue)
  // label group 'labelGroups delete' DOES return a resource change, see presence of oldValue
  const labelsDiff = generateLabelsDiff(labels?.created, labels?.deleted);
  const labelGroupsDiff = generateLabelGroupsDiff(labelGroups?.created, labelGroups?.deleted, key);

  return (
    <div className={styles.diffWrapper}>
      {getName(name, type)}
      {getDescription(description, type)}
      <div className={styles.changesGap}>
        {labelsDiff}
        {labelGroupsDiff}
      </div>
    </div>
  );
};

/**
 * Virtual Service Diff Utils
 */
const getPoolTarget = target =>
  target && (
    <Diff.Option
      value={prefixString(
        intl('Common.PoolTarget'),
        target.after &&
          (target.after === 'internal_bridge_network'
            ? intl('VirtualServices.InternalBridgeNetwork')
            : intl('VirtualServices.HostOnly')),
      )}
      oldValue={prefixString(
        intl('Common.PoolTarget'),
        target.before &&
          (target.before === 'internal_bridge_network'
            ? intl('VirtualServices.InternalBridgeNetwork')
            : intl('VirtualServices.HostOnly')),
      )}
    />
  );

const computeServiceOrPorts = (service, portProto) =>
  diffWords(
    prefixString(
      intl('VirtualServices.ServiceOrPorts'),
      service?.after?.href ?? getPortAndProtocolString(portProto?.created?.[0]),
    ),
    prefixString(
      intl('VirtualServices.ServiceOrPorts'),
      service?.before?.href ?? getPortAndProtocolString(portProto?.deleted?.[0]),
    ),
  );

// TODO: BE bug - https://jira.illum.io/browse/EYE-81609
export const diffVirtualService = (
  type,
  {description, name, labels, apply_to: poolTarget, service_ports: servicePorts, service},
) => {
  return (
    <div className={styles.diffWrapper}>
      {getName(name, type)}
      {getDescription(description, type)}
      {getPoolTarget(poolTarget)}
      {computeServiceOrPorts(service, servicePorts)}
      <div className={styles.labelsDiffWrapper}>{generateLabelsDiff(labels?.created, labels?.deleted)}</div>
    </div>
  );
};

// bound service diff
// how to bind a service?: Virtual Services -> Workloads Tab -> Bind button
const generateBoundWorkloadString = ({name, hostname, href}) =>
  prefixMultiLineString(
    intl('VirtualServices.BoundWorkload'),
    `${prefixString(intl('Common.Name'), name ?? hostname)}\n${prefixString(intl('Common.Href'), href)}`,
  );

export const diffBoundService = (
  type,
  {bound_service: boundService, port_overrides: overrides},
  {service_binding: {workload}},
) => {
  // bound service only create & delete events. delete has no data
  if (type !== 'create') {
    return;
  }

  const virtualServiceInfo = `${intl('Common.VirtualService')}\n${intl('Common.Href')}: ${boundService?.after.href}\n`;

  const portOverrides =
    overrides.after.length &&
    prefixString(
      intl('Port.Overrides'),
      overrides.after.map(obj => `${getPortAndProtocolString(obj)}${obj.new_port ? `: ${obj.new_port}` : ''}`).join(''),
    );

  return <Diff.Text value={stringBuilder(virtualServiceInfo, generateBoundWorkloadString(workload), portOverrides)} />;
};

/**
 * Virtual Server Diff Utils
 */
const getService = (service = {}) =>
  diffWords(
    prefixString(intl('Common.Service'), service.after?.href),
    prefixString(intl('Common.Service'), service.before?.href),
  );

// TODO: BE bug - https://jira.illum.io/browse/EYE-81611
export const diffVirtualServer = (type, {description, service, labels}) => {
  return (
    <div className={styles.diffWrapper}>
      {getDescription(description, type)}
      {getService(service, type)}
      <div className={styles.labelsDiffWrapper}>{generateLabelsDiff(labels?.created, labels?.deleted)}</div>
    </div>
  );
};

/**
 * Ruleset Diff Utils
 */
// TODO: BE bug - https://jira.illum.io/browse/EYE-81615
// we don't need 'removeNullObjs' once bug is resolved
const removeNullObjs = obj => obj.hasOwnProperty('label') && obj.label;

export const diffRuleset = (type, {name, description, rule_list_scopes: scopes}) => {
  const labelsDiff = generateLabelsDiffWithPrefix(
    scopes?.created?.filter(removeNullObjs),
    scopes?.deleted?.filter(removeNullObjs),
    intl('Common.Scopes'),
  );

  return (
    <div className={styles.diffWrapper}>
      {getName(name, type)}
      {getDescription(description, type)}
      {labelsDiff}
    </div>
  );
};

/**
 * Ruleset - ip table rules
 */
const getIPVersionString = ipVersion =>
  diffWords(
    prefixString(intl('Rulesets.Rules.IpTables.Form.IpVersion'), getIPVersion(ipVersion?.after)),
    prefixString(intl('Rulesets.Rules.IpTables.Form.IpVersion'), getIPVersion(ipVersion?.before)),
  );

export const diffIPTableRules = (type, {actors, ip_version: ipVersion, description, enabled}) => {
  // TODO: BE bug - https://jira.illum.io/browse/EYE-81615
  // TODO: only get created, not deleted property - maybe API bug? we know the result, just not what changed
  const receivers = prefixMultiLineString(
    intl('Rulesets.Rules.IpTables.Actors'),
    actors?.created
      ?.map(actor => actor.label?.href ?? actor.workload?.href ?? actor?.label_group?.href ?? intl('Workloads.All'))
      .join('\n'),
  );

  return (
    <div className={styles.diffWrapper}>
      {receivers && <Diff.Text value={receivers} oldValue={null} />}
      {getDescription(description, type)}
      {getEnabled(enabled)}
      {getIPVersionString(ipVersion)}
    </div>
  );
};

/**
 * Ruleset - security rules
 */
const getResolveLabelValue = (value = []) => {
  if (!value.length) {
    return;
  }

  if (value.length === 2) {
    return intl('Common.UsesVirtualServicesWorkloads');
  }

  if (value.includes('virtual_services')) {
    return intl('Common.UsesVirtualServices');
  }
};

const getResolveConsumersLabelsAs = resolveLabelsAs =>
  diffWords(
    prefixString(intl('Common.Consumers'), getResolveLabelValue(resolveLabelsAs?.after?.consumers)),
    prefixString(intl('Common.Consumers'), getResolveLabelValue(resolveLabelsAs?.before?.consumers)),
  );

const getResolveProvidersLabelsAs = resolveLabelsAs =>
  diffWords(
    prefixString(intl('Common.Providers'), getResolveLabelValue(resolveLabelsAs?.after?.providers)),
    prefixString(intl('Common.Providers'), getResolveLabelValue(resolveLabelsAs?.before?.providers)),
  );

const getSecureConnectStatus = secureConnect =>
  diffWords(
    prefixString(intl('Settings.SecureConnect'), convertBoolToYesOrNo(secureConnect?.after)),
    prefixString(intl('Settings.SecureConnect'), convertBoolToYesOrNo(secureConnect?.before)),
  );

// TODO: BE bug - https://jira.illum.io/browse/EYE-81615
// TODO: consumers & providers return created & deleted properties - ingress_services ONLY returns created (why? idk)
export const diffSecRule = (
  type,
  {
    description,
    enabled,
    rule_list: rule,
    providers,
    consumers,
    ingress_services: services,
    resolve_labels: resolveLabelsAs,
    sec_connect: secureConnect,
  },
) => (
  <div className={styles.diffWrapper}>
    {prefixString(intl('Events.RulesetHref'), rule?.after.href)}
    {getDescription(description, type)}
    {getEnabled(enabled)}
    {getSecureConnectStatus(secureConnect)}
    {getResolveConsumersLabelsAs(resolveLabelsAs)}
    {getResolveProvidersLabelsAs(resolveLabelsAs)}
    {computeConsumers(consumers)}
    {computeServices(services)}
    {computeProviders(providers)}
  </div>
);

/**
 * Enforcement Boundary Diff Utils
 */
export const diffEnfBoundary = (
  type,
  {name, consumers, ingress_services: services, providers, service_ports: ports},
) => (
  <div className={styles.diffWrapper}>
    {getName(name, type)}
    {computePortProto(ports)}
    {computeConsumers(consumers)}
    {computeServices(services)}
    {computeProviders(providers)}
  </div>
);

/**
 * Workload Diff Utils
 */
// these utils are slightly different than the rest, since these properties often come in response with a mix of null & empty string values
const getDistinguishedName = (name = {}, type) =>
  (name.before || name.after) &&
  diffWords(
    prefixString(intl('Common.DistinguishedName'), name.after, true),
    prefixString(intl('Common.DistinguishedName'), name.before, true),
    {type: getDiffType(type)},
  );

const getHostName = (name = {}, type) =>
  (name.before || name.after) &&
  diffWords(
    prefixString(intl('Common.Hostname'), name.after, true),
    prefixString(intl('Common.Hostname'), name.before, true),
    {
      type: getDiffType(type),
    },
  );

const getLocation = (location = {}, type) =>
  (location.before || location.after) &&
  diffWords(
    prefixString(intl('Common.Location'), location.after, true),
    prefixString(intl('Common.Location'), location.before, true),
    {type: getDiffType(type)},
  );

const getOSName = (name = {}, type) =>
  (name.before || name.after) &&
  diffWords(
    prefixString(intl('Common.OSFamily'), name.after, true),
    prefixString(intl('Common.OSFamily'), name.before, true),
    {
      type: getDiffType(type),
    },
  );

const getOSRelease = (osRelease = {}, type) =>
  (osRelease.before || osRelease.after) &&
  diffWords(
    prefixString(intl('Workloads.OSRelease'), osRelease.after, true),
    prefixString(intl('Workloads.OSRelease'), osRelease.before, true),
    {type: getDiffType(type)},
  );

const getPublicIP = (publicIp = {}, type) =>
  (publicIp.before || publicIp.after) &&
  diffWords(
    // public ip properties can be null, so we want to make sure pass empty string instead of null
    prefixString(intl('Workloads.PublicIP'), publicIp.after ?? '', true),
    prefixString(intl('Workloads.PublicIP'), publicIp.before ?? '', true),
    {type: getDiffType(type)},
  );

const getServicePrincipal = (servicePrincipal = {}, type) =>
  (servicePrincipal.before || servicePrincipal.after) &&
  diffWords(
    // service principal properties can be null, so we want to make sure pass empty string instead of null
    prefixString(intl('Workloads.Summary.KerberosServicePrincipalName'), servicePrincipal.after ?? '', true),
    prefixString(intl('Workloads.Summary.KerberosServicePrincipalName'), servicePrincipal.before ?? '', true),
    {type: getDiffType(type)},
  );

// always use side by side diff for interfaces, the way the data is structured and represented doesnt lend well to unified diff
const getWorkloadInterfaces = interfaces => {
  const value = prefixMultiLineString(
    intl('Workloads.Interfaces'),
    interfaces?.created?.map(({name, address, href}) => prefixString(name, address ?? href)).join('\n'),
  );
  const oldValue = prefixMultiLineString(
    intl('Workloads.Interfaces'),
    interfaces?.deleted?.map(({name, address, href}) => prefixString(name, address ?? href)).join('\n'),
  );

  return <Diff.List value={value} oldValue={oldValue} />;
};
// when ike cert changes, this event propagates to all workloads
// this is mutually exclusive with ALL other data we get with WL events
const computeIKECertInfo = (certObj = {}, key) => {
  if (!certObj?.[key]) {
    return;
  }

  const {
    authority_key_identifier: aki,
    issuer_name: issuer,
    not_valid_after: notValidAfter,
    not_valid_before: notValidBefore,
    subject_name: subjectName,
  } = certObj[key];

  const output = stringBuilder(
    prefixString(intl('Workloads.AKI'), aki),
    getCertIssuer(issuer),
    prefixString(intl('Workloads.NotValidBefore'), notValidBefore),
    prefixString(intl('Workloads.NotValidAfter'), notValidAfter),
    prefixString(intl('Workloads.SubjectName'), subjectName),
  );

  return output || null;
};

const getIkeAuthCertInfo = (cert = {}) => {
  if (!cert?.after && !cert?.before) {
    return;
  }

  const value = computeIKECertInfo(cert, 'after');
  const oldValue = computeIKECertInfo(cert, 'before');

  return value && oldValue ? <Diff.Option value={value} oldValue={oldValue} /> : <Diff.Text value={value} />;
};

const getWorkloadOnline = online => diffWords(prefixString(intl('Common.Online'), convertBoolToYesOrNo(online?.after)));

export const diffWorkload = (
  type,
  {
    name,
    description,
    distinguished_name: distinguishedName,
    hostname,
    labels,
    online,
    data_center: location,
    os_id: operatingSystem,
    os_detail: operatingSystemRelease,
    public_ip: publicIP,
    workload_interfaces: interfaces,
    service_principal_name: servicePrincipalName,
    ike_authentication_certificate: cert,
  },
) => {
  return (
    <div className={styles.diffWrapper}>
      {getName(name, type)}
      {getWorkloadOnline(online)}
      {getDescription(description, type)}
      {getDistinguishedName(distinguishedName, type)}
      {getHostName(hostname, type)}
      {getLocation(location, type)}
      {getOSName(operatingSystem, type)}
      {getOSRelease(operatingSystemRelease, type)}
      {getPublicIP(publicIP, type)}
      {getWorkloadInterfaces(interfaces)}
      {getServicePrincipal(servicePrincipalName, type)}
      {getIkeAuthCertInfo(cert)}
      {generateLabelsDiffWithPrefix(labels?.created, labels?.deleted)}
    </div>
  );
};

/**
 * VEN Diff Utils
 */
const venStatusesMap = new Map([
  ['active', intl('Common.Active')],
  ['suspended', intl('Workloads.Status.Suspended')],
  ['stopped', intl('Common.Stopped')],
  ['uninstalling', intl('Common.Uninstalled')],
  ['deactivated_confirmed', intl('Events.Deactivated')],
]);

const getStatus = status =>
  diffWords(
    prefixString(intl('Common.Status'), venStatusesMap.get(status?.after)),
    prefixString(intl('Common.Status'), venStatusesMap.get(status?.before)),
  );

const getMachineId = machineId =>
  machineId && (
    <Diff.Text
      value={prefixString(intl('Common.MachineId'), machineId.after)}
      oldValue={prefixString(intl('Common.MachineId'), machineId.before)}
    />
  );

const diffActivationType = type =>
  type &&
  diffWords(
    prefixString(intl('VEN.ActivationType'), getActivationType(type.after)),
    prefixString(intl('VEN.ActivationType'), getActivationType(type.before)),
  );

const getVenVersion = version =>
  version &&
  diffWords(prefixString(intl('Common.Version'), version.after), prefixString(intl('Common.Version'), version.before));

const getManagedSince = managedSince =>
  managedSince &&
  diffWords(
    prefixString(intl('Events.ManagedSince'), managedSince.after),
    prefixString(intl('Events.ManagedSince'), managedSince.before),
  );

const getOSPlatform = os =>
  os && diffWords(prefixString(intl('Common.OS'), os.after), prefixString(intl('Common.OS'), os.before));

export const diffVen = (
  type,
  {
    name,
    description,
    status,
    machine_id: machineId,
    activation_type: activationType,
    agent_version: venVersion,
    hostname,
    managed_since: managedSince,
    os_platform: osPlatform,
  },
) => {
  return (
    <div className={styles.diffWrapper}>
      {getName(name, type)}
      {getHostName(hostname, type)}
      {getMachineId(machineId)}
      {getDescription(description, type)}
      {getStatus(status, type)}
      {diffActivationType(activationType)}
      {getVenVersion(venVersion)}
      {getManagedSince(managedSince)}
      {getOSPlatform(osPlatform)}
    </div>
  );
};

/**
 * Pairing Profile Diff Utils
 */
const getLabelLocks = (
  {role_label_lock: roleLock, app_label_lock: appLock, env_label_lock: envLock, loc_label_lock: locLock},
  key,
) =>
  stringBuilder(
    prefixString(intl('PairingProfiles.Mixin.CanSetRoleLabel'), convertBoolToYesOrNo(roleLock?.[key])),
    prefixString(intl('PairingProfiles.Mixin.CanSetApplicationLabel'), convertBoolToYesOrNo(appLock?.[key])),
    prefixString(intl('PairingProfiles.Mixin.CanSetEnvironmentLabel'), convertBoolToYesOrNo(envLock?.[key])),
    prefixString(intl('PairingProfiles.Mixin.CanSetLocationLabel'), convertBoolToYesOrNo(locLock?.[key])),
  );

const getEnforcementLock = (lock, key) =>
  prefixString(
    intl('Common.Enforcement'),
    typeof lock?.[key] === 'boolean' && (lock[key] === true ? intl('Common.Locked') : intl('Common.Unlocked')),
  );

const getCommandLineOverrides = (enforcementLock, labelLocks, type) => {
  const value = prefixMultiLineString(
    intl('PairingProfiles.CommandLineOverrides'),
    stringBuilder(getEnforcementLock(enforcementLock, 'after'), getLabelLocks(labelLocks, 'after')),
  );

  const oldValue = prefixMultiLineString(
    intl('PairingProfiles.CommandLineOverrides'),
    stringBuilder(getEnforcementLock(enforcementLock, 'before'), getLabelLocks(labelLocks, 'before')),
  );

  return diffWords(value, oldValue, {type: getDiffType(type)});
};

// if obj.key === null, that means unlimited pairings (hence undefined check)
const getKeyUsage = (usage, type) => {
  const value = prefixString(
    intl('PairingProfiles.Mixin.UsesPerKey'),
    usage?.after !== undefined &&
      (usage.after === 1 ? intl('PairingProfiles.SinglePairing') : intl('Common.Unlimited')),
  );

  const oldValue = prefixString(
    intl('PairingProfiles.Mixin.UsesPerKey'),
    usage?.before !== undefined &&
      (usage.before === 1 ? intl('PairingProfiles.SinglePairing') : intl('Common.Unlimited')),
  );

  return diffWords(value, oldValue, {type: getDiffType(type)});
};

// if obj.key === null, that means unlimited key age (hence undefined check)
const getKeyLifespan = (lifespan, type) => {
  if (!lifespan) {
    return;
  }

  const value = prefixString(
    intl('PairingProfiles.KeyMaxAge'),
    lifespan?.after !== undefined &&
      (lifespan?.after ? getKeyLifespanText(lifespan.after).title : intl('Common.Unlimited')),
  );

  const oldValue =
    type === 'create'
      ? null
      : prefixString(
          intl('PairingProfiles.KeyMaxAge'),
          lifespan?.before ? getKeyLifespanText(lifespan.before).title : intl('Common.Unlimited'),
        );

  return diffWords(value, oldValue, {type: getDiffType(type)});
};

const getLastPairedAt = lastPairedAt =>
  lastPairedAt &&
  diffWords(
    prefixString(intl('Events.LastPairedAt'), lastPairedAt.after),
    prefixString(intl('Events.LastPairedAt'), lastPairedAt.before),
  );

const getPairingProfileRelease = release =>
  diffWords(
    prefixString(intl('Workloads.Release'), release?.after),
    prefixString(intl('Workloads.Release'), release?.before),
  );

export const diffPairingProfile = (type, changes) => {
  const {
    name,
    description,
    labels,
    enabled,
    key_lifespan: keyLifespan,
    allowed_uses_per_key: keyUsage,
    enforcement_mode: enforcementMode,
    mode_lock: enforcementLock,
    last_pairing_at: lastPairedAt,
    agent_software_release: release,
    ...labelLocks
  } = changes;

  return (
    <div className={styles.diffWrapper}>
      {getName(name, type)}
      {getDescription(description, type)}
      {getEnabled(enabled, type)}
      {getEnforcementMode(enforcementMode, type)}
      {getCommandLineOverrides(enforcementLock, labelLocks, type)}
      {getKeyUsage(keyUsage, type)}
      {getKeyLifespan(keyLifespan, type)}
      {getPairingProfileRelease(release)}
      {getLastPairedAt(lastPairedAt, type)}
      {generateLabelsDiffWithPrefix(labels?.created, labels?.deleted)}
    </div>
  );
};

/**
 * Container Cluster Diff Utils
 */
export const diffContainerCluster = (type, {name, description}) => (
  <div className={styles.diffWrapper}>
    {getName(name, type)}
    {getDescription(description, type)}
  </div>
);

const getVisibility = visibility =>
  diffWords(
    prefixString(
      intl('Common.Visibility'),
      (edge ? visibilityLevelViewEdge : visibilityLevelView())[visibility?.after]?.name,
    ),
    prefixString(
      intl('Common.Visibility'),
      (edge ? visibilityLevelViewEdge : visibilityLevelView())[visibility?.before]?.name,
    ),
  );

const getManaged = (managed, type) =>
  diffWords(
    prefixString(
      intl('Common.Management'),
      typeof managed?.after === 'boolean' && (managed.after ? intl('Common.Managed') : intl('Common.Unmanaged')),
    ),
    prefixString(
      intl('Common.Management'),
      typeof managed?.before === 'boolean' && (managed.before ? intl('Common.Managed') : intl('Common.Unmanaged')),
    ),
    {type: getDiffType(type)},
  );

const getContainerClusterHref = containerCluster =>
  diffWords(
    prefixString(`${intl('VEN.ContainerCluster')} ${intl('Common.Href')}`, containerCluster?.after?.href),
    prefixString(`${intl('VEN.ContainerCluster')} ${intl('Common.Href')}`, containerCluster?.before?.href),
  );

export const diffContainerWorkloadProfile = (
  type,
  {
    name,
    description,
    visibility_level: visibilityLevel,
    managed,
    container_cluster: containerCluster,
    enforcement_mode: enforcementMode,
  },
) => (
  <div className={styles.diffWrapper}>
    {getName(name, type)}
    {getDescription(description, type)}
    {getContainerClusterHref(containerCluster)}
    {getEnforcementMode(enforcementMode, type)}
    {getVisibility(visibilityLevel)}
    {getManaged(managed, type)}
  </div>
);

/**
 * Secure Connect Diff Utils
 */
const getIdleTimeout = (timeout, type) =>
  diffWords(
    prefixString(intl('SecureGateway.IdleTimeout'), timeout?.after),
    prefixString(intl('SecureGateway.IdleTimeout'), timeout?.before, {type: getDiffType(type)}),
  );

const computeVPNConfigString = ({ca_id: certificateAuthId, local_id: localId, remote_id: remoteId}, key) => {
  // certificate authority id is a required field when using certificate authentication
  let authType;

  // if there have been changes to any auth ID, let's show authentication type (PSK or certificate)
  if (certificateAuthId || localId || remoteId) {
    // certificate authentication id is required field for certificate authentication type
    // lack of existence coupled with existence of local id or remote id also means certificate auth type
    if (certificateAuthId?.[key] || (!certificateAuthId && (localId || remoteId))) {
      authType = intl('SecureGateway.CertificateRsaSig');
    } else {
      authType = intl('SecureGateway.PreSharedKey');
    }
  }

  return prefixMultiLineString(
    intl('SecureGateway.VPNConfiguration'),
    stringBuilder(
      prefixString(intl('Common.Authentication'), authType),
      prefixString(intl('SecureGateway.CertificateAuthorityID'), certificateAuthId?.[key]),
      prefixString(intl('SecureGateway.LocalID'), localId?.[key]),
      prefixString(intl('SecureGateway.RemoteID'), remoteId?.[key]),
    ),
  );
};

const getVPNConfig = (config, type) =>
  diffWords(
    computeVPNConfigString(config, 'after'),
    type !== 'create' ? computeVPNConfigString(config, 'before') : null,
  );

export const diffSecureConnect = (type, {name, description, idle_timeout_min: idleTimeout, ...vpnConfig}) => (
  <div className={styles.diffWrapper}>
    {getName(name, type)}
    {getDescription(description, type)}
    {getIdleTimeout(idleTimeout, type)}
    {getVPNConfig(vpnConfig, type)}
  </div>
);

/**
 * Security Policy (provisioning) Diff Utils
 */
// TODO: BE BUG - https://jira.illum.io/browse/EYE-81607
const aliases = new Map([
  ['bound_services', 'virtual_services'],
  ['selective_enforcement_rules', 'enforcement_boundaries'],
  ['rulesets', 'rule_sets'],
]);

const getObjectChanges = changes =>
  Object.keys(changes.after)
    .map(key => prefixString(getTypeAndRoutes()[aliases.get(key) ?? key].typeLabel, changes.after[key].toString()))
    .join('\n');

export const diffSecurityPolicy = (
  type,
  {commit_message: provisionNote, object_counts: objectCounts, version, workloads_affected: workloadsAffected},
) =>
  // no diffing here - each provision event is simply a count of changes
  stringBuilder(
    prefixString(intl('Common.Version'), version?.after),
    prefixString(intl('Version.WorkloadsAffected'), workloadsAffected?.after),
    prefixQuotedText(intl('Provision.Note'), provisionNote, 'after'),
    prefixMultiLineString(intl('Provision.Changes'), getObjectChanges(objectCounts)),
  );

/**
 * Event Setting Diff Utils
 */
const getPCEString = (pceScope, type) =>
  diffWords(
    prefixMultiLineString(intl('Common.PCE'), pceScope?.after?.join('\n')),
    prefixMultiLineString(intl('Common.PCE'), pceScope?.before?.join('\n')),
    {type: getDiffType(type)},
  );

const eventSettingsKeyMap = {
  ...eventKeyMap(), // clone this from events utils in case more KV pairs are ever added
  configuration_event_included: intl('Events.OrganizationalEvents'),
  system_event_included: intl('SystemSettings.Events'),
  traffic_flow_allowed_event_included: intl('Events.AllowedTraffic'),
  traffic_flow_potentially_blocked_event_included: intl('Events.PotentiallyBlockedTraffic'),
  traffic_flow_blocked_event_included: intl('BlockedTraffic.Name'),
};

const getEventSettings = (settingsObj = {}, type) => {
  const value = prefixMultiLineString(
    intl('Events.EventForwarding'),
    Object.keys(settingsObj)
      .map(key => prefixString(eventSettingsKeyMap[key], settingsObj[key]?.before.toString()))
      .join('\n'),
  );

  const oldValue = prefixMultiLineString(
    intl('Events.EventForwarding'),
    Object.keys(settingsObj)
      .map(key => prefixString(eventSettingsKeyMap[key], settingsObj[key]?.after.toString()))
      .join('\n'),
  );

  return diffWords(value, oldValue, {type: getDiffType(type)});
};

export const diffSysLogDestination = (type, {description, type: eventType, pce_scope: pceScope, ...settings}) => (
  <div className={styles.diffWrapper}>
    {prefixString(intl('Common.Type'), eventType?.after)}
    {getDescription(description, type)}
    {getPCEString(pceScope, type)}
    {getEventSettings(settings, type)}
  </div>
);

/**
 * Flow Collection Filters Diff Utils
 */
// action is only set on create
const getAction = action =>
  action
    ? diffWords(
        prefixString(
          intl('Common.Action'),
          action?.after
            ? action.after === 'aggregate'
              ? intl('FlowCollectionFilters.Aggregate')
              : intl('FlowCollectionFilters.Drop')
            : null,
        ),
      )
    : null;

export function getTransmissionName(transmission) {
  switch (transmission) {
    case 'B':
    case 'broadcast':
      return intl('Map.Traffic.Broadcast');
    case 'M':
    case 'multicast':
      return intl('Map.Traffic.Multicast');
    case 'U':
    case 'unicast':
    default:
      return intl('Map.Traffic.Unicast');
  }
}

const getTransmission = (transmission, type) => {
  const value = prefixString(
    intl('Components.Transmission'),
    transmission?.after ? getTransmissionName(transmission.after) : null,
  );

  const oldValue = prefixString(
    intl('Components.Transmission'),
    transmission?.before ? getTransmissionName(transmission.before) : null,
  );

  return diffWords(value, oldValue, {type: getDiffType(type)});
};

const getProtocol = (protocol, type) =>
  diffWords(
    prefixString(intl('Common.Protocol'), protocol?.after),
    prefixString(intl('Common.Protocol'), protocol?.before),
    {type: getDiffType(type)},
  );

const getPort = (port, type) =>
  diffWords(
    prefixString(intl('Common.DestinationPort'), port?.after),
    prefixString(intl('Common.DestinationPort'), port?.before),
    {type: getDiffType(type)},
  );

const getIP = (ip, type) =>
  diffWords(
    prefixString(intl('FlowCollectionFilters.DestinationIP'), ip?.after),
    prefixString(intl('FlowCollectionFilters.DestinationIP'), ip?.before),
    {type: getDiffType(type)},
  );

export const diffFlowCollectionFilters = (type, {action, dst_ip: ip, dst_port: port, proto, transmission}) => (
  <div className={styles.diffWrapper}>
    {getAction(action, type)}
    {getTransmission(transmission, type)}
    {getProtocol(proto, type)}
    {getPort(port, type)}
    {getIP(ip, type)}
  </div>
);

/**
 * Ike Certificate Diff Util
 */
export const diffIkeCertificate = ({value}) =>
  value && <Diff.Text value={getCertIssuer(value?.after)} oldValue={getCertIssuer(value?.before)} />;

/**
 * Firewall Setting Diff Utils
 */
const getAuthType = authType =>
  authType && (
    <Diff.Option
      value={prefixString(
        intl('Settings.AuthenticationType'),
        authType.after ? (authType.after === 'certificate' ? intl('Common.Certificate') : intl('Settings.PSK')) : null,
      )}
      oldValue={prefixString(
        intl('Settings.AuthenticationType'),
        authType.before
          ? authType.before === 'certificate'
            ? intl('Common.Certificate')
            : intl('Settings.PSK')
          : null,
      )}
    />
  );

// VEN version < 20.2.0
const getOldIPv6 = ipTraffic =>
  ipTraffic && (
    <Diff.Option
      value={prefixString(
        `${intl('Settings.VensVersionsLower')} ${intl('Settings.IPv6Traffic')}`,
        typeof ipTraffic.after === 'boolean' &&
          (ipTraffic.after ? intl('Common.AllAllowed') : intl('Common.AllBlocked')),
      )}
      oldValue={prefixString(
        `${intl('Settings.VensVersionsLower')} ${intl('Settings.IPv6Traffic')}`,
        typeof ipTraffic.before === 'boolean' &&
          (ipTraffic.before ? intl('Common.AllAllowed') : intl('Common.AllBlocked')),
      )}
    />
  );

// VEN version >= 20.2.0
const getIPv6 = ipTraffic =>
  ipTraffic && (
    <Diff.Option
      value={prefixString(
        `${intl('Settings.VensVersionsHigher')} ${intl('Settings.IPv6Traffic')}`,
        ipTraffic?.after &&
          (ipTraffic.after === 'block_all'
            ? intl('Settings.Edit.BlockIPv6Traffic')
            : intl('Settings.Edit.IPv6TrafficAllowedBasedOnPolicy')),
      )}
      oldValue={prefixString(
        `${intl('Settings.VensVersionsHigher')} ${intl('Settings.IPv6Traffic')}`,
        ipTraffic?.before &&
          (ipTraffic.before === 'block_all'
            ? intl('Settings.Edit.BlockIPv6Traffic')
            : intl('Settings.Edit.IPv6TrafficAllowedBasedOnPolicy')),
      )}
    />
  );

// TODO: all of the other firewall settings once the following bug is resolved - simply not enough information to be useful:
// TODO: https://jira.illum.io/browse/EYE-81959
export const diffFirewallSettings = (
  type,
  {ike_authentication_type: authType, allow_ipv6: oldIPv6, ipv6_mode: newIPv6},
) => (
  <div className={styles.diffWrapper}>
    {getAuthType(authType)}
    {getOldIPv6(oldIPv6)}
    {getIPv6(newIPv6)}
  </div>
);

/**
 * Trusted Proxy IP Diff Util
 */
// each ip gets it own section, i.e. we only diff a single ip at a time
export const diffTrustedProxyIP = ({ip}) =>
  ip && (
    <Diff.Text
      value={prefixString(intl('Common.IPAddress'), ip.after)}
      oldValue={prefixString(intl('Common.IPAddress'), ip.before)}
    />
  );

/**
 * Policy Settings Diff Utils
 */
const getProvisionNote = (requireProvisionNote, type) =>
  diffWords(
    prefixString(intl('Policy.RequireProvisionNote'), convertBoolToYesOrNo(requireProvisionNote?.after)),
    prefixString(intl('Policy.RequireProvisionNote'), convertBoolToYesOrNo(requireProvisionNote?.before)),
    {type: getDiffType(type)},
  );

const getReversibleProviderConsumer = (isReversed, type) =>
  diffWords(
    prefixString(
      intl('Policy.UIColumnOrder'),
      typeof isReversed?.after === 'boolean' &&
        (isReversed.after ? intl('Policy.ConsumerFirst') : intl('Policy.ProviderFirst')),
    ),
    prefixString(
      intl('Policy.UIColumnOrder'),
      typeof isReversed?.before === 'boolean' &&
        (isReversed.before ? intl('Policy.ConsumerFirst') : intl('Policy.ProviderFirst')),
    ),
    {type: getDiffType(type)},
  );

const getAppGroupType = (arr = []) =>
  arr.length === 2 ? intl('AppGroups.AppEnv') : arr.length === 3 ? intl('AppGroups.AppEnvLoc') : '';

const getDefaultAppGroups = defaultAppGroups =>
  defaultAppGroups &&
  diffWords(
    prefixString(intl('AppGroups.AppGroupType'), getAppGroupType(defaultAppGroups.before)),
    prefixString(intl('AppGroups.AppGroupType'), getAppGroupType(defaultAppGroups.after)),
  );

export const diffOrgSettings = (
  type,
  {
    require_sec_policy_commit_message: requireProvisionNote,
    reverse_provider_consumer_column: reverseProviderConsumerOrder,
    app_groups_default: defaultAppGroups,
  },
) => (
  <div className={styles.diffWrapper}>
    {getProvisionNote(requireProvisionNote, type)}
    {getReversibleProviderConsumer(reverseProviderConsumerOrder, type)}
    {getDefaultAppGroups(defaultAppGroups)}
  </div>
);

/**
 * Workload Settings Diff Utils (offline timer)
 */
const getGoodbyeTimeout = val =>
  stringBuilder(prefixMultiLineString(intl('OfflineTimers.DecommissionTimer'), getGoodbyeString(val)));

const getDisconnectTimeout = val =>
  stringBuilder(prefixMultiLineString(intl('OfflineTimers.Disconnect'), getDisconnectString(val)));

// unlike every other event, we only get value, not type - hence why we need resource here
export const diffWorkloadSettings = (resource, {value: {before, after}}) => {
  const {
    workload_setting: {workload_goodbye_timeout_seconds: goodbyeTimeout},
  } = resource;

  // both settings can be changed at once, but each will have its own resource change section - i.e. don't combine them
  if (goodbyeTimeout) {
    return <Diff.Option value={getGoodbyeTimeout(after)} oldValue={getGoodbyeTimeout(before)} />;
  }

  return <Diff.Option value={getDisconnectTimeout(after)} oldValue={getDisconnectTimeout(before)} />;
};

/**
 * RBAC Permissions Diff Utils
 */
const roleNames = roleStrings();
const filterLabels = scope => scope.label;
const filterLabelGroups = scope => scope.label_group;

// only ever shows up in create event, hence no old value
const getServiceAccountHref = serviceAccount =>
  diffWords(prefixString(intl('Events.ServiceAccountHref'), serviceAccount?.after?.href));

export const diffPermissions = (
  type,
  {role, auth_security_principal, org_permission_scopes, service_account: serviceAccount},
) => {
  const secPrincipalHref = auth_security_principal?.after?.href;

  // we get role href in a format like this: /orgs/2/roles/ruleset_provisioner
  // convert it to a friendly UI display value like: 'Ruleset Provisioner'
  const roleHref = role?.after?.href;
  const roleString = roleHref && roleNames[roleHref.slice(roleHref.lastIndexOf('/') + 1)];

  const createdScopes = org_permission_scopes?.created ?? [];
  const deletedScopes = org_permission_scopes?.deleted ?? [];

  // unlike other events, this event amalgamates labels and label groups
  const labels = createdScopes.filter(filterLabels);
  const labelGroups = createdScopes.filter(filterLabelGroups);
  const deletedLabels = deletedScopes.filter(filterLabels);
  const deletedLabelGroups = deletedScopes.filter(filterLabelGroups);

  const labelsDiff = generateLabelsDiff(labels, deletedLabels);
  const labelGroupsDiff = generateLabelGroupsDiff(labelGroups, deletedLabelGroups);

  return (
    <div className={styles.diffWrapper}>
      {roleString && diffWords(prefixString(intl('Common.Role'), roleString))}
      {secPrincipalHref && diffWords(prefixString(intl('Events.SecurityPrincipalHref'), secPrincipalHref))}
      {serviceAccount && getServiceAccountHref(serviceAccount)}
      <span className={styles.labelsDiffMargin}>
        {createdScopes.length || labelGroups.length ? `${intl('Common.Scopes')}:` : null}
      </span>
      <div className={styles.labelsDiffGap}>
        {labelsDiff}
        {labelGroupsDiff}
      </div>
    </div>
  );
};

/**
 * RBAC Security Principal Diff Utils
 */
const securityPrincipalTypeMap = new Map([['group', intl('Users.ExternalGroup')]]);

// following pieces of data can only be set on create
const getExternalGroup = group => diffWords(prefixString(intl('Users.ExternalGroup'), group?.after));
const getUser = user => diffWords(prefixString(intl('Users.EmailOrUsername'), user?.after));
const getPrincipalType = (type = {}) =>
  diffWords(prefixString(intl('Events.SecurityPrincipalType'), securityPrincipalTypeMap.get(type.after) ?? type.after));
const getAccessRestriction = (ar = {}, type) =>
  diffWords(
    prefixString(intl('AccessRestriction.AccessRestrictionTitle'), ar.after?.href),
    prefixString(intl('AccessRestriction.AccessRestrictionTitle'), ar.before?.href),
    {
      type: getDiffType(type),
    },
  );

// handles external group, external user, local user
// 'name' (genericValue) that corresponds to the name of the external group OR the email of the external user
export const diffSecurityPrincipal = (
  type,
  {
    display_name: name,
    name: genericValue,
    principal_type: principalType,
    access_restriction: accessRestriction,
    securityPrincipalType,
  },
) => (
  <div className={styles.diffWrapper}>
    {getName(name, type)}
    {securityPrincipalType === 'user' ? getUser(genericValue) : getExternalGroup(genericValue)}
    {getPrincipalType(principalType)}
    {getAccessRestriction(accessRestriction, type)}
  </div>
);

const getEmail = email => diffWords(prefixString(intl('Common.Email'), email));
const getPasswordChangedAt = date =>
  date && (
    <Diff.Option
      value={prefixString(intl('EventUtils.UserPasswordChangedAt'), date.after)}
      oldValue={prefixString(intl('EventUtils.UserPasswordChangedAt'), date.before)}
    />
  );

export const diffUser = (type, {email, full_name: name, password_changed_at: date}) => (
  <div className={styles.diffWrapper}>
    {getName(name, type)}
    {getEmail(email?.after)}
    {getPasswordChangedAt(date)}
  </div>
);

/**
 * API Key Diff Util
 */
export const diffAPIKey = (
  type,
  {name, description, key_id: keyID, user_id: userID, account_type: accountType, service_account: serviceAccount},
) => (
  <div className={styles.diffWrapper}>
    {getName(name, type)}
    {getDescription(description, type)}
    {diffWords(prefixString(intl('Users.APIKeys.KeyID'), keyID?.after))}
    {diffWords(prefixString(intl('Users.APIKeys.UserID'), userID?.after?.href))}
    {diffWords(
      prefixString(
        intl('Common.Type'),
        accountType?.after === 'service_account' ? intl('Events.ServiceAccount') : null,
      ),
    )}
    {getServiceAccountHref(serviceAccount)}
  </div>
);

/**
 * Service Account Diff Util
 */
export const diffServiceAccount = (type, {name, description, access_restriction: accessRestriction}) => (
  <div className={styles.diffWrapper}>
    {getName(name, type)}
    {getDescription(description, type)}
    {getAccessRestriction(accessRestriction, type)}
  </div>
);

/**
 * Authentication - SAML Diff Utils
 */
const getSingleSignOnURL = (url, type) =>
  url &&
  diffWords(
    prefixString(intl('Users.SSOConfig.RemoteLoginURL'), url.after),
    prefixString(intl('Users.SSOConfig.RemoteLoginURL'), url.before),
    {
      type: getDiffType(type),
    },
  );

const getSingleLogoutURL = (url, type) =>
  url &&
  diffWords(
    prefixString(intl('Users.SSOConfig.LogoutLandingURL'), url.after),
    prefixString(intl('Users.SSOConfig.LogoutLandingURL'), url.before),
    {
      type: getDiffType(type),
    },
  );

const getAuthContext = context =>
  context && (
    <Diff.Option
      value={prefixString(
        intl('Users.SSOConfig.AuthenticationMethod'),
        context.after ? intl('Users.SSOConfig.PasswordProtected') : intl('Users.SSOConfig.Unspecified'),
      )}
      oldValue={prefixString(
        intl('Users.SSOConfig.AuthenticationMethod'),
        context.before ? intl('Users.SSOConfig.PasswordProtected') : intl('Users.SSOConfig.Unspecified'),
      )}
    />
  );

const getReauthentication = forceReauthentication =>
  forceReauthentication && (
    <Diff.Option
      value={prefixString(
        intl('Users.SSOConfig.ForceReauthentication'),
        forceReauthentication.after ? intl('Common.Yes') : intl('Common.No'),
      )}
      oldValue={prefixString(
        intl('Users.SSOConfig.ForceReauthentication'),
        forceReauthentication.before ? intl('Common.Yes') : intl('Common.No'),
      )}
    />
  );

export const diffSAML = (type, {saml_configs: configs}) => {
  const {
    idp_sso_target_url: singleSignOnURL,
    idp_slo_target_url: singleLogoutURL,
    authn_context: authContext,
    force_authn: forceAuth,
  } = configs.updated[0].changes; // can have multiple configs, but can only modify one at a time via edit page

  return (
    <div className={styles.diffWrapper}>
      {getSingleSignOnURL(singleSignOnURL, type)}
      {getSingleLogoutURL(singleLogoutURL, type)}
      {getAuthContext(authContext)}
      {getReauthentication(forceAuth)}
    </div>
  );
};

/**
 * Authentication - LDAP Diff Utils
 */
const getHostOrIP = (host, type) =>
  diffWords(
    prefixString(intl('Common.IPAddressHostname'), host?.after),
    prefixString(intl('Common.IPAddressHostname'), host?.before),
    {
      type: getDiffType(type),
    },
  );

const getLDAPProtocol = (protocol, type) =>
  diffWords(
    prefixString(intl('Common.Protocol'), protocol?.after),
    prefixString(intl('Common.Protocol'), protocol?.before),
    {
      type: getDiffType(type),
    },
  );

const getLDAPPort = (port, type) =>
  diffWords(prefixString(intl('Common.Protocol'), port?.after), prefixString(intl('Common.Protocol'), port?.before), {
    type: getDiffType(type),
  });

const getBoundDN = (boundDN, type) =>
  diffWords(
    prefixString(intl('AuthenticationSettings.BindDN'), boundDN?.after),
    prefixString(intl('AuthenticationSettings.BindDN'), boundDN?.before),
    {
      type: getDiffType(type),
    },
  );

const getAnonymousBinding = (boundPW, boundDN, type) =>
  // a bound password or bound distinguished name means anonymous binding is disallowed
  boundPW || boundDN
    ? diffWords(
        prefixString(
          intl('AuthenticationSettings.AnonymousBind'),
          boundPW?.after ?? boundDN?.after ? intl('Common.DoNotAllow') : intl('Common.Allowed'),
        ),
        type !== 'create'
          ? prefixString(
              intl('AuthenticationSettings.AnonymousBind'),
              boundPW?.before ?? boundDN?.before ? intl('Common.DoNotAllow') : intl('Common.Allowed'),
            )
          : null,
        {
          type: getDiffType(type),
        },
      )
    : null;

const getTimeout = (timeout = {}, type) =>
  diffWords(
    prefixString(intl('AuthenticationSettings.RequestTimeoutPeriod'), timeout.after?.toString()),
    prefixString(intl('AuthenticationSettings.RequestTimeoutPeriod'), timeout.before?.toString()),
    {type: getDiffType(type)},
  );

const getUserBaseDN = (userBaseDN, type) =>
  diffWords(
    prefixString(intl('AuthenticationSettings.UserBaseDN'), userBaseDN?.after),
    prefixString(intl('AuthenticationSettings.UserBaseDN'), userBaseDN?.before),
    {type: getDiffType(type)},
  );

const getBasePattern = (basePattern, type) =>
  diffWords(
    prefixString(intl('AuthenticationSettings.UserDNPattern'), basePattern?.after),
    prefixString(intl('AuthenticationSettings.UserDNPattern'), basePattern?.before),
    {type: getDiffType(type)},
  );

const getBaseFilter = (baseFilter, type) =>
  diffWords(
    prefixString(intl('AuthenticationSettings.UserSearchFilter'), baseFilter?.after),
    prefixString(intl('AuthenticationSettings.UserSearchFilter'), baseFilter?.before),
    {type: getDiffType(type)},
  );

const getUsernameAttribute = (usernameAttribute, type) =>
  diffWords(
    prefixString(intl('AuthenticationSettings.UserNameAttribute'), usernameAttribute?.after),
    prefixString(intl('AuthenticationSettings.UserNameAttribute'), usernameAttribute?.before),
    {type: getDiffType(type)},
  );

const getNameAttribute = (nameAttribute, type) =>
  diffWords(
    prefixString(intl('AuthenticationSettings.FullNameAttribute'), nameAttribute?.after),
    prefixString(intl('AuthenticationSettings.FullNameAttribute'), nameAttribute?.before),
    {type: getDiffType(type)},
  );

const getMemberOfAttribute = (memberOfAttribute, type) =>
  diffWords(
    prefixString(intl('AuthenticationSettings.GroupMembershipAttribute'), memberOfAttribute?.after),
    prefixString(intl('AuthenticationSettings.GroupMembershipAttribute'), memberOfAttribute?.before),
    {type: getDiffType(type)},
  );

export const diffLDAP = (
  type,
  {
    name,
    address,
    authentication_method: protocol,
    port,
    bind_dn: boundDN,
    bind_password: boundPW,
    request_timeout_seconds: timeout,
    user_base_dn: baseDN,
    user_dn_pattern: basePattern,
    user_base_filter: baseFilter,
    username_attribute: usernameAttribute,
    full_name_attribute: nameAttribute,
    user_memberof_attribute: memberOfAttribute,
  },
) => (
  <div className={styles.diffWrapper}>
    {getName(name, type)}
    {getHostOrIP(address, type)}
    {getLDAPProtocol(protocol, type)}
    {getLDAPPort(port, type)}
    {getBoundDN(boundDN, type)}
    {getAnonymousBinding(boundPW, boundDN, type)}
    {getTimeout(timeout, type)}
    {getUserBaseDN(baseDN, type)}
    {getBasePattern(basePattern, type)}
    {getBaseFilter(baseFilter, type)}
    {getUsernameAttribute(usernameAttribute, type)}
    {getNameAttribute(nameAttribute, type)}
    {getMemberOfAttribute(memberOfAttribute, type)}
  </div>
);

/**
 * Access Restriction Diff Utils
 */
// TODO: another BE bug - https://jira.illum.io/browse/EYE-82044
export const diffAccessRestriction = (type, {name, description}) => (
  <div className={styles.diffWrapper}>
    {getName(name, type)}
    {getDescription(description, type)}
  </div>
);

/**
 * Event Settings Diff Util
 */
const diffEventSettings = ({org_setting} = {}) => {
  const {
    audit_event_min_severity: minSeverity,
    audit_event_retention_seconds: rententionInSeconds,
    format,
  } = org_setting;

  return (
    <div className={styles.diffWrapper}>
      <span>{prefixString(intl('Events.Severity'), severitySetting()[minSeverity])}</span>
      <span>
        {prefixString(
          intl('Events.RetentionPeriod'),
          rententionInSeconds && intl('TrafficParameters.Days', {count: rententionInSeconds / 60 / 60 / 24}),
        )}
      </span>
      <span>{prefixString(intl('Events.Format'), format)}</span>
    </div>
  );
};

/**
 * Function that recursively flattens a deeply nested object
 * The goal is to display a simple list, where the keys are joined together
 *
 * Input : {rule_set: {name: 'Prod Ruleset', scopes: [{label: {key: 'env', href: '/label/'}}]}}
 * Output : [
 *  'rule_set.name = Prod Ruleset',
 *  'rule_set.scopes.label.key = env',
 *  'rule_set.scopes.label.href = /label/',
 * ]
 *
 * @param obj          Object to flatten
 * @param oldKey       The key to access the obj param. We build upon this key
 * @return {(array | JSX.Element)}     Return an array of the flattened data
 */
export const getNestedData = (obj, oldKey) => {
  if (typeof obj === 'string') {
    return <div key={oldKey}>{`${oldKey} = '${obj || ' '}'`}</div>;
  }

  if (typeof obj === 'number' || typeof obj === 'boolean' || obj === null) {
    return <div key={oldKey}>{`${oldKey} = ${obj}`}</div>;
  }

  if (Array.isArray(obj)) {
    return obj.map(item => getNestedData(item, oldKey));
  }

  return (
    obj &&
    Object.keys(obj).reduce((result, currentKey) => {
      const newKey = `${oldKey}.${currentKey}`;

      result.push(getNestedData(obj[currentKey], newKey));

      return result;
    }, [])
  );
};

/**
 * Diff controller
 */
export const diffChanges = (resource, resourceType, type, changes) => {
  let output;

  switch (resourceType) {
    case 'service':
      output = diffService(type, changes);
      break;
    case 'ip_list':
      output = diffIPList(type, changes);
      break;
    case 'label':
      output = diffLabel(type, changes);
      break;
    case 'security_principal':
      output = diffUserGroup(type, changes);
      break;
    case 'label_group':
      output = diffLabelGroup(type, {...changes, key: resource.label_group.key});
      break;
    case 'virtual_service':
      output = diffVirtualService(type, changes);
      break;
    case 'service_binding':
      output = diffBoundService(type, changes, resource);
      break;
    case 'virtual_server':
      output = diffVirtualServer(type, changes);
      break;
    case 'rule_set':
      output = diffRuleset(type, changes);
      break;
    case 'ip_tables_rule':
      output = diffIPTableRules(type, changes);
      break;
    case 'sec_rule':
      output = diffSecRule(type, changes);
      break;
    case 'enforcement_boundary':
      output = diffEnfBoundary(type, changes);
      break;
    case 'workload':
      output = diffWorkload(type, changes);
      break;
    case 'agent':
      output = diffVen(type, changes);
      break;
    case 'pairing_profile':
      output = diffPairingProfile(type, changes);
      break;
    case 'container_cluster':
      output = diffContainerCluster(type, changes);
      break;
    case 'container_workload_profile':
      output = diffContainerWorkloadProfile(type, changes);
      break;
    case 'secure_connect_gateway':
      output = diffSecureConnect(type, changes);
      break;
    case 'sec_policy':
      output = diffSecurityPolicy(type, changes);
      break;
    case 'syslog_destination': // event settings
      output = diffSysLogDestination(type, changes);
      break;
    case 'traffic_collector_setting':
      output = diffFlowCollectionFilters(type, changes);
      break;
    case 'ven_setting': // ike certificate
      output = diffIkeCertificate(changes);
      break;
    case 'firewall_setting':
      output = diffFirewallSettings(type, changes);
      break;
    case 'trusted_proxy_ip':
      output = diffTrustedProxyIP(changes);
      break;
    case 'org':
      output = diffOrgSettings(type, changes);
      break;
    case 'workload_setting':
      output = diffWorkloadSettings(resource, changes);
      break;
    case 'permission':
      output = diffPermissions(type, changes);
      break;
    case 'auth_security_principal':
      output = diffSecurityPrincipal(type, {
        ...changes,
        securityPrincipalType: resource.auth_security_principal.type,
      });
      break;
    case 'user':
      output = diffUser(type, changes);
      break;
    case 'api_key':
      output = diffAPIKey(type, changes);
      break;
    case 'service_account':
      output = diffServiceAccount(type, changes);
      break;
    case 'access_restriction':
      output = diffAccessRestriction(type, changes);
      break;
    case 'domain':
      output = diffSAML(type, changes);
      break;
    case 'ldap_config':
      output = diffLDAP(type, changes);
      break;
    case 'org_setting':
      output = diffEventSettings(resource);
      break;
  }

  // switch default case & also a fallback in case diff computation returns no data
  if (!output || (typeof output === 'object' && output.props.children.every(child => !child))) {
    return Object.keys(changes).map(key => getNestedData(changes[key], key));
  }

  return output;
};
