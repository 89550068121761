/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from 'intl';
import PubSub from 'pubsub';
import PropTypes from 'prop-types';
import {Component} from 'react';
import {HeaderProps} from 'containers';
import {AttributeList, Badge, Button, Modal, ToolBar, ToolGroup, TypedMessages, Form} from 'components';
import {object, string} from 'yup';
import {connect} from 'react-redux';
import {AppContext} from 'containers/App/AppUtils';
import LabelReducers from '../LabelState';
import {getLabelEdit} from './LabelEditState';
import {fetchLabelItem, updateLabel} from '../LabelSaga';
import {createLabels, verifyUserPermissions} from './LabelEditSaga';
import {reactUtils, hrefUtils} from 'utils';
import {edge} from 'api/apiUtils';

// Selelector Options
const selectorOptions = {
  types: [
    {value: 'role', label: edge ? intl('Common.Group') : intl('Common.Role'), subText: intl('Common.RoleDescription')},
    {value: 'app', label: intl('Common.Application'), subText: intl('Common.ApplicationDescription')},
    {value: 'env', label: intl('Common.Environment'), subText: intl('Common.EnvironmentDescription')},
    {value: 'loc', label: intl('Common.Location'), subText: intl('Common.LocationDescription')},
  ],
};

// Get formik's mandatory initialValues props for form setup
const getInitialValues = props => {
  const {label} = props;

  return {
    // A required input value use empty string
    name: label.value || '',
    // required input, must be one of 'role', 'app', 'env', 'loc'
    type: selectorOptions.types.find(option => option.value === label.key) || null,
  };
};

// Initial State
const getInitialState = props => ({
  initialValues: getInitialValues(props),
  isEdit: props.routeName === 'app.labels.item.edit',
  cancel: false,
  saving: false,
  error: null,
});

// when container is controlled we will pass the data via containerProps, as opposed to connecting to the store
const makeMapState = (state, props) => (props.controlled ? {} : getLabelEdit(state));

@connect(makeMapState, null, null, {forwardRef: true})
export default class LabelEdit extends Component {
  static prefetch = verifyUserPermissions;
  static contextType = AppContext;
  static reducers = LabelReducers;

  static propTypes = {
    buttonAlign: PropTypes.oneOf(['top', 'bottom']),
    onDone: PropTypes.func,
    onCancel: PropTypes.func,
  };

  constructor(props) {
    super(props);

    // Yup validation
    this.schemas = object({
      name: string().max(255, intl('Common.NameIsTooLong')).required(intl('Common.AddValidName')),
      type: object().nullable().required(intl('Labels.Create.Placeholder.LabelType')),
    });

    this.handleSubmit = _.noop;
    this.renderForm = this.renderForm.bind(this);
    this.handleOnSave = this.handleOnSave.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.renderEditAlert = this.renderEditAlert.bind(this);
    this.handleErrorClose = this.handleErrorClose.bind(this);

    this.state = getInitialState(props);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.initialValues.name !== nextProps.label.value && prevState.initialValues.key !== nextProps.label.key) {
      return getInitialState(nextProps);
    }

    return null;
  }

  componentDidMount() {
    if (!_.isEmpty(this.props.label) && !this.state.isEdit) {
      PubSub.publish('FORM.DIRTY', {id: this.props.formProps?.id, dirty: true}, {immediate: true});
    }
  }

  handleCancel(evt) {
    const {
      props: {onCancel, currentRouteParams: {id} = {}},
      state: {isEdit},
      context: {navigate},
    } = this;

    if (typeof onCancel === 'function') {
      return onCancel();
    }

    navigate({
      evt,
      ...(isEdit ? {to: 'labels.item', params: {id}} : {to: 'labels.list'}),
    });
  }

  // Parse the proper payload to send to the API when creating label.
  // This method is only called when no errors and all the required values are met for formik
  async handleOnSave(evt) {
    const {values, setSubmitting} = this.formik;
    const {isEdit} = this.state;
    const {label, onDone} = this.props;
    const {fetcher, navigate} = this.context;
    const payload = {
      value: values.name,
    };

    // Only pass in key properties as a payload during create mode per API
    if (values.type && !isEdit) {
      payload.key = values.type.value;
    }

    try {
      let id;

      await reactUtils.setStateAsync({saving: true}, this);
      // Call formik method to set isSubmitting to true
      setSubmitting(true);

      if (isEdit) {
        id = this.props.currentRouteParams?.id;

        if (label.external_data_set) {
          payload.external_data_set = label.external_data_set;
        }

        if (label.external_data_reference) {
          payload.external_data_reference = label.external_data_reference.includes('\r')
            ? label.external_data_reference
            : `${label.external_data_reference}\r`;
        }

        await fetcher.spawn(updateLabel, id, payload);
      } else {
        const {
          data: {href},
        } = await fetcher.spawn(createLabels, payload);

        id = hrefUtils.getId(href);
      }

      const modifiedLabel = await fetcher.fork(fetchLabelItem, {params: {id}}, true);

      // Wait progress on save button to finish
      await new Promise(onSaveDone => this.setState({onSaveDone, saving: false}));

      if (typeof onDone === 'function') {
        onDone(evt, modifiedLabel);
      } else {
        // Navigate to a view page
        navigate({to: 'labels.item', params: {id}});
      }
    } catch (error) {
      this.setState({error, saving: false});
      // Call formik method to set isSubmitting to false
      setSubmitting(false);
    }
  }

  // Handle Edit Error
  handleErrorClose() {
    this.setState({error: null});
  }

  // Render alert message when edit or create fails
  renderEditAlert() {
    const {error, isEdit} = this.state;
    const token = _.get(error, 'data[0].token');
    const title = isEdit ? intl('Labels.Errors.Edit') : intl('Labels.Errors.Create');
    const message = (token && intl(`ErrorsAPI.err:${token}`)) || _.get(error, 'data[0].message', error.message);

    return (
      <Modal.Alert title={title} onClose={this.handleErrorClose} buttonProps={{tid: 'ok', text: intl('Common.OK')}}>
        <TypedMessages>{[{icon: 'error', content: message}]}</TypedMessages>
      </Modal.Alert>
    );
  }

  renderForm(options) {
    const {isValid} = options;

    this.formik = options;

    const {saving, onSaveDone, error, isEdit, initialValues} = this.state;
    const {
      label,
      currentRouteParams: {id} = {},
      buttonAlign = 'top',
      typeIsDisabled,
      saveButtonProps,
      cancelButtonProps,
      controlled,
    } = this.props;

    const buttons = (
      <ToolBar>
        <ToolGroup>
          <Button
            icon="save"
            tid="save"
            disabled={isValid === false}
            text={intl('Common.Save')}
            progressCompleteWithCheckmark
            progress={saving}
            progressError={Boolean(error)}
            onClick={this.handleOnSave}
            onProgressDone={onSaveDone}
            {...saveButtonProps}
          />
          <Button
            color="standard"
            disabled={saving || Boolean(onSaveDone)}
            icon="cancel"
            tid="cancel"
            text={intl('Common.Cancel')}
            onClick={this.handleCancel}
            {...cancelButtonProps}
          />
        </ToolGroup>
      </ToolBar>
    );

    const attributes = [
      buttonAlign === 'top' ? {divider: true} : null,
      {title: intl('Common.General')},
      {
        tid: 'name',
        key: <Form.Label name="name" title={intl('Common.Name')} />,
        value: <Form.Input tid="name" placeholder={intl('Labels.Create.Placeholder.LabelName')} name="name" />,
      },
      {
        tid: 'type',
        key: <Form.Label name="type" title={intl('Common.Type')} />,
        value: (
          <Form.Selector
            name="type"
            disabled={isEdit || typeIsDisabled}
            placeholder={intl('Labels.Create.Placeholder.LabelType')}
            options={selectorOptions.types}
          />
        ),
      },
      buttonAlign === 'bottom' ? {value: buttons} : null,
    ];

    if (label && label.external_data_set) {
      attributes.push({
        tid: 'external_data_set',
        key: <Form.Label name="external_data_set" title={intl('Common.ExternalSet')} />,
        value: label.external_data_set,
      });
    }

    if (label && label.external_data_reference) {
      attributes.push({
        tid: 'external_data_reference',
        key: <Form.Label name="external_data_reference" title={intl('Common.ExternalReference')} />,
        valueGap: 'gapMedium gapHorizontal gapAlignStart',
        value: label.external_data_reference.includes('\r') ? (
          <>
            <Badge type="updated" style={{lineHeight: 'var(--21px)'}}>
              {intl('Common.Edited')}
            </Badge>
            <span>{label.external_data_reference}</span>
          </>
        ) : (
          label.external_data_reference
        ),
      });
    }

    return (
      <>
        {!controlled && (
          <HeaderProps
            title={intl('Common.Labels')}
            subtitle={initialValues.name}
            label={`(${intl(isEdit ? 'Common.Edit' : 'Common.Create')})`}
            up={isEdit ? {to: 'labels.item', params: {id}} : 'labels.list'}
          />
        )}
        {buttonAlign === 'top' && buttons}
        <AttributeList valuesGap="gapLarge">{attributes}</AttributeList>
        {error && this.renderEditAlert()}
      </>
    );
  }

  render() {
    return (
      <Form
        enableReinitialize
        schemas={this.schemas}
        initialValues={this.state.initialValues}
        onSubmit={this.handleSubmit}
        {...this.props.formProps}
      >
        {this.renderForm}
      </Form>
    );
  }
}
