/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from 'intl';
import * as PropTypes from 'prop-types';
import {PureComponent, createRef} from 'react';
import {composeThemeFromProps} from '@css-modules-theme/react';
import {AppContext} from 'containers/App/AppUtils';
import {reactUtils} from 'utils';
import {AttributeList, Form, Button, ModalMachineAuto, GridLocal, Icon} from 'components';
import {unpairVens} from '../VenSaga';
import {object} from 'yup';
import styles from './VenUnpair.css';

const getEmptyState = () => ({
  showModal: false,
  running: false,
  error: null,
});

const gridSettings = {
  id: 'unpairGrid',
  columns: {
    selected: {
      header: intl('Common.Selected'),
      value: 'selected',
    },
    unpairHeader: {
      header: intl('Workloads.Unpair.Type.Header'),
      value: 'unpairHeader',
    },
    enforcement: {
      header: intl('Common.Enforcement'),
      value: 'enforcement',
    },
    des: {
      header: intl('Workloads.Unpair.Actions.Header'),
      value: 'des',
    },
    remove: {
      header: intl('Common.Remove'),
      value: true,
      format: ({row}) => (
        <Icon name="close" theme={styles} themePrefix="close-" onClick={_.partial(row.remove, row.key)} />
      ),
      sortable: false,
    },
  },
  templates: [
    [
      {columns: ['selected'], size: 'max-content'},
      {columns: ['unpairHeader'], size: 'max-content'},
      {columns: ['enforcement'], size: 'max-content'},
      {columns: ['des'], size: 'minmax(120px, auto)'},
      {columns: ['remove'], size: 'max-content'},
    ],
    {
      maxWidth: 1120,
      template: [
        {columns: ['selected'], size: 'max-content'},
        {columns: ['unpairHeader', 'enforcement'], size: 'max-content'},
        {columns: ['des'], size: 'minmax(120px, auto)'},
        {columns: ['remove'], size: 'max-content'},
      ],
    },
    {
      maxWidth: 960,
      template: [
        {columns: ['selected'], size: 'max-content'},
        {columns: ['unpairHeader', 'enforcement'], size: 'max-content'},
        {columns: ['des'], size: 'minmax(120px, auto)'},
        {columns: ['remove'], size: 'max-content'},
      ],
    },
    {
      maxWidth: 640,
      template: [
        {columns: ['selected', 'unpairHeader', 'enforcement'], size: 'max-content'},
        {columns: ['des'], size: 'minmax(120px, auto)'},
        {columns: ['remove'], size: 'max-content'},
      ],
    },
  ],
};

export default class VenUnpair extends PureComponent {
  static contextType = AppContext;
  static propTypes = {
    hrefsIdlePairingKey: PropTypes.instanceOf(Set).isRequired,
    hrefsIdleKerberosPKI: PropTypes.instanceOf(Set).isRequired,
    hrefsToUnpair: PropTypes.instanceOf(Set).isRequired,
    hrefsKerberosPKI: PropTypes.instanceOf(Set).isRequired,
    hrefsPairingKey: PropTypes.instanceOf(Set).isRequired,
    showCounter: PropTypes.bool,

    onButtonHover: PropTypes.func,
    onCancel: PropTypes.func,
    onDone: PropTypes.func,
    disabled: PropTypes.bool,
    edgeEnabled: PropTypes.bool,
  };

  static defaultProps = {
    showCounter: false,
    onButtonHover: _.noop,
    onDone: _.noop,
    disabled: false,
  };

  constructor(props) {
    super(props);

    this.state = getEmptyState();
    this.closeRef = createRef();

    this.handleButtonEnter = this.handleButtonEnter.bind(this);
    this.handleButtonLeave = this.handleButtonLeave.bind(this);
    this.handleButtonClick = this.handleButtonClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleConfirmationClose = this.handleConfirmationClose.bind(this);
    this.onUnpair = this.onUnpair.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
    this.renderForm = this.renderForm.bind(this);

    this.initialValues = {
      firewallStatus: 'saved',
    };

    this.schemas = object({
      firewallStatus: Form.Radio.schema.required(),
    });
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!prevState.showModal) {
      return {
        hrefsIdlePairingKey: nextProps.hrefsIdlePairingKey,
        hrefsIdleKerberosPKI: nextProps.hrefsIdleKerberosPKI,
        hrefsToUnpair: nextProps.hrefsToUnpair,
        hrefsPairingKey: nextProps.hrefsPairingKey,
        hrefsKerberosPKI: nextProps.hrefsKerberosPKI,
      };
    }

    return null;
  }

  handleButtonEnter(evt) {
    this.props.onButtonHover(evt, 'enter');
  }

  handleButtonLeave(evt) {
    // Notify parent on leave only when modal is not shown,
    // so parent can drop highlight if user moved out cursor and haven't opened modal
    if (!this.state.showModal) {
      this.props.onButtonHover(evt, 'leave');
    }
  }

  handleButtonClick() {
    this.setState({showModal: true});
  }

  handleConfirmationClose(evt) {
    this.props.onButtonHover(evt, 'leave');
    this.handleClose();
  }

  handleClose() {
    this.setState(getEmptyState());
  }

  async handleRemove(name) {
    const {
      state: {hrefsIdlePairingKey, hrefsIdleKerberosPKI, hrefsToUnpair, hrefsKerberosPKI, hrefsPairingKey},
    } = this;
    const hrefsIdle = new Set([...hrefsIdleKerberosPKI, ...hrefsIdlePairingKey]);
    const kerberosOrPKI = new Set([...hrefsKerberosPKI].filter(href => !hrefsIdle.has(href)));
    const pairingKey = new Set([...hrefsPairingKey].filter(href => !hrefsIdle.has(href)));
    let newhrefsToUnpair = hrefsToUnpair;
    let newhrefsPairingKey = pairingKey;
    let newhrefsIdleKerberosPKI = hrefsIdleKerberosPKI;
    let newhrefsKerberosPKI = kerberosOrPKI;
    let newhrefsIdlePairingKey = hrefsIdlePairingKey;

    if (name === 'pairingkey') {
      newhrefsToUnpair = new Set([...hrefsToUnpair].filter(href => !pairingKey.has(href)));
      newhrefsPairingKey = new Set();
    } else if (name === 'pkidle') {
      newhrefsToUnpair = new Set([...hrefsToUnpair].filter(href => !hrefsIdlePairingKey.has(href)));
      newhrefsIdlePairingKey = new Set();
    } else if (name === 'kerberopki') {
      newhrefsToUnpair = new Set([...hrefsToUnpair].filter(href => !kerberosOrPKI.has(href)));
      newhrefsKerberosPKI = new Set();
    } else if (name === 'kpidle') {
      newhrefsToUnpair = new Set([...hrefsToUnpair].filter(href => !hrefsIdleKerberosPKI.has(href)));
      newhrefsIdleKerberosPKI = new Set();
    }

    if (newhrefsToUnpair.size === 0) {
      this.setState(getEmptyState());
    }

    await reactUtils.setStateAsync(
      {
        hrefsToUnpair: newhrefsToUnpair,
        hrefsPairingKey: newhrefsPairingKey,
        hrefsIdleKerberosPKI: newhrefsIdleKerberosPKI,
        hrefsKerberosPKI: newhrefsKerberosPKI,
        hrefsIdlePairingKey: newhrefsIdlePairingKey,
      },
      this,
    );
  }

  onUnpair() {
    const {
      context: {fetcher},
      formik: {values},
      state: {hrefsToUnpair, hrefsIdlePairingKey, hrefsIdleKerberosPKI},
    } = this;

    const hrefsIdle = new Set([...hrefsIdleKerberosPKI, ...hrefsIdlePairingKey]);

    const restoreType = values && hrefsToUnpair.size > hrefsIdle.size ? values.firewallStatus : 'default';

    return fetcher.spawn(unpairVens, {
      managedHrefs: Array.from(hrefsToUnpair, workloadHref => ({href: workloadHref})),
      restoreType,
    });
  }

  renderForm(context, send, options) {
    const {edgeEnabled} = this.props;
    const {hrefsIdlePairingKey, hrefsIdleKerberosPKI, hrefsToUnpair, hrefsKerberosPKI, hrefsPairingKey} = this.state;
    const hrefsIdle = new Set([...hrefsIdleKerberosPKI, ...hrefsIdlePairingKey]);
    const kerberosOrPKI = new Set([...hrefsKerberosPKI].filter(href => !hrefsIdle.has(href))).size;
    const pairingKey = new Set([...hrefsPairingKey].filter(href => !hrefsIdle.has(href))).size;
    const rows = [];

    const theme = composeThemeFromProps(styles, this.props);

    this.formik = options;

    if (pairingKey > 0) {
      rows.push({
        key: 'pairingkey',
        data: {
          selected: pairingKey,
          unpairHeader: intl('Workloads.Unpair.Type.PairingKey'),
          enforcement: edgeEnabled ? intl('Workloads.Unpair.Mode.EdgeType') : intl('Workloads.Unpair.Mode.Type'),
          des: (
            <>
              <div className={styles.selectedAction}>{intl('Workloads.Unpair.Action')}</div>
              <div className={styles.selectedAction}>{intl('Workloads.Unpair.Actions.PairingKey')}</div>
              <div className={styles.selectedAction}>
                {!edgeEnabled && intl('Workloads.Unpair.Actions.FirewallChoice')}
              </div>
            </>
          ),
        },
        remove: this.handleRemove,
      });
    }

    if (hrefsIdlePairingKey.size > 0) {
      rows.push({
        key: 'pkidle',
        data: {
          selected: hrefsIdlePairingKey.size,
          unpairHeader: intl('Workloads.Unpair.Type.PairingKey'),
          enforcement: intl('Common.Idle'),
          des: (
            <>
              <div className={styles.selectedAction}>{intl('Workloads.Unpair.Action')}</div>
              <div className={styles.selectedAction}>{intl('Workloads.Unpair.Actions.PairingKey')}</div>
              <div className={styles.selectedAction}>{intl('Workloads.Unpair.Actions.Idle')}</div>
            </>
          ),
        },
        remove: this.handleRemove,
      });
    }

    if (kerberosOrPKI > 0) {
      rows.push({
        key: 'kerberopki',
        data: {
          selected: kerberosOrPKI,
          unpairHeader: intl('Workloads.Unpair.Type.KerberosPKI'),
          enforcement: edgeEnabled ? intl('Workloads.Unpair.Mode.EdgeType') : intl('Workloads.Unpair.Mode.Type'),
          des: (
            <>
              <div className={styles.selectedAction}>{intl('Workloads.Unpair.Action')}</div>
              <div className={styles.selectedAction}>{intl('Workloads.Unpair.Actions.KerberosPKI')}</div>
              <div className={styles.selectedAction}>{intl('Workloads.Unpair.Actions.FirewallChoice')}</div>
            </>
          ),
        },
        remove: this.handleRemove,
      });
    }

    if (hrefsIdleKerberosPKI.size > 0) {
      rows.push({
        key: 'kpidle',
        data: {
          selected: hrefsIdleKerberosPKI.size,
          unpairHeader: intl('Workloads.Unpair.Type.KerberosPKI'),
          enforcement: intl('Common.Idle'),
          des: (
            <>
              <div className={styles.selectedAction}>{intl('Workloads.Unpair.Action')}</div>
              <div className={styles.selectedAction}>{intl('Workloads.Unpair.Actions.KerberosPKI')}</div>
              <div className={styles.selectedAction}>{intl('Workloads.Unpair.Actions.Idle')}</div>
            </>
          ),
        },
        remove: this.handleRemove,
      });
    }

    return (
      <>
        <GridLocal secondary offset="0" settings={gridSettings} rows={rows} />
        {hrefsToUnpair.size > hrefsIdle.size && (
          <>
            <div className={styles.radioGroupTitle}>
              {!edgeEnabled && intl('Workloads.Unpair.SelectFirewallState')}
              {hrefsIdle.size > 0 && intl('Workloads.Unpair.IdleNotApply')}
            </div>
            <Form.RadioGroup name="firewallStatus">
              <Form.Radio
                value="saved"
                tid="removeIllumioPolicy"
                theme={theme}
                themePrefix="form-"
                label={
                  <AttributeList renderContentOnInit theme={theme} themePrefix="formAttributes-">
                    {[
                      {
                        title: !edgeEnabled
                          ? intl('Workloads.Unpair.Options.First.Title')
                          : intl('Workloads.Unpair.Options.First.TitleEdge'),
                      },
                      !edgeEnabled && {
                        key: intl('Workloads.Unpair.Options.OperatingSystems.Linux'),
                        value: intl('Workloads.Unpair.Options.First.LinuxDesc'),
                      },
                      !edgeEnabled && {
                        key: intl('Workloads.Unpair.Options.OperatingSystems.AIXSolaris'),
                        value: intl('Workloads.Unpair.Options.First.AIXSolarisDesc'),
                      },
                      {
                        key: intl('Workloads.Unpair.Options.OperatingSystems.Windows'),
                        value: edgeEnabled
                          ? intl('Workloads.Unpair.Options.First.WindowsEdgeDesc')
                          : intl('Workloads.Unpair.Options.First.WindowsDesc'),
                      },
                    ]}
                  </AttributeList>
                }
              />
              {!edgeEnabled && (
                <Form.Radio
                  value="disable"
                  tid="openPorts"
                  theme={theme}
                  themePrefix="form-"
                  label={
                    <AttributeList renderContentOnInit theme={theme} themePrefix="formAttributes-">
                      {[
                        {title: intl('Workloads.Unpair.Options.Second.Title')},
                        {
                          key: intl('Workloads.Unpair.Options.OperatingSystems.All'),
                          value: intl('Workloads.Unpair.Options.Second.Desc'),
                        },
                      ]}
                    </AttributeList>
                  }
                />
              )}
              {!edgeEnabled && (
                <Form.Radio
                  value="default"
                  tid="closePorts"
                  theme={theme}
                  themePrefix="form-"
                  label={
                    <AttributeList renderContentOnInit theme={theme} themePrefix="formAttributes-">
                      {[
                        {title: intl('Workloads.Unpair.Options.Third.Title')},
                        {
                          key: intl('Workloads.Unpair.Options.OperatingSystems.LinuxAIXSolaris'),
                          value: intl('Workloads.Unpair.Options.Third.LinuxDesc'),
                        },
                        {
                          key: intl('Workloads.Unpair.Options.OperatingSystems.Windows'),
                          value: intl('Workloads.Unpair.Options.Third.WindowsDesc'),
                        },
                      ]}
                    </AttributeList>
                  }
                />
              )}
            </Form.RadioGroup>
          </>
        )}
      </>
    );
  }

  renderModal(theme) {
    return (
      <ModalMachineAuto
        modalProps={{stretch: true, theme}}
        onClose={this.handleConfirmationClose}
        onDone={this.props.onDone}
        saga={this.onUnpair}
      >
        {{
          title: intl('Workloads.Unpair.Title', {count: this.state.hrefsToUnpair.size}),
          formProps: {
            schemas: this.schemas,
            initialValues: this.initialValues,
            allowLeaveOnDirty: true,
          },
          submitProps: {
            text: intl('Common.Unpair'),
          },
          confirmMessage: this.renderForm,
        }}
      </ModalMachineAuto>
    );
  }

  render() {
    const {
      props: {showCounter, disabled},
      state: {hrefsToUnpair, showModal},
    } = this;
    const theme = composeThemeFromProps(styles, this.props);

    return (
      <>
        <Button
          color="standard"
          textIsHideable
          text={intl('Common.Unpair')}
          tid="unpair"
          theme={theme}
          counter={showCounter ? hrefsToUnpair.size : undefined}
          counterColor="yellow"
          disabled={!hrefsToUnpair.size || disabled}
          onMouseEnter={this.handleButtonEnter}
          onMouseLeave={this.handleButtonLeave}
          onClick={this.handleButtonClick}
        />
        {showModal && this.renderModal(theme)}
      </>
    );
  }
}
