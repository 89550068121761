/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import cx from 'classnames';
import * as PropTypes from 'prop-types';
import {motion, AnimatePresence} from 'framer-motion';
import {composeThemeFromProps} from '@css-modules-theme/react';
import {classSplitter} from 'utils/general';
import {isMotionReduced} from 'utils/dom';
import {Icon} from 'components';
import styles from './TypedMessages.css';
import stylesUtils from 'utils.css';

const iconByType = {
  info: 'info',
  success: 'inuse',
  warning: 'warning',
  error: 'error',
};

const variants = {
  hide: () => ({height: 0, opacity: 0, transition: {duration: isMotionReduced() ? 0 : 0.15}}),
  show: () => ({height: 'auto', opacity: 1, transition: {duration: isMotionReduced() ? 0 : 0.2}}),
};

TypedMessages.propTypes = {
  // Gap class to specify distance between children. Default is 'gap' which will inherit specific gap from the closest parent
  // You can specify any gap class to override, like 'gapSmall'
  gap: PropTypes.string,
  // Children is an array of objects, or null/false/'' to skip rendering
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        // Every new child will be animated on add, specify key so 'framer-motion' could determine which one needs to be animated
        key: PropTypes.string,
        // Colored optional icon
        icon: PropTypes.oneOf(['info', 'success', 'warning', 'error']),
        // To offset child message padding to parent icon
        alignWithIcon: PropTypes.bool,
        // Any context/text next to the optional icon, or without the icon
        content: PropTypes.node,
        // Color of the content and icon
        color: PropTypes.oneOf(['info', 'success', 'warning', 'error', 'gray']),
        // Shorthand for a specific fontSize (like 'var(--12px)'), but default is inherited from the parent
        fontSize: PropTypes.string,
        // Custom tid
        tid: PropTypes.string,
      }),
    ),
    PropTypes.bool,
    PropTypes.string,
  ]),
};

export default function TypedMessages(props) {
  const {gap = 'gap', children} = props;
  const theme = composeThemeFromProps(styles, props);

  return (
    <div className={classSplitter(stylesUtils, gap)}>
      <AnimatePresence initial={false}>
        {children.reduce((result, item, index) => {
          if (item) {
            const {key, icon, content, color, fontSize, alignWithIcon = false, tid} = item;

            result.push(
              <motion.div
                key={key || index}
                className={theme.section}
                style={{fontSize}}
                tid={tid}
                variants={variants}
                initial="hide"
                animate="show"
                exit="hide"
              >
                {icon ? (
                  <div data-tid={tid} className={cx(theme.icon, color ? theme[color] : theme[icon])}>
                    <Icon name={iconByType[icon]} />
                  </div>
                ) : null}
                <div
                  data-tid={tid}
                  className={cx(theme.message, {[theme[color]]: color, [theme.offsetIconPadding]: alignWithIcon})}
                >
                  {content}
                </div>
              </motion.div>,
            );
          }

          return result;
        }, [])}
      </AnimatePresence>
    </div>
  );
}
