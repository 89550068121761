/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import {createSelector} from 'reselect';
import {edge} from 'api/apiUtils';

const SECONDS_PER_MINUTE = 60;
const SECONDS_PER_HOUR = 3600;
const SECONDS_PER_DAY = 86_400;
const SECONDS_PER_MONTH = 2_592_000;

export const secondsToUnit = (seconds, unit) => {
  const num = Number(seconds);

  return Math.floor(num / unit);
};

export const unitOptions = createSelector([], () => [
  {value: 'seconds', label: intl('Common.Seconds')},
  {value: 'minutes', label: intl('Common.Minutes')},
  {value: 'hours', label: intl('Common.Hours')},
  {value: 'days', label: intl('Common.Days')},
  {value: 'months', label: intl('OfflineTimers.Months')},
]);

export const disconnectOptions = createSelector([], () => [
  {value: '3600', label: intl('OfflineTimers.OneHourWait', {default: edge ? '' : `(${intl('Common.Default')})`})},
  {value: '-1', label: intl('OfflineTimers.NeverTimeout')},
  {value: 'custom', label: intl('OfflineTimers.CustomTimeoutQuarantine')},
]);

export const goodbyeOptions = createSelector([], () => [
  {value: '900', label: intl('OfflineTimers.FifteenMinutes', {default: edge ? '' : `(${intl('Common.Default')})`})},
  {value: '-1', label: intl('OfflineTimers.NeverCleanUp')},
  {value: 'custom', label: intl('OfflineTimers.CustomTimeoutIPCleanup')},
]);

export const getSecondsFromValues = (number, unit) => {
  let numSeconds;

  if (unit === 'seconds') {
    numSeconds = 1;
  } else if (unit === 'minutes') {
    numSeconds = SECONDS_PER_MINUTE;
  } else if (unit === 'hours') {
    numSeconds = SECONDS_PER_HOUR;
  } else if (unit === 'days') {
    numSeconds = SECONDS_PER_DAY;
  } else if (unit === 'months') {
    numSeconds = SECONDS_PER_MONTH;
  }

  return numSeconds * number;
};

export const getUnitFromSeconds = seconds => {
  if (!seconds) {
    return {time: seconds || 0, unit: 'seconds'};
  }

  if (seconds % SECONDS_PER_MONTH === 0) {
    return {time: secondsToUnit(seconds, SECONDS_PER_MONTH), unit: 'months'};
  }

  if (seconds % SECONDS_PER_DAY === 0) {
    return {time: secondsToUnit(seconds, SECONDS_PER_DAY), unit: 'days'};
  }

  if (seconds % SECONDS_PER_HOUR === 0) {
    return {time: secondsToUnit(seconds, SECONDS_PER_HOUR), unit: 'hours'};
  }

  if (seconds % SECONDS_PER_MINUTE === 0) {
    return {time: secondsToUnit(seconds, SECONDS_PER_MINUTE), unit: 'minutes'};
  }

  return {time: seconds || 0, unit: 'seconds'};
};

export const getCustomTimeout = (time, unit) => {
  if (unit === 'seconds') {
    return intl('OfflineTimers.SecondsNumber', {count: time});
  }

  if (unit === 'minutes') {
    return intl('OfflineTimers.MinutesNumber', {count: time});
  }

  if (unit === 'hours') {
    return intl('OfflineTimers.HoursNumber', {count: time});
  }

  if (unit === 'days') {
    return intl('OfflineTimers.DaysNumber', {count: time});
  }

  if (unit === 'months') {
    return intl('OfflineTimers.MonthsNumber', {count: time});
  }
};

export const getSecondsToTimeout = seconds => {
  if (!seconds) {
    return intl('OfflineTimers.SecondsNumber', {count: 0});
  }

  if (seconds % SECONDS_PER_MONTH === 0) {
    return intl('OfflineTimers.MonthsNumber', {count: secondsToUnit(seconds, SECONDS_PER_MONTH)});
  }

  if (seconds % SECONDS_PER_DAY === 0) {
    return intl('OfflineTimers.DaysNumber', {count: secondsToUnit(seconds, SECONDS_PER_DAY)});
  }

  if (seconds % SECONDS_PER_HOUR === 0) {
    return intl('OfflineTimers.HoursNumber', {count: secondsToUnit(seconds, SECONDS_PER_HOUR)});
  }

  if (seconds % SECONDS_PER_MINUTE === 0) {
    return intl('OfflineTimers.MinutesNumber', {count: secondsToUnit(seconds, SECONDS_PER_MINUTE)});
  }

  return intl('OfflineTimers.SecondsNumber', {count: seconds});
};

// expects value to be in seconds, as per workload_settings API
export const getDisconnectString = (seconds, edge) => {
  if (seconds === 3600) {
    return intl('OfflineTimers.OneHourWait', {default: edge ? '' : `(${intl('Common.Default')})`});
  }

  if (seconds === -1) {
    return intl('OfflineTimers.NeverTimeout');
  }

  return getSecondsToTimeout(seconds);
};

// expects value to be in seconds, as per workload_settings API
export const getGoodbyeString = (seconds, edge) => {
  if (seconds === 900) {
    return intl('OfflineTimers.FifteenMinutes', {default: edge ? '' : `(${intl('Common.Default')})`});
  }

  if (seconds === -1) {
    return intl('OfflineTimers.NeverCleanUp');
  }

  return getSecondsToTimeout(seconds);
};

export const disconnectDescriptionList = createSelector([], () => ({
  3600: [
    intl('OfflineTimers.Wait1Hour'),
    intl('OfflineTimers.CleanUpQuarantineWorkloads'),
    intl('OfflineTimers.PushPolicyRemainingDisconnect'),
  ],
  [-1]: [
    intl('OfflineTimers.NeverDisconnectOrQuarantine'),
    intl('OfflineTimers.KeepAllIPsAndNeverAutoRemove'),
    intl('OfflineTimers.RequiresManualRemoval'),
  ],
  custom: [
    intl('OfflineTimers.WaitForSpecifiedTime'),
    intl('OfflineTimers.CleanUpQuarantineWorkloads'),
    intl('OfflineTimers.PushPolicyRemainingDisconnect'),
  ],
}));

export const getDisconnectDescription = (obj, edge) => {
  const {time, unit} = obj;
  const newTime = typeof time === 'string' ? Number(time) : time;

  let disconnectValue;
  let disconnectString;

  if (unit) {
    disconnectString = getCustomTimeout(time, unit);
    disconnectValue = getSecondsFromValues(time, unit);
  } else {
    disconnectString = getDisconnectString(newTime, edge);
    disconnectValue = disconnectString;
  }

  const key = newTime !== -1 && newTime !== 3600 ? 'custom' : newTime;

  return {
    title: intl('OfflineTimers.DescTitle'),
    value: disconnectValue,
    list: disconnectDescriptionList()[key],
  };
};

export const goodbyeDescriptionList = createSelector([], () => ({
  900: [
    intl('OfflineTimers.ListenGoodbye'),
    intl('OfflineTimers.FifteenMinutesCleanup'),
    intl('OfflineTimers.PushPolicyRemainingDecommission'),
  ],
  [-1]: [
    intl('OfflineTimers.IgnoreGoodbyeMessages'),
    intl('OfflineTimers.KeepAllIPsAndNeverAutoCleanup'),
    intl('OfflineTimers.RequiresManualRemoval'),
  ],
  custom: [
    intl('OfflineTimers.ListenGoodbye'),
    intl('OfflineTimers.WaitForSpecifiedTimeGoodbye'),
    intl('OfflineTimers.PushPolicyRemainingDecommission'),
  ],
}));

export const getGoodbyeDescription = (obj, edge) => {
  const {time, unit} = obj;
  const newTime = typeof time === 'string' ? Number(time) : time;

  let goodbyeValue;
  let goodbyeString;

  if (unit) {
    goodbyeString = getCustomTimeout(time, unit);
    goodbyeValue = getSecondsFromValues(time, unit);
  } else {
    goodbyeString = getGoodbyeString(newTime, edge);
    goodbyeValue = goodbyeString;
  }

  const key = newTime !== -1 && newTime !== 900 ? 'custom' : newTime;

  return {
    title: intl('OfflineTimers.DescTitle'),
    value: goodbyeValue,
    list: goodbyeDescriptionList()[key],
    note: key === 'custom' ? intl('OfflineTimers.DecommissionIpCleanupNote') : null,
  };
};

export const isValidWaitTime = (minValue, timeUnit) => inputValue => {
  let isValid = false;
  const value = parseInt(inputValue, 10);

  if (Number.isNaN(value)) {
    isValid = false;
  } else if (timeUnit === 'seconds') {
    isValid = value >= minValue;
  } else if (timeUnit === 'minutes') {
    isValid = value * SECONDS_PER_MINUTE >= minValue;
  } else {
    isValid = value >= 1;
  }

  return isValid;
};
