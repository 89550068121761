/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import * as PropTypes from 'prop-types';
import {useContext, useLayoutEffect, useRef} from 'react';
import {AppContext} from 'containers/App/AppUtils';
import {GatewayContext} from './GatewayController';
import GatewayProvider from './GatewayProvider';
import GatewayTarget from './GatewayTarget';

Gateway.Provider = GatewayProvider;
Gateway.Target = GatewayTarget;
Gateway.propTypes = {
  // Corresponding name of the GatewayTarget
  into: PropTypes.string.isRequired,
  // Content to render
  children: PropTypes.node,
};

/**
 * Gateway is a component that takes children and sends them to the named GatewayTarget through the GatewayController.
 * Gateway renders nothing, it's more like a wormhole into another part of the react tree.
 *
 * For example,
 * ####################################################################
 * Gateway into="T1"  ↘                                               #
 * Gateway into="T2" -> GatewayController -> GatewayTarget name="T1"  #
 * Gateway into="T1"  ↗                    ↘ GatewayTarget name="T2"  #
 * ####################################################################
 */
export default function Gateway(props) {
  // Get the GatewayController from the context
  const controller = useContext(GatewayContext);
  // Get the innermost instance of the AppContext with the closest fetcher instance,
  // and pass it to the GatewayController along with children to render,
  // to make sure that, for example, Modal's content will be using that closest fetcher.
  const appContext = useContext(AppContext);
  // GatewayController will generate an id for this Gateway instance which we'll be storing in a ref
  const id = useRef(null);

  // Update GatewayTarget with new props through controller on every render synchronously
  useLayoutEffect(() => {
    id.current = controller.renderChild(props, appContext, id.current);
  });

  // Remove children upon unmount (controller dependency never changes)
  useLayoutEffect(() => () => controller.checkOutChild(id.current), [controller]);

  return null;
}
