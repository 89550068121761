/**
 * Copyright 2021 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import {useCallback, useEffect, useRef, useLayoutEffect, useState} from 'react';
import {useDeepCompareMemo} from 'utils/react';
import ContainerResource from './Resource/ContainerResource';
import ListResource from './Resource/ListResource';
import {useFilialPiety, CATEGORYPANEL_ID, OPTIONPANEL_ID} from 'containers/Selector/SelectorUtils';

export default function OptionPanel(props) {
  const {
    theme,
    saveRef,
    category,
    category: {id: categoryId, infoPanel: categoryInfoPanel, maxColumns},
    infoPanel: selectorInfoPanel,
    searchPromises = {},
    registerHandlers,
    ...restProps
  } = props;
  const prevCategoryId = useRef(null);
  const resourcesRef = useRef(null);
  const elementRef = useRef(null);
  const initialLoadRef = useRef(null);
  const [renderedWidth, setRenderedWidth] = useState(null);
  const appliedWidth = prevCategoryId.current !== categoryId ? null : renderedWidth;
  const {saveChildRef, registerChildHandlers, setHighlightedChild, resetHighlightedChild, keyDown} = useFilialPiety();

  const template = useDeepCompareMemo(category.template);
  const resources = useDeepCompareMemo(category.resources);
  const pathArr = useDeepCompareMemo(props.pathArr);

  const saveRefCallback = useCallback(
    element => {
      elementRef.current = element;
      saveRef(OPTIONPANEL_ID, element);
    },
    [saveRef],
  );

  if (prevCategoryId.current !== categoryId && !initialLoadRef.current) {
    initialLoadRef.current = Object.entries(resources).reduce((result, [resourceId, {hidden}]) => {
      if (hidden) {
        return result;
      }

      const promiseObj = {};

      promiseObj.promise = new Promise((resolve, reject) => {
        promiseObj.onInitialLoadDone = resolve;
        promiseObj.onInitialLoadReject = reject;
      }); // promiseObj: {promise, onInitialLoadDone, onInitialLoadReject}

      result[resourceId] = promiseObj;

      return result;
    }, {});
  }

  useLayoutEffect(() => {
    if (pathArr.includes(CATEGORYPANEL_ID)) {
      return;
    }

    // listens to template change
    // fallback to Selector default small/wide screen template if template string changes to empty string
    // e.g. on label create option click, the template is set to empty string so that form can occupy all available space
    resourcesRef.current.style.setProperty(
      '--resources-template-wide-screen',
      template || 'auto / repeat(auto-fit, minmax(180px, 1fr))',
    );
    resourcesRef.current.style.setProperty(
      '--resources-template-small-screen',
      template || 'auto / minmax(180px, 1fr)',
    );
  }, [pathArr, template]);

  useLayoutEffect(() => {
    if (prevCategoryId.current === categoryId || !initialLoadRef.current) {
      return;
    }

    (async () => {
      try {
        const promises = initialLoadRef.current;

        await Promise.all(Object.values(promises).map(({promise}) => promise));

        prevCategoryId.current = categoryId;
        initialLoadRef.current = null;

        setRenderedWidth(elementRef.current.getBoundingClientRect().width);
      } catch (error) {
        console.log(error); //placeholder for error handling
      }
    })();
  }, [categoryId, resources]);

  useEffect(() => {
    const unregister = registerHandlers(OPTIONPANEL_ID, {setHighlightedChild, resetHighlightedChild, keyDown});

    return () => unregister();
  }, [registerHandlers, setHighlightedChild, resetHighlightedChild, keyDown]);

  const style = {};

  if (appliedWidth) {
    style.width = appliedWidth;
  }

  if (maxColumns) {
    style.maxWidth = `${maxColumns * 180}px`;
  }

  return (
    <div
      ref={saveRefCallback}
      className={theme.optionPanel}
      {...(_.isEmpty(style) ? {} : {style})}
      data-tid="comp-selector-optionpanel"
    >
      {!pathArr.includes(CATEGORYPANEL_ID) && selectorInfoPanel}
      {categoryInfoPanel}
      <div ref={resourcesRef} className={theme.resources}>
        {Object.entries(resources)
          .filter(([, {hidden}]) => !hidden)
          .map(([resourceId, {type}]) => {
            const Component = type === 'container' ? ContainerResource : ListResource;

            return (
              <Component
                key={resourceId}
                {...restProps}
                theme={theme}
                id={resourceId}
                category={category}
                saveRef={saveChildRef}
                isGridArea={Boolean(template)}
                pathArr={[...pathArr, OPTIONPANEL_ID]}
                registerHandlers={registerChildHandlers}
                resource={props.allResources[resourceId]}
                initialLoadParams={initialLoadRef.current?.[resourceId]}
                searchParams={type === 'container' ? searchPromises[resourceId] : null}
              />
            );
          })}
      </div>
    </div>
  );
}
