/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */

import {fillUserInfo} from 'containers/RBAC/RBACUtils';
import {getAllUsersMap} from 'containers/User/UserState';
import produce from 'immer';
import {createSelector} from 'reselect';

export default {
  detail(state = {}, action) {
    switch (action.type) {
      case 'EVENTS_GET_DETAIL':
        return action.data.detail;
      default:
        return state;
    }
  },
};

export const getEventsDetail = createSelector([state => state.events.detail, getAllUsersMap], (detail, usersMap) => {
  return {
    ...detail,
    created_by: produce(detail.created_by, draft => {
      if (draft?.user) {
        draft.user = fillUserInfo(usersMap, draft.user);
      }

      if (draft?.service_account) {
        draft.service_account = fillUserInfo(usersMap, draft.service_account);
      }
    }),
  };
});
