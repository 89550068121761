/**
 * Copyright 2016 Illumio, Inc. All Rights Reserved.
 */
import cx from 'classnames';
import PubSub from 'pubsub';
import {Resizable} from 're-resizable';
import * as PropTypes from 'prop-types';
import FocusLock from 'react-focus-lock';
import {useTransformRef} from 'use-callback-ref';
import {composeThemeFromProps} from '@css-modules-theme/react';
import {forwardRef, PureComponent} from 'react';
import {mutuallyExclusiveTruePropsSpread} from 'utils/react';
import {ModalContext} from './ModalUtils';
import ModalGateway from './ModalGateway';
import Header from './ModalHeader';
import Content from './ModalContent';
import Footer from './ModalFooter';
import ModalStickyShadow from './ModalStickyShadow';
import {Gateway} from 'components';
import {tidUtils} from 'utils';
import styles from './Modal.css';
import Alert from './Alert/Alert';

/* In some cases, we may need to position elements in between multiple Modals mounts
 * For e.g. In case of Selector (lets say S) if we render EditLabels button in Selector option panel
 * then clicking on this button should mount a Modal (lets say M), this modal further mounts a Selector for scope selection
 * So, here the z-order is S1 -> M1 -> S2 -> M2.
 * zIndex variable is incremented by 10 in Modal constructor and is passed as context to Selector, which positions itself at zIndex + 1
 * */
let zIndex = 10_000;
let closeEventTimeoutId;
const sizes = {
  small: '400px',
  medium: '500px',
  large: '640px',
  full: '92%',
  stretch: 'auto',
};

export default class Modal extends PureComponent {
  static Gateway = ModalGateway;
  static Content = Content;
  static Header = Header;
  static Footer = Footer;
  static Alert = Alert;
  static StickyShadow = ModalStickyShadow;

  static propTypes = {
    // Modal size fixes width Default is medium
    small: PropTypes.bool, // 400px, maximum is 96vw
    medium: PropTypes.bool, // 500px, maximum is 96vw
    large: PropTypes.bool, // 640px, maximum is 96vw
    full: PropTypes.bool, // 92vw and 96vw if windows is smaller than 800px
    stretch: PropTypes.bool, // Stretch from 360px to full size
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]), // Alternatively you can give it a custom initial width
    ...mutuallyExclusiveTruePropsSpread('small', 'medium', 'large', 'full', 'stretch'), // Declare sizes to be mutually exclusive

    tid: PropTypes.string,

    instant: PropTypes.bool, // Whether modal should be emerged instantly (true) or using animation (false - default)
    notResizable: PropTypes.bool, // Whether modal should not be resizable by user
    dontStretchChildren: PropTypes.bool, // Don't apply `flex: 1 1 auto` to children
    dontRestrainChildren: PropTypes.bool, // Don't apply `overflow: hidden` to children

    // Don't change width after initial show if Modal is set to 'stretch'.
    // Useful when Modal should initially adjust, but subsequent content changes should not change width to avoid width jumps,
    // For example, SessionExpiration has a countdown and width should not change when the number decrements from 10 to 9
    fixStretchedWidth: PropTypes.bool,

    // min/maxWidth can be either a number(640), string('640px'/'80%') or a size('small'/'medium'/'large')
    minWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]), // 375 by default
    minHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]), // 200 by default
    maxWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    maxHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

    // Whether first tabbable element inside modal should be automatically focused on open (false - default).
    // If you have several tabbable elements inside modal (links, buttons, etc), but want to automatically focus on not first,
    // just assign tabIndex="1" (first positive index) property to that element
    autoFocus: PropTypes.bool,
    focusLockProps: PropTypes.object,

    idleOnEsc: PropTypes.bool, // Do nothing (true) or close modal (false - default) on esc key press
    idleOnBackdropClick: PropTypes.bool, // Do nothing (true) or close modal (false - default) on backdrop click/touch
    onClose: PropTypes.func, // Callback that will be called on Esc or CloseIcon/Backdrop click (if enabled by above properties)
    // Alternative to handling onClose you can specify a reference of an action element, like Button or Link,
    // that should be artificially clicked on Esc or CloseIcon/Backdrop click.
    // For instance when Button in Footer is a link that changes route
    closeRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({current: PropTypes.any})]),
  };

  static defaultProps = {
    instant: false,
    autoFocus: false,
    notResizable: false,

    minWidth: 375 /* 390 min viewport width * 96vw(0.96) */,
    minHeight: 200,

    idleOnEsc: false,
    idleOnBackdropClick: false,
  };

  constructor(props) {
    super(props);

    zIndex += 10;

    this.handleClose = this.handleClose.bind(this);
    this.saveModalRef = this.saveModalRef.bind(this);
    this.getModalRef = this.getModalRef.bind(this);

    this.state = {context: {onClose: this.handleClose, getModalRef: this.getModalRef, zIndex}};
    this.width = null;

    // A way to make react-focus-lock work with re-resizable without nesting one into each and creating extra divs
    // https://github.com/theKashey/react-focus-lock/issues/85
    this.ResizableWithRef = forwardRef((props, ref) => (
      <Resizable {...props} ref={useTransformRef(ref, i => i?.resizable)} />
    ));
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const {context} = prevState;

    if (context.tid !== nextProps.tid) {
      return {context: {...context, tid: nextProps.tid}};
    }

    return null;
  }

  componentDidMount() {
    if (closeEventTimeoutId) {
      clearTimeout(closeEventTimeoutId);
      closeEventTimeoutId = null;
    } else {
      // Publish open event for QA
      PubSub.publish('MODAL.OPEN');
    }
  }

  componentWillUnmount() {
    closeEventTimeoutId = setTimeout(() => {
      closeEventTimeoutId = null;
      // Publish close event for QA
      PubSub.publish('MODAL.CLOSE');
    }, 100);
  }

  getModalRef() {
    return this.$modal;
  }

  saveModalRef(element) {
    this.$modal = element;

    if (!element && this.width) {
      this.width = null;
    }
  }

  handleModalClick(evt) {
    // Do not propagate click on modal itself to make click on .animator work only outside of modal
    evt.stopPropagation();
  }

  handleClose(evt) {
    const {closeRef, onClose} = this.props;

    if (closeRef) {
      try {
        (closeRef.current || closeRef).click();
      } catch (error) {
        console.warn('Modal onclose button click emulation', error);
      }
    } else if (onClose) {
      onClose(evt);
    }
  }

  fixStretchedWidth() {
    // This call is delayed from the ModalGateway until show animation is done,
    // because getBoundingClientRect takes css transforms into account,
    // but we need to use it because unlike offsetWidth it returns precise width with fractions
    if (this.props.stretch && this.props.fixStretchedWidth && this.$modal) {
      this.width = `${this.$modal.getBoundingClientRect().width}px`;
    } else if (this.width) {
      this.width = null;
    }
  }

  render() {
    const {
      children,
      tid,
      small,
      large,
      full,
      stretch,
      autoFocus,
      instant,
      notResizable,
      dontStretchChildren,
      dontRestrainChildren,
      minWidth,
      minHeight,
      maxWidth,
      maxHeight,
      idleOnEsc,
      idleOnBackdropClick,
    } = this.props;
    const theme = composeThemeFromProps(styles, this.props);
    let width;

    if (this.width) {
      width = this.width;
    } else if (small) {
      width = sizes.small;
    } else if (large) {
      width = sizes.large;
    } else if (full) {
      width = sizes.full;
    } else if (stretch) {
      width = sizes.stretch;
    } else {
      width = this.props.width /*custom*/ ?? sizes.medium;
    }

    const container = notResizable ? 'div' : this.ResizableWithRef;
    const props = {
      'onClick': this.handleModalClick,
      'data-tid': tidUtils.getTid('comp-dialog', tid),
    };
    const className = cx(theme.modal, {
      [theme.stretchChildren]: !dontStretchChildren,
      [theme.noOverflowChildren]: !dontRestrainChildren,
    });

    if (notResizable) {
      props.style = {
        width,
        minWidth: sizes[minWidth] ?? minWidth,
        minHeight,
        maxWidth: sizes[maxWidth] ?? maxWidth,
        maxHeight,
      };
    } else {
      props.minWidth = sizes[minWidth] ?? minWidth;
      props.minHeight = minHeight;
      props.maxWidth = sizes[maxWidth] ?? maxWidth;
      props.maxHeight = maxHeight;
      props.defaultSize = {width};
      props.handleWrapperClass = theme.fixedHeight;
    }

    const focusLockProps = {
      ...this.props.focusLockProps,
      ref: this.saveModalRef,
      className,
      autoFocus,
      as: container,
      lockProps: props,
    };

    return (
      <Gateway
        into="modal"
        theme={theme}
        instant={instant}
        instance={this}
        idleOnEsc={idleOnEsc}
        idleOnBackdropClick={idleOnBackdropClick}
        onClose={this.handleClose}
        zIndex={zIndex}
      >
        <FocusLock {...focusLockProps}>
          <ModalContext.Provider value={this.state.context}>{children}</ModalContext.Provider>
        </FocusLock>
      </Gateway>
    );
  }
}
