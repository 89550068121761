/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import _ from 'lodash';
import {createSelector} from 'reselect';
import {getGridSelector} from 'components/Grid/GridSelectors';
import {getWorkloadInstance} from '../WorkloadItemState';
import {gridSettings} from './WorkloadCompatibilityReportConfig';
import {statusIcons} from '../../WorkloadUtils';

export function extractReportData(data) {
  return _.transform(data, (result, report) => {
    if (
      report.hasOwnProperty('required_packages') ||
      report.hasOwnProperty('required_packages_missing') ||
      report.hasOwnProperty('required_packages_installed')
    ) {
      return;
    }

    const reportObject = {};

    Object.keys(report).forEach(key => {
      if (key === 'status') {
        reportObject.status = statusIcons[report.status].status;
      } else {
        reportObject.type = key;

        if (Array.isArray(report[key])) {
          reportObject.value = report[key].join(', ');
        } else if (report[key] === '') {
          reportObject.value = intl('Common.None');
        } else {
          reportObject.value = _.upperFirst(report[key]);
        }
      }
    });
    result.push(reportObject);
  });
}

export default {
  compatibilityreport(state = [], action) {
    switch (action.type) {
      case 'WORKLOAD_GET_REPORT':
        return action.data;
      default:
        return state;
    }
  },
};

export const getCompatibilityReport = state => state.workload.compatibilityreport;

export const getWorkloadCompatibilityReport = createSelector([getCompatibilityReport], compatibilityReport => {
  const reportData = !_.isEmpty(compatibilityReport)
    ? extractReportData(compatibilityReport.results.qualify_tests)
    : [];

  const compatibilityReportData = reportData.map((item, idx) => ({
    key: idx,
    ...item,
  }));

  return {
    compatibilityReportData,
  };
});

export const getCompatibilityReportRows = createSelector(
  [getWorkloadCompatibilityReport],
  ({compatibilityReportData}) => compatibilityReportData,
);

export const compatibilityReportGridSettings = createSelector([gridSettings], gridSettings => ({...gridSettings}));

const getCompatibilityReportGrid = state =>
  getGridSelector(state, {
    settings: compatibilityReportGridSettings,
    rows: getCompatibilityReportRows,
  });

export const getWorkloadCompatibilityReportsPage = createSelector(
  [getCompatibilityReportGrid, getCompatibilityReport, getWorkloadInstance],
  (compatibilityReportGrid, compatibilityReport, workload) => ({
    compatibilityReportGrid,
    compatibilityReport,
    workload,
  }),
);
