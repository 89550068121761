/**
 * Copyright 2021 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import {edge, isAPIAvailable} from 'api/apiUtils';
import {routesMap} from '../../router/routes';
import {isEventsEnabled} from '../Events/EventsState';
import {
  isExplorerEnabled,
  isTrafficEnabled,
  isIlluminationMapEnabled,
  isUserWithReducedScope,
  isSegmentationTemplatesEnabled,
  isUserOwner,
  canUserViewEnforcementBoundaries,
} from 'containers/User/UserState';
import {getVenLibraries} from '../Ven/Library/VenLibraryState';
import {isVenLibraryEnabled} from '../Ven/VenState';
import {isPolicySettingsEnabled} from '../PolicySettings/PolicySettingsState';
import {isSupportReportsEnabled} from '../SupportBundles/VENSupportReports/SupportReportsState';
import {isNetworkEnabled} from '../Network/NetworkState';
import {
  isHealthEnabled,
  isNetworkEnforcementNodeEnabled,
  isEdge,
  isCrowdstrike,
  isReportingEnabled,
  isIPForwardingEnabled,
  isCoreServicesEnabled,
} from '../App/AppState';
import {getCoreServiceSettings} from '../CoreServices/Settings/CoreServicesSettingsState';
import {isEventsConfigEnabled} from '../Events/Config/EventsConfigState';
import {isAuthenticationSettingsEnabled} from '../AuthenticationSettings/AuthenticationSettingsState';
import {isOfflineTimersEnabled} from '../OfflineTimers/OfflineTimersState';
import {isPairingProfilesEnabled} from '../PairingProfile/PairingProfileState';
import {isSecureGatewayEnabled} from '../SecureGateway/SecureGatewayState';
import {isAccessRestrictionEnabled} from '../RBAC/AccessRestriction/AccessRestrictionState';
import {isContainerClustersEnabled} from '../ContainerCluster/ContainerClusterState';
import {isLoadBalancerEnabled} from '../LoadBalancer/LoadBalancerState';
import {isSwitchEnabled} from '../Switch/SwitchState';
import {isSecuritySettingsEnabled} from '../SecuritySettings/SecuritySettingsState';
import {isPCESupportBundlesEnabled} from 'containers/SupportBundles/PCESupportBundles/PCESupportBundlesState';
import {isMyManagedTenantsEnabled} from '../MSP/MyManagedTenants/MyManagedTenantsState';

const tesseReactContainersPropertiesMap = new Map([
  [
    'EventsList',
    {
      viewName: () => intl('Common.Events'),
      isAvailable: isEventsEnabled,
      params: {
        section: 'landing',
      },
    },
  ],
  [
    'WorkloadList',
    {
      viewName: () => intl('Common.Workloads'),
      collectionName: 'workloads',
    },
  ],
  [
    'ProvisionVersionList',
    {
      viewName: () => intl('Provision.Versions', {multiple: true}),
      isAvailable: state => !isUserWithReducedScope(state),
    },
  ],
  [
    'VenLibrary',
    {
      viewName: () => intl('VEN.Library'),
      isAvailable: state => {
        const vens = getVenLibraries(state);

        return isVenLibraryEnabled(state) && !isCrowdstrike(state) && vens?.length > 0;
      },
    },
  ],
  [
    'ScopeList',
    {
      viewName: () => intl('Common.Scopes'),
      isAvailable: state => isUserOwner(state) && !isEdge(state),
    },
  ],
  [
    'MyRolesList',
    {
      viewName: () => intl('Users.MyRoles'),
      isAvailable: state => !isEdge(state),
    },
  ],
  [
    'Provisioning',
    {
      viewName: () => intl('Provision.ProvisioningStatus'),
    },
  ],
  [
    'PendingList',
    {
      viewName: () => intl('Provision.DraftChanges'),
      isAvailable: state => !isUserWithReducedScope(state),
    },
  ],
  [
    'PolicySettings',
    {
      viewName: () => intl('Policy.Settings'),
      isAvailable: isPolicySettingsEnabled,
    },
  ],
  [
    'IPListList',
    {
      viewName: () => intl(edge ? 'IPLists.Mixin.Ranges' : 'Common.IPLists'),
      collectionName: 'ip_lists',
    },
  ],
  [
    'SupportReportsList',
    {
      viewName: () => intl('SupportReports.PageName'),
      isAvailable: state => !isCrowdstrike(state) && isSupportReportsEnabled(state),
    },
  ],
  [
    'PCESupportBundlesList',
    {
      viewName: () => intl('PCESupportBundles.PageName'),
      isAvailable: state => isPCESupportBundlesEnabled(state),
    },
  ],
  [
    'NetworkList',
    {
      viewName: () => intl('Menu.Networks', {multiple: true}),
      isAvailable: state => isNetworkEnabled(state) && !isEdge(state),
    },
  ],
  [
    'LabelGroupList', // TODO: This container has a duplicate file name
    {
      viewName: () => intl('Labels.Groups'),
      collectionName: 'label_groups',
      isAvailable: state => !isEdge(state),
    },
  ],
  [
    'LabelList',
    {
      viewName: () => intl('Common.Labels'),
      collectionName: 'labels',
      isAvailable: state => !isEdge(state),
    },
  ],
  [
    'EventsConfig',
    {
      viewName: () => intl('Common.EventSettings'),
      isAvailable: state => !isEdge(state) && isHealthEnabled(state) && isEventsConfigEnabled(state),
    },
  ],
  [
    'APIKeysList',
    {
      viewName: () => intl('Users.APIKeys.MyAPIKeys'),
      isAvailable: state => !isEdge(state),
    },
  ],
  [
    'VenList',
    {
      viewName: () => intl('Common.VENS'),
      collectionName: 'vens',
      isAvailable: state => !isCrowdstrike(state),
    },
  ],
  [
    'AuthenticationSettings',
    {
      viewName: () => intl('Common.Authentication'),
      isAvailable: isAuthenticationSettingsEnabled,
    },
  ],
  [
    'HealthList',
    {
      viewName: () => intl('Health.PCEHealth'),
      isAvailable: isHealthEnabled,
    },
  ],
  [
    'OfflineTimers',
    {
      viewName: () => intl('OfflineTimers.OfflineTimers'),
      isAvailable: state => isOfflineTimersEnabled(state) && !isEdge(state),
    },
  ],
  [
    'MyProfile',
    {
      viewName: () => intl('Users.MyProfile'),
    },
  ],
  [
    'MyManagedTenantsList',
    {
      viewName: () => intl('MSP.MyManagedTenants'),
      isAvailable: state => isMyManagedTenantsEnabled(state),
    },
  ],
  [
    'ServiceList',
    {
      viewName: () => intl('Common.Services'),
      collectionName: 'services',
    },
  ],
  [
    'PairingProfileList',
    {
      viewName: () => intl('PairingProfiles.Profiles'),
      isAvailable: state => isPairingProfilesEnabled(state) && !isEdge(state),
    },
  ],
  [
    'ContainerWorkloadList',
    {
      viewName: () => intl('Common.ContainerWorkloads'),
      collectionName: 'container_workloads',
      isAvailable: state => !isEdge(state),
    },
  ],
  [
    'SecureGatewayList',
    {
      viewName: () => intl('SecureGateway.SecureConnect'),
      isAvailable: state => isSecureGatewayEnabled(state) && !isEdge(state),
    },
  ],
  [
    'UserGroupList',
    {
      viewName: () => intl('Common.UserGroups'),
    },
  ],
  [
    'FlowCollectionFilters',
    {
      viewName: () => intl('Common.FlowCollectionFilters'),
      isAvailable: state => !isEdge(state),
    },
  ],
  [
    'RulesetList',
    {
      viewName: () => intl('Common.Rulesets'),
      isAvailable: state => !isUserWithReducedScope(state) && !isEdge(state),
    },
  ],
  [
    'AccessRestrictionList',
    {
      viewName: () => intl('AccessRestriction.AccessRestrictionTitle'),
      isAvailable: isAccessRestrictionEnabled,
    },
  ],
  [
    'ContainerClusterList',
    {
      viewName: () => intl('Menu.ContainerClusters', {multiple: true}),
      collectionName: 'container_clusters',
      isAvailable: state => isContainerClustersEnabled(state) && !isEdge(state),
    },
  ],
  [
    'LoadBalancerList',
    {
      viewName: () => intl('Menu.LoadBalancers'),
      isAvailable: state => isLoadBalancerEnabled(state) && !isEdge(state),
    },
  ],
  [
    'TrustedProxyList',
    {
      viewName: () => intl('TrustedProxy.TrustedProxy'),
      isAvailable: state => !isEdge(state),
    },
  ],
  [
    'UserActivityList',
    {
      viewName: () => intl('RBAC.UsersActivity.UserActivity'),
      isAvailable: isUserOwner,
    },
  ],
  [
    'ExternalUserList',
    {
      viewName: () => intl('Users.ExternalUsers'),
      isAvailable: isUserOwner,
    },
  ],
  [
    'ExternalGroupList',
    {
      viewName: () => intl('Users.ExternalGroups'),
      isAvailable: isUserOwner,
    },
  ],
  [
    'GlobalRoleList',
    {
      viewName: () => intl('RBAC.GlobalRoles'),
      isAvailable: isUserOwner,
    },
  ],
  [
    'ServiceAccountList',
    {
      viewName: () => intl('Common.ServiceAccounts'),
      isAvailable: state => !isEdge(state) && isUserOwner,
    },
  ],
  [
    'APIKeySettingsView',
    {
      viewName: () => intl('APIKeySettings.APIKeySettings'),
      isAvailable: isUserOwner,
    },
  ],
  [
    'ReportsList',
    {
      viewName: () => intl('Exports.PageName'),
    },
  ],
  [
    'CoreServicesList',
    {
      viewName: () => intl('CoreServices.CoreServices'),
      isAvailable: state =>
        !isEdge(state) && isCoreServicesEnabled(state) && getCoreServiceSettings(state) === 'enabled',
    },
  ],
  [
    'CoreServicesSettings',
    {
      viewName: () => intl('CoreServices.CoreServiceSettings'),
      isAvailable: state => !isEdge(state) && isCoreServicesEnabled(state),
    },
  ],
  [
    'SwitchList',
    {
      viewName: () => intl('Menu.Switches'),
      isAvailable: state => isSwitchEnabled(state) && isNetworkEnforcementNodeEnabled(state) && !isEdge(state),
    },
  ],
  [
    'LocalUserList',
    {
      viewName: () => intl('Users.LocalUsers'),
      isAvailable: isUserOwner,
    },
  ],
  [
    'EssentialServiceRules',
    {
      viewName: () => intl('Settings.EssentialServiceRules'),
      isAvailable: state => !isEdge(state) && isAPIAvailable('essential_service_rules.get'),
    },
  ],
  [
    'IKECertificates',
    {
      viewName: () => intl('Settings.IKECertificates'),
    },
  ],
  [
    'EnforcementBoundariesMain',
    {
      viewName: () => intl('Workloads.EnforcementBoundaries'),
      isAvailable: state => !isEdge(state) && canUserViewEnforcementBoundaries(state),
    },
  ],
  [
    'VirtualServiceList',
    {
      viewName: () => intl('Common.VirtualServices'),
      collectionName: 'virtual_services',
      isAvailable: state => !isEdge(state),
    },
  ],
  [
    'VirtualServerList',
    {
      viewName: () => intl('Common.VirtualServers'),
      collectionName: 'virtual_servers',
      isAvailable: state => !isEdge(state),
    },
  ],
  [
    'CorporatePublicIPsView',
    {
      viewName: () => intl('GlobalNetwork.GlobalIPs'),
      collectionName: 'corporatepublicips',
      isAvailable: state => isSecuritySettingsEnabled(state),
    },
  ],
  [
    'SecuritySettings',
    {
      viewName: () => intl('Common.Security'),
      isAvailable: state => !isEdge(state) && isSecuritySettingsEnabled(state),
      aliases: [
        {
          viewName: () => `${intl('Common.Security')} - ${intl('Common.StaticPolicy')}`,
          params: {section: 'staticscopes'},
          isAvailable: state => !isEdge(state) && isSecuritySettingsEnabled(state),
        },
        {
          viewName: () => `${intl('Common.Security')} - ${intl('Firewall.Coexistence.Title')}`,
          params: {section: 'firewallcoexistence'},
          isAvailable: state => !isEdge(state) && isSecuritySettingsEnabled(state),
        },
        {
          viewName: () => `${intl('Common.Security')} - ${intl('Common.BlockAction')}`,
          params: {section: 'blockaction'},
          isAvailable: state => !isEdge(state) && isSecuritySettingsEnabled(state),
        },
        {
          viewName: () => `${intl('Common.Security')} - ${intl('Settings.LoopbackInterfaces.Title')}`,
          params: {section: 'loopbackinterfaces'},
          isAvailable: state => !isEdge(state) && isSecuritySettingsEnabled(state),
        },
        {
          viewName: () => `${intl('Common.Security')} - ${intl('Settings.Containers.ContainersPolicy')}`,
          params: {section: 'containers'},
          isAvailable: state => !isEdge(state) && isSecuritySettingsEnabled(state),
        },
        {
          viewName: () => `${intl('Common.Security')} - ${intl('Settings.IPForwarding.Title')}`,
          params: {section: 'ipforwarding'},
          isAvailable: state => !isEdge(state) && isSecuritySettingsEnabled(state) && isIPForwardingEnabled(state),
        },
        {
          viewName: () => `${intl('Common.Security')} - ${intl('Settings.SecureConnect')}`,
          params: {section: 'secureconnect'},
          isAvailable: state => !isEdge(state) && isSecuritySettingsEnabled(state),
        },
      ],
    },
  ],
  [
    'OutboundPolicy',
    {
      viewName: () => intl('Policy.Organizational'),
      isAvailable: state => isEdge(state) && !isCrowdstrike(state),
    },
  ],
  [
    'GroupDashboard',
    {
      viewName: () => intl('Common.Groups'),
      isAvailable: isEdge,
    },
  ],
  [
    'Reporting',
    {
      viewName: () => intl('Common.Reports'),
      isAvailable: state => !isEdge(state) && isAPIAvailable('reports.get_collection') && isReportingEnabled(state),
    },
  ],
]);

/**
 * Map of routes in legacy and their repective viewNames.
 *
 * This is done since we do not access to the container or
 * components in legacy, so this map simulates that.
 *
 * All role checking is done as well to emulate the main menu behavior.
 */
export const getLegacyRoutesDisplayNamesMap = state => {
  const legacyRoutesDisplayNameMap = {};

  if (isIlluminationMapEnabled(state) && !isEdge(state)) {
    legacyRoutesDisplayNameMap['app.map'] = {
      viewName: () => intl('Common.IlluminationMap'),
    };
  }

  const explorerIsEnabled = isExplorerEnabled(state);

  if (explorerIsEnabled) {
    legacyRoutesDisplayNameMap['app.explorer'] = {
      viewName: () => intl('Common.Explorer'),
    };
  }

  if (!isCrowdstrike(state)) {
    legacyRoutesDisplayNameMap[explorerIsEnabled ? 'app.blockedTraffic' : 'app.blockedTrafficOld'] = {
      viewName: () => intl('BlockedTraffic.Name'),
    };
  }

  if (isTrafficEnabled(state) && !isEdge(state)) {
    legacyRoutesDisplayNameMap['app.appMap'] = {
      viewName: () => intl('Common.AppGroupMap'),
    };

    legacyRoutesDisplayNameMap['app.appGroups'] = {
      viewName: () => intl('Common.AppGroupsList'),
    };

    legacyRoutesDisplayNameMap['app.policygenerator'] = {
      viewName: () => intl('PolicyGenerator.PolicyGenerator'),
    };
  }

  if (!isUserWithReducedScope(state) && !isEdge(state)) {
    legacyRoutesDisplayNameMap['app.ruleSearch'] = {
      viewName: () => intl('Common.RuleSearch'),
      params: {pversion: 'draft'},
    };

    legacyRoutesDisplayNameMap['app.connectivity'] = {
      viewName: () => intl('Policy.Check'),
    };
  }

  if (isSegmentationTemplatesEnabled(state) && !isEdge(state)) {
    legacyRoutesDisplayNameMap['app.segmentationTemplates.list'] = {
      viewName: () => intl('SegmentationTemplates.SegmentationTemplates'),
    };
  }

  return legacyRoutesDisplayNameMap;
};

export const getTesseReactRoutesNamesMap = () => {
  const routeNamesMap = new Map();

  for (const [routeName, routeProperties] of routesMap) {
    if (tesseReactContainersPropertiesMap.get(routeProperties.container)) {
      routeNamesMap.set(routeName, tesseReactContainersPropertiesMap.get(routeProperties.container));
    }
  }

  return routeNamesMap;
};

export default tesseReactContainersPropertiesMap;
