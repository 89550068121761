/**
 * Copyright 2021 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import PropTypes from 'prop-types';
import {Field} from 'formik';
import {forwardRefFactory, forwardRefSymbol} from 'react-forwardref-utils';
import {whenPropTypes, useWhen} from '../FormUtils';
import DurationPicker from '../../DurationPicker/DurationPicker';
import {areDurationsEqual} from '../../DurationPicker/DurationPickerUtils';

FormDurationPicker.propTypes = {
  // Name that corresponds to your form's schema
  name: PropTypes.string,
  customPickers: PropTypes.object,
  options: PropTypes.arrayOf(PropTypes.object),

  // By default FormSelector calls Selector onSelectionChange method and set selection changes to form values
  // If you want to intercept selection change and handle it manually, pass onChange handler to make it controlled by parent component
  onChange: PropTypes.func,

  // Might depend on other fields
  ...whenPropTypes,
};

function FormDurationPicker(props) {
  // Control field reset when dependecies are not true
  const {
    [forwardRefSymbol]: ref,
    onChange,
    options,
    form,
    field,
    type,
    ...durationPickerProps
  } = useWhen(props.form, props.field, props);
  const {name, value} = field;

  durationPickerProps.ref = ref;
  durationPickerProps.options = options;
  durationPickerProps.name = name;
  durationPickerProps.duration = value;

  durationPickerProps.onChange = value => {
    const initialValue = _.get(form.initialValues, name);
    const unchanged = areDurationsEqual(initialValue, value);

    if (unchanged) {
      form.setFieldValue(name, initialValue);
    } else {
      form.setFieldValue(name, value);
    }

    if (!form.touched[name] && !unchanged) {
      form.setFieldTouched(name, true);
    }
  };

  return <DurationPicker {...durationPickerProps} />;
}

// Temporary Field wrapper until we get hooks in Formik 2.0
export default forwardRefFactory(props => <Field {...props} component={FormDurationPicker} />, {
  hoistSource: FormDurationPicker,
});
