/**
 * Copyright 2016 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import {getMethodParameterizedPath, edge} from 'api/apiUtils';
import type {Label} from 'illumio';

export const namesByType = {
  role: edge ? intl('Common.Group') : intl('Common.Role'),
  loc: intl('Common.Location'),
  app: intl('Common.Application'),
  env: intl('Common.Environment'),
  allWorkloads: intl('Workloads.All'),
  ipList: intl('Common.IPRange'),
  virtualService: intl('Common.VirtualService'),
  userGroup: intl('Common.UserGroup'),
  virtualServer: intl('Common.VirtualServer'),
  scope: intl('Common.Scope'),
};

export const getLabelsObject = (labels: Label[] = []): Record<string, Label> =>
  labels.reduce((result: Record<string, Label>, label) => {
    if (label.key) {
      result[label.key] = label;
    }

    return result;
  }, {});

export const typesName = {
  role: edge ? intl('Common.Group') : intl('Common.Role'),
  loc: intl('Common.Location'),
  app: intl('Common.Application'),
  env: intl('Common.Environment'),
};

// Given a label key, return label type name string
export const getLabelTypeName = (key: string): string | undefined => typesName[key as keyof typeof typesName];

export const getLabelHref = (orgId: string, labelId: string): string =>
  getMethodParameterizedPath({
    orgId,
    params: {label_id: labelId},
    method: 'labels.get_instance',
  });

// // Used to convert object of type:href mapping into array of labels with only href
// export const buildLabelHrefArray = (labelTypeObject) => {
//   const labels = [];

//   Object.keys(labelTypeObject).forEach(key => {
//     if (labelTypeObject[key] && labelTypeObject[key].type !== 'all') {
//       labels.push({href: labelTypeObject[key].href ? labelTypeObject[key].href : labelTypeObject[key]});
//     }
//   });

//   return labels;
// };

// Data Structure used for SingleItemSelect
export const getLabelValues = (labelObject: Record<string, Label>): Record<string, Label[]> =>
  Object.keys(labelObject).reduce((label: Record<string, Label[]>, cur) => {
    if (!cur) {
      return label;
    }

    label[cur] = [labelObject[cur]];

    return label;
  }, {});

// Role, App, Loc and Env labels
export const labelFields = ['role', 'app', 'loc', 'env'];
