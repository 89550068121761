/**
 * Copyright 2016 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import cx from 'classnames';
import {tidUtils} from 'utils';
import SizeWatcher from 'react-size-watcher';
import {Fragment, createElement} from 'react';
import {mixThemeWithProps, type ThemeProps} from '@css-modules-theme/react';
import {unwrapChildren} from 'utils/react';
import {classSplitter} from 'utils/general';
import styles from './AttributeList.css';
import stylesUtils from 'utils.css';
import type {MergeExclusive} from 'type-fest';
import type {BreakPoint, BreakPoints, SizeWatcherProps} from 'react-size-watcher';
import type {ReactStrictNode} from 'utils/types';

const defaultRowTid = 'comp-attributerow';
const minmaxRegex = /^minmax\((.+),(.+)\)$/;

const isAreaExpandable = (...sizes: (string | boolean)[]) =>
  sizes.some(size => {
    if (typeof size === 'string') {
      let sizeString = size;

      if (minmaxRegex.test(sizeString)) {
        // Take max value from minmax
        sizeString = sizeString.match(minmaxRegex)![2].trim();
      }

      // Area is considered to be expandable if size contains auto or a fraction (fr)
      return sizeString.includes('auto') || sizeString.includes('fr');
    }

    return false;
  });

type UnknownObject = Record<string, unknown>;

export interface AttributeRenderChild {
  tid?: string;

  /** Custom props for the row */
  props?: UnknownObject;

  /** Title if element is a header */
  title?: string;
  divider?: boolean;

  /** In case of regular item [key: value/secondary] */
  key?: ReactStrictNode;

  /** Custom props for the key node */
  keyProps?: UnknownObject;
  value?: ReactStrictNode;

  /** Custom props for the value node */
  valueProps?: UnknownObject;

  valueGap?: string;

  secondary?: ReactStrictNode;

  /** Custom props for the secondary node */
  secondaryProps?: UnknownObject;

  /** Use as max indicator a hint can span */
  hintSpanMax?: number;

  /** Hint node */
  hint?: ReactStrictNode;

  icon?: ReactStrictNode;

  /**
   * Any renderable content that will take up whole row
   * Useful when you want several aligned AttributeList sections, but they must be split by some another content
   */
  content?: ReactStrictNode;
  contentGap?: string;
}

type Hint = {span: number; spanning?: boolean} & Partial<Pick<AttributeRenderChild, 'hint' | 'hintSpanMax'>>;

/** All extra props will be passed down to rendered SizeWatcher as is. */
export type AttributeListProps = {
  keyColumnWidth?: string;
  valueColumnWidth?: string;
  iconColumnWidth?: string; // info card icon
  hintColumnWidth?: string;

  keysGap?: string;
  hintsGap?: string;
  valuesGap?: string;
  contentsGap?: string;

  children: (false | AttributeRenderChild)[];

  /** Do not add left padding to keys */
  noKeyPadding?: boolean;
} & MergeExclusive<
  {breakpoints?: BreakPoints},
  {
    // FIXME: remove this line if eslint(lines-around-comment is fixed on TS interface)
    /**
     * If you just want to override certain parts of the default breakpoints
     * without changing breakpoints configuration (number of breakpoints),
     * you can specify those objects in the following props (all are optional),
     * and they will be _merged_ into the corresponding breakpoint.
     * Look at https://github.com/klimashkin/react-size-watcher/blob/master/src/SizeWatcher.js#L12 for breakpoint props
     *
     * Example:
     * sizeM: {maxWidth: 1000} - To just override breakpoint width
     * sizeS: {maxWidth: 400, props: {'data-tid': 'test'}} - To override breakpoint width and assign data-tid
     *                                                       property to container when breakpoint matches,
     *                                                       whilst default className will remain
     * sizeS: {minHeight: 500, maxHeight: 500} - Don't change default, but add height boundaries
     */
    sizeL?: BreakPoint;
    sizeM?: BreakPoint;
    sizeS?: BreakPoint;
    sizeXS?: BreakPoint;
  }
> &
  ThemeProps &
  Omit<SizeWatcherProps, 'children' | 'breakpoints'>;

export default function AttributeList(props: AttributeListProps): JSX.Element {
  const {
    noKeyPadding = false,
    theme,
    keyColumnWidth = 'fit-content(25%)',
    valueColumnWidth = 'minmax(auto, 1100px)',
    hintColumnWidth = 'auto',
    iconColumnWidth = 'max-content',
    sizeL,
    sizeM,
    sizeS,
    sizeXS,
    children,
    keysGap,
    valuesGap,
    hintsGap,
    contentsGap,
    ...elementProps
  } = mixThemeWithProps(styles, props);

  const childrenWrapped = unwrapChildren(children);
  const hintsExist = childrenWrapped.some(child => Boolean(child.hint));
  const iconsExist = childrenWrapped.some(child => Boolean(child.icon));
  // Add empty column that will expand AttributeList if key/value/hint columns have fixed upper size
  const addExpanderColumn = !isAreaExpandable(
    keyColumnWidth,
    valueColumnWidth,
    iconColumnWidth,
    hintsExist && hintColumnWidth,
  );
  const colSpan = 2 + Number(iconsExist) + Number(hintsExist) + Number(addExpanderColumn);

  elementProps['data-tid'] = 'comp-attributelist';

  const breakpoints = elementProps.breakpoints || [
    _.merge({props: {className: theme.sizeL}}, sizeL),
    _.merge({maxWidth: 1399, props: {className: theme.sizeM}}, sizeM),
    _.merge({maxWidth: 960, props: {className: theme.sizeS}}, sizeS),
    _.merge({maxWidth: hintsExist ? 700 : 480, props: {className: theme.sizeXS}, data: {sizeXS: true}}, sizeXS),
  ];

  // we have to do array length checking in runtime
  if (__DEV__ && breakpoints.length === 0) {
    console.error("Prop 'breakpoints' supplied to 'AttributeList' should be a non-empty array");
  }

  elementProps.style = {
    gridTemplateColumns: [
      keyColumnWidth,
      valueColumnWidth,
      iconsExist && iconColumnWidth,
      hintsExist && hintColumnWidth,
      addExpanderColumn && 'auto',
    ]
      .filter(size => size)
      .join(' '),
  };

  return (
    <SizeWatcher {...elementProps} breakpoints={breakpoints}>
      {({data: {sizeXS = false} = {}}: {data: {sizeXS?: boolean}}) => {
        const spanColumnsStyle = {gridColumn: `1 / span ${colSpan}`};
        let hintsSet: Partial<Hint>[] = [];

        if (hintsExist) {
          // A helper variable to keep track of the beginning where the hint will start
          let currentHint: Hint | null = null;

          hintsSet = childrenWrapped.map(child => {
            if (!child.title && !child.divider && !child.content) {
              if (child.hint) {
                // span: 1 - is default to span one row
                currentHint = {hint: child.hint, span: 1, hintSpanMax: child.hintSpanMax};

                return currentHint;
              }

              if (currentHint) {
                // Increment the span to indicate the amount rows to span from currentHint
                currentHint.span++;

                // Check to determine if there is a hintSpanMax
                if (currentHint.span === currentHint.hintSpanMax) {
                  // Reset currentHint
                  currentHint = null;
                }

                // Setting: {spanning: true} is used to indicate the specific row is
                // being spanned to avoid adding extra DOM element e.g. <div> on a span row
                return {spanning: true};
              }
            } else if (currentHint) {
              // Reset currentHint to indicate a new start of the current item to check for hints
              currentHint = null;
            }

            return {};
          });
        }

        const rows = childrenWrapped.reduce((result: ReactStrictNode[], item, itemIndex) => {
          if (item) {
            if (item.title) {
              const {title, props} = item;
              const content = (
                <div
                  className={theme.title}
                  style={spanColumnsStyle}
                  data-tid={`comp-sectiontitle-${title.toLowerCase()}`}
                  {...props}
                >
                  {title}
                </div>
              );

              result.push(content);
            } else if (item.divider) {
              const content = <div className={theme.divider} style={spanColumnsStyle} data-tid="comp-sectiondivider" />;

              result.push(content);
            } else if (item.content) {
              const {contentGap = contentsGap} = item;
              const content = (
                <div
                  className={cx(theme.content, classSplitter(stylesUtils, contentGap))}
                  style={spanColumnsStyle}
                  {...item.props}
                >
                  {item.content}
                </div>
              );

              result.push(content);
            } else {
              const {
                props,
                key,
                keyProps,
                value,
                valueProps,
                valueGap = valuesGap,
                secondary,
                secondaryProps,
                tid,
                icon,
              } = item;
              const hintMapping = hintsSet[itemIndex];

              const valueGapClass = classSplitter(stylesUtils, valueGap);
              let renderingValue = value;

              // If value gap is specified, and its only value is a simple text, wrap it into span to make it a correct flex item
              if (value && valueGapClass) {
                const unwrappedValue = unwrapChildren(value);

                if (unwrappedValue.length === 1 && typeof unwrappedValue[0] === 'string') {
                  renderingValue = <span>{unwrappedValue[0]}</span>;
                }
              }

              result.push(
                <div
                  className={cx(theme.row, {[stylesUtils.gapXSmall]: sizeXS})}
                  data-tid={tidUtils.getTid(defaultRowTid, tid)}
                  {...props}
                >
                  <div
                    className={cx(theme.key, {[theme.keyWithPadding]: !noKeyPadding})}
                    data-tid="comp-attributerow-label"
                    {...keyProps}
                  >
                    {key}
                  </div>
                  <div className={cx(theme.value, valueGapClass)} data-tid="comp-attributerow-value" {...valueProps}>
                    {renderingValue}
                    {secondary ? (
                      <div className={theme.secondary} {...secondaryProps}>
                        {secondary}
                      </div>
                    ) : null}
                  </div>
                  {iconsExist && <div className={theme.icon}>{icon}</div>}
                  {hintsExist &&
                    (hintMapping.hint ? (
                      <div
                        className={theme[iconsExist ? 'hintWithIcon' : 'hint']}
                        style={{gridArea: `span ${hintMapping.span}`}}
                        data-tid="comp-attributerow-hint"
                      >
                        {hintMapping.hint}
                      </div>
                    ) : hintMapping.spanning ? null : (
                      <div className={theme[iconsExist ? 'hintWithIcon' : 'hint']} />
                    ))}
                  {addExpanderColumn && <div />}
                </div>,
              );
            }
          }

          return result;
        }, []);

        // Spread rows over div to avoid necessity of react keys
        return createElement(Fragment, {}, ...rows);
      }}
    </SizeWatcher>
  );
}
