/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import {Component} from 'react';
import {connect} from 'react-redux';
import {reactUtils, hrefUtils} from 'utils';
import {AppContext} from 'containers/App/AppUtils';
import {resourceType} from './RulesetListConfig';
import {fetchRulesetList, removeRuleset, updateRulesetStatus} from './RulesetListSaga';
import {fetchPending} from 'containers/Provisioning/ProvisioningSaga';
import {getRulesetsPage} from './RulesetListState';
import RulesetReducers from 'containers/Ruleset/RulesetState';
import ReportsReducers from 'containers/Reports/ReportsState';
import LabelReducers from '../../Label/LabelState';
import {
  Button,
  ButtonRefresh,
  Grid,
  Link,
  Modal,
  ModalMachineAuto,
  Notifications,
  ToolBar,
  ToolGroup,
  TypedMessages,
} from 'components';
import {HeaderProps, ReportButtons} from 'containers';
import {ComboSelect} from 'containers/Selectors';
import styles from './RulesetList.css';
import stylesGridUtils from 'components/Grid/GridUtils.css';
import ProvisionButtons from 'containers/Provisioning/Provision/ProvisionButtons';
import {getMaxPageNotificationList} from 'components/Grid/GridUtils';
import {isAPIAvailable, edge} from 'api/apiUtils';

const scopeFilterKeys = ['app', 'env', 'loc'];

const buttonsTheme = {textIsHideable: styles['button-textIsHideable']};
const removeRowHighLight = {className: stylesGridUtils.rowToRemove};
const revertRowHighLight = {className: stylesGridUtils.rowToRevert};
const provisionRowHighLight = {className: stylesGridUtils.rowToProvision};
const disableRowHighLight = {className: stylesGridUtils.rowToRevert};
const enableRowHighLight = {className: styles.rowToEnable};

// Statically create add item link for grid to avoid its rerender on each page render (Grid is PureComponent)
const addRulesetLink = <Link to="rulesets.create">{intl('Rulesets.AddNew')}</Link>;

const getEmptyState = () => ({
  extraPropsKeyMap: new Map(),
  selectedKeySet: new Set(),
  selectedKeySetToRemove: new Set(),
  selectedObjectsToProvision: {rule_sets: []},
  selectedKeySetToDisable: new Set(),
  selectedKeySetToEnable: new Set(),
  runningEnable: false,
  runningDisable: false,
  apiTask: null, // {running: <boolean>, success: [], errors: [], remove: <boolean>}
  remove: false,
});

@connect(getRulesetsPage)
export default class RulesetList extends Component {
  static contextType = AppContext;
  static prefetch = fetchRulesetList;
  static reducers = [ReportsReducers, LabelReducers, RulesetReducers];

  constructor(props) {
    super(props);

    this.state = getEmptyState();

    this.handleClick = this.handleClick.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleRefresh = this.handleRefresh.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
    this.handleRemoveEnter = this.handleRemoveEnter.bind(this);
    this.handleRemoveLeave = this.handleRemoveLeave.bind(this);
    this.handleEnable = this.handleEnable.bind(this);
    this.handleEnableEnter = this.handleEnableEnter.bind(this);
    this.handleEnableLeave = this.handleEnableLeave.bind(this);
    this.handleDisable = this.handleDisable.bind(this);
    this.handleDisableEnter = this.handleDisableEnter.bind(this);
    this.handleDisableLeave = this.handleDisableLeave.bind(this);
    this.handleProvisionButtonsHover = this.handleProvisionButtonsHover.bind(this);
    this.handleProvisionDone = this.handleProvisionDone.bind(this);
    this.handleErrorAlertClose = this.handleErrorAlertClose.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.grid.rows !== prevState.rows) {
      const nextState = {rows: nextProps.grid.rows};

      if (prevState.selectedKeySet.size > 0) {
        // If page or sorting have changed, find selected items that remain on current page
        nextState.selectedKeySet = new Set();
        nextState.selectedKeySetToRemove = new Set();
        nextState.selectedObjectsToProvision = {rule_sets: []};
        nextState.selectedKeySetToEnable = new Set();
        nextState.selectedKeySetToDisable = new Set();

        for (const row of nextProps.grid.rows) {
          if (row.selectable && prevState.selectedKeySet.has(row.key)) {
            nextState.selectedKeySet.add(row.key);

            if (row.writable) {
              nextState.selectedKeySetToRemove.add(row.key);

              if (row.data.enabled) {
                nextState.selectedKeySetToDisable.add(row.key);
              } else {
                nextState.selectedKeySetToEnable.add(row.key);
              }
            }

            if (row.provisionable) {
              nextState.selectedObjectsToProvision = {
                rule_sets: nextState.selectedObjectsToProvision.rule_sets.concat({href: row.key}),
              };
            }
          }
        }
      }

      if (prevState.runningEnable) {
        nextState.runningEnable = false;
      }

      if (prevState.runningDisable) {
        nextState.runningDisable = false;
      }

      return nextState;
    }

    return null;
  }

  handleClick(evt, row) {
    this.context.navigate({evt, to: 'rulesets.item', params: {id: hrefUtils.getId(row.key), pversion: 'draft'}});
  }

  handleErrorAlertClose() {
    this.setState({apiTask: null, extraPropsKeyMap: new Map()});
  }

  handleSelect({affectedRows, selecting}) {
    this.setState(state => {
      const selectedKeySet = new Set(state.selectedKeySet);
      const selectedKeySetToRemove = new Set(state.selectedKeySetToRemove);
      let selectedObjectsToProvision = {...state.selectedObjectsToProvision};
      const selectedKeySetToEnable = new Set(state.selectedKeySetToEnable);
      const selectedKeySetToDisable = new Set(state.selectedKeySetToDisable);

      for (const row of affectedRows) {
        selectedKeySet[selecting ? 'add' : 'delete'](row.key);

        if (selecting) {
          if (row.writable) {
            selectedKeySetToRemove.add(row.key);

            if (row.data.enabled) {
              selectedKeySetToDisable.add(row.key);
            } else {
              selectedKeySetToEnable.add(row.key);
            }
          }

          if (row.provisionable) {
            selectedObjectsToProvision = {
              rule_sets: selectedObjectsToProvision.rule_sets.concat({href: row.key}),
            };
          }
        } else {
          selectedKeySetToRemove.delete(row.key);
          selectedKeySetToDisable.delete(row.key);
          selectedKeySetToEnable.delete(row.key);
          selectedObjectsToProvision = {
            rule_sets: selectedObjectsToProvision.rule_sets.filter(object => object.href !== row.key),
          };
        }
      }

      return {
        selectedKeySet,
        selectedKeySetToRemove,
        selectedObjectsToProvision,
        selectedKeySetToDisable,
        selectedKeySetToEnable,
      };
    });
  }

  handleRefresh() {
    // Return promise that will wait for all, so Pagination component can publish event for QA
    return Promise.all([
      // Refetch the list, cancelable on page leave
      this.context.fetcher.fork(fetchRulesetList.refetch),
      // Refetch provision counter, doesn't depend on page leave
      this.context.fetcher.spawn(fetchPending),
    ]);
  }

  handleFilterChange(selection) {
    const prevScopes = this.props.scope && this.props.scope.valid.scope;
    const roleScope = prevScopes && prevScopes.find(scope => scope.key === 'role');

    const scopeItems = selection.filter(item => scopeFilterKeys.includes(item.categoryKey));
    const filterItems = selection.filter(item => !scopeFilterKeys.includes(item.categoryKey));

    const scope = scopeItems.reduce((result, {key, href}) => result.concat({key, href}), roleScope ? [roleScope] : []);
    const filter = filterItems.reduce((result, {categoryKey, value}) => {
      result[categoryKey] = [value];

      return result;
    }, {});

    this.context.navigate({
      params: {
        scope: scope.length ? {scope} : undefined,
        [this.props.grid.settings.id]: {...this.props.grid.params, filter, page: null},
      },
    });
  }

  handleProvisionButtonsHover(evt, type, action) {
    this.setState(state => {
      if (type === 'enter' && state.selectedObjectsToProvision.rule_sets.length) {
        if (action === 'provision') {
          return {
            extraPropsKeyMap: new Map(
              state.selectedObjectsToProvision.rule_sets.map(object => [object.href, provisionRowHighLight]),
            ),
          };
        }

        if (action === 'revert') {
          return {
            extraPropsKeyMap: new Map(
              state.selectedObjectsToProvision.rule_sets.map(object => [object.href, revertRowHighLight]),
            ),
          };
        }
      }

      if (type === 'leave' && state.extraPropsKeyMap.size) {
        return {extraPropsKeyMap: new Map()};
      }

      // Return null to prevent updating state
      return null;
    });
  }

  handleProvisionDone() {
    // Reset selection
    this.setState(getEmptyState());
    this.handleRefresh();
  }

  async handleEnable() {
    await reactUtils.setStateAsync({runningEnable: true, extraPropsKeyMap: new Map()}, this);

    const {success, errors} = await this.context.fetcher.spawn(updateRulesetStatus, {
      enabled: true,
      hrefs: [...this.state.selectedKeySetToEnable],
    });

    if (errors.size) {
      // If we have errors, show alert with errors
      this.setState(({apiTask}) => ({apiTask: {...apiTask, success, errors}, runningEnable: false}));
    } else {
      this.setState(getEmptyState());
      this.handleRefresh();
    }
  }

  handleEnableEnter() {
    if (this.state.selectedKeySetToEnable.size) {
      this.setState(state => ({
        extraPropsKeyMap: new Map(Array.from(state.selectedKeySetToEnable, key => [key, enableRowHighLight])),
      }));
    }
  }

  handleEnableLeave() {
    if (this.state.extraPropsKeyMap.size) {
      this.setState({extraPropsKeyMap: new Map()});
    }
  }

  async handleDisable() {
    await reactUtils.setStateAsync({runningDisable: true, extraPropsKeyMap: new Map()}, this);

    const {success, errors} = await this.context.fetcher.spawn(updateRulesetStatus, {
      enabled: false,
      hrefs: [...this.state.selectedKeySetToDisable],
    });

    if (errors.size) {
      // If we have errors, show alert with errors
      this.setState(({apiTask}) => ({apiTask: {...apiTask, success, errors}, runningDisable: false}));
    } else {
      this.setState(getEmptyState());
      this.handleRefresh();
    }
  }

  handleDisableEnter() {
    if (this.state.selectedKeySetToDisable.size) {
      this.setState(state => ({
        extraPropsKeyMap: new Map(Array.from(state.selectedKeySetToDisable, key => [key, disableRowHighLight])),
      }));
    }
  }

  handleDisableLeave() {
    if (this.state.extraPropsKeyMap.size) {
      this.setState({extraPropsKeyMap: new Map()});
    }
  }

  handleRemove() {
    this.setState(({remove}) => ({remove: !remove}));
  }

  handleRemoveEnter() {
    if (this.state.selectedKeySetToRemove.size) {
      this.setState(state => ({
        extraPropsKeyMap: new Map(Array.from(state.selectedKeySetToRemove, key => [key, removeRowHighLight])),
      }));
    }
  }

  handleRemoveLeave() {
    // Drop highlight if user moved out cursor and haven't opened remove dialog
    if (!this.state.remove && this.state.extraPropsKeyMap.size) {
      this.setState({extraPropsKeyMap: new Map()});
    }
  }

  renderErrorAlert() {
    const {
      props: {
        grid: {rowsMap},
      },
      state: {selectedKeySetToEnable, selectedKeySetToDisable, apiTask},
    } = this;

    const renderItem = key => {
      const {cells} = rowsMap.get(key);

      return <li key={key}>{cells.get('name').value}</li>;
    };

    let sections = [];

    if (apiTask.errors && apiTask.errors.size) {
      sections = Array.from(apiTask.errors.entries(), ([message, hrefs]) => ({
        icon: 'error',
        content: (
          <div>
            {message}:<ol>{hrefs.map(renderItem)}</ol>
          </div>
        ),
      }));

      if (apiTask.success.length) {
        sections.push({
          icon: 'success',
          content: (
            <div>
              {intl('Rulesets.UpdateSuccess', {count: apiTask.success.length})}:
              <ol>{apiTask.success.map(renderItem)}</ol>
            </div>
          ),
        });
      }
    }

    const title = intl('Rulesets.UpdateResultTitle', {
      count: selectedKeySetToEnable.size ? selectedKeySetToEnable.size : selectedKeySetToDisable.size,
    });

    return (
      <Modal.Alert stretch title={title} onClose={this.handleErrorAlertClose}>
        <TypedMessages>{sections}</TypedMessages>
      </Modal.Alert>
    );
  }

  renderRemoveModal() {
    const {
      props: {
        grid: {rowsMap},
      },
      state: {selectedKeySetToRemove},
    } = this;

    return (
      <ModalMachineAuto
        batch
        rowsMap={rowsMap}
        onClose={this.handleRemove}
        onDone={this.handleRefresh}
        saga={removeRuleset}
        hrefs={[...selectedKeySetToRemove]}
        listItem={['name']}
      >
        {{
          title: ({selectedHrefs}) => intl('Rulesets.Delete', {count: selectedHrefs.length}),
          confirmMessage: intl('Rulesets.DeleteConfirm'),
          error: {
            title: intl('Rulesets.RemoveResultTitle', {count: selectedKeySetToRemove.size}),
          },
        }}
      </ModalMachineAuto>
    );
  }

  render() {
    const {
      props: {grid, count, selector, userIsReadOnlyOrProvisioner, userIsScoped},
      state: {
        rows,
        extraPropsKeyMap,
        selectedKeySet,
        selectedObjectsToProvision,
        selectedKeySetToRemove,
        remove,
        apiTask,
        selectedKeySetToDisable,
        selectedKeySetToEnable,
        runningDisable,
        runningEnable,
      },
    } = this;

    const isAddEnabled = isAPIAvailable('rule_sets.create');

    const notifications = getMaxPageNotificationList({page: grid.page, capacity: grid.capacity, count});

    if (userIsScoped) {
      notifications.push({
        type: 'instruction',
        message: intl('Rulesets.ScopedUserRuleset', {pageName: intl('Common.RuleSearch')}),
      });
    }

    return (
      <>
        <HeaderProps title={intl('Common.Rulesets')} />
        {notifications.length > 0 && <Notifications sidebar>{notifications}</Notifications>}

        <ToolBar>
          <ToolGroup>
            <Button.Link
              icon="add"
              text={intl('Common.Add')}
              textIsHideable
              link="rulesets.create"
              tid="add"
              theme={buttonsTheme}
              disabled={!isAddEnabled}
            />
            {!edge && (
              <Button.Link
                color="secondary"
                text={intl('PolicyGenerator.StartPolicyGenerator')}
                textIsHideable
                link="policygenerator"
                tid="policygenerator"
                theme={buttonsTheme}
                disabled={userIsReadOnlyOrProvisioner}
              />
            )}
            <ProvisionButtons
              counter={selectedObjectsToProvision.rule_sets.length}
              theme={buttonsTheme}
              onButtonHover={this.handleProvisionButtonsHover}
              onDone={this.handleProvisionDone}
              objectsToProvision={selectedObjectsToProvision}
            />
            <Button
              color="standard"
              icon="remove"
              text={intl('Common.Remove')}
              textIsHideable
              tid="remove"
              theme={buttonsTheme}
              counter={selectedKeySetToRemove.size}
              counterColor="red"
              disabled={!selectedKeySetToRemove.size || !isAPIAvailable('rule_sets.delete')}
              onClick={this.handleRemove}
              onMouseEnter={this.handleRemoveEnter}
              onMouseLeave={this.handleRemoveLeave}
            />
            <Button
              color="standard"
              icon="disabled"
              text={intl('Common.Disable')}
              textIsHideable
              tid="disable"
              theme={buttonsTheme}
              counter={selectedKeySetToDisable.size}
              counterColor="yellow"
              progressCompleteWithCheckmark
              progress={runningDisable}
              disabled={!selectedKeySetToDisable.size || !isAPIAvailable('rule_set.update')}
              onClick={this.handleDisable}
              onMouseEnter={this.handleDisableEnter}
              onMouseLeave={this.handleDisableLeave}
            />
            <Button
              color="standard"
              icon="check"
              text={intl('Common.Enable')}
              textIsHideable
              tid="enable"
              theme={buttonsTheme}
              counter={selectedKeySetToEnable.size}
              progressCompleteWithCheckmark
              progress={runningEnable}
              disabled={!selectedKeySetToEnable.size || !isAPIAvailable('rule_set.update')}
              onClick={this.handleEnable}
              onMouseEnter={this.handleEnableEnter}
              onMouseLeave={this.handleEnableLeave}
            />
          </ToolGroup>
          <ToolGroup>
            <ButtonRefresh color="standard" textIsHideable onRefresh={this.handleRefresh} theme={buttonsTheme} />
            <ReportButtons type="rulesets" disabledGen={!rows.length} theme={buttonsTheme} />
          </ToolGroup>
        </ToolBar>
        <ToolBar>
          <ToolGroup expand tid="page-filter">
            <ComboSelect
              objects={selector.objects}
              placeholder={intl('Common.FilterView')}
              initialItems={selector.initialItems}
              activeCategoryKey="name"
              categories={selector.categories}
              partials={selector.partials}
              facets={selector.facets}
              statics={selector.statics}
              onSelectionChange={this.handleFilterChange}
              resourceType={resourceType}
            />
          </ToolGroup>
        </ToolBar>

        <Grid
          grid={grid}
          theme={styles}
          count={count}
          selectedKeySet={selectedKeySet}
          dontHighlightSelected={extraPropsKeyMap.size > 0}
          extraPropsKeyMap={extraPropsKeyMap}
          onClick={this.handleClick}
          onSelect={this.handleSelect}
          emptyMessage={selector.initialItems.length ? intl('Rulesets.NoMatchData') : intl('Rulesets.NoData')}
          addItemLink={isAddEnabled && !selector.initialItems.length && addRulesetLink}
        />

        {remove && this.renderRemoveModal()}
        {apiTask && this.renderErrorAlert()}
      </>
    );
  }
}
