/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from 'intl';
import {webStorageUtils, hrefUtils} from 'utils';
import {fillUserInfo} from 'containers/RBAC/RBACUtils';
import {edge} from 'api/apiUtils';

export const diffStatusIcon = {
  create: 'added',
  delete: 'removed',
  update: 'modified',
};
export const outboundOrgPolicyObjType = 'outbound';
export const getTypeAndRoutes = (count = 0) => {
  if (edge) {
    return {
      ip_lists: {
        typeLabel: intl('Common.IPList'),
        tally: intl('Provision.TallyLabel.IPRanges', {count}),
        tallyHistory: intl('Version.Detail.TallyLabel.ChangedIPRanges', {count}),
        route: 'iplists.item.view',
      },
      rule_sets: {
        typeLabel: intl('Common.Group'),
        tally: intl('Common.GroupsByCount', {count}),
        tallyHistory: intl('Version.Detail.TallyLabel.ChangedGroups', {count}),
        route: 'rulesets.item',
      },
      services: {
        typeLabel: intl('Common.Service'),
        tally: intl('Map.Traffic.ServiceCount', {count}),
        tallyHistory: intl('Version.Detail.TallyLabel.ChangedServices', {count}),
        route: 'services.item.view',
      },
      [outboundOrgPolicyObjType]: {
        typeLabel: intl('Policy.Organizational'),
        name: intl('Common.Outbound'),
        tally: intl('Policy.Organizational'),
        tallyHistory: intl('Version.Detail.TallyLabel.ChangedOrganizationalPolicy'),
        route: 'outboundpolicy',
      },
      essential_service_rules: {
        typeLabel: intl('Common.Settings'),
        name: intl('Settings.EssentialServiceRules'),
        tally: intl('Provision.TallyLabel.Settings', {count}),
        tallyHistory: intl('Version.Detail.TallyLabel.ChangedSettings', {count}),
        route: 'essentialservicerules',
        param: {section: 'draft'},
      },
    };
  }

  return {
    virtual_services: {
      typeLabel: intl('Common.VirtualService'),
      tally: intl('Provision.TallyLabel.VirtualServices', {count}),
      tallyHistory: intl('Version.Detail.TallyLabel.ChangedVirtualServices', {count}),
      route: 'virtualServices.item.view',
    },
    enforcement_boundaries: {
      typeLabel: intl('Workloads.EnforcementBoundaries'),
      tally: intl('Provision.TallyLabel.EnforcementBoundaries', {count}),
      tallyHistory: intl('Version.Detail.TallyLabel.ChangedEnforcementBoundaries', {count}),
      route: 'boundaries.item.view',
    },
    firewall_settings: {
      typeLabel: intl('Common.Setting'),
      name: intl('Common.SecuritySettings'),
      tally: intl('Provision.TallyLabel.Settings', {count}),
      tallyHistory: intl('Version.Detail.TallyLabel.ChangedSettings', {count}),
      route: 'securitysettings.section',
      param: {section: 'general'},
    },
    ip_lists: {
      typeLabel: intl('Common.IPList'),
      tally: intl('Provision.TallyLabel.IPLists', {count}),
      tallyHistory: intl('Version.Detail.TallyLabel.ChangedIPLists', {count}),
      route: 'iplists.item.view',
    },
    label_groups: {
      typeLabel: intl('Labels.Group'),
      tally: intl('Provision.TallyLabel.LabelGroups', {count}),
      tallyHistory: intl('Version.Detail.TallyLabel.ChangedLabelGroups', {count}),
      route: 'labelGroups.item',
    },
    rule_sets: {
      typeLabel: intl('Common.Ruleset'),
      tally: intl('Provision.TallyLabel.Rulesets', {count}),
      tallyHistory: intl('Version.Detail.TallyLabel.ChangedRulesets', {count}),
      route: 'rulesets.item.rules',
    },
    services: {
      typeLabel: intl('Common.Service'),
      tally: intl('Map.Traffic.ServiceCount', {count}),
      tallyHistory: intl('Version.Detail.TallyLabel.ChangedServices', {count}),
      route: 'services.item.view',
    },
    secure_connect_gateways: {
      typeLabel: intl('Provision.TallyLabel.SecureConnect', {count: 1}),
      tally: intl('Provision.TallyLabel.SecureConnect', {count}),
      tallyHistory: intl('Version.Detail.TallyLabel.ChangedSecureConnect', {count}),
      route: 'secureGateways.item.view',
    },
    virtual_servers: {
      typeLabel: intl('Common.VirtualServer'),
      tally: intl('VirtualServers.ByCount', {count}),
      tallyHistory: intl('Version.Detail.TallyLabel.ChangedVirtualServers', {count}),
      route: 'virtualServers.item',
    },
    essential_service_rules: {
      typeLabel: intl('Common.Setting'),
      name: intl('Settings.EssentialServiceRules'),
      tally: intl('Provision.TallyLabel.Settings', {count}),
      tallyHistory: intl('Version.Detail.TallyLabel.ChangedSettings', {count}),
      route: 'essentialservicerules',
      param: {section: 'draft'},
    },
    settings: {
      typeLabel: intl('Common.Setting'),
      tally: intl('Provision.TallyLabel.Settings', {count}),
      tallyHistory: intl('Version.Detail.TallyLabel.ChangedSettings', {count}),
    },
  };
};

const getKeyFromType = (type, count) => Object.keys(getTypeAndRoutes(count)).includes(type);

export const getTypeAndRoute = (type, count) => getKeyFromType(type, count) && getTypeAndRoutes(count)[type];

export const getInternalType = typeLabel => {
  if (typeLabel) {
    for (const [key, {typeLabel: value = null}] of Object.entries(getTypeAndRoutes())) {
      if (value === typeLabel) {
        return key;
      }
    }
  }

  return null;
};

export const getProvisionSelection = () => {
  const storedGeneralSelection = _.find(
    webStorageUtils.getSessionItem('GeneralSelection', {remove: true}) || [],
    selection => selection.key === 'provision',
  );

  return storedGeneralSelection ? storedGeneralSelection.value : {};
};

export const getProvisionMenuCounts = (pendingObjects = {}, outboundAllowRulesetId) =>
  Object.entries(pendingObjects).reduce((result, [objType, items]) => {
    // we need to collapse essential service rules & firewall settings in to a single category: settings
    // both are arrays with 1 object
    if (objType === 'essential_service_rules' || objType === 'firewall_settings') {
      result.settings = (result.settings ?? 0) + 1;
    } else {
      result[objType] = items.filter(item => item.caps).length;
    }

    if (edge) {
      // In Edge allow ruleset and enforcement_boundaries pending objects are aggregated into a single outbound policy pending object
      // Check and update the result to remove 'enforcement_boundaries' and remove allow ruleset count from total 'rule_sets' count
      if (objType === 'enforcement_boundaries' && items.some(item => item.caps)) {
        // Set outbound count to 1 and remove 'enforcement_boundaries' count that was added previously
        result.outbound = 1;
        delete result[objType];
      }

      if (objType === 'rule_sets') {
        // Check if default ruleset is pending and is added in the total rule_sets count,
        // then subtract rulesets count by one and set outbound count
        if (items.some(({href, caps}) => caps && hrefUtils.getId(href) === outboundAllowRulesetId)) {
          result.outbound = 1;
          result[objType] = result[objType] - 1;

          if (result[objType] === 0) {
            // If rule_sets count only included outbound allow ruleset and the result count is 0 after subtracting,
            // then remove rule_sets from counts object as no rule_sets other than allow ruleset are pending
            delete result[objType];
          }
        }
      }
    }

    return result;
  }, {});

export const getProvisionCounts = (items = [], outboundAllowRulesetId) => {
  const counts = {
    rule_sets: 0,
    services: 0,
    ip_lists: 0,
    settings: 0,
    ...(edge
      ? {
          outbound: 0,
        }
      : {
          virtual_services: 0,
          label_groups: 0,
          virtual_servers: 0,
          secure_connect_gateways: 0,
          enforcement_boundaries: 0,
        }),
  };

  _.each(items, item => {
    if (edge) {
      switch (item.data?.type || item.object_type) {
        case 'rule_sets':
          if (hrefUtils.getId(item.data?.href || item.href) === outboundAllowRulesetId) {
            // Set outbound count to 1 for allow ruleset otherwise increment rule_sets count
            counts.outbound = 1;
          } else {
            counts.rule_sets++;
          }

          break;
        case 'outbound':
          counts.outbound = 1;
          break;
        case 'services':
          counts.services++;
          break;
        case 'ip_lists':
          counts.ip_lists++;
          break;
        case 'enforcement_boundaries':
          // Set outbound count to 1 for enforcement boundaries rules as these are aggregated into one outbound policy row
          counts.outbound = 1;
          break;
        case 'essential_service_rules':
          counts.settings++;
          break;
        // no default
      }
    } else {
      switch (item.data?.type || item.object_type) {
        case 'virtual_services':
          counts.virtual_services++;
          break;
        case 'rule_sets':
        case 'rulesets':
          counts.rule_sets++;
          break;
        case 'services':
          counts.services++;
          break;
        case 'ip_lists':
          counts.ip_lists++;
          break;
        case 'label_groups':
          counts.label_groups++;
          break;
        case 'virtual_servers':
          counts.virtual_servers++;
          break;
        case 'firewall_settings':
        case 'essential_service_rules':
          counts.settings++;
          break;
        case 'secure_connect_gateways':
          counts.secure_connect_gateways++;
          break;
        case 'enforcement_boundaries':
          counts.enforcement_boundaries++;
          break;
        // no default
      }
    }
  });

  return counts;
};

export const formatCountsForTally = (counts = {}, {versionLabel = false} = {}) =>
  Object.keys(counts)
    .filter(item => counts[item] > 0)
    .map(
      item =>
        counts[item] && {
          children: versionLabel
            ? getTypeAndRoute(item, counts[item]).tallyHistory
            : getTypeAndRoute(item, counts[item]).tally,
          count: counts[item],
        },
    );

export const calculatedItemTypeStaticValues = (counts = {}) => ({
  type: Object.keys(counts).map(item => getTypeAndRoute(item).typeLabel),
});

export const getProvisionCountsTotal = (counts = {}) => Object.values(counts).reduce((prev, cur) => prev + cur, 0);

export const fillPolicyObjUserInfo = (versions, usersMap) =>
  Object.entries(versions).reduce(
    (result, [pversion, data]) => ({
      ...result,
      [pversion]: data && {
        ...data,
        createdBy: fillUserInfo(usersMap, data.created_by),
        updatedBy: fillUserInfo(usersMap, data.updated_by),
      },
    }),
    {},
  );

export const getPolicyVersions = (detail = {}, pversion) => {
  // When isOldVersion is true then the pversion is numeric
  const isOldVersion = Number.isInteger(Number(pversion));
  const {active, draft, oldPversionObj, oldPrevPversionObj} = detail;

  // Scenario to describe oldPversionObj.
  // When viewing page: https://devtest201:8443/#/iplists/136/941 an API call to
  // 'https://devtest201:8443/api/v2/orgs/1/sec_policy/941/ip_lists/136' is invoked to get 'oldPversionObj'.

  //  941 - is the pversion on uri: https://devtest201:8443/api/v2/orgs/1/sec_policy/941/ip_lists/136
  //  136 - is the ip_lists id on uri: https://devtest201:8443/api/v2/orgs/1/sec_policy/941/ip_lists/136
  // When 'oldPversionObj', !draft , and !active are undefined then this pversion is DELETED (aka deleted policy object).
  // When 'oldPversionObj' is undefined, draft and active both exist then this pversion has been RESTORED (aka restored policy object).

  const pversionObjIsDeleted = !oldPversionObj && Boolean(oldPrevPversionObj);

  const versions = {draft, active};

  if (pversionObjIsDeleted) {
    // show content of last valid version for deleted policy object and restored policy object
    versions.pversionObj = oldPrevPversionObj;
  } else if (isOldVersion) {
    versions.pversionObj = oldPversionObj;
    versions.prevPversionObj = oldPrevPversionObj;
  } else if (pversion === 'draft') {
    versions.pversionObj = draft;
    versions.prevPversionObj = active;
  } else if (draft && !draft.update_type) {
    versions.pversionObj = draft;
  } else {
    versions.pversionObj = active;
  }

  return {versions, isOldVersion, pversionObjIsDeleted};
};

export const getModifiedDateAndBy = (items = []) => {
  const values = [...items];

  if (values.length === 0) {
    return;
  }

  let {updated_at, updated_by} = values.shift() ?? {};

  values.forEach(({updated_at: updatedAt, updated_by: updatedBy} = {}) => {
    if (new Date(updated_at).getTime() < new Date(updatedAt).getTime()) {
      updated_at = updatedAt;
      updated_by = updatedBy;
    }
  });

  return {updated_at, updated_by};
};

export const aggregateOutboundPolicy = ({ruleSets, enforcementBoundaries, outboundAllowRulesetHref, usersMap}) => {
  let outboundRulesetModified = false;
  const changeSubset = {};
  const pendingOutboundRuleset = ruleSets?.find(
    ruleset => hrefUtils.getId(ruleset.href) === hrefUtils.getId(outboundAllowRulesetHref),
  );

  if (Array.isArray(enforcementBoundaries) && enforcementBoundaries.length > 0) {
    const provisionableRules = enforcementBoundaries.filter(({caps}) => caps?.includes('provision'));

    if (provisionableRules.length > 0) {
      // enforcement rules can be read only, add it to change subset only if user has provision capabilities
      changeSubset.enforcement_boundaries = provisionableRules.map(({href}) => ({href}));
    }

    outboundRulesetModified = true;
  }

  if (pendingOutboundRuleset) {
    if (pendingOutboundRuleset.caps?.includes('provision')) {
      // allow ruleset can be read only, add it to change subset only if user has provision capabilities
      changeSubset.rule_sets = [{href: pendingOutboundRuleset.href}];
    }

    outboundRulesetModified = true;
  }

  if (outboundRulesetModified) {
    const {updated_at, updated_by} = getModifiedDateAndBy([...(enforcementBoundaries ?? []), pendingOutboundRuleset]);

    const selectable =
      pendingOutboundRuleset?.caps?.includes('provision') ||
      enforcementBoundaries?.some(({caps}) => caps?.includes('provision'));

    return {
      key: pendingOutboundRuleset?.href ?? outboundAllowRulesetHref,
      selectable,
      data: {
        ...changeSubset,
        update_type: 'update',
        name: intl('Common.Outbound'),
        caps: selectable ? ['provision'] : [],
        type: outboundOrgPolicyObjType,
        ...getTypeAndRoute(outboundOrgPolicyObjType),
        updated_at,
        updated_by: fillUserInfo(usersMap, updated_by),
      },
    };
  }

  return;
};
