/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from 'intl';
import {createSelector} from 'reselect';
import * as GridUtils from 'components/Grid/GridUtils';
import {hrefUtils, formatUtils} from 'utils';
import {isAPIAvailable} from 'api/apiUtils';
import {isUserScoped} from 'containers/User/UserState';
import {getGridSelector, getUrlScopeValue} from 'components/Grid/GridSelectors';
import {getSecuritySettings} from 'containers/SecuritySettings/SecuritySettingsState';
import {getLabelsHrefMap} from 'containers/Label/List/LabelListState';
import {getScopeLabelByHref} from 'containers/Selectors/SelectorUtils';
import {noLabelsExistList} from '../../Workload/WorkloadUtils';
import {getWorkloadsIgnoreScope} from 'containers/Workload/List/WorkloadListState';
import {getClusters, getLocalFQDN} from 'containers/Health/HealthState';
import {isEdge, getRouteParams} from 'containers/App/AppState';
import {gridSettings, getSelectorSettings} from './VenListConfig';
import {getGroup, getGroupItem} from 'edge/containers/Group/GroupViewState';

export default {
  list(state = [], action) {
    switch (action.type) {
      case 'VEN_GET_LIST':
        return action.data.list;
      default:
        return state;
    }
  },

  count(state = {}, action) {
    switch (action.type) {
      case 'VEN_GET_LIST':
        return action.data.count;
      default:
        return state;
    }
  },

  map(state = {}, action) {
    switch (action.type) {
      case 'VENS_GET_ITEM':
        if (action.item && !_.isEqual(action.item, state[action.item.href])) {
          return {...state, [action.item.href]: action.item};
        }

        return state;
      default:
        return state;
    }
  },
  agentsMap(state = {}, action) {
    switch (action.type) {
      case 'AGENTS_GET_ITEM':
        if (action.item && !_.isEqual(action.item, state[action.item.href])) {
          return {...state, [action.item.href]: action.item};
        }

        return state;
      default:
        return state;
    }
  },
  pending(state = 0, action) {
    switch (action.type) {
      case 'VENS_UPGRADE_PENDING_TRUE':
        return action.data;
      default:
        return state;
    }
  },
  healthError(state = 0, action) {
    switch (action.type) {
      case 'VENS_HEALTH_ERROR':
        return action.data;
      default:
        return state;
    }
  },
  active(state = 0, action) {
    switch (action.type) {
      case 'VENS_STATUS_ACTIVE':
        return action.data;
      default:
        return state;
    }
  },
  warning(state = 0, action) {
    switch (action.type) {
      case 'VENS_HEALTH_WARNING':
        return action.data;
      default:
        return state;
    }
  },
  suspended(state = 0, action) {
    switch (action.type) {
      case 'VENS_STATUS_SUSPENDED':
        return action.data;
      default:
        return state;
    }
  },
};

export const getVens = state => state.ven.list;
export const getVensCount = state => state.ven.count;
export const getVensHrefMap = (state, isAgent) => (isAgent ? state.ven.agentsMap : state.ven.map);
export const getVensUpgradePendingTrue = state => state.ven.pending;
export const getVensHealthError = state => state.ven.healthError;
export const getVensActive = state => state.ven.active;
export const getVensWarning = state => state.ven.warning;
export const getVensSuspended = state => state.ven.suspended;
const getStaticPolicyScopes = createSelector(
  getSecuritySettings,
  securitySettings => securitySettings.draft && securitySettings.draft.static_policy_scopes,
);

const getVensRows = createSelector(getVens, vens =>
  vens.map(item => ({
    key: item.href,
    id: hrefUtils.getId(item.href),
    selectable: Array.isArray(item.caps) && item.caps.length > 0,
    data: {
      ...item,
      labels: GridUtils.getLabelsMap(item.labels),
    },
  })),
);

export const getCalculatedStaticMap = createSelector(
  [getClusters, getStaticPolicyScopes, getWorkloadsIgnoreScope, getSelectorSettings],
  (clusters, staticPolicyScopes, ignoreScope, selectorObject) => {
    const newStaticMap =
      !ignoreScope && clusters.length > 1
        ? {
            active_pce_fqdn: intl('Common.PCE'),
          }
        : {};

    const policyHealthStaticMap =
      staticPolicyScopes && staticPolicyScopes.length
        ? {
            security_policy_received_at: intl('Workloads.PolicyLastReceived'),
            security_policy_update_mode: intl('Policy.UpdateMode'),
          }
        : {};

    return {...newStaticMap, ...selectorObject.staticMap, ...policyHealthStaticMap};
  },
);

export const getCalculatedStaticValues = createSelector(
  [getClusters, getLocalFQDN, getStaticPolicyScopes, getWorkloadsIgnoreScope, getSelectorSettings],
  (clusters, localFQDN, staticPolicyScopes, ignoreScope, selectorObject) => {
    const newStaticValues =
      !ignoreScope && clusters.length > 1
        ? {
            active_pce_fqdn: clusters.reduce((result, cluster) => {
              if (cluster) {
                const label =
                  cluster.fqdn === localFQDN ? intl('Workloads.ThisPCE', {fqdn: cluster.fqdn}) : cluster.fqdn;

                result[label] = cluster.fqdn;
              }

              return result;
            }, {}),
          }
        : {};

    const policyHealthStaticValues =
      staticPolicyScopes && staticPolicyScopes.length
        ? {
            policy_health: {
              ...selectorObject.staticValues.policy_health,
              [intl('Common.Staged')]: [{name: 'security_policy_sync_state', value: 'staged'}],
            },
          }
        : {};

    return {...newStaticValues, ...selectorObject.staticValues, ...policyHealthStaticValues};
  },
);

export const getCalculatedFacetMap = createSelector(getSelectorSettings, selectorObject => selectorObject.facetMap);

export const getCalculatedFilterMap = createSelector(
  [getCalculatedStaticMap, getCalculatedFacetMap, isUserScoped, getSelectorSettings, isEdge, getRouteParams],
  (calculatedStaticMap, calculatedFacetMap, userIsScoped, selectorObject, isEdge, params) => {
    let newScopeMap = selectorObject.scopeMap;

    if (isEdge && params.tab === 'venlist') {
      newScopeMap = _.omit(selectorObject.scopeMap, 'role');
    }

    return {
      ...newScopeMap,
      ...calculatedStaticMap,
      ...(!userIsScoped && !isEdge && {container_clusters: selectorObject.autocompleteMap.container_clusters}),
      ...calculatedFacetMap,
    };
  },
);

export const getCalculatedRouteFilters = createSelector(
  [getCalculatedFilterMap, getSelectorSettings],
  (calculatedFilterMap, selectorObject) => ({
    ...calculatedFilterMap,
    ...selectorObject.customRouteFilters,
  }),
);

export const getScopeItems = createSelector(
  [getUrlScopeValue, getLabelsHrefMap, getCalculatedFilterMap],
  (scope, labelsMap, calculatedFilterMap) => {
    if (scope.isEmpty) {
      return [];
    }

    return scope.valid.scope.reduce((result, {href}) => {
      const label = labelsMap[href] || getScopeLabelByHref(href) || {};

      // Hide label in filter panel for Discovered Workloads
      if (noLabelsExistList.includes(label.value)) {
        return result;
      }

      result.push({
        href,
        value: label.value,
        categoryKey: label.key,
        key: label.key,
        scope: true,
        categoryName: _.get(calculatedFilterMap[label.key], 'value'),
      });

      return result;
    }, []);
  },
);

export const getGridSettings = createSelector(
  [isEdge, (_, props) => props.gridOnly, gridSettings],
  (isEdge, gridOnly, gridSettings) => {
    const columns = {...gridSettings.columns};

    columns.app.disabled = isEdge;
    columns.env.disabled = isEdge;
    columns.loc.disabled = isEdge;

    const settings = {...gridSettings, columns};
    const zeroActionEnabled = !isAPIAvailable('vens.unpair') && !isAPIAvailable('vens.upgrade');

    if (!gridOnly && !zeroActionEnabled) {
      return settings; // leave settings alone
    }

    columns.checkboxes.disabled = gridOnly; // display no checkboxes for gridOnly mode, which hides controls

    return settings;
  },
);

const getGrid = (state, props) =>
  getGridSelector(state, {
    settings: () => getGridSettings(state, props),
    rows: getVensRows,
    filterMap: getCalculatedRouteFilters,
  });

export const getVensPage = createSelector(
  [
    (state, props) => getGrid(state, props),
    getVensCount,
    getScopeItems,
    getCalculatedFilterMap,
    getCalculatedStaticValues,
    getCalculatedFacetMap,
    isEdge,
    getRouteParams,
    getGroup,
    getGroupItem,
    getSelectorSettings,
    getVensUpgradePendingTrue,
    getVensHealthError,
    getVensActive,
    getVensWarning,
    getVensSuspended,
  ],
  (
    grid,
    count,
    scopeItems,
    calculatedFilterMap,
    calculatedStaticValues,
    calculatedFacetMap,
    edgeEnabled,
    params,
    group,
    groupItem,
    selectorObject,
    pending,
    healthError,
    active,
    warning,
    suspended,
  ) => {
    const {
      versions: {pversionObj},
      isOldVersion,
    } = groupItem;

    let includeMatches = grid.rows.map(row => ({
      name: row.data.name || row.data.hostname,
      hostname: row.data.hostname,
      version: row.data.version,
      os_id: row.data.os_id,
    }));

    //This is for case when we want to hide all matches since the grid in empty in group view
    if (!includeMatches.length) {
      includeMatches = null;
    }

    const filterItems = Object.keys(grid.filter).reduce((result, categoryKey) => {
      const prefix = categoryKey.split('[')[0];
      const categoryName = _.get(calculatedFilterMap, `${prefix}.value`, calculatedFilterMap[prefix]); // ensure string, not object

      if (selectorObject.scopeMap[categoryKey]) {
        // in detail page
        grid.filter[categoryKey].forEach(({href, key, value}) => {
          result.push({
            href,
            value,
            categoryKey,
            key,
            categoryName: _.get(calculatedFilterMap[categoryKey], 'value'),
          });
        });
      } else if (selectorObject.autocompleteMap[categoryKey]) {
        grid.filter[categoryKey].forEach(({href, value}) => {
          result.push({href, value, categoryKey, categoryName});
        });
      } else if (categoryKey.includes('[gte]') || categoryKey.includes('[lte]')) {
        if (result.some(obj => obj.categoryKey === categoryKey && (obj.from || obj.to))) {
          return result;
        }

        const from = grid.filter[`${prefix}[gte]`];
        const to = grid.filter[`${prefix}[lte]`];

        const value = formatUtils.getRangeExpression(from, to, prefix);
        const dup = result.find(element => element && element.categoryKey === prefix && element.value === value);

        if (!dup) {
          result.push({
            categoryKey: prefix,
            categoryName,
            from,
            to,
            value,
          });
        }
      } else {
        result.push({
          categoryKey,
          value: grid.filter[categoryKey][0],
          categoryName,
        });
      }

      return result;
    }, []);

    let newObjectMap = selectorObject.objectMap;

    if (isEdge && params.tab === 'venlist') {
      newObjectMap = _.omit(selectorObject.objectMap, 'roleLabels');
    }

    // Selector parameters based on filter and config
    const selector = {
      scopeItems,
      filterItems,
      objects: Object.values(newObjectMap),
      categories: Object.entries(calculatedFilterMap).map(([categoryKey, value]) => ({
        categoryKey,
        value: value.value || value,
        object: value.object,
        scope: value.scope,
        statics: value.statics,
      })),
      facets: Object.keys(calculatedFacetMap),
      partials: Object.keys(calculatedFacetMap),
      statics: Object.entries(calculatedStaticValues).reduce((result, [key, values]) => {
        result[key] = Object.keys(values);

        return result;
      }, {}),
      customPickers: selectorObject.customPickers,
    };

    const hrefSelectedLabel = group.roleLabel ? group.roleLabel.href : pversionObj?.roleLabel?.href;

    return {
      grid,
      count,
      selector,
      edgeEnabled,
      includeMatches,
      selectedLabels: [{key: 'role', href: hrefSelectedLabel}],
      pending,
      healthError,
      active,
      suspended,
      warning,
      showActionButtons: !isOldVersion,
    };
  },
);
