/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import {PureComponent} from 'react';
import {connect} from 'react-redux';
import {getRoute} from 'containers/App/AppState';
import {fetchPairingProfileItem} from './PairingProfileItemSaga';

@connect(getRoute)
export default class PairingProfilesItem extends PureComponent {
  static prefetch = fetchPairingProfileItem;

  render() {
    return this.props.children;
  }
}
