/**
 * Copyright 2021 Illumio, Inc. All Rights Reserved.
 */
import PropTypes from 'prop-types';
import {useCallback, useContext} from 'react';
import {useSelector} from 'react-redux';
import Selector from './Selector';
import {getUrlScopeValue} from 'components/Grid/GridSelectors';
import {AppContext} from 'containers/App/AppUtils';

PropTypes.GridFilter = {
  noAutoFocus: PropTypes.bool, // GridFilter autoFocus the Selector by default, pass noAutoFocus to prevent this
  gridId: PropTypes.string.isRequired,
  gridParams: PropTypes.object,
  addLabelsAsScope: PropTypes.bool,
  onNavigate: PropTypes.func,
};

export default function GridFilter(props) {
  const {navigate} = useContext(AppContext);
  const {valid: scope} = useSelector(getUrlScopeValue);

  const {gridId, gridParams, addLabelsAsScope, onNavigate, noAutoFocus, inputProps, ...selectorProps} = props;

  const handleFilterChange = useCallback(
    values => {
      if (onNavigate) {
        return onNavigate(values);
      }

      const filter = {};
      const scope = {};

      [...Array.from(values)].forEach(([categoryKey, values]) => {
        if (addLabelsAsScope && (categoryKey === 'labels' || categoryKey === 'label_groups')) {
          scope[categoryKey] = values;
        } else {
          filter[categoryKey] = values;
        }
      });

      navigate({params: {scope, [gridId]: {...gridParams, filter, page: null}}});
    },
    [gridId, addLabelsAsScope, onNavigate, navigate, gridParams],
  );

  const values = new Map([...Object.entries(scope ?? {}), ...Object.entries(gridParams.filter ?? {})]);

  Object.assign(selectorProps, {
    closeDropdownOnSelection: true,
    values,
    onSelectionChange: handleFilterChange,
    inputProps: {...inputProps, autoFocus: !noAutoFocus},
  });

  return <Selector {...selectorProps} />;
}
