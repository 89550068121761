/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from 'intl';
import {Component} from 'react';
import {AppContext} from 'containers/App/AppUtils';
import {APIError} from 'errors';
import {AttributeList, Button, Form, ModalMachine, Notifications, TypedMessages} from 'components';
import * as PropTypes from 'prop-types';
import {object, mixed} from 'yup';
import {submitActionModalMachine} from 'containers/SubmitButton/SubmitSaga';
import {hrefUtils} from 'utils';
import {
  getEnforcementBoundariesVisibilitySchema,
  getEnforcementBoundariesInitialValues,
} from 'containers/EnforcementBoundaries/EnforcementBoundariesVisibilityFormUtils';
import {
  managementOptions,
  getContainerClusterContainerWorkloadProfilePayload,
  getLabelSourcesForm,
} from '../../ContainerClusterDetailUtils';

const getInitialValues = props => {
  const {initLabels, managed, roleSource, appSource, envSource, locSource} = props;
  const {role, app, env, loc, roleAllow, appAllow, envAllow, locAllow} = initLabels; // FormLabelSelector expects legacy format

  return {
    roleSource,
    appSource,
    envSource,
    locSource,
    role,
    app,
    env,
    loc,
    roleAllow,
    appAllow,
    envAllow,
    locAllow,
    managed: Form.Utils.findSelectedOption(managementOptions, managed?.toString() ?? 'true'),
    ...getEnforcementBoundariesInitialValues({
      enforcement_mode: props.enforcementMode,
      visibility_level: props.visibilityLevel,
    }),
  };
};

const getEmptyState = props => ({
  actionError: null,
  initialValues: getInitialValues(props),
});

export default class DefaultContainerWorkloadProfileEditModal extends Component {
  static contextType = AppContext;
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    href: PropTypes.string.isRequired,
    enforcementMode: PropTypes.string.isRequired,
    visibilityLevel: PropTypes.string,
    initLabels: PropTypes.object.isRequired,
    clusterId: PropTypes.string.isRequired,
    managed: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);

    this.options = {};
    this.schemas = object({
      policyState: object().nullable(),
      managed: mixed(),
      enforcementMode: getEnforcementBoundariesVisibilitySchema.enforcementMode,
      // Use nested when to look for both managed and enforcementMode
      // ref: https://github.com/jquense/yup/issues/335
      // When managed is selected then visibility_level is required only when enforcementMode !== 'idle'
      // When managed is selected and enforcementMode === 'idle' then visibility_level is not required
      visibility_level: mixed().when('managed', {
        is: val => val.value === 'true',
        then: getEnforcementBoundariesVisibilitySchema.visibility_level,
      }),
      roleSource: Form.Radio.schema,
      role: object().when('roleSource', {
        is: 'assign',
        then: object().required(intl('ContainerClusters.AssignLabelIsRequired')),
        otherwise: object(),
      }),
      appSource: Form.Radio.schema,
      app: object().when('appSource', {
        is: 'assign',
        then: object().required(intl('ContainerClusters.AssignLabelIsRequired')),
        otherwise: object(),
      }),
      envSource: Form.Radio.schema,
      env: object().when('envSource', {
        is: 'assign',
        then: object().required(intl('ContainerClusters.AssignLabelIsRequired')),
        otherwise: object(),
      }),
      locSource: Form.Radio.schema,
      loc: object().when('locSource', {
        is: 'assign',
        then: object().required(intl('ContainerClusters.AssignLabelIsRequired')),
        otherwise: object(),
      }),
    });

    this.handleOnSubmit = this.handleOnSubmit.bind(this);
    this.renderForm = this.renderForm.bind(this);

    this.state = getEmptyState(props);
  }

  handleOnSubmit(context, {values}) {
    const {fetcher} = this.context;
    const {clusterId, href} = this.props;

    try {
      const payload = getContainerClusterContainerWorkloadProfilePayload(values);

      return fetcher.spawn(submitActionModalMachine, {
        target: 'editContainerClusterProfile',
        obj: {
          params: {container_cluster_id: clusterId, container_workload_profile_id: hrefUtils.getId(href)},
          data: payload,
        },
      });
    } catch (error) {
      // Use APIError to align with ModalMachine.parseErrorsFromProcessResult() data structure
      return Promise.reject(new APIError({data: [{message: error.message}]}));
    }
  }

  renderForm(send, options) {
    const {setSubmitting} = options;
    const {allowCreateTypes} = this.props;

    this.options = options;
    this.setSubmitting = setSubmitting;

    const attributeList = [
      {
        key: (
          <Form.Label
            name="managed"
            title={intl('Common.Management')}
            lineHeight="var(--optionSelector-line-height)"
            paddingTop="var(--optionSelector-vertical-padding)"
          />
        ),
        value: <Form.Selector name="managed" tid="managed" options={managementOptions} />,
      },
    ];

    attributeList.push(...getLabelSourcesForm(options, allowCreateTypes));

    // The following div is used to style the minimal height of the attribute list, as the attribute list doesn't generate proper
    // class name for css module
    return <AttributeList valuesGap="gapMedium">{attributeList}</AttributeList>;
  }

  render() {
    const {onSubmit, onClose} = this.props;

    return (
      <ModalMachine
        modalProps={{
          stretch: true,
          dontRestrainChildren: false,
          minHeight: 295, // vertical room for "unmanaged/managed" menu
        }}
        onClose={onClose}
        services={{
          process: this.handleOnSubmit,
          onProcessDone: onSubmit,
        }}
      >
        {({matches, context: {onProgressDone, errors}}) => {
          if (matches('modal.open') || matches('modal.submitting')) {
            return {
              formProps: {
                allowLeaveOnDirty: true,
                schemas: this.schemas,
                initialValues: getInitialValues(this.props),
              },
              header: {props: {title: intl('ContainerClusters.ContainerWorkloadProfileTemplate')}},
              content: {children: this.renderForm},
              footer: {
                children: (send, {isValid, values}) => (
                  <>
                    <Button
                      noFill
                      tid="cancel"
                      key="cancel"
                      name="cancel"
                      text={intl('Common.Cancel')}
                      onClick={_.partial(send, {type: 'CANCEL'})}
                    />
                    <Button
                      tid="ok"
                      name="ok"
                      key="primary"
                      type="submit"
                      text={intl('Common.Apply')}
                      disabled={isValid === false}
                      progressCompleteWithCheckmark
                      onProgressDone={onProgressDone}
                      onClick={_.partial(send, {type: 'SUBMIT', values})}
                      progress={matches('modal.submitting.progress')}
                    />
                  </>
                ),
              },
            };
          }

          if (matches('modal.error')) {
            return {
              header: {props: {title: intl('Common.Alert')}},
              content: (
                <>
                  <Notifications>
                    {[
                      {
                        type: 'instruction',
                        message: intl('ContainerClusters.ContainerWorkloadProfilesMessage'),
                      },
                    ]}
                  </Notifications>
                  {errors && <TypedMessages>{[{icon: 'error', content: errors?.message}]}</TypedMessages>}
                </>
              ),
            };
          }
        }}
      </ModalMachine>
    );
  }
}
