/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import apiSaga from 'api/apiSaga';
import {call, select, put, take, fork} from 'redux-saga/effects';
import {getNetworkEnforcementNodes} from './NetworkEnforcementNodeState';

export function* fetchNetworkEnforcementNodes(payload, force = false) {
  let networkEnforcementNodes = yield select(getNetworkEnforcementNodes);

  yield put({type: 'NETWORK_ENFORCEMENT_NODES_GETTING_LIST'});
  ({data: networkEnforcementNodes} = yield call(apiSaga, 'network_enforcement_nodes.get_collection', {cache: !force}));
  yield put({type: 'NETWORK_ENFORCEMENT_NODES_GET_LIST', data: {networkEnforcementNodes}});

  if (networkEnforcementNodes && networkEnforcementNodes.length) {
    const selectedNodeHref = payload && payload.networkEnforcementNodeHref;
    const networkEnforcementNodeHref =
      selectedNodeHref === undefined ? networkEnforcementNodes[0].href : selectedNodeHref;
    const networkEnforcementNodeInstance = networkEnforcementNodes.find(
      networkEnforcementNode => networkEnforcementNode.href === networkEnforcementNodeHref,
    );

    if (networkEnforcementNodeInstance) {
      yield put({type: 'NETWORK_ENFORCEMENT_NODE_GET_INSTANCE', data: {networkEnforcementNodeInstance}});
    }
  }
}

export function* watchNetworkEnforcementNodeSelect() {
  while (true) {
    const {payload} = yield take('SELECTED_NETWORK_ENFORCEMENT_NODE_UPDATE');

    yield fork(fetchNetworkEnforcementNodes, payload);
  }
}
