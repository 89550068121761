/**
 * Copyright 2017 Illumio, Inc. All Rights Reserved.
 */
import * as PropTypes from 'prop-types';
import {Component, createContext, createElement} from 'react';
import {forwardRefSymbol, withForwardRef} from 'react-forwardref-utils';
import styles from './StickyShadow.css';

export const StickyContext = createContext(null);

@withForwardRef()
export default class StickyContainer extends Component {
  static propTypes = {
    // Sticky container can mimic any html element ('div', 'h2' etc) or custom component (constructor like Link, Label etc)
    // Custom components are usually functions. but can be objects if they wrapped in forwardRef
    type: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.object]),
    // Offset positive of which will applied to the sticky element's 'top' and nagative will be applied to the shadow helper
    offset: PropTypes.string,
    // If you want shadow to start showing earlier than sticky element stucks, for instance `10px` earlier
    shadowPreOffset: PropTypes.string,

    children: PropTypes.any,
  };

  static defaultProps = {
    type: 'div',
    offset: '0px',
    shadowPreOffset: '0px',
  };

  constructor(props) {
    super(props);

    this.stickyChildren = new Set();

    this.saveHelperRef = this.saveHelperRef.bind(this);
    this.registerChildSticky = this.registerChildSticky.bind(this);
    this.deregisterChildSticky = this.deregisterChildSticky.bind(this);

    // If offset is not zero, start showing shadow ten pixels in advance
    // to finish its animation by the time element have actually stuck, to bring more smoothness
    const helperOffset =
      props.offset === '0px' ? props.offset : `calc(-1 * ${props.offset} - ${props.shadowPreOffset})`;

    this.helper = <div style={{top: helperOffset}} className={styles.helper} ref={this.saveHelperRef} />;

    this.stickyContext = {
      offset: this.props.offset,
      checkIn: this.registerChildSticky,
      checkOut: this.deregisterChildSticky,
    };
  }

  componentDidMount() {
    if (this.helperDom) {
      this.observer = new IntersectionObserver(([entry]) => {
        this.stickyChildren.forEach(stickyChild => stickyChild.setRatio(entry.isIntersecting, entry.intersectionRatio));
      });

      this.observer.observe(this.helperDom);
    }
  }

  componentWillUnmount() {
    if (this.observer) {
      this.observer.disconnect();
    }
  }

  saveHelperRef(element) {
    if (!element && this.observer) {
      this.observer.unobserve(this.helperDom);
    }

    this.helperDom = element;
  }

  registerChildSticky(child) {
    this.stickyChildren.add(child);
  }

  deregisterChildSticky(child) {
    this.stickyChildren.delete(child);
  }

  render() {
    const {children, offset, shadowPreOffset, type, [forwardRefSymbol]: forwardRef, ...props} = this.props;

    props.ref = forwardRef;
    props.className = props.className ? `${props.className} ${styles.container}` : styles.container;

    return (
      <StickyContext.Provider value={this.stickyContext}>
        {createElement(type, props, this.helper, children)}
      </StickyContext.Provider>
    );
  }
}
