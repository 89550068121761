/**
 * Copyright 2021 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import {useCallback, useState, useContext, useRef} from 'react';
import {useSelector} from 'react-redux';
import {AppContext} from 'containers/App/AppUtils';
import {fetchIKECertificate, updateIKECertificate} from './IKECertificatesSaga';
import IKECertificatesReducer, {getIKECertificatePage} from './IKECertificatesState';
import {HeaderProps} from 'containers';
import {AttributeList, StatusIcon, ToolBar, ToolGroup, Button, Form, Modal, TypedMessages, InfoCard} from 'components';
import {object, mixed} from 'yup';
import stylesUtils from 'utils.css';

// yup schema
const schemas = object({
  certIssuer: mixed(),
});

IKECertificatesEdit.prefetch = fetchIKECertificate;
IKECertificatesEdit.reducers = IKECertificatesReducer;

export default function IKECertificatesEdit() {
  const {fetcher, navigate} = useContext(AppContext);
  const {ikeCertificate} = useSelector(getIKECertificatePage);
  const [error, setError] = useState(null);
  const formikOptions = useRef(null);
  const infoCardIconRef = useRef(null);

  const handleSave = useCallback(async () => {
    const {
      values: {certIssuer},
      setSubmitting,
      resetForm,
    } = formikOptions.current;

    setSubmitting(true);

    try {
      await fetcher.spawn(updateIKECertificate, certIssuer);
      setSubmitting(false);
      resetForm({certIssuer}); // reset to prevent page dirty notification on navigation
    } catch (error) {
      setSubmitting(false);
      setError(error);
    }
  }, [fetcher]);

  const handleErrorClose = useCallback(() => {
    setError(null);
  }, []);

  const renderErrorModal = useCallback(
    () => (
      <Modal.Alert
        title={intl('Common.Error')}
        onClose={handleErrorClose}
        buttonProps={{tid: 'ok', text: intl('Common.OK')}}
      >
        <TypedMessages>{[{icon: 'error', content: error.message}]}</TypedMessages>
      </Modal.Alert>
    ),
    [error, handleErrorClose],
  );

  const handleSaveDone = useCallback(() => {
    if (!error) {
      navigate('ikecertificates');
    }
  }, [navigate, error]);

  return (
    <>
      <HeaderProps title={intl('Settings.IKECertificates')} label={`(${intl('Common.Edit')})`} />
      <Form enableReinitialize schemas={schemas} initialValues={{certIssuer: ikeCertificate}}>
        {options => {
          formikOptions.current = options;

          return (
            <>
              <ToolBar justify="left">
                <ToolGroup>
                  <Button
                    icon="save"
                    tid="save"
                    type="submit"
                    text={intl('Common.Save')}
                    progressCompleteWithCheckmark
                    progress={options.isSubmitting}
                    onClick={handleSave}
                    onProgressDone={handleSaveDone}
                    progressError={Boolean(error)}
                    disabled={ikeCertificate === options.values.certIssuer}
                  />
                  <Button.Link
                    color="standard"
                    icon="cancel"
                    tid="cancel"
                    text={intl('Common.Cancel')}
                    link="ikecertificates"
                  />
                </ToolGroup>
              </ToolBar>
              <StatusIcon status="info" label={intl('Settings.VensIKEAuthenticationInfo')} noTextColor />
              <AttributeList>
                {[
                  {
                    key: (
                      <Form.Label
                        name="certIssuer"
                        title={intl('Settings.CertificateIssuer')}
                        lineHeight="var(--30px)"
                      />
                    ),
                    value: (
                      <>
                        <Form.Input
                          tid="certIssuer"
                          name="certIssuer"
                          placeholder={intl('Settings.CertificateExample')}
                        />
                        <InfoCard trigger={infoCardIconRef} initiallyOpened>
                          {intl('Settings.ValidCertificateValues', {className: stylesUtils.bold}, {html: true})}
                        </InfoCard>
                      </>
                    ),
                    secondary: intl('Settings.CertificateSecondaryText'),
                    valueGap: 'gapMedium',
                    icon: <InfoCard.Icon ref={infoCardIconRef} />,
                  },
                ]}
              </AttributeList>
              {error && renderErrorModal()}
            </>
          );
        }}
      </Form>
    </>
  );
}
