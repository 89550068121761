/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
export default {
  instance(state = {}, action) {
    switch (action.type) {
      case 'PAIRING_PROFILES_GET_INSTANCE':
        return action.data;
      default:
        return state;
    }
  },
  pairKey(state = {}, action) {
    switch (action.type) {
      case 'PAIRING_PROFILE_PAIR_KEY':
        return action.data;
      default:
        return state;
    }
  },
};

export const getPairingProfileInstance = state => state.pairingprofiles.instance;
export const getPairingKey = state => state.pairingprofiles.pairKey;
