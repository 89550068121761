/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import {type ContextType, createContext} from 'react';
import type {ReactReduxContextValue} from 'react-redux';
import type {Router} from '../../router/configureRouter';
import type {MouseEventLike} from 'utils/dom';

// Provides global context for router, store, navigate, navigateBackOrTo
export const AppContext = createContext<{
  //
  /**
   * FIXME: use the proper types once they are ready.
   * `add`, `update`, `upsert`, `remove`, `navigate` and `navigateBackOrTo` can be derived from `typeof App`
   *
   * And we probably should move the whole interface to App.js
   */
  add: unknown;
  update: unknown;
  upsert: unknown;
  remove: unknown;
  store: ReactReduxContextValue['store'];
  // FIXME: once ReactReduxContextValue['store'] is augmented, we can use the correct type here
  router: Router;
  dispatch: ReactReduxContextValue['store']['dispatch'];
  navigate: (
    to:
      | string
      | {
          to?: string;
          params?: Record<string, unknown>;
          reload?: boolean;
          replace?: boolean;
          evt?: MouseEventLike;
          pageName?: string;
          scrollTop?: boolean;
          noUnsavedPendingWarning?: boolean;
          mergeParams?: boolean;
          target?: string;
        },
  ) => void;
  navigateBackOrTo: unknown;
  // we do null assertion to prevent null-check on the use site. However, we need to make sure the context is consumed within a Provider with correct values
  // see: https://react-typescript-cheatsheet.netlify.app/docs/basic/getting-started/context
}>(null!);

export type AppContextType = ContextType<typeof AppContext>;

/**
 * Call fetchHealth will get information for clusters and local fqdn.
 * These information can help to decide whether the current PCE is local PCE for the super cluster setting.
 * If it is not a super cluster, it is always a local PCE.
 * @param clusters A list of clusters. If the number is greater than 1, it is a super cluster with multiple PCEs
 * @param local fqdn
 * @param pceFqdn The current PCE's fqdn
 * @returns {boolean}
 */
export const isLocalPce = ({
  clusters,
  local,
  pceFqdn,
}: {
  clusters: unknown[];
  local: boolean;
  pceFqdn: boolean;
}): boolean => (clusters.length > 1 ? local === pceFqdn : true);

const directions = {
  consumerFirst: {
    horizontal: 'right',
    vertical: 'down',
  },
  providerFirst: {
    horizontal: 'left',
    vertical: 'up',
  },
} as const;

/**
 * Helper that provides arrow direction based on consumer provider order.
 * @param {String} preference
 * @returns {{leftOrRight: string, upOrDown: string}}
 */
export const getConsumerProviderArrowDirection = (
  isReverseProviderConsumer: boolean,
  bearing: 'horizontal' | 'vertical',
): 'right' | 'down' | 'left' | 'up' => {
  return directions[isReverseProviderConsumer ? 'consumerFirst' : 'providerFirst'][bearing];
};
