/**
 * Copyright 2021 Illumio, Inc. All Rights Reserved.
 */
import cx from 'classnames';
import {useEffect, useCallback, useRef} from 'react';
import {KEY_BACK_SPACE, KEY_RETURN, KEY_TAB} from 'keycode-js';
import {Pill, Tooltip} from 'components';
import SelectedValue from './SelectedValue';
import styleUtils from 'utils.css';
import {
  VALUEPANEL_ID,
  useFilialPiety,
  getOptionId,
  getOptionById,
  getOptionText,
} from 'containers/Selector/SelectorUtils';

export default function SelectedResource(props) {
  const {
    id,
    categoryId,
    displayResourceAsCategory,
    name,
    disabled,
    insensitive,
    optionProps: {idPath, textPath} = {},
    selectedProps = {},
    selectedProps: {formatResource} = {},
    registerHandlers,
    values,
    errors,
    onMouseLeave,
    onRemove,
    onSelectedValueClick,
    onSetHighlighted,
    theme,
    saveRef,
  } = props;

  const {saveChildRef, highlightedChild, setHighlightedChild, resetHighlightedChild} = useFilialPiety();
  const highlightedChildRef = useRef();
  const valuesRef = useRef();
  const onRemoveRef = useRef();
  const onSelectedValueClickRef = useRef();

  highlightedChildRef.current = highlightedChild;
  valuesRef.current = values;
  onRemoveRef.current = onRemove;
  onSelectedValueClickRef.current = onSelectedValueClick;

  const saveRefCallback = useCallback(element => saveRef(id, element), [id, saveRef]);

  const handleRemove = useCallback(
    (evt, valueId) => onRemoveRef.current(evt, new Map([[id, [getOptionById(valuesRef.current, valueId, idPath)]]])),
    [id, idPath],
  );

  const handleClick = useCallback(
    evt => {
      onSelectedValueClickRef.current(evt, categoryId);
    },
    [categoryId],
  );

  const handleMouseOver = useCallback(
    (evt, valueId) => {
      if (valueId !== highlightedChild?.id) {
        // remove previous highlighted child and set new highlighted child
        onSetHighlighted(evt, {pathArr: [VALUEPANEL_ID, id], newHighlightedId: valueId});
      }
    },
    [id, onSetHighlighted, highlightedChild],
  );

  const handleKeyDown = useCallback(
    evt => {
      if (evt.keyCode === KEY_BACK_SPACE && highlightedChildRef.current) {
        handleRemove(evt, highlightedChildRef.current.id);
      }

      if (evt.keyCode === KEY_RETURN || evt.keyCode === KEY_TAB) {
        handleClick(evt);
      }
    },
    [handleRemove, handleClick],
  );

  useEffect(() => {
    const unregister = registerHandlers(id, {setHighlightedChild, resetHighlightedChild, keyDown: handleKeyDown}); // register handlers

    return () => unregister();
  }, [registerHandlers, id, setHighlightedChild, resetHighlightedChild, handleKeyDown]);

  const formatContent = useCallback(
    values => {
      const {
        formatValue,
        isPill = true,
        valueJoiner,
        hideResourceName,
        pillProps,
        joinerPillProps = {},
      } = selectedProps;

      let content = values.map((value, index) => {
        const valueId = getOptionId(value, idPath);

        // Error specific to a value is set at its index position in errors array
        // If specific error do not exist but there is an error in the selected resource then errors is a string
        // Pass resource error if joiner is false so that each selected pill can render error message
        const error = Array.isArray(errors) ? errors[index] : valueJoiner ? undefined : errors;

        const val = (
          <SelectedValue
            theme={theme}
            saveRef={saveChildRef}
            disabled={disabled}
            insensitive={insensitive}
            key={valueId}
            id={valueId}
            text={getOptionText(value, textPath)}
            value={value}
            format={formatValue}
            resourceName={name}
            hideResourceName={hideResourceName ?? Boolean(valueJoiner)}
            error={error}
            isPill={valueJoiner ? false : isPill}
            pillProps={pillProps}
            highlighted={valueId === highlightedChild?.id}
            onClick={displayResourceAsCategory ? undefined : handleClick}
            onRemove={handleRemove}
            onMouseOver={handleMouseOver}
            onMouseLeave={onMouseLeave}
          />
        );

        if (valueJoiner) {
          return (
            <div key={valueId} className={cx(styleUtils.gapInline, styleUtils.gapHorizontal)}>
              {index > 0 && <div className={theme.joiner}>{valueJoiner}</div>}
              {val}
            </div>
          );
        }

        return val;
      });

      if (valueJoiner) {
        const pillProps = typeof joinerPillProps === 'function' ? joinerPillProps(values) : joinerPillProps;

        Object.assign(pillProps, {
          theme,
          error: typeof errors === 'string',
          disabled,
          insensitive,
          ...(disabled && {themePrefix: 'pillDisabled-'}),
        });

        content = (
          <Pill {...pillProps}>
            {hideResourceName ? (
              content
            ) : (
              <>
                {`${name}: `}
                <span className={styleUtils.bold}>{content}</span>
              </>
            )}
          </Pill>
        );

        if (errors && typeof errors === 'string') {
          // error on resource, show error message
          content = (
            <Tooltip content={errors} visible>
              {content}
            </Tooltip>
          );
        }
      }

      return content;
    },
    [
      name,
      idPath,
      textPath,
      disabled,
      displayResourceAsCategory,
      insensitive,
      selectedProps,
      theme,
      errors,
      handleMouseOver,
      handleClick,
      handleRemove,
      saveChildRef,
      highlightedChild,
      onMouseLeave,
    ],
  );

  return (
    <div className={theme.selectedResource} ref={saveRefCallback} onKeyDown={handleKeyDown}>
      {formatResource?.({
        theme,
        formatContent,
        selectedProps,
        values,
        saveRef,
        highlightedChild,
        onClick: displayResourceAsCategory ? undefined : handleClick,
        onRemove: handleRemove,
        onMouseOver: handleMouseOver,
        onMouseLeave,
      }) ?? formatContent(values)}
    </div>
  );
}
