/**
 * Copyright 2016 Illumio, Inc. All Rights Reserved.
 */
import cx from 'classnames';
import * as PropTypes from 'prop-types';
import {Component, createElement} from 'react';
import {AppContext} from 'containers/App/AppUtils';
import {shallowEqualLooseByProps} from 'utils/general';
import {KEY_ESCAPE, KEY_UP, KEY_DOWN, KEY_SPACE, KEY_RETURN} from 'keycode-js';
import {Link, Spinner} from 'components';
import {preventEvent, scrollToElement} from 'utils/dom';
import Area from './GridAreaBody';
import styles from './Grid.css';
import stylesManager from './Manager/GridManager.css';
import {getColSpanData} from 'components/Grid/GridUtils';

export default class GridRow extends Component {
  static contextType = AppContext;
  static propTypes = {
    breakpoint: PropTypes.object.isRequired,
    grid: PropTypes.object.isRequired,
    row: PropTypes.object.isRequired,
    extraProps: PropTypes.object,
    selected: PropTypes.bool,
    dontHighlightSelected: PropTypes.bool,
    onSelect: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    onClick: PropTypes.func,
    onMouseOver: PropTypes.func,
    onMouseLeave: PropTypes.func,
    colSpanData: PropTypes.object,
  };

  static defaultProps = {
    selected: false,
  };

  constructor(props) {
    super(props);

    this.state = {focused: false};
    this.cellsToCheckFocus = [];

    this.saveFocuserRef = this.saveFocuserRef.bind(this);
    this.saveLoaderRef = this.saveLoaderRef.bind(this);
    this.saveCellRef = this.saveCellRef.bind(this);
    this.saveRowRef = this.saveRowRef.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleMouseOver = this.handleMouseOver.bind(this);
    this.handleMouseLeave = this.handleMouseLeave.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.updateLoader = this.updateLoader.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const {row, extraProps, breakpoint} = nextProps;

    const span = extraProps?.span ?? row.span;

    if (prevState.breakpoint !== breakpoint || prevState.span !== span) {
      return {breakpoint, span, ...getColSpanData(nextProps)};
    }

    return null;
  }

  componentDidMount() {
    this.updateLoader();
  }

  componentDidUpdate() {
    this.updateLoader();
  }

  saveRowRef(element) {
    if (element) {
      this.rowElement = this.props.row.link ? element.element : element;
    } else {
      this.rowElement = element;
    }
  }

  saveCellRef(element, instance) {
    if (element) {
      this.cellsToCheckFocus.push({element, instance});
    } else {
      this.cellsToCheckFocus = this.cellsToCheckFocus.filter(cell => cell.instance !== instance);
    }
  }

  saveFocuserRef(focuser) {
    this.focuser = focuser;
  }

  saveLoaderRef(loader) {
    this.loader = loader;

    // Until we use subgrid (only Firefox supports it as of now) we need to compute width of the loader based on the table width
    if (loader) {
      window.addEventListener('resize', this.updateLoader);

      // Until we have the 'inert' html attribute, we need to at least remove focus from within of the row.
      // (Existing inert polyfill is very espensive https://github.com/WICG/inert)
      if (document.activeElement && this.rowElement?.contains(document.activeElement)) {
        document.activeElement.blur();
      }
    } else {
      window.removeEventListener('resize', this.updateLoader);
    }
  }

  handleFocus(evt) {
    // Highlight row on focuser focus
    if (!this.state.focused) {
      this.changeFocus(true);
    }

    this.props.onFocus?.(evt, this);
  }

  handleBlur(evt) {
    // Unhighlight row on focuser blur
    if (this.state.focused) {
      this.changeFocus(false);
    }

    this.props.onBlur?.(evt, this);
  }

  handleSelect(evt, checking, pressedKeys) {
    if (this.props.onSelect) {
      this.props.onSelect(evt, this.props.row, pressedKeys);
    }
  }

  handleClick(evt) {
    // prevent row click when user is selecting text
    const didUserSelectText = !evt.shiftKey && Boolean(window.getSelection().toString());

    // Prevent row click action if cell or its content not focusable or not clickable (if onMouseOver/onClick handlers contain/return false)
    const preventRowClick =
      didUserSelectText ||
      !this.rowElement.contains(evt.target) ||
      this.cellsToCheckFocus.some(({instance, element}) => {
        if (element.contains(evt.target)) {
          const {
            props: {cell, row},
            contentElements,
          } = instance;
          const params = {evt, row, elements: contentElements, store: this.context.store};

          // Prevent click on row if onMouseOver or onClick returns false
          // onClick still can return true to override false from onMouseOver if row click should happen
          let prevent;

          if (cell.onClick === true) {
            prevent = false;
          } else if (cell.onClick === false) {
            prevent = true;
          } else if (typeof cell.onClick === 'function') {
            const onClickResult = cell.onClick(params);

            if (onClickResult === true) {
              prevent = false;
            } else if (onClickResult === false) {
              prevent = true;
            }
          }

          if (prevent === undefined) {
            prevent =
              cell.onMouseOver === false ||
              (typeof cell.onMouseOver === 'function' && cell.onMouseOver(params) === false);
          }

          return prevent;
        }

        return false;
      });

    if (preventRowClick) {
      evt.stopPropagation();
    } else if (this.props.onClick) {
      this.props.onClick(evt, this.props.row);
    }
  }

  handleMouseOver(evt) {
    // Prevent row focus if cell or its content not focusable (if onMouseOver handler contains/returns false)
    const preventRowFocus =
      !this.rowElement.contains(evt.target) ||
      this.cellsToCheckFocus.some(({instance, element}) => {
        if (element.contains(evt.target)) {
          const {
            props: {cell, row},
            contentElements,
          } = instance;

          return (
            cell.onMouseOver === false ||
            (typeof cell.onMouseOver === 'function' &&
              cell.onMouseOver({evt, row, elements: contentElements}) === false)
          );
        }

        return false;
      });

    if (
      !preventRowFocus &&
      document.activeElement !== this.focuser &&
      (!document.activeElement ||
        document.activeElement === document.body ||
        document.activeElement.classList.contains(styles.focuser))
    ) {
      // If user hovers this row while nothing else is focused, or the other row is focused, focus this row instead
      this.focusElement(this.focuser);
    } else {
      // Otherwise just toggle the visible focus highlight
      this.changeFocus(!preventRowFocus);
    }

    this.props.onMouseOver?.(evt, this.props.row);
  }

  handleMouseLeave(evt) {
    this.changeFocus(false);

    if (document.activeElement === this.focuser) {
      this.focuser.blur();
    }

    this.props.onMouseLeave?.(evt, this.props.row);
  }

  handleKeyDown(evt) {
    const {keyCode} = evt;

    if (keyCode === KEY_ESCAPE && document.activeElement === this.focuser) {
      // If row is focused, blur it and it will remove focus state
      preventEvent(evt);
      this.focuser.blur();
    } else if (keyCode === KEY_UP || keyCode === KEY_DOWN) {
      // Focus previous row on up arrow or next one on down arrow
      preventEvent(evt);
      this.focusNextClickableRow(keyCode === KEY_UP);
    } else if (keyCode === KEY_SPACE || keyCode === KEY_RETURN) {
      // Invoke row click on Space/Enter
      preventEvent(evt);
      this.props.onClick?.(evt, this.props.row);
    }
  }

  focusNextClickableRow(upwards) {
    const clickableRowClass = styles.rowBodyClickable;
    const currentRow = this.rowElement;
    let nextRow = currentRow;

    if (upwards) {
      do {
        nextRow = nextRow.previousElementSibling;
      } while (nextRow && !nextRow.classList.contains(clickableRowClass));

      // If current row is the first one, nextRow will be null, then move focus to the last row
      nextRow ??= [...currentRow.parentElement.querySelectorAll(`.${clickableRowClass}`)].lastItem;
    } else {
      do {
        nextRow = nextRow.nextElementSibling;
      } while (nextRow && !nextRow.classList.contains(clickableRowClass));

      // If current row is the last one, nextRow will be null, then move focus to the first row
      nextRow ??= currentRow.parentElement.querySelector(`.${clickableRowClass}`);
    }

    // Exit if could not find the other row
    if (!nextRow || nextRow === currentRow) {
      return;
    }

    const focuser = nextRow.querySelector(`.${styles.focuser}`);

    if (focuser) {
      this.focusElement(focuser, true);
    }
  }

  // Method to set 'focused' state on a row, is called by parent Grid, which will unfocus currently focused and focus this one
  changeFocus(focused, onRerender) {
    if (this.state.focused !== focused) {
      this.setState({focused}, onRerender);
    }
  }

  focusElement(element, scroll = false) {
    element.focus({preventScroll: true});

    if (scroll) {
      // Correctly compute scroll offset by taking sticky GridManager and GridHead into account
      const offsetElements = [
        // Get the GridManager element is exists
        this.rowElement.parentElement.parentElement.querySelector(`.${stylesManager.manager}`),
        // Need to get first elements in the head, since the header is `display: contents` until it uses the subgrid
        this.rowElement.parentElement.querySelector(`.${styles.rowHead.split(' ')[0]}`)?.querySelector(':first-child'),
      ].filter(Boolean);

      scrollToElement({element, offsetElements});
    }
  }

  updateLoader() {
    if (this.loader) {
      // Loader inherits height of the focuser, but we still need to take width from the table (row is dispaly:contents so its width is 0)
      this.loader.style.width = this.loader.closest(`.${styles.table}`).getBoundingClientRect().width + 'px';
    }
  }

  render() {
    const {
      grid,
      row,
      selected,
      dontHighlightSelected,
      theme,
      component,
      onClick,
      extraProps: {
        error = row.error ?? false,
        warning = row.warning ?? false,
        info = row.info ?? false,
        loading = row.loading ?? false,
        ...extraProps
      } = {},
    } = this.props;

    const {span, breakpoint, flattenedIndices, spanColumnIndices} = this.state;

    // Each row can contain `clickable` prop to control if it can be clicked. Otherwise, onClick prop on Grid will be checked.
    const clickable = row.clickable ?? Boolean(onClick || row.link);
    const className = cx(theme.rowBody, {
      [theme.focused]: this.state.focused,
      [theme.rowBodyInsensitive]: loading,
      [theme.rowBodyClickable]: clickable,
      [theme.rowBodySelected]: selected && !dontHighlightSelected,
    });
    const style = {};

    let elementType;
    const elementProps = {
      className,
      style,
      'ref': this.saveRowRef,
      'data-tid': 'comp-grid-row',
    };

    if (clickable) {
      elementProps.onClick = this.handleClick;
      elementProps.onMouseOver = this.handleMouseOver;
      elementProps.onMouseLeave = this.handleMouseLeave;
    }

    if (Object.keys(extraProps).length) {
      Object.assign(elementProps, extraProps, {className: cx(className, extraProps.className)});
    }

    if (row.link) {
      elementType = Link;
      Object.assign(elementProps, {theme: {link: className}}, typeof row.link === 'string' ? {to: row.link} : row.link);
    } else {
      elementType = 'div';
    }

    const areasProps = {
      grid,
      row,
      theme,
      selected,
      breakpoint,
      component,
      error,
      warning,
      info,
      loading,
      extraProps,
    };

    if (!this.areasProps || !shallowEqualLooseByProps(this.areasProps, areasProps, Object.keys(areasProps))) {
      this.children = [
        // First column contains invisible empty div to receive focus, which is reachable only if row is clickable
        <div
          key="focuser"
          className={theme.focuser}
          ref={this.saveFocuserRef}
          {...(clickable && {
            tabIndex: '0',
            onFocus: this.handleFocus,
            onBlur: this.handleBlur,
            onKeyDown: this.handleKeyDown,
          })}
        >
          {loading && ( // In case loading is happening, put absolute positioned div that will overlay the row
            <div key="loader" className={theme.loader} ref={this.saveLoaderRef}>
              <Spinner theme={theme} themePrefix="loader-" />
            </div>
          )}
        </div>,
      ];

      for (const [index, breakpointColumn] of breakpoint.columns.entries()) {
        const spanColumn = spanColumnIndices.find(spanColumn =>
          breakpointColumn.cells?.some(cell => cell.id === spanColumn.id),
        );

        if (!flattenedIndices.has(index) || !span) {
          this.children.push(
            <Area
              {...areasProps}
              spanColumn={spanColumn}
              column={breakpointColumn}
              onSelect={this.handleSelect}
              saveCellRef={this.saveCellRef}
            />,
          );
        }
      }

      this.areasProps = areasProps;
    }

    return createElement(elementType, elementProps, ...this.children);
  }
}
